// @flow

import { toast } from "react-toastify";
import * as R from "ramda";
import moment from "moment";
import { call, put, takeEvery, select } from "redux-saga/effects";

import * as message from "src/api/message";
import { incrementLastRead } from "src/api/chatroom";
import * as atypes from "src/constants/actionTypes";

import getAppState from "src/selectors";

import type { Action } from "src/types";

function* insertChat({ payload }: Action): any {
  const { message: messageData } = payload;
  try {
    yield put({
      type: atypes.HAS_NEW_MESSAGE
    });

    yield call(message.insertChat, messageData);
    yield put({
      type: atypes.INSERT_CHAT_SUCCESS,
      payload
    });

    yield call(incrementLastRead, messageData.roomId, messageData.author);
  } catch (error) {
    yield put({
      type: atypes.INSERT_CHAT_FAILURE,
      payload: { ...payload, error }
    });
  }
}

function* watchInsertChat(): any {
  yield takeEvery(atypes.INSERT_CHAT_REQUEST, insertChat);
}

function* fetchMessage({ payload }: Action): any {
  try {
    const { messageId } = payload;

    const roomId = (yield select(getAppState)).chatRooms.current;

    const fetchedMessage = yield call(message.fetchMessage, {
      roomId,
      messageId
    });

    yield put({
      type: atypes.FETCH_MESSAGE_SUCCESS,
      payload: { id: messageId, message: fetchedMessage }
    });
  } catch (err) {
    yield put({
      type: atypes.FETCH_MESSAGE_FAILURE,
      payload: `ERROR FETCHING MESSAGE: ${err}`
    });
  }
}

function* watchFetchMessage(): any {
  yield takeEvery(atypes.FETCH_MESSAGE_REQUEST, fetchMessage);
}

function* fetchMessageRange({ payload }: Action): any {
  try {
    const roomId = (yield select(getAppState)).chatRooms.current;
    const { id, timestamp } = payload;
    const messages = yield call(message.fetchMessageRange, {
      roomId,
      id,
      timestamp
    });

    yield put({
      type: atypes.FETCH_MESSAGE_RANGE_SUCCESS,
      payload: { messages, roomId }
    });

    window.location.href = `${window.location.href.split("#")[0]}#${id}`;
  } catch (err) {
    yield put({
      type: atypes.FETCH_MESSAGE_RANGE_FAILURE,
      payload: { err }
    });
  }
}

function* watchFetchMessageRange(): any {
  yield takeEvery(atypes.FETCH_MESSAGE_RANGE_REQUEST, fetchMessageRange);
}

function* deleteMessage({ payload }: Action): any {
  try {
    const { roomId } = payload;
    const { deletableMessages } = (yield select(getAppState)).chatRooms;

    const isDiffGreaterThan15 = time =>
      moment.duration(moment().diff(moment(time))).asMinutes() > 15;

    const currentlyDeletable = deletableMessages.reduce((acc, val) => {
      if (isDiffGreaterThan15(val.timestamp)) return false;
      return acc;
    }, true);

    if (currentlyDeletable) {
      const messages = R.map(R.prop("id"), deletableMessages);
      yield call(message.deleteMessage, roomId, messages);
      yield put({
        type: atypes.CLEAR_MESSAGE_SELECTION
      });
    } else {
      yield put({
        type: atypes.SET_INFO_MODAL,
        payload: {
          isOpen: true,
          message: "There was some issue with deleting your messages"
        }
      });
    }
  } catch (err) {
    if (err.status === 400) {
      toast.error("Could not delete message");
    }
    yield put({
      type: atypes.DELETE_MESSAGE_FAILURE,
      payload: { ...payload, err }
    });
  }
}

function* watchDeleteMessage(): any {
  yield takeEvery(atypes.DELETE_MESSAGE_REQUEST, deleteMessage);
}

function* hideMessages({ payload }): any {
  try {
    const { roomId } = payload;
    const { selectedMessages } = (yield select(getAppState)).chatRooms;

    yield call(message.hideMessages, roomId, selectedMessages);

    // Dispatch hide messages Success
    yield put({
      type: atypes.HIDE_MESSAGE_SUCCESS,
      payload
    });

    yield put({
      type: atypes.CLEAR_MESSAGE_SELECTION
    });
  } catch (err) {
    yield put({
      type: atypes.HIDE_MESSAGE_FAILURE,
      payload: { ...payload, err }
    });
  }
}

function* watchHideMessages(): any {
  yield takeEvery(atypes.HIDE_MESSAGE_REQUEST, hideMessages);
}

function* unhideMessage({ payload }): any {
  try {
    const { id: messageId, roomId } = payload;

    yield call(message.unhideMessage, roomId, messageId);

    // Dispatch unhide messages Success
    yield put({
      type: atypes.UNHIDE_MESSAGE_SUCCESS,
      payload
    });

    yield put({
      type: atypes.CLEAR_MESSAGE_SELECTION,
      payload: {}
    });
  } catch (err) {
    yield put({
      type: atypes.UNHIDE_MESSAGE_FAILURE,
      payload: { ...payload, err }
    });
  }
}

function* watchUnhideMessage(): any {
  yield takeEvery(atypes.UNHIDE_MESSAGE_REQUEST, unhideMessage);
}

function* forwardMessage({ payload }): any {
  try {
    const { roomId } = payload;
    const { selectedMessages, selectedRooms } = (yield select(getAppState))
      .chatRooms;

    yield call(
      message.forwardMessages,
      roomId,
      selectedMessages,
      selectedRooms.map(room => parseInt(room, 10))
    );

    yield put({
      type: atypes.FORWARD_MESSAGE_SUCCESS,
      payload
    });
  } catch (err) {
    yield put({
      type: atypes.FORWARD_MESSAGE_FAILURE,
      payload: { ...payload, err }
    });
  }
}

function* watchForwardMessage(): any {
  yield takeEvery(atypes.FORWARD_MESSAGE_REQUEST, forwardMessage);
}

export default [
  watchInsertChat(),
  watchFetchMessage(),
  watchFetchMessageRange(),
  watchDeleteMessage(),
  watchHideMessages(),
  watchUnhideMessage(),
  watchForwardMessage()
];
