// @flow

import React from "react";
import { useSelector } from "react-redux";
import * as R from "ramda";
import moment from "moment";

import NewConversationValue from "./NewConversationValue";
import ConversationDiffValue from "./ConversationDiffValue";
import type { RoomId } from "src/types";
import DiffOldConversationValues from "./DiffOldConversationValues";
import OldConversationValues from "./OldConversationValues";
import { getChatRoomsById } from "src/reducers";

type Props = {
  timestamp: string,
  oldValue: Array<RoomId>,
  newValue: Array<RoomId>,
  disableTitleHyperlink: boolean
};

const Conversations = ({
  timestamp,
  oldValue,
  newValue,
  disableTitleHyperlink
}: Props) => {
  const filteredOldValues = (oldValue || []).filter(
    val => !newValue.includes(val)
  );
  const chatRooms = useSelector(({ app }) => getChatRoomsById(app));
  const oldAddresses = filteredOldValues.map(id => chatRooms[id]) || [];
  return (
    <>
      {moment(timestamp).startOf("day").isAfter("2020-05-27") ? (
        <OldConversationValues
          oldValue={R.difference(oldValue || [], newValue || [])}
          oldAddresses={oldAddresses}
          disableTitleHyperlink={disableTitleHyperlink}
        />
      ) : (
        <DiffOldConversationValues
          filteredOldValues={filteredOldValues}
          oldAddresses={oldAddresses}
          disableTitleHyperlink={disableTitleHyperlink}
        />
      )}
      {/* This done to prevent old values repeating in notificatoin
           more details at https://app.unifize.com/conversation/8GR5Y61D2 */}
      {moment(timestamp).startOf("day").isAfter("2020-05-27") ? (
        <ConversationDiffValue
          newValue={newValue}
          oldValue={oldValue}
          disableTitleHyperlink={disableTitleHyperlink}
        />
      ) : (
        <NewConversationValue
          newValue={newValue}
          oldValue={oldValue}
          disableTitleHyperlink={disableTitleHyperlink}
        />
      )}
    </>
  );
};

export default Conversations;
