// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";
import * as R from "ramda";

import type { NonOrgParticipant } from "src/types";
import { systemRolesDispMap } from "src/constants/roleManagement";
import { getEmailPrefix } from "src/utils";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  HStack,
  VStack,
  Text,
  Avatar,
  Tooltip,
  IconButton
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";

import Select from "src/components/Select";

import * as styles from "./styles";

type ActionButton = {
  label: string,
  action: Function
};

type Props = {
  title: string,
  roles: Array<{ id: number, title: string, label: string }>,
  participants: NonOrgParticipant[],
  open: boolean,
  inProgress: boolean,
  cancelButton?: ActionButton,
  okButton: ActionButton,
  onBack: Function,
  setParticipantRole: Function
};

const SkipInviteOption = props => (
  <Text my={0} color="unifize_redDark" {...props}>
    {i18n.t(k.SKIP_INVITE)}
  </Text>
);

function InvitationModal({
  title,
  roles,
  participants,
  open,
  inProgress,
  cancelButton,
  okButton,
  onBack,
  setParticipantRole
}: Props) {
  return (
    <Modal isOpen={open} {...styles.modal}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader {...styles.header}>
          <HStack {...styles.headerStack}>
            <IconButton
              onClick={onBack}
              isDisabled={inProgress}
              icon={<ArrowBackIcon {...styles.headerIcon} />}
              {...styles.headerIconButton}
            />

            <Text {...styles.headerTitle}>{title}</Text>
          </HStack>
        </ModalHeader>
        <ModalBody>
          <VStack {...styles.inviteeList}>
            {participants.map(({ email, role }) => (
              <HStack key={email} {...styles.invitee}>
                <HStack {...styles.avatarBox}>
                  <Avatar name={email} {...styles.avatar} />
                  <VStack {...styles.emailBox}>
                    <Text {...styles.emailPrefix}>{getEmailPrefix(email)}</Text>
                    <Tooltip label={email}>
                      <Text {...styles.email}>{email}</Text>
                    </Tooltip>
                  </VStack>
                </HStack>

                <Select
                  value={role || null}
                  label={
                    R.isNil(role) ? (
                      <SkipInviteOption />
                    ) : (
                      systemRolesDispMap[role || ""] || role
                    )
                  }
                  onChange={newRole =>
                    setParticipantRole({ email, role: newRole })
                  }
                  width="220px"
                >
                  {roles.map(role => (
                    <Text key={role.id} value={role.title} my={0}>
                      {role.label}
                    </Text>
                  ))}
                  <SkipInviteOption value={null} />
                </Select>
              </HStack>
            ))}
          </VStack>
        </ModalBody>

        <ModalFooter>
          {cancelButton && (
            <Button
              onClick={cancelButton.action}
              isLoading={inProgress}
              {...styles.cancelButton}
            >
              {cancelButton.label}
            </Button>
          )}

          <Button
            onClick={okButton.action}
            isLoading={inProgress}
            {...styles.okButton}
          >
            {okButton.label}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default InvitationModal;
