// @flow

import React from "react";

import * as colors from "src/styles/constants/colors";

type Props = {
  color?: string,
  style?: Object
};

const CaretRight = ({ color = colors.grey42, style = {} }: Props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...style }}
    >
      <path
        d="M5.72656 11.06L8.7799 8L5.72656 4.94L6.66656 4L10.6666 8L6.66656 12L5.72656 11.06Z"
        fill={color}
      />
    </svg>
  );
};

export default CaretRight;
