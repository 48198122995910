// @flow

import { combineReducers } from "redux";
import { createSelector } from "reselect";
import * as R from "ramda";

import * as atypes from "src/constants/actionTypes";
import { modalTypes } from "src/constants";

import type {
  Action,
  FilesPreview,
  ModalState,
  WorkflowDeletionModal
} from "src/types";

const editDisplayName = (state: boolean = false, { type, payload }: Action) => {
  switch (type) {
    case atypes.TOGGLE_DISPLAY_NAME_MODAL:
      return payload.open;
    default:
      return state;
  }
};

const createOrg = (state: boolean = false, { type, payload }: Action) => {
  switch (type) {
    case atypes.TOGGLE_CREATE_ORG_MODAL:
      return payload.open;
    default:
      return state;
  }
};

const cloneOrg = (state: boolean = false, { type, payload }: Action) => {
  switch (type) {
    case atypes.TOGGLE_CLONE_ORG_MODAL:
      return payload.open;
    default:
      return state;
  }
};

const preferences = (state: boolean = false, { type, payload }: Action) => {
  switch (type) {
    case atypes.TOGGLE_PREFERENCES_MODAL:
      return payload.open;
    default:
      return state;
  }
};

const showRelatedModal = (
  state: boolean = false,
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.TOGGLE_RELATED_MODAL:
      return payload.open;
    case atypes.SET_CURRENT_CHATROOM_REQUEST:
      return false;
    default:
      return state;
  }
};

const infoModal = (
  state: { isOpen: boolean, message: string, confirmAction: Function } = {
    isOpen: false,
    message: "",
    type: "CONFIRMATION",
    confirmAction: () => {}
  },
  { type, payload }
) => {
  switch (type) {
    case atypes.SET_INFO_MODAL:
      return payload;
    case atypes.CLOSE_INFO_MODAL:
      return {
        isOpen: false,
        message: "",
        type: "CONFIRMATION",
        confirmAction: () => {}
      };
    default:
      return state;
  }
};

const forward = (state: boolean = false, { type }: Action) => {
  switch (type) {
    case atypes.SHOW_FORWARD_MODAL:
      return true;
    case atypes.HIDE_FORWARD_MODAL:
    case atypes.FORWARD_MESSAGE_SUCCESS:
      return false;
    case atypes.TOGGLE_FORWARD_MODAL:
      return !state;
    default:
      return state;
  }
};

const promptRules = (
  state: any = {
    isOpen: false,
    promptField: null
  },
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.SHOW_PROMPT_RULES:
      return { isOpen: true, promptField: payload };
    case atypes.HIDE_PROMPT_RULES:
      return { isOpen: false, promptField: null };
    default:
      return state;
  }
};

const changePassword = (state: boolean = false, { type, payload }: Action) => {
  switch (type) {
    case atypes.TOGGLE_CHANGE_PASSWORD_MODAL:
      return payload.open;
    default:
      return state;
  }
};

const filesUpload = (state: FilesPreview = {}, { type, payload }: Action) => {
  switch (type) {
    case atypes.SHOW_FILE_UPLOAD_MODAL:
      return R.mergeDeepRight(state, {
        [payload.viewId]: {
          files: payload.files
        }
      });

    case atypes.HIDE_FILE_UPLOAD_MODAL:
      return R.dissoc(payload.viewId, state);

    default:
      return state;
  }
};

const workflowDeletion = (
  state: WorkflowDeletionModal = { isOpen: false },
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.TOGGLE_WORKFLOW_DELETION_MODAL:
      return payload;
    default:
      return state;
  }
};

const blockNavigation = (state: Object = {}, { type, payload }: Action) => {
  switch (type) {
    case atypes.SHOW_BLOCK_NAVIGATION_MODAL:
      const { message, canLeave } = payload;
      return { message, canLeave };
    case atypes.HIDE_BLOCK_NAVIGATION_MODEL:
      return {};
    default:
      return state;
  }
};

const about = (state: boolean = false, { type, payload }: Action) => {
  switch (type) {
    case atypes.TOGGLE_ABOUT_MODAL:
      return payload.value ?? !state;
    default:
      return state;
  }
};

export const getFileUploadModalStatus = (state: ModalState, viewId: string) =>
  Boolean(state.filesUpload[viewId]);

export const getFileUploadModalFiles = (state: ModalState, viewId: string) =>
  state.filesUpload[viewId] ? state.filesUpload[viewId].files : [];

export const getShowCloneOrgModal = (state: ModalState) => state.cloneOrg;

export const getIsAboutModalOpen = (state: ModalState) => state.about;
export const getIsDisplayNameModalOpen = (state: ModalState) =>
  state.editDisplayName;
export const getIsCreateOrgModalOpen = (state: ModalState) => state.createOrg;
export const getIsCloneOrgModalOpen = (state: ModalState) => state.cloneOrg;
export const getIsChangePasswordModalOpen = (state: ModalState) =>
  state.changePassword;
export const getIsPreferencesModalOpen = (state: ModalState) =>
  state.preferences;

export const getOpenModalName = createSelector(
  [
    getIsDisplayNameModalOpen,
    getIsCreateOrgModalOpen,
    getIsCloneOrgModalOpen,
    getIsChangePasswordModalOpen,
    getIsPreferencesModalOpen
  ],
  (displayName, createOrg, cloneOrg, changePassword, preferences) => {
    if (displayName) return modalTypes.displayName;
    else if (createOrg) return modalTypes.createOrg;
    else if (cloneOrg) return modalTypes.cloneOrg;
    else if (changePassword) return modalTypes.changePassword;
    else if (preferences) return modalTypes.preferences;
    return null;
  }
);

export const getNavigationBlock = (state: ModalState) => state.blockNavigation;

export default combineReducers<Object, Action>({
  editDisplayName,
  createOrg,
  cloneOrg,
  preferences,
  showRelatedModal,
  infoModal,
  forward,
  promptRules,
  changePassword,
  filesUpload,
  workflowDeletion,
  blockNavigation,
  about
});
