// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React, { useState } from "react";
import { useSelector } from "react-redux";
import * as R from "ramda";

import { EditIconWrapper } from "../styles";
import {
  Address,
  MultiContainer,
  MultiConversationRow,
  MultiTable,
  StyledMultiForm as StyledForm,
  FormWrapper
} from "./styles";
import MultipleForm from "./MultipleForm";
import FormTable from "./FormTable";
import Icon from "src/icons";
import {
  getIfFormFieldsVisible,
  getEmbeddedFormColumns,
  getFieldWidth
} from "src/reducers";
import type { ColumnId, WorkflowInstances } from "src/types";
import ChecklistItem from "../ChecklistItem";

type Props = {
  selected: boolean,
  multiple: boolean,
  value: any,
  openChecklistEditModal: Function,
  index: number,
  fieldId: number,
  roomId: string,
  hideEdit?: boolean,
  autoNo: string,
  columnId: ColumnId,
  type: string,
  process: WorkflowInstances,
  embeddedIndex?: number
};

const Form = ({
  selected,
  value,
  openChecklistEditModal,
  index,
  fieldId,
  roomId,
  hideEdit,
  autoNo,
  columnId,
  type,
  process,
  embeddedIndex
}: Props) => {
  const [form, setForm] = useState({});
  const isFormExpanded = useSelector(({ app }) =>
    getIfFormFieldsVisible(app, fieldId)
  );
  const embeddedFormColumns = useSelector(({ app }) =>
    getEmbeddedFormColumns(app)
  );
  const fieldWidth = useSelector(({ app }) => getFieldWidth(app, columnId));

  const handleTable = (newTable: Object) => {
    setForm(newTable);
  };

  const field = useSelector(({ app }) =>
    app.checklist.fields.byId.get(`${fieldId}`)
  );
  // $FlowFixMe
  const settings = JSON.parse(field?.get("settings") || "{}");

  if ((value || []).length === 0) {
    return (
      <MultiContainer
        selected={selected}
        borderRequired={columnId.includes("-")}
      >
        <MultiTable>
          <tbody>
            <MultiConversationRow expandedField={isFormExpanded ? true : false}>
              <StyledForm
                selected={selected}
                width={fieldWidth}
                expanded={true}
              >
                <FormWrapper>{i18n.t(k.NO_VALUE)}</FormWrapper>

                {!hideEdit && (
                  <EditIconWrapper
                    onClick={e =>
                      openChecklistEditModal(
                        e,
                        0,
                        fieldId,
                        roomId,
                        value,
                        columnId
                      )
                    }
                  >
                    <Icon type="editPencil" />
                  </EditIconWrapper>
                )}
              </StyledForm>
              {/* Embedded form fields */}
              {embeddedFormColumns[columnId] &&
                embeddedFormColumns[columnId].map((column, columnIndex) => {
                  const embeddedFormValue =
                    process && process[column.id]
                      ? process[column.id][index]
                      : [];
                  return (
                    <ChecklistItem
                      selected={selected}
                      key={`${columnIndex}-${index}`}
                      columnId={`${column.id}`}
                      fieldId={parseInt(column.fieldId)}
                      value={embeddedFormValue}
                      settings={settings}
                      index={index}
                      isPrivate={false}
                      roomId={roomId}
                      process={process}
                      allRevisionsShown={true}
                      shouldRenderEmbeddedFields={true}
                      embeddedIndex={0}
                    />
                  );
                })}
            </MultiConversationRow>
          </tbody>
        </MultiTable>
      </MultiContainer>
    );
  }

  if (value && (settings.multiple || Array.isArray(value[0]))) {
    return (
      <>
        {!R.isEmpty(form) && (
          <FormTable handleClose={() => setForm({})} form={form} />
        )}
        <MultipleForm
          selected={selected}
          value={value}
          openChecklistEditModal={openChecklistEditModal}
          rowIndex={index}
          fieldId={fieldId}
          roomId={roomId}
          handleTable={handleTable}
          hideEdit={hideEdit}
          autoNo={autoNo}
          columnId={columnId}
          type={type}
          process={process}
          settings={settings}
          embeddedIndex={embeddedIndex}
        />
      </>
    );
  }

  const firstVal = value[0] || {};

  return (
    <>
      {!R.isEmpty(form) && (
        <FormTable handleClose={() => setForm({})} form={form} />
      )}
      <MultiContainer
        selected={selected}
        borderRequired={columnId.includes("-")}
      >
        <MultiTable>
          <tbody>
            <MultiConversationRow expandedField={isFormExpanded ? true : false}>
              <StyledForm
                selected={selected}
                width={fieldWidth}
                expanded={true}
              >
                <FormWrapper>
                  <span>
                    {firstVal ? firstVal.templateTitle : i18n.t(k._5)}
                  </span>
                  <Address>
                    {firstVal ? firstVal.address : i18n.t(k._5)}
                  </Address>
                </FormWrapper>
                {!hideEdit && (
                  <EditIconWrapper
                    onClick={e =>
                      openChecklistEditModal(
                        e,
                        0,
                        fieldId,
                        roomId,
                        value,
                        columnId
                      )
                    }
                  >
                    <Icon type="editPencil" />
                  </EditIconWrapper>
                )}
              </StyledForm>
              {/* Embedded form fields */}
              {embeddedFormColumns[columnId] &&
                embeddedFormColumns[columnId].map((column, columnIndex) => {
                  const embeddedFormValue =
                    process && process[column.id] ? process[column.id][0] : [];
                  return (
                    <ChecklistItem
                      selected={selected}
                      key={`${columnIndex}${i18n.t(k._5)}${index}`}
                      columnId={`${column.id}`}
                      fieldId={parseInt(column.fieldId)}
                      value={embeddedFormValue}
                      settings={settings}
                      index={index}
                      isPrivate={false}
                      roomId={roomId}
                      process={process}
                      allRevisionsShown={true}
                      shouldRenderEmbeddedFields={true}
                      embeddedIndex={0}
                    />
                  );
                })}
            </MultiConversationRow>
          </tbody>
        </MultiTable>
      </MultiContainer>
    </>
  );
};

export default Form;
