// @flow

import { connect } from "react-redux";
import React from "react";

import Group from "src/components/chatroom/Status/Group";
import Approval from "src/components/chatroom/Status/Approval";
import Conversation from "src/components/chatroom/Status/Conversation";
import Workflow from "src/components/chatroom/Status/Workflow";

import { getChatroomType } from "src/reducers";

import location from "src/constants/location";

import type { ConversationType, AppState, RoomId } from "src/types";

type Props = {
  roomId: RoomId,
  type: ConversationType,
  handleStatus: (status: number) => void,
  handleArchive: (value: boolean) => void
};

const Items = ({ roomId, type, handleStatus, handleArchive }: Props) => {
  switch (type) {
    case "group":
      return <Group roomId={roomId} handleStatus={handleStatus} />;
    case "approval":
      return <Approval roomId={roomId} handleStatus={handleStatus} />;
    case "workflow":
      return (
        <Workflow
          roomId={roomId}
          handleStatus={handleStatus}
          handleArchive={handleArchive}
          location={location.manageView}
        />
      );

    default:
      return <Conversation roomId={roomId} handleStatus={handleStatus} />;
  }
};

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  type: getChatroomType(app, props.roomId)
});

export default connect(mapStateToProps)(Items);
