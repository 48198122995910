// @flow

import * as easyFetch from "src/utils/fetch";

/**
 * API to fetch Sharepoint access token and auth url
 * @return {Object} object containing access token and url
 */
export const getOneDriveAuthData = () =>
  easyFetch.get("/microsoft365/token?action=filePicker");

/**
 * API to create MS365 document in sharepoint / one drive
 * @param {string} fieldId - id of checklist
 * @param {string} name - name of document
 * @param {string} extension - document file extension
 * @return {Object} sharepoint file information
 */
export const createMicrosoftDocument = async ({
  fieldId,
  name,
  extension
}: {
  fieldId: number,
  name: string,
  extension: string
}) =>
  easyFetch.post("/microsoft365/file/create", {
    body: {
      fieldId,
      title: name,
      extension
    }
  });
