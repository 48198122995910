// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";

import Item from "src/components/Manage/Reports/Instances/Chart/ChartList/Title";
import { Value as StyledValue } from "src/components/Manage/Reports/Instances/Chart/New/FieldSelect/styles";
import Icon from "src/icons";

type Props = {
  value: number,
  handleSelect: Function
};

const Selection = ({ value, handleSelect }: Props) => {
  if (value) {
    return (
      <StyledValue type="button" onClick={() => handleSelect(null)}>
        <Item id={value} />
        <Icon type="close" />
      </StyledValue>
    );
  }
  return <StyledValue>{i18n.t(k.SELECT_FIELD)}</StyledValue>;
};

export default Selection;
