// @flow

import React from "react";
import * as R from "ramda";

import { conversationTypes } from "src/constants/display";

type Props = {
  value: string,
  handleChange: Function
};

const chatTypes = ["group", "workflow", "approval", "conversation", "task"];

const SelectConversationType = ({ value, handleChange }: Props) => {
  return (
    <select
      data-cy="processTypeDropdown"
      id="type"
      value={value}
      onChange={handleChange}
    >
      {R.map(
        type => (
          <option key={type} value={type}>
            {conversationTypes[type][0]}
          </option>
        ),

        chatTypes
      )}
    </select>
  );
};

export default SelectConversationType;
