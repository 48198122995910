// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React, { useCallback, useEffect } from "react";

import { Dropdown as StyledDropdown } from "../../styles";
import { ListItemMedium } from "src/styles/box";
import OutsideClickHandler from "src/components/OutsideClickHandler";

type Props = {
  outerRef: any,
  handleClose: Function,
  handleSelect: Function
};

const Dropdown = ({ outerRef, handleSelect, handleClose }: Props) => {
  const closeModal = useCallback(
    (event: any) => {
      if (event.key === "Escape") {
        event.preventDefault();
        event.stopPropagation();
        handleClose();
      }
    },
    [handleClose]
  );

  useEffect(() => {
    document.addEventListener("keydown", closeModal, true);
    return () => {
      document.removeEventListener("keydown", closeModal, true);
    };
  }, []);

  const handleClickOutside = useCallback(
    (event: any) => {
      if (
        outerRef &&
        outerRef.current &&
        !outerRef.current.contains(event.target)
      ) {
        handleClose();
      }
    },
    [outerRef, handleClose]
  );

  return (
    <OutsideClickHandler onClickOutside={handleClickOutside}>
      <StyledDropdown>
        <ListItemMedium
          onClick={() =>
            handleSelect({
              displayEmpty: false,
              emptyMessage: {
                title: i18n.t(k._4),
                subTitle: i18n.t(k._4)
              }
            })
          }
        >
          {i18n.t(k.DON_T_SHOW_TILE)}
        </ListItemMedium>
        <ListItemMedium
          onClick={() =>
            handleSelect({
              displayEmpty: true,
              emptyMessage: {
                title: i18n.t(k._4),
                subTitle: i18n.t(k._4)
              }
            })
          }
        >
          {i18n.t(k.SHOW_A_MESSAGE)}
        </ListItemMedium>
      </StyledDropdown>
    </OutsideClickHandler>
  );
};

export default Dropdown;
