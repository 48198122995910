// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import { connect } from "react-redux";
import React from "react";
import * as R from "ramda";

import Name from "./GroupName";
import { Groups as StyledGroups } from "./styles";
import { getUserGroups } from "src/reducers";

import type { AppState } from "src/types";

type Props = {
  groups: Array<number>
};

const Groups = ({ groups }: Props) => (
  <StyledGroups>
    <div>
      {R.take(3, groups || []).map((g: number) => (
        <Name key={g} id={g} />
      ))}
      {(groups || []).length > 3
        ? `    ${i18n.t(k._13)}${groups.length - 3}`
        : null}
    </div>
  </StyledGroups>
);

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  groups: getUserGroups(app, props.uid)
});

export default connect(mapStateToProps)(Groups);
