// @flow

import { backendUrl } from "src/config/firebase";

/**
 * Get the backend version the production is using
 * @returns {string} - backend version
 */
export const getBackendVersion = async () => {
  try {
    const response = await fetch(`${backendUrl}/version`);
    return response.text();
  } catch (err) {
    console.error(err);
    return "";
  }
};
