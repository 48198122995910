// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";
import { useSelector } from "react-redux";
import { FormControl, FormLabel, Flex } from "@chakra-ui/react";

import * as styles from "../styles";
import { getValueFromQuery } from "src/reducers";
import Tooltip from "src/components/Tooltip";
import Icon from "src/icons";
import GroupByDropdown from "./GroupByDropdown";
import * as colors from "src/styles/constants/colors";
import { comparisonCharts } from "src/constants/charts";

type Props = {
  linkedOrFormFieldId: ?number,
  chartDetails: Object,
  setChartDetails: Function,
  seriesId: string,
  groupByFieldId: number
};

const GroupBy = ({
  linkedOrFormFieldId,
  chartDetails,
  setChartDetails,
  seriesId,
  groupByFieldId
}: Props) => {
  const builder = useSelector(({ location }) =>
    getValueFromQuery("builder")(location)
  );

  return (
    <FormControl sx={styles.FormLabel}>
      <Flex
        sx={
          builder === comparisonCharts.DualYAxis
            ? styles.DisableGroupBy
            : styles.SeriesLabel
        }
      >
        <FormLabel sx={styles.SeriesLabel}>{i18n.t(k.GROUP_BY)}</FormLabel>
        {builder === comparisonCharts.DualYAxis && (
          <Tooltip
            sx={{ backgroundColor: "none" }}
            title="In Dual Y axis chart, columns can't be grouped"
            placement="top-start"
          >
            <Icon
              type="info"
              fillColor={colors.inkLightest}
              width="16px"
              height="16px"
            />
          </Tooltip>
        )}
      </Flex>

      <GroupByDropdown
        linkedOrFormFieldId={linkedOrFormFieldId}
        chartDetails={chartDetails}
        setChartDetails={setChartDetails}
        seriesId={seriesId}
        groupByFieldId={groupByFieldId}
      />
    </FormControl>
  );
};

export default GroupBy;
