// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";
import * as R from "ramda";

import SegmentName from "./SegmentName";
import { UserSegmentSelection as StyledUserSegmentSelection } from "./styles";

type Props = {
  color: string,
  value: Array<number>,
  toggleDropdown: Function
};

const UserSegmentSelection = ({ color, value, toggleDropdown }: Props) => {
  const first = R.head(value || []);

  return (
    <StyledUserSegmentSelection color={color} onClick={toggleDropdown}>
      <SegmentName id={first} /> &nbsp;
      {value.length > 1 && (
        <b>
          {value.length - 1} {i18n.t(k.OTHERS1)}{" "}
        </b>
      )}
    </StyledUserSegmentSelection>
  );
};

export default UserSegmentSelection;
