// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";

import { Small as StyledTitle, NoTitle, TitleHeader } from "./styles";
import DirectChat from "./DirectChat";
import Privacy from "src/components/chatroom/Privacy";
import Email from "src/containers/user/Email";
import { getCurrentUserId } from "src/reducers";

import type { RoomId, UID, AppState } from "src/types";

type Props = {
  type: string,
  title: string,
  canceled: ?boolean,
  roomId: RoomId,
  currentUser: UID
};

const SmallTitle = ({ type, roomId, title, canceled, currentUser }: Props) => {
  const users = (title || "").split(",") || [];
  const uid = R.head(R.reject(R.equals(currentUser), users) || []);
  return (
    <StyledTitle canceled={canceled}>
      <TitleHeader data-cy="chatroomTitle">
        <Privacy roomId={roomId} />
        {(title || "").length === 0 &&
        type === "workflow" &&
        type !== "direct" ? (
          <NoTitle>{i18n.t(k.UNTITLED)}</NoTitle>
        ) : null}
        {type !== "direct" ? title : <DirectChat title={title} />}
        {type === "direct" && (
          <div>
            <Email uid={uid} />
          </div>
        )}
      </TitleHeader>
    </StyledTitle>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  currentUser: getCurrentUserId(app)
});

export default connect(mapStateToProps)(SmallTitle);
