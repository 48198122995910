// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";
import { Portal } from "@chakra-ui/react";

import {
  Modalwrapper,
  Overlay,
  MessageText,
  PendingText,
  CompletedText,
  ConformButton
} from "./styles";

type Props = {
  hideModal: Function
};

const WarningModal = ({ hideModal }: Props) => {
  return (
    <Portal>
      <Overlay>
        <Modalwrapper>
          <MessageText>
            You need to have at least one status mapped to
            <PendingText> PENDING</PendingText> and
            <CompletedText> COMPLETED</CompletedText> each.
          </MessageText>
          <ConformButton onClick={hideModal}>{i18n.t(k.OK)}</ConformButton>
        </Modalwrapper>
      </Overlay>
    </Portal>
  );
};

export default WarningModal;
