// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";
import ReactModal from "react-modal";

import {
  Approvals as StyledApprovals,
  Body,
  Item,
  Accept,
  Deny
} from "./styles";
import { getParent } from "src/utils";
import { Form } from "src/styles/form";
import Icon from "src/components/Icon";
import { iconSize } from "src/styles/constants/size";
import * as colors from "src/styles/constants/colors";
import NameOrEmail from "src/containers/user/NameOrEmail";
import type { UID } from "src/types";

type Props = {
  show: boolean,
  pendingMembers: Array<UID>,
  handleClose: Function,
  handleAccept: Function,
  handleDeny: Function
};

const Approvals = ({
  show,
  pendingMembers,
  handleClose,
  handleAccept,
  handleDeny
}: Props) => {
  return (
    <StyledApprovals id="approval-dialog">
      <ReactModal
        isOpen={show}
        onRequestClose={handleClose}
        parentSelector={() => getParent("#approval-dialog")}
        shouldCloseOnOverlayClick
      >
        <Form>
          <h4>
            <b>{i18n.t(k.ACCESS_REQUEST)}</b>
            <Icon
              icon="close"
              color={colors.primary}
              onClick={handleClose}
              size={iconSize.space_l}
            />
          </h4>
        </Form>
        <Body>
          {pendingMembers.length} {i18n.t(k.PEOPLE_HAVE_REQUESTED_TO_ACCES)}
          <div>
            {(pendingMembers || []).map(uid => (
              <Item key={uid}>
                <NameOrEmail uid={uid} />
                <div>
                  <Accept onClick={() => handleAccept(uid)}>
                    {i18n.t(k.ACCEPT)}
                  </Accept>
                  <Deny onClick={() => handleDeny(uid)}>{i18n.t(k.DENY)}</Deny>
                </div>
              </Item>
            ))}
          </div>
        </Body>
      </ReactModal>
    </StyledApprovals>
  );
};

export default Approvals;
