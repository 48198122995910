// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React, { Component } from "react";
import debounce from "debounce";

import Result from "./result";
import Selection from "src/containers/user/ImageLabel/Remove";
import selector from "src/components/selector";
import { TextWithputBorders as SearchInput } from "src/styles/input";
import { Select as StyledSelect } from "src/styles/dropdown";

import type { UID } from "src/types";

type Props = {
  focus: number,
  dropdown: boolean,
  value: UID,
  result: Array<UID>,
  autoFocus?: boolean,
  handleChange: Function,
  handleDropdown: Function,
  handleClose: Function,
  setFocus: Function,
  handleSearch: Function,
  resetSearch: Function,
  noInvite?: boolean,
  renderOptions: Function
};

type State = {
  search: string,
  showInvite: boolean
};

class Select extends Component<Props, State> {
  outerRef: any;
  searchRef: any;

  static defaultProps = {
    autoFocus: false,
    noInvite: false
  };

  state = {
    search: i18n.t(k._4),
    showInvite: false
  };

  componentDidUpdate(prevProps: Props) {
    const { dropdown } = this.props;
    if (prevProps.dropdown !== dropdown && dropdown) {
      const { resetSearch } = this.props;
      resetSearch();
    }
  }

  handleDeletion = (e: any) => {
    if (e.keyCode === 46 || e.keyCode === 8) {
      this.handleSelect(null);
      this.handleDropdown();
    }
  };

  handleInvite = () => {
    this.setState(prevState => ({
      showInvite: !prevState.showInvite
    }));
  };

  onKeyDown = (e: any) => {
    if (e) {
      // On Enter selecting the first user in the search result
      if (e.key === "Enter") {
        const { focus, result } = this.props;
        const value = result[focus];
        if (value) {
          this.handleSelect(value);
          e.preventDefault();
        }
      }

      // Detect ESC
      if (e.keyCode === 27) {
        const { dropdown, handleClose } = this.props;
        if (dropdown) {
          e.preventDefault();
          e.stopPropagation();
          handleClose();
        }
      }

      const { dropdown } = this.props;
      if (!dropdown) {
        this.handleDropdown();
      }
    }
  };

  handleSelect = (item: ?UID) => {
    const { handleChange, resetSearch, handleClose } = this.props;
    this.setState({ search: i18n.t(k._4) });
    handleChange(item);
    resetSearch();
    handleClose();
  };

  removeHandler = (e: any) => {
    e.stopPropagation();
    this.handleSelect(null);
    this.handleDropdown();
  };

  debouncedSearch = debounce((value: String) => {
    const { handleSearch } = this.props;
    handleSearch(value);
  }, 800);

  handleSearch = (e: any) => {
    const { value, id } = e.target;
    this.setState({ [id]: value });
    this.debouncedSearch(value);
  };

  handleDropdown = () => {
    const { dropdown, handleDropdown, resetSearch } = this.props;
    if (!dropdown) {
      if (this.searchRef) {
        this.searchRef.focus();
        resetSearch();
      }
    }
    handleDropdown();
  };

  render() {
    const {
      value,
      dropdown,
      autoFocus,
      focus,
      result,
      setFocus,
      handleClose,
      noInvite,
      renderOptions
    } = this.props;
    const { search } = this.state;

    return (
      <StyledSelect
        ref={node => {
          this.outerRef = node;
        }}
        onClick={this.handleDropdown}
        onKeyDown={this.handleDeletion}
      >
        <div>
          {!value || dropdown ? (
            <SearchInput
              type="text"
              id="search"
              placeholder="Search People"
              autoComplete="off"
              value={search}
              onChange={this.handleSearch}
              onKeyDown={this.onKeyDown}
              ref={node => {
                this.searchRef = node;
              }}
              autoFocus={autoFocus || dropdown}
            />
          ) : (
            <Selection uid={value} removeHandler={this.removeHandler} />
          )}
        </div>
        <div>
          {!dropdown ? null : (
            <Result
              text={search}
              focus={focus}
              result={result}
              outerRef={this.outerRef}
              setFocus={setFocus}
              handleSelect={this.handleSelect}
              handleClose={handleClose}
              handleInvite={this.handleInvite}
              noInvite={noInvite}
              renderOptions={renderOptions}
            />
          )}
        </div>
      </StyledSelect>
    );
  }
}

export default selector(Select);
