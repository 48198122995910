// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";

import type { UnifizeUser } from "src/types";

import { Section, SectionTitle } from "./styles";
import Creator from "./CreatorItem";

export default function AdHocParticipants({
  users,
  path = ""
}: {
  users: Array<UnifizeUser>,
  path?: string
}) {
  if (!users || users?.length === 0) {
    return null;
  }
  return (
    <Section>
      {path === "cancellers" ? (
        <SectionTitle> {i18n.t(k.ALLOWED_CANCELLERS)}</SectionTitle>
      ) : (
        <SectionTitle>{i18n.t(k.ALLOWED_SIGNATORIES)}</SectionTitle>
      )}
      {users.map(user =>
        !user?.disabled ? (
          <Creator
            key={user.uid}
            img={user.photoUrl}
            title={user.displayName || user.email}
          />
        ) : null
      )}
    </Section>
  );
}
