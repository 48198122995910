// @flow

import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";

import * as colors from "src/styles/constants/colors";
import * as spacing from "src/styles/constants/spacing";
import * as radius from "src/styles/constants/radius";

const BaseButton = styled.button`
  padding: ${spacing.space_inset_squish_m};
  background: ${colors.active};
  color: ${colors.brightLight};
  border: 1px solid ${colors.active};
  border-radius: ${radius.small};
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const loadingDot = keyframes`
    0% {
        transform: scale(1, 1);
    }
    50% {
        transform: scale(0, 0);
    }
    100% {
        transform: scale(1, 1);
    }`;

const Dot = styled.div`
  width: 0.494rem;
  height: 0.563rem;
  border-radius: ${radius.medium};
  background-color: ${colors.brightLight};
  margin: 0px 0.1rem 0px 0.125rem;
`;

const Dot1 = styled(Dot)`
  animation: ${loadingDot} 1s 0s infinite;
`;

const Dot2 = styled(Dot)`
  animation: ${loadingDot} 1s 0.25s infinite;
`;

const Dot3 = styled(Dot)`
  animation: ${loadingDot} 1s 0.5s infinite;
`;

const Loader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  flex-direction: row;
  margin: ${spacing.space_inset_xs};
`;

export { BaseButton, Dot1, Dot2, Dot3, Loader };
