/* eslint-disable lingui/no-unlocalized-strings */
// @flow

import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import * as R from "ramda";
import { arrayMove } from "react-sortable-hoc";

import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";

import { createForm, updateForm, closeFormModal } from "src/actions/forms";
import type { UID, AppState, Form as FormType } from "src/types";

import {
  ProcessOverlay,
  tabContainer,
  tabListContainer,
  StyledFooter
} from "src/components/Manage/Builder/styles";
import Button from "src/components/Buttons/ChakraButton";
import * as styles from "src/components/Manage/Builder/chakraStyles";
import AlertModal from "src/components/Modal/Alert";
import Title from "./Title";
import DetailsTabPanel from "./Details";
import FieldsPanel from "./Fields";
import Tooltip from "../../../Tooltip";

const initialState = {
  title: "",
  id: "",
  fields: [],
  type: "form",
  settings: { color: "" },
  owners: [],
  edit: false
};

const getFormattedFormData = formData => {
  const {
    title: savedTitle,
    id,
    fields,
    settings: { color },
    owners
  } = formData;

  return {
    title: savedTitle,
    edit: true,
    type: "form",
    settings: { color },
    owners,
    id,
    fields: fields.map(c => ({
      fieldName: c.label,
      fieldType: c.type,
      fieldId: c.id,
      checkMark: c.isChecked,
      settings: JSON.parse(c.settings),
      promptRules: c.promptRules
    }))
  };
};

type Props = {
  openedForm: FormType,
  _createForm: Function,
  _closeFormModal: Function,
  _updateForm: Function
};

function FormBuilder({
  _createForm,
  openedForm,
  _closeFormModal,
  _updateForm
}: Props) {
  const [isCancelConfirmationModalOpen, setIsCancelConfirmationModalOpen] =
    useState(false);

  const [formData, setFormData] = useState(initialState);

  const {
    fields,
    owners,
    settings: { color: selectedColor },
    title
  } = formData;

  // fill form with clicked form data
  useEffect(() => {
    if (!R.isEmpty(openedForm) && R.prop("fields", openedForm)) {
      setFormData(getFormattedFormData(openedForm));
    }
  }, [openedForm]);

  const addField = ({ index, fieldType }) => {
    const newField = {
      fieldName: "",
      fieldType,
      checkMark: true,
      settings: {}
    };

    if (R.isNil(index)) {
      setFormData(prevFormData => ({
        ...prevFormData,
        fields: [...prevFormData.fields, newField]
      }));
    } else {
      setFormData(prevState => ({
        ...prevState,
        fields: R.insert(index + 1, newField, prevState.fields)
      }));
    }
  };

  const removeField = useCallback(
    (index: number) => {
      setFormData({
        ...formData,
        fields: fields.filter((x, i) => i !== index)
      });
    },
    [formData]
  );

  const onSortEnd = ({
    oldIndex,
    newIndex
  }: {
    oldIndex: number,
    newIndex: number
  }) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      fields: arrayMove(prevFormData.fields, oldIndex, newIndex)
    }));
  };

  const closeModal = () => {
    setIsCancelConfirmationModalOpen(false);
    setFormData(initialState);
    _closeFormModal();
  };

  const cancelHandler = () => {
    // Check if form data changed
    if (!R.isEmpty(openedForm)) {
      const previousFormData = getFormattedFormData(openedForm);

      const hasDataChanged = R.not(R.equals(previousFormData, formData));
      if (hasDataChanged) {
        setIsCancelConfirmationModalOpen(true);
      } else {
        closeModal();
      }
    } else {
      closeModal();
    }
  };

  const handleFormOwners = useCallback(
    (formOwner: UID) => {
      if (R.includes(formOwner, owners)) {
        const newOwners: Array<UID> = R.filter(
          (owner: UID) => owner !== formOwner,
          owners
        );

        setFormData({
          ...formData,
          owners: newOwners
        });
      } else {
        setFormData({
          ...formData,
          owners: [...owners, formOwner]
        });
      }
    },
    [formData, owners]
  );

  const setFormColor = useCallback(
    (color: string) => {
      setFormData({
        ...formData,
        settings: { color }
      });
    },
    [formData]
  );

  const saveForm = useCallback(
    (e: Event) => {
      e.preventDefault();

      const formattedFormData = R.mergeDeepRight(formData, {
        fields: formData.fields.map(field =>
          R.mergeDeepRight(field, {
            settings: JSON.stringify(field.settings)
          })
        )
      });

      if (R.equals(openedForm, {})) {
        _createForm(formattedFormData);
      } else {
        _updateForm(formattedFormData);
      }

      closeModal();
    },
    [formData, openedForm]
  );

  const updateField = ({ index, property, data }) => {
    setFormData(prevState => ({
      ...prevState,
      fields: R.update(
        index,
        {
          ...prevState.fields[index],
          [property]: data
        },
        prevState.fields
      )
    }));
  };

  return (
    <ProcessOverlay>
      <header>
        <Title formTitle={title} setFormData={setFormData} />
      </header>

      <Tabs sx={styles.tabs}>
        <TabList sx={tabListContainer}>
          <Tab sx={tabContainer}>Details</Tab>
          <Tab sx={tabContainer}>Fields</Tab>
        </TabList>

        <TabPanels sx={styles.tabPanels}>
          <TabPanel sx={styles.tabPanel}>
            <DetailsTabPanel
              selectedColor={selectedColor}
              setFormColor={setFormColor}
              owners={owners}
              handleFormOwners={handleFormOwners}
            />
          </TabPanel>

          <TabPanel sx={styles.tabPanel}>
            <FieldsPanel
              items={fields}
              addField={addField}
              removeField={removeField}
              updateField={updateField}
              onSortEnd={onSortEnd}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>

      <StyledFooter>
        <Button variant="uSecondary" onClick={cancelHandler}>
          Exit Form Editor
        </Button>

        <Tooltip
          title="Enter a title for this form"
          hide={formData.title !== ""}
          placement="right"
          bg="red.600"
        >
          <Button
            isDisabled={!title.length}
            variant="uPrimary"
            onClick={saveForm}
          >
            {R.isEmpty(openedForm) ? "Create Form" : "Save Changes"}
          </Button>
        </Tooltip>
      </StyledFooter>

      <AlertModal
        shown={isCancelConfirmationModalOpen}
        onSubmit={closeModal}
        onClose={closeModal}
        title="Exit Form Editor"
        body="Are you sure you want to exit without saving changes?"
        cancelBtnText="Cancel"
        submitBtnText="Exit Form Editor"
      />
    </ProcessOverlay>
  );
}

const mapStateToProps = ({ app }: { app: AppState }) => ({
  openedForm: app.form.openedForm
});

export default connect(mapStateToProps, {
  _createForm: createForm,
  _updateForm: updateForm,
  _closeFormModal: closeFormModal
})(FormBuilder);
