// @flow

import { connect } from "react-redux";
import React, { useState, useRef, useCallback, useEffect } from "react";

import type { WorkflowStatuses } from "src/types";
import type { UpdateStatusFunction } from "src/components/Manage/Builder/Status";

import { Checkbox, Divider, Text } from "@chakra-ui/react";
import SortableComponent from "./Selection";
import {
  SearchInput,
  InputWrapper,
  AddNewButton,
  MainIconWrapper,
  IconWrapper,
  NameWrapper,
  StatusChangeModal,
  StatusChangeIconWrapper,
  StatusChangeText,
  BlueTickIconWrapper
} from "./styles";
import { createStatus, editStatus } from "src/actions/statuses";
import OutsideClickHandler from "src/components/OutsideClickHandler";
import Icons from "src/icons";

type Props = {
  value: Array<number>,
  active: boolean,
  _createStatus: Function,
  _editStatus: Function,
  statuses: WorkflowStatuses,
  handleDeleteStatus: Function,
  updateStatusData: UpdateStatusFunction
};

const Select = ({
  value,
  _createStatus,
  _editStatus,
  handleDeleteStatus,
  statuses,
  updateStatusData
}: Props) => {
  const searchRef = useRef(null);
  const statusEndRef = useRef(null);
  const [active, setActive] = useState(true);
  const [search, setSearch] = useState("");
  const [lockNewStatus, setLockNewStatus] = useState(false);
  const [addButton, setAddButton] = useState(true);
  const [tempName, setTempName] = useState("");
  const [statusChangeModal, setStatusChangeModal] = useState(false);

  const handleSearch = useCallback(
    (e: any) => {
      const { value: newValue } = e.target;
      setSearch(newValue);
    },
    [setSearch]
  );
  const handleStatus = useCallback(() => {
    if (search.length === 0) {
      setAddButton(true);
    } else {
      _createStatus(search, active, lockNewStatus);
    }
  }, [search, active, _createStatus, setAddButton]);

  const handleEditStatus = (statusId: number) => {
    if (search.length === 0) {
      setAddButton(true);
    } else {
      _editStatus(statusId, search);
    }
  };

  const scrollToBottom = () => {
    if (statusEndRef.current) {
      statusEndRef.current.scrollIntoView();
    }
  };

  // scroll to bottom if status is updated
  useEffect(() => {
    scrollToBottom();
  }, [value]);

  const handleKeyDown = useCallback(
    (e: any) => {
      if (e.keyCode === 13) {
        handleStatus();
        setAddButton(true);
      }
    },
    [handleStatus, setAddButton]
  );

  const showInputField = () => {
    setAddButton(false);
    setSearch(""); // clears the state before displaying input
    setTempName("");
  };

  const cancelCreatingStatus = () => {
    setSearch("");
    setAddButton(true);
  };

  const hideInputField = () => {
    handleStatus();
    setAddButton(true);
    setActive(true);
    setStatusChangeModal(false);
  };

  return (
    <div>
      <div>
        <SortableComponent
          value={value}
          statuses={statuses}
          handleDeleteStatus={handleDeleteStatus}
          search={search}
          setSearch={setSearch}
          searchRef={searchRef}
          handleSearch={handleSearch}
          handleEditStatus={handleEditStatus}
          tempName={tempName}
          setTempName={setTempName}
          updateStatusData={updateStatusData}
        />

        <div ref={statusEndRef} />
      </div>

      {addButton ? (
        <AddNewButton type="button" onClick={showInputField}>
          + Create new status
        </AddNewButton>
      ) : (
        <OutsideClickHandler onClickOutside={cancelCreatingStatus}>
          <NameWrapper>
            <InputWrapper>
              <SearchInput
                type="text"
                id="search"
                value={search}
                ref={searchRef}
                placeholder="Enter status title"
                onChange={handleSearch}
                onKeyDown={handleKeyDown}
                autoComplete="off"
                autoFocus
              />

              <BlueTickIconWrapper onClick={hideInputField}>
                {search.length > 0 && <Icons type="blueTick" />}
              </BlueTickIconWrapper>
            </InputWrapper>
            {statusChangeModal && (
              <OutsideClickHandler
                onClickOutside={() => setStatusChangeModal(false)}
              >
                <StatusChangeModal>
                  <StatusChangeIconWrapper
                    onClick={() => {
                      setActive(true);
                    }}
                  >
                    <Icons
                      type={active ? "activeRadioButton" : "defaultRadioButton"}
                    />

                    <StatusChangeText>Map to Pending</StatusChangeText>
                  </StatusChangeIconWrapper>
                  <StatusChangeIconWrapper
                    onClick={() => {
                      setActive(false);
                    }}
                  >
                    <Icons
                      type={
                        !active ? "activeRadioButton" : "defaultRadioButton"
                      }
                    />

                    <StatusChangeText>Map to Complete</StatusChangeText>
                  </StatusChangeIconWrapper>

                  <Divider my={0} />
                  <Checkbox
                    size="sm"
                    isChecked={lockNewStatus}
                    onChange={e => setLockNewStatus(e.target.checked)}
                  >
                    <Text fontSize="xs" my={0}>
                      Disable until approved
                    </Text>
                  </Checkbox>
                </StatusChangeModal>
              </OutsideClickHandler>
            )}
            <MainIconWrapper edit>
              <IconWrapper onClick={cancelCreatingStatus} active={active}>
                <Icons type="deleteTrash" />
              </IconWrapper>
              <IconWrapper
                active={active}
                onClick={() => setStatusChangeModal(true)}
              >
                <Icons type="circleDown" />
              </IconWrapper>
            </MainIconWrapper>
          </NameWrapper>
        </OutsideClickHandler>
      )}
    </div>
  );
};

export default connect(null, {
  _createStatus: createStatus,
  _editStatus: editStatus
})(Select);
