// @flow

import React, { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { Text } from "@unifize/sarah";

import tileColors from "src/styles/constants/tileColors";
import { setTileEmptyStatus } from "src/actions/homescreen";

import { Tile as StyledTile } from "../styles";
import { Reports as StyledReports } from "./styles";
import ReportLink from "./ReportLink";

import type { HomescreenTile } from "src/types";
type Props = {
  tile: HomescreenTile,
  sectionId: number
};

const Reports = ({ tile, sectionId }: Props) => {
  const dispatch = useDispatch();
  const colorScheme = tileColors[tile.settings.tileColor];

  const isTileHidden = useMemo(() => {
    return (
      !tile.settings?.displayEmpty &&
      (tile.settings?.reports == undefined ||
        tile.settings?.reports?.length === 0)
    );
  }, [tile.settings]);

  useEffect(() => {
    dispatch(setTileEmptyStatus(sectionId, tile.id, isTileHidden));
  }, [tile.settings?.reports?.length, isTileHidden]);

  if (isTileHidden) {
    return <></>;
  }

  return (
    <StyledTile color={tile.settings.tileColor}>
      {tile.settings?.reports?.length ? (
        <>
          <Text variant="body" weight="bold" color={colorScheme.title}>
            {tile.title}
          </Text>
          <StyledReports color={colorScheme.secondaryText}>
            {tile.settings.reports.map((report, index) => (
              <ReportLink
                key={`homescreenReport${report}-${index}`}
                reportId={report}
                color={colorScheme.secondaryText}
              />
            ))}
          </StyledReports>
        </>
      ) : (
        <>
          <Text variant="body" weight="bold" color={colorScheme.title}>
            {tile.settings.emptyMessage?.title || ""}
          </Text>
          <Text variant="caption" color={colorScheme.subTitle}>
            {tile.settings.emptyMessage?.subTitle || ""}
          </Text>
        </>
      )}
    </StyledTile>
  );
};

export default Reports;
