// @flow

import React from "react";

type Props = {
  size?: number
};

const DownIcon = ({ size = 20 }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={`${size}px`}
    width={`${size}px`}
    viewBox="0 -960 960 960"
    fill="#000"
  >
    <path d="M480-333 240-573l51-51 189 189 189-189 51 51-240 240Z" />
  </svg>
);

export default DownIcon;
