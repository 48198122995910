// @flow

import React from "react";
import { connect } from "react-redux";

import { Flex, ListItem, IconButton } from "@chakra-ui/react";
import { EditIcon, DeleteIcon } from "@chakra-ui/icons";
import Bookmark from "src/icons/Bookmark";
import { setFilter } from "src/actions/filter";
import { hideFilterModal } from "src/actions/header";
import { showView } from "src/actions";

type Props = {
  name: string,
  _setFilter: Function,
  _hideFilterModal: Function,
  customPinned: Object,
  type: String,
  handleDelete: Function,
  emptySearchField: Function,
  _showView: Function,
  handleEdit: Function
};

const PinnedListItem = ({
  name,
  _setFilter,
  _hideFilterModal,
  customPinned,
  type,
  handleDelete,
  emptySearchField,
  _showView,
  handleEdit
}: Props) => {
  const handleSelect = customPinnedItem => {
    _setFilter(customPinnedItem.filter, type, name, true);
    _showView(name);
    _hideFilterModal();
    emptySearchField();
  };

  return (
    <ListItem
      py="2"
      px="3"
      _hover={{
        background: "gray.100"
      }}
      onClick={() => handleSelect(customPinned)}
      cursor="pointer"
    >
      <Flex
        justifyContent="space-between"
        _hover={{
          button: {
            visibility: "visible"
          }
        }}
      >
        <Flex alignItems="center">
          <Bookmark mr={2} h={5} w={5} />
          <strong>{name}</strong>
        </Flex>
        <Flex>
          <IconButton
            bg="inherit"
            size="xs"
            onClick={e => handleEdit(e, customPinned)}
            icon={<EditIcon />}
            visibility="hidden"
          />

          <IconButton
            bg="inherit"
            size="xs"
            onClick={e => handleDelete(e, customPinned)}
            icon={<DeleteIcon />}
            visibility="hidden"
          />
        </Flex>
      </Flex>
    </ListItem>
  );
};

export default connect(null, {
  _setFilter: setFilter,
  _hideFilterModal: hideFilterModal,
  _showView: showView
})(PinnedListItem);
