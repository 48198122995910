// @flow

import React, { useRef, useEffect, useState } from "react";

import Icon from "src/icons";
import PathLabels from "./PathLabels";

import { getFormattedPath } from "src/utils/language";
import { PathContainer } from "../styles";

import type { Area as AreaType } from "src/types";

type Props = {
  cellData: Array<string>,
  area: AreaType
};

const Path = ({ cellData, area }: Props) => {
  const containerRef = useRef();
  const [isOverflowing, setIsOverflowing] = useState(false);

  const getIcon = () => {
    switch (area) {
      case "form":
        return <Icon type="form" style={{ width: "16px", height: "16px" }} />;
      case "home":
        return (
          <Icon type="homeArea" style={{ width: "16px", height: "16px" }} />
        );
      case "report":
        return <Icon type="report" style={{ width: "16px", height: "16px" }} />;
      case "process":
        return (
          <Icon type="process" style={{ width: "16px", height: "16px" }} />
        );
      case "dashboard":
        return (
          <Icon
            type="dashboardArea"
            style={{ width: "16px", height: "16px" }}
          />
        );
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      const totalWidth = Array.from(container.children).reduce((acc, child) => {
        return acc + child.scrollWidth;
      }, 0);
      setIsOverflowing(totalWidth > container.clientWidth);
    }
  }, [JSON.stringify(cellData)]);

  const formattedPath =
    cellData.length > 2 ? getFormattedPath(cellData) : cellData;

  const firstElement = formattedPath[0];
  const lastElement = formattedPath[formattedPath.length - 1];
  const middleElements = formattedPath.slice(1, -1);

  return (
    <PathContainer ref={containerRef}>
      <div style={{ flexShrink: 0, paddingRight: "0.25rem" }}>{getIcon()}</div>
      <PathLabels
        isOverflowing={isOverflowing}
        firstElement={firstElement}
        lastElement={lastElement}
        middleElements={middleElements}
      />
    </PathContainer>
  );
};

export default Path;
