// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";
import Link from "redux-first-router-link";
import moment from "moment";

import {
  Header,
  Navigation as StyledNavigation,
  InActive,
  Active
} from "./styles";
import Close from "src/containers/Close";
import * as atypes from "src/constants/actionTypes";

type Props = {
  tab: string
};

const lastWeek = `${moment()
  .subtract(7, "days")
  .startOf("day")
  .format("YYYY-MM-DD")}::${moment().startOf("day").format("YYYY-MM-DD")}`;

const Navigation = ({ tab }: Props) => (
  <StyledNavigation>
    <Header>{i18n.t(k.MANAGE)}</Header>

    {tab !== "workflow" ? (
      <InActive>
        <Link to={{ type: atypes.SET_PROCESS_REQUEST, payload: {} }}>
          {i18n.t(k.PROCESSES)}
        </Link>
      </InActive>
    ) : (
      <Active>{i18n.t(k.PROCESSES)}</Active>
    )}

    {tab !== "user-analytics" ? (
      <InActive>
        <Link
          to={{
            type: atypes.SET_USER_ANALYTICS_REQUEST,
            payload: {},
            meta: {
              query: {
                createdAt: lastWeek,
                completedAt: lastWeek,
                sort: ["pendingUnread:desc"]
              }
            }
          }}
        >
          {i18n.t(k.USER_ANALYTICS1)}
        </Link>
      </InActive>
    ) : (
      <Active>{i18n.t(k.USER_ANALYTICS1)}</Active>
    )}

    {tab !== "user-reports" ? (
      <InActive>
        <Link
          to={{
            type: atypes.SET_REPORTS_REQUEST,
            payload: {}
          }}
        >
          {i18n.t(k.REPORTS)}
        </Link>
      </InActive>
    ) : (
      <Active>{i18n.t(k.REPORTS)}</Active>
    )}
    {tab !== "forms" ? (
      <InActive>
        <Link
          to={{
            type: atypes.SET_FORMS_REQUEST,
            payload: {}
          }}
        >
          {i18n.t(k.FORMS)}
        </Link>
      </InActive>
    ) : (
      <Active>{i18n.t(k.FORMS)}</Active>
    )}
    <Close />
  </StyledNavigation>
);

export default Navigation;
