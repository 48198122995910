// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import Link from "redux-first-router-link";
import React from "react";
import { useSelector } from "react-redux";
import NoFieldsIcon from "src/icons/NoFieldsIcon";

import * as atypes from "src/constants/actionTypes";
import { getTemplateId } from "src/reducers";

import { NoFieldsContainer } from "./styles";
import * as styles from "./styles";
import type { RoomId } from "src/types";

type Props = {
  roomId: RoomId
};

const NoFields = ({ roomId }: Props) => {
  const templateId = useSelector(({ app }) => getTemplateId(app, `${roomId}`));
  return (
    <NoFieldsContainer>
      <NoFieldsIcon />
      <Link
        to={{
          type: atypes.EDIT_PROCESS_REQUEST,
          payload: { id: templateId },
          meta: {
            query: {
              tab: "Checklist"
            }
          }
        }}
        style={styles.Link}
      >
        {i18n.t(k.ADD_NEW_FIELDS)}
      </Link>
    </NoFieldsContainer>
  );
};

export default NoFields;
