// @flow

import React from "react";

import { StyledHeader } from "./styles";

type Props = {
  label: string
};

const Header = ({ label }: Props) => {
  return (
    <StyledHeader>
      <span>{label}</span>
    </StyledHeader>
  );
};

export default Header;
