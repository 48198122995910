// @flow

import React from "react";

import Type from "src/components/Settings/Type";
import LanguageSwitcher from "./LanguageSwitcher";

import { NotificationSettingsContainer } from "./styles";

type Props = {
  activeIndex: number
};

const PreferencesContent = ({ activeIndex }: Props) => {
  switch (activeIndex) {
    case 0:
      return (
        <NotificationSettingsContainer>
          <Type />
        </NotificationSettingsContainer>
      );
    default:
      return <LanguageSwitcher />;
  }
};

export default PreferencesContent;
