// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";
import { Options as StyledOptions, SortItem } from "src/styles/table";

type Props = {
  sortAsc: Function,
  sortDesc: Function
};

const SortBy = ({ sortAsc, sortDesc }: Props) => {
  return (
    <>
      <span>{i18n.t(k.SORT_BY2)}</span>
      <ul>
        <SortItem onClick={sortAsc}>{i18n.t(k.ASCENDING)}</SortItem>
        <SortItem onClick={sortDesc}>{i18n.t(k.DESCENDING)}</SortItem>
      </ul>
    </>
  );
};

export default SortBy;
