// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { toast } from "react-toastify";

import {
  HStack,
  VStack,
  Box,
  Text,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Portal,
  Skeleton,
  Center
} from "@chakra-ui/react";
import { downloadFile, getFilePreview } from "src/actions/file";
import { getFilePreviewURL, getUser } from "src/reducers";
import { getSharepointEditUrl } from "src/api/file";

import {
  getFileType,
  getFileSize,
  getFilePreviewSupport,
  getFileSourceDisplayName
} from "src/utils/file";
import FilePreview from "src/components/messages/Chat/Notification/FileOrEmail/FilePreview";
import useFileViewer from "src/hooks/useFileViewer";
import FileThumbnail from "../FileThumbnail";
import Tooltip from "src/components/Tooltip";
import Modal from "../Modal";
import ActionsBar from "./ActionsBar";

import {
  FileInfo,
  FileThumbnail as FileThumbnailWrapper,
  FileIconWrapper
} from "../../styles";

import Icons from "src/icons";
import appLocation from "src/constants/location";
import * as styles from "./styles";
import * as colors from "src/styles/constants/colors";
import type { AppState, RoomId } from "src/types";

type Props = {
  roomId: RoomId,
  originalName: string,
  handleRemove: Function,
  file: Object,
  preview: boolean,
  handleDelete: Function,
  modal: boolean,
  disabled: boolean,
  filePreview: ?Object,
  location?: string,
  handleClose?: Function
};

const SingleFile = ({
  roomId,
  originalName,
  handleRemove,
  file,
  preview,
  handleDelete,
  modal,
  disabled,
  filePreview,
  location,
  handleClose
}: Props) => {
  const fileSource = file.source || null;
  const fileSourceDisplayName = getFileSourceDisplayName(fileSource);
  const hasPreviewSupport = getFilePreviewSupport(file);
  const [fetchingEditUrl, setFetchingEditUrl] = useState(false);
  const name = file.name;
  const size = file.size;
  const time = file.time;

  const dispatch = useDispatch();

  const [isConfirmationOpen, setConfirmation] = useState(false);
  const { data, openFile } = useFileViewer({ file, modal, roomId });

  useEffect(() => {
    if (
      preview &&
      hasPreviewSupport &&
      (!filePreview || moment().diff(filePreview?.date, "minutes") > 10) &&
      name
    ) {
      dispatch(getFilePreview({ name, roomId }));
    }
  }, [name, file, filePreview, roomId, fileSource]);

  const openModal = (e: Event) => {
    e.stopPropagation();

    if (disabled) return;

    setConfirmation(true);
  };

  const handleDownload = e => {
    e.stopPropagation();

    if (file) {
      dispatch(
        downloadFile({
          roomId,
          location: "Checklist",
          name: file.name,
          originalName: file.originalName,
          generation: file.generation,
          versionId: file.versionId
        })
      );
    }
  };

  const handleEditSharepointFile = async e => {
    e.stopPropagation();
    setFetchingEditUrl(true);
    try {
      const response = await getSharepointEditUrl({ name: file.name, roomId });

      if (response && response.url) {
        window.open(response.url);
        setFetchingEditUrl(false);
      }
    } catch (error) {
      if (error.status === 403) {
        toast.error("Not authorized with Microsoft 365");
      }
      if (error.status === 400) {
        toast.error("Integration is not enabled");
      }
      setFetchingEditUrl(false);
      console.error(error);
    }
  };

  const handleFileOpen = e => {
    e.stopPropagation();
    openFile();
    handleClose && handleClose();
  };

  const fileOwner = useSelector(({ app }) => getUser(app, file.owner));
  const uploadedTime = moment(time).format("MMM DD, YYYY");
  const fileName = file?.originalName || originalName;
  const fileSize = file?.size || size;
  const fileUploadedTime = file?.uploadTime
    ? moment(file?.uploadTime).format("MMM DD, YYYY")
    : uploadedTime;

  if (!file && !originalName) {
    return null;
  }

  if (typeof preview === "boolean" && !preview) {
    return (
      <>
        {!isConfirmationOpen ? (
          <Tooltip title={fileName} placement="left">
            <Box
              role="group"
              disabled={disabled}
              sx={{
                border: "1px solid",
                borderColor: "unifize_inkLightest",
                borderRadius: "2px",
                width: "100%",
                marginBottom: "0.5rem",
                height: "2.67em",
                p: "0 0.67em",
                display: "inline-grid",
                gridTemplateColumns: "auto auto",
                gap: 4,
                alignItems: "center",
                justifyContent: "space-between"
              }}
            >
              <HStack {...styles.compressedView.fileName}>
                <FileIconWrapper>
                  <Icons type="fileIcons" icon={getFileType(fileName)} />
                </FileIconWrapper>

                <Text {...styles.compressedView.fileName}>{fileName}</Text>
              </HStack>

              <ActionsBar
                openFile={openFile}
                fetchingFileUrl={data.fetching}
                fetchingEditUrl={fetchingEditUrl}
                disabled={disabled}
                fileSource={fileSource}
                handleEditSharepointFile={handleEditSharepointFile}
                handleDownload={handleDownload}
                openModal={openModal}
              />
            </Box>
          </Tooltip>
        ) : (
          <Modal
            isOpen={isConfirmationOpen}
            name={name}
            fileSource={fileSource}
            handleRemove={handleRemove}
            handleDelete={handleDelete}
            setConfirmation={setConfirmation}
            filename={fileName}
          />
        )}
      </>
    );
  }

  return (
    <>
      {!isConfirmationOpen ? (
        <Popover trigger="hover" placement="left-start">
          <HStack
            onClick={openFile}
            disabled={disabled}
            spacing={2}
            role="group"
            sx={{
              ...styles.ExpandedViewContainer,
              borderColor:
                location === appLocation.filesModal
                  ? colors.sky
                  : "unifize_inkLightest",

              marginBottom:
                location === appLocation.filesModal ? "0px" : "0.5rem"
            }}
          >
            <PopoverTrigger>
              <FileThumbnailWrapper>
                <FileThumbnail
                  roomId={roomId}
                  file={file}
                  originalName={fileName}
                />
              </FileThumbnailWrapper>
            </PopoverTrigger>

            <VStack {...styles.expandedView.fileData} data-cy="fileContainer">
              <Box
                sx={{
                  display: "inline-grid",
                  gridTemplateColumns: "auto auto",
                  justifyContent: "space-between",
                  gap: 4,
                  width: "100%"
                }}
              >
                <Text data-cy="fileName" {...styles.expandedView.fileName}>
                  {fileName}
                </Text>

                <ActionsBar
                  openFile={handleFileOpen}
                  fetchingFileUrl={data.fetching}
                  fetchingEditUrl={fetchingEditUrl}
                  disabled={disabled}
                  fileSource={fileSource}
                  handleEditSharepointFile={handleEditSharepointFile}
                  handleDownload={handleDownload}
                  openModal={openModal}
                />
              </Box>
              {location === appLocation.filesModal ? (
                <>
                  <HStack>
                    <FileInfo>{getFileSize(fileSize)}</FileInfo>
                    <Text as="span" fontSize="xs">
                      {i18n.t(k._11)}
                    </Text>
                    <FileInfo>
                      {i18n.t(k.UPLOADED_ON)} {fileUploadedTime || ""}
                    </FileInfo>
                  </HStack>
                  <FileInfo as="span">{fileOwner.displayName}</FileInfo>
                </>
              ) : (
                <>
                  <FileInfo>{getFileSize(fileSize)}</FileInfo>
                  <FileInfo>
                    {i18n.t(k.UPLOADED_ON)} {fileUploadedTime || ""}
                  </FileInfo>
                </>
              )}
            </VStack>
          </HStack>

          <Portal>
            <PopoverContent {...styles.popover.content}>
              <PopoverBody {...styles.popover.body}>
                <Text {...styles.popover.fileName}>{fileName}</Text>

                <HStack {...styles.popover.fileData}>
                  <Text>{getFileSize(fileSize)}</Text>
                  <Text>{i18n.t(k._11)}</Text>
                  <Text>
                    {i18n.t(k.UPLOADED_ON)} {fileUploadedTime}
                  </Text>
                  {fileSourceDisplayName && (
                    <>
                      <Text>{i18n.t(k._11)}</Text>
                      <Text>{fileSourceDisplayName}</Text>
                    </>
                  )}
                </HStack>

                {!hasPreviewSupport && (
                  <Center {...styles.popover.noPreview.container}>
                    <Text {...styles.popover.noPreview.text}>
                      {i18n.t(k.NO_PREVIEW_AVAILABLE)}
                    </Text>
                  </Center>
                )}

                {hasPreviewSupport &&
                  (filePreview?.url ? (
                    <FilePreview preview={filePreview?.url} />
                  ) : (
                    <Skeleton height="372px" width="100%" />
                  ))}
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </Popover>
      ) : (
        <Modal
          isOpen={isConfirmationOpen}
          name={name}
          fileSource={fileSource}
          handleRemove={handleRemove}
          handleDelete={handleDelete}
          setConfirmation={setConfirmation}
          filename={fileName}
          location={location}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ app }: { app: AppState }, { file }) => ({
  modal: app.conversationModal.show,
  filePreview: getFilePreviewURL(app, file.name)
});

export default connect(mapStateToProps)(SingleFile);
