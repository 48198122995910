// @flow

import React, { useCallback } from "react";
import { connect } from "react-redux";
import * as R from "ramda";

import Description from "./Description";
import Owner from "./Owner";
import Members from "./Members";
import ProcessOwners from "./ProcessOwners";
import { FormBody, Form, Template as StyledTemplate } from "./styles";
import type {
  AppState,
  WorkflowBuilderDialog,
  UID,
  GroupId,
  Email
} from "src/types";
import {
  setWorkflowBuiderAttributes,
  setWorkflowBuiderAttribute,
  createWorkflow,
  editWorkflow
} from "src/actions/workflows";

type Props = {
  workflowBuilder: WorkflowBuilderDialog,
  setWorkflowBuiderAttributes: Function,
  setWorkflowBuiderAttribute: Function,
  editWorkflow: Function,
  createWorkflow: Function
};

const Template = ({
  workflowBuilder,
  setWorkflowBuiderAttributes,
  setWorkflowBuiderAttribute
}: Props) => {
  const handleChange = useCallback(
    (e: any) => {
      const { id, value } = e.target;
      setWorkflowBuiderAttributes({ [id]: value });
    },
    [setWorkflowBuiderAttributes]
  );

  const handleOwner = useCallback(
    (value: ?UID) => {
      const { members } = workflowBuilder;

      if (value) {
        setWorkflowBuiderAttributes({
          owner: value,
          members: R.uniq([value, ...(members || [])])
        });
      } else {
        setWorkflowBuiderAttributes({ owner: value });
      }
    },
    [workflowBuilder, setWorkflowBuiderAttributes]
  );

  const handleMembers = useCallback(
    (member: UID | Email) => {
      const { members } = workflowBuilder;

      if (R.includes(member, members)) {
        setWorkflowBuiderAttribute({
          id: "members",
          value: R.reject(R.equals(member), members)
        });
      } else {
        setWorkflowBuiderAttribute({
          id: "members",
          value: [...members, member]
        });
      }
    },
    [workflowBuilder, setWorkflowBuiderAttribute]
  );

  const handleGroups = useCallback(
    (group: GroupId) => {
      const { groups = [] } = workflowBuilder;
      if (R.includes(group, groups)) {
        setWorkflowBuiderAttribute({
          id: "groups",
          value: R.reject(R.equals(group))(groups)
        });
      } else {
        setWorkflowBuiderAttribute({
          id: "groups",
          value: [...groups, group]
        });
      }
    },
    [workflowBuilder, setWorkflowBuiderAttribute]
  );

  const handleProcessOwners = useCallback(
    (processOwner: UID) => {
      const { processOwners, privacySettings } = workflowBuilder;

      const whitelist = (privacySettings || {}).whitelist || [];

      if (R.includes(processOwner, processOwners)) {
        setWorkflowBuiderAttributes({
          processOwners: R.reject(R.equals(processOwner), processOwners)
        });
      } else if (R.includes(processOwner, whitelist)) {
        setWorkflowBuiderAttributes({
          processOwners: [...processOwners, processOwner]
        });
      } else {
        setWorkflowBuiderAttributes({
          processOwners: [...processOwners, processOwner],
          privacySettings: {
            whitelist: [...whitelist, processOwner]
          }
        });
      }
    },
    [workflowBuilder, setWorkflowBuiderAttributes]
  );

  const {
    description,
    owner,
    members,
    processOwners,
    privacy,
    groups = []
  } = workflowBuilder;
  return (
    <StyledTemplate>
      <Form>
        <FormBody>
          <Description description={description} handleChange={handleChange} />
          <Owner owner={owner} handleOwner={handleOwner} />
          <Members
            members={members}
            groups={groups}
            handleMembers={handleMembers}
            handleGroups={handleGroups}
          />

          <ProcessOwners
            privacy={privacy}
            processOwners={processOwners}
            handleProcessOwners={handleProcessOwners}
          />
        </FormBody>
      </Form>
    </StyledTemplate>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  workflowBuilder: app.workflow.builderDialog
});

export default connect(mapStateToProps, {
  setWorkflowBuiderAttributes,
  setWorkflowBuiderAttribute,
  editWorkflow,
  createWorkflow
})(Template);
