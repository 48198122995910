// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React, { useCallback } from "react";
import { connect } from "react-redux";

import type { CurrentFilter, State } from "src/types";
import { updateFilter } from "src/actions/filter";
import { getCurrentFilter } from "src/reducers";
import { fetchChatroomUpdates } from "src/actions/chatroom";

import { RoundButton } from "./styles";
import { blue } from "src/styles/constants/colors";
import Icons from "src/icons";

type Props = {
  showCancelled: boolean,
  updateFilter: Function,
  fetchChatroomUpdates: Function,
  currentFilter: CurrentFilter,
  disabled: boolean
};

const Cancelled = ({
  showCancelled,
  updateFilter,
  currentFilter,
  fetchChatroomUpdates,
  disabled
}: Props) => {
  const toggleCancelFilter = useCallback(() => {
    if (showCancelled) {
      updateFilter({
        ...currentFilter,
        cancelled: false
      });
      fetchChatroomUpdates();
    } else {
      updateFilter({
        ...currentFilter,
        cancelled: true
      });
    }
  }, [updateFilter, showCancelled, currentFilter]);

  return (
    <RoundButton
      type="button"
      onClick={toggleCancelFilter}
      onKeyPress={toggleCancelFilter}
      active={showCancelled}
      disabled={disabled}
    >
      <Icons type="status" color={blue} />
      {i18n.t(k.CANCELLED)}
    </RoundButton>
  );
};

const mapStateToProps = ({ app }: State) => ({
  showCancelled: app.chatRooms.filters.current.cancelled,
  disabled: app.chatRooms.filters.current.unread,
  currentFilter: getCurrentFilter(app)
});

const mapDispatchToProps = {
  updateFilter,
  fetchChatroomUpdates
};

export default connect(mapStateToProps, mapDispatchToProps)(Cancelled);
