// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React, { useCallback } from "react";
import * as R from "ramda";
import onClickOutside from "react-onclickoutside";

import { ListItem, List as StyledList, Add } from "./styles";

import type { Department } from "src/types";

type Props = {
  search: string,
  departments: Array<Department>,
  handleSelect: Function,
  handleClose: Function,
  handleAdd: Function,
  outerRef: any
};

const List = ({
  search,
  departments,
  outerRef,
  handleClose,
  handleAdd,
  handleSelect
}: Props) => {
  List.handleClickOutside = useCallback((e: any) => {
    if (outerRef) {
      if (!outerRef.current.contains(e.target)) {
        handleClose();
      }
    }
  }, []);

  const result = R.filter(
    d => R.includes(R.toLower(search || ""), R.toLower(d.name || "")),
    departments
  );

  return (
    <StyledList>
      <Add
        role="button"
        tabIndex="0"
        onClick={handleAdd}
        onKeyPress={handleAdd}
      >
        {i18n.t(k.ADD_A_DEPARTMENT)}
      </Add>
      {result.map(d => (
        <ListItem
          key={d.id}
          role="button"
          tabIndex="0"
          onClick={() => handleSelect(d)}
          onKeyPress={() => handleSelect(d)}
        >
          {d.name}
        </ListItem>
      ))}
    </StyledList>
  );
};

const clickOutsideConfig = {
  handleClickOutside: () => List.handleClickOutside
};

export default onClickOutside(List, clickOutsideConfig);
