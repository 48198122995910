// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React, { useState } from "react";
import { WarningIcon } from "@chakra-ui/icons";

import AddButton from "src/components/Buttons/ChakraButton";
import ButtonLoader from "src/components/Dock/Checklist/Conversation/Loader";
import { InteractiveToolTip } from "src/components/Unifize";
import CreatorsModal from "src/components/Dock/Checklist/Revision/CreatorsModal";

import {
  useBoolean,
  Text,
  Button,
  Modal,
  ModalContent,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  HStack,
  FormControl,
  FormLabel,
  Textarea
} from "@chakra-ui/react";

import type { RoomId, FieldId, ApprovalFieldSettingsV2 } from "src/types";
import type { HandleCreateRevision } from "./index";

type Props = {
  signatureCount: number,
  isCommentRequired: boolean,
  createRevision: HandleCreateRevision,
  disabled: boolean,
  updating: boolean,
  roomId: RoomId,
  fieldId: FieldId,
  settings: ApprovalFieldSettingsV2
};

function Revision({
  createRevision,
  disabled,
  updating,
  isCommentRequired,
  signatureCount,
  fieldId,
  roomId,
  settings
}: Props) {
  const [showCancellers, setShowCancellers] = useState(false);
  const [comment, setComment] = useState("");
  const [isModalOpen, setModalVisibility] = useBoolean();

  const label =
    signatureCount > 0 ? i18n.t(k.CANCEL_APPROVAL) : i18n.t(k.CANCEL_REQUEST);

  const handleCreateRevision = () => {
    if (comment) {
      createRevision(comment);
    } else {
      createRevision();
    }

    setModalVisibility.off();
  };

  return (
    <>
      {updating ? (
        <ButtonLoader />
      ) : (
        <InteractiveToolTip
          isShown={disabled}
          onClick={() => setShowCancellers(true)}
          message={i18n.t(k.YOU_ARE_NOT_AUTHORIZED_CLICK)}
        >
          <AddButton
            onClick={setModalVisibility.on}
            isDisabled={disabled}
            variant="add"
            rootProps={{ width: "100%" }}
            rightIcon={disabled && <WarningIcon w={4} h={4} color="red.500" />}
          >
            {label}
          </AddButton>
        </InteractiveToolTip>
      )}

      {showCancellers && (
        <CreatorsModal
          title={i18n.t(k.APPROVAL_PERMISSIONS)}
          subtitle={i18n.t(k.APPROVAL_CAN_BE_CANCELLED_BY)}
          emptySubtitle={i18n.t(k.APPROVAL_SETTINGS_NOT_CONFIGURED)}
          isOpen={showCancellers}
          onClose={() => setShowCancellers(false)}
          fieldId={fieldId}
          roomId={roomId}
          settings={settings}
          path="cancellers"
        />
      )}

      <Modal isOpen={isModalOpen} onClose={setModalVisibility.off} isCentered>
        <ModalOverlay />

        <ModalContent>
          <ModalHeader fontWeight={700} fontSize="lg">
            {label}
          </ModalHeader>

          <ModalBody>
            <Text my={0}>{i18n.t(k.ARE_YOU_SURE_YOU_WANT_TO_CANCE)}</Text>

            {isCommentRequired && (
              <>
                <br />
                <FormControl isRequired>
                  <FormLabel>{i18n.t(k.PLEASE_ENTER_A_COMMENT)}</FormLabel>
                  <Textarea
                    value={comment}
                    onChange={e => setComment(e.target.value)}
                    placeholder={i18n.t(k.ENTER_COMMENT_HERE)}
                    resize="vertical"
                  />
                </FormControl>
              </>
            )}
          </ModalBody>

          <ModalFooter>
            <HStack spacing={4}>
              <Button variant="uSecondary" onClick={setModalVisibility.off}>
                {i18n.t(k.NO)}
              </Button>

              <Button
                variant="uDanger"
                onClick={handleCreateRevision}
                isDisabled={isCommentRequired && !comment}
              >
                {i18n.t(k.YES)}
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default Revision;
