// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";
import { connect } from "react-redux";

import Common from "./Common";
import LinkedRoom from "./LinkedRoom";
import { getChatroomAddress } from "src/reducers";
import * as notifications from "src/constants/notifications";
import type { Message, ChatroomAddress } from "src/types";

type Props = {
  message: Message,
  roomAddress: ChatroomAddress,
  oldAddress: ChatroomAddress,
  newAddress: ChatroomAddress
};

const Parent = ({ message, roomAddress, oldAddress, newAddress }: Props) => {
  const { data, action } = message;
  const { roomId } = data;
  const { old: oldParent, new: newParent } = data;
  let notificationMessage = i18n.t(k._4);
  let id = i18n.t(k._4);
  let address = i18n.t(k._4);
  switch (action) {
    case notifications.PARENT_ADD:
      notificationMessage = i18n.t(k.ADDED_CHILD_CONVERSATION);
      id = roomId;
      address = roomAddress;
      break;
    case notifications.PARENT_REMOVE:
      notificationMessage = i18n.t(k.REMOVED_CHILD_CONVERSATION);
      id = roomId;
      address = roomAddress;
      break;
    case notifications.PARENT_UPDATE:
      id = oldParent || newParent;
      address = oldParent ? oldAddress : newAddress;
      notificationMessage = oldParent
        ? "removed parent conversation"
        : "added parent conversation";
      break;
    default:
      break;
  }
  return (
    <Common {...message}>
      {" "}
      {notificationMessage} <LinkedRoom id={id} chatroomAddress={address} />
    </Common>
  );
};

const mapStateToProps = ({ app }, { message }) => ({
  roomAddress: getChatroomAddress(app, message.data.roomId),
  oldAddress: getChatroomAddress(app, message.data.old),
  newAddress: getChatroomAddress(app, message.data.new)
});

export default connect(mapStateToProps)(Parent);
