// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import { connect } from "react-redux";
import React, { useCallback } from "react";

import {
  Option,
  OptionDanger,
  TypeHeader,
  SubHeader,
  List,
  Type as StyledType
} from "./styles";
import Radio from "src/components/Radio";
import { setNotificationSettings } from "src/actions/settings";

import type { AppState } from "src/types";

type Props = {
  notification: number,
  _setNotificationSettings: Function
};

const Type = ({ notification, _setNotificationSettings }: Props) => {
  const handleType = useCallback(
    (id: number) => {
      if (notification !== id) {
        _setNotificationSettings(id);
      }
    },
    [notification, _setNotificationSettings]
  );

  return (
    <StyledType>
      <Option selected={notification === 1} onClick={() => handleType(1)}>
        <Radio
          id="everything"
          checked={notification === 1}
          handleChange={() => {}}
        />

        <div>
          <TypeHeader>
            <strong>{i18n.t(k.EVERYTHING)} </strong>
            {i18n.t(k.ALL_UPDATES_AND_MESSAGES)}
          </TypeHeader>
          <SubHeader>
            <strong>{i18n.t(k.FOR_CONVERSATIONS_YOU_ARE_A_PA)}</strong>
            {i18n.t(k.YOU_WILL_BE_NOTI)}
          </SubHeader>
          <List>
            <li>
              <strong>{i18n.t(k.ALL_COMMENTS)}</strong> {i18n.t(k.AND_MESSAGES)}
            </li>
            <li>
              {i18n.t(k.WHEN)} <strong>{i18n.t(k.YOU_VE_BEEN_ADDED)}</strong>{" "}
              {i18n.t(k.TO_A_CONVERSATION)}
            </li>
            <li>
              <strong>{i18n.t(k.ALL_STATUS_UPDATES)}</strong>{" "}
              {i18n.t(k.INCLUDING_WHEN_CONVERSATIONS)}
            </li>
            <li>
              {i18n.t(k.WHEN_THE_CONVERSATION_IS)}{" "}
              <strong>{i18n.t(k.OVERDUE1)}</strong> {i18n.t(k.AND_OTHER)}{" "}
              <strong>{i18n.t(k.REMINDERS)}</strong>
            </li>
            <li>
              <strong>{i18n.t(k.ANY_OWNER_CHANGE)}</strong>{" "}
              {i18n.t(k.OR_ASSIGNMENT)}
            </li>
            <li>
              {i18n.t(k.WHEN_YOU_VE_BEEN)}{" "}
              <strong>{i18n.t(k.MENTIONED)}</strong>{" "}
              {i18n.t(k.IN_A_CONVERSATION)}
            </li>
          </List>
        </div>
      </Option>

      <Option selected={notification === 2} onClick={() => handleType(2)}>
        <Radio
          id="important"
          checked={notification === 2}
          handleChange={() => {}}
        />

        <div>
          <TypeHeader>
            <strong>{i18n.t(k.IMPORTANT)} </strong>
            {i18n.t(k.STATUS_UPDATES_REMINDERS_AND)}
          </TypeHeader>
          <SubHeader>
            <strong>{i18n.t(k.FOR_CONVERSATIONS_YOU_ARE_A_PA1)}</strong>{" "}
            {i18n.t(k.YOU_WILL_BE_NOTIFI)}
          </SubHeader>
          <List>
            <li>
              <strong>{i18n.t(k.ALL_STATUS_UPDATES1)}</strong>
              {i18n.t(k.INCLUDING_WHEN_CONVERSATIONS1)}
            </li>
            <li>
              {i18n.t(k.WHEN)} <strong>{i18n.t(k.YOU_VE_BEEN_ADDED)}</strong>{" "}
              {i18n.t(k.TO_A_CONVERSATION)}
            </li>
            <li>
              {i18n.t(k.WHEN_THE_CONVERSATION_IS)}{" "}
              <strong>{i18n.t(k.OVERDUE1)}</strong> {i18n.t(k.AND_OTHER_REMIND)}
            </li>
            <li>
              <strong>{i18n.t(k.ANY_OWNER_CHANGE)}</strong>{" "}
              {i18n.t(k.OR_ASSIGNMENT)}
            </li>
            <li>
              {i18n.t(k.WHEN_YOU_VE_BEEN)}{" "}
              <strong>{i18n.t(k.MENTIONED)}</strong>{" "}
              {i18n.t(k.IN_A_CONVERSATION)}
            </li>
          </List>
        </div>
      </Option>

      <Option selected={notification === 3} onClick={() => handleType(3)}>
        <Radio
          id="critical"
          checked={notification === 3}
          handleChange={() => {}}
        />

        <div>
          <TypeHeader>
            <strong>{i18n.t(k.CRITICAL1)}</strong>{" "}
            {i18n.t(k.CRITICAL_UPDATES_AND_MENTIONS)}
          </TypeHeader>
          <SubHeader>
            <strong>{i18n.t(k.FOR_CONVERSATIONS_YOU_ARE_A_PA)}</strong>
            {i18n.t(k.YOU_WILL_BE_NOTI)}
          </SubHeader>
          <List>
            <li>
              <strong>{i18n.t(k.YOU_VE_BEEN_ASSIGNED_AS_THE_OW)}</strong>{" "}
              {i18n.t(k.OF_A_CONVERSATIO)}
            </li>
            <li>
              {i18n.t(k.YOU_VE_BEEN)} <strong>{i18n.t(k.MENTIONED)}</strong>{" "}
              {i18n.t(k.IN_A_CONVERSATION)}
            </li>
          </List>
        </div>
      </Option>

      <OptionDanger selected={notification === 4} onClick={() => handleType(4)}>
        <Radio
          id="nothing"
          checked={notification === 4}
          handleChange={() => {}}
        />

        <div>
          <TypeHeader>
            <strong>{i18n.t(k.NOTHING_NOT_RECOMMENDED)}</strong>
          </TypeHeader>
          <SubHeader>{i18n.t(k.YOU_WILL_NOT_BE_NOTIFIED_VIA_E)}</SubHeader>
        </div>
      </OptionDanger>
    </StyledType>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  notification: app.settings.emailNotification
});

export default connect(mapStateToProps, {
  _setNotificationSettings: setNotificationSettings
})(Type);
