// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";
import { connect } from "react-redux";
import FileItem from "./FileItem";

type Props = {
  searchedGroupedFiles: any,
  setOption?: Function,
  chooseFile?: Function,
  roomId: number
};

const SectionedFiles = ({
  searchedGroupedFiles,
  setOption,
  chooseFile,
  roomId
}: Props) => {
  return searchedGroupedFiles[roomId].map(data => (
    <FileItem
      handleClick={() => {
        setOption?.([data.name]);
        chooseFile?.(data);
      }}
      key={`${data.name}${i18n.t(k._5)}${data.roomId}`}
      originalName={data.originalName}
      size={data.size}
      name={data.name}
      roomId={data.roomId}
    />
  ));
};

export default connect(null)(SectionedFiles);
