// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";
import { FormControl, FormLabel, Input, Flex } from "@chakra-ui/react";

import * as colors from "src/styles/constants/colors";
import Tooltip from "src/components/Tooltip";
import * as styles from "./styles";
import Icon from "src/icons";
import PrimaryFieldDropdown from "src/components/PrimaryFieldDropdown";
import { getChartFieldId } from "src/utils/charts.js";

type Props = {
  chartDetails: Object,
  setChartDetails: Function
};

const PrimaryField = ({ chartDetails, setChartDetails }: Props) => {
  const { x } = chartDetails;
  const primaryFieldId = x && getChartFieldId(x);

  return (
    <Flex sx={styles.FlexColumn}>
      <FormControl>
        <Flex sx={styles.FormLabelWrapper}>
          <FormLabel sx={styles.Label}>{i18n.t(k.DESCRIPTION)}</FormLabel>
        </Flex>
        <Input
          onChange={e =>
            setChartDetails(prevState => ({
              ...prevState,
              description: e.target.value
            }))
          }
          value={chartDetails.description}
          sx={styles.BuilderInput}
          placeholder={i18n.t(k.GRAPHICAL_REPRESENTATION_THE_PENDING_ENQUIRIES)}
        />
      </FormControl>

      <FormControl isRequired>
        <Flex sx={styles.FormLabelWrapper}>
          <FormLabel sx={styles.Label}>{i18n.t(k.PRIMARY_FIELD)}</FormLabel>
          <Tooltip
            sx={{ backgroundColor: "none" }}
            title={i18n.t(k.THIS_WILL_BE_X_AXIS_SERIES)}
            placement="top-start"
          >
            <Icon
              type="info"
              fillColor={colors.inkLightest}
              width="16px"
              height="16px"
            />
          </Tooltip>
        </Flex>

        <PrimaryFieldDropdown
          chartDetails={chartDetails}
          setChartDetails={setChartDetails}
          primaryFieldId={primaryFieldId}
        />
      </FormControl>
    </Flex>
  );
};

export default PrimaryField;
