// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React, { Component } from "react";

import Result from "../result";
import { Select as StyledSelect } from "./styles";
import Selection from "src/containers/chatroom/selection";
import selector from "src/components/selector";
import { TextWithputBorders as SearchInput } from "src/styles/input";

import type { RoomId } from "src/types";

type Props = {
  focus: number,
  dropdown: boolean,
  createNew?: boolean,
  value: RoomId,
  placeholder?: string,
  result: Array<RoomId>,
  autoFocus?: boolean,
  settings?: Object,
  handleChange: Function,
  handleDropdown: Function,
  handleClose: Function,
  setFocus: Function,
  handleSearch: Function,
  resetSearch: Function,
  startConversation: Function
};

type State = {
  search: string
};

class Select extends Component<Props, State> {
  outerRef: any;
  searchRef: any;

  static defaultProps = {
    autoFocus: false,
    settings: {},
    createNew: false,
    placeholder: i18n.t(k._4)
  };

  state = {
    search: i18n.t(k._4)
  };

  componentDidUpdate(prevProps: Props) {
    const { dropdown } = this.props;
    if (prevProps.dropdown !== dropdown && dropdown) {
      const { settings, resetSearch } = this.props;
      resetSearch(settings);
    }
  }

  onKeyDown = (e: any) => {
    if (e) {
      // On Enter selecting the first user in the search result
      if (e.key === "Enter") {
        const { focus, result } = this.props;
        const value = result[focus];
        if (value) {
          this.handleSelect(value);
          e.preventDefault();
        }
      }

      if (e.keyCode === 27) {
        const { dropdown, handleClose } = this.props;
        if (dropdown) {
          e.preventDefault();
          e.stopPropagation();
          handleClose();
        }
      }

      const { dropdown } = this.props;
      if (!dropdown) {
        this.handleDropdown();
      }
    }
  };

  handleSelect = (item: ?RoomId) => {
    const { handleChange, handleClose, resetSearch } = this.props;
    this.setState({ search: i18n.t(k._4) });
    handleChange(item);
    resetSearch();
    handleClose();
  };

  handleDeletion = (e: any) => {
    if (e.keyCode === 46 || e.keyCode === 8) {
      this.handleSelect(null);
      this.handleDropdown();
    }
  };

  removeHandler = (e: any) => {
    e.stopPropagation();
    this.handleSelect(null);
    this.handleDropdown();
  };

  handleSearch = (e: any) => {
    const { value, id } = e.target;
    const { settings, handleSearch } = this.props;
    this.setState({ [id]: value });
    handleSearch(value, settings);
    this.handleDropdown();
  };

  handleDropdown = () => {
    const { dropdown, handleDropdown, resetSearch } = this.props;
    if (!dropdown) {
      handleDropdown();
      if (this.searchRef) {
        this.searchRef.focus();
        resetSearch();
      }
    }
  };

  render() {
    const {
      value,
      placeholder,
      dropdown,
      createNew,
      settings,
      autoFocus,
      focus,
      result,
      setFocus,
      startConversation,
      handleClose
    } = this.props;
    const { search } = this.state;

    return (
      <StyledSelect
        ref={node => {
          this.outerRef = node;
        }}
      >
        <div>
          {!value || dropdown ? (
            <SearchInput
              type="text"
              id="search"
              placeholder={placeholder || i18n.t(k.SEARCH)}
              autoComplete="off"
              value={search}
              onChange={this.handleSearch}
              onClick={this.handleDropdown}
              onKeyDown={this.onKeyDown}
              ref={node => {
                this.searchRef = node;
              }}
              autoFocus={autoFocus || dropdown}
            />
          ) : (
            <Selection id={value} removeHandler={this.removeHandler} />
          )}
        </div>
        <div>
          {!dropdown ? null : (
            <Result
              text={search}
              focus={focus}
              result={result}
              outerRef={this.outerRef}
              setFocus={setFocus}
              handleSelect={this.handleSelect}
              settings={settings}
              handleClose={handleClose}
              startConversation={startConversation}
              createNew={createNew}
            />
          )}
        </div>
      </StyledSelect>
    );
  }
}

export default selector(Select);
