// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React, { useState, useCallback, useEffect } from "react";
import * as R from "ramda";
import { useSelector, useDispatch } from "react-redux";

import {
  PopoverHeader,
  PopoverBody,
  HStack,
  IconButton,
  Text
} from "@chakra-ui/react";
import { ChevronLeftIcon, CloseIcon } from "@chakra-ui/icons";
import {
  FileSearchWrapper,
  FileListWrapper,
  InputWrapper,
  Input
} from "../styles";
import * as styles from "./styles";
import FileItem from "./FileItem";
import SectionedFileLists from "./SectionedFileLists";
import { getSearchedGroupedFiles } from "src/reducers";
import { searchConversationFile } from "src/actions/file";
import Icons from "src/icons";

import type { State, RoomId, ConversationFile } from "src/types";

type Props = {
  roomId: RoomId,
  fileIds: string[],
  onSelect: string => void,
  onBack: () => void,
  onClose: () => void
};

const isExistingFile = (conversationFile: ConversationFile, fileId: string) =>
  conversationFile.name === fileId;

const NativeFilePicker = ({
  roomId,
  onSelect,
  onBack,
  onClose,
  fileIds
}: Props) => {
  const dispatch = useDispatch();

  const conversationFiles = useSelector(
    (state: State) => state.app.files.conversationFiles
  );
  const searchedGroupFiles = useSelector((state: State) =>
    getSearchedGroupedFiles(state.app, roomId)
  );

  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    dispatch(searchConversationFile(searchText));
  }, [searchText]);

  const filteredList: ConversationFile[] = R.differenceWith(
    isExistingFile,
    conversationFiles,
    // $FlowFixMe
    fileIds
  );

  const searchedGroupedFiles = R.groupBy(searchedFile =>
    String(searchedFile.chatroomId)
  )(filteredList);

  const roomIds = R.keys(searchedGroupedFiles);

  const handleSearch = useCallback((event: any) => {
    const { value } = event.target;
    setSearchText(value);
  }, []);

  const setOption = useCallback(
    option => {
      onSelect(option);
      setSearchText("");
    },
    [onSelect, setSearchText]
  );

  return (
    <>
      <PopoverHeader {...styles.popover.header.root}>
        <HStack>
          <IconButton
            onClick={onBack}
            aria-label="back"
            variant="transparent"
            icon={<ChevronLeftIcon />}
            {...styles.popover.header.backButton}
          />

          <Text textStyle="popoverHeader">
            {i18n.t(k.FROM_UNIFIZE_FILE_STORE)}
          </Text>
        </HStack>

        <IconButton
          onClick={onClose}
          variant="transparent"
          aria-label="close"
          icon={<CloseIcon />}
          {...styles.popover.header.closeButton}
        />
      </PopoverHeader>

      <PopoverBody {...styles.popover.body.root}>
        <FileSearchWrapper>
          <InputWrapper>
            <Icons type="searchLense" />
            <Input
              type="text"
              onChange={handleSearch}
              placeholder={i18n.t(k.SEARCH)}
              autoFocus
            />
          </InputWrapper>

          <FileListWrapper>
            {searchText.length > 0 ? (
              <SectionedFileLists
                setOption={setOption}
                roomIds={roomIds}
                searchedGroupFiles={searchedGroupFiles}
                searchedGroupedFiles={searchedGroupedFiles}
              />
            ) : (
              roomIds.map(id => {
                return searchedGroupedFiles[id].map(data => {
                  return (
                    <FileItem
                      roomId={roomId}
                      handleClick={() => setOption(data.name)}
                      key={`${data.name}${i18n.t(k._5)}${data.chatroomId}`}
                      originalName={data.originalName}
                      size={data.size}
                      name={data.name}
                    />
                  );
                });
              })
            )}
          </FileListWrapper>
        </FileSearchWrapper>
      </PopoverBody>
    </>
  );
};

export default NativeFilePicker;
