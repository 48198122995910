// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";

import type { UnifizeUser } from "src/types";

type Props = {
  user: UnifizeUser
};

const PhoneNumber = ({ user }: Props) => (
  <span title={user.phoneNumber}>
    {user.phoneNumber ? user.phoneNumber : i18n.t(k._19)}
  </span>
);

export default PhoneNumber;
