// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React, { useCallback, useRef, useState, useEffect } from "react";
import { VStack, Box, Divider, Text } from "@chakra-ui/react";

import { Current as StyledCurrent } from "src/styles";
import Tooltip from "src/components/Tooltip";
import Icon from "src/icons";
import OutsideClickHandler from "src/components/OutsideClickHandler";
import Files from "./Files";
import Revisions from "../Revisions";
import RelatedConverations from "./RelatedConverations";
import Privacy from "./Privacy";
import ProcessBuilder from "./ProcessBuilder";
import ChangeProcessType from "./ChangeProcessType";
import DownloadJSON from "./DownloadJSON";
import Reminders from "./Reminders";
import ConversationLink from "./ConversationLink";
import ForwardAddress from "./ForwardAddress";
import ConversationCreator from "./ConversationCreator";
import LeaveConversation from "./LeaveConversation";
import * as styles from "./styles";

import type { RoomId, ConversationType } from "src/types";

type Props = {
  outerRef: null | React$ElementRef<Box>,
  roomId: RoomId,
  roomType: ConversationType,
  isSrwMobile?: boolean,
  isSrw?: boolean,
  currentRef: null | React$ElementRef<StyledCurrent>
};

const MoreMenu = ({
  outerRef,
  roomId,
  roomType,
  isSrwMobile,
  isSrw,
  currentRef
}: Props) => {
  const portalRef = useRef(null);
  const [isMoreMenuOpen, setIsMoreMenuOpen] = useState(false);
  const [isOptionModalOpen, setIsOptionModalOpen] = useState(false);

  useEffect(() => {
    setIsMoreMenuOpen(false);
  }, [roomId]);

  const handleClickOutside = useCallback(
    (event: any) => {
      if (outerRef && !outerRef.current.contains(event.target)) {
        if (currentRef && currentRef.current.contains(event.target)) {
          setIsMoreMenuOpen(false);
        }
      }
    },
    [outerRef, isMoreMenuOpen]
  );

  const closeMoreMenu = () => {
    setIsMoreMenuOpen(false);
  };

  const toggleMenu = () => {
    isMoreMenuOpen ? setIsMoreMenuOpen(false) : setIsMoreMenuOpen(true);
  };

  return (
    <Box
      sx={{
        position: "relative"
      }}
    >
      <Tooltip title={i18n.t(k.MORE_OPTIONS)}>
        <Box
          sx={styles.MoreMenuBox}
          onClick={() => {
            isMoreMenuOpen && outerRef && outerRef.current.blur();
            toggleMenu();
          }}
          ref={outerRef}
          tabIndex={0}
          onKeyPress={e => e.key === "Enter" && toggleMenu()}
          data-cy="moreButton"
        >
          {isMoreMenuOpen ? (
            <Icon type="moreMenuFilled" />
          ) : (
            <Icon type="moreMenu" />
          )}

          <Text as="span">{i18n.t(k.MORE2)}</Text>
        </Box>
      </Tooltip>
      {(isMoreMenuOpen || isOptionModalOpen) && (
        <OutsideClickHandler onClickOutside={handleClickOutside}>
          <VStack
            spacing={0}
            sx={isMoreMenuOpen ? styles.MenuOpen : styles.MenuClosed}
            onClick={e => {
              e.stopPropagation();
              setIsOptionModalOpen(true);
              closeMoreMenu();
            }}
          >
            {roomType === "workflow" && (
              <Revisions
                isSrwMobile={isSrwMobile}
                roomId={roomId}
                portalRef={portalRef}
              />
            )}
            <Files
              roomId={roomId}
              portalRef={portalRef}
              closeMoreMenu={closeMoreMenu}
            />

            <RelatedConverations
              roomId={roomId}
              portalRef={portalRef}
              closeMoreMenu={closeMoreMenu}
            />

            <Privacy
              roomId={roomId}
              isSrw={isSrw}
              isSrwMobile={isSrwMobile}
              portalRef={portalRef}
              closeMoreMenu={closeMoreMenu}
            />

            {roomType !== "group" && <ProcessBuilder roomId={roomId} />}
            <ChangeProcessType
              roomId={roomId}
              setIsMoreMenuOpen={setIsMoreMenuOpen}
              closeMoreMenu={closeMoreMenu}
            />

            <Divider />
            <DownloadJSON
              roomId={roomId}
              setIsMoreMenuOpen={setIsMoreMenuOpen}
            />

            <Reminders
              roomId={roomId}
              isSrwMobile={isSrwMobile}
              location="more-menu"
              setIsMoreMenuOpen={setIsMoreMenuOpen}
            />

            <ConversationLink setIsMoreMenuOpen={setIsMoreMenuOpen} />
            <ForwardAddress
              roomId={roomId}
              setIsMoreMenuOpen={setIsMoreMenuOpen}
            />

            <Divider />
            <Text sx={styles.CreatedByItem}>{i18n.t(k.CREATED_BY1)}</Text>
            <ConversationCreator roomId={roomId} />
            {!isSrw && (
              <LeaveConversation
                roomId={roomId}
                setIsMoreMenuOpen={setIsMoreMenuOpen}
              />
            )}
          </VStack>
        </OutsideClickHandler>
      )}
    </Box>
  );
};

export default MoreMenu;
