// @flow

import React from "react";
import { connect } from "react-redux";

import NavBar from "./filter/NavBar/Index";

import Overlay from "./Overlay";
import Body from "src/components/Body";
import SRW from "src/components/SRW";
import { getCurrentLanguage } from "src/reducers";
import { HomeContainer } from "src/styles";
import type { Location, AppState } from "src/types";

type Props = {
  isLite: boolean,
  language: string
};

const Home = ({ isLite = false, language }: Props) => {
  if (isLite) return <SRW />;
  return (
    <React.Fragment>
      <Overlay />
      <HomeContainer>
        <NavBar />
        <Body key={language} />
      </HomeContainer>
    </React.Fragment>
  );
};

const mapStateToProps = ({
  location,
  app
}: {
  location: Location,
  app: AppState
}) => ({
  isLite: location.query?.authCode ? true : false,
  language: getCurrentLanguage(app)
});

export default connect(mapStateToProps)(Home);
