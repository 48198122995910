// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";

import { ParentWrapper } from "./styles";
import ChatroomSelect from "src/containers/chatroom/select";
import { FormGroup, Label } from "src/styles/form";

import type { RoomId } from "src/types";

type Props = {
  parent: ?RoomId,
  handleParent: Function
};

const Parent = ({ parent, handleParent }: Props) => (
  <FormGroup data-cy="parentLabelContainer">
    <Label>{i18n.t(k.PARENT2)}</Label>
    <ParentWrapper>
      <ChatroomSelect value={parent} handleChange={handleParent} />
    </ParentWrapper>
  </FormGroup>
);

export default Parent;
