// @flow

import i18n from "i18next";
import k from "src/i18n/keys";
import React, { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Alert } from "@unifize/sarah";

import { deleteDashboard } from "src/actions/dashboard";
import { getCurrentDashboardTitle } from "src/reducers";

type Props = {
  isOpen: boolean,
  id: number,
  handleClose: Function
};

const DeleteModal = ({ isOpen, id, handleClose }: Props) => {
  const dispatch = useDispatch();
  const title = useSelector(({ app }) => getCurrentDashboardTitle(app));

  const handleDelete = useCallback(
    (event: any) => {
      event.preventDefault();
      dispatch(deleteDashboard(id));
      handleClose();
    },
    [handleClose]
  );

  return (
    <Alert
      isOpen={isOpen}
      onClose={handleClose}
      showHeader={false}
      handleConfirm={handleDelete}
      prompt={`${i18n.t(k.ARE_YOU_SURE_YOU_WANT_TO_DELET)} "${title}"? ${i18n.t(k.PINNED_CHARTS_WILL_NOT_BE_DELE)}`}
      confirmVariant="negative"
    />
  );
};

export default DeleteModal;
