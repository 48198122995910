// @flow

import React, { useRef } from "react";
import {
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Link
} from "@chakra-ui/react";
import * as R from "ramda";

import {
  getFieldsFromCSV,
  getFieldsFromJSON
} from "src/utils/checklistBuilder";
import { Field } from "./styles";
import { newTransitTypes } from "src/transit/checklist/field/types";
import {
  checklistFieldNamesEn as checklistFieldNames,
  checklistFieldIcons
} from "src/constants/display";
import Button from "src/components/Buttons/ChakraButton";
import Icon from "src/icons";
import * as colors from "src/styles/constants/colors";

type Props = {
  isOpen: boolean,
  onClose: Function,
  onFieldCardPress: Function,
  fieldType: ?string,
  onSave: Function
};

const AddFieldModal = ({
  isOpen,
  onClose,
  onFieldCardPress,
  fieldType,
  onSave
}: Props) => {
  const csvFileRef = useRef(null);
  const jsonFileRef = useRef(null);

  const saveHandler = () => {
    if (fieldType === "uploadFieldsFromCSV") {
      csvFileRef.current && csvFileRef.current.click();
    } else if (fieldType === "uploadFieldsJSON") {
      jsonFileRef.current && jsonFileRef.current.click();
    } else {
      onSave();
    }
  };

  const uploadJSON = e => {
    const fileData = e.target.files[0];

    if (!fileData) {
      onFieldCardPress(null);
      return;
    }

    const reader = new FileReader();

    reader.onload = function (e) {
      try {
        // $FlowFixMe
        const text = e.target.result;

        const newFields = getFieldsFromJSON(text);

        onSave({ fields: newFields });
      } catch (error) {
        console.error(error);
        onSave({ fields: [] });
      }
    };

    reader.readAsText(fileData);
  };

  const uploadCsv = e => {
    const fileData = e.target.files[0];

    if (!fileData) {
      onFieldCardPress(null);
      return;
    }

    const reader = new FileReader();

    reader.onload = function (e) {
      try {
        // $FlowFixMe
        const text = e.target.result;
        const newFields = getFieldsFromCSV(text);

        onSave({ fields: newFields });
      } catch (error) {
        onSave({ fields: [] });
      }
    };

    reader.readAsText(fileData);
  };

  const downloadSampleCsv = e => {
    e.stopPropagation();

    const fieldTypes = R.keys(newTransitTypes);

    let fileData = [
      ["Label", "Field Type"],
      ...fieldTypes.map(fieldType => [`${fieldType} field`, fieldType])
    ];

    fileData = fileData
      .reduce((prev, curr) => prev + "\n" + curr.join(","), "")
      .trim();

    const csvFile = new Blob([fileData], { type: "text/csv" });

    const hiddenElement = document.createElement("a");
    hiddenElement.download = "Sample checklist upload template.csv";
    hiddenElement.href = window.URL.createObjectURL(csvFile);
    hiddenElement.style.display = "none";

    hiddenElement.click();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="full">
      <ModalOverlay />
      <ModalContent maxW="78vw" maxH="90vh">
        <ModalHeader bg={colors.grey28}>Add Field</ModalHeader>
        <ModalCloseButton />
        <ModalBody style={{ overflow: "scroll" }} data-cy="addFieldModal">
          <Field.Container>
            {R.keys(newTransitTypes).map((type, id) => (
              <Field.Card
                key={id}
                active={fieldType === type}
                onClick={() => onFieldCardPress(type)}
              >
                <Field.Icon>
                  <Icon
                    type={checklistFieldIcons[type].icon}
                    width="36px"
                    height="36px"
                    fill={checklistFieldIcons[type].hexCode}
                  />
                </Field.Icon>
                <Field.Title data-cy={`${type}Field`}>
                  {checklistFieldNames[type]}
                </Field.Title>
                <Field.Subtitle>
                  {checklistFieldIcons[type].description}
                </Field.Subtitle>
              </Field.Card>
            ))}

            <Field.Card
              active={fieldType === "uploadFieldsFromCSV"}
              onClick={() => onFieldCardPress("uploadFieldsFromCSV")}
            >
              <Field.Icon>
                <Icon
                  type={checklistFieldIcons.uploadFieldsFromCSV.icon}
                  width="36px"
                  height="36px"
                />
              </Field.Icon>
              <Field.Title>
                {checklistFieldNames.uploadFieldsFromCSV}
              </Field.Title>
              <Field.Subtitle>
                {checklistFieldIcons.uploadFieldsFromCSV.description}
              </Field.Subtitle>

              <Link
                onClick={downloadSampleCsv}
                sx={{
                  fontSize: "xs",
                  color: "blue",
                  textDecoration: "underline"
                }}
              >
                Download sample CSV
              </Link>

              <input
                type="file"
                accept=".csv"
                id="bulkUploadFields"
                ref={csvFileRef}
                onChange={uploadCsv}
                style={{ display: "none" }}
              />
            </Field.Card>

            <Field.Card
              active={fieldType === "uploadFieldsJSON"}
              onClick={() => onFieldCardPress("uploadFieldsJSON")}
            >
              <Field.Title>
                {checklistFieldNames["uploadFieldsJSON"]}
              </Field.Title>

              <input
                type="file"
                accept=".json"
                id="bulkUploadFieldsFromJSON"
                ref={jsonFileRef}
                onChange={uploadJSON}
                style={{ display: "none" }}
              />
            </Field.Card>
          </Field.Container>
        </ModalBody>
        <ModalFooter bg={colors.grey28}>
          <Flex w="100%">
            <Button variant="uSecondary" mr="12px" onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant="uPrimary"
              onClick={saveHandler}
              data-cy="addFieldButton"
            >
              Add Field
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddFieldModal;
