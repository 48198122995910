// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";
import { Center, Text } from "@chakra-ui/react";

import * as styles from "./styles";

type Props = {
  hasPreviewSupport: boolean,
  isPreviewEnabled: boolean
};

const PreviewUnavailable = ({ hasPreviewSupport, isPreviewEnabled }: Props) => {
  return !hasPreviewSupport && isPreviewEnabled ? (
    <Center {...styles.popover.noPreview.container}>
      <Text {...styles.popover.noPreview.text}>
        {i18n.t(k.NO_PREVIEW_AVAILABLE)}
      </Text>
    </Center>
  ) : null;
};

export default PreviewUnavailable;
