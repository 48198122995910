// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";

import { FilterItem as StyledFilterItem, CloseButton } from "../styles";
import { processColumnNames } from "src/constants/display";
import { getChecklistFieldsById } from "src/reducers";
import { getCanEditReportFilters } from "src/reducers/combinedSelectors";
import * as colors from "src/styles/constants/colors";
import Icon from "src/icons";
import { dateRangeToLabelMap } from "../Table/manageViewFilters";
import { capitalize } from "src/utils";

type Props = {
  value: any,
  column: string,
  handleClose: Function,
  blanksFilterText: ?string
};

const Date = ({ value, column, handleClose, blanksFilterText }: Props) => {
  const fields = useSelector(({ app }) => getChecklistFieldsById(app));
  const parentLinkedField = column.split("-")[0];
  const parentFieldName = fields.toJS()[parentLinkedField]?.label;

  const isFilteringAllowed = useSelector(state =>
    getCanEditReportFilters(state)
  );

  if (
    blanksFilterText ||
    dateRangeToLabelMap[value] ||
    value.startsWith("lastn") ||
    value.startsWith("nextn")
  ) {
    const filterValue =
      blanksFilterText || value.startsWith("lastn") || value.startsWith("nextn")
        ? `${capitalize(
            value.replace(/^(last|next)n-/, "$1 ").replace(/-/g, " ")
          )}`
        : dateRangeToLabelMap[value];

    return (
      <StyledFilterItem isFilteringAllowed={isFilteringAllowed}>
        <span>
          {processColumnNames[column]}
          {column.includes("-") && parentFieldName}
          {i18n.t(k._)}
        </span>
        <strong>{filterValue}</strong>
        {isFilteringAllowed && (
          <CloseButton onClick={handleClose}>
            <Icon type="close" color={colors.active} />
          </CloseButton>
        )}
      </StyledFilterItem>
    );
  }

  const dates = (value || "").split(":").filter(d => d !== "");
  const start = dates.length > 0 ? dates[0] : null;
  const end = dates.length === 2 ? dates[1] : null;

  return (
    <StyledFilterItem isFilteringAllowed={isFilteringAllowed}>
      <span>
        {processColumnNames[column]}
        {i18n.t(k._)}
      </span>
      <strong>
        {start ? moment(start).format(i18n.t(k.MMM_DD_YYYY)) : ""}
      </strong>

      {/* eslint-disable-next-line no-irregular-whitespace */}
      {start && end ? <strong> {i18n.t(k.TO)} </strong> : <strong></strong>}

      <strong>{end ? moment(end).format(i18n.t(k.MMM_DD_YYYY)) : ""}</strong>
      {isFilteringAllowed && (
        <CloseButton onClick={handleClose}>
          <Icon type="close" color={colors.active} />
        </CloseButton>
      )}
    </StyledFilterItem>
  );
};

export default Date;
