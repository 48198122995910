// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import * as R from "ramda";
import { VStack } from "@chakra-ui/react";
import { WarningIcon } from "@chakra-ui/icons";

import Button from "src/components/Buttons/ChakraButton";
import RevisionConversation from "src/components/Dock/Checklist/Conversation/Revision";
import RevisionModal from "./RevisionModal";
import CreatorsModal from "./CreatorsModal";
import { InteractiveToolTip } from "src/components/Unifize";

import {
  getCurrentUserId,
  getChecklistValue,
  getChecklistFieldDetails,
  getIsAuthorizedCreator,
  getLockedStatus
} from "src/reducers";
import useFields from "../useFields";
import * as morpheus from "src/utils/morpheus";

import type { RoomId, FieldId } from "src/types";
import * as styles from "./styles";

type Props = {
  roomId: RoomId,
  fieldId: FieldId,
  promptCallback?: ?Function,
  formId: ?number,
  scrollContent?: boolean,
  fromManageView?: boolean
};

const modalStages = {
  confirm: 0,
  create: 1
};

function Revision({
  promptCallback = null,
  roomId,
  fieldId,
  formId,
  scrollContent = false
}: Props) {
  const checklistValue = useSelector(({ app }) =>
    getChecklistValue(app, fieldId, roomId)
  );
  const details = useSelector(({ app }) =>
    getChecklistFieldDetails(app, `${fieldId}`)
  );
  const isChecklistFieldLocked = useSelector(({ app }) =>
    getLockedStatus(app, roomId, fieldId)
  );

  const { settings } = useFields({
    checklistValue,
    details
  });

  const currentUserId = useSelector(({ app }) => getCurrentUserId(app));

  const [modalStage, setModalStage] = useState(null);
  const [showMembers, setShowMembers] = useState(false);
  const [revisionsCreated, setRevisionsCreated] = useState(false);
  const [revisionIds, setRevisionIds] = useState([]);
  const [highlightRevisionIds, setHighlightRevisionIds] = useState([]);

  const isAuthorized: boolean = useSelector(({ app }) =>
    getIsAuthorizedCreator(app, fieldId, roomId, currentUserId)
  );

  const settingsV2 = morpheus.revision(settings);

  const { copyableFields = [] } = settingsV2;

  const start = () => {
    setModalStage(modalStages.confirm);
  };

  useEffect(() => {
    const newRevisionIds = (checklistValue?.value || []).map(room => room.id);
    setRevisionIds(newRevisionIds);

    let timeout;

    if (revisionsCreated) {
      setHighlightRevisionIds(R.difference(newRevisionIds, revisionIds));
      timeout = setTimeout(() => setHighlightRevisionIds([]), 5000);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [checklistValue]);

  return (
    <VStack {...styles.fieldContainer({ scrollContent })}>
      <VStack {...styles.revisionList({ scrollContent })}>
        {(checklistValue?.value || []).map(room => (
          <RevisionConversation
            key={room.id}
            room={room}
            currentRoomId={roomId}
            fieldId={fieldId}
            highlight={highlightRevisionIds.includes(room.id)}
            disabled={!isAuthorized}
          />
        ))}
      </VStack>

      <InteractiveToolTip
        isShown={!isAuthorized}
        onClick={() => setShowMembers(true)}
        message={i18n.t(k.YOU_ARE_NOT_AUTHORIZED_CLICK)}
      >
        <Button
          variant="add"
          onClick={start}
          isDisabled={!isAuthorized || isChecklistFieldLocked}
          rightIcon={
            (!isAuthorized || isChecklistFieldLocked) && (
              <WarningIcon w={4} h={4} color="red.500" />
            )
          }
          {...styles.createVersion({ scrollContent })}
        >
          {i18n.t(k.CREATE_REVISION1)}
        </Button>
      </InteractiveToolTip>

      {showMembers && (
        <CreatorsModal
          title={i18n.t(k.REVISION_PERMISSIONS)}
          subtitle={i18n.t(k.REVISIONS_CAN_BE_CREATED_BY)}
          emptySubtitle={i18n.t(k.REVISION_SETTINGS_NOT_CONFIGURED)}
          isOpen={showMembers}
          onClose={() => setShowMembers(false)}
          fieldId={fieldId}
          roomId={roomId}
          settings={settingsV2}
          path="authorizedToCreate"
        />
      )}

      <RevisionModal
        modalStage={modalStage}
        revisionsCreated={revisionsCreated}
        promptCallback={promptCallback}
        roomId={roomId}
        fieldId={fieldId}
        formId={formId}
        setRevisionsCreated={setRevisionsCreated}
        setModalStage={setModalStage}
        copyableFields={copyableFields}
      />
    </VStack>
  );
}

export default Revision;
