// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React, { useState, Fragment } from "react";
import { Flex, Text } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { ChevronUpIcon, ChevronDownIcon } from "@chakra-ui/icons";

import { getGroup } from "src/reducers";
import * as styles from "./styles";
import Icon from "src/icons";
import GroupMember from "src/components/chatroom/Members/GroupUsers";

type GroupProps = {
  id: number
};

const Group = ({ id }: GroupProps) => {
  const group = useSelector(({ app }) => getGroup(app, id));
  const { title, members, photoUrl } = group;

  const [showMembers, setShowMembers] = useState(false);

  return (
    <Fragment>
      <Flex sx={styles.FlexColumn}>
        <Flex>
          <Icon
            type="groupAvatar"
            text={title}
            size="sm"
            photoUrl={photoUrl}
            id={id}
          />
          <Flex sx={styles.FlexColumn}>
            <Text isTruncated sx={styles.Name}>
              {title}
            </Text>
            <Text sx={styles.LightGreyText}>
              {members.length}{" "}
              {members.length > 1 ? i18n.t(k.MEMBERS) : i18n.t(k.MEMBER)}
            </Text>
          </Flex>

          <Flex
            onClick={e => {
              e.stopPropagation();
              setShowMembers(prev => !prev);
            }}
            sx={showMembers ? styles.ActiveArrowIcon : styles.ArrowIcon}
          >
            {showMembers ? (
              <ChevronUpIcon boxSize={5} />
            ) : (
              <ChevronDownIcon boxSize={5} />
            )}
          </Flex>
        </Flex>
        {showMembers &&
          members.map(user => {
            return <GroupMember key={user} id={user} />;
          })}
      </Flex>
    </Fragment>
  );
};

export default Group;
