// @flow

import React from "react";

type Props = {
  height?: number,
  width?: number,
  fill?: string
};

const Unifize = ({ height = 34, width = 40, fill = "none" }: Props) => {
  return (
    <svg
      width={`${width}px`}
      height={`${height}px`}
      viewBox="0 0 40 34"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.13175 2.60098C1.50878 2.60098 0.191895 3.9124 0.191895 5.5291V8.45677V19.2667C0.191895 23.1594 1.78966 26.6981 4.36296 29.2603C6.93488 31.8224 10.4883 33.4136 14.3977 33.4136C18.3071 33.4136 21.8596 31.8224 24.432 29.2603C27.0057 26.6981 28.6035 23.1594 28.6035 19.2667V14.3126C28.6035 12.6954 27.2862 11.3849 25.6627 11.3849C24.0402 11.3849 22.7233 12.6954 22.7233 14.3126V19.2667C22.7233 21.5441 21.7854 23.6157 20.2751 25.1202C18.7653 26.6242 16.6842 27.5578 14.3977 27.5578C12.1108 27.5578 10.0296 26.6242 8.51982 25.1202C7.00956 23.6157 6.07161 21.5441 6.07161 19.2667V14.0119V7.94981H7.51313C8.9904 7.94981 10.1987 6.74653 10.1987 5.27539C10.1987 3.80426 8.98994 2.60098 7.51313 2.60098H3.13175Z"
        fill="url(#paint0_linear_791_135)"
      />
      <path
        d="M15.5671 4.1799C12.9938 6.75874 11.3965 10.32 11.3965 14.2385V19.2249C11.3965 20.8526 12.7134 22.1721 14.3368 22.1721C15.9598 22.1721 17.2767 20.8526 17.2767 19.2249V14.2385C17.2767 11.9463 18.2142 9.8607 19.7244 8.34646C21.2347 6.83314 23.3158 5.89346 25.6018 5.89346C27.8883 5.89346 29.9704 6.83314 31.4802 8.34646C32.99 9.8607 33.9279 11.9463 33.9279 14.2385V19.5271V25.6291H32.4864C31.0096 25.6291 29.8013 26.8402 29.8013 28.3204V28.3209C29.8013 29.8011 31.0096 31.0127 32.4864 31.0127H36.8673C38.4912 31.0127 39.8081 29.6927 39.8081 28.066V25.1188V14.2385C39.8081 10.32 38.2103 6.75874 35.637 4.1799C33.0642 1.60106 29.5113 2.09808e-05 25.6018 2.09808e-05C21.6929 2.09808e-05 18.1395 1.60106 15.5671 4.1799Z"
        fill="url(#paint1_linear_791_135)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_791_135"
          x1="28.7584"
          y1="33.3175"
          x2="-11.3071"
          y2="6.81134"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#0052FF" />
          <stop offset="1" stop-color="#5E91FC" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_791_135"
          x1="39.9629"
          y1="30.916"
          x2="-0.260042"
          y2="4.47732"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#0052FF" />
          <stop offset="1" stop-color="#5E91FC" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Unifize;
