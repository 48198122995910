// @flow

import { connect } from "react-redux";
import React, { useCallback } from "react";

import RowItem from "./Item";
import Child from "./Child";
import { Row as StyledRow, Selector } from "./styles";
import { getChildConversations } from "src/actions/chatroom";
import useBoolean from "src/hooks/useBoolean";
import * as R from "ramda";

import type { WorkflowInstances, State, Workflow, UID } from "src/types";
import { getWorkflow, getCurrentUserId } from "src/reducers";
import { isRoomPrivateForUser } from "src/utils";

type Props = {
  width: string,
  columnSize: string,
  depth: number,
  index: number,
  parentId: number,
  parentTitle: string,
  numberOfColumns: number,
  process: WorkflowInstances,
  processColumns: Array<Object>,
  _getChildConversations: Function,
  workflow: Workflow,
  currentUserId: UID
};

const ChildRow = ({
  width,
  columnSize,
  process,
  depth,
  index,
  parentId,
  parentTitle,
  numberOfColumns,
  processColumns,
  workflow,
  currentUserId,
  _getChildConversations
}: Props) => {
  const { value: expand, toggleBoolean } = useBoolean(false);

  const toggleExpand = useCallback(() => {
    if (!expand && process) {
      _getChildConversations(process.chatroomId);
    }
    toggleBoolean();
  }, [expand, toggleBoolean, _getChildConversations, process]);

  if (!process) {
    return <></>;
  }

  const currentRoomTitle = `${process.processTitle || ""} ${
    process.autoNo
      ? `#${process.autoNo}`
      : process.seqNo
        ? `#${process.seqNo}`
        : ""
  }${process.title ? `: ${process.title}` : ""}`;

  let columnsExcludingFields = R.filter(
    column => column.type === "revision" || !column.type,
    processColumns
  );
  columnsExcludingFields = columnsExcludingFields.map(column => {
    if (!column.type) return column;

    return {
      ...column,
      key: column.type
    };
  });

  const isPrivate = isRoomPrivateForUser({
    uid: currentUserId,
    workflow,
    process
  });

  return (
    <>
      <StyledRow
        depth={depth}
        width={width}
        columnSize={columnSize}
        style={{ paddingLeft: "24px" }}
      >
        <Selector depth={depth} />
        {columnsExcludingFields.map(column => (
          <RowItem
            selected={false}
            depth={depth}
            key={column.key}
            column={column.key}
            process={process}
            expand={expand}
            index={index}
            parentTitle={parentTitle}
            parentId={parentId}
            toggleExpand={toggleExpand}
            isPrivate={isPrivate}
          />
        ))}
      </StyledRow>
      {expand && (
        <Child
          width={width}
          depth={depth + 1}
          index={index}
          parentTitle={currentRoomTitle}
          rowSize={process.childCount}
          numberOfColumns={numberOfColumns}
          parentId={process.chatroomId}
          columnSize={columnSize}
          processColumns={processColumns}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ app }: State, { process }: Props) => ({
  workflow: getWorkflow(app, `${process.templateId}` || ""),
  currentUserId: getCurrentUserId(app)
});

export default connect(mapStateToProps, {
  _getChildConversations: getChildConversations
})(ChildRow);
