// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button
} from "@chakra-ui/react";
import * as colors from "src/styles/constants/colors";

type Props = {
  onClose: Function,
  isOpen: boolean,
  id: number,
  _deleteRole: Function
};

const DeleteRoleModal = ({ onClose, isOpen, id, _deleteRole }: Props) => {
  return (
    <Modal
      isCentered
      onClose={onClose}
      isOpen={isOpen}
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader backgroundColor={colors.grey0}>
          {i18n.t(k.DELETE_ROLE)}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>{i18n.t(k.ARE_YOU_SURE_YOU_WANT_TO_DELET3)}</ModalBody>
        <ModalFooter>
          <Button
            colorScheme="red"
            mr={3}
            onClick={() => {
              _deleteRole(id);
              onClose();
            }}
          >
            {i18n.t(k.DELETE)}
          </Button>
          <Button variant="ghost" onClick={onClose}>
            {i18n.t(k.CANCEL)}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeleteRoleModal;
