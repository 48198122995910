// @flow

import React from "react";
import { useSelector } from "react-redux";
import * as R from "ramda";

import Tile from "./Tile";
import { Section as StyledSection } from "./styles";
import { getSectionTiles } from "src/reducers";

import type { HomescreenSection } from "src/types";

type Props = {
  section: HomescreenSection
};

const Section = ({ section }: Props) => {
  const sectionTiles = useSelector(({ app }) =>
    getSectionTiles(app, section.id)
  );

  // Check if all tiles are empty - isEmpty should be set to true
  const isSectionEmpty = R.all(
    R.equals(true),
    Object.keys(sectionTiles || {}).map(tileId => sectionTiles[tileId].isEmpty)
  );

  if (isSectionEmpty) {
    return null;
  }

  return (
    <StyledSection>
      <h2>{section.title}</h2>
      <p>{section.description}</p>
      {section.tiles.map(tile => (
        <Tile key={tile.id} tile={tile} sectionId={section.id} />
      ))}
    </StyledSection>
  );
};

export default Section;
