// @flow

import React from "react";

import { Suggestion as StyledSuggestion } from "./styles";
import UserAndGroup from "./UserAndGroup";

import All from "src/img/mentions/all.svg";
import Owner from "src/img/mentions/owner.svg";
import Creator from "src/img/mentions/creator.svg";
import Signatories from "src/img/mentions/signatories.svg";

import type { Mentions } from "src/types";
import SpecialMention from "./SpecialMention";

type Props = {
  focused: boolean,
  mention: Mentions
};

const Suggestion = ({ mention, focused, ...parentProps }: Props) => {
  switch (mention.id) {
    case "!everyone":
      return (
        <StyledSuggestion
          tabIndex="0"
          role="button"
          isFocused={focused}
          {...parentProps}
        >
          <SpecialMention
            title="All"
            description="Every participant of the conversation"
            icon={All}
          />
        </StyledSuggestion>
      );

    case "!signatories":
      return (
        <StyledSuggestion
          tabIndex="0"
          role="button"
          isFocused={focused}
          {...parentProps}
        >
          <SpecialMention
            title="Signatories"
            description="Signatories of the conversation"
            icon={Signatories}
          />
        </StyledSuggestion>
      );

    case "!owner":
      return (
        <StyledSuggestion
          tabIndex="0"
          role="button"
          isFocused={focused}
          {...parentProps}
        >
          <SpecialMention
            title="Owner"
            description="Current owner of the conversation"
            icon={Owner}
          />
        </StyledSuggestion>
      );

    case "!creator":
      return (
        <StyledSuggestion
          tabIndex="0"
          role="button"
          isFocused={focused}
          {...parentProps}
        >
          <SpecialMention
            title="Creator"
            description="Creator of the conversation"
            icon={Creator}
          />
        </StyledSuggestion>
      );

    default:
      return (
        <UserAndGroup focused={focused} mention={mention} {...parentProps} />
      );
  }
};

export default Suggestion;
