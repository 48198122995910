// @flow
import React from "react";

import { InputContainer, Input, ErrorMessage } from "../styles";

type Props = {
  id: string,
  name?: string,
  helperMessage?: string,
  errorMessage?: string,
  placeholder?: string,
  inputRef?: any,
  style?: Object,
  showError?: boolean
};

const FormTextInput = ({
  id,
  name = "",
  placeholder = "",
  inputRef,
  style = {},
  errorMessage = "",
  showError = false,
  ...rest
}: Props) => {
  return (
    <InputContainer>
      <Input
        {...(inputRef ? { ref: inputRef } : {})}
        {...rest}
        id={id ?? ""}
        name={name}
        placeholder={placeholder}
        style={style}
      />

      {showError && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </InputContainer>
  );
};

export default FormTextInput;
