// @flow

import React, { useEffect, useRef } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import { useClientDimensions } from "react-client-dimensions";
import { Flex } from "@chakra-ui/react";

import SignUpModal from "./SignUpModal";
import ChatArea from "./ChatArea";
import Mobile from "./Mobile";
import AppInfo from "./AppInfo";
import { SRW as StyledSRW, SRWMain, Chatroom } from "./styles";
import * as styles from "src/components/SRW/styles";
import LoadingState from "src/components/LoadingState";
import ChatroomHeader from "src/components/chatroom/Header";
import Dock from "src/containers/CurrentRoomDock";
import PreviewModal from "src/components/file/PreviewModal";
import Viewer from "src/components/Main/Viewer";
import AccessDenied from "src/components/auth/AccessDenied";

import {
  getCurrentChatRoom,
  getTemplateId,
  getFileUploadModalStatus,
  getSignInError,
  getIsChatroomOpen,
  getProcessLayoutSettings,
  getActiveDock
} from "src/reducers";
import { setMobileResponsiveness } from "src/actions/srw";
import { showFileUploadModal } from "src/actions/file";
import { hideChatroomComponent } from "src/actions/chatmenu";

import type {
  AppState,
  UnifizeUser,
  UnifizeChatRoom,
  AuthTokenError
} from "src/types";

type Props = {
  currentUser: UnifizeUser,
  room: UnifizeChatRoom,
  showSignUpModal: boolean,
  participants: boolean,
  templateId?: number,
  showFilesPreviewModal: boolean,
  error: AuthTokenError,
  layout: Object
};

const SRW = ({
  room,
  showSignUpModal,
  participants,
  templateId,
  showFilesPreviewModal,
  error,
  currentUser,
  layout
}: Props) => {
  const dispatch = useDispatch();
  const currentRef = useRef(null);
  const isFullWidth = layout?.checklistWidth === "full";
  const mainContainerRef = useRef(null);

  const { vw, vh } = useClientDimensions();

  useEffect(() => {
    if (vw < 799) {
      dispatch(setMobileResponsiveness(true));
    } else {
      dispatch(setMobileResponsiveness(false));
    }
  }, [vw]);

  useEffect(() => {
    window.history.replaceState({}, "", `${window.location.pathname}`);
  }, []);

  useEffect(() => {
    if (templateId) {
      window.history.replaceState(
        {},
        "",
        `${window.location.pathname}?templateId=${templateId}`
      );
    }
  }, [templateId]);

  const activeDock = useSelector(({ app }) => getActiveDock(app));
  const isChatroomActive = useSelector(({ app }) => getIsChatroomOpen(app));

  useEffect(() => {
    if (isFullWidth) {
      if (activeDock) {
        dispatch(hideChatroomComponent());
      }
    }
  }, [room, activeDock, isFullWidth]);

  const onDrop = acceptedFiles => {
    if (room?.id) {
      dispatch(showFileUploadModal({ viewId: room.id, files: acceptedFiles }));
    }
  };
  const { getRootProps, isDragActive } = useDropzone({ onDrop });

  if (error) {
    return (
      <Flex sx={styles.AccessDenied}>
        <AccessDenied />
      </Flex>
    );
  }

  if (vw < 799) {
    return <Mobile vh={vh} />;
  }

  return (
    <StyledSRW ref={currentRef}>
      {showSignUpModal && <SignUpModal />}
      {showFilesPreviewModal && room?.id && <PreviewModal roomId={room.id} />}
      <AppInfo />
      <SRWMain {...getRootProps()} isDragActive={isDragActive}>
        {room && room.id ? (
          <ChatroomHeader roomId={room.id} currentRef={currentRef} isSrw />
        ) : (
          <LoadingState type="header" />
        )}
        <Chatroom ref={mainContainerRef}>
          {isChatroomActive && (
            <ChatArea
              room={room}
              currentUser={currentUser}
              participants={participants}
            />
          )}

          {room && room.id ? (
            <Dock isSrw parentRef={mainContainerRef} />
          ) : (
            <LoadingState type="panel" />
          )}
        </Chatroom>
        <Viewer />
      </SRWMain>
    </StyledSRW>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => {
  const room = getCurrentChatRoom(app);
  return {
    currentUser: app.currentUser,
    room,
    participants: app.header.participants,
    showSignUpModal: app.srw.signUpModal,
    templateId: room ? getTemplateId(app, room.id) : null,
    layout: room ? getProcessLayoutSettings(app, room.id) : {},
    showFilesPreviewModal: room
      ? getFileUploadModalStatus(app, room.id)
      : false,
    error: getSignInError("sendAuthToken")(app)
  };
};

export default connect(mapStateToProps)(SRW);
