// @flow
import React, { useState, useEffect, useCallback } from "react";

import { useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { Link } from "@tiptap/extension-link";
import { Placeholder } from "@tiptap/extension-placeholder";
import TiptapUnderline from "@tiptap/extension-underline";
import { Color } from "@tiptap/extension-color";
import TextStyle from "@tiptap/extension-text-style";
import Highlight from "@tiptap/extension-highlight";

import type { Node } from "react";
import { Editor as TipTapEditorType } from "@tiptap/react";
import type { RichTextFieldValue } from "src/types";

import RichTextEditor from "src/components/Dock/Checklist/RichText/RichTextEditor";

const LinkWithTooltip = Link.extend({
  addAttributes() {
    return {
      // $FlowFixMe: Optional chaining
      ...this.parent?.(),
      title: {
        default: null,
        parseHTML: element => element.getAttribute("title"),
        renderHTML: attributes => {
          if (!attributes.href) {
            return {};
          }
          return { title: attributes.href };
        }
      }
    };
  }
});

/**
 * A custom hook that provides a rich text editor functionality using
 * TipTap
 *
 * @param {string} [placeholder] - Optional placeholder text to display
 * when the editor is empty
 * @param {string|RichTextFieldValue} content - The content to display
 * in the editor
 * @param {boolean} [readonly] - Whether the editor should be in
 * readonly mode
 * @param {Function} [handleEditorClick] - Optional callback function to
 * handle editor click events
 * @param {Function} [handleEditorKeyDown] - Optional callback function
 * to handle editor keydown events
 *
 * @returns {Object} An object containing:
 *   - Editor: A propless React component to render the rich-text
 * editor, can be omitted entirely to use the TipTap editor directly
 *   - editing: A state boolean indicating whether the editor is in
 * editing mode
 *   - setEditing: A function to set the editing state
 *   - editor: The TipTap editor instance
 */
const useRichTextEditor = (
  placeholder?: string,
  content: string | RichTextFieldValue,
  readonly?: boolean,
  handleEditorClick?: () => void,
  handleEditorKeyDown?: () => void
): ({
  Editor: any => React$Element<any>,
  editing: boolean,
  setEditing: boolean => void,
  editor: TipTapEditorType
}) => {
  const [editing, setEditing] = useState<boolean>(false);

  const editor: TipTapEditorType = useEditor(
    {
      extensions: [
        StarterKit,
        LinkWithTooltip.configure({
          autolink: true,
          openOnClick: false,
          defaultProtocol: "https",
          protocols: ["http", "https"]
        }),
        TiptapUnderline,
        TextStyle,
        Color,
        Highlight.configure({ multicolor: true }),
        Placeholder.configure({ placeholder })
      ],
      content:
        typeof content === "string" ? content : (content?.htmlText ?? ""),
      editable: !readonly && editing,
      onFocus: () => {
        if (!editing && !readonly) {
          setEditing(true);
        }
      }
    },
    [editing, content, readonly]
  );

  useEffect(() => {
    if (editing) {
      editor.commands.focus("end");
    }
  }, [editing, editor]);

  const Editor = useCallback(
    ({ children }: { children: Node }) => (
      <RichTextEditor
        editor={editor}
        showMenu={editing}
        handleClick={handleEditorClick}
        handleKeyDown={handleEditorKeyDown}
      >
        {children}
      </RichTextEditor>
    ),
    [editor] // Be careful with this dependency array to avoid rerenders preventing the editor from working
  );

  useEffect(() => {
    return () => {
      // $FlowFixMe: Optional chaining
      editor?.destroy();
    };
  }, [editor]);

  return { Editor, editing, setEditing, editor };
};

export default useRichTextEditor;
