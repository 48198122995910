// @flow

import * as easyFetch from "src/utils/fetch";

/**
 * Get org's supported languages
 */
export const getSupportedLanguages = () => easyFetch.get(`/language`);

/**
 * Get user's preferred language
 */
export const getLanguage = () => easyFetch.get(`/my-language`);

/**
 * Switch the user's language
 * @param {string} lang - language code for the desired language
 */
export const switchLanguage = (lang: string) =>
  easyFetch.patch(`/my-language`, {
    body: {
      langAbbr: lang
    }
  });
