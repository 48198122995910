// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* overrides for pdf_viewer.css from PDF.JS web viewer */

.textLayer {
  z-index: 2;
  opacity: 1;
}

.annotationLayer {
  position: absolute;
  top: 0;

  z-index: 3;
}

html
  body
  .textLayer
  > div:not(.PdfHighlighter__highlight-layer):not(.Highlight):not(
    .Highlight-emoji
  ) {
  opacity: 0.5;
}

.textLayer ::selection {
  background: rgba(252, 232, 151, 1);
}
`, "",{"version":3,"sources":["webpack://./src/pdf-viewer/style/pdf_viewer.css"],"names":[],"mappings":"AAAA,wDAAwD;;AAExD;EACE,UAAU;EACV,UAAU;AACZ;;AAEA;EACE,kBAAkB;EAClB,MAAM;;EAEN,UAAU;AACZ;;AAEA;;;;;;EAME,YAAY;AACd;;AAEA;EACE,kCAAkC;AACpC","sourcesContent":["/* overrides for pdf_viewer.css from PDF.JS web viewer */\n\n.textLayer {\n  z-index: 2;\n  opacity: 1;\n}\n\n.annotationLayer {\n  position: absolute;\n  top: 0;\n\n  z-index: 3;\n}\n\nhtml\n  body\n  .textLayer\n  > div:not(.PdfHighlighter__highlight-layer):not(.Highlight):not(\n    .Highlight-emoji\n  ) {\n  opacity: 0.5;\n}\n\n.textLayer ::selection {\n  background: rgba(252, 232, 151, 1);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
