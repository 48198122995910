/* eslint-disable lingui/no-unlocalized-strings */
// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React, { Component } from "react";
import * as R from "ramda";

import SearchMultiple from "src/containers/user.old/SearchMultiple";
import User from "src/containers/user.old";
import Icon from "src/components/InlineSVG";
import { colors } from "src/styles/constants";
import { AttachItem, AttachedItem } from "src/styles/form.old";

import { onManageView } from "src/utils";

type Props = {
  value: Array<string>,
  addAttribute: Function
};

type State = {
  search: boolean
};

class SelectMultiple extends Component<Props, State> {
  outerRef: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      search: false
    };
  }

  toggleSearch = () => {
    this.setState(prevState => ({ search: !prevState.search }));
  };

  removeHandler = (uid: string) => {
    const { value, addAttribute } = this.props;
    const newUsers = value.filter(oldUid => oldUid !== uid);
    addAttribute(newUsers);
  };

  handleUser = (uid: string) => {
    const { addAttribute, value } = this.props;

    if (R.includes(uid, value)) {
      const newUsers = R.reject(member => uid === member, value);
      addAttribute(newUsers);
    } else {
      const newUsers = [...value, uid];
      addAttribute(newUsers);
    }
  };

  render() {
    const { value } = this.props;
    const { search } = this.state;

    return (
      <div
        ref={node => {
          this.outerRef = node;
        }}
      >
        {value.length !== 0 ? (
          value.length > 1 ? (
            <AttachItem
              onClick={this.toggleSearch}
              onKeyPress={this.toggleSearch}
              tabIndex="0"
            >
              {value.length} {onManageView() ? "Selected" : i18n.t(k.SELECTED1)}
              <Icon icon="caretDown" color={colors.mediumBlack} />
            </AttachItem>
          ) : (
            <AttachedItem
              onClick={this.toggleSearch}
              onKeyPress={this.toggleSearch}
              tabIndex="0"
            >
              <User
                key={value[0]}
                uid={value[0]}
                type="userWithDisplayName"
                remove
                clickHandler={() => this.removeHandler(value[0])}
              />
            </AttachedItem>
          )
        ) : (
          <AttachItem
            onClick={this.toggleSearch}
            onKeyPress={this.toggleSearch}
            tabIndex="0"
          >
            {onManageView() ? "Select Members" : i18n.t(k.SELECT_MEMBERS)}
            <Icon icon="caretDown" color={colors.mediumBlack} />
          </AttachItem>
        )}

        {search ? (
          <SearchMultiple
            selectedUser={value}
            toggleMembers={this.handleUser}
            closeUserList={this.toggleSearch}
            outerRef={this.outerRef}
          />
        ) : null}
      </div>
    );
  }
}

export default SelectMultiple;
