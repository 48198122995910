// @flow

import i18n from "i18next";
import k from "src/i18n/keys";
import React from "react";
import { Modal, SettingsPlaceholder } from "src/components/Unifize";
import { Title, ModalContent } from "./styles";
import AdHocDetails from "./AdHocDetails";
import CreatorDetails from "./CreatorDetails";

import type {
  RevisionFieldSettingsv2,
  ApprovalFieldSettingsV2,
  RoomId,
  FieldId
} from "src/types";

type Props = {
  isOpen: boolean,
  onClose: Function,
  title: string,
  subtitle?: string,
  emptySubtitle?: string,
  path: string,
  settings: RevisionFieldSettingsv2 | ApprovalFieldSettingsV2,
  roomId: RoomId,
  fieldId: FieldId
};

const CreatorsModal = ({
  isOpen,
  onClose,
  settings,
  title,
  subtitle = "",
  emptySubtitle = "",
  path,
  fieldId,
  roomId
}: Props) => {
  const { requiredApprovers = "some" } = settings;
  const isAdHoc = requiredApprovers === "adhoc";
  const { [path]: value = {} } = settings;
  const {
    groups = [],
    roles = [],
    users = [],
    fields = [],
    allParticipants,
    owner
  } = value;
  const isEmpty = !(
    groups?.length ||
    roles?.length ||
    users?.length ||
    fields?.length ||
    allParticipants ||
    owner
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      cancelLabel={i18n.t(k.CANCEL)}
      confirmLabel={i18n.t(k.DONE)}
    >
      {subtitle && (
        <Title>{isEmpty && emptySubtitle ? emptySubtitle : subtitle}</Title>
      )}
      {isEmpty ? (
        <SettingsPlaceholder />
      ) : (
        <ModalContent>
          {isAdHoc ? (
            <AdHocDetails
              fieldId={fieldId}
              roomId={roomId}
              settings={settings}
              path={path}
            />
          ) : (
            <CreatorDetails path={path} settings={settings} />
          )}
        </ModalContent>
      )}
    </Modal>
  );
};

export default CreatorsModal;
