// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";

import {
  FilterItem as StyledFilterItem,
  CloseButton
} from "src/components/Manage/Workflow/Instances/styles";
import { formColumnNames } from "src/constants/display";
import * as colors from "src/styles/constants/colors";
import ChatroomName from "src/containers/chatroom/Name";
import Icon from "src/icons";

type Props = {
  id: any,
  column: string,
  handleClose: Function
};

const Chatroom = ({ id, column, handleClose }: Props) => {
  return (
    <StyledFilterItem>
      <span>
        {formColumnNames[column]}
        {i18n.t(k._)}
      </span>
      <strong>
        <ChatroomName id={id} />
      </strong>
      <CloseButton onClick={handleClose}>
        <Icon type="close" color={colors.active} />
      </CloseButton>
    </StyledFilterItem>
  );
};

export default Chatroom;
