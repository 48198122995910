// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalContent, ModalFooter } from "@unifize/sarah";
import { useSelector, useDispatch } from "react-redux";

import Input from "src/components/Unifize/Input/TextInput";
import ButtonLoader from "src/components/ButtonLoader";
import {
  getIsSaveFilterModalOpen,
  getIsEditFilterModalOpen,
  getCurrentClickedSavedFilter,
  getSavePinnedListError,
  getIsSaveFilterLoading
} from "src/reducers";
import {
  hideSaveFilterModal,
  savePinnedList,
  editPinnedList,
  hideEditFilterModal
} from "src/actions/filter";

import { Small as StyledModal } from "src/styles/modal";
import { ErrorText } from "src/styles/auth";
import { Secondary } from "src/styles/buttons";

const New = () => {
  const dispatch = useDispatch();
  const saveModal = useSelector(({ app }) => getIsSaveFilterModalOpen(app));
  const edit = useSelector(({ app }) => getIsEditFilterModalOpen(app));
  const currentClickedSavedFilter = useSelector(({ app }) =>
    getCurrentClickedSavedFilter(app)
  );
  const modalError = useSelector(({ app }) => getSavePinnedListError(app));

  const [name, setName] = useState(edit ? currentClickedSavedFilter : "");
  const isLoading = useSelector(({ app }) => getIsSaveFilterLoading(app));

  useEffect(() => {
    if (edit) {
      setName(currentClickedSavedFilter);
    }
  }, [currentClickedSavedFilter]);

  const handleChange = (e: SyntheticKeyboardEvent<HTMLInputElement>) => {
    setName(e.currentTarget.value);
  };

  const closeSaveFilterModal = (e: any) => {
    // $FlowFixMe
    e?.preventDefault();

    setName("");

    if (edit) {
      dispatch(hideEditFilterModal());
    } else {
      dispatch(hideSaveFilterModal());
    }
  };

  const saveFilter = (e: any) => {
    e.preventDefault();

    if (edit) {
      dispatch(editPinnedList(name));
    } else {
      dispatch(savePinnedList(name));
    }
  };

  return (
    <StyledModal id="filters-modal">
      <Modal
        height="auto"
        isOpen={saveModal || edit}
        onClose={closeSaveFilterModal}
      >
        <ModalHeader hideDivider>
          {edit ? i18n.t(k.EDIT_FILTER) : i18n.t(k.SAVE_FILTER)}
        </ModalHeader>
        <ModalContent>
          <Input
            placeholder={i18n.t(k.ENTER_FILTER_NAME)}
            value={name}
            onChange={handleChange}
            autoFocus
            required
          />
          {modalError && (
            <ErrorText>
              {" "}
              {edit
                ? i18n.t(k.ERROR_EDITING_FILTER)
                : i18n.t(k.FILTER_CANNOT_BE_SAVED)}
            </ErrorText>
          )}
        </ModalContent>
        <ModalFooter>
          <Secondary type="button" onClick={closeSaveFilterModal}>
            {i18n.t(k.CANCEL)}
          </Secondary>
          <ButtonLoader
            type="submit"
            handleClick={saveFilter}
            isLoading={isLoading}
          >
            {i18n.t(k.CONFIRM)}
          </ButtonLoader>
        </ModalFooter>
      </Modal>
    </StyledModal>
  );
};

export default New;
