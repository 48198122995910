// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import { connect, useDispatch, useSelector } from "react-redux";
import React, { useCallback } from "react";

import Cell from "./Cell";
import SubHeader from "./SubHeader";
import { setNewChartDialogAttributes, setCreateChart } from "src/actions/chart";
import Name from "src/components/Manage/Reports/Name";
import { getInstanceFilterId } from "src/reducers";
import { closeNewChartDialog } from "src/actions/chart";

import {
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Box,
  Flex
} from "@chakra-ui/react";

type Props = {
  reportId: string,
  handleClose: Function,
  _setNewChartDialogAttributes: Function
};

const Type = ({
  reportId,
  _setNewChartDialogAttributes,
  handleClose
}: Props) => {
  const dispatch = useDispatch();

  const workflowId = useSelector(({ app }) => getInstanceFilterId(app));

  const handleType = useCallback(
    (type: string) => {
      _setNewChartDialogAttributes({
        type,
        stage: 1
      });
      if (type === "comparison-stacked") {
        dispatch(
          setCreateChart({
            chartName: "comparison",
            reportId,
            workflowId
          })
        );
        dispatch(closeNewChartDialog());
      } else {
        if (type == "time-series-stacked") {
          _setNewChartDialogAttributes({
            timeInterval: "weekly",
            timePeriod: { timeDiff: 30 }
          });
        }

        _setNewChartDialogAttributes({
          type,
          stage: 1
        });
      }
    },
    [_setNewChartDialogAttributes]
  );

  const handleName = useCallback(
    (name: string) => {
      _setNewChartDialogAttributes({
        chartName: name
      });
    },
    [_setNewChartDialogAttributes]
  );

  return (
    <>
      <ModalHeader>
        {" "}
        {i18n.t(k.SELECT_A_CHART_FOR)} <Name id={reportId} />
        <ModalCloseButton bg="white" onClick={handleClose} />
      </ModalHeader>

      <ModalBody>
        <Box mb={4}>
          <SubHeader
            heading1={i18n.t(k.COUNT_OF_CONVERSATIONS)}
            heading2={i18n.t(k.FOLLOWING_CHARTS_REPRESENT_CONVERSATIONS)}
          />

          <Flex alignItems="center" mb={4}>
            <Cell
              chartType={i18n.t(k.BAR_CHART)}
              name="conversation-count-stacked"
              type="conversation-count"
              handleType={handleType}
              handleName={handleName}
            />

            <Cell
              chartType={i18n.t(k.PIE_CHART)}
              name="conversation-count-doughnut"
              type="conversation-count-doughnut"
              handleType={handleType}
              handleName={handleName}
            />
          </Flex>
        </Box>

        <Box mb={4}>
          <SubHeader
            heading1={i18n.t(k.COUNT_OF_FORMS)}
            heading2={i18n.t(k.FOLLOWING_CHARTS_REPRESENT_FORMS)}
          />

          <Flex alignItems="center" mb={4}>
            <Cell
              chartType={i18n.t(k.BAR_CHART)}
              name="form-count-stacked"
              type="form-count"
              handleType={handleType}
              handleName={handleName}
            />

            <Cell
              chartType={i18n.t(k.PIE_CHART)}
              name="form-count-doughnut"
              type="form-count-doughnut"
              handleType={handleType}
              handleName={handleName}
            />
          </Flex>
        </Box>

        <Box mb={4}>
          <SubHeader
            heading1={i18n.t(k.SUM_OF_NUMERIC_FIELD)}
            heading2={i18n.t(k.USE_CHARTS_FOR_TOTALS)}
          />

          <Flex alignItems="center" mb={4}>
            <Cell
              chartType={i18n.t(k.BAR_CHART)}
              name="numeric-field-stacked"
              type="conversation-count"
              handleType={handleType}
              handleName={handleName}
            />
          </Flex>
        </Box>

        <Box mb={4}>
          <SubHeader
            heading1={i18n.t(k.TIME_TRENDS)}
            heading2={i18n.t(k.USE_CHARTS_FOR_TRENDS)}
          />

          <Flex alignItems="center" mb={4}>
            <Cell
              chartType={i18n.t(k.BAR_CHART)}
              name="time-series-stacked"
              type="time-series"
              handleType={handleType}
              handleName={handleName}
            />
          </Flex>
        </Box>

        <Box mb={4}>
          <SubHeader
            heading1={i18n.t(k.COMPARISON_CHART)}
            heading2={i18n.t(k.USE_CHARTS_FOR_COMPARISON)}
          />

          <Flex alignItems="center" mb={4}>
            <Cell
              chartType={i18n.t(k.BAR_CHART)}
              name="comparison-stacked"
              type="comparison-stacked"
              handleType={handleType}
              handleName={handleName}
            />
          </Flex>
        </Box>
      </ModalBody>
    </>
  );
};

export default connect(null, {
  _setNewChartDialogAttributes: setNewChartDialogAttributes
})(Type);
