// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import { connect } from "react-redux";
import React from "react";

import { CriteriaSelect as StyledSegmentValue } from "../CriteriaSelect/styles";
import SelectAge from "./SelectAge";
import SelectGroup from "./SelectGroup";
import SelectRoles from "./SelectRoles";

import type { AppState, SegmentType } from "src/types";

type Props = {
  type: number,
  value: Array<number>,
  segmentType: SegmentType,
  handleSegmentValue: Function
};

const SegmentValue = ({
  type,
  value,
  segmentType,
  handleSegmentValue
}: Props) => {
  const typeName = segmentType[`${type}`]?.title;

  switch (typeName) {
    case "age_on_platform":
      return <SelectAge value={value} handleChange={handleSegmentValue} />;
    case "group_member":
      return <SelectGroup value={value} handleChange={handleSegmentValue} />;
    case "not_group_member":
      return <SelectGroup value={value} handleChange={handleSegmentValue} />;
    case "is_of_role":
      return <SelectRoles value={value} handleChange={handleSegmentValue} />;
    default:
      return <StyledSegmentValue>{i18n.t(k.SELECT_VALUES)}</StyledSegmentValue>;
  }
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  segmentType: app.orgSettings.segmentType
});

export default connect(mapStateToProps)(SegmentValue);
