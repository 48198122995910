// @flow

import * as atypes from "src/constants/actionTypes";

export const setNotificationSettings = (notification: number) => ({
  type: atypes.SET_USER_NOTIFICATION_SETTINGS_REQUEST,
  payload: {
    notification
  }
});
