// @flow

import React from "react";
type Props = {
  fill?: string,
  color?: string
};
const CaretDown = ({ fill, color = "black" }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <rect
      x="24"
      width="24"
      height="24"
      rx="12"
      transform="rotate(90 24 0)"
      fill={fill || "transparent"}
    />
    <path
      d="M7.41 8.58984L12 13.1698L16.59 8.58984L18 9.99984L12 15.9998L6 9.99984L7.41 8.58984Z"
      fill={color}
    />
  </svg>
);

export default CaretDown;
