import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";

const Header = () => (
  <thead>
    <tr>
      <td>{i18n.t(k.FILE_NAME)}</td>
      <td>{i18n.t(k.SIZE)}</td>
      <td>{i18n.t(k.LAST_MODIFIED)}</td>
      <td>{i18n.t(k.UPLOADED_BY)}</td>
    </tr>
  </thead>
);

export default Header;
