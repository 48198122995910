// @flow

import i18n from "i18next";
import k from "src/i18n/keys";
import React from "react";

import Header from "src/components/SearchHeader.old";

type Props = {
  text: string,
  handleSearch: Function,
  handleCreate?: Function
};

const TemplateHeader = ({ text, handleSearch, handleCreate }: Props) => (
  <Header
    text={text}
    handleChange={handleSearch}
    handleCreate={handleCreate}
    button={i18n.t(k.NEW_PROCESS_TEMPLATE)}
    instant
    showJSONImportExport
  />
);

export default TemplateHeader;
