// @flow

import i18n from "i18next";
import k from "src/i18n/keys";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Text } from "@unifize/sarah";
import { toast } from "react-toastify";

import SingleSelect from "src/components/Unifize/Select/SingleSelect";
import { switchLanguage, getSupportedLanguages } from "src/api/language";

import { getCurrentLanguage } from "src/reducers";
import { LanguageContainer, HelperText } from "./styles";

const LanguageSwitcher = () => {
  const [languages, setLanguages] = useState([]);

  const getLanguages = async () => {
    const response = await getSupportedLanguages();
    if (response) {
      setLanguages(response);
    }
  };

  useEffect(() => {
    getLanguages();
  }, []);

  const selectedLanguage = useSelector(({ app }) => getCurrentLanguage(app));

  const selectLanguage = (language: string) => {
    const isLanguageSupported = languages.some(
      item => item.langAbbr === language
    );
    if (isLanguageSupported) {
      // API call to switch language
      switchLanguage(language)
        .then(() => {
          i18n.changeLanguage(language);
          localStorage.setItem("currentLanguage", language);
          // Reload the app on langauge switch.
          window.location.reload();
        })
        .catch(error => {
          toast.error(i18n.t(k.FAILED_TO_SWITCH_LANGUAGE));
          console.error(error);
        });
    }
  };

  return (
    <LanguageContainer>
      <Text variant="caption" weight="bold">
        {i18n.t(k.LANGUAGE)}
      </Text>
      <SingleSelect
        data={languages}
        keys={["langAbbr", "englishName"]}
        onChange={selectLanguage}
        selected={selectedLanguage}
        placeholder={"Select language"}
        isDisabled={languages.length < 1}
      />
      <HelperText>
        {languages.length < 1
          ? i18n.t(k.LANGUAGE_SUPPORT_HINT)
          : i18n.t(k.LANGUAGE_SWITCHING_HINT)}
      </HelperText>
    </LanguageContainer>
  );
};

export default LanguageSwitcher;
