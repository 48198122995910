// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import { connect } from "react-redux";
import React, { useCallback } from "react";
import { toast } from "react-toastify";

import { getWorkflowInstanceFilter } from "src/reducers";
import BlueOutlineButton from "src/components/Buttons/BlueOutline";
import Icon from "src/icons";

import type { AppState, WorkflowInstanceFilter } from "src/types";

type Props = {
  filter: WorkflowInstanceFilter
};

const Share = ({ filter }: Props) => {
  const handleShare = useCallback(() => {
    const { page } = filter;
    const textField = document.createElement("textarea");
    textField.innerText = (window.location.href || "")
      .replace(`&page=${page}`, "")
      .replace(`page=${page}`, "");

    if (document.body) {
      document.body.appendChild(textField);
    }
    textField.select();
    document.execCommand("copy");
    textField.remove();

    toast.success(`Copied your current Process table view`);
  }, [filter]);

  return (
    <BlueOutlineButton
      leftIcon={<Icon type="shareDots" />}
      onClick={handleShare}
    >
      {i18n.t(k.SHARE)}
    </BlueOutlineButton>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  filter: getWorkflowInstanceFilter(app)
});

export default connect(mapStateToProps)(Share);
