// @flow

import React from "react";

import { RadioLabel, CustomRadio } from "../styles";
import Accordion from "src/components/Accordion.v2";
import AccordionContent from "../AccordionContent";
import { Checkbox, TextInput } from "src/components/Unifize";
import Radio from "src/components/Radio";
import type { ApprovalFieldSettingsV2 } from "src/types";

type Props = {
  settings: ApprovalFieldSettingsV2,
  saveSettings: Function
};

const BasicSettings = ({ settings, saveSettings }: Props) => {
  return (
    <Accordion title="Basic approval settings">
      <AccordionContent sx={{ gap: 2 }}>
        <CustomRadio>
          <Radio
            id="some"
            value="some"
            checked={settings?.requiredApprovers === "some"}
            handleChange={() => saveSettings(["requiredApprovers"], "some")}
          >
            <RadioLabel>Get signature of any</RadioLabel>
          </Radio>
          <TextInput
            type="number"
            placeholder="eg. 2"
            value={settings?.minApprovers}
            onChange={e => {
              if (Number(e.target?.value) < 1) {
                e.target.value = 1;
              }
              saveSettings(["minApprovers"], Number(e.target?.value ?? 1));
            }}
            min={1}
          />

          <RadioLabel>user(s)</RadioLabel>
        </CustomRadio>

        <Radio
          id="adhoc"
          value="adhoc"
          checked={settings?.requiredApprovers === "adhoc"}
          handleChange={() => saveSettings(["requiredApprovers"], "adhoc")}
        >
          <RadioLabel>Select ad-hoc</RadioLabel>
        </Radio>

        <Checkbox
          checked={settings?.showRequestApprovalButton}
          isDisabled={settings?.requiredApprovers === "adhoc"}
          label="Request approval button"
          onChange={value => saveSettings(["showRequestApprovalButton"], value)}
          height="inherit"
        />
      </AccordionContent>
    </Accordion>
  );
};

export default BasicSettings;
