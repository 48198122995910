// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";

import type { AdvancedBulkUpdateFunction } from "./index";

import {
  Modal,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button
} from "@chakra-ui/react";

type Props = {
  isOpen: boolean,
  onClose: Function,
  bulkUpdate: AdvancedBulkUpdateFunction
};

export default function AdvancedBulkUpdater({
  isOpen,
  onClose,
  bulkUpdate
}: Props) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{i18n.t(k.APPLY_BULK_CHANGES)}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{i18n.t(k.ARE_YOU_SURE_YOU_WANT_TO_APPLY)}</ModalBody>

        <ModalFooter>
          <Button
            colorScheme="blue"
            mr={3}
            onClick={() => bulkUpdate("append")}
          >
            {i18n.t(k.REPEAT_LAST_ACTION_TO_ALL)}
          </Button>

          <Button variant="ghost" onClick={() => bulkUpdate("replace")}>
            {i18n.t(k.REPLACE_ALL)}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
