// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React, { useCallback, useEffect, useRef } from "react";

import Overlay from "../../Overlay";
import Items from "./Items";
import { Options as StyledOptions, SubHeading } from "./styles";
import OutsideClickHandler from "src/components/OutsideClickHandler";
import useAutoPosition from "src/hooks/useAutoPosition";

import type { RoomId } from "src/types";

type Props = {
  outerRef: any,
  show: boolean,
  roomId: RoomId,
  location: string,
  handleClose: Function
};

const Options = ({ show, roomId, location, outerRef, handleClose }: Props) => {
  const dropdownRef = useRef(null);
  const { top, bottom } = useAutoPosition({
    outerRef: dropdownRef,
    initialTop: "auto",
    initialBottom: "24px",
    topOffset: "24px",
    bottomOffset: "24px"
  });

  const handleClickOutside = useCallback(
    (event: any) => {
      if (outerRef && !outerRef.current.contains(event.target)) {
        handleClose();
      }
    },
    [handleClose, outerRef]
  );

  const handleKeyDown = useCallback(
    (event: any) => {
      if (event.key === "Escape") {
        handleClose();
      }
    },
    [handleClose]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown, false);
    return () => {
      document.removeEventListener("keydown", handleKeyDown, false);
    };
  }, []);

  if (show) {
    return (
      <>
        <Overlay />
        <OutsideClickHandler onClickOutside={handleClickOutside}>
          <StyledOptions ref={dropdownRef} top={top} bottom={bottom}>
            <ul>
              <SubHeading>{i18n.t(k.UPDATE_STATUS)}</SubHeading>
              <Items
                _handleClose={handleClose}
                roomId={roomId}
                location={location}
              />
            </ul>
          </StyledOptions>
        </OutsideClickHandler>
      </>
    );
  }
  return null;
};

export default Options;
