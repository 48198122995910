import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";
import { Flex, Image, Text } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import Link from "redux-first-router-link";

import * as styles from "src/components/Manage/Reports/Instances/Chart/New/ComparisonChart/styles";
import DualYAxis from "./DualYAxis.svg";
import MultiSeries from "./MultiSeries.svg";
import StackedArea from "./StackedArea.svg";
import { setSelectedChart } from "src/actions/chart";
import { setReports } from "src/actions/reports";
import { getValueFromQuery } from "src/reducers";
import { comparisonCharts } from "src/constants/charts";
import Icon from "src/icons";

const chartImages = [
  {
    id: 1,
    name: comparisonCharts.DualYAxis,
    src: DualYAxis
  },
  {
    id: 2,
    name: comparisonCharts.StackedArea,
    src: StackedArea
  },
  {
    id: 3,
    name: comparisonCharts.MultiSeries,
    src: MultiSeries
  }
];

const ComparisonChart = () => {
  const chartName = useSelector(({ location }) =>
    getValueFromQuery("chart")(location)
  );
  const workflowId = useSelector(({ location }) =>
    getValueFromQuery("workflowId")(location)
  );
  const reportId = useSelector(({ location }) =>
    getValueFromQuery("reportId")(location)
  );

  const dispatch = useDispatch();

  const clickHandler = name =>
    dispatch(
      setSelectedChart({ chartName, selectedChart: name, reportId, workflowId })
    );

  return (
    <Flex sx={styles.ChartOverlay}>
      <Flex sx={styles.Header}>
        <Link
          style={{ display: "flex", alignItems: "center" }}
          to={setReports(reportId)}
        >
          <Icon type="backArrow" />
        </Link>
        <Text sx={{ m: 0, userSelect: "none" }}>
          {i18n.t(k.SELECT_A_COMPARISON_CHART)}
        </Text>
      </Flex>

      <Flex sx={styles.ChartWrapper}>
        {chartImages.map(val => {
          const { id, name, src } = val;
          return (
            <Image
              key={id}
              onClick={() => clickHandler(name)}
              name={name}
              sx={styles.ChartImage}
              src={src}
            />
          );
        })}
      </Flex>
    </Flex>
  );
};

export default ComparisonChart;
