// @flow

import { combineReducers } from "redux";
import { createSelector } from "reselect";
import * as R from "ramda";

import * as atypes from "src/constants/actionTypes";

import type { Action, TypingByRoom, RoomId, UID } from "src/types";

const chatroom = (state: boolean = false, { type }: Action) => {
  switch (type) {
    case atypes.SHOW_CHAT_MENU:
      // Reset and show list of items
      return false;
    case atypes.HIDE_CHAT_MENU:
      // Reset
      return false;
    case atypes.SET_CHAT_MENU_COMPONENT:
      return true;
    default:
      return state;
  }
};

const typing = (state: TypingByRoom = {}, { type, payload }: Action) => {
  switch (type) {
    case atypes.SYNC_TYPING_SUCCESS:
      return { ...state, [payload.roomId]: payload.typing };
    case atypes.INSERT_CHAT_REQUEST:
    case atypes.SYNC_TYPING_FAILURE:
      return {};
    default:
      return state;
  }
};

const status = (state: boolean = true, { type }: Action) => {
  switch (type) {
    case atypes.SET_CURRENT_CHATROOM_REQUEST:
    case atypes.HIDE_DOCK:
    case atypes.HIDE_SRW_DOCK:
      return true;
    case atypes.HIDE_CHATROOM_COMPONENT:
      return false;
    default:
      return state;
  }
};

const activeChat = combineReducers<Object, Action>({
  chatroom,
  status,
  typing
});

export default activeChat;

const getTyping = state => state.typing;

export const getChatroomTyping = (roomId: RoomId, uid: UID) =>
  createSelector([getTyping], typingByRoom => {
    if (typingByRoom[roomId]) {
      return R.filter(
        user => {
          return user.uid !== uid;
        },
        R.values(typingByRoom[roomId] || {})
      );
    }
    return [];
  });

export const getIsChatroomOpen = (state: Object) => state.status;
