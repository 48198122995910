// @flow

import React from "react";
import { connect } from "react-redux";
import type { List } from "immutable";
import * as R from "ramda";

import NoUnread from "./Placeholders/NoUnread";
import NoResultFound from "./Placeholders/NoResultFound";
import ChatroomList from "src/components/chatroom-nav/List";
import { showCustomStatusSegments } from "src/utils/filters";
import {
  getCurrentFilterWorkflow,
  getCurrentFilterSortBy,
  getCurrentFilter,
  getFilteredChatRooms,
  getToogleUnread
} from "src/reducers";

import type { AppState, RoomId, CurrentFilter } from "src/types";

type Props = {
  currentFilter: CurrentFilter,
  currentFilterSortBy: string,
  currentWorkflowId: number,
  rooms: List<RoomId>,
  unread: boolean,
  type: string,
  text: string
};

const SegmentOrNoConversations = ({
  currentFilterSortBy,
  currentWorkflowId,
  currentFilter,
  rooms,
  unread,
  type,
  text
}: Props) => {
  const { workflow } = currentFilter;
  const { defaultSegment, customSegment, prioritySegment } =
    showCustomStatusSegments(currentFilterSortBy, currentWorkflowId);
  const isDirect = R.includes("direct", type);
  const directMessagePlaceholder = isDirect && !unread && text === "";
  const renderPlaceholder = unread ? <NoUnread /> : <NoResultFound />;

  return defaultSegment || customSegment || prioritySegment ? (
    <ChatroomList rooms={rooms} workflow={workflow} />
  ) : directMessagePlaceholder ? null : (
    renderPlaceholder
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  unread: getToogleUnread(app),
  currentFilter: getCurrentFilter(app),
  currentFilterSortBy: getCurrentFilterSortBy(app),
  currentWorkflowId: getCurrentFilterWorkflow(app),
  rooms: getFilteredChatRooms(app)
});

export default connect(mapStateToProps)(SegmentOrNoConversations);
