// @flow

import styled from "styled-components";
import * as colors from "src/styles/constants/colors";

const Header = styled.div`
  height: 62px;
  padding: 24px;
  border-radius: 8px 8px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${colors.grey1};
`;

const Title = styled.p`
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  font-family: Lato;
`;

const CloseIcon = styled.span`
  width: 24px;
  height: 24px;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  > svg {
    cursor: pointer !important;
    width: 24px;
    height: 24px;
  }
`;

const ModalBody = styled.div`
  padding: 8px 24px;
  border-radius: 10px;
`;

const ModalFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 0.5rem;
  padding: 16px 24px;
`;

export { Header, Title, CloseIcon, ModalBody, ModalFooter };
