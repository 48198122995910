// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import { useDispatch, useSelector } from "react-redux";
import React, { useState, useCallback, useRef, useEffect } from "react";
import { useDebouncedCallback } from "use-debounce";

import { getIsDataProcess } from "src/reducers";

import List from "./List";
import Footer from "./Footer";
import { Dropdown as StyledDropdown } from "./styles";
import { Input } from "src/styles/input";
import { searchChatroom, searchChatroomSuccess } from "src/actions/chatroom";
import useComponentPermission from "src/hooks/useComponentPermission";
import { componentPermissions } from "src/constants/roleManagement";
import { searchChatroom as getChatroom } from "src/api/chatroom";

import type { ChatroomSearchOptions, RoomId } from "src/types";

type Props = {
  roomId: RoomId,
  showRemove?: boolean,
  workflow: ?number,
  type: string,
  selectedValue: Array<number>,
  handleClose: Function,
  handleNewRoom: Function,
  handleSelect: Function,
  handleCreate: Function,
  handleRemove?: Function,
  create: boolean,
  select: boolean,
  groupVersions?: boolean,
  selectNewRoomVersions?: Function,
  hideSelectedVersions?: boolean,
  dependentInclude?: ?Array<number>,
  dependentExclude?: ?Array<number>,
  alwaysShowRevisionIcon?: boolean,
  fromManageView: boolean,
  showArchived?: boolean
};

const Dropdown = ({
  roomId,
  showRemove,
  workflow,
  type,
  selectedValue,
  handleClose,
  handleNewRoom,
  handleSelect,
  handleCreate,
  handleRemove,
  create,
  select,
  groupVersions = false,
  selectNewRoomVersions = null,
  hideSelectedVersions,
  dependentInclude,
  dependentExclude,
  alwaysShowRevisionIcon,
  fromManageView = false,
  showArchived = false
}: Props) => {
  const dispatch = useDispatch();
  const workflowId = workflow ? String(workflow) : null;

  // If true, conversation details needs to fetched on demand.
  const isDataProcess = useSelector(({ app }) =>
    getIsDataProcess(app, workflowId)
  );

  const _searchChatroom = async (
    searchString: string,
    settings?: ChatroomSearchOptions = {}
  ) => {
    if (isDataProcess) {
      setLoading(true);
      const rooms: Array<Object> = await getChatroom({
        templateId: String(workflow),
        title: search
      });
      const byId = new Map(rooms.map(room => [room.id, room]));
      const roomIds: Array<RoomId> = rooms.map(({ id }) => id);

      setFetchedRooms(byId);
      dispatch(searchChatroomSuccess(roomIds));
      setLoading(false);
    } else {
      dispatch(searchChatroom(searchString, settings));
    }
  };

  const outerRef = useRef(null);
  const [search, setSearch] = useState("");
  const [fetchedRooms, setFetchedRooms] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [debouncedChatroomSearch] = useDebouncedCallback(_searchChatroom, 400);

  const showCreateAndSelect = create && select;

  useEffect(() => {
    // Search through all the chatrooms
    _searchChatroom("", {
      workflow: workflowId,
      type,
      showArchived
    });
  }, [workflow, type]);

  const closeDropdown = useCallback(
    (event: any) => {
      if (outerRef && outerRef.current) {
        if (!outerRef.current.contains(event.target)) {
          handleClose();
        }
      }
    },
    [outerRef, handleClose]
  );

  const handleKeyDown = useCallback(
    (event: any) => {
      // Close on esc
      if (event.keyCode === 27) {
        handleClose();
      }
    },
    [handleClose]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown, false);
    document.addEventListener("mousedown", closeDropdown, false);
    return () => {
      document.removeEventListener("keydown", handleKeyDown, false);
      document.removeEventListener("mousedown", closeDropdown, false);
    };
  }, []);

  const handleSearch = useCallback(
    (event: any) => {
      const { value } = event.target;
      setSearch(value);
      debouncedChatroomSearch(value, {
        workflow: workflowId,
        type,
        showArchived
      });
    },
    [setSearch, workflow, type, debouncedChatroomSearch]
  );

  const chatroomCreationEnabled = useComponentPermission(
    componentPermissions.checklistNewConversation
  );

  return (
    <StyledDropdown ref={outerRef}>
      <Input
        type="text"
        value={search}
        onChange={handleSearch}
        // if only create then show "Enter title"
        placeholder={!select ? i18n.t(k.ENTER_TITLE) : i18n.t(k.SEARCH)}
        autoFocus
      />

      <div style={{ position: fromManageView ? "block" : "absolute" }}>
        {(!create || showCreateAndSelect) && (
          <List
            roomId={roomId}
            showRemove={Boolean(showRemove)}
            handleSelect={handleSelect}
            handleRemove={handleRemove}
            selectedValue={selectedValue}
            groupVersions={groupVersions}
            selectNewRoomVersions={selectNewRoomVersions}
            hideSelectedVersions={hideSelectedVersions}
            dependentInclude={dependentInclude}
            dependentExclude={dependentExclude}
            alwaysShowRevisionIcon={Boolean(alwaysShowRevisionIcon)}
            chatRooms={fetchedRooms}
            isDataProcess={isDataProcess}
          />
        )}
        {(!select || showCreateAndSelect) && chatroomCreationEnabled && (
          <Footer
            text={search}
            workflow={workflow}
            type={type}
            handleCreate={handleCreate}
            handleNewRoom={handleNewRoom}
            isLoading={isLoading}
          />
        )}
      </div>
    </StyledDropdown>
  );
};

export default Dropdown;
