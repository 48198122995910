// @flow

import * as R from "ramda";
import { toast } from "react-toastify";
import { takeEvery, call, put, select } from "redux-saga/effects";

import {
  getEmailState,
  getCurrentChatroom,
  getUserEmailsFromIds
} from "src/selectors";
import * as atypes from "src/constants/actionTypes";
import * as api from "src/api/email";
import * as fileApi from "src/api/file";

import type { Action } from "src/types";

function* sendEmail({ payload }: Action): any {
  try {
    const chatroomId = yield select(getCurrentChatroom);
    const emailState = yield select(getEmailState);

    let emailData = emailState.data;

    if (R.isNil(emailData.headers)) {
      emailData = R.omit(["headers"], emailData);
    }

    const skipInvites = payload;

    let uniqueOrgMemberRecipients = [];

    const recipientProps = ["to", "cc", "bcc"];

    recipientProps.forEach(prop => {
      emailData[prop].forEach(item => {
        if (R.is(String, item)) {
          uniqueOrgMemberRecipients.push(item);
        }
      });
    });

    uniqueOrgMemberRecipients = R.uniq(uniqueOrgMemberRecipients);

    const emailsOfOrgMemberRecipients = yield select(
      getUserEmailsFromIds(uniqueOrgMemberRecipients)
    );

    recipientProps.forEach(prop => {
      emailData[prop] = emailData[prop].map(item => {
        if (R.is(String, item)) {
          return { email: emailsOfOrgMemberRecipients[item] };
        }

        const { email } = item;

        if (skipInvites) return { email, skipInvite: true };

        const role = emailState.nonOrgRecipients[email];

        return role ? { email, orgRole: role } : { email, skipInvite: true };
      });
    });

    const { tempAttachments, attachments } = emailState;

    const files = attachments.map(item => item.name);

    const newFiles = Object.keys(tempAttachments).map(fileId => ({
      name: fileId,
      ...tempAttachments[fileId].meta
    }));

    const emailPayload = {
      ...emailData,
      files,
      newFiles
    };

    yield call(api.sendEmail, {
      chatroomId,
      payload: emailPayload
    });

    yield put({
      type: atypes.SEND_EMAIL_SUCCESS,
      payload: {}
    });

    toast.success("Email sent successfully!");
  } catch (e) {
    if ((e || {}).status === 403) {
      toast.error(
        "Email failure to send notification: One of more of the email IDs you've entered is blocked by the admin"
      );
    } else {
      toast.error("Error sending email!");
    }
    yield put({
      type: atypes.SEND_EMAIL_FAILURE,
      payload: { e }
    });
  }
}

function* watchSendEmail(): any {
  yield takeEvery(atypes.SEND_EMAIL_REQUEST, sendEmail);
}

function* attachFile({ payload }: Action): any {
  let fileId;
  try {
    const { file } = payload;

    const {
      name,
      url: signedURL,
      contentType
    } = yield call(fileApi.getSignedURLForEmailAttachment, {
      originalName: file.name
    });
    fileId = name;

    const handleProgress = (progressEvent: any) => {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      payload.dispatch({
        type: atypes.UPDATE_TEMP_ATTACHMENT_PROGRESS,
        payload: { name, progress: percentCompleted }
      });
    };

    const response = yield call(fileApi.uploadFileToAWS, {
      signedURL,
      contentType,
      fileData: file,
      name,
      handleProgress
    });

    const versionId =
      response.headers["x-ms-version-id"] ??
      response.headers["x-amz-version-id"];
    yield put({
      type: atypes.ADD_TEMP_ATTACHMENT_SUCCESS,
      payload: {
        name,
        meta: {
          versionId,
          size: file.size,
          originalName: file.name
        }
      }
    });
  } catch (error) {
    console.log(error);
    yield put({
      type: atypes.ATTACH_FILE_TO_EMAIL_FAILURE,
      payload: { error }
    });
    yield put({
      type: atypes.ADD_TEMP_ATTACHMENT_FAILURE,
      payload: { name: fileId }
    });
  }
}

function* watchAttachFile(): any {
  yield takeEvery(atypes.ATTACH_FILE_TO_EMAIL_REQUEST, attachFile);
}

function* generateChatroomEmail({ payload }: Action): any {
  try {
    const { chatroomId } = payload;
    const response = yield call(
      api.generateChatroomEmail,
      parseInt(chatroomId, 10)
    );
    yield put({
      type: atypes.GENERATE_CHATROOM_EMAIL_SUCCESS,
      payload: response
    });
  } catch (e) {
    toast.error(`Error generating email for the chatroom!`);
    yield put({
      type: atypes.GENERATE_CHATROOM_EMAIL_FAILURE,
      payload: { e }
    });
  }
}

function* watchGenerateChatroomEmail(): any {
  yield takeEvery(
    atypes.GENERATE_CHATROOM_EMAIL_REQUEST,
    generateChatroomEmail
  );
}

export default [
  watchSendEmail(),
  watchAttachFile(),
  watchGenerateChatroomEmail()
];
