// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { getCurrentUserName } from "src/reducers";
import * as orgCloneActions from "src/actions/orgClone";
import { orgCloneWizardSteps } from "src/constants";
import type { OrgCloneWizardSteps, OrgCloneState } from "src/types";
import { getOrgCloneState } from "src/selectors";

import { Box, Button, HStack, Text, VStack } from "@chakra-ui/react";
import Email from "./Email";
import SignUp from "./SignUp";
import OrgInfo from "./OrgInfo";
import VerificationEmailNotice from "./VerificationEmailNotice";
import SignIn from "./SignIn";
import OrgCreationStatus from "./OrgCreationStatus";
import * as styles from "./styles";

type InfoHeaderProps = {
  step: OrgCloneWizardSteps
};

function InfoHeader({ step }: InfoHeaderProps) {
  const dispatch = useDispatch();

  const displayName = useSelector(state => getCurrentUserName(state.app));

  const handleLogout = () => {
    dispatch(orgCloneActions.logout());
  };

  switch (step) {
    case orgCloneWizardSteps.email:
      return (
        <Box
          sx={{
            width: "100%"
          }}
        >
          <Text
            textStyle="wizardStage"
            sx={{
              mb: "16px"
            }}
          >
            {i18n.t(k.STEP_OF)}
          </Text>

          <Text
            textStyle="displayMedium"
            sx={{
              mb: "8px"
            }}
          >
            {i18n.t(k.SIGN_UP)}
          </Text>

          <Text textStyle="displaySmall">
            {i18n.t(k.SPEED_UP_YOUR_PROCESSES_BY_X)}
          </Text>
        </Box>
      );

    case orgCloneWizardSteps.signUp:
      return (
        <Box
          sx={{
            width: "100%"
          }}
        >
          <Text
            textStyle="wizardStage"
            sx={{
              mb: "16px"
            }}
          >
            {i18n.t(k.STEP_OF1)}
          </Text>

          <Text
            textStyle="displayMedium"
            sx={{
              mb: "8px"
            }}
          >
            {i18n.t(k.CREATE_ACCOUNT)}
          </Text>

          <Text textStyle="displaySmall">
            {i18n.t(k.SPEED_UP_YOUR_PROCESSES_BY_X)}
          </Text>
        </Box>
      );

    case orgCloneWizardSteps.signIn:
      return (
        <Box
          sx={{
            width: "100%"
          }}
        >
          <Text
            textStyle="wizardStage"
            sx={{
              mb: "16px"
            }}
          >
            {i18n.t(k.STEP_OF1)}
          </Text>

          <Text
            textStyle="displayMedium"
            sx={{
              mb: "8px"
            }}
          >
            {i18n.t(k.SIGN_IN)}
          </Text>

          <Text textStyle="displaySmall">
            {i18n.t(k.YOU_HAVE_ALREADY_USED_THIS_EMA)}
          </Text>
        </Box>
      );

    case orgCloneWizardSteps.createOrg:
      return (
        <Box
          sx={{
            width: "100%"
          }}
        >
          <HStack
            sx={{
              mb: "16px",
              width: "100%",
              justifyContent: "space-between"
            }}
          >
            <Text textStyle="wizardStage">{i18n.t(k.STEP_OF2)}</Text>

            <Button variant="transparentLink" onClick={handleLogout}>
              {i18n.t(k.SIGN_OUT)}
            </Button>
          </HStack>

          <Text
            textStyle="displayMedium"
            sx={{
              mb: "8px"
            }}
          >
            {i18n.t(k.LAST_STEP)} {displayName}
            {i18n.t(k.INVITE_YOUR_COLLABORATORS)}
          </Text>

          <Text textStyle="displaySmall">
            {i18n.t(k.NO_MORE_SILOS_INVITE_UP_TO)}
          </Text>
        </Box>
      );

    default:
      return (
        <div>
          {i18n.t(k.STAGE_INFO_HEADER)} {step}
        </div>
      );
  }
}

export default function StageContent() {
  const dispatch = useDispatch();

  const location = useSelector(state => state.location);
  const orgId = Number(location.query.orgId);

  const { wizard: orgCloneWizard }: OrgCloneState =
    useSelector(getOrgCloneState);
  const { step, cloneStatus } = orgCloneWizard;

  const updateData = (prop: string[]) => value => {
    dispatch(
      orgCloneActions.updateData({
        prop,
        value
      })
    );
  };

  switch (step) {
    case orgCloneWizardSteps.email:
      return (
        <VStack sx={styles.container}>
          <InfoHeader step={step} />
          <Email
            email={orgCloneWizard.fields.email}
            onChange={updateData(["email"])}
          />
        </VStack>
      );

    case orgCloneWizardSteps.signUp:
      return (
        <VStack sx={styles.container}>
          <InfoHeader step={step} />
          <SignUp
            firstName={orgCloneWizard.fields.firstName}
            lastName={orgCloneWizard.fields.lastName}
            password={orgCloneWizard.fields.password}
            confirmPassword={orgCloneWizard.fields.confirmPassword}
            onChange={updateData}
          />
        </VStack>
      );

    case orgCloneWizardSteps.createOrg:
      return (
        <VStack sx={styles.container}>
          <InfoHeader step={step} />
          <OrgInfo wizardData={orgCloneWizard.fields} onChange={updateData} />
        </VStack>
      );

    case orgCloneWizardSteps.verificationEmailSent:
      return (
        <VerificationEmailNotice
          email={orgCloneWizard.fields.email.value}
          orgId={orgId}
        />
      );

    case orgCloneWizardSteps.signIn:
      return (
        <VStack sx={styles.container}>
          <InfoHeader step={step} />
          <SignIn
            password={orgCloneWizard.fields.password}
            onChange={updateData(["password"])}
          />
        </VStack>
      );

    case orgCloneWizardSteps.creatingOrg:
      return (
        cloneStatus && (
          <OrgCreationStatus
            cloneStatus={cloneStatus}
            orgName={orgCloneWizard.fields.orgName.value}
          />
        )
      );

    default:
      return (
        <div>
          {i18n.t(k.STAGE)} {step}
        </div>
      );
  }
}
