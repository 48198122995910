// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import { connect, useSelector, useDispatch } from "react-redux";
import React, { useState, useEffect, useCallback } from "react";
import * as R from "ramda";
import { useDebouncedCallback } from "use-debounce";

import {
  Filter as StyledFilter,
  ListItem,
  SubHeading,
  Chatroom as StyledChatroom,
  Separator
} from "./styles";
import WebWorker from "src/workers/WebWorker";
import worker from "src/workers/app.worker";
import { searchChatroom } from "src/actions/chatroom";
import {
  setManageViewFilter,
  filterEmbeddedFields
} from "src/actions/workflows";
import Checkbox from "src/components/Checkbox";
import Name from "src/containers/chatroom/Name";
import AllRecordsFilter from "./AllRecordsFilter";
import {
  getSearchUniqueValues,
  getChecklistFieldsById,
  getAllWorkflows,
  getAllRecords,
  getIsDataProcess
} from "src/reducers";
import { Input } from "src/styles/input";
import BlanksNonBlanks from "./BlanksNonBlanks";
import FetchedConversations from "./FetchedConversations";
import { searchChatroom as getChatroom } from "src/api/chatroom";

import type { AppState, RoomId, FilterWorkerData } from "src/types";

type Props = {
  column: string,
  values: Array<number>,
  parentRef: any,
  _setManageViewFilter: Function,
  handleClose: Function,
  _searchChatroom: Function,
  setFilter: Function,
  toggleSortBy: Function,
  filter: Array<RoomId>,
  templateId?: string
};

const Chatroom = ({
  parentRef,
  values,
  column,
  _setManageViewFilter,
  handleClose,
  _searchChatroom,
  setFilter,
  filter,
  toggleSortBy,
  templateId = null
}: Props) => {
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [fetchedValues, setFetchedValues] = useState([]);
  const allRecords = useSelector(({ app }) => getAllRecords(app));
  const workflowId = templateId ? String(templateId) : null;
  const isDataProcess = useSelector(({ app }) =>
    getIsDataProcess(app, workflowId)
  );

  const handleServerSearch = async (searchText: string) => {
    try {
      setLoading(true);
      const rooms: Array<Object> = await getChatroom({
        templateId: workflowId,
        title: searchText
      });
      if (rooms) setFetchedValues(rooms);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.error("Error fetching values", e);
    }
  };

  const [debouncedSearchChatroom] = useDebouncedCallback(_searchChatroom, 800);
  const [debouncedServerSearch] = useDebouncedCallback(handleServerSearch, 800);
  const dispatch = useDispatch();

  const workflow = useSelector(({ app }) => getAllWorkflows(app));
  const fieldsById = useSelector(({ app }) => getChecklistFieldsById(app));
  const filters = workflow.instanceFilter;
  const chatroomAttributes = ["owner", "dueDate", "status"];
  const instancesById = workflow.instancesById;
  const updatedInstances = { ...instancesById };

  let workerData: FilterWorkerData = {
    workflow,
    fieldsById: fieldsById.toJS(),
    instancesById,
    filters,
    chatroomAttributes,
    updatedInstances,
    allRecords
  };

  useEffect(() => {
    _searchChatroom("", {
      searchType: "uniqueConversation",
      column: `${column}`
    });
    return () => {
      _searchChatroom("", {
        searchType: "uniqueConversation",
        column: `${column}`
      });
    };
  }, []);

  useEffect(() => {
    if (isDataProcess) {
      handleServerSearch("");
    }
  }, []);

  const preventDefault = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleSearch = useCallback(
    (event: any) => {
      preventDefault(event);
      setSearch(event.target.value);
      if (isDataProcess) {
        debouncedServerSearch(event.target.value);
      } else {
        debouncedSearchChatroom(event.target.value, {
          searchType: "uniqueConversation",
          column: `${column}`
        });
      }
    },
    [setSearch, debouncedSearchChatroom]
  );

  const closeModal =
    ((event: any) => {
      if (event.keyCode === 13) {
        handleClose();
      }
    },
    [handleClose]);

  const clickOutside = useCallback(
    (event: any) => {
      if (parentRef && parentRef.current) {
        if (!parentRef.current.contains(event.target)) {
          handleClose();
        }
      }
    },
    [parentRef, handleClose]
  );

  useEffect(() => {
    // $FlowFixMe
    document.addEventListener("keydown", closeModal, false);
    document.addEventListener("click", clickOutside, false);
    return () => {
      // $FlowFixMe
      document.removeEventListener("keydown", closeModal, false);
      document.removeEventListener("click", clickOutside, false);
    };
  }, []);

  const handleSort = (ascending: boolean) => {
    if (ascending) {
      toggleSortBy(false, true);
    } else {
      toggleSortBy(true, true);
    }
    handleClose();
  };

  const handleSelect = useCallback(
    (event: Event, item: string | number) => {
      event.preventDefault();
      event.stopPropagation();

      const webWorker = new WebWorker(worker);
      if (R.includes(item, filter || [])) {
        // $FlowFixMe - Flow type infer error, type of event listener not clear
        webWorker.addEventListener("message", event => {
          dispatch(filterEmbeddedFields({ instances: event.data }));
        });
        workerData = {
          ...workerData,
          payload: {
            ...filters,
            [column]: R.reject(R.equals(item), filter)
          }
        };

        webWorker.postMessage(workerData);
        setFilter(column, R.reject(R.equals(item), filter));
      } else {
        // $FlowFixMe- Flow type infer error, type of event listener not clear
        webWorker.addEventListener("message", event => {
          dispatch(filterEmbeddedFields({ instances: event.data }));
        });

        workerData = {
          ...workerData,
          payload: {
            ...filters,
            [column]: [...(filter || []), item]
          }
        };

        webWorker.postMessage(workerData);
        setFilter(column, [...(filter || []), item]);
      }
    },
    [column, filter, _setManageViewFilter, allRecords]
  );

  const allRecordsColumnId = column.includes("-")
    ? column.split("-")[0]
    : column;

  return (
    <StyledFilter onClick={preventDefault} fullWidth={true}>
      <SubHeading>{i18n.t(k.SORT_BY2)}</SubHeading>
      <ul>
        <ListItem tabIndex="0" role="button" onClick={() => handleSort(true)}>
          {i18n.t(k.ASCENDING)}
        </ListItem>
        <ListItem tabIndex="0" role="button" onClick={() => handleSort(false)}>
          {i18n.t(k.DESCENDING)}
        </ListItem>
      </ul>
      <Separator />
      <SubHeading>{i18n.t(k.FILTER)}</SubHeading>
      <BlanksNonBlanks handleSelect={handleSelect} filter={filter} />
      <Separator />
      <AllRecordsFilter
        label={i18n.t(k.ALL_EMBEDDED_RECORDS)}
        isAllRecords={allRecords[allRecordsColumnId]}
        columnId={allRecordsColumnId}
        workerData={workerData}
      />

      <Input
        type="text"
        value={search}
        onChange={handleSearch}
        placeholder={i18n.t(k.SEARCH)}
        autoFocus
      />

      {isDataProcess ? (
        <FetchedConversations
          chatRooms={fetchedValues}
          handleSelect={handleSelect}
          filter={filter}
          isLoading={loading}
        />
      ) : (
        <ul>
          {values.map(value => (
            <StyledChatroom
              tabIndex="0"
              role="button"
              key={value}
              onClick={event => handleSelect(event, value)}
            >
              <Checkbox
                id={`chatroom${value}`}
                checked={R.includes(value, filter || [])}
              />

              <Name id={value} />
            </StyledChatroom>
          ))}
        </ul>
      )}
    </StyledFilter>
  );
};

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  values: getSearchUniqueValues(app, props.column)
});

export default connect(mapStateToProps, {
  _setManageViewFilter: setManageViewFilter,
  _searchChatroom: searchChatroom
})(Chatroom);
