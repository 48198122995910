// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React, { useRef, useCallback, useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import * as R from "ramda";

import SelectionMenu from "./SelectionMenu";
import {
  Join as StyledJoin,
  LoadingConversation as StyledLoadingConversation
} from "./styles";
import Reply from "src/containers/messages/Reply";
import Chat from "src/containers/chatroom/Input/Chat";
import ProgressBar from "src/containers/chatroom/ProgressBar";
import AddChatroom from "src/containers/chatroom/creator";
import PulseLoader from "src/components/PulseLoader";

import { addRoomMember, updateLastRead } from "src/actions/chatroom";
import {
  hideChatMenu,
  clearReplyTo,
  clearMessageSelection
} from "src/actions/chatmenu";
import {
  getChatroomPrivacy,
  getMessageData,
  getReplyTo,
  getCurrentUserId,
  getChatroomType,
  getChatroomMembership,
  isProcessOwner,
  getWorkflowWhitelistMembership,
  getSelectedAction,
  getSelectedRoomId,
  getCurrentRoomId,
  getConversationModalId,
  getConversationModalVisibility
} from "src/reducers";

import { conversationTypes } from "src/constants/display";
import { JoinButton } from "src/styles/buttons.old";

import type { AppState, RoomId, UID, Reply as ReplyType } from "src/types";

type Props = {
  isProcessOwner: boolean,
  creating: Array<number>,
  roomId: RoomId,
  privacy: string,
  type: string,
  membership: boolean,
  menu: boolean,
  currentMessage: Object,
  currentUser: UID,
  orgId: number,
  emailModal: boolean,
  hideMenu: Function,
  whitelistMembership: boolean,
  _updateLastRead: typeof updateLastRead,
  _clearReplyTo: typeof clearReplyTo,
  replyToId: RoomId,
  _clearSelection: typeof clearMessageSelection,
  selectedAction: string,
  selectedRoomId: RoomId
};

const Input = ({
  isProcessOwner,
  creating,
  menu,
  type,
  currentMessage,
  membership,
  currentUser,
  orgId,
  hideMenu,
  emailModal,
  roomId,
  privacy,
  whitelistMembership,
  _updateLastRead,
  _clearReplyTo,
  replyToId,
  _clearSelection,
  selectedAction,
  selectedRoomId
}: Props) => {
  useEffect(() => {
    _clearReplyTo();
    _clearSelection();
  }, [roomId]);

  const newRef = useRef();

  const [show, setShow] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(true);
    }, 4000);
    return () => clearTimeout(timer);
  }, [setShow]);

  const joinChannel = useCallback(() => {
    if (roomId) {
      dispatch(
        addRoomMember({
          users: currentUser,
          groups: null,
          roomId,
          request: true
        })
      );
      _updateLastRead(roomId, currentUser, orgId);
    }
  }, [roomId, orgId, currentUser, addRoomMember, _updateLastRead]);

  if (selectedRoomId && selectedRoomId == roomId) {
    return <SelectionMenu showSelect={selectedAction} roomId={roomId} />;
  }

  if (R.includes(parseInt(roomId, 10), creating)) {
    return (
      <StyledLoadingConversation>
        <h4>{i18n.t(k.LOADING_MESSAGES)}</h4>
        <PulseLoader w="100px" h="8px" />
      </StyledLoadingConversation>
    );
  }

  if (!roomId) {
    return null;
  }

  return (
    <>
      <div ref={newRef}>
        {roomId == replyToId && <Reply />}
        {!emailModal && <ProgressBar roomId={roomId} />}
        {membership ? (
          <Chat roomId={roomId} currentMessage={currentMessage} />
        ) : privacy !== "content" || isProcessOwner ? (
          <StyledJoin>
            {show && (
              <JoinButton type="button" onClick={joinChannel}>
                {i18n.t(k.JOIN)} {R.head(conversationTypes[type] || [])}
              </JoinButton>
            )}
          </StyledJoin>
        ) : privacy === "content" && whitelistMembership ? (
          <StyledJoin>
            {show && (
              <JoinButton type="button" onClick={joinChannel}>
                {i18n.t(k.JOIN)} {R.head(conversationTypes[type] || [])}
              </JoinButton>
            )}
          </StyledJoin>
        ) : null}
      </div>

      {menu && (
        <AddChatroom
          position="center"
          autoPopulateParent
          handleClose={hideMenu}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => {
  const replyTo: ReplyType = getReplyTo(app);
  const modal = getConversationModalVisibility(app);
  const currentRoomId = getCurrentRoomId(app);
  const conversationModalRoomId = getConversationModalId(app);
  const roomId =
    modal && conversationModalRoomId ? conversationModalRoomId : currentRoomId;
  const { to, roomId: replyToId } = replyTo;
  return {
    creating: app.chatRooms.creating,
    isProcessOwner: isProcessOwner(app, roomId),
    privacy: getChatroomPrivacy(app, roomId),
    type: getChatroomType(app, roomId),
    membership: getChatroomMembership(app, roomId),
    menu: app.activeChat.chatroom,
    currentMessage: getMessageData(app, to),
    whitelistMembership: getWorkflowWhitelistMembership(
      app,
      app.chatRooms.filters.templateId,
      app.currentUser.uid
    ),
    currentUser: getCurrentUserId(app),
    orgId: app.orgs.last,
    emailModal: app.email.stage,
    replyToId,
    roomId,
    selectedAction: getSelectedAction(app),
    selectedRoomId: getSelectedRoomId(app)
  };
};

export default connect(mapStateToProps, {
  hideMenu: hideChatMenu,
  _updateLastRead: updateLastRead,
  _clearReplyTo: clearReplyTo,
  _clearSelection: clearMessageSelection
})(Input);
