// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import { connect } from "react-redux";
import React, { useCallback } from "react";

import {
  FormControl,
  FormLabel,
  Box,
  Button,
  Input,
  FormHelperText
} from "@chakra-ui/react";
import FieldSelect from "../FieldSelect";
import MultipleSelection from "./MultipleSelection";
import {
  setNewChartDialogAttributes,
  createChart,
  updateChart
} from "src/actions/chart";
import { inkLight } from "src/styles/constants/colors";

import type { AppState, NewChartDialog } from "src/types";

type Props = {
  newChartDialog: NewChartDialog,
  _createChart: Function,
  _editChart: Function,
  _setNewChartDialogAttributes: Function,
  name: string
};

const CountBarChart = ({
  newChartDialog,
  _createChart,
  _editChart,
  _setNewChartDialogAttributes,
  name
}: Props) => {
  const { title, x, computationField } = newChartDialog;
  const isDisabled =
    title.length === 0 ||
    x === null ||
    (name === "form-count-doughnut" && computationField === null);
  const handleYaxisLabel = useCallback(
    (event: any) => {
      _setNewChartDialogAttributes({
        yAxisLabel: event.target.value
      });
    },
    [_setNewChartDialogAttributes]
  );

  const handlePrimaryField = useCallback(
    (value: ?string) => {
      _setNewChartDialogAttributes({
        x: value
      });
    },
    [_setNewChartDialogAttributes]
  );

  const handleFields = useCallback(() => {
    _setNewChartDialogAttributes({
      loading: true
    });
    if (newChartDialog.edit) {
      _editChart();
    } else {
      _createChart();
    }
  }, [_setNewChartDialogAttributes, _editChart, _createChart, newChartDialog]);

  const handleFormFieldSelect = (value: string = "") => {
    _setNewChartDialogAttributes({
      computationField: value
    });
  };

  return (
    <>
      {name === "form-count-doughnut" ? (
        <FormControl id="form" mb={3}>
          <FormLabel fontWeight={600}>{i18n.t(k.SELECT_FORM1)}</FormLabel>
          <FormHelperText color={inkLight}>
            {i18n.t(k.THE_COUNT_WILL_REPRESENT_THE_T)}
          </FormHelperText>
          <FieldSelect
            isForm
            value={newChartDialog.computationField}
            handleSelect={handleFormFieldSelect}
            fieldType="form"
          />
        </FormControl>
      ) : null}

      <FormControl id="primaryField" mb={3}>
        <FormLabel>{i18n.t(k.PRIMARY_FIELD)}</FormLabel>
        <FormHelperText>
          {i18n.t(k.PRIMARY_FIELDS_ARE_THE_MAIN_UN)}
        </FormHelperText>
        <FieldSelect
          value={newChartDialog.x}
          dependentField={newChartDialog.y}
          handleSelect={handlePrimaryField}
        />
      </FormControl>

      <FormControl id="yAxisLabel" mb={3}>
        <FormLabel>{i18n.t(k.Y_AXIS_LABEL)}</FormLabel>
        <FormHelperText>
          {i18n.t(k.LABEL_FOR_Y_AXIS_OF_THE_CHART)}
        </FormHelperText>
        <Input
          type="text"
          value={newChartDialog.yAxisLabel}
          onChange={handleYaxisLabel}
          placeholder={i18n.t(k.ENTER_LABEL)}
          size="sm"
        />
      </FormControl>

      <FormControl id="multipleValue" mb={3}>
        <FormLabel>{i18n.t(k.MULTIPLE_VALUE_CALCULATION)}</FormLabel>
        <FormHelperText>
          {i18n.t(k.IF_A_FIELD_CONTAINS_MULTIPLE_V2)}
        </FormHelperText>
        <MultipleSelection value={newChartDialog.relativeCount} />
      </FormControl>

      <Box mb={3} mt={6}>
        {newChartDialog.edit ? (
          <Button
            onClick={handleFields}
            disabled={isDisabled}
            type="button"
            isLoading={newChartDialog.loading}
            size="sm"
            colorScheme="blue"
          >
            {i18n.t(k.SAVE_CHANGES1)}
          </Button>
        ) : (
          <Button
            onClick={handleFields}
            disabled={isDisabled}
            type="button"
            isLoading={newChartDialog.loading}
            size="sm"
            colorScheme="blue"
          >
            {i18n.t(k.CREATE_AND_SAVE)}
          </Button>
        )}
      </Box>
    </>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  newChartDialog: app.charts.newChartDialog
});

export default connect(mapStateToProps, {
  _setNewChartDialogAttributes: setNewChartDialogAttributes,
  _createChart: createChart,
  _editChart: updateChart
})(CountBarChart);
