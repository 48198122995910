// @flow

import * as React from "react";
import { Portal } from "@chakra-ui/react";

import { Modalwrapper, Overlay } from "./styles";
import OutsideClickHandler from "src/components/OutsideClickHandler";

type Props = {
  handleClose: () => void,
  isOpen: boolean,
  children?: React.Node,
  targetRef?: any,
  height?: number,
  isParent?: boolean
};

const EditModal = ({
  isOpen,
  handleClose,
  children,
  targetRef,
  height,
  isParent
}: Props) =>
  isOpen ? (
    <Portal>
      <Overlay>
        <OutsideClickHandler onClickOutside={handleClose}>
          <Modalwrapper ref={targetRef} height={height} isParent={isParent}>
            {children}
          </Modalwrapper>
        </OutsideClickHandler>
      </Overlay>
    </Portal>
  ) : (
    <></>
  );

export default EditModal;
