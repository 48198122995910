// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import { connect } from "react-redux";
import * as R from "ramda";
import React, { useState, useEffect, useCallback } from "react";
import onClickOutside from "react-onclickoutside";

import { List as StyledList, Input, ListItem } from "../styles";
import { searchWorkflow } from "src/actions/workflows";
import { setUserAnalytics } from "src/actions/userAnalytics";
import Checkbox from "src/components/Checkbox";
import Name from "src/containers/workflow/Name";

import type { AppState, UserAnalyticsFilter } from "src/types";

type Props = {
  searchResults: Array<string>,
  outerRef: any,
  filter: UserAnalyticsFilter,
  _searchWorkflow: Function,
  _setUserAnalytics: Function,
  handleClose: Function
};

const List = ({
  outerRef,
  searchResults,
  filter,
  _searchWorkflow,
  _setUserAnalytics,
  handleClose
}: Props) => {
  const [text, setText] = useState("");

  List.handleClickOutside = useCallback(
    (e: any) => {
      if (outerRef) {
        if (outerRef.current && !outerRef.current.contains(e.target)) {
          handleClose();
        }
      }
    },
    [outerRef, handleClose]
  );

  useEffect(() => {
    _searchWorkflow("");
  }, [_searchWorkflow]);

  const handleSearch = useCallback(
    (e: any) => {
      const { value } = e.target;
      setText(value);
      _searchWorkflow(value);
    },
    [text, setText, _searchWorkflow]
  );

  const handleProcessSelect = useCallback(
    (e: any, id: string) => {
      e.preventDefault();
      const template = (filter || {}).template || [];

      const newTemplate = R.includes(id, template)
        ? R.difference(template, [id])
        : [...template, id];

      _setUserAnalytics({
        query: {
          ...filter,
          template: newTemplate
        }
      });
    },
    [filter, _setUserAnalytics]
  );

  const template = (filter || {}).template || [];

  return (
    <StyledList>
      <Input
        type="text"
        value={text}
        placeholder={i18n.t(k.SEARCH_PROCESS)}
        onChange={handleSearch}
        autoFocus
      />

      <ul>
        {searchResults.map(id => (
          <ListItem onClick={e => handleProcessSelect(e, `${id}`)} key={id}>
            <>
              <Checkbox
                id={`process${id}`}
                handleChange={() => {}}
                checked={R.includes(`${id}`, template)}
              />

              <span>
                <Name id={id} />
              </span>
            </>
          </ListItem>
        ))}
      </ul>
    </StyledList>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  searchResults: app.workflow.searchResults,
  filter: app.userAnalytics.filter
});

const clickOutsideConfig = {
  handleClickOutside: () => List.handleClickOutside
};

export default connect(mapStateToProps, {
  _searchWorkflow: searchWorkflow,
  _setUserAnalytics: setUserAnalytics
})(onClickOutside(List, clickOutsideConfig));
