// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";

import { RoundButton } from "./styles";
import Icons from "src/icons";

type Props = {
  handleClick?: Function
};

const SaveList = ({ handleClick = () => {} }: Props) => {
  return (
    <RoundButton type="button" onClick={handleClick}>
      <Icons type="save" />
      {i18n.t(k.SAVE)}
    </RoundButton>
  );
};

export default SaveList;
