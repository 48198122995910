// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import * as R from "ramda";
import transit from "transit-js";
import Linkify from "react-linkify";
import moment from "moment";

import fieldReader from "src/transit/checklist/field/reader";
import { formatFieldValue } from "src/utils/checklist";
import { getChecklistFieldSettings } from "src/reducers";

import type { FieldId } from "src/types";

import { OldValue, NewValue } from "../styles";
import Conversation from "./Conversation";
import Conversations from "./Conversations";
import User from "./User";
import Users from "./Users";
import Files from "./Files";
import Approval from "./Approval";

type Props = {
  timestamp: string,
  field: {
    old: any,
    new: any,
    type: string
  },

  fieldId: FieldId
};

const Value = ({ timestamp, field, fieldId }: Props) => {
  const componentDecorator = useCallback(
    (href, text, key) => (
      <a href={href} key={key} target="_blank" rel="noopener noreferrer">
        {text}
      </a>
    ),

    []
  );
  const settings = useSelector(({ app }) =>
    getChecklistFieldSettings(app, fieldId)
  );

  try {
    const reader = transit.reader("json", { handlers: fieldReader });
    const { type } = reader.read(field.type);
    const parsedFieldSettings = JSON.parse(settings ?? "{}");

    switch (type) {
      case "conversation":
      case "chatPickList":
      case "workflow":
      case "task":
      case "group":
      case "childConversation": {
        const disableTitleHyperlink = R.has(
          "disableTitleHyperlink",
          parsedFieldSettings
        )
          ? parsedFieldSettings.disableTitleHyperlink
          : false;

        if (Array.isArray(field.old) || Array.isArray(field.new)) {
          return (
            <Conversations
              disableTitleHyperlink={disableTitleHyperlink}
              oldValue={field.old}
              newValue={field.new}
              timestamp={timestamp}
            />
          );
        }
        return (
          <Conversation
            oldValue={field.old}
            newValue={field.new}
            disableTitleHyperlink={disableTitleHyperlink}
          />
        );
      }
      case "user": {
        if (Array.isArray(field.old) || Array.isArray(field.new))
          return (
            <Users
              oldValue={field.old}
              newValue={field.new}
              timestamp={timestamp}
            />
          );

        return <User oldValue={field.old} newValue={field.new} />;
      }
      case "file":
      case "pdf":
        if (Array.isArray(field.old) || Array.isArray(field.new)) {
          const oldFiles: string[] = field.old.filter(
            f => !field.new.includes(f)
          );
          return <Files oldValue={oldFiles} newValue={field.new} />;
        }
        break;
      case "date":
        // TODO: Use a standard date format and then make use of formatFieldValue here
        return (
          <>
            <OldValue>
              <Linkify componentDecorator={componentDecorator}>
                {field.old
                  ? moment(field.old).format(i18n.t(k.MMM_DD_YYYY))
                  : null}
              </Linkify>
            </OldValue>
            <NewValue>
              <Linkify componentDecorator={componentDecorator}>
                {field.new
                  ? moment(field.new).format(i18n.t(k.MMM_DD_YYYY))
                  : null}
              </Linkify>
            </NewValue>
          </>
        );

      case "approval":
        return <Approval data={field.new} />;

      case "link": {
        const disableTitleHyperlink = R.has(
          "disableTitleHyperlink",
          parsedFieldSettings
        )
          ? parsedFieldSettings.disableTitleHyperlink
          : false;

        return (
          <Conversations
            disableTitleHyperlink={disableTitleHyperlink}
            oldValue={(field.old || []).map(val => val.chatroom)}
            newValue={(field.new || []).map(val => val.chatroom)}
            timestamp={timestamp}
          />
        );
      }

      case "number":
        return (
          <p>
            <OldValue>
              <Linkify componentDecorator={componentDecorator}>
                {field.old}
              </Linkify>
            </OldValue>
            <NewValue>
              <Linkify componentDecorator={componentDecorator}>
                {R.type(field.new) === "Array"
                  ? field.new.join("\n ")
                  : formatFieldValue("number", field.new, parsedFieldSettings)}
              </Linkify>
            </NewValue>
          </p>
        );

      case "richtext":
        return (
          <p>
            <OldValue>
              <Linkify componentDecorator={componentDecorator}>
                {field.old && String(field.old)}
              </Linkify>
            </OldValue>
            <NewValue>
              <Linkify componentDecorator={componentDecorator}>
                {R.type(field.new) === "Array"
                  ? field.new.join("\n ")
                  : field.new && String(field.new)}
              </Linkify>
            </NewValue>
          </p>
        );

      default:
        return (
          <p>
            <OldValue>
              <Linkify componentDecorator={componentDecorator}>
                {field.old}
              </Linkify>
            </OldValue>
            <NewValue>
              <Linkify componentDecorator={componentDecorator}>
                {R.type(field.new) === "Array"
                  ? field.new.join("\n ")
                  : field.new}
              </Linkify>
            </NewValue>
          </p>
        );
    }
  } catch (e) {
    console.error({ field });
    console.error(e);
    return <></>;
  }
  return <></>;
};

export default Value;
