// @flow

import React, { Component } from "react";
import Modal from "react-modal";
import * as R from "ramda";

import Navigation from "./Navigation";
import Table from "src/containers/file/Manage/Table";
import Header from "src/components/SearchHeader.old";
import { FullScreen as StyledManage } from "src/styles/modal";
import { getParent } from "src/utils";

import type { UID, RoomId, ManageFilesFilter } from "src/types";

Modal.setAppElement("#root");

type Props = {
  uid: UID,
  currentRoom: RoomId,
  filter: ManageFilesFilter,
  changeCurrentChatroom: Function,
  searchFile: Function,
  showManageFile: Function
};

class Manage extends Component<Props> {
  componentDidMount() {
    const { filter, searchFile } = this.props;

    const search = filter.search || "";
    if (search.length > 0) {
      searchFile(search);
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { filter, searchFile } = this.props;
    const search = filter.search || "";
    const prevSearch = prevProps.filter.search || "";

    if (search !== prevSearch && prevSearch === "") {
      searchFile(search);
    }
  }

  handleClose = () => {
    const { currentRoom, changeCurrentChatroom } = this.props;
    changeCurrentChatroom(currentRoom);
  };

  handleSearch = (value: string) => {
    const { searchFile, showManageFile } = this.props;
    searchFile(value);
    if (R.isEmpty(value)) {
      showManageFile();
    } else {
      showManageFile({ query: { search: value } });
    }
  };

  render() {
    const { filter, uid } = this.props;
    const search = filter.search || "";
    const mine = filter.mine || false;

    return (
      <StyledManage id="manage-files">
        <Modal isOpen parentSelector={() => getParent("#manage-files")}>
          <Navigation mine={mine} />

          <Header
            text={search}
            handleChange={this.handleSearch}
            button="Upload New File"
            type="file"
          />

          <Table mine={mine} uid={uid} />
        </Modal>
      </StyledManage>
    );
  }
}

export default Manage;
