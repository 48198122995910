// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";
import * as R from "ramda";

import type { UnifizeChatRoom } from "src/types";

import Icons from "src/icons";
import {
  Button,
  ModalBody,
  ModalFooter,
  VStack,
  HStack,
  RadioGroup,
  Radio,
  Text,
  CheckboxGroup,
  Checkbox
} from "@chakra-ui/react";
import * as colors from "src/styles/constants/colors";

import * as styles from "../styles";

type Props = {
  linkType: string,
  setLinkType: Function,
  newVersions: string[],
  handleVersionsChange: Function,
  versions: UnifizeChatRoom[],
  processTitle: string,
  handleSuccess: Function,
  handleClose: Function
};

const Editor = ({
  linkType,
  setLinkType,
  newVersions,
  handleVersionsChange,
  versions,
  processTitle,
  handleSuccess,
  handleClose
}: Props) => {
  return (
    <>
      <ModalBody {...styles.modalBody}>
        <RadioGroup value={linkType} onChange={setLinkType}>
          <VStack {...styles.radioOptions}>
            <HStack {...styles.multiEditFirstOption({ linkType })}>
              <Radio value="auto">
                <HStack {...styles.centerAlign}>
                  <Text {...styles.normalText}>
                    {i18n.t(k.AUTO_SELECT_ALL_REVISIONS)}
                  </Text>
                  <Icons fill={colors.purple} type="currentRevision" />
                </HStack>
              </Radio>
            </HStack>

            <VStack {...styles.multiEditRemainingOptions({ linkType })}>
              <Radio value="manual">
                <Text {...styles.normalText}>{i18n.t(k.SELECT_MANUALLY)}</Text>
              </Radio>

              <CheckboxGroup
                value={newVersions}
                onChange={handleVersionsChange}
              >
                <VStack {...styles.multiEditCheckboxGroup}>
                  {versions.map(version => (
                    <Checkbox
                      key={version.id}
                      value={version.id}
                      alignItems="start"
                    >
                      <Text {...styles.normalText}>
                        {processTitle}{" "}
                        {`${i18n.t(k._7)}${version.autoNo || version.seqNo || ""}`}
                        {version.currentVersion && "C"}
                        {i18n.t(k._)} {version.title}
                      </Text>
                      <Text {...styles.versionComment}>
                        {version.versionComment}
                      </Text>
                    </Checkbox>
                  ))}
                </VStack>
              </CheckboxGroup>
            </VStack>
          </VStack>
        </RadioGroup>
      </ModalBody>

      <ModalFooter {...styles.modalFooter}>
        <Button
          variant="uBlueFilled"
          onClick={handleSuccess}
          disabled={R.isEmpty(newVersions)}
        >
          {i18n.t(k.CONFIRM)}
        </Button>
        <Button variant="uSecondary" onClick={handleClose}>
          {i18n.t(k.BACK)}
        </Button>
      </ModalFooter>
    </>
  );
};

export default Editor;
