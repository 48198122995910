// @flow

import i18n from "i18next";
import k from "src/i18n/keys";
import React, { useCallback } from "react";
import { useSelector } from "react-redux";

import { InstantSearch as StyledInstantSearch } from "./styles";
import { getWorkflowTemplates } from "src/reducers";
import { GreyInput } from "src/styles/input";

type Props = {
  search: string,
  handleSearch: Function
};

const InstantSearch = ({ search, handleSearch }: Props) => {
  const workflowTemplates = useSelector(({ app }) => getWorkflowTemplates(app));
  const handleChange = useCallback(
    (e: any) => {
      const { value } = e.target;
      handleSearch(value || "");
    },
    [workflowTemplates]
  );

  return (
    <StyledInstantSearch>
      <GreyInput
        placeholder={i18n.t(k.SEARCH)}
        type="text"
        value={search}
        onChange={handleChange}
      />
    </StyledInstantSearch>
  );
};

export default InstantSearch;
