import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";

import { PDFToolbar, Find, ButtonSeparator } from "../styles";

const DEFINED_SCALE_TYPE = [
  "auto",
  "page-fit",
  "page-actual",
  i18n.t(k.PAGE_WIDTH1),

  0.5,
  0.75,
  1,
  1.25,
  1.5,
  2,
  3,
  4
];

const Toolbar = props => {
  const {
    scale,
    numPages,
    pageNumber,
    searchRef,
    pageNumberRef,
    hanldeRotate,
    handleSearch,
    handleZoomIn,
    handleZoomOut,
    handleDownload,
    handleNextPage,
    handleScaleChange,
    handlePreviousPage,
    handlePageNoChange,
    handlePageNoKeyDown,
    handleSearchKeyDown
  } = props;
  return (
    <PDFToolbar>
      <div>
        <button
          className="previous"
          title="Previous Page"
          onClick={handlePreviousPage}
        />
        <ButtonSeparator />
        <button className="next" title="Next Page" onClick={handleNextPage} />
        <input
          type="number"
          title="Page"
          ref={pageNumberRef}
          onBlur={handlePageNoChange}
          onKeyDown={handlePageNoKeyDown}
        />
        {i18n.t(k.OF)} {numPages}
      </div>
      <div>
        <button onClick={handleZoomOut} title="Zoom Out" className="zoomout" />
        <ButtonSeparator />
        <button onClick={handleZoomIn} title="Zoom In" className="zoomin" />
        <select
          title="Zoom"
          onChange={handleScaleChange}
          value={DEFINED_SCALE_TYPE.includes(scale) ? scale : i18n.t(k.CUSTOM1)}
        >
          <option disabled="disabled" hidden="true" value="custom">
            {isNaN(scale) ? "" : (Number(scale) * 100).toFixed(0)} %
          </option>
          <option value="auto">{i18n.t(k.AUTOMATIC_ZOOM)}</option>
          <option value="page-actual">{i18n.t(k.ACTUAL_SIZE)}</option>
          <option value="page-fit">{i18n.t(k.PAGE_FIT)}</option>
          <option value="page-width">{"page-width"}</option>
          <option value="0.5">{"50%"}</option>
          <option value="0.75">{i18n.t(k._57)}</option>
          <option value="1">100%</option>
          <option value="1.25">{i18n.t(k._58)}</option>
          <option value="1.5">{i18n.t(k._59)}</option>
          <option value="2">{i18n.t(k._60)}</option>
          <option value="3">{i18n.t(k._61)}</option>
          <option value="4">{i18n.t(k._62)}</option>
        </select>
      </div>
      <div>
        <button
          className="rotateccw"
          onClick={() => hanldeRotate(-90)}
          title="Rotate Anti-Clockwise"
        />
        <ButtonSeparator />
        <button
          className="rotatecw"
          onClick={() => hanldeRotate(90)}
          title="Rotate Clockwise"
        />
      </div>
      <Find>
        <input
          type="text"
          ref={searchRef}
          onBlur={handleSearch}
          onKeyDown={handleSearchKeyDown}
          title={i18n.t(k.SEARCH)}
          placeholder={i18n.t(k.SEARCH)}
        />
      </Find>
      <button className="download" title="Download " onClick={handleDownload} />
    </PDFToolbar>
  );
};

export default Toolbar;
