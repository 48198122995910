// @flow

import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Text } from "@chakra-ui/react";
import moment from "moment";

import Spinner from "src/components/Loader";
import Icon from "src/icons";
import { refreshChart } from "src/actions/chart";
import {
  getChartLastGeneratedTime,
  getIfChartIsRefreshing
} from "src/reducers";
import * as colors from "src/styles/constants/colors";
import { getRefreshButtonColor } from "src/utils/charts";
import {
  loaderStyles,
  RefreshButton as StyledRefreshButton,
  containerStyle
} from "./styles";

import type { State } from "src/types";

type Props = {
  chartId: string
};

const RefreshButton = ({ chartId }: Props) => {
  const dispatch = useDispatch();

  const lastGeneratedAt: ?string = useSelector(({ app }: State) =>
    getChartLastGeneratedTime(app, chartId)
  );
  const refreshing = useSelector(({ app }: State) =>
    getIfChartIsRefreshing(app, chartId)
  );

  const refreshButtonColor = getRefreshButtonColor(lastGeneratedAt);

  return refreshing ? (
    <Spinner
      color={colors.unifizeBlue}
      style={loaderStyles}
      containerStyle={containerStyle}
    />
  ) : (
    <StyledRefreshButton
      type="button"
      title="Refresh"
      onClick={() => dispatch(refreshChart(parseInt(chartId)))}
    >
      {moment(lastGeneratedAt).isValid() ? (
        <Text m={0} px={1} fontSize="xs" color={refreshButtonColor}>
          {moment(lastGeneratedAt).fromNow()}
        </Text>
      ) : null}
      <Icon type="refresh" color={refreshButtonColor} height={14} width={14} />
    </StyledRefreshButton>
  );
};

export default RefreshButton;
