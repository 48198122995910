// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React, { useCallback, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import * as R from "ramda";
import moment from "moment";

import {
  Comments,
  FileContent as StyledContent,
  FileInfo,
  Download,
  FileDetails
} from "../styles";
import FilePreview from "./FilePreview";
import Icon from "src/icons";
import {
  getFileType,
  getFileSize,
  getFilePreviewSupport
} from "src/utils/file";
import useFileViewer from "src/hooks/useFileViewer";
import {
  getFile as _getFile,
  downloadFile,
  getFilePreview
} from "src/actions/file";
import { getFile, getCurrentUserId, getFilePreviewURL } from "src/reducers";

import type { UID, UnifizeFile, RoomId } from "src/types";

type Props = {
  roomId: RoomId,
  file: ?UnifizeFile,
  preview: ?Object,
  message: any,
  modal: boolean,
  _getFile: Function,
  currentUser: UID
};

const Content = ({
  roomId,
  file,
  message,
  modal,
  preview,
  _getFile,
  currentUser
}: Props) => {
  const { data, author } = message;
  const { name } = data;

  const dispatch = useDispatch();

  const { openFile } = useFileViewer({ file, modal, roomId });

  useEffect(() => {
    if (R.isEmpty(file || {}) && name) {
      _getFile(name);
    }
  }, [file, name]);

  useEffect(() => {
    if (
      file &&
      getFilePreviewSupport(file) &&
      (!preview || moment().diff(preview?.date, "minutes") > 10) &&
      name
    ) {
      dispatch(getFilePreview({ name, roomId }));
    }
  }, [name, file, preview, roomId]);

  const handleFileOpen = useCallback(
    (event: any) => {
      if (event.target.id === "download") {
        return;
      }

      openFile();
    },
    [modal, file, getFileType]
  );

  const preventDefault = useCallback((e: any) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleDownload = () => {
    if (file)
      dispatch(
        downloadFile({
          roomId,
          location: modal ? "conversation-dialog" : "chat-history",
          name: file.name,
          originalName: file.originalName,
          generation: file.generation,
          versionId: file.versionId
        })
      );
  };

  const isCurrentUser = currentUser === author;

  return (
    <StyledContent isCurrentUser={isCurrentUser}>
      {file ? (
        <>
          <FileDetails>
            {!preview && (
              <div
                onClick={handleFileOpen}
                onKeyPress={handleFileOpen}
                role="button"
                tabIndex="0"
              >
                <Icon type="fileIcons" icon={getFileType(file.originalName)} />
                <FileInfo>
                  <div>{file.originalName}</div>
                  {file.versions && (
                    <div>
                      {getFileSize(
                        parseInt((R.last(file.versions) || {}).size || 0, 10)
                      )}
                    </div>
                  )}
                </FileInfo>
              </div>
            )}

            {file && (
              <Download onClick={handleDownload}>
                <Icon type="download" />
              </Download>
            )}
          </FileDetails>

          <div
            onClick={handleFileOpen}
            onKeyPress={handleFileOpen}
            role="button"
            tabIndex="0"
          >
            <FilePreview preview={preview?.url} />
          </div>
        </>
      ) : (
        <div>
          <h3>{i18n.t(k.LOADING2)}</h3>
        </div>
      )}
      <Comments onClick={preventDefault}>{message.data.description}</Comments>
    </StyledContent>
  );
};

const mapStateToProps = ({ app }, { message }) => ({
  file: getFile(app, message.data.name),
  currentUser: getCurrentUserId(app),
  modal: app.conversationModal.show,
  preview: getFilePreviewURL(app, message?.data?.name),
  isMember: R.includes(getCurrentUserId(app), app.roomMembers)
});

export default connect(mapStateToProps, {
  _getFile
})(Content);
