// @flow
import React from "react";

const ColorBG = () => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_307_131"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="13"
      height="13"
    >
      <rect x="0.400024" y="0.400024" width="12" height="12" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_307_131)">
      <path d="M4.72502 10.65L1.65002 7.57502C1.56669 7.49169 1.50419 7.40002 1.46252 7.30002C1.42086 7.20002 1.40002 7.09586 1.40002 6.98752C1.40002 6.87919 1.42086 6.77502 1.46252 6.67502C1.50419 6.57502 1.56669 6.48336 1.65002 6.40002L4.52502 3.53752L3.20002 2.21252L3.97502 1.40002L8.97502 6.40002C9.05836 6.48336 9.11877 6.57502 9.15627 6.67502C9.19377 6.77502 9.21252 6.87919 9.21252 6.98752C9.21252 7.09586 9.19377 7.20002 9.15627 7.30002C9.11877 7.40002 9.05836 7.49169 8.97502 7.57502L5.90002 10.65C5.81669 10.7334 5.72502 10.7959 5.62502 10.8375C5.52502 10.8792 5.42086 10.9 5.31252 10.9C5.20419 10.9 5.10002 10.8792 5.00002 10.8375C4.90002 10.7959 4.80836 10.7334 4.72502 10.65ZM5.31252 4.32502L2.63752 7.00002H7.98752L5.31252 4.32502ZM10.3 10.9C10 10.9 9.74586 10.7938 9.53752 10.5813C9.32919 10.3688 9.22502 10.1084 9.22502 9.80002C9.22502 9.57502 9.28127 9.36252 9.39377 9.16252C9.50627 8.96252 9.63336 8.76669 9.77502 8.57502L10.3 7.90002L10.85 8.57502C10.9834 8.76669 11.1084 8.96252 11.225 9.16252C11.3417 9.36252 11.4 9.57502 11.4 9.80002C11.4 10.1084 11.2917 10.3688 11.075 10.5813C10.8584 10.7938 10.6 10.9 10.3 10.9Z" />
    </g>
  </svg>
);

export default ColorBG;
