// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import { connect } from "react-redux";
import React from "react";
import moment from "moment";

import {
  New,
  StyledTableRow
} from "src/components/Manage/Reports/Templates/Table/styles";
import { IconContainer } from "src/components/Manage/Workflow/Templates/Table/Row/styles";
import Time from "src/components/Manage/Reports/Templates/Table/Time";
import ImageLabel from "src/containers/user/ImageLabel/Medium";
import InlineIcon from "src/components/Icon";
import * as colors from "src/styles/constants/colors";
import { iconSize } from "src/styles/constants/size";
import { LinkIconWrapper } from "./styles";
import * as status from "src/constants/status";
import { fetchForm } from "src/actions/forms";
import { getForm } from "src/reducers";

import type { AppState, Form } from "src/types";

type Props = {
  form: Form,
  _fetchForm: Function,
  setFormDeletionModal: Function
};

const Row = ({ form, _fetchForm, setFormDeletionModal }: Props) => {
  const fetchForm = (formId: number) => {
    _fetchForm(formId);
  };

  const showFormDeletionModal = e => {
    e.preventDefault();
    setFormDeletionModal({ isOpen: true, formId: form.id });
  };

  return (
    <>
      {form && (
        <StyledTableRow key={form.id}>
          <td>
            <div>
              <LinkIconWrapper>
                <span key={form.id}>{form.title}</span>
                <IconContainer>
                  <InlineIcon
                    icon="edit"
                    onClick={() => {
                      fetchForm(form.id);
                    }}
                    size={iconSize.space_m}
                    color={colors.primary}
                  />
                </IconContainer>
                <IconContainer>
                  <InlineIcon
                    icon="remove"
                    onClick={showFormDeletionModal}
                    size={iconSize.space_m}
                  />
                </IconContainer>
              </LinkIconWrapper>
              {moment()
                .startOf("day")
                .diff(
                  moment.unix(form.createdAt).startOf("day"),
                  i18n.t(k.DAYS)
                ) === 0 ? (
                <New status={status.NEW}> {i18n.t(k.NEW)}</New>
              ) : null}
            </div>
          </td>

          <Time seconds={`${form.createdAt}`} />
          <Time seconds={`${form.updatedAt}`} />
          <td>
            <ImageLabel uid={form.creator} />
          </td>
        </StyledTableRow>
      )}
    </>
  );
};

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  form: getForm(app, props.id)
});

export default connect(mapStateToProps, { _fetchForm: fetchForm })(Row);
