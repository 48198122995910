// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";

import User from "src/containers/user/NameOrEmail";
import type { UID } from "src/types";

type Props = {
  currentUserId: UID,
  uid: UID
};

const Me = ({ currentUserId, uid }: Props) =>
  currentUserId === uid ? <span>{i18n.t(k.ME1)}</span> : <User uid={uid} />;

export default Me;
