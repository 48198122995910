// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";

import type { UnifizeChatRoom } from "src/types";

import Icons from "src/icons";
import {
  Button,
  ModalBody,
  ModalFooter,
  VStack,
  HStack,
  RadioGroup,
  Radio,
  Text
} from "@chakra-ui/react";
import * as colors from "src/styles/constants/colors";

import * as styles from "../styles";

type Props = {
  autoLink: boolean,
  versions: UnifizeChatRoom[],
  selectedVersion: ?string,
  processTitle: string,
  onEnterEditMode: Function
};

const Viewer = ({
  autoLink,
  versions,
  selectedVersion,
  processTitle,
  onEnterEditMode
}: Props) => {
  return (
    <>
      <ModalBody {...styles.modalBody}>
        <RadioGroup value={selectedVersion}>
          <VStack {...styles.radioOptions}>
            <HStack {...styles.centerAlign}>
              <Icons
                fill={autoLink ? colors.purple : colors.inkLightest}
                type="currentRevision"
              />

              <HStack {...styles.autoLinkText}>
                <Text {...styles.boldText}>
                  {i18n.t(k.AUTO_SELECT_CURRENT_REVISION_I)}
                </Text>
                <Text
                  {...styles.boldText}
                  color={autoLink ? "unifize_darkGreen" : "unifize_inkLightest"}
                >
                  {autoLink ? "enabled" : "disabled"}
                </Text>
              </HStack>
            </HStack>

            {versions.map(version => (
              <Radio
                key={version.id}
                value={version.id}
                isDisabled
                {...styles.radio}
              >
                <Text {...styles.normalText}>
                  {processTitle}{" "}
                  {`${i18n.t(k._7)}${version.autoNo || version.seqNo || ""}`}
                  {version.currentVersion && "C"}
                  {i18n.t(k._)} {version.title}
                </Text>
                <Text {...styles.versionComment}>{version.versionComment}</Text>
              </Radio>
            ))}
          </VStack>
        </RadioGroup>
      </ModalBody>

      <ModalFooter {...styles.modalFooter}>
        <Button onClick={onEnterEditMode} {...styles.editButton}>
          {i18n.t(k.EDIT_SELECTED_REVISION)}
        </Button>
      </ModalFooter>
    </>
  );
};

export default Viewer;
