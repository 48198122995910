// @flow
import React from "react";
import i18n from "i18next";
import k from "src/i18n/keys";

import tileColors from "src/styles/constants/tileColors";

import { FormGroup, FilterGroup, TileColor } from "./styles";

type Props = {
  modal: Object,
  _setHomeScreenBuilderAttributes: Function
};

const TileColorSelection = ({
  modal,
  _setHomeScreenBuilderAttributes
}: Props) => (
  <FormGroup>
    <label>{i18n.t(k.TILE_COLOR)}</label>
    <FilterGroup>
      {Object.keys(tileColors).map(color => (
        <TileColor
          onClick={() => _setHomeScreenBuilderAttributes({ tileColor: color })}
          key={color}
          color={color}
          active={String(modal.tileColor).toLowerCase() === color}
        />
      ))}
    </FilterGroup>
  </FormGroup>
);

export default TileColorSelection;
