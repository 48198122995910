// @flow

import React, { useCallback } from "react";
import { connect } from "react-redux";

import QuickFilterItem from "./QuickFilterItem";
import { setFilter, setWorkflowFilter, updateFilter } from "src/actions/filter";
import type { UID, AppState } from "src/types";
import { initialFilterState } from "src/reducers/chatRooms";
import {
  getWorkflowName,
  getCurrentUserId,
  getCurrentFilter
} from "src/reducers";

type Props = {
  workflowTitle: string,
  hideConversation: Function,
  emptySearchField: Function,
  _setWorkflowFilter: Function,
  _updateFilter: Function,
  workflowId: number,
  uid: UID
};

const Workflow = ({
  workflowTitle,
  _setWorkflowFilter,
  hideConversation,
  emptySearchField,
  workflowId,
  _updateFilter,
  uid
}: Props) => {
  const pendingIOwnText = `Pending ${workflowTitle} that I own`;
  const overdueText = `Overdue ${workflowTitle}`;
  const pendingText = `Pending ${workflowTitle}`;
  const unreadText = `Unread ${workflowTitle}`;

  const handlePendingIOwn = useCallback(() => {
    _updateFilter({
      ...initialFilterState,
      name: pendingIOwnText,
      workflow: workflowId,
      owner: uid,
      active: "pending"
    });
    emptySearchField();
    hideConversation();
  }, [emptySearchField, hideConversation, _updateFilter, initialFilterState]);

  const handleOverdue = useCallback(() => {
    _updateFilter({
      ...initialFilterState,
      name: overdueText,
      workflow: workflowId,
      overdue: true
    });
    emptySearchField();
    hideConversation();
  }, [emptySearchField, hideConversation, _updateFilter, initialFilterState]);

  const handlePending = useCallback(() => {
    _updateFilter({
      ...initialFilterState,
      name: pendingText,
      workflow: workflowId,
      active: "pending"
    });
    emptySearchField();
    hideConversation();
  }, [_updateFilter, emptySearchField, hideConversation, initialFilterState]);

  const handleUnread = useCallback(() => {
    _updateFilter({
      ...initialFilterState,
      unread: true,
      workflow: workflowId,
      name: unreadText
    });
    emptySearchField();
    hideConversation();
  }, [
    _updateFilter,
    _setWorkflowFilter,
    emptySearchField,
    hideConversation,
    initialFilterState
  ]);

  return (
    <>
      <QuickFilterItem
        title={pendingIOwnText}
        handleClick={handlePendingIOwn}
      />

      <QuickFilterItem title={overdueText} handleClick={handleOverdue} />
      <QuickFilterItem title={pendingText} handleClick={handlePending} />
      <QuickFilterItem title={unreadText} handleClick={handleUnread} />
    </>
  );
};

const mapStateToProps = ({ app }: { app: AppState }, { workflowId }) => ({
  workflowTitle: getWorkflowName(app, workflowId),
  uid: getCurrentUserId(app),
  currentFilter: getCurrentFilter(app)
});

export default connect(mapStateToProps, {
  _setFilter: setFilter,
  _setWorkflowFilter: setWorkflowFilter,
  _updateFilter: updateFilter
})(Workflow);
