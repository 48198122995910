// @flow

import { connect } from "react-redux";
import React, { useState, useEffect, useCallback, useMemo } from "react";
import { IconButton } from "@chakra-ui/react";

import Tooltip from "src/components/Tooltip";
import Icon from "src/icons";
import { setChecklistBuilderParent } from "src/actions/checklist";
import * as colors from "src/styles/constants/colors";

type Props = {
  settings: Object,
  index: number,
  type: string,
  saveSettings: Function,
  hover: boolean,
  _setChecklistBuilderParent: Function
};

const ParentConversation = ({
  settings,
  index,
  type,
  saveSettings,
  hover,
  _setChecklistBuilderParent
}: Props) => {
  const [localSettings, setSettings] = useState({});

  const eligible = useMemo(() => {
    if (type === "conversation") return true;

    if (type === "link") {
      return !settings.child;
    }

    return false;
  }, [index, type, settings]);

  useEffect(() => {
    if (settings) {
      try {
        setSettings({ primaryParent: false, ...(settings || {}) });
        if (settings.primaryParent) {
          _setChecklistBuilderParent(index);
        }
      } catch (e) {
        console.error(e);
      }
    }
  }, [settings, setSettings]);

  const handlePrimaryParent = useCallback(() => {
    const primaryParent = localSettings.primaryParent;
    const newSettings = { ...localSettings, primaryParent: !primaryParent };
    if (newSettings.primaryParent) {
      _setChecklistBuilderParent(index);
    } else {
      _setChecklistBuilderParent(null);
    }
    setSettings(newSettings);
    saveSettings(newSettings);
    return false;
  }, [index, localSettings, saveSettings, setSettings]);

  return (
    <Tooltip
      title="Primary Parent"
      placement="right"
      hide={eligible ? false : true}
    >
      <IconButton
        sx={eligible ? { display: "flex" } : { visibility: "hidden" }}
        variant="unstyled"
        aria-label="Primary Parent"
        onClick={handlePrimaryParent}
        opacity={localSettings.primaryParent ? 1 : hover ? 1 : 0}
        icon={
          <Icon
            type="accountTree"
            fill={
              localSettings.primaryParent ? colors.blue : colors.inkLightest
            }
          />
        }
      />
    </Tooltip>
  );
};

export default connect(null, {
  _setChecklistBuilderParent: setChecklistBuilderParent
})(ParentConversation);
