// @flow

import React, { useState, useEffect } from "react";
import { VStack } from "@chakra-ui/react";
import * as morpheus from "src/utils/morpheus";

import {
  CreateRevisions,
  MarkRevisions,
  RevisionDefaults,
  MarkedCurrent,
  CurrentPrevious
} from "./Sections";
import type { RevisionFieldSettings, RevisionFieldSettingsv2 } from "src/types";

type Props = {
  type: string,
  settings: RevisionFieldSettings | RevisionFieldSettingsv2,
  saveSettings: Function
};

const Revision = ({ settings, saveSettings }: Props) => {
  const [currentSettings, setCurrentSettings] =
    useState<RevisionFieldSettingsv2>(morpheus.revision(settings));

  useEffect(() => {
    saveSettings(currentSettings);
  }, [currentSettings]);

  return (
    <VStack>
      <CreateRevisions
        settings={currentSettings}
        saveSettings={setCurrentSettings}
      />

      <MarkRevisions
        settings={currentSettings}
        saveSettings={setCurrentSettings}
      />

      <RevisionDefaults
        settings={currentSettings}
        saveSettings={setCurrentSettings}
      />

      <MarkedCurrent
        settings={currentSettings}
        saveSettings={setCurrentSettings}
      />

      <CurrentPrevious
        settings={currentSettings}
        saveSettings={setCurrentSettings}
      />
    </VStack>
  );
};

export default Revision;
