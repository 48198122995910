// @flow

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import Icon from "src/icons";
import { Modal, ModalHeader, ModalContent, Text } from "@unifize/sarah";
import { toggleAboutModal } from "src/actions/modal";
import { getIsAboutModalOpen } from "src/reducers";
import { getBackendVersion } from "src/api/version";

import * as colors from "src/styles/constants/colors";

import { AboutContainer, VersionContainer, Flex } from "./styles";

const AboutModal = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(({ app }) => getIsAboutModalOpen(app));
  const [isLoading, setIsLoading] = useState(false);
  const [backendVersion, setBackendVersion] = useState("...");

  const handleClose = () => {
    dispatch(toggleAboutModal({ value: false }));
  };

  useEffect(() => {
    if (isOpen) {
      setIsLoading(true);
      getBackendVersion().then(result => {
        setBackendVersion(result);
        setIsLoading(false);
      });
    }
  }, [isOpen]);

  return (
    <Modal width={315} height={312} isOpen={isOpen} onClose={handleClose}>
      <ModalHeader hideDivider></ModalHeader>
      <ModalContent>
        <AboutContainer>
          <Icon type="unifize" />
          <Text variant="body" weight="bold" color={colors.grey39} paragraph>
            Unifize Inc.
          </Text>
          <VersionContainer>
            {isLoading || backendVersion === "..." ? (
              <Icon type="spinner" color={colors.grey42} />
            ) : (
              <>
                <Flex style={{ alignItems: "flex-end" }}>
                  <Text weight="bold" variant="caption">
                    Server
                  </Text>
                  <Text weight="bold" variant="caption">
                    Web
                  </Text>
                </Flex>{" "}
                <Flex style={{ overflow: "hidden" }}>
                  <Text variant="caption">{backendVersion || "..."}</Text>
                  <Text variant="caption">
                    {/* VERSION env variable is conflicts with the version stored on Firestore 
                    which is used to determine whether to show the red dot and the option to
                    update the web app to the user which can't be consistent with 
                    the version of the web app that we calculate through the Github Action workflow. */}
                    {process.env.RELEASE_VERSION === "@RELEASE_VERSION@"
                      ? "..."
                      : process.env.RELEASE_VERSION}
                  </Text>
                </Flex>
              </>
            )}
          </VersionContainer>
          <Text
            variant="caption"
            weight="bold"
            color={colors.grey42}
            style={{ textAlign: "center" }}
          >
            © {new Date().getFullYear()} Unifize Inc. All rights reserved.
          </Text>
        </AboutContainer>
      </ModalContent>
    </Modal>
  );
};

export default AboutModal;
