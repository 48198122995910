// @flow

import React from "react";

import type { UnifizeDropdownRow } from "../utils";

import { DropdownElement } from "./styles";
import ElementCell from "./ElementCell";

const Element = ({
  cells,
  ...restProps
}: $Diff<UnifizeDropdownRow, { id: string }>) => {
  return (
    <DropdownElement {...restProps}>
      {cells.map((cell, index) => (
        <ElementCell cell={cell} key={`cell-${cell[0] ?? ""}-${index}`} />
      ))}
    </DropdownElement>
  );
};

export default Element;
