// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import { connect } from "react-redux";
import React, { useCallback, useRef } from "react";
import * as R from "ramda";
import { arrayMove } from "react-sortable-hoc";

import { SecondaryButton } from "src/components/Manage/Workflow/Instances/styles";
import { SortMenu } from "./styles";
import SortItem from "./SortItem";
import SortOptions from "./SortOptions";
import { setForm } from "src/actions/forms";
import { formColumnNames } from "src/constants/display";
import useBoolean from "src/hooks/useBoolean";
import options from "src/constants/formInstanceColumns";
import {
  getPrincipalChecklistFields,
  getWorkflowInstanceFilter
} from "src/reducers";

import type { AppState, WorkflowInstanceFilter } from "src/types";

type Props = {
  fields: Object,
  sortParams: Array<string>,
  filter: WorkflowInstanceFilter,
  _setForm: Function
};

const Sort = ({ sortParams, fields, filter, _setForm }: Props) => {
  const outerRef = useRef(null);
  const {
    value: sortable,
    toggleBoolean: toggleSortable,
    setFalse: hideSortable
  } = useBoolean();

  const handleSort = useCallback(
    ({ oldIndex, newIndex }: { oldIndex: number, newIndex: number }) => {
      const sort = filter.sort || [];
      const newSort = arrayMove(sort, oldIndex, newIndex);
      _setForm({
        query: {
          ...filter,
          sort: newSort,
          page: 1
        }
      });
    },
    [filter, _setForm]
  );

  const clearSort = useCallback(
    (column: string) => {
      const newSort = R.reject(R.equals(`${column}`), filter.sort || []);
      _setForm({
        query: {
          ...filter,
          sort: newSort,
          page: 1
        }
      });
      hideSortable();
    },
    [filter, _setForm, hideSortable]
  );

  const sort = filter.sort || [];
  const processLabels = R.mergeAll(
    R.map(x => ({ [x.key]: formColumnNames[x.key] }), options)
  );

  if (sortParams.length === 0) {
    return <></>;
  }

  if (sortParams.length === 1) {
    const column = R.head(sortParams);

    return <SortItem column={column} clearSort={clearSort} />;
  }
  return (
    <>
      <SecondaryButton ref={outerRef} type="button" onClick={toggleSortable}>
        {sortParams.length} {i18n.t(k.PARAMETERS)}
      </SecondaryButton>
      {sortable ? (
        <SortMenu>
          <SortOptions
            processLabels={processLabels}
            fields={fields}
            sort={sort}
            clearSort={clearSort}
            handleSort={handleSort}
            handleClose={hideSortable}
            outerRef={outerRef}
          />
        </SortMenu>
      ) : null}
    </>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  filter: getWorkflowInstanceFilter(app),
  fields: getPrincipalChecklistFields(app)
});

export default connect(mapStateToProps, {
  _setForm: setForm
})(Sort);
