// @flow

import React, { useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import {
  Portal,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Text,
  HStack,
  Box,
  Spinner
} from "@chakra-ui/react";
import i18n from "i18next";

import { getFilePreview } from "src/actions/file";
import { getFilePreviewSupport, getFileType } from "src/utils/file";
import {
  getFilePreviewURL,
  getConversationModalVisibility
} from "src/reducers";
import Tooltip from "src/components/Tooltip";

import * as styles from "./styles";
import PreviewUnavailable from "./PreviewUnavailable";
import FilePreviewWrapper from "./FilePreviewWrapper";
import Icons from "src/icons";

import type { UnifizeFile, RoomId } from "src/types";
import k from "src/i18n/keys";
import useFileViewer from "src/hooks/useFileViewer";

type Props = {
  file: UnifizeFile,
  triggerMode?: "hover" | "click",
  children: React$Element<*>,
  roomId: RoomId,
  settings: string
};

const HoverPreview = ({
  file,
  triggerMode = "hover",
  children,
  roomId,
  settings
}: Props) => {
  const dispatch = useDispatch();
  const fileName = file?.originalName;
  const settingsJSON = useMemo(() => {
    try {
      return JSON.parse(settings);
    } catch (err) {
      console.error(err);
      return {};
    }
  }, [settings]);

  const isPreviewEnabled = settingsJSON?.preview ?? false;

  const isConversationModalVisible = useSelector(({ app }) =>
    getConversationModalVisibility(app)
  );

  const filePreview = useSelector(({ app }) =>
    getFilePreviewURL(app, file?.name)
  );

  const hasPreviewSupport = getFilePreviewSupport(file);

  const { data, openFile } = useFileViewer({
    file,
    modal: isConversationModalVisible,
    roomId
  });
  const fileType = getFileType(file.originalName);

  // Fetch the file preview
  useEffect(() => {
    if (
      isPreviewEnabled &&
      hasPreviewSupport &&
      (!filePreview ||
        (moment().diff(filePreview?.date, "minutes") > 10 && file.name))
    ) {
      dispatch(getFilePreview({ name: file.name, roomId }));
    }
  }, []);

  const handleViewFile = e => {
    e.stopPropagation();
    openFile();
  };

  return (
    <Popover trigger={triggerMode}>
      <PopoverTrigger>{children}</PopoverTrigger>
      <Portal>
        <PopoverContent {...styles.popover.content}>
          <PopoverBody {...styles.popover.body}>
            <HStack {...styles.previewBar}>
              <Text {...styles.popover.fileName}>{fileName}</Text>
              {fileType === "pdf" && (
                <Tooltip title={i18n.t(k.OPEN)}>
                  <Box onClick={handleViewFile} sx={styles.iconBtn}>
                    {data?.fetching ? (
                      <Spinner size="xs" color="unifize_inkLightest" />
                    ) : (
                      <Icons type="preview" />
                    )}
                  </Box>
                </Tooltip>
              )}
            </HStack>

            <PreviewUnavailable
              hasPreviewSupport={hasPreviewSupport}
              isPreviewEnabled={isPreviewEnabled}
            />

            <FilePreviewWrapper
              isPreviewEnabled={isPreviewEnabled}
              file={file}
            />
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

export default HoverPreview;
