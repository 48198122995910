// @flow

import React, { useState } from "react";
import * as R from "ramda";

import { GroupDropdown as StyledDropdDown, ListItemGroup } from "./styles";
import { Input } from "src/styles/input";
import Checkbox from "src/components/Checkbox";
import useClickoutside from "src/hooks/useClickoutside";

import type { Roles } from "src/types";

type Props = {
  outerRef: any,
  data: Roles,
  value: Array<number>,
  handleSelect: Function,
  handleClose: Function
};

const RolesDropdown = ({
  outerRef,
  data,
  value,
  handleSelect,
  handleClose
}: Props) => {
  const roles = Object.values(data);
  const [filteredRoles, setFilteredRoles] = useState(roles || []);

  useClickoutside({ outerRef, handleClose });

  const handleSearch = e => {
    const searchTerm = e.target.value.toLowerCase();
    const filtered = roles.filter(role => {
      // $FlowFixMe
      const title = role.title?.toLowerCase() || "";
      // $FlowFixMe
      const originalTitle = role.originalTitle?.toLowerCase() || "";
      return title.includes(searchTerm) || originalTitle.includes(searchTerm);
    });

    setFilteredRoles(filtered);
  };

  return (
    <StyledDropdDown>
      <Input onChange={handleSearch} placeholder="Search role" autoFocus />
      <ul>
        {filteredRoles.map((role: Object) => (
          <ListItemGroup
            key={role?.id}
            onClick={() => handleSelect(parseInt(role.id, 10))}
          >
            {role?.title}
            <Checkbox
              id={`role${role?.id}`}
              checked={R.includes(parseInt(role.id, 10), value)}
              handleChange={() => {}}
            />
          </ListItemGroup>
        ))}
      </ul>
    </StyledDropdDown>
  );
};

export default RolesDropdown;
