// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";

import {
  PopoverHeader,
  PopoverBody,
  Text,
  VStack,
  IconButton
} from "@chakra-ui/react";
import { IconWrapper, IconBox } from "../styles";
import Upload from "src/containers/chatroom/Upload";
import Icons from "src/icons";

import * as styles from "./styles";

import { fileSources } from "./index";
import type { SharepointIntegrationState } from "./index";
import type {
  RoomId,
  FieldId,
  UnifizeFile,
  ColumnId,
  SharepointFileCreateOptions
} from "src/types";
import { CloseIcon } from "@chakra-ui/icons";
import SharepointSource from "./SharepointSource";

type Props = {
  onClose: Function,
  onSourceChange: Function,
  setChecklistValue: Function,
  uploadOptions: Array<string>,
  roomId: RoomId,
  fieldId: FieldId,
  columnId: ColumnId,
  formId: ?FieldId,
  location: ?string,
  multiple: boolean,
  files: UnifizeFile[],
  onSelectIntegration: Function,
  sharepointData: SharepointIntegrationState,
  createOptions: SharepointFileCreateOptions,
  promptCallback?: ?Function
};

const SourcePicker = ({
  onClose,
  onSourceChange,
  setChecklistValue,
  uploadOptions,
  roomId,
  fieldId,
  columnId,
  formId,
  files,
  location,
  multiple,
  onSelectIntegration,
  sharepointData,
  createOptions,
  promptCallback
}: Props) => {
  const { fetching } = sharepointData;
  return (
    <>
      <PopoverHeader {...styles.popover.header.root}>
        <Text textStyle="popoverHeader">{i18n.t(k.PICK_SOURCE)}</Text>

        <IconButton
          onClick={onClose}
          variant="transparent"
          icon={<CloseIcon />}
          {...styles.popover.header.closeButton}
        />
      </PopoverHeader>

      <PopoverBody {...styles.popover.body.root}>
        <VStack {...styles.popover.body.sourcePicker}>
          {uploadOptions.includes("unifize") && (
            <IconWrapper onClick={() => onSourceChange(fileSources.native)}>
              <IconBox>
                <Icons type="attachClip" />
              </IconBox>

              <Text textStyle="popoverMenuItem">
                {i18n.t(k.UNIFIZE_FILE_STORE)}
              </Text>
            </IconWrapper>
          )}

          {uploadOptions.includes("computer") && (
            <Upload
              roomId={roomId}
              multiple={multiple}
              location="checklist-upload"
              fieldId={fieldId}
              columnId={columnId}
              formId={formId}
              value={files}
              setChecklistValue={
                location === "manage-view" ? setChecklistValue : null
              }
              render={openFileBrowser => (
                <IconWrapper onClick={openFileBrowser}>
                  <IconBox>
                    <Icons type="uploadCloud" />
                  </IconBox>

                  <Text textStyle="popoverMenuItem">{i18n.t(k.COMPUTER)}</Text>
                </IconWrapper>
              )}
              togglePopover={() => onSourceChange(fileSources.local)}
              multipleFiles={multiple ? true : false}
              onClose={onClose}
              promptCallback={promptCallback}
            />
          )}

          {uploadOptions.includes("sharepoint") && (
            <SharepointSource
              onSelect={onSelectIntegration}
              isLoading={fetching}
              createOptions={createOptions}
            />
          )}

          {uploadOptions && uploadOptions.length === 0 && (
            <Text width="full" pl="3">
              {i18n.t(k.NO_SOURCE_SELECTED)}
            </Text>
          )}
        </VStack>
      </PopoverBody>
    </>
  );
};

export default SourcePicker;
