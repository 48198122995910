// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";
import { Container, Label, Info } from "./styles";

type Props = {
  message: string
};

const Banner = ({ message = "" }: Props) => {
  return (
    <Container>
      <Info>{i18n.t(k._32)}</Info>
      <Label>{message}</Label>
    </Container>
  );
};

export default Banner;
