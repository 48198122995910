// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import { Tooltip } from "@chakra-ui/react";

import { getChatRoom, getChecklistFieldsById } from "src/reducers";
import { getFieldLabel } from "src/utils/checklist";

import { ChecklistLabel } from "../styles";
import ShowMore from "./ShowMore";
import type {
  FieldId,
  RoomId,
  ChecklistFieldTypes,
  FieldsById
} from "src/types";

type Props = {
  fieldId: FieldId,
  roomId: RoomId
};

const FieldLabel = ({ fieldId, roomId }: Props) => {
  const labelRef = useRef();
  const [showMore, setShowMore] = useState(false);
  const chatroom = useSelector(({ app }) => getChatRoom(app, roomId));
  const fieldsById: FieldsById = useSelector(({ app }) =>
    getChecklistFieldsById(app)
  );
  const details = fieldsById.get(`${fieldId}`);

  // $FlowFixMe - Flow doesn't support optional chaining
  const fieldType: ChecklistFieldTypes = fieldsById
    .get(`${fieldId}`)
    ?.get("type");

  const label = getFieldLabel({
    templateId: chatroom.templateId,
    details,
    type: fieldType
  });

  return (
    <Tooltip
      hasArrow
      label={label.length > 30 ? label : ""}
      sx={{ backgroundColor: i18n.t(k.UNIFIZE_UNIFIZEBLUE) }}
    >
      <ChecklistLabel data-cy="fieldLabel" more={showMore} ref={labelRef}>
        {label}
        <ShowMore
          labelRef={labelRef}
          showMore={showMore}
          setShowMore={setShowMore}
        />
      </ChecklistLabel>
    </Tooltip>
  );
};

export default FieldLabel;
