// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  Button
} from "@chakra-ui/react";

type Props = {
  onClose: Function,
  isOpen: boolean,
  title: string,
  onDisable: Function
};

const DisableModal = ({ onClose, isOpen, title, onDisable }: Props) => {
  return (
    <Modal
      isCentered
      onClose={onClose}
      isOpen={isOpen}
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody mt={4}>
          {i18n.t(k.ARE_YOU_SURE_YOU_WANT_TO_DISAB)} {title}{" "}
          {i18n.t(k.INTEGRATION)}
        </ModalBody>
        <ModalFooter>
          <Button mr={4} variant="ghost" onClick={onClose}>
            {i18n.t(k.CANCEL)}
          </Button>
          <Button
            colorScheme="blue"
            onClick={() => {
              onDisable();
              onClose();
            }}
          >
            {i18n.t(k.DISABLE)}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DisableModal;
