// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";

import {
  Header as StyledHeader,
  Button,
  ButtonActive,
  Buttons
} from "./styles";
import Search from "./Search";
import InstantSearch from "./InstantSearch";
import Icon from "src/components/Icon";
import InlineIcon from "src/icons";
import Upload from "src/containers/chatroom/UploadCurrentRoom";
import * as colors from "src/styles/constants/colors";
import UnifizeButton from "src/components/Unifize/Button";
import Import from "src/components/Manage/Workflow/Templates/Import";
import Export from "src/components/Manage/Workflow/Templates/Export";

import { iconSize } from "src/styles/constants/size";

type Props = {
  text: string,
  button?: string,
  addItem?: boolean,
  type?: ?string,
  instant?: boolean,
  buttonColor?: string,
  secondary?: boolean,
  secondaryPlaceholder?: string,
  handleChange: Function,
  handleCreate?: Function,
  handleSecondaryAction?: Function,
  showJSONImportExport?: boolean
};

const Header = ({
  text,
  button = i18n.t(k._4),
  type = null,
  instant = false,
  secondary = false,
  secondaryPlaceholder = i18n.t(k._4),
  handleSecondaryAction = () => {},
  addItem = true,
  buttonColor = colors.active,
  handleChange,
  handleCreate,
  showJSONImportExport = false
}: Props) => (
  <StyledHeader buttonColor={buttonColor}>
    {instant ? (
      <InstantSearch
        search={text}
        handleSearch={handleChange}
        instant={instant}
      />
    ) : (
      <Search search={text} handleSearch={handleChange} instant={instant} />
    )}

    <Buttons>
      {showJSONImportExport ? (
        <>
          <Import />
          <Export />
        </>
      ) : null}
      {addItem ? (
        <div>
          {secondary ? (
            <ButtonActive onClick={handleSecondaryAction}>
              <Icon icon="plusCircle" size={iconSize.space_l} />
              {secondaryPlaceholder}
            </ButtonActive>
          ) : null}

          {type === "file" ? (
            <Upload
              location="manage-file-view"
              render={openFileBrowser => (
                <Button onClick={openFileBrowser}>
                  <Icon icon="plusCircle" size={iconSize.space_l} />
                  {button}
                </Button>
              )}
            />
          ) : (
            <Button
              type="button"
              onClick={handleCreate}
              data-cy="createProcessTemplate"
            >
              {buttonColor === colors.active ? (
                <Icon icon="plusCircle" size={iconSize.space_l} />
              ) : (
                <InlineIcon type="removeCircle" size={iconSize.space_l} />
              )}
              {button}
            </Button>
          )}
        </div>
      ) : null}
    </Buttons>
  </StyledHeader>
);

export default Header;
