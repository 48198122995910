// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React, { Component } from "react";
import ReactModal from "react-modal";
import * as R from "ramda";

import SortItem from "./SortItem";
import {
  PopupModal,
  PopupForm,
  SecondaryFooter,
  PopupFormBody,
  ButtonSecondary,
  ButtonPrimary
} from "src/components/chatroom-nav/filter/styles";

import type { CurrentFilter } from "src/types";

type Props = {
  _hideSortByModal: Function,
  sortbyModal: boolean,
  sort: string,
  _updateFilter: Function,
  currentFilter: CurrentFilter
};

type State = {
  updatedSortBy: string
};

class SortModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { updatedSortBy: props.sort || "updatedAt" };
  }

  closeSortByModal = (e: any) => {
    e.preventDefault();
    const { _hideSortByModal } = this.props;
    _hideSortByModal();
  };

  setSortBy = (sortByItem: string) => {
    this.setState({ updatedSortBy: sortByItem });
  };

  applySortBy = (e: any) => {
    const { _updateFilter, currentFilter } = this.props;
    const { updatedSortBy } = this.state;
    _updateFilter({
      ...currentFilter,
      sortBy: updatedSortBy
    });
    this.closeSortByModal(e);
  };

  render() {
    const { sortbyModal } = this.props;
    const { updatedSortBy } = this.state;
    return (
      <PopupModal id="sort-modal">
        <ReactModal
          isOpen={sortbyModal}
          onRequestClose={this.closeSortByModal}
          shouldCloseOnOverlayClick
          parentSelector={() => document.querySelector("#sort-modal")}
        >
          <PopupForm>
            <h4>{i18n.t(k.SORT_BY3)}</h4>
            <PopupFormBody>
              <SortItem
                date={i18n.t(k.DATE_UPDATED_SORT)}
                secondary="Stale - &gt; Recent"
                filterText="updatedAt"
                active={R.startsWith("updatedAt", updatedSortBy)}
                setSort={this.setSortBy}
                updatedSortBy={updatedSortBy}
              />

              <SortItem
                date={i18n.t(k.PRIORITY_SORT)}
                secondary="Critical - &gt; Low"
                active={R.startsWith("priority", updatedSortBy)}
                filterText="priority"
                setSort={this.setSortBy}
                updatedSortBy={updatedSortBy}
              />

              <SortItem
                date={i18n.t(k.STATUS_SORT)}
                secondary="Pending - &gt; Complete"
                active={R.startsWith("status", updatedSortBy)}
                filterText="status"
                setSort={this.setSortBy}
                updatedSortBy={updatedSortBy}
              />

              <SortItem
                date={i18n.t(k.DATE_CREATED_SORT)}
                secondary="Newest - &gt; oldest"
                active={R.startsWith("createdAt", updatedSortBy)}
                filterText="createdAt"
                setSort={this.setSortBy}
                updatedSortBy={updatedSortBy}
              />

              <SortItem
                date={i18n.t(k.DUE_DATE_SORT)}
                secondary="Due soon - &gt; Due later"
                active={R.startsWith("dueDate", updatedSortBy)}
                filterText="dueDate"
                setSort={this.setSortBy}
                updatedSortBy={updatedSortBy}
              />
            </PopupFormBody>
            <SecondaryFooter>
              <ButtonSecondary type="button" onClick={this.closeSortByModal}>
                {i18n.t(k.CANCEL)}
              </ButtonSecondary>
              <ButtonPrimary onClick={this.applySortBy} type="button">
                {i18n.t(k.APPLY)}
              </ButtonPrimary>
            </SecondaryFooter>
          </PopupForm>
        </ReactModal>
      </PopupModal>
    );
  }
}

export default SortModal;
