// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import { connect } from "react-redux";
import React from "react";

import { Average as StyledAverge, OtherAverages, Active } from "./styles";

import type { Average as AverageType, AppState } from "src/types";

type Props = {
  average: AverageType
};

const Average = ({ average }: Props) => {
  return (
    <>
      <StyledAverge>{i18n.t(k.AVERAGE)}</StyledAverge>
      <OtherAverages>{average.createdAll}</OtherAverages>
      <OtherAverages>{average.createdOverdue}</OtherAverages>
      <OtherAverages>{average.createdCompleted}</OtherAverages>
      <OtherAverages>{average.createdPending}</OtherAverages>
      <OtherAverages>{average.pendingAll}</OtherAverages>
      <OtherAverages>{average.pendingOverdue}</OtherAverages>
      <OtherAverages>
        <Active>{average.pendingUnread}</Active>
      </OtherAverages>
      <OtherAverages>{average.pendingOwner}</OtherAverages>
      <OtherAverages>{average.pendingCritical}</OtherAverages>
      <OtherAverages>{average.completedAll}</OtherAverages>
      <OtherAverages>{average.completedOwner}</OtherAverages>
      <OtherAverages>{average.completedCreator}</OtherAverages>
      <OtherAverages>{average.completedBy}</OtherAverages>
      <OtherAverages />
    </>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  average: app.userAnalytics.average
});

export default connect(mapStateToProps)(Average);
