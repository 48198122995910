// @flow

import React, { useEffect } from "react";
import { connect } from "react-redux";

import { getAllRoles, getAllPermissions } from "src/actions/roleManagement";
import { getCurrentLanguage } from "src/reducers";

import { Box } from "@chakra-ui/react";
import Title from "./Title";
import RoleTable from "./RoleTable";

import type { AppState } from "src/types";

type Props = {
  _getAllRoles: Function,
  _getAllPermissions: Function
};

const RoleManagement = ({ _getAllRoles, _getAllPermissions }: Props) => {
  useEffect(() => {
    _getAllRoles();
    _getAllPermissions();
  }, []);

  return (
    <Box mt={4}>
      <Title />
      <RoleTable />
    </Box>
  );
};

const mapStatetoProps = ({ app }: { app: AppState }) => ({
  language: getCurrentLanguage(app)
});

export default connect(mapStatetoProps, {
  _getAllRoles: getAllRoles,
  _getAllPermissions: getAllPermissions
})(RoleManagement);
