// @flow

import i18n from "i18next";
import k from "src/i18n/keys";
import { connect } from "react-redux";
import React, { useState, useEffect, useCallback } from "react";
import * as R from "ramda";
import onClickOutside from "react-onclickoutside";

import { List as StyledList, Input, ListItem } from "../styles";
import { setUserAnalytics } from "src/actions/userAnalytics";
import Checkbox from "src/components/Checkbox";

import type { AppState, Department, UserAnalyticsFilter } from "src/types";

type Props = {
  outerRef: any,
  filter: UserAnalyticsFilter,
  departments: Array<Department>,
  handleClose: Function,
  _setUserAnalytics: Function
};

const List = ({
  outerRef,
  filter,
  departments,
  handleClose,
  _setUserAnalytics
}: Props) => {
  const [text, setText] = useState("");
  const [searchResults, setSearchResult] = useState(departments);

  useEffect(() => {
    setSearchResult(departments);
  }, [departments, setSearchResult]);

  List.handleClickOutside = useCallback(
    (e: any) => {
      if (outerRef) {
        if (outerRef.current && !outerRef.current.contains(e.target)) {
          handleClose();
        }
      }
    },
    [outerRef, handleClose]
  );

  const handleSearch = useCallback(
    (e: any) => {
      const { value } = e.target;
      setText(value);

      setSearchResult(
        R.filter(
          d => R.includes(R.toLower(value), R.toLower(d.name || "")),
          departments
        )
      );
    },
    [text, setText, departments, setSearchResult]
  );

  const handleDepartmentSelect = useCallback(
    (e: any, id: string) => {
      e.preventDefault();
      const department = (filter || {}).department || [];

      const newDepartment = R.includes(id, department)
        ? R.difference(department, [id])
        : [...department, id];

      _setUserAnalytics({
        query: {
          ...filter,
          department: newDepartment
        }
      });
    },
    [filter, _setUserAnalytics]
  );

  const department = (filter || {}).department || [];

  return (
    <StyledList>
      <Input
        type="text"
        value={text}
        placeholder={i18n.t(k.SEARCH_DEPARTMENTS)}
        onChange={handleSearch}
        autoFocus
      />

      <ul>
        {searchResults.map(d => (
          <ListItem
            onClick={e => handleDepartmentSelect(e, `${d.id}`)}
            key={d.id}
          >
            <>
              <Checkbox
                id={`department${d.id}`}
                handleChange={() => {}}
                checked={R.includes(`${d.id}`, department)}
              />

              <span>{(d || {}).name}</span>
            </>
          </ListItem>
        ))}
      </ul>
    </StyledList>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  filter: app.userAnalytics.filter,
  departments: app.departments
});

const clickOutsideConfig = {
  handleClickOutside: () => List.handleClickOutside
};

export default connect(mapStateToProps, {
  _setUserAnalytics: setUserAnalytics
})(onClickOutside(List, clickOutsideConfig));
