// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import * as R from "ramda";
import { useDebouncedCallback } from "use-debounce";

import SegmentName from "./SegmentName";
import {
  Dropdown as StyledDropdown,
  Button,
  ListItem,
  EditButton
} from "./styles";
import useClickoutside from "src/hooks/useClickoutside";
import {
  setSegmentUserModalAttributes,
  searchUserSegments
} from "src/actions/orgSettings";
import { Input } from "src/components/user/Analytics/Filters/styles";
import Checkbox from "src/components/Checkbox";
import Icon from "src/icons";

import type { AppState, UserSegmentById } from "src/types";

type Props = {
  tileId: number,
  showModal: boolean,
  value: Array<number>,
  result: Array<number>,
  outerRef: any,
  userSegmentById: UserSegmentById,
  _searchUserSegments: Function,
  _setSegmentUserModalAttributes: Function,
  handleSelect: Function,
  handleClose: Function
};

const UserSegmentDropdown = ({
  tileId,
  value,
  result,
  _searchUserSegments,
  _setSegmentUserModalAttributes,
  handleSelect,
  outerRef,
  handleClose,
  userSegmentById
}: Props) => {
  const [searchSegment] = useDebouncedCallback(_searchUserSegments, 800);
  useClickoutside({ outerRef, handleClose });

  useEffect(() => {
    _searchUserSegments("");
  }, [_searchUserSegments]);

  const handleSearch = useCallback(
    (event: any) => {
      searchSegment(event.target.value);
    },
    [searchSegment]
  );

  const handleEdit = useCallback(
    (event: any, id: number) => {
      event.preventDefault();
      event.stopPropagation();
      _setSegmentUserModalAttributes({
        show: tileId,
        ...(userSegmentById[`${id}`] || {})
      });
    },
    [userSegmentById, tileId, _setSegmentUserModalAttributes]
  );

  return (
    <>
      <StyledDropdown>
        <Input
          type="text"
          placeholder={i18n.t(k.SEARCH)}
          onChange={handleSearch}
          autoFocus
        />

        {result.map(segment => (
          <ListItem key={segment} onClick={() => handleSelect(segment)}>
            <div>
              <Checkbox
                id={`segment${segment}`}
                handleChange={() => {}}
                checked={R.includes(segment, value)}
              />

              <span>
                <SegmentName id={segment} />
              </span>
            </div>

            <EditButton
              type="button"
              onClick={(event: any) => handleEdit(event, segment)}
            >
              <Icon type="editPencil" />
            </EditButton>
          </ListItem>
        ))}
        <Button
          type="button"
          onClick={() => _setSegmentUserModalAttributes({ show: tileId })}
        >
          {i18n.t(k.CREATE_NEW_USER_SEGMENT)}
        </Button>
      </StyledDropdown>
    </>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  result: app.orgSettings.segmentSearchResult,
  userSegmentById: app.orgSettings.userSegmentById
});

export default connect(mapStateToProps, {
  _searchUserSegments: searchUserSegments,
  _setSegmentUserModalAttributes: setSegmentUserModalAttributes
})(UserSegmentDropdown);
