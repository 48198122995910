import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";

import { NoUnreadWrapper, Subtitle, Heading } from "./styles";

const NoUnread = () => {
  return (
    <NoUnreadWrapper>
      <Heading>
        <div>
          <span role="img" aria-label="party popper">
            🎉
          </span>
          &nbsp;
          <h3>{i18n.t(k.CONGRATULATIONS)}</h3> &nbsp;
          {"   "}
          <span role="img" aria-label="party popper">
            🎉
          </span>
        </div>
        <Subtitle>{i18n.t(k.YOU_HAVE_UNREAD_CONVERSATION)}</Subtitle>
      </Heading>
      <Subtitle>
        <div>{i18n.t(k.TRY_APPLYING_OTHER_FILTERS)}</div>
      </Subtitle>
    </NoUnreadWrapper>
  );
};

export default NoUnread;
