// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";
import { connect } from "react-redux";
import { Portal } from "@chakra-ui/react";
import { ErrorModal, Overlays, Header, ErrorBody } from "./styles";
import Icons from "src/icons";
import type { AppState } from "src/types";

import { closeDownloadPreventedModal } from "src/actions/file";

type Props = {
  closeModal: Function,
  isDownloadBlocked: boolean
};

const DownloadPreventedModal = ({ closeModal, isDownloadBlocked }: Props) => {
  const close = () => {
    closeModal();
  };

  if (!isDownloadBlocked) {
    return <></>;
  }

  return (
    <Portal>
      <Overlays onClick={close}>
        <ErrorModal>
          <Header>
            <h2>{i18n.t(k.DOWNLOAD_PROHIBITED)}</h2>
            <Icons type="close" />
          </Header>
          <ErrorBody>
            <Icons type="warning" />
            {i18n.t(k.DOWNLOADING_FILES_IS_PROHIBITE)}
          </ErrorBody>
        </ErrorModal>
      </Overlays>
    </Portal>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  isDownloadBlocked: app.files.downloadBlocked
});

export default connect(mapStateToProps, {
  closeModal: closeDownloadPreventedModal
})(DownloadPreventedModal);
