// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";
import Link from "redux-first-router-link";

import * as atypes from "src/constants/actionTypes";
import { TabArea, Tab } from "src/styles";

type Props = {
  tab: string
};

const Tabs = ({ tab }: Props) => {
  if (tab === "people") {
    return (
      <TabArea>
        <Tab active>{i18n.t(k.PEOPLE1)}</Tab>
        <Link to={{ type: atypes.SET_GROUPS_REQUEST, payload: {} }}>
          <Tab>{i18n.t(k.GROUPS)}</Tab>
        </Link>
      </TabArea>
    );
  }

  return (
    <TabArea>
      <Link
        to={{
          type: atypes.SET_CONTACTS_REQUEST,
          meta: {
            query: {
              sort: ["displayName:asc"],
              status: ["Active", "Pending"]
            }
          }
        }}
      >
        <Tab>{i18n.t(k.PEOPLE1)}</Tab>
      </Link>
      <Tab active>{i18n.t(k.GROUPS)}</Tab>
    </TabArea>
  );
};

export default Tabs;
