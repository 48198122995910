// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React, { useCallback } from "react";
import { connect } from "react-redux";
import { RoundButton, UnreadCount } from "./styles";
import { updateFilter } from "src/actions/filter";
import type { AppState, CurrentFilter } from "src/types";

import {
  getToogleUnread,
  getCurrentFilter,
  getFilteredUnreadConversationCount
} from "src/reducers";

import Icons from "src/icons";

type Props = {
  unread: boolean,
  _updateFilter: Function,
  currentFilter: CurrentFilter,
  unreadCount: number
};

const Status = ({
  unread,
  _updateFilter,
  currentFilter,
  unreadCount
}: Props) => {
  const isMine = currentFilter.mine;

  const toggleUnread = useCallback(() => {
    if (!isMine) {
      return null;
    }
    if (unread) {
      _updateFilter({
        ...currentFilter,
        unread: false
      });
    } else {
      _updateFilter({
        ...currentFilter,
        unread: true,
        cancelled: false
      });
    }
  }, [unread, _updateFilter, currentFilter]);

  const displayCount = unreadCount > 0 && !unread;

  return (
    <RoundButton
      onClick={toggleUnread}
      onKeyPress={toggleUnread}
      active={unread === true}
      disabled={!isMine}
    >
      <Icons type="unreads" />
      {i18n.t(k.UNREAD)}
      {displayCount ? <UnreadCount>{unreadCount}</UnreadCount> : null}
    </RoundButton>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  unread: getToogleUnread(app),
  currentFilter: getCurrentFilter(app),
  unreadCount: getFilteredUnreadConversationCount(app)
});

export default connect(mapStateToProps, {
  _updateFilter: updateFilter
})(Status);
