// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import { connect } from "react-redux";
import React from "react";
import moment from "moment";

import {
  Groups as StyledGroups,
  GroupHeader,
  Name as StyledName
} from "./styles";
import Name from "src/components/Group/ImageLabel";
import Members from "src/containers/Group/Members";
import { getAddedOn } from "src/reducers";

import type { AppState, AddedOn } from "src/types";

type Props = {
  groups: Array<number>,
  addedOn: AddedOn
};

const Groups = ({ groups, addedOn }: Props) => {
  return (
    <StyledGroups>
      <GroupHeader>
        <tr>
          <th>{i18n.t(k.GROUP_NAME)}</th>
          <th>{i18n.t(k.PARTICIPANTS)}</th>
          <th>{i18n.t(k.ADDED_ON)}</th>
        </tr>
      </GroupHeader>
      <tbody>
        {(groups || []).map(g => (
          <tr key={g}>
            <td>
              <StyledName>
                <Name id={g} />
              </StyledName>
            </td>
            <td>
              <Members id={g} />
            </td>
            <td>
              {addedOn && addedOn[`${g}`]
                ? moment(addedOn).format(i18n.t(k.MMM_DD_YYYY))
                : null}
            </td>
          </tr>
        ))}
      </tbody>
    </StyledGroups>
  );
};

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  addedOn: getAddedOn(app, props.uid)
});

export default connect(mapStateToProps)(Groups);
