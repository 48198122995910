// @flow

import { connect } from "react-redux";
import React, { useEffect, useRef, useMemo } from "react";
import * as R from "ramda";
import { Text } from "@unifize/sarah";

import Visualization from "./Visualization";
import Icon from "src/icons";
import {
  MyConversationChart as StyledConversation,
  IconButton
} from "./styles";
import {
  getMyConversatoinChart,
  setTileEmptyStatus
} from "src/actions/homescreen";
import Loader from "src/components/Dashboard/Body/Loader";
import { exportChart } from "src/utils/charts";
import tileColors from "src/styles/constants/tileColors";

import type { AppState, HomescreenTile, Action } from "src/types";

type Props = {
  loading: boolean,
  chartData: Object,
  tile: HomescreenTile,
  sectionId: number,
  _getMyConversatoinChart: Function,
  _setTileEmptyStatus: (
    sectionId: number,
    tileId: number,
    isEmpty: boolean
  ) => Action
};

const MyConversationChart = ({
  loading,
  chartData,
  tile,
  sectionId,
  _getMyConversatoinChart,
  _setTileEmptyStatus
}: Props) => {
  const chartRef = useRef(null);
  const filter = tile.settings.filters;

  useEffect(() => {
    _getMyConversatoinChart({ tileId: tile.id, filter });
  }, [tile, _getMyConversatoinChart, filter]);

  const isTileHidden = useMemo(
    () => !tile.settings.displayEmpty && R.isEmpty(chartData) && !loading,
    [chartData]
  );

  useEffect(() => {
    _setTileEmptyStatus(sectionId, tile.id, isTileHidden);
  }, [R.isEmpty(chartData), isTileHidden]);

  const colorScheme = tileColors[tile.settings.tileColor];

  if (isTileHidden) {
    return <></>;
  }

  return (
    <StyledConversation color={tile.settings.tileColor}>
      {loading && R.isEmpty(chartData) ? (
        <>
          <Text variant="body" weight="bold" color={colorScheme.title}>
            {tile.title}
          </Text>
          <Text variant="caption" color={colorScheme.subTitle}>
            {tile.subTitle}
          </Text>
          <Loader />
        </>
      ) : (
        <>
          {R.isEmpty(chartData) ? (
            <>
              <Text variant="body" weight="bold" color={colorScheme.title}>
                {tile.settings.emptyMessage?.title || ""}
              </Text>
              <Text variant="caption" color={colorScheme.subTitle}>
                {tile.settings.emptyMessage?.subTitle || ""}
              </Text>
            </>
          ) : (
            <>
              <Text variant="body" weight="bold" color={colorScheme.title}>
                {tile.title}
              </Text>
              <Text variant="caption" color={colorScheme.subTitle}>
                {tile.subTitle}
              </Text>
              <IconButton onClick={() => exportChart(chartRef)}>
                <Icon type="export" />
              </IconButton>
              <Visualization
                chartRef={chartRef}
                type="scrollColumn2d"
                dataSource={chartData}
              />
            </>
          )}
        </>
      )}
    </StyledConversation>
  );
};

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  chartData: app.homescreen.myConversationChart[props.tile.id] || {},
  loading: app.homescreen.loadingConversationChart[props.tile.id]
});

export default connect(mapStateToProps, {
  _getMyConversatoinChart: getMyConversatoinChart,
  _setTileEmptyStatus: setTileEmptyStatus
})(MyConversationChart);
