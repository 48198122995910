// @flow
import i18n from "i18next";
import k from "src/i18n/keys";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactSwitch from "react-switch";
import Tooltip from "src/components/Tooltip";

import WebWorker from "src/workers/WebWorker";
import worker from "src/workers/app.worker";
import {
  toggleAllRecordsFilter,
  filterEmbeddedFields
} from "src/actions/workflows";
import { getWorkflowInstanceFilter } from "src/reducers";

import { AllRecordsContainer, FilterToggleContainer } from "./styles";
import Icon from "src/icons";
import * as colors from "src/styles/constants/colors";
import type { ColumnId, FilterWorkerData } from "src/types";

type Props = {
  label: string,
  isAllRecords: boolean,
  columnId: ColumnId,
  workerData: FilterWorkerData
};

const AllRecordsFilter = ({
  label,
  isAllRecords,
  columnId,
  workerData
}: Props) => {
  const dispatch = useDispatch();
  const filters = useSelector(({ app }) => getWorkflowInstanceFilter(app));

  const handleToggleSwitch = () => {
    dispatch(toggleAllRecordsFilter(columnId));

    const webWorker = new WebWorker(worker);

    // $FlowFixMe - Type of event listener not recognized
    webWorker.addEventListener("message", event => {
      dispatch(filterEmbeddedFields({ instances: event.data }));
    });

    webWorker.postMessage({
      ...workerData,
      payload: filters,
      allRecords: {
        ...workerData.allRecords,
        [columnId]: !isAllRecords
      }
    });
  };

  return (
    <AllRecordsContainer>
      <span>{i18n.t(k.FILTER_BY_VALUE)}</span>

      <FilterToggleContainer>
        <Tooltip
          contentSx={{
            maxHeight: "16px"
          }}
          placement="top"
          title={i18n.t(k.ALL_RECORDS_FILTER)}
          description={i18n.t(k.ALL_RECORDS_FILTER_DESCRIPTION)}
        >
          <Icon type="info" width={16} />
        </Tooltip>
        {label}
        <ReactSwitch
          onChange={handleToggleSwitch}
          uncheckedIcon={false}
          checkedIcon={false}
          onColor={colors.checked}
          offColor={colors.grey6}
          checked={!!isAllRecords}
          className={isAllRecords ? "off" : "on"}
          height={18}
          width={31}
        />
      </FilterToggleContainer>
    </AllRecordsContainer>
  );
};

export default AllRecordsFilter;
