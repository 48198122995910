// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React, { useEffect, useState, useRef } from "react";
import { connect, useSelector } from "react-redux";
import isEmail from "validator/lib/isEmail";
import { toast } from "react-toastify";
import {
  getSignatureURLs,
  clearChecklistFieldError,
  clearCustomSignature
} from "src/actions/checklist";
import { requestOTP } from "src/api/checklist";
import Icon from "src/icons";
import {
  getChecklistFileUploadProgress,
  getSignatureByType
} from "src/reducers/checklist";
import {
  getRoomFieldValueStatus,
  getRoomFieldValueError,
  getIsUploadingFile,
  getCurrentUserId,
  getUserHasPassword
} from "src/reducers";
import Upload from "src/containers/chatroom/Upload";
import ProgressBar from "src/components/Dock/Checklist/File/ProgressBar";
import { dataStages } from "src/constants";
import * as styles from "./styles";

import {
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Textarea,
  Radio,
  RadioGroup,
  VStack,
  HStack,
  Image,
  Modal as ChakraModal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Button,
  Box,
  Flex
} from "@chakra-ui/react";

import type {
  AppState,
  RoomId,
  FieldId,
  Action,
  ChecklistFieldUploadProgress
} from "src/types";

type Props = {
  isOpen: boolean,
  systemSignature: ?string,
  customSignature: ?string,
  roomId: RoomId,
  fieldId: FieldId,
  isUploading: boolean,
  progress: ChecklistFieldUploadProgress,
  loading: boolean,
  error: ?string,
  handleSign: Function,
  onClose: Function,
  _getSignatureURLs: Function,
  _clearChecklistFieldError: Function,
  _clearCustomSignature: () => Action,
  commentOnApproval: boolean,
  commentOnRejection: boolean
};

const signatureTypesMap = {
  custom: true,
  system: false
};

const approvalTypesMap = {
  approval: true,
  rejection: false
};

const Modal = ({
  isOpen,
  systemSignature,
  customSignature,
  roomId,
  fieldId,
  handleSign,
  isUploading,
  progress,
  loading,
  error,
  _getSignatureURLs,
  onClose,
  _clearChecklistFieldError,
  _clearCustomSignature,
  commentOnApproval,
  commentOnRejection
}: Props) => {
  const isInitialEmailRef = useRef(false);
  const isInitialPasswordRef = useRef(false);
  const [isOTPEntered, setIsOTPEntered] = useState(false);

  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [password, setPassword] = useState("");
  const [isOTPSent, setIsOTPSent] = useState(false);
  const [isOTPResent, setIsOTPResent] = useState(false);
  const [comment, setComment] = useState("");
  const [signatureType, setSignatureType] = useState(
    customSignature ? "custom" : "system"
  );
  const [approvalType, setApprovalType] = useState("approval");
  const [cropping, setCropping] = useState(false);
  const currentUserId = useSelector(({ app }) => getCurrentUserId(app));
  const userHasPassword = useSelector(({ app }) =>
    getUserHasPassword(app, currentUserId)
  );

  // In case of uploading custom signature the name of the file always
  // starts with "signature" and it is the only pair present in the
  // object
  const signUploadProgress = Object.values(progress || {})[0];

  // Reset the state of the form once its closed
  const resetForm = () => {
    setCropping(false);
    setEmail("");
    setOtp("");
    setPassword("");
    isInitialEmailRef.current = false;
    setIsOTPEntered(false);
    setComment("");
    setIsOTPSent(false);
    setIsOTPResent(false);
    setSignatureType(customSignature ? "custom" : "system");
    setApprovalType("approval");
  };

  const hideModal = () => {
    resetForm();
    _clearChecklistFieldError(fieldId);
    onClose();
  };

  const handleFormSubmit = e => {
    e.preventDefault();

    const formValue = {
      customSignature: signatureTypesMap[signatureType],
      approved: approvalTypesMap[approvalType],
      comment,
      username: email,
      ...(!userHasPassword ? { otp } : { password })
    };

    handleSign({ value: formValue });
  };

  const generateOTP = async () => {
    try {
      const toastMessage = isOTPSent
        ? i18n.t(k.RESENDING_OTP_TO_YOUR_EMAIL)
        : i18n.t(k.OTP_SENT_TO_EMAIL);

      await requestOTP(email);
      if (isOTPSent) {
        setIsOTPResent(true);
        setTimeout(() => {
          setIsOTPResent(false);
        }, 10000);
      } else {
        setIsOTPSent(true);
      }
      toast.success(toastMessage);
    } catch (err) {
      setIsOTPSent(false);
      setIsOTPResent(false);
      toast.error(err?.message || i18n.t(k.FAILED_TO_SEND_OTP));
    }
  };

  const handleEmailChange = e => {
    isInitialEmailRef.current = true;
    setEmail(e.target.value);
  };

  const handlePasswordChange = e => {
    isInitialPasswordRef.current = true;
    setPassword(e.target.value);
  };

  const handleOTPChange = e => {
    setOtp(e.target.value);
  };

  // Returns true of the entered password is valid
  const validatePassword = (): boolean => {
    return password.length > 0;
  };

  // Returns true if the approval values are NOT valid
  // false if they are valid
  const validateApproval = (): boolean => {
    return (
      !isEmail(email) ||
      (!userHasPassword && otp.length !== 6) ||
      (userHasPassword && !validatePassword()) ||
      (approvalType === "approval"
        ? commentOnApproval && comment.length < 1
        : commentOnRejection && comment.length < 1)
    );
  };

  useEffect(() => {
    if (!loading && !error) hideModal();
  }, [loading, error]);

  useEffect(() => {
    _getSignatureURLs();
  }, [_getSignatureURLs]);

  useEffect(() => {
    return () => {
      resetForm();
    };
  }, []);

  const isOTPInvalid: boolean = otp.length < 6 && isOTPEntered;

  const customStyles = {
    direction: { base: "column", md: "row" },
    align: { base: "flex-start", md: "center" },
    gap: { base: "0.5rem", md: "1rem" },
    left: { base: "0px", md: "96px" },
    width: { base: "100%", md: "45%" }
  };

  return (
    <ChakraModal
      isOpen={isOpen}
      onClose={hideModal}
      isCentered
      scrollBehavior="inside"
    >
      <ModalOverlay />

      <ModalContent borderRadius="4px" maxW="720px">
        <ModalHeader sx={styles.singatureModalHeader}>
          {i18n.t(k.APPLY_YOUR_SIGNATURE)}
        </ModalHeader>
        <ModalCloseButton sx={styles.modalCloseButton} />

        <ModalBody sx={styles.signatureModalBody}>
          <form onSubmit={handleFormSubmit} autoComplete="off">
            <VStack spacing={6}>
              <FormControl sx={styles.formFieldWrapper}>
                <Flex
                  direction={customStyles.direction}
                  align={customStyles.align}
                  gap={customStyles.gap}
                >
                  <FormLabel maxW="max-content">{i18n.t(k.TYPE)}</FormLabel>
                  <RadioGroup value={approvalType} onChange={setApprovalType}>
                    <HStack spacing={4}>
                      <Radio id="approval" value="approval" size="sm">
                        {i18n.t(k.APPROVAL)}
                      </Radio>
                      <Radio id="rejection" value="rejection" size="sm">
                        {i18n.t(k.REJECTION)}
                      </Radio>
                    </HStack>
                  </RadioGroup>
                </Flex>
              </FormControl>

              <FormControl isRequired sx={styles.formFieldWrapper}>
                <Flex
                  direction={customStyles.direction}
                  align={customStyles.align}
                  gap={customStyles.gap}
                >
                  <FormLabel for="email">{i18n.t(k.EMAIL_ID)}</FormLabel>
                  <Input
                    data-cy="approvalEmailInput"
                    id="email"
                    type="email"
                    placeholder="e.g. JohnDDoe@gmail.com"
                    sx={styles.formFieldInput}
                    value={email}
                    onChange={handleEmailChange}
                    autoComplete="off"
                    onKeyDown={async e => {
                      // Generate OTP on Enter
                      if (e.key === "Enter" && !userHasPassword && !isOTPSent) {
                        e.preventDefault();
                        await generateOTP();
                      }
                    }}
                    isInvalid={!isEmail(email) && isInitialEmailRef.current}
                  />
                </Flex>

                {!userHasPassword && isOTPResent ? (
                  <FormHelperText ml={customStyles.left}>
                    {i18n.t(k.OTP_SENT_AGAIN_PLEASE_CHECK_Y)}
                  </FormHelperText>
                ) : isOTPSent ? (
                  <FormHelperText ml={customStyles.left}>
                    {i18n.t(k.OTP_SENT_TO_THE_MENTIONED_EMAI)}{" "}
                    <a
                      onClick={generateOTP}
                      style={{
                        cursor: "pointer"
                      }}
                    >
                      {i18n.t(k.RESEND)}
                    </a>
                  </FormHelperText>
                ) : null}
              </FormControl>

              {userHasPassword && (
                <FormControl isRequired sx={styles.formFieldWrapper}>
                  <Flex
                    direction={customStyles.direction}
                    align={customStyles.align}
                    gap={customStyles.gap}
                  >
                    <FormLabel for="password">{i18n.t(k.PASSWORD)}</FormLabel>
                    <Input
                      data-cy="approvalPasswordInput"
                      id="password"
                      type="password"
                      placeholder={i18n.t(k.ENTER_PASSWORD)}
                      sx={styles.formFieldInput}
                      value={password}
                      onChange={handlePasswordChange}
                      autoComplete="on"
                      isInvalid={!password && isInitialPasswordRef.current}
                    />
                  </Flex>
                </FormControl>
              )}

              {!userHasPassword && isOTPSent && (
                <FormControl isRequired sx={styles.formFieldWrapper}>
                  <Flex
                    direction={customStyles.direction}
                    align={customStyles.align}
                    gap={customStyles.gap}
                  >
                    <FormLabel>{i18n.t(k.OTP)}</FormLabel>
                    <Input
                      id="otp"
                      name="otp"
                      type="number"
                      sx={styles.formFieldInput}
                      placeholder={i18n.t(k.ENTER_OTP)}
                      value={otp}
                      maxLength={6}
                      onBlur={() => {
                        // The user has tried entering the OTP
                        // show the error message now if it is invalid
                        setIsOTPEntered(true);
                      }}
                      onChange={handleOTPChange}
                      isInvalid={isOTPInvalid}
                    />
                  </Flex>
                  {(error === "Invalid credentials." || isOTPInvalid) && (
                    <FormHelperText
                      ml={customStyles.left}
                      sx={{ color: "red" }}
                    >
                      {i18n.t(k.INCORRECT_OTP)}
                    </FormHelperText>
                  )}
                </FormControl>
              )}

              {!userHasPassword && !isOTPSent && (
                <FormControl
                  pl={customStyles.left}
                  sx={{
                    marginTop: "1rem !important"
                  }}
                >
                  <Button
                    variant="uBlueOutlined"
                    sx={styles.actionButton}
                    onClick={generateOTP}
                    isDisabled={!isEmail(email)}
                  >
                    {i18n.t(k.GENERATE_OTP)}
                  </Button>
                </FormControl>
              )}

              <FormControl
                isRequired={
                  approvalType === "approval"
                    ? commentOnApproval
                    : commentOnRejection
                }
                sx={{
                  ...styles.formFieldWrapper,
                  "> div": {
                    alignItems: "start !important"
                  }
                }}
              >
                <Flex
                  direction={customStyles.direction}
                  align={customStyles.align}
                  gap={customStyles.gap}
                >
                  <FormLabel>{i18n.t(k.COMMENT)}</FormLabel>
                  <Textarea
                    data-cy="approvalCommentInput"
                    value={comment}
                    onChange={e => setComment(e.target.value)}
                    placeholder={i18n.t(k.ENTER_COMMENT_HERE)}
                    resize="vertical"
                    sx={styles.formFieldInput}
                  />
                </Flex>
                {error === "Comment is mandatory." && (
                  <FormHelperText ml={customStyles.left} sx={{ color: "red" }}>
                    {error}
                  </FormHelperText>
                )}
              </FormControl>

              <FormControl pl={customStyles.left}>
                <RadioGroup value={signatureType} onChange={setSignatureType}>
                  <Flex
                    alignItems="start"
                    width="100%"
                    justify="space-between"
                    wrap="wrap"
                    gap="2"
                  >
                    <VStack alignItems="start" width={customStyles.width}>
                      <Radio id="custom" value="custom" size="sm">
                        {i18n.t(k.UPLOAD_YOUR_OWN_SIGNATURE)}
                      </Radio>

                      {!cropping && customSignature && !isUploading && (
                        <HStack w="100%" justifyContent="flex-start">
                          <Image
                            src={customSignature}
                            alt="custom-signature"
                            marginLeft="20px !important"
                            maxWidth="94px"
                            maxHeight="49px"
                          />

                          <Button
                            sx={{
                              background: "transparent",
                              ":hover": {
                                background: "transparent"
                              }
                            }}
                            onClick={() => {
                              _clearCustomSignature();
                            }}
                          >
                            <Icon type="newDelete" />
                          </Button>
                        </HStack>
                      )}

                      {isUploading &&
                        signUploadProgress &&
                        parseInt(signUploadProgress) > 0 && (
                          <ProgressBar
                            progress={parseInt(signUploadProgress)}
                            style={{
                              width: "100%",
                              marginLeft: "20px",
                              marginBottom: 0
                            }}
                          />
                        )}

                      <Box ml="20px !important">
                        <Upload
                          variant="uBlueOutlined"
                          styles={styles.actionButton}
                          location="signature-upload"
                          cropping={cropping}
                          setCropping={setCropping}
                          roomId={roomId}
                          fieldId={fieldId}
                          render={openFileBrowser =>
                            !customSignature &&
                            !isUploading && (
                              <Button
                                onClick={openFileBrowser}
                                size="lg"
                                variant="uBlueOutlined"
                                sx={{
                                  ...styles.actionButton,
                                  width: "163px !important"
                                }}
                                isDisabled={
                                  !!customSignature ||
                                  signatureType !== "custom"
                                }
                                padding="4px 8px !important"
                              >
                                {i18n.t(k.ATTACH_FILE)}
                              </Button>
                            )
                          }
                        />
                      </Box>
                    </VStack>

                    <VStack
                      width={customStyles.width}
                      alignItems="start"
                      sx={styles.systemGeneratedContainer}
                    >
                      <Radio id="system" value="system" size="sm">
                        {i18n.t(k.USE_SYSTEM_GENERATED_SIGNATURE)}
                      </Radio>
                      <Image
                        src={systemSignature}
                        alt="system signature"
                        maxWidth="102px"
                        maxheight="44px"
                        marginLeft="20px !important"
                      />
                    </VStack>
                  </Flex>
                </RadioGroup>
              </FormControl>
            </VStack>
          </form>
        </ModalBody>

        <VStack sx={styles.modalFooter}>
          <HStack alignItems="start">
            <Button
              data-cy="approvalSubmitButton"
              variant="uPrimary"
              onClick={handleFormSubmit}
              isLoading={loading}
              borderRadius="0.25rem"
              isDisabled={validateApproval()}
              px="1rem"
              py="0.25rem"
              sx={styles.confirmButton}
            >
              {i18n.t(k.CONFIRM_AND_SIGN)}
            </Button>
            <Button
              variant="uSecondary"
              borderRadius="0.25rem"
              px="1rem"
              py="0.25rem"
              onClick={hideModal}
            >
              {i18n.t(k.CANCEL)}
            </Button>
          </HStack>
        </VStack>
      </ModalContent>
    </ChakraModal>
  );
};

const mapStateToProps = (
  { app }: { app: AppState },
  { roomId, fieldId }: Props
) => ({
  systemSignature: getSignatureByType(app.checklist, "system"),
  customSignature: getSignatureByType(app.checklist, "custom"),
  isUploading: getIsUploadingFile(app),
  progress: getChecklistFileUploadProgress(app.checklist, roomId, fieldId),
  loading:
    getRoomFieldValueStatus(app, fieldId, roomId) === dataStages.updating,
  error: getRoomFieldValueError(app, fieldId, roomId)
});

export default connect(mapStateToProps, {
  _getSignatureURLs: getSignatureURLs,
  _clearChecklistFieldError: clearChecklistFieldError,
  _clearCustomSignature: clearCustomSignature
})(Modal);
