// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  VStack,
  Text,
  UnorderedList,
  ListItem,
  HStack,
  OrderedList,
  Button
} from "@chakra-ui/react";
import Integration from "src/img/apps/integration";
import { integrations } from "src/constants/integrations";
import * as colors from "src/styles/constants/colors";

type Props = {
  isOpen: boolean,
  onClose: Function,
  onInstall: Function,
  title: string,
  name: string
};

const InfoModal = ({ isOpen, onClose, onInstall, title, name }: Props) => {
  const integrationSteps = integrations.get(name)?.info.steps || [];
  const integrationInfoTitle = integrations.get(name)?.info.title || "";
  const integrationPoints = integrations.get(name)?.info.points || [];
  const getLogo = () => {
    const logo = require(`src/img/apps/${name}.svg`);
    return logo;
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered={true}>
      <ModalOverlay />
      <ModalContent maxWidth={960}>
        <ModalCloseButton bg="none" />
        <ModalBody p={8}>
          <HStack>
            <VStack bg="unifize_grey26" p={8} w="50%">
              <Integration image={getLogo()} />
              {integrationPoints.length == 1 ? (
                <Text align="center">{integrationPoints[0]}</Text>
              ) : (
                <VStack>
                  <Text textStyle="heading">{integrationInfoTitle}</Text>
                  <UnorderedList textStyle="label" p={0}>
                    {integrationPoints.map((item, index) => (
                      <ListItem key={index}>{item}</ListItem>
                    ))}
                  </UnorderedList>
                </VStack>
              )}
            </VStack>
            <VStack p={8} w="50%">
              <Text w="full" align="left" textStyle="modalTitle" m={0}>
                {title} {i18n.t(k.INTEGRATION1)}
              </Text>
              <OrderedList p={4}>
                {integrationSteps.map((item, index) => (
                  <ListItem mb={2} key={index}>
                    {item}
                  </ListItem>
                ))}
              </OrderedList>

              <Button onClick={onInstall} h="40px" variant="uPrimary" w="full">
                {i18n.t(k.INTEGRATE)} {title}
              </Button>
              <Text as="i" fontSize="sm" color={colors.inkLighter} pt={2}>
                {i18n.t(k.GLOBAL_OR_PRIVILEGED_ADMINIST)}
              </Text>
            </VStack>
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default InfoModal;
