// @flow

import React from "react";
import StatusModal from "src/components/chatroom-nav/filter/StatusModal";
import type { Set as ImmutableSetType } from "immutable";

type Props = {
  _hideStatusModal: Function,
  statusModal: boolean,
  active: string,
  isWorkflow: ?number,
  _setCheckedStatus: Function,
  currentSelectedStatuses: ImmutableSetType<number>,
  _setStandardStatusFilter: Function
};

const StatusModalWrapper = (props: Props) => (
  <StatusModal homeScreen {...props} />
);

export default StatusModalWrapper;
