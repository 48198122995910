// @flow

import React from "react";
import { useSelector } from "react-redux";

import Text from "./Text";
import Date from "./Date";
import File from "./File";
import Number from "./Number";
import Picklist from "./Picklist";
import ChecklistUsers from "./ChecklistUsers";
import Conversation from "./Conversation";
import Revision from "./Revision";
import LinkedField from "./LinkedField";
import Approval from "./Approval";
import Form from "./Form";
import RichText from "./RichText";

import { getChecklistFieldDetails } from "src/reducers";

import type { RoomId, FieldId, ChecklistId } from "src/types";

type Props = {
  roomId: RoomId,
  fieldId: FieldId,
  formId: ?number,
  checklistId?: ?ChecklistId,
  roomFieldFormId?: string
};

const FieldComponent = ({
  roomId,
  formId,
  fieldId,
  checklistId,
  roomFieldFormId
}: Props) => {
  const details = useSelector(({ app }) =>
    getChecklistFieldDetails(app, `${fieldId}`)
  );

  // $FlowFixMe - optional chaining is not yet supported in Flow
  const fieldType = details?.get("type");

  switch (fieldType) {
    case "pdf":
    case "file":
      return (
        <File
          roomId={roomId}
          formId={formId}
          fieldId={fieldId}
          roomFieldFormId={roomFieldFormId}
        />
      );

    case "approval":
      return (
        <Approval
          formId={formId}
          roomId={roomId}
          fieldId={fieldId}
          roomFieldFormId={roomFieldFormId}
        />
      );

    case "text":
      return (
        <Text
          formId={formId}
          roomId={roomId}
          fieldId={fieldId}
          roomFieldFormId={roomFieldFormId}
        />
      );

    case "date":
      return (
        <Date
          formId={formId}
          roomId={roomId}
          fieldId={fieldId}
          roomFieldFormId={roomFieldFormId}
        />
      );

    case "number":
      return (
        <Number
          formId={formId}
          roomId={roomId}
          fieldId={fieldId}
          roomFieldFormId={roomFieldFormId}
        />
      );

    case "user":
      return (
        <ChecklistUsers
          formId={formId}
          roomId={roomId}
          fieldId={fieldId}
          roomFieldFormId={roomFieldFormId}
        />
      );

    case "select":
      return (
        <Picklist
          formId={formId}
          roomId={roomId}
          fieldId={fieldId}
          roomFieldFormId={roomFieldFormId}
        />
      );

    case "task":
    case "decision":
    case "group":
    case "workflow":
    case "conversation":
    case "chatPickList":
      return (
        <Conversation
          formId={formId}
          roomId={roomId}
          fieldId={fieldId}
          checklistId={checklistId}
          roomFieldFormId={roomFieldFormId}
        />
      );

    case "revision":
      return <Revision roomId={roomId} formId={formId} fieldId={fieldId} />;
    case "link":
      return <LinkedField roomId={roomId} formId={formId} fieldId={fieldId} />;
    case "childConversation":
      return (
        <Conversation
          formId={formId}
          roomId={roomId}
          fieldId={fieldId}
          checklistId={checklistId}
          roomFieldFormId={roomFieldFormId}
          parentConversation
        />
      );

    case "form":
      return <Form roomId={roomId} formId={formId} fieldId={fieldId} />;

    case "richtext":
      return (
        <RichText
          data-cy="richTextInput"
          formId={formId}
          roomId={roomId}
          fieldId={fieldId}
          roomFieldFormId={roomFieldFormId}
        />
      );
    default:
      return null;
  }
};

export default FieldComponent;
