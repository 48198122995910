// @flow

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import * as R from "ramda";

import Name from "./Name";
import {
  Remove as StyledRemove,
  NameWrapper,
  EditSearchInput,
  InputIconWrapper,
  InputBlueTickWrapper,
  BlueTickIconWrapper
} from "./styles";
import DeleteAndMapIcons from "./DeleteAndMapIcons";
import { getWorkflowTemplates, getStatusTitle } from "src/reducers";
import Icons from "src/icons";
import OutsideClickHandler from "src/components/OutsideClickHandler";
import { PENDING, COMPLETED } from "src/constants/status";

import type { AppState, WorkflowStatus } from "src/types";
import type { UpdateStatusFunction } from "src/components/Manage/Builder/Status";

import SettingsModal from "./SettingsModal";

type Props = {
  id: number,
  handleClick: Function,
  handleDeleteStatus: Function,
  templateId: number,
  search: string,
  searchRef: any,
  handleSearch: Function,
  title: string,
  setSearch: Function,
  handleEditStatus: Function,
  tempName: string,
  setTempName: Function,
  statusChangeModal: boolean,
  setStatusChangeModal: Function,
  updateStatusData: UpdateStatusFunction,
  data: WorkflowStatus
};

const Remove = ({
  id,
  handleClick,
  handleDeleteStatus,
  templateId,
  search,
  searchRef,
  handleSearch,
  setSearch,
  title,
  handleEditStatus,
  tempName,
  setTempName,
  statusChangeModal,
  setStatusChangeModal,
  updateStatusData,
  data
}: Props) => {
  const [edit, setEdit] = useState(false);

  const showStatusChangeModal = (e: any, statusId: number) => {
    e.stopPropagation();
    if (R.includes(statusId, [PENDING, COMPLETED])) {
      return; // Don't allow mapping for pending and completed status
    }
    setStatusChangeModal(true);
  };

  const deleteStatus = (
    e: any,
    processTemplateId: number,
    statusId: number
  ) => {
    e.stopPropagation();
    handleDeleteStatus(processTemplateId || null, statusId);
  };

  const handleEdit = (statusId: number) => {
    if (!R.includes(statusId, [PENDING, COMPLETED])) {
      setEdit(true);
      setSearch(title);
      setTempName(title);
    }
  };

  const handleCancel = () => {
    setSearch(title);
    setEdit(false);
  };

  // if title is updated display name from title instead of local state
  // when editing status name
  useEffect(() => {
    setTempName("");
  }, [title]);

  const handleSave = statusId => {
    setEdit(false);
    handleEditStatus(statusId);
  };

  const handleEditSave = (e: any, statusId: number) => {
    if (e.keyCode === 13) {
      handleEditStatus(statusId);
      setEdit(false);
    }
  };

  return (
    <StyledRemove id={id} onClick={handleClick} active={data.active}>
      {edit ? (
        <InputIconWrapper>
          <OutsideClickHandler onClickOutside={handleCancel}>
            <InputBlueTickWrapper>
              <EditSearchInput
                type="text"
                id="search"
                value={search}
                ref={searchRef}
                placeholder=""
                onChange={handleSearch}
                onKeyDown={e => handleEditSave(e, id)}
                autoComplete="off"
                autoFocus
              />

              {search.length > 0 && (
                <BlueTickIconWrapper onClick={() => handleSave(id)}>
                  <Icons type="blueTick" />
                </BlueTickIconWrapper>
              )}
            </InputBlueTickWrapper>
          </OutsideClickHandler>
          <DeleteAndMapIcons
            edit={edit}
            id={id}
            templateId={templateId}
            active={data.active}
            showStatusChangeModal={showStatusChangeModal}
            deleteStatus={deleteStatus}
          />
        </InputIconWrapper>
      ) : (
        <>
          {statusChangeModal && (
            <SettingsModal
              isOpen={statusChangeModal}
              onClose={() => setStatusChangeModal(false)}
              id={id}
              title={title}
              data={data}
              updateStatusData={updateStatusData}
            />
          )}

          <NameWrapper
            onClick={() => handleEdit(id)}
            active={data.active}
            defaultStatus={R.includes(id, [PENDING, COMPLETED])}
          >
            {/* display status from local state untill respnse from api */}
            {parseInt(id, 10) > 0 && (title === "" || tempName === title) ? (
              <span>{search}</span>
            ) : (
              <Name id={id} />
            )}

            <DeleteAndMapIcons
              edit={edit}
              id={id}
              templateId={templateId}
              showStatusChangeModal={showStatusChangeModal}
              deleteStatus={deleteStatus}
            />
          </NameWrapper>
        </>
      )}
    </StyledRemove>
  );
};

const mapStateToProps = ({ app }: { app: AppState }, { id }) => ({
  templateId: app.workflow.builderDialog.id,
  workflow: getWorkflowTemplates(app),
  title: getStatusTitle(app, `${id}`)
});

export default connect(mapStateToProps, {})(Remove);
