// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React, { useCallback, useState, useEffect } from "react";
import { connect } from "react-redux";
import * as R from "ramda";

import FieldWrapper from "./styles";

// TODO: Remove this later
import location from "src/constants/location";
import Text from "src/components/Dock/Checklist/Text";
import Number from "src/components/Dock/Checklist/Number";
import Date from "src/components/Dock/Checklist/Date";
import Conversation from "src/components/Dock/Checklist/Conversation";
import Picklist from "src/components/Dock/Checklist/Picklist";
import File from "src/components/Dock/Checklist/File";
import ChecklistUsers from "src/components/Dock/Checklist/ChecklistUsers";
import Form from "src/components/Dock/Checklist/Form";
import LinkedField from "src/components/Dock/Checklist/LinkedField";
import RichText from "src/components/Dock/Checklist/RichText";
import useFields from "src/components/Dock/Checklist/useFields";
import { Container, Label } from "src/components/Dock/Checklist/styles";
import {
  getChecklistValue,
  getChecklistFieldDetails,
  isProcessRowSelected,
  getRoomFieldValueStatus,
  getMututalVersionsAndCurrentVersionId,
  getSelectedChecklist
} from "src/reducers";
import {
  updateChecklistFromManageView,
  bulkUpdateProcess
} from "src/actions/workflows";
import type { BulkUpdateProcessActionCreator } from "src/actions/workflows";
import httpMethods from "src/constants/httpMethods";
import appLocation from "src/constants/location";

import type {
  AppState,
  ChecklistId,
  FieldId,
  ChecklistValue,
  RoomId,
  ColumnId,
  DataStage,
  ChecklistField,
  SelectedChecklist,
  BulkUpdateMode,
  FieldValue,
  FormId,
  HttpMethods,
  ChecklistFieldTypes
} from "src/types";
import { Button, useBoolean } from "@chakra-ui/react";
import AdvancedBulkUpdaterModal from "./AdvancedBulkUpdaterModal";

type Props = {
  details: ChecklistField,
  roomId: RoomId,
  columnId: ColumnId,
  checklistValue: ChecklistValue,
  selectedChecklist: SelectedChecklist,
  id: FieldId,
  checklistId?: ChecklistId,
  type: ChecklistFieldTypes,
  _setChecklistValueFromManageView: Function,
  editRoomId: RoomId,
  index: number,
  selectedValue: any,
  handleClose: Function,
  _bulkUpdateProcess: BulkUpdateProcessActionCreator,
  rowSelected: boolean,
  fromManageView: ?boolean,
  valueStatus: ?DataStage,
  getMututalVersionsAndCurrentVersionId: Function,
  embeddedIndex: number
};

export type AdvancedBulkUpdateFunction = (mode: BulkUpdateMode) => void;

const roomProps = [
  "owner",
  "versionCount",
  "statusTitle",
  "privacy",
  "id",
  "currentVersion",
  "autoNo",
  "seqNo",
  "address",
  "derivedTitle",
  "status",
  "versionComment",
  "processTitle",
  "title",
  "type",
  "version",
  "fields",
  "dueDate",
  "active",
  "templateId",
  "outcome",
  "orgId",
  "canceled"
];

const getUpdatedFieldValue = ({
  currentValue,
  value,
  type,
  httpMethod,
  getMututalVersionsAndCurrentVersionId
}): any => {
  let updatedValue = R.clone(currentValue);

  if (type === "link") {
    if (httpMethod === httpMethods.patch) {
      if (value.linkAllVersions) {
        const { versions, currentVersionId } =
          getMututalVersionsAndCurrentVersionId(value.chatroom);

        //  - Get all mutual versions of this room
        let versionIds = R.pluck("id", versions);

        //  - Remove them from value
        versionIds.forEach(versionId => {
          updatedValue = R.dissocPath(["entities", "chatrooms", versionId])(
            updatedValue
          );
        });

        versionIds = versionIds.map(item => parseInt(item, 10));
        updatedValue.result = R.without(versionIds, updatedValue.result);

        // 2. add current version
        let currentVersionRoom = versions.find(
          R.propEq("id", String(currentVersionId))
        );
        currentVersionRoom = R.pick(roomProps, currentVersionRoom);
        currentVersionRoom = R.mergeDeepLeft({ fields: [] })(
          currentVersionRoom
        );

        updatedValue = R.assocPath(
          ["entities", "chatrooms", String(currentVersionId)],
          {
            chatroom: currentVersionRoom,
            linkAllVersions: true,
            originChatroomId: currentVersionId
          }
        )(updatedValue);

        // $FlowFixMe
        updatedValue.result = R.append(currentVersionId)(updatedValue.result);
      } else if (!R.isEmpty(value.chatroom)) {
        const { versions } = getMututalVersionsAndCurrentVersionId(
          value.chatroom[0]
        );

        const versionIds = versions.map(item => parseInt(item.id, 10));

        // Remove existing versions in `updatedValue
        const existingVersionIds = R.intersection(
          updatedValue.result,
          versionIds
        );

        existingVersionIds.forEach(versionId => {
          updatedValue = R.dissocPath([
            "entities",
            "chatrooms",
            String(versionId)
          ])(updatedValue);
        });

        updatedValue.result = R.without(
          existingVersionIds,
          updatedValue.result
        );

        // Add new versions in `value`
        updatedValue.result = R.concat(updatedValue.result, value.chatroom);

        value.chatroom.forEach(roomId => {
          let room = versions.find(R.propEq("id", String(roomId)));
          room = R.pick(roomProps, room);
          room = R.mergeDeepLeft({ fields: [] })(room);

          updatedValue = R.assocPath(
            ["entities", "chatrooms", String(roomId)],
            {
              chatroom: room,
              linkAllVersions: false,
              originChatroomId: roomId
            }
          )(updatedValue);
        });
      }
    } else if (httpMethod === httpMethods.delete) {
      const { originChatroomId } = value;

      // Remove `originChatroomId` from `updatedValue.result`
      updatedValue.result = R.without([originChatroomId])(updatedValue.result);

      // Remove `originChatroomId` from `updatedValue.entities.chatroom`
      updatedValue = R.dissocPath([
        "entities",
        "chatrooms",
        String(originChatroomId)
      ])(updatedValue);
    }
  } else {
    updatedValue = value;
  }

  return updatedValue;
};

const Field = ({
  details,
  roomId,
  columnId,
  checklistValue,
  id,
  checklistId = null,
  type,
  _setChecklistValueFromManageView,
  editRoomId,
  index,
  selectedValue,
  handleClose,
  _bulkUpdateProcess,
  rowSelected,
  fromManageView,
  valueStatus,
  getMututalVersionsAndCurrentVersionId,
  embeddedIndex
}: Props) => {
  const label = details ? details.get("label") : "";

  let FieldComp = null;

  const {
    edit,
    settings,
    openEdit,
    closeEdit,
    increment,
    decrement,
    setValue
  } = useFields({
    checklistValue,
    details
  });
  const multiple = (settings || {}).multiple || false;

  const [selectedFieldValue, setSelectedFieldValue] = useState(selectedValue);

  const [isAdvancedBulkUpdaterOpen, setAdvancedBulkUpdaterVisibility] =
    useBoolean();

  const handleSelectedChange = useCallback((e: any) => {
    setSelectedFieldValue(e.target.value);
  }, []);

  useEffect(() => {
    setSelectedFieldValue(selectedValue);
  }, [selectedValue]);

  const setChecklistValue = ({
    roomId,
    id: fieldId,
    value: fieldDetail,
    progress: _progress,
    formId: _formId,
    httpMethod = undefined,
    extraBody = null,
    columnId
  }: {
    roomId: RoomId,
    id: FieldId,
    value: FieldValue,
    progress: boolean,
    formId?: FormId,
    httpMethod?: HttpMethods,
    extraBody?: Object,
    columnId?: ColumnId
  }) => {
    console.log({ roomId, _progress, _formId });
    const { value, type } = fieldDetail;
    if (rowSelected) {
      if (!multiple) {
        _bulkUpdateProcess({
          attrs: {
            [fieldId]: value
          }
        });
      } else {
        // Only required for linked field
        const updatedValue = getUpdatedFieldValue({
          currentValue: selectedFieldValue,
          value,
          type,
          httpMethod,
          getMututalVersionsAndCurrentVersionId
        });

        setSelectedFieldValue(updatedValue);
      }
    } else {
      _setChecklistValueFromManageView(
        editRoomId,
        {
          [fieldId]: value,
          type,
          value
        },
        index,
        fieldId,
        httpMethod,
        extraBody,
        columnId,
        embeddedIndex
      );
    }

    if (!multiple && rowSelected) {
      handleClose();
    }
  };

  const setFieldValue = ({
    value,
    httpMethod,
    extraBody,
    columnId,
    embeddedIndex
  }: {
    value: any,
    httpMethod: HttpMethods,
    extraBody?: Object,
    columnId?: ColumnId,
    embeddedIndex?: number
  }) => {
    return setChecklistValue({
      roomId,
      id,
      value: {
        value,
        type,
        checked: true
      },
      progress: true,
      httpMethod,
      extraBody,
      columnId,
      embeddedIndex
    });
  };

  const advancedBulkUpdate: AdvancedBulkUpdateFunction = updateMode => {
    let bulkUpdateValue = R.clone(selectedFieldValue);

    if (type === "link") {
      bulkUpdateValue = R.values(bulkUpdateValue.entities.chatrooms);
      bulkUpdateValue = bulkUpdateValue.map(item => ({
        ...item,
        chatroom: parseInt(item.chatroom.id, 10)
      }));
    }

    _bulkUpdateProcess({
      attrs: {
        [id]: bulkUpdateValue
      },
      sourceChatroom: parseInt(editRoomId, 10),
      options: { updateMode }
    });

    setAdvancedBulkUpdaterVisibility.off();
    handleClose();
  };

  const forwardProps = {
    fieldId: id,
    checklistId,
    roomId,
    details,
    checklistValue,
    setChecklistValue,
    setFieldValue,
    edit,
    settings,
    value: selectedFieldValue,
    handleChange: handleSelectedChange,
    openEdit,
    closeEdit,
    setValue,
    increment,
    decrement,
    formId: null,
    location: location.manageView,
    fromManageView,
    valueStatus,
    columnId
  };

  forwardProps.checklistValue = R.mergeDeepRight(forwardProps.checklistValue, {
    value: forwardProps.value
  });

  switch (type) {
    case "pdf":
    case "file":
      FieldComp = (
        <File
          roomId={roomId}
          fieldId={id}
          location={appLocation.manageView}
          fromManageView={fromManageView}
          handleClose={handleClose}
          selectedFieldValue={selectedFieldValue}
          setSelectedFieldValue={setSelectedFieldValue}
        />
      );

      break;
    case "text":
      FieldComp = (
        <Text
          roomId={roomId}
          fieldId={id}
          location={appLocation.manageView}
          fromManageView={fromManageView ?? false}
          handleClose={handleClose}
        />
      );

      break;
    case "number":
      FieldComp = (
        <Number
          roomId={roomId}
          fieldId={id}
          location={appLocation.manageView}
          fromManageView={fromManageView}
          handleClose={handleClose}
        />
      );

      break;
    case "date":
      FieldComp = (
        <Date
          roomId={roomId}
          fieldId={id}
          location={appLocation.manageView}
          fromManageView={fromManageView}
          handleClose={handleClose}
        />
      );

      break;
    case "select":
      FieldComp = (
        <Picklist
          roomId={roomId}
          fieldId={id}
          location={appLocation.manageView}
          fromManageView={fromManageView}
          handleClose={handleClose}
          selectedFieldValue={selectedFieldValue}
          setSelectedFieldValue={setSelectedFieldValue}
        />
      );

      break;
    case "user":
      FieldComp = (
        <ChecklistUsers
          roomId={roomId}
          fieldId={id}
          location={appLocation.manageView}
          fromManageView={fromManageView}
          handleClose={handleClose}
          selectedFieldValue={selectedFieldValue}
          setSelectedFieldValue={setSelectedFieldValue}
        />
      );

      break;
    case "task":
    case "decision":
    case "group":
    case "workflow":
    case "conversation":
    case "chatPickList":
      // Convert array of chatroom objects to chatroom Ids
      FieldComp = (
        <Conversation
          roomId={roomId}
          fieldId={id}
          checklistId={checklistId}
          location={appLocation.manageView}
          fromManageView={fromManageView}
          handleClose={handleClose}
          selectedFieldValue={selectedFieldValue}
          setSelectedFieldValue={setSelectedFieldValue}
        />
      );

      break;
    case "childConversation":
      // Convert array of chatroom objects to chatroom Ids
      FieldComp = (
        <Conversation
          roomId={roomId}
          fieldId={id}
          checklistId={checklistId}
          location={appLocation.manageView}
          fromManageView={fromManageView}
          handleClose={handleClose}
          selectedFieldValue={selectedFieldValue}
          setSelectedFieldValue={setSelectedFieldValue}
          parentConversation
        />
      );

      break;
    case "form":
      const value = forwardProps.value
        ? Array.isArray(forwardProps)
          ? forwardProps.value
          : [forwardProps.value]
        : [];
      const formIds = value.length > 0 ? value.map(form => form.id) : [];
      FieldComp = <Form {...{ ...forwardProps, formIds }} />;
      break;
    case "link":
      FieldComp = (
        <LinkedField
          roomId={roomId}
          fieldId={id}
          location={appLocation.manageView}
          fromManageView={fromManageView}
          handleClose={handleClose}
        />
      );

      break;
    case "richtext":
      FieldComp = (
        <RichText
          roomId={roomId}
          fieldId={id}
          location={appLocation.manageView}
          fromManageView={fromManageView ?? false}
          handleClose={handleClose}
        />
      );
      break;
    default:
      FieldComp = null;
      break;
  }

  return type === "section" ? null : (
    <>
      <Label>{label}</Label>
      <FieldWrapper>
        <Container isFromManageView>{FieldComp}</Container>

        {multiple && rowSelected && (
          <>
            {!isAdvancedBulkUpdaterOpen && (
              <Button isFullWidth onClick={setAdvancedBulkUpdaterVisibility.on}>
                {i18n.t(k.SAVE_CHANGES)}
              </Button>
            )}

            <AdvancedBulkUpdaterModal
              isOpen={isAdvancedBulkUpdaterOpen}
              onClose={setAdvancedBulkUpdaterVisibility.off}
              bulkUpdate={advancedBulkUpdate}
            />
          </>
        )}
      </FieldWrapper>
    </>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => {
  const {
    roomId: editRoomId,
    index,
    fieldId,
    embeddedIndex
  } = app.checklist?.selectedChecklist || {};
  return {
    selectedChecklist: getSelectedChecklist(app),
    valueStatus: getRoomFieldValueStatus(app, fieldId, editRoomId),
    details: getChecklistFieldDetails(app, `${fieldId}`),
    checklistValue: getChecklistValue(app, fieldId, editRoomId),
    editRoomId,
    index,
    embeddedIndex,
    rowSelected: isProcessRowSelected(app, editRoomId),
    getMututalVersionsAndCurrentVersionId: (roomId: string) =>
      getMututalVersionsAndCurrentVersionId(app, roomId)
  };
};

export default connect(mapStateToProps, {
  _setChecklistValueFromManageView: updateChecklistFromManageView,
  _bulkUpdateProcess: bulkUpdateProcess
})(Field);
