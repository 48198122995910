// @flow

import type {
  PrincipalChecklist,
  PrincipalChecklistFieldType
} from "src/types";

export type IdenticalPair<T> = [T, T];

/**
 * A tuple representing a checklist field type, its section and
 * subsection if present.
 *
 * Example:
 * [ { label: 'Name', type: 'text', id: ...}, { label: 'main section',
 * type: 'section', ... }, { label: 'nested section', type: 'subSection'
 * , ...} ]
 */
export type ChecklistFieldTuple = [
  PrincipalChecklistFieldType,
  ?PrincipalChecklistFieldType,
  ?PrincipalChecklistFieldType
];

/**
 * Represents a single row of a dropdown element with n number of cells
 * in it. A cell is an identical pair of strings with the first string
 * being the content and the second being its metadata.
 *
 * @typedef {Object} UnifizeDropdownRow
 * @property {Array<[?string, ?string]>} cells - The cells of the
 * dropdown element ("row") as a [text, metadata] pair
 * @property {boolean?} static - Optionally removes dynamic styling from
 * the element if true
 */
export type UnifizeDropdownRow = {|
  cells: Array<IdenticalPair<?string>>,
  id: string,
  static?: boolean
|};

/**
 * Maps a single ChecklistFieldTuple into a unifize dropdown row object
 * with the relevant placement for sections, subsections and their icons
 * @param {[PrincipalChecklistFieldType,?PrincipalChecklistFieldType ?PrincipalChecklistFieldType]} sectionMappedField -
 * A tupe containing the checklist field, its section and subsection
 * @return {UnifizeDropdownRow} - A dropdown element object with the
 * cells corresponding to the given section-mapped checklist field
 */
const mapFieldIntoRow = (
  sectionMappedField: ChecklistFieldTuple
): UnifizeDropdownRow =>
  sectionMappedField[1]
    ? {
        cells: [
          [sectionMappedField[0].label, ""],
          [
            sectionMappedField[1] ? sectionMappedField[1]?.label : null,
            sectionMappedField[1] ? "fadedSection" : ""
          ],
          [
            sectionMappedField[2] ? sectionMappedField[2]?.label : null,
            sectionMappedField[2] ? "fadedSubSection" : ""
          ]
        ],
        id: String(sectionMappedField[0].id)
      }
    : {
        cells: [[sectionMappedField[0].label, ""]],
        id: String(sectionMappedField[0].id)
      };

/**
 * Maps checklist fields per their sections
 *
 * @return {[PrincipalChecklistFieldType, ?PrincipalChecklistFieldType ]}
 * A tuple with the first element being the field followed by its
 * section string or null
 */
const mapFieldsBySection = (
  fields: $PropertyType<PrincipalChecklist, "fields">
): Array<ChecklistFieldTuple> => {
  let mappedFields = [];
  let currentSection = null,
    currentSubsection = null;
  for (const field of fields) {
    if (field.type === "section") {
      currentSection = field;
      currentSubsection = null;
    } else if (field.type === "subSection") {
      currentSubsection = field;
    } else {
      mappedFields.push([field, currentSection, currentSubsection]);
    }
  }
  return mappedFields;
};

export { mapFieldIntoRow, mapFieldsBySection };
