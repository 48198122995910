// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";

import Common from "../Common";
import Name from "src/components/Form/Name";
import type { Message } from "src/types";

type Props = {
  message: Message
};

const AddForm = ({ message }: Props) => {
  const templateId = message?.data?.templateId;

  return (
    <Common {...message}>
      {" "}
      {i18n.t(k.REMOVED_A_FORM)}{" "}
      <strong>
        <Name id={templateId} />
      </strong>{" "}
      {i18n.t(k.FROM_THE_CHECKLIST1)}
    </Common>
  );
};

export default AddForm;
