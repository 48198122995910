// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";

import MultiSelect from "./MultiSelect";
import User from "src/containers/user/NameOrEmail";

type Props = {
  value: Array<number>,
  multiple: boolean
};

const Conversation = ({ value, multiple }: Props) => {
  if ((value || []).length === 0) {
    return (
      <div>
        <i>{i18n.t(k.NO_VALUE)}</i>
      </div>
    );
  }

  if (multiple && value) {
    return <MultiSelect value={value} />;
  }

  return (
    <div>
      <User uid={value[0]} showPending={false} />{" "}
    </div>
  );
};

export default Conversation;
