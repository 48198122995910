// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";

import { Lock as StyledLock } from "./styles";

type Props = {
  locked: boolean,
  handleLock: Function
};

const Lock = ({ locked, handleLock }: Props) => (
  <StyledLock
    locked={locked}
    tabIndex="0"
    role="button"
    onClick={handleLock}
    onKeyPress={handleLock}
  >
    <h5>{i18n.t(k.LOCK_GROUP)}</h5>
    <div>{i18n.t(k.ONLY_THE_GROUP_OWNER_CAN_MAKE)}</div>
  </StyledLock>
);

export default Lock;
