// @flow

import React from "react";
import { useSelector } from "react-redux";
import { SortableContainer } from "react-sortable-hoc";

import FieldSelect from "./FieldSelect";
import { Label, FormGroupSmall, ErrorMessage } from "../styles";
import { AddField } from "./styles";
import Icon from "src/icons";
import SelectProcess from "src/containers/workflow/select";
import { VStack } from "@chakra-ui/react";
import { checkIfProcessDeleted } from "src/utils/workflow";
import { PROCESS_DELETED } from "src/constants/errorMessage";

import { getWorkflow } from "src/reducers";

const FieldList = SortableContainer(({ children }) => {
  return <VStack alignItems="start">{children}</VStack>;
});

type Props = {
  workflow: number | null,
  fields: Array<number>,
  handleTemplate: Function,
  addField: Function,
  handleField: Function,
  removeField: Function,
  onSortFields: Function,
  checklistFields: Array<Object>
};

const Workflow = ({
  workflow,
  fields,
  handleTemplate,
  addField,
  handleField,
  removeField,
  onSortFields,
  checklistFields = []
}: Props) => {
  const processDetails = useSelector(({ app }) =>
    getWorkflow(app, `${workflow || ""}`)
  );

  const isProcessDeleted = checkIfProcessDeleted(processDetails, workflow);

  return (
    <>
      <FormGroupSmall>
        <Label>Process</Label>
        <SelectProcess workflowId={workflow} handleChange={handleTemplate} />
        {isProcessDeleted && <ErrorMessage>{PROCESS_DELETED}</ErrorMessage>}
      </FormGroupSmall>

      <div style={{ width: "100%" }}>
        <Label>Embedded Fields</Label>
        <FieldList useDragHandle onSortEnd={onSortFields}>
          {!isProcessDeleted &&
            fields.map((field: ?number, index) => (
              <FieldSelect
                index={index}
                itemIndex={index}
                key={`item-${field ?? -1}-${index}`}
                handleField={handleField}
                removeField={removeField}
                addField={addField}
                fields={fields}
                value={field}
                checklistFields={checklistFields}
              />
            ))}
        </FieldList>
      </div>

      <AddField
        data-cy="addEmbeddedFieldButton"
        type="button"
        disabled={
          !workflow ||
          isProcessDeleted ||
          fields[fields.length - 1] === null ||
          fields.indexOf(null) !== -1
        }
        onClick={addField}
      >
        <Icon type="hollowPlus" />
        <span>Add Embedded Field</span>
      </AddField>
    </>
  );
};

export default Workflow;
