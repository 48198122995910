// @flow

import React from "react";

import SidebarItem from "./SidebarItem.js";

import { SidebarContainer } from "./styles";

type Props = {
  activeIndex: number,
  changeActiveIndex: (index: number) => void,
  preferences: Array<{ icon: string, label: string }>
};

const Sidebar = ({ activeIndex, changeActiveIndex, preferences }: Props) => {
  return (
    <SidebarContainer>
      {preferences.map((preference, index) => (
        <SidebarItem
          key={index}
          label={preference.label}
          icon={preference.icon}
          isActive={activeIndex === index}
          handleClick={() => changeActiveIndex(index)}
        />
      ))}
    </SidebarContainer>
  );
};

export default Sidebar;
