import styled from "styled-components";
import * as colors from "src/styles/constants/colors";

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Flex = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 0;
  overflow: hidden;
`;

export const HeaderContainer = styled.div`
  display: flex;
  padding: 1.5rem 1.5rem;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${colors.grey2};
`;

export const SupportedLanguagesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  cursor: pointer;
  color: ${colors.blue700};
`;

export const MenuBar = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem;
`;

export const TableBody = styled.div`
  height: 100%;
  margin: 1.5rem 1.5rem 0 1.5rem;
  position: relative;
  overflow: auto;
`;

export const StyledHeader = styled.div`
  width: 100%;
  height: 100%:
  flex: 1;
  flex-grow: 1;
  font-size: 12px;
  text-transform: none;
  font-weight: 700;
  border-right: 1px solid ${colors.grey41};
  display: flex;
  align-items: center;

  /* Don't add right border on the last column header */
  &:last-child {
    border: none;
  }

  > span {
    margin: 0.25rem 0.5rem;
  }
`;

export const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const HeaderRow = styled(StyledRow)`
  background: ${colors.skyLighter};
  border: 1px solid ${colors.grey41};
  position: fixed;
  height: 24px;
  align-items: center;
  z-index: 1;
`;

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 1.5rem;
  padding: 0.75rem;
  gap: 0.75rem;
  align-items: center;
  background: ${colors.textInfoSubtle};

  > div {
    max-width: fit-content;
  }
`;

export const ClearAllButton = styled.button`
  padding: 0.25rem 0.25rem 0.25rem 0.325rem;
  display: flex;
  height: max-content;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: 1px solid ${colors.active};
  border-radius: 0.25rem;
  gap: 0.25rem;
  cursor: pointer;
  > span {
    font-size: 12px;
    color: ${colors.active};
    line-height: 16px;
  }
`;

export const TranslationEditInput = styled.textarea`
  resize: vertical;
  flex: 1;
  width: 100%;
  margin: 0;
  padding: 0.5rem;
  border: none;
  outline: none;
  border-bottom: 1px solid ${colors.grey41};
  line-height: 16px;
  min-height: 36px;
`;

export const StyledCell = styled.div`
  flex: 1;
  flex-shrink: 0;
  flex-grow: 1;
  width: 100%;
  margin: 0;
  padding: 0.5rem;
  line-height: 16px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
  overflow: hidden;
  max-height: ${props =>
    !props.seeMore || !props.isReadOnly ? "none" : "65px"};
  border-bottom: 1px solid ${colors.grey41};

  :first-child {
    border-right: 1px solid ${colors.grey41};
    border-left: 1px solid ${colors.grey41};
  }

  :last-child {
    border-left: 1px solid ${colors.grey41};
    border-right: 1px solid ${colors.grey41};
  }
`;

export const PathContainer = styled(StyledCell)`
  display: flex;
  align-items: center;
  overflow: hidden;
`;

export const PathLabel = styled.span`
  color: ${colors.grey42};
  font-size: 12px;
  font-style: ${props => (props.lastElement ? "italics" : "normal")};
  font-weight: 400;
  line-height: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const TranslationCellWrapper = styled.div`
  position: relative;

  > span {
    display: ${props => (props.seeMore ? "block" : "inline-block")};
    overflow: hidden;
    max-height: ${props => (props.seeMore ? "48px" : "none")};
  }

  > div > svg {
    height: 16px;
    width: 16px;
    right: 0px;
    position: absolute;
    display: none;
    cursor: pointer;
  }

  &:hover {
    > div > svg {
      display: block;
    }
  }
`;

export const SeeMore = styled.span`
  background: white;
  cursor: pointer;
  position: absolute;
  bottom: ${props => (!props.seeMore ? "4px" : 0)};
  right: 0;
  color: ${colors.grey42};
  font-weight: bold;
`;

export const Footer = styled.div`
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`;

export const PageButton = styled.button`
  border: none;
  border-radius: 0.125rem;
  padding: 0.25rem 0.5rem;
  pointer: ${props => (props.disabled ? "not-allowed" : "cursor")};
`;

export const PageIndicatorContainer = styled.span`
  display: flex;
  align-items: center;
  gap: 0.25rem;
`;

export const PageInput = styled.input`
  border: 1px solid black;
  padding: 0.25rem 0.5rem;
  border-radius: 0.125rem;
  width: 4rem;
`;

export const NoResultsFound = styled.div`
  padding-top: 75px;
  width: 100%;
  display: flex;
  justify-content: center;

  > span {
    color: ${colors.grey42};
    font-size: 31px;
    font-weight: 699;
    line-height: 47px;
  }
`;

export const ShimmerLoader = styled.div`
  flex: 1;
  flex-shrink: 0;
  flex-grow: 1;
  margin: 0.5rem;
  height: 21px;
  background: linear-gradient(
    88deg,
    ${colors.grey41} 25%,
    ${colors.grey40} 50%,
    ${colors.grey41} 75%
  );
  background-size: 198% 100%;
  animation: shimmer 1.5s infinite;
  margin: 9px 0;
  border-radius: 3px;

  @keyframes shimmer {
    0% {
      background-position: 200% 0;
    }
    100% {
      background-position: 0 0;
    }
  }
`;

export const filterToggleButtonStyles = {
  width: "fit-content",
  maxWidth: "fit-content",
  minWidth: "120px",
  padding: "0.25rem 0.5rem",
  borderRadius: "0.25rem",
  border: `1px solid ${colors.grey41}`
};

export const headerStyle = {
  background: colors.skyLighter,
  border: `1px solid ${colors.grey41}`,
  flexGrow: 1,
  width: 200,
  margin: 0,
  overflow: "visible"
};

export const cellStyle = {
  width: "100%",
  flexGrow: 1,
  border: `1px solid ${colors.grey41}`,
  margin: 0,
  padding: "-1.5rem"
};

export const searchInput = {
  width: "100%",
  flexGrow: 1,
  border: `1px solid ${colors.grey40}`,
  backgroundColor: colors.grey43,
  padding: "0.5rem",
  outline: "none",
  borderRadius: "0.25rem",
  lineHeight: "20px",
  fontSize: "14px",
  maxWidth: "633px"
};

export const SpinnerContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  > div {
    height: auto;
  }
`;
