// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";
import type { ApprovalFieldSettingsV2, UID } from "src/types";

import { useBoolean } from "@chakra-ui/react";
import { AddButton } from "src/components/Dock/Checklist/styles";
import ButtonLoader from "src/components/Dock/Checklist/Conversation/Loader";
import AdhocModal from "./AdHocModal";

type Props = {|
  settings: ApprovalFieldSettingsV2,
  setFieldValue: Function,
  disabled: boolean,
  updating: boolean,
  signatories: UID[]
|};

export default function RequestApprovalButton({
  setFieldValue,
  settings,
  disabled,
  updating,
  signatories
}: Props) {
  const [isAdhocModalOpen, setAdhocModalVisibility] = useBoolean();

  const handleRequestApproval = () => {
    if (settings.requiredApprovers !== "adhoc") {
      setFieldValue({
        value: {
          requestApproval: true
        }
      });
    } else {
      setAdhocModalVisibility.on();
    }
  };

  const handleSaveAdhocConfig = config => {
    setFieldValue({
      value: {
        requestApproval: true,
        config
      }
    });
    setAdhocModalVisibility.off();
  };

  return (
    <>
      {updating ? (
        <ButtonLoader />
      ) : (
        <AddButton onClick={handleRequestApproval} disabled={disabled}>
          {i18n.t(k.REQUEST_APPROVAL)}
        </AddButton>
      )}

      <AdhocModal
        isOpen={isAdhocModalOpen}
        onClose={setAdhocModalVisibility.off}
        settings={settings}
        saveConfig={handleSaveAdhocConfig}
        signatories={signatories}
      />
    </>
  );
}
