// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";

import Common from "./Common";
import NameOrEmail from "src/containers/user/NameOrEmail";
import type { Message } from "src/types";

type Props = {
  message: Message
};

const Owner = ({ message }: Props) => {
  const { data } = message;
  const { new: newOwner, old: oldOwner } = data;
  if (newOwner && oldOwner)
    return (
      <Common {...message}>
        {" "}
        {i18n.t(k.CHANGED_THE_OWNER_FROM)}{" "}
        <strong>
          <NameOrEmail uid={oldOwner} showPending={false} />
        </strong>{" "}
        {i18n.t(k.TO)}{" "}
        <strong>
          <NameOrEmail uid={newOwner} showPending={false} />
        </strong>
      </Common>
    );

  if (newOwner)
    return (
      <Common {...message}>
        {" "}
        {i18n.t(k.ASSIGNED)}{" "}
        <strong>
          <NameOrEmail uid={newOwner} />
        </strong>{" "}
        {i18n.t(k.AS_THE_OWNER)}
      </Common>
    );

  return (
    <Common {...message}>
      {" "}
      {i18n.t(k.REMOVED)}{" "}
      <strong>
        <NameOrEmail uid={oldOwner} />
      </strong>{" "}
      {i18n.t(k.AS_THE_OWNER)}
    </Common>
  );
};

export default Owner;
