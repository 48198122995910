// @flow
import i18n from "i18next";
import k from "src/i18n/keys";
import React, { useState, useCallback, useRef, useEffect } from "react";
import ReactProgressiveList from "react-progressive-list";
import * as R from "ramda";

import { getAllUsersAndGroupsSearchResults } from "src/reducers";
import { useSelector } from "react-redux";
import { useDebounce } from "use-debounce";
import Dropdown from "src/components/Dropdown";
import Downshift from "downshift";
import useBoolean from "src/hooks/useBoolean";
import { validateEmail, onManageView } from "src/utils";

import { Text, VStack, Input, Button, Box } from "@chakra-ui/react";

import type { DownshiftProps } from "downshift";
import type { UsersAndGroups, UID, Email, GroupId } from "src/types";

import NewUser from "./NewUser";
import NewGroup from "./NewGroup";
import Selection from "./Selection";

import * as styles from "./styles";

type Member = UID | Email;

type Props = {
  members: Array<Member>,
  groups?: Array<GroupId>,
  handleMembers: (UID | Email) => void,
  handleGroups?: GroupId => void,
  dropdownPosition?: string,
  hideEmailInvite?: boolean
};

export type HandleRemove = (args: { id: UID | GroupId | Email }) => void;

const SelectInviteMultiple = ({
  members,
  groups = [],
  handleMembers,
  handleGroups,
  dropdownPosition = "relative",
  hideEmailInvite = false
}: Props) => {
  const inputRef = useRef(null);
  const selectionRef = useRef(null);
  const isInitialRender = useRef(true);
  const prevMembers = useRef(members);
  const prevGroups = useRef(groups);
  const downshiftRef = useRef<DownshiftProps>(null);
  const [query, setQuery] = useState<string>("");

  const scrollToSelected = (type: string) => {
    const container = selectionRef.current;
    if (container) {
      const hasOverflow = container.scrollHeight > container.clientHeight;
      const lastIndex =
        type === "group" ? groups.length - 1 : members.length - 1;
      const name = `${type}-${lastIndex}`;
      const element = container.querySelector(`span[name="${name}"]`);
      if (element && hasOverflow) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "nearest"
        });
      }
    }
  };

  const {
    value: isOpen,
    setTrue: toggleDropdown,
    setFalse: closeDropdown
  } = useBoolean();

  const [debouncedSearch]: [string] = useDebounce<string>(query, 100);

  const searchedFilteredResults: [UsersAndGroups] = useSelector(({ app }) =>
    getAllUsersAndGroupsSearchResults(app, debouncedSearch)
  );

  const filteredResults = searchedFilteredResults.filter(
    ({ id }) => groups && !(members.includes(id) || groups.includes(id))
  );

  const handleSelect = useCallback(
    ({ id }) => {
      if (typeof id === "string") handleMembers(id);
      else if (typeof id === "number") handleGroups && handleGroups(id);
      else if (typeof id === "object") id && handleMembers(id); // This for email
      setQuery("");
    },
    [handleMembers, handleGroups, setQuery]
  );

  const unSelectItem = useCallback(
    ({ id }) => {
      handleSelect({ id });
      setQuery("");
    },
    [handleSelect, setQuery]
  );

  useEffect(() => {
    if (!isInitialRender.current) {
      if (members !== prevMembers.current) {
        scrollToSelected("member");
      } else if (groups !== prevGroups.current) {
        scrollToSelected("group");
      }
    }
    isInitialRender.current = false;
    prevMembers.current = members;
    prevGroups.current = groups;
  }, [members, groups]);

  const handleInvite = useCallback(
    (email: string) => {
      handleMembers({ email });
      setQuery("");
    },
    [handleMembers, setQuery]
  );

  const handleKeyboard = useCallback(
    e => {
      if (e.key === "ArrowDown") {
        e.preventDefault();
        if (downshiftRef.current != null) {
          downshiftRef.current.internalSetState({
            itemsLength: filteredResults.length,
            type: Downshift.stateChangeTypes.keyDownArrowDown
          });
        }
      } else if (e.key === "ArrowUp") {
        e.preventDefault();
        if (downshiftRef.current != null) {
          downshiftRef.current.internalSetState({
            itemsLength: filteredResults.length,
            type: Downshift.stateChangeTypes.keyDownArrowUp
          });
        }
      } else if (e.key === "Enter") {
        if (isOpen) {
          e.preventDefault();
          if (downshiftRef.current != null) {
            const highlightedIndex =
              downshiftRef.current.state.highlightedIndex;
            handleSelect({ id: filteredResults[highlightedIndex].id });

            downshiftRef.current.internalSetState({
              highlightedIndex: highlightedIndex,
              type: Downshift.stateChangeTypes.keyDownEnter
            });
          } else if (validateEmail(query)) {
            !hideEmailInvite && handleInvite(query);
          }
        }
      } else if (e.key === "Backspace") {
        if (groups && groups.length > 0 && query.length === 0)
          unSelectItem({ id: R.last(groups) });
        else if (members.length > 0 && query.length === 0)
          unSelectItem({ id: R.last(members) });
      } else if (e.key === "Escape") closeDropdown();
      else if (e.key === "Tab") closeDropdown();
      else {
        toggleDropdown();
      }
    },
    [handleSelect, filteredResults, handleInvite, unSelectItem]
  );

  const onQueryChange = event => {
    setQuery(event.target.value);
  };

  return (
    <Box>
      <VStack
        sx={styles.SelectionContainer}
        spacing="0"
        onClick={() => {
          toggleDropdown();
          if (inputRef.current) inputRef.current.focus();
        }}
      >
        <Selection
          members={members}
          groups={groups}
          handleRemove={unSelectItem}
          selectionRef={selectionRef}
        />

        <Input
          sx={styles.InputField}
          ref={inputRef}
          variant="flushed"
          size="sm"
          value={query}
          onChange={event => onQueryChange(event)}
          onKeyDown={e => handleKeyboard(e)}
          placeholder={
            onManageView()
              ? "Search People or Groups"
              : i18n.t(k.SEARCH_PEOPLE_OR_GROUPS)
          }
          autoFocus={isOpen ? true : false}
          onFocus={toggleDropdown}
        />
      </VStack>

      {filteredResults.length > 0 ? (
        <Dropdown
          onItemClick={handleSelect}
          isOpen={isOpen}
          onOuterClick={closeDropdown}
          ref={downshiftRef}
          inputFieldSize="sm"
          position={dropdownPosition}
        >
          {({
            onItemClick,
            getItemProps,
            highlightedIndex,
            scrollIntoView
          }) => {
            return (
              <VStack spacing="0" sx={styles.DropdownList}>
                <ReactProgressiveList
                  initialAmount={10}
                  progressiveAmount={10}
                  role="list"
                  rowCount={filteredResults.length}
                  renderItem={index => {
                    if (filteredResults[index]) {
                      const { id, type } = filteredResults[index];
                      if (type === "user") {
                        return (
                          <NewUser
                            key={`${i18n.t(k.USER)}${id}`}
                            index={index}
                            highlightedIndex={highlightedIndex}
                            scrollIntoView={scrollIntoView}
                            {...getItemProps({
                              item: id,
                              id: id,
                              onItemClick: onItemClick
                            })}
                          />
                        );
                      } else if (type === "group") {
                        return (
                          <NewGroup
                            item={filteredResults[index]}
                            key={`${i18n.t(k.GROUP)}${id}`}
                            index={index}
                            highlightedIndex={highlightedIndex}
                            scrollIntoView={scrollIntoView}
                            {...getItemProps({
                              item: id,
                              id: id,
                              onItemClick: onItemClick
                            })}
                          />
                        );
                      }
                    }
                  }}
                />
              </VStack>
            );
          }}
        </Dropdown>
      ) : validateEmail(query) && !hideEmailInvite ? (
        <Button
          sx={styles.EmailInviteButton}
          variant="outline"
          onClick={() => handleInvite(query)}
        >
          {i18n.t(k.INVITE1)} {`${query}`} {i18n.t(k.VIA_EMAIL)}
        </Button>
      ) : query.length !== 0 ? (
        <Text sx={styles.ErrorMessage}>
          {i18n.t(k.NO_RESULTS_FOR)} {query}
        </Text>
      ) : null}
    </Box>
  );
};

export default SelectInviteMultiple;
