// @flow

import React from "react";
import { connect } from "react-redux";

import ProfileModal from "src/containers/filter/ProfileModal";
import NewOrgModal from "src/components/filter/Org/NewOrgModal";
import CloneOrgModal from "src/components/filter/Org/CloneOrgModal";
import ChangePassword from "src/components/filter/Profile/ChangePassword";
import AboutModal from "src/components/filter/Profile/AboutModal";
import {
  getCurrentUser,
  getCloneOrgModalStatus,
  getOpenModalName
} from "src/reducers";
import Preferences from "src/components/filter/Profile/Preferences";
import { modalTypes } from "src/constants";

import type { AppState, UnifizeUser } from "src/types";

type Props = {
  currentUser: UnifizeUser,
  closeMenu: Function,
  openModalName: ?boolean
};

const Modal = ({ currentUser, closeMenu, openModalName = null }: Props) => {
  switch (openModalName) {
    case modalTypes.displayName:
      return <ProfileModal currentUser={currentUser} closeMenu={closeMenu} />;
    case modalTypes.createOrg:
      return <NewOrgModal closeMenu={closeMenu} />;
    case modalTypes.cloneOrg:
      return <CloneOrgModal />;
    case modalTypes.changePassword:
      return <ChangePassword closeMenu={closeMenu} />;
    case modalTypes.preferences:
      return <Preferences />;
    default:
      return <AboutModal />;
  }
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  currentUser: getCurrentUser(app),
  openModalName: getOpenModalName(app)
});

export default connect(mapStateToProps)(Modal);
