// @flow

import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import Checkbox from "src/components/Checkbox";
import {
  selectAllProcessRows,
  clearProcessRowSelection
} from "src/actions/workflows";

import {
  getWorkflowSelectedRows,
  getCurrentUserId,
  getRole
} from "src/reducers";
import * as colors from "src/styles/constants/colors";
import { StyledCheckboxWrapper } from "./Row/styles";
import { Selector } from "./styles";

type Props = {
  getRowModel: Function
};

const SelectAll = ({ getRowModel }: Props) => {
  const dispatch = useDispatch();
  const [isSelected, setIsSelected] = useState(false);
  const selectedRows = useSelector(({ app }) => getWorkflowSelectedRows(app));

  const currentUserId = useSelector(({ app }) => getCurrentUserId(app));
  const currentUserRole = useSelector(({ app }) => getRole(app, currentUserId));

  const isAdmin = useMemo(
    () => currentUserRole.includes("admin"),
    [currentUserId, currentUserRole]
  );

  useEffect(() => {
    if (selectedRows.length === 0) setIsSelected(false);
  }, [selectedRows]);

  const handleSelect = useCallback(
    (e: MouseEvent) => {
      const rows = getRowModel().rows;
      const allRooms = rows.map(row => row.original.chatroomId);

      e.stopPropagation();

      setIsSelected(prev => !prev);
      if (!isSelected) {
        // Select all the rows
        dispatch(selectAllProcessRows(allRooms || []));
      } else {
        // Clear all selection
        dispatch(clearProcessRowSelection());
      }
    },
    [getRowModel, isSelected]
  );

  return (
    <Selector
      selected={isSelected}
      onClick={handleSelect}
      style={{
        backgroundColor: colors.grey20,
        cursor: isAdmin ? "pointer" : "default"
      }}
    >
      {isAdmin && (
        <StyledCheckboxWrapper>
          <Checkbox
            id="selectAll"
            checked={isSelected}
            handleChange={handleSelect}
          />
        </StyledCheckboxWrapper>
      )}
    </Selector>
  );
};

export default SelectAll;
