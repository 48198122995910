// @flow

import React from "react";

import AddUserSegment from "./AddUserSegment";

type Props = {
  tileId: number,
  color: string
};

const UserSegments = ({ tileId, color }: Props) => (
  <AddUserSegment color={color} tileId={tileId} />
);

export default UserSegments;
