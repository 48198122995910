// @flow

import i18n from "i18next";
import k from "src/i18n/keys";

import React, { useCallback, lazy, Suspense, useEffect } from "react";
import { connect } from "react-redux";
import * as R from "ramda";

import type { EmailModalStages } from "src/types";
import { getCurrentUserId, getRole } from "src/reducers";
import { showConversationalModalWarn } from "src/actions/chatroom";
import { clearEmailData, clearAllTempAttachments } from "src/actions/email";
import * as emailModalStages from "src/constants/emailModalStages";
import { setEmailModalStage } from "src/actions/modal";

import Icon from "src/components/Icon";
import Tooltip from "src/components/Tooltip";
const EmailModal = lazy(() => import("src/components/chatroom/Email"));
const RecipientsInvitationModal = lazy(
  () => import("src/containers/chatroom/EmailRecipientsInvitationModal")
);

import { MailIcon } from "./styles";
import { iconSize } from "src/styles/constants";

import type { AppState } from "src/types";

type Props = {
  role: string,
  showConversationalModal: boolean,
  emailModalStage: EmailModalStages,
  writeEmail: Function,
  clearEmailData: Function,
  clearAllTempAttachments: Function,
  showConversationalModalWarn: Function
};

const renderModal = (modalStage: EmailModalStages) => {
  switch (modalStage) {
    case null:
      return <></>;

    case emailModalStages.INVITE:
      return (
        <Suspense fallback={<></>}>
          <RecipientsInvitationModal />
        </Suspense>
      );

    case emailModalStages.WRITE:
    default:
      return (
        <Suspense fallback={<></>}>
          <EmailModal />
        </Suspense>
      );
  }
};

const Mail = ({
  role,
  showConversationalModal,
  emailModalStage,
  writeEmail,
  clearEmailData,
  clearAllTempAttachments,
  showConversationalModalWarn
}: Props) => {
  const openEmailModal = useCallback(() => {
    if (showConversationalModal) {
      showConversationalModalWarn();
    } else {
      writeEmail();
    }
  }, [writeEmail]);

  useEffect(() => {
    if (R.isNil(emailModalStage)) {
      clearAllTempAttachments();
      clearEmailData();
    }
  }, [emailModalStage]);

  if (role === "demo") {
    return <></>;
  }

  return (
    <>
      <MailIcon role="button" onClick={openEmailModal}>
        <Tooltip title={i18n.t(k.SEND_AS_EMAIL)}>
          <Icon icon="mail" size={iconSize.big} />
        </Tooltip>
      </MailIcon>
      {renderModal(emailModalStage)}
    </>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  showConversationalModal: app.conversationModal.show,
  role: getRole(app, getCurrentUserId(app)),
  emailModalStage: app.email.stage
});

const mapDispatchToProps = {
  writeEmail: () => setEmailModalStage(emailModalStages.WRITE),
  showConversationalModalWarn,
  clearEmailData,
  clearAllTempAttachments
};

export default connect(mapStateToProps, mapDispatchToProps)(Mail);
