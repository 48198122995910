// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";
import { Input, HStack, Button, Spinner } from "@chakra-ui/react";
import NewFolder from "src/icons/NewFolder";
import { folderInput, folderButton } from "./styles";

type Props = {
  value: string,
  onClick: Function,
  isLoading: boolean
};

const FolderInput = ({ value, onClick, isLoading }: Props) => {
  const viewFolder = () => {
    window.open(value);
  };

  return (
    <HStack spacing={0} mb={2}>
      <Input
        disabled
        value={value}
        placeholder="Select folder path"
        sx={folderInput}
      />

      <Button
        variant="uPrimary"
        onClick={value ? viewFolder : onClick}
        leftIcon={!value && !isLoading ? <NewFolder /> : null}
        iconSpacing={1}
        sx={folderButton}
        isDisabled={isLoading}
      >
        {isLoading ? (
          <Spinner color="gray.100" size="sm" />
        ) : value ? (
          i18n.t(k.VIEW)
        ) : (
          i18n.t(k.FOLDER)
        )}
      </Button>
    </HStack>
  );
};

export default FolderInput;
