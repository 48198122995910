// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import type { State, UnifizeChatRoom } from "src/types";
import {
  getProcessTitle,
  getMututalVersionsAndCurrentVersionId
} from "src/reducers";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  IconButton
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";

import Viewer from "./Viewer";
import Editor from "./Editor";
import * as styles from "../styles";

type Props = {
  roomId: number,
  autoLink: boolean,
  defaultEdit: boolean,
  versions: UnifizeChatRoom[],
  processTitle: string,
  currentVersionId: number,
  open: boolean,
  selectChatroom: Function,
  onClose: Function
};

const SingleModal = ({
  roomId,
  autoLink,
  defaultEdit,
  versions,
  processTitle,
  currentVersionId,
  selectChatroom,
  onClose
}: Props) => {
  const [viewMode, setViewMode] = useState(!defaultEdit);
  const [newVersion, setNewVersion] = useState(null);

  useEffect(() => {
    if (viewMode) {
      setNewVersion(autoLink ? `${currentVersionId}` : `${roomId}`);
    } else {
      setNewVersion(autoLink ? "auto" : `${roomId}`);
    }
  }, [viewMode]);

  const selectNewVersion = () => {
    const auto = newVersion === "auto";

    let payload = {
      chatroom: auto ? currentVersionId : Number(newVersion)
    };

    if (auto) payload = { ...payload, linkAllVersions: true };

    selectChatroom(payload);
    onClose();
  };

  const enterEditMode = () => setViewMode(false);

  const handleClose = () => {
    if (defaultEdit || viewMode) {
      onClose();
    } else {
      setViewMode(true);
    }
  };

  return (
    <Modal isOpen={open} {...styles.modal}>
      <ModalOverlay />

      <ModalContent {...styles.modalContent}>
        <ModalHeader {...styles.modalHeader}>
          {viewMode ? i18n.t(k.ALL_REVISIONS) : i18n.t(k.SELECT_REVISION)}

          <IconButton
            {...styles.closeIconButton}
            icon={<CloseIcon {...styles.closeIcon} />}
            onClick={onClose}
          />
        </ModalHeader>

        {viewMode ? (
          <Viewer
            autoLink={autoLink}
            versions={versions}
            selectedVersion={newVersion}
            processTitle={processTitle}
            onEnterEditMode={enterEditMode}
          />
        ) : (
          <Editor
            newVersion={newVersion}
            setNewVersion={setNewVersion}
            versions={versions}
            processTitle={processTitle}
            selectNewVersion={selectNewVersion}
            handleClose={handleClose}
          />
        )}
      </ModalContent>
    </Modal>
  );
};

const mapStateToProps = ({ app }: State, { roomId }: Props) => {
  const processTitle = getProcessTitle(app, `${roomId}`);

  const { versions, currentVersionId } = getMututalVersionsAndCurrentVersionId(
    app,
    `${roomId}`
  );

  return {
    versions,
    processTitle,
    currentVersionId
  };
};

export default connect(mapStateToProps)(SingleModal);
