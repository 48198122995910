// @flow

import React from "react";
import { SortableHandle } from "react-sortable-hoc";
import moment from "moment";
import * as R from "ramda";
import { Tr, Td, IconButton, Tag, TagLabel, Input } from "@chakra-ui/react";

import type { DeletedChecklistField } from "src/types";
import NameOrEmail from "src/containers/user/NameOrEmail";
import Icon from "src/icons";
import OldIcon from "src/components/InlineSVG";
import * as colors from "src/styles/constants/colors";
import { fieldTagStyles } from "./styles";
import { checklistFieldNamesEn as checklistFieldNames } from "src/constants/display";
import { checklistFieldMeta } from "src/components/Manage/Builder/display";

type Props = {
  index: number,
  position: number,
  value: DeletedChecklistField,
  updateField: Function,
  restoreField: Function
};

const DragHandle = SortableHandle(() => (
  <IconButton
    display="flex"
    variant="unstyled"
    aria-label="Drag Handle"
    icon={<Icon type="dragIndicator" />}
  />
));

const Field = ({ value, position, updateField, restoreField }: Props) => {
  const { label, type, deletedBy, deletedAt } = value;

  return (
    <Tr>
      <Td>
        <IconButton
          display="flex"
          opacity={0}
          variant="unstyled"
          aria-label="Add new field"
          onClick={() => {}}
          icon={<OldIcon icon="hollowPlusCircle" color={colors.blue500} />}
        />
      </Td>
      <Td>
        <DragHandle />
      </Td>
      <Td>{position + 1}</Td>
      <Td>
        <Tag
          size="md"
          sx={fieldTagStyles}
          variant="solid"
          bgColor={checklistFieldMeta[type]?.[0] || "cyan.300"}
        >
          <Icon
            type={checklistFieldMeta[type]?.[1] || "gear"}
            width="16px"
            height="16px"
            fill="#fff"
          />

          <TagLabel ml="4px">{checklistFieldNames[type]}</TagLabel>
        </Tag>
      </Td>
      <Td>
        <Input
          type="text"
          value={label}
          style={{ width: "495px", height: "32px", borderRadius: "4px" }}
          placeholder="Field Name"
          onChange={e => updateField(e.target.value, position, "label")}
          required
        />
      </Td>
      <Td>
        <IconButton
          display="flex"
          variant="unstyled"
          aria-label="Restore Field"
          onClick={e => restoreField(e, position)}
          icon={<Icon type="restore" />}
        />
      </Td>
      <Td>{!R.isNil(deletedBy) && <NameOrEmail uid={deletedBy} />}</Td>
      <Td>
        {!R.isNil(deletedAt) &&
          moment(deletedAt).format("MMMM Do YYYY, h:mm:ss a")}
      </Td>
    </Tr>
  );
};

export default Field;
