// @flow

import styled from "@emotion/styled";

import * as colors from "src/styles/constants/colors";
import { ButtonOutline } from "src/styles/buttons";

export const SubHeading = styled.h4`
  text-transform: uppercase;
  margin: 0;
  font-size: 12px;
  color: ${colors.inkLightest};
  font-weight: bold;
  padding: 2px 8px;
  cursor: text;
`;

export const Update = styled.div`
  background-color: ${colors.mildRed};
  padding: 0.8em;
  color: ${colors.orangeRed};
  margin-bottom: 10px;
  ${ButtonOutline} {
    height: 28px;
    display: block;
    padding: 0.6em;
    width: 99%;
    margin: auto;
    margin-top: 0.4em;
    font-size: 9.5px;
    border: none;
    color: ${colors.primary};
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
    > span {
      padding-left: 0.5em;
      position: relative;
      top: 1px;
    }
    :hover,
    :focus {
      color: ${colors.brightLight};
      background-color: ${colors.active};
      border-color: ${colors.active};
      > svg > g > path {
        fill: ${colors.brightLight};
      }
    }
  }
`;

export const UpdateDot = styled.div`
  border-radius: 50%;
  background: ${colors.danger};
  width: 14px;
  height: 14px;
  position: absolute;
  z-index: 1;
  right: 6px;
  top: 10px;
`;

export const BorderImage = styled.img`
  position: absolute;
  left: 0;
  height: 43px;
  width: 4px;
  z-index: 1;
  top: 7px;
`;

export const ErrorMessage = styled.p`
  color: ${colors.redDark};
  font-size: 10px;
  margin: 0;
  padding-top: 4px;
`;

export const SuccessText = styled.p`
  color: ${colors.greenDark};
  font-size: 10px;
  margin: 0;
  padding-top: 4px;
`;

export const SuccessMessageContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const CircleCheckContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const AboutContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
`;

export const Flex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

export const VersionContainer = styled.div`
  width: 137px;
  height: 67px;
  display: flex;
  flex-direction: row;
  margin: 2rem;
  justify-content: center;
  align-items: center;
  background: ${colors.skyLighter};
  border-radius: 0.25rem;
  gap: 0.75rem;
  padding: 1rem;

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  > svg {
    animation: rotate 0.6s linear infinite;
  }
`;
