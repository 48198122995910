// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React, { useCallback, Fragment } from "react";
import { connect } from "react-redux";

import { DeletedMessage } from "./styles";
import ChatTimestamp from "src/components/messages/Chat/ChatTimestamp";
import NameOrEmail from "src/containers/user/NameOrEmail";
import * as notifications from "src/constants/notifications";

type Props = {
  message: any,
  currentUser: any
};

const Deleted = ({ message, currentUser }: Props) => {
  const { deletedBy, read, delivered, deletedAt, action } = message;
  const addSpace = useCallback(
    () => [...Array(30)].map((val, id) => <Fragment key={id}>&nbsp;</Fragment>),
    []
  );
  const isFile = action === notifications.FILE_ADD;

  return (
    <div>
      <DeletedMessage isCurrentUser={currentUser === deletedBy}>
        {currentUser === deletedBy ? (
          `${i18n.t(k.YOU)}`
        ) : (
          <NameOrEmail uid={deletedBy} />
        )}{" "}
        {i18n.t(k.DELETED_A)} {isFile ? "file" : "message"}
        {i18n.t(k._9)}
        {addSpace()}
        <ChatTimestamp
          read={read}
          delivered={delivered}
          // $FlowFixMe - Flow does not support optional chaining
          timestamp={deletedAt?.toDate()}
        />
      </DeletedMessage>
    </div>
  );
};

const mapStateToProps = ({ app }) => ({
  currentUser: app.currentUser.uid
});

export default connect(mapStateToProps)(Deleted);
