// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React, { useCallback } from "react";
import { FormControl, FormLabel, Input, HStack, Text } from "@chakra-ui/react";

import * as styles from "./process/styles";

type Props = {
  title: string,
  templateTitle?: string,
  seqNo?: number,
  handleChange: Function,
  workflowSettings?: Object
};

const Title = ({ title, seqNo, handleChange, workflowSettings }: Props) => {
  const handleText = useCallback(
    (event: any) => {
      const { value } = event.target;
      handleChange("title", value);
    },
    [handleChange]
  );

  const { hideAutoNumber } = workflowSettings || {};

  return (
    <FormControl p={4}>
      <FormLabel>{i18n.t(k.TITLE)}</FormLabel>
      <HStack sx={styles.InputContainer}>
        {!hideAutoNumber && seqNo && (
          <Text sx={styles.SeqNo}>
            {" "}
            {i18n.t(k._7)}
            {seqNo || ""}
            {i18n.t(k._)}
          </Text>
        )}
        <Input
          sx={styles.InputField}
          size="sm"
          variant="unstyled"
          value={title}
          id="title"
          data-cy="conversationDialogTitle"
          autoFocus
          onChange={handleText}
          autoComplete="off"
          placeholder={i18n.t(k.ENTER_TITLE)}
        />
      </HStack>
    </FormControl>
  );
};

export default Title;
