import i18n from "i18next";
import k from "src/i18n/keys";

import React, { useState, useEffect } from "react";
import Link from "redux-first-router-link";
import { v4 as uuid } from "uuid";
import * as R from "ramda";
import { useSelector, useDispatch } from "react-redux";
import {
  Input,
  Flex,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Button
} from "@chakra-ui/react";

import * as styles from "./styles";
import Details from "./Details";
import {
  getChart,
  getValueFromQuery,
  getLocationPayload,
  getChartLoading,
  getChartDataLoading
} from "src/reducers";
import AdvancedSettings from "./AdvancedSettings";
import {
  setCreateChart,
  createComparisonChart,
  updateComparisonChart
} from "src/actions/chart";
import { setReports } from "src/actions/reports";
import { comparisonValues, comparisonCharts } from "src/constants/charts.js";
import SpinnerLoader from "src/components/Loader/SpinnerLoader";
import Loader from "src/components/Dashboard/Body/Loader";
import ReportTableLoader from "./ReportTableLoader";
import Tooltip from "src/components/Tooltip";

export const initialSeriesState = {
  tempId: null,
  y: null,
  title: i18n.t(k._4),
  seqNo: null,
  computationType: "",
  computationField: null,
  operation: "",
  rateSeries: null,
  rateOperation: null,
  plotType: "",
  axisType: "",
  hide: false,
  relativeCount: false,
  seriesMultiValue: false
};

const initialSortState = {
  seriesId: null,
  orderBy: "default"
};

const initialState = {
  title: i18n.t(k._4),
  description: "",
  x: null,
  reportId: null,
  type: "",
  pYAxisLabel: "",
  sYAxisLabel: "",
  series: [],
  trendlines: [],
  sort: initialSortState,
  isMultiValue: false
};

const ComparisonBuilder = () => {
  const dispatch = useDispatch();

  const chartName = useSelector(({ location }) =>
    getValueFromQuery("chart")(location)
  );

  // -1 is used as a temp id for handling loading state while creating a chart.
  const chartId =
    useSelector(({ location }) => getLocationPayload("id")(location)) || -1;

  const workflowId = useSelector(({ location }) =>
    getValueFromQuery("workflowId")(location)
  );
  const reportId = useSelector(({ location }) =>
    getValueFromQuery("reportId")(location)
  );
  const builder = useSelector(({ location }) =>
    getValueFromQuery("builder")(location)
  );

  const editId = useSelector(({ location }) =>
    getLocationPayload("id")(location)
  );

  const isUpdating = useSelector(({ app }) =>
    getChartDataLoading(app, parseInt(chartId))
  );

  const isLoading = useSelector(({ app }) => getChartLoading(app));

  const editChartDetails =
    useSelector(({ app }) => getChart(app, `${editId}`)) || {};

  const initialSeriesValue =
    builder === comparisonCharts.StackedArea
      ? {
          ...initialSeriesState,
          tempId: uuid(),
          plotType: comparisonValues.area,
          axisType: "primary"
        }
      : {
          ...initialSeriesState,
          tempId: uuid(),
          plotType: comparisonValues.column,
          axisType: "primary"
        };

  const defaultChartDetails = {
    ...initialState,
    reportId,
    type: builder,
    series: [initialSeriesValue]
  };

  const [chartDetails, setChartDetails] = useState(defaultChartDetails);

  useEffect(() => {
    if (editId) {
      setChartDetails(editChartDetails);
    }
  }, [editId, editChartDetails]);

  const handleCreateChart = e => {
    e.preventDefault();

    const filteredIsMultiValue = R.omit(["isMultiValue"], chartDetails);
    const filteredChartDetails = R.over(
      R.lensPath(["series"]),
      R.map(R.omit(["seriesMultiValue"])),
      filteredIsMultiValue
    );

    const request = filteredChartDetails;
    editId
      ? dispatch(updateComparisonChart(editId, request))
      : dispatch(createComparisonChart(chartId, request));
  };

  const isSeriesEmpty = chartDetails.series.some(
    item => R.isEmpty(item.operation) || item.computationField == null
  );

  const disableButton =
    R.isEmpty(chartDetails.title) || chartDetails.x === null || isSeriesEmpty;

  return (
    <Flex
      as="form"
      onSubmit={handleCreateChart}
      sx={styles.SelectedChartBuilderWrapper}
    >
      {isLoading ? (
        <SpinnerLoader />
      ) : (
        <Flex sx={styles.TabsWrapper}>
          <Input
            autoFocus
            onChange={e => {
              setChartDetails(prev => ({ ...prev, title: e.target.value }));
            }}
            sx={styles.ChartTitle}
            value={chartDetails.title}
            variant="unstyled"
            placeholder={i18n.t(k.UNTITLED_CHART)}
          />

          {isUpdating ? (
            <>
              <Loader
                textStyles={{ top: "45%", left: "45%" }}
                imgStyles={{ width: "70%" }}
              />

              <ReportTableLoader />
            </>
          ) : (
            <Tabs>
              <TabList>
                <Tab sx={styles.Tab}>{i18n.t(k.DETAILS)}</Tab>
                <Tab sx={styles.Tab}>{i18n.t(k.ADVANCED_SETTINGS)}</Tab>
              </TabList>

              <TabPanels>
                <TabPanel tabIndex="-1" sx={{ p: "1.5rem" }}>
                  <Details
                    chartDetails={chartDetails}
                    setChartDetails={setChartDetails}
                  />
                </TabPanel>

                <TabPanel tabIndex="-1" sx={{ p: "1.5rem" }}>
                  <AdvancedSettings
                    chartDetails={chartDetails}
                    setChartDetails={setChartDetails}
                  />
                </TabPanel>
              </TabPanels>
            </Tabs>
          )}

          <Flex sx={styles.FooterButtonsWrapper}>
            <Link
              to={
                editId
                  ? setReports(reportId)
                  : setCreateChart({ chartName, reportId, workflowId })
              }
              style={styles.CancelButton}
            >
              {i18n.t(k.CANCEL)}
            </Link>
            <Tooltip
              sx={{ backgroundColor: "none", bg: "red" }}
              title={i18n.t(k.PLEASE_FILL_MANDATORY_FIELDS)}
              hide={!disableButton}
              hasArrow={false}
              placement="top"
            >
              <Button
                type="submit"
                isDisabled={disableButton}
                isLoading={isUpdating}
                sx={styles.Button}
                variant="uPrimary"
              >
                {editId ? i18n.t(k.UPDATE) : i18n.t(k.CREATE1)}{" "}
                {i18n.t(k.CHART1)}
              </Button>
            </Tooltip>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

export default ComparisonBuilder;
