// @flow

import React, { useState } from "react";

import Cell from "./Cell";

import type { Translation } from "src/types";

type Props = {
  translation: Translation
};

const Body = ({ translation }: Props) => {
  const [seeMore, setSeeMore] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  return (
    <div key={translation.id} style={{ display: "flex", width: "100%" }}>
      {/* $FlowFixMe */}
      <Cell
        translation={translation}
        column="originalText"
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        seeMore={seeMore}
        setSeeMore={setSeeMore}
      />
      {/* $FlowFixMe */}
      <Cell
        translation={translation}
        column="translated"
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        seeMore={seeMore}
        setSeeMore={setSeeMore}
      />
      {/* $FlowFixMe */}
      <Cell translation={translation} column="path" />
    </div>
  );
};

export default Body;
