// @flow

import React from "react";
import { Input } from "./styles";

type Props = {
  placeholder: string,
  onInput: Function,
  style?: Object
};

const SearchInput = ({ placeholder, onInput, style = {} }: Props) => {
  return (
    <Input
      onChange={e => onInput(e.target.value)}
      placeholder={placeholder}
      style={{
        ...style
      }}
    />
  );
};

export default SearchInput;
