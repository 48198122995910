// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React, { useState, useCallback, useEffect } from "react";
import * as R from "ramda";
import onClickOutside from "react-onclickoutside";

import Item from "./Item";
import {
  List,
  ListItem as StyledItem,
  Flex,
  Text,
  Box
} from "@chakra-ui/react";
import { Card } from "src/styles/card";

type Props = {
  value: ?string,
  outerRef: any,
  selected?: boolean,
  handleClose: Function,
  handleChange: Function,
  isInfoPanelOpen: boolean
};

const Options = ({
  value,
  outerRef,
  selected = false,
  handleClose,
  handleChange,
  isInfoPanelOpen
}: Props) => {
  const priorityLabel = [
    i18n.t(k.CRITICAL),
    i18n.t(k.HIGH),
    i18n.t(k.NORMAL1),
    i18n.t(k.LOW)
  ];

  const items = ["critical", "high", "normal", "low"];

  const [focus, setFocus] = useState(R.findIndex(R.equals(value), items) + 1);

  Options.handleClickOutside = useCallback((e: any) => {
    if (!isInfoPanelOpen) {
      if (outerRef) {
        if (!outerRef.current.contains(e.target)) {
          handleClose();
        }
      }
    }
  }, []);

  const handleKeyDown = useCallback(
    (e: any) => {
      if (e.keyCode === 40) {
        e.preventDefault();
        setFocus(focus === items.length ? 1 : focus + 1);
      } else if (e.keyCode === 38) {
        e.preventDefault();
        setFocus(focus > 1 ? focus - 1 : items.length);
      } else if (e.keyCode === 27) {
        handleClose();
      } else if (e.keyCode === 13) {
        handleChange(items[focus - 1]);
      }
    },
    [items, focus, setFocus, handleClose, handleChange]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown, false);
    return () => {
      document.removeEventListener("keydown", handleKeyDown, false);
    };
  }, [handleKeyDown]);

  return (
    <Card position="absolute" top={8} p={0} zIndex={2}>
      <Text
        whiteSpace="nowrap"
        mt={0}
        mb={1}
        mx={1}
        px={1}
        pt={1}
        color="gray.500"
        fontSize="xs"
        fontWeight="bold"
      >
        {i18n.t(k.SET_PRIORITY)}
      </Text>
      <List p={0} m={0}>
        {items.map((item: string, index: number) => {
          const isActive = value === item && !selected;
          const focused = focus == index + 1 ? "gray.100" : "white";

          return (
            <StyledItem
              key={item}
              onClick={() => handleChange(item)}
              onKeyPress={() => handleChange(item)}
              tabIndex="0"
              fontWeight={isActive ? "bold" : "normal"}
              bg={isActive ? "gray.200" : focused}
              data-cy={`priority-${item}`}
              _hover={{
                bg: "gray.100"
              }}
            >
              <Flex ml={1} px={1} py={1}>
                <Item value={item} />
                <Box ml={1} width="5em" textTransform="capitalize">
                  {priorityLabel[index]}
                </Box>
              </Flex>
            </StyledItem>
          );
        })}
      </List>
    </Card>
  );
};

const clickOutsideConfig = {
  handleClickOutside: () => Options.handleClickOutside
};

export default onClickOutside(Options, clickOutsideConfig);
