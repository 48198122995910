// @flow

import React from "react";

import Icon from "src/icons";
import { Text } from "@unifize/sarah";
import { SidebarItemContainer, Label } from "./styles";

import * as colors from "src/styles/constants/colors";

type Props = {
  isActive: boolean,
  icon: string,
  label: string,
  handleClick: () => void
};

const SidebarItem = ({ isActive, icon, label, handleClick }: Props) => {
  return (
    <SidebarItemContainer active={isActive} onClick={handleClick}>
      <Icon
        type={icon}
        color={isActive ? colors.brightLight : colors.navyBlue}
      />
      <Text
        variant="caption"
        color={isActive ? colors.brightLight : colors.grey39}
      >
        {label}
      </Text>
    </SidebarItemContainer>
  );
};

export default SidebarItem;
