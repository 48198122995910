// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";

import { Select } from "@chakra-ui/react";

type Props = {
  value?: string,
  handleSelect: Function
};

const TimeInterval = ({ value, handleSelect }: Props) => (
  <Select value={value} size="sm" onChange={handleSelect}>
    <option value="daily">{i18n.t(k.DAILY)}</option>
    <option value="weekly">{i18n.t(k.WEEKLY)}</option>
    <option value="monthly">{i18n.t(k.MONTHLY)}</option>
    <option value="annually">{i18n.t(k.YEARLY)}</option>
  </Select>
);

export default TimeInterval;
