// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import { connect } from "react-redux";
import React, { useCallback } from "react";

import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Flex,
  UnorderedList,
  ListItem
} from "@chakra-ui/react";
import { SmallAddIcon, ChevronDownIcon, DeleteIcon } from "@chakra-ui/icons";

import { setNewChartDialogAttributes } from "src/actions/chart";

type Props = {
  value: Object,
  _setNewChartDialogAttributes: Function
};

const Columns = ({ value, _setNewChartDialogAttributes }: Props) => {
  const completed = (value || {}).completed || false;
  const created = (value || {}).created || false;

  const handleAddColumn = useCallback(
    (type: string) => {
      switch (type) {
        case "created":
          _setNewChartDialogAttributes({
            columns: {
              created: true,
              completed
            }
          });
          break;
        case "completed":
          _setNewChartDialogAttributes({
            columns: {
              created,
              completed: true
            }
          });
          break;
        default:
          _setNewChartDialogAttributes({
            columns: {
              created,
              completed
            }
          });
          break;
      }
    },
    [created, completed, _setNewChartDialogAttributes]
  );

  const handleRemoveColumn = useCallback(
    (type: string) => {
      switch (type) {
        case "created":
          _setNewChartDialogAttributes({
            columns: {
              created: false,
              completed
            }
          });
          break;
        case "completed":
          _setNewChartDialogAttributes({
            columns: {
              created,
              completed: false
            }
          });
          break;
        default:
          _setNewChartDialogAttributes({
            columns: {
              created,
              completed
            }
          });
          break;
      }
    },
    [created, completed, _setNewChartDialogAttributes]
  );

  return (
    <>
      <UnorderedList spacing={1} pl="0" mt="0">
        {created && (
          <ListItem fontSize="sm">
            <Flex alignItems="center">
              <div>{i18n.t(k.CREATED1)}</div>
              <DeleteIcon
                type="button"
                onClick={() => handleRemoveColumn("created")}
                ml="2"
                cursor="pointer"
              />
            </Flex>
          </ListItem>
        )}

        {completed && (
          <ListItem fontSize="sm">
            <Flex alignItems="center">
              <div>{i18n.t(k.COMPLETED)}</div>
              <DeleteIcon
                type="button"
                onClick={() => handleRemoveColumn("completed")}
                ml="2"
                cursor="pointer"
              />
            </Flex>
          </ListItem>
        )}
      </UnorderedList>

      <Menu>
        <MenuButton
          size="sm"
          as={Button}
          colorScheme="blue"
          variant="outline"
          rightIcon={<ChevronDownIcon />}
        >
          <SmallAddIcon h={5} w={5} /> {i18n.t(k.ADD_COLUMN)}
        </MenuButton>
        <MenuList
          boxShadow="0 2px 8px 0 rgba(0, 0, 0, 0.08);"
          border="1px solid"
          borderColor="gray.200"
        >
          <MenuItem
            bg="white"
            type="button"
            onClick={() => handleAddColumn("created")}
          >
            {i18n.t(k.CREATED1)}
          </MenuItem>
          <MenuItem
            bg="white"
            type="button"
            onClick={() => handleAddColumn("completed")}
          >
            {i18n.t(k.COMPLETED)}
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  );
};

export default connect(null, {
  _setNewChartDialogAttributes: setNewChartDialogAttributes
})(Columns);
