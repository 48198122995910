// @flow

import React from "react";
import { Flex } from "@chakra-ui/react";
import Icon from "src/icons";
import { PathLabel } from "../styles";
import * as colors from "src/styles/constants/colors";

type Props = {
  isOverflowing: boolean,
  firstElement: string,
  lastElement: string,
  middleElements: Array<string>
};

const PathLabels = ({
  isOverflowing,
  firstElement,
  lastElement,
  middleElements
}: Props) => {
  return isOverflowing ? (
    <>
      <Flex>
        <PathLabel
          style={{
            maxWidth: "100px"
          }}
        >
          {firstElement}
        </PathLabel>
        <Icon
          type="caretRightNew"
          style={{ width: "16px", height: "16px" }}
          color={colors.grey42}
        />
      </Flex>
      <PathLabel>...</PathLabel>
      <Flex>
        <Icon
          type="caretRightNew"
          style={{ width: "16px", height: "16px" }}
          color={colors.grey42}
        />
        <PathLabel
          style={{
            maxWidth: "100px"
          }}
        >
          {lastElement}
        </PathLabel>
      </Flex>
    </>
  ) : (
    <>
      <Flex>
        <PathLabel>{firstElement}</PathLabel>
        <Icon
          type="caretRightNew"
          style={{ width: "16px", height: "16px" }}
          color={colors.grey42}
        />
      </Flex>
      {middleElements.map((element, index) => (
        <Flex key={index}>
          <PathLabel>{element}</PathLabel>
          {index !== middleElements.length - 1 && (
            <Icon
              type="caretRightNew"
              style={{ width: "16px", height: "16px" }}
              color={colors.grey42}
            />
          )}
        </Flex>
      ))}
      <Flex>
        <Icon
          type="caretRightNew"
          style={{ width: "16px", height: "16px" }}
          color={colors.grey42}
        />
        <PathLabel style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
          {lastElement}
        </PathLabel>
      </Flex>
    </>
  );
};

export default PathLabels;
