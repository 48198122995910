// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React, { Component } from "react";
import * as R from "ramda";

import { conversationTypes } from "src/constants/display";
import Search from "src/containers/chatroom/SearchMultiple";
import Name from "src/containers/chatroom/Name";
import { ListItem } from "src/styles";
import { TextButtonWithImage } from "src/styles/buttons.old";
import { AttachedItemMultiple } from "src/styles/form.old";

import { onManageView } from "src/utils";

type Props = {
  value: Array<number>,
  settings?: Object,
  handleChange: Function,
  startConversation?: ?Function
};

type State = {
  search: boolean
};

class SelectMultiple extends Component<Props, State> {
  outerRef: any;

  static defaultProps = {
    settings: {
      workflow: null,
      type: null,
      startConversation: null
    }
  };

  state = {
    search: false
  };

  toggleSearch = () => {
    this.setState(prevState => ({ search: !prevState.search }));
  };

  handleChatroom = (id: number) => {
    const { value, handleChange } = this.props;

    if (R.includes(id, value)) {
      handleChange(
        R.map(
          r => parseInt(r, 10),
          R.reject(c => id === c, value)
        )
      );
    } else {
      handleChange(R.map(r => parseInt(r, 10), [...value, id]));
    }
  };

  render() {
    const { value, settings, startConversation } = this.props;
    const { search } = this.state;
    return (
      <div>
        {(value || []).length !== 0 ? (
          <AttachedItemMultiple>
            {R.map(
              room => (
                <ListItem key={room}>
                  <Name id={room} />
                  <button
                    type="button"
                    onClick={() => this.handleChatroom(room)}
                  >
                    {i18n.t(k.X3)}
                  </button>
                </ListItem>
              ),

              value
            )}
          </AttachedItemMultiple>
        ) : null}

        <TextButtonWithImage
          onClick={this.toggleSearch}
          onKeyPress={this.toggleSearch}
          ref={node => {
            this.outerRef = node;
          }}
          type="button"
        >
          <span>
            {onManageView() ? "Select" : i18n.t(k.SELECT)}{" "}
            {(settings || {}).type
              ? conversationTypes[(settings || {}).type][0]
              : onManageView()
                ? "Conversation"
                : i18n.t(k.CONVERSATION1)}
          </span>
        </TextButtonWithImage>

        {search ? (
          <Search
            outerRef={this.outerRef}
            selected={value}
            handleClose={this.toggleSearch}
            toggleRoom={this.handleChatroom}
            settings={settings}
            startConversation={startConversation}
          />
        ) : null}
      </div>
    );
  }
}

export default SelectMultiple;
