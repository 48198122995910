// @flow

import React, { createContext, useContext, useState, useCallback } from "react";
import {
  refreshTranslation as refreshTranslationApi,
  updateTranslation as updateTranslationApi
} from "src/api/translation";

type LanguageContextType = {
  language: string | null,
  translations: Array<Object>,
  setLanguage: (lang: string) => void,
  setTranslations: Function,
  refreshTranslation: (translationId: number) => Promise<void>,
  updateTranslation: (
    translationId: number,
    manualTranslation: string
  ) => Promise<void>
};

export const LanguageContext = createContext<LanguageContextType>({
  language: null,
  translations: [],
  setLanguage: () => {},
  setTranslations: () => {},
  refreshTranslation: async () => {},
  updateTranslation: async () => {}
});

type Props = {
  children: React$Element<*>
};

export const LanguageProvider = ({ children }: Props) => {
  const [language, setLanguage] = useState(null);
  const [translations, setTranslations] = useState([]);

  const refreshTranslation = useCallback(
    async (translationId: number) => {
      try {
        if (language) {
          await refreshTranslationApi(translationId, language);
          setTranslations(prev => {
            const updated = prev.map(translation => {
              if (translation.id === translationId) {
                return { ...translation, manualTranslation: null };
              }
              return translation;
            });
            return updated;
          });
        }
      } catch (error) {
        console.error("Failed to refresh translation: ", error);
      }
    },
    [language]
  );

  const updateTranslation = useCallback(
    async (translationId: number, manualTranslation: string) => {
      try {
        if (language) {
          const updatedTranslation = await updateTranslationApi(
            translationId,
            manualTranslation,
            language
          );
          setTranslations(prev => {
            const updated = prev.map(translation => {
              if (translation.id === translationId) {
                return {
                  ...translation,
                  manualTranslation: updatedTranslation.manualTranslation
                };
              }
              return translation;
            });
            return updated;
          });
        }
      } catch (error) {
        console.error("Failed to update translation: ", error);
      }
    },
    [language]
  );

  return (
    <LanguageContext.Provider
      value={{
        language,
        setLanguage,
        translations,
        setTranslations,
        refreshTranslation,
        updateTranslation
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => useContext(LanguageContext);
