// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React, { useState } from "react";
import { useSelector } from "react-redux";

import type { RoomId, FieldId } from "src/types";
import { getMutualVersions } from "src/reducers";
import useFetch from "src/hooks/useFetch";
import useBoolean from "src/hooks/useBoolean";
import { fieldTransitReader } from "src/transit/checklist/field/reader";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  HStack,
  Button,
  Text
} from "@chakra-ui/react";
import Icons from "src/icons";

import SingleSelectModal from "./SingleModal";
import MultiSelectModal from "./MultiModal";
import * as styles from "./styles";

type Props = {
  open: boolean,
  onClose: Function,
  roomId: RoomId,
  fieldId: FieldId,
  sourceChatroomId: number,
  handledEditParentField: Function,
  handleRemoveLink: Function
};

const isFieldMulti = response =>
  JSON.parse(fieldTransitReader.read(response.type).settings).multiple;

const apiUrl = ({ roomId, fieldId, sourceChatroomId }) =>
  `/chatroom/${roomId}/checklist-field/${fieldId}?sourceChatroom=${sourceChatroomId}`;

const RemoveLinkModal = ({
  open,
  onClose,
  roomId,
  fieldId,
  sourceChatroomId,
  handledEditParentField,
  handleRemoveLink
}: Props) => {
  const { response, isLoading: isFetchingValue } = useFetch(
    apiUrl({ roomId, fieldId, sourceChatroomId }),
    { method: "GET" }
  );

  const {
    value: isParentFieldModalOpen,
    setTrue: openParentFieldModal,
    setFalse: closeParentFieldModal
  } = useBoolean(false);

  const removeLink = () => {
    handleRemoveLink();
    onClose();
  };

  const editParentField = value => {
    handledEditParentField(value);
    onClose();
  };

  const [selectedRoomId, setSelectedRoomId] = useState(null);

  const modalSelectedRooms = useSelector(({ app }) => {
    return selectedRoomId !== null && selectedRoomId !== undefined
      ? getMutualVersions(app)(response.value, selectedRoomId)
      : [];
  });

  if (!isParentFieldModalOpen || !response)
    return (
      <Modal isOpen={open} onClose={onClose} {...styles.modal}>
        <ModalOverlay />

        <ModalContent {...styles.modalContent}>
          <ModalHeader>{i18n.t(k.REMOVE_LINK_OR_REASSIGN_REVISI)}</ModalHeader>

          <ModalBody>
            <HStack {...styles.removeLinkBody}>
              <span>
                <Icons type="currentRevision" {...styles.removeLinkModalIcon} />
              </span>
              <Text {...styles.normalText}>
                {i18n.t(k.THIS_CONVERSATION_HAS_MULTIPLE)}
              </Text>
            </HStack>
          </ModalBody>

          <ModalFooter>
            <HStack {...styles.removeLinkModalFooter}>
              <Button
                variant="uSecondary"
                onClick={openParentFieldModal}
                isLoading={isFetchingValue}
              >
                {i18n.t(k.REASSIGN_REVISIONS)}
              </Button>
              <Button variant="uDanger" onClick={removeLink}>
                {i18n.t(k.REMOVE_LINK)}
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );

  setSelectedRoomId(response.value[0].chatroom.id);

  const selectedRooms = modalSelectedRooms.map(item => `${item.chatroom.id}`);

  const autoLink = selectedRooms.length === 1;

  return isFieldMulti(response) ? (
    <MultiSelectModal
      open
      roomId={selectedRoomId}
      selectedRooms={selectedRooms}
      autoLink={autoLink}
      onSuccess={editParentField}
      onClose={closeParentFieldModal}
    />
  ) : (
    <SingleSelectModal
      open
      roomId={selectedRoomId}
      autoLink={autoLink}
      selectChatroom={editParentField}
      onClose={closeParentFieldModal}
    />
  );
};

export default RemoveLinkModal;
