// @flow

import { combineReducers } from "redux";
import { createSelector } from "reselect";
import * as R from "ramda";

import * as atypes from "src/constants/actionTypes";

import type {
  GroupsState,
  Group,
  Action,
  GroupModal,
  GroupFilter,
  GroupsById,
  GroupsByUsers,
  UID,
  AddedOn,
  AppState,
  GroupId
} from "src/types";

const byId = (state: GroupsById = {}, { type, payload }: Action) => {
  switch (type) {
    case atypes.SYNC_GROUPS_SUCCESS:
    case atypes.GET_GROUPS_SUCCESS:
      return { ...state, ...payload.groups };
    case atypes.UPDATE_GROUP_SUCCESS:
      return {
        ...state,
        [payload.id]: payload
      };
    default:
      return state;
  }
};

const byUsers = (state: GroupsByUsers = {}, { type, payload }: Action) => {
  switch (type) {
    case atypes.GET_GROUPS_BY_USERS:
      return payload.users;
    default:
      return state;
  }
};

const current = (state: Group | {} = {}, { type, payload }: Action) => {
  switch (type) {
    case atypes.SET_CURRENT_GROUP:
      return payload.group;
    default:
      return state;
  }
};

const addedOn = (state: AddedOn = {}, { type, payload }: Action) => {
  switch (type) {
    case atypes.SYNC_GROUPS_SUCCESS:
    case atypes.GET_USER_GROUP_SUCCESS:
      return {
        ...state,
        [payload.uid]: R.mergeAll(
          R.map(g => ({ [g.gid]: g.addedOn }), payload.groups)
        )
      };
    default:
      return state;
  }
};

const initialModalState = {
  image: null,
  title: "",
  owners: [],
  members: [],
  locked: false,
  show: false,
  error: null
};

const modal = (
  state: GroupModal = initialModalState,
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.SET_GROUP_MODAL_ATTRIBUTES:
      return { ...state, ...payload };
    case atypes.CREATE_GROUP_REQUEST:
    case atypes.EDIT_GROUP_REQUEST:
      return { ...state, loading: true };
    case atypes.CREATE_GROUP_SUCCESS:
    case atypes.UPDATE_GROUP_SUCCESS:
    case atypes.CLOSE_GROUP_MODAL:
      return initialModalState;
    default:
      return state;
  }
};

const filter = (state: GroupFilter = {}, { type, meta }: Action) => {
  switch (type) {
    case atypes.SET_GROUPS_REQUEST:
      const filter = (meta || {}).query || {};
      // When user refreshes the browser if there is multiple values browser returns array
      // but returns string for single values so it needs to be converted to array
      const multiSelections = R.difference(R.keys(filter), ["id", "search"]);

      const multipleValues = R.mergeAll(
        R.map(
          key => ({
            [key]: R.type(filter[key]) === "Array" ? filter[key] : [filter[key]]
          }),
          multiSelections
        )
      );
      const newFilter = { ...filter, ...multipleValues };
      return newFilter;
    default:
      return state;
  }
};

const searchResult = (state: Array<number> = [], { type, payload }: Action) => {
  switch (type) {
    case atypes.SEARCH_GROUPS_SUCCESS:
      return payload.groups;
    default:
      return state;
  }
};

const groupDetailsResult = (
  state: Array<UID> = [],
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.SEARCH_GROUP_DETAILS_SUCCESS:
      return payload.users;
    default:
      return state;
  }
};

const groupDetailsSelection = (
  state: Array<UID> = [],
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.TOGGLE_GROUP_DETAILS_SELECTION:
      return R.includes(payload.row, state)
        ? R.reject(R.equals(payload.row), state)
        : [...state, payload.row];
    case atypes.CLEAR_GROUP_DETAILS_SELECTION:
      return [];
    default:
      return state;
  }
};

const groups = combineReducers<Object, Action>({
  byId,
  byUsers,
  current,
  modal,
  filter,
  searchResult,
  groupDetailsResult,
  groupDetailsSelection,
  addedOn
});

export const getAllGroupsById = (state: GroupsState) => state.byId;

export const getFilteredGroups = (state: GroupsState) => state.searchResult;
export const getUserGroups = (state: GroupsState, uid: UID) =>
  state.byUsers[uid];

export const getGroup = (state: GroupsState, id: number) => state.byId[`${id}`];
export const getGroups = (state: GroupsState, ids: number[]) =>
  ids.map<Group>(id => state.byId[`${id}`]);

export const getGroupMembers = (state: GroupsState, id: number) =>
  state.byId[`${id}`] ? state.byId[`${id}`].members : [];

export const getFilteredGroupDetails = (state: GroupsState) =>
  state.groupDetailsResult;

export const isGroupDetailsRowSelected = (state: GroupsState, uid: UID) =>
  R.includes(uid, state.groupDetailsSelection);

export const getAddedOn = (state: GroupsState, uid: UID) => state.addedOn[uid];

export const getAllGroupIds = (state: GroupsState) => R.keys(state.byId);

export const getGroupTitle = (state: GroupsState, id: number) =>
  state.byId[`${id}`]?.title;

const getRoomMembers = (state: AppState) => state.roomMembers;
const getRoomGroups = (state: AppState) => state.roomGroups;

export const getAllGroups = (state: GroupsState) => R.values(state.byId);

const getRoomGroupsDetails = createSelector(
  [state => getAllGroupsById(state.groups), getRoomGroups],
  (groups, groupIds) => {
    const roomGroups =
      Object.keys(groups).length > 0 ? groupIds.map(id => groups[id]) : [];
    return roomGroups;
  }
);

export const getAllParticipantsCount = createSelector(
  [getRoomGroupsDetails, getRoomMembers],
  (groups, members) => {
    const allGroupMembers = groups.map(group => group.members).flat() || [];
    const allMembers = [...allGroupMembers, ...(members || [])];
    return new Set(allMembers).size;
  }
);

const getGroupIds = (_state: GroupsState, groupIds: Array<GroupId>) => groupIds;

export const getGroupDetails = createSelector(
  [getAllGroupsById, getGroupIds],
  (byId, groupIds) => {
    if (!groupIds || groupIds.length < 1) return [];

    return groupIds.reduce((groups, id) => {
      const group = byId[String(id)];
      if (group) {
        groups.push(group);
      }
      return groups;
    }, []);
  }
);

export const getMembersByGroups = createSelector(
  [getAllGroupsById, getGroupIds],
  (byId, groupIds) => {
    if (!groupIds || groupIds.length < 1) return [];

    return groupIds.reduce((members, id) => {
      const group = byId[String(id)];
      if (group) {
        members = members.concat(group.members);
      }
      return members;
    }, []);
  }
);

export const getAllParticipants = createSelector(
  [getRoomMembers, getRoomGroupsDetails],
  (roomMembers, roomGroups) => {
    const allGroupMembers: Array<UID> =
      roomGroups.map(group => group.members).flat() || [];
    const allParticipants: Array<UID> = [
      ...allGroupMembers,
      ...(roomMembers || [])
    ];
    return allParticipants;
  }
);

export const getGroupMentions = createSelector(
  [state => getAllGroups(state)],
  (groups: Array<Group>) => [
    ...groups.map((group: Group) => {
      return {
        id: `!team^${group.id}`,
        type: "group",
        display: `@${group.title}`
      };
    })
  ]
);

export default groups;
