// @flow

import { combineReducers } from "redux";
import * as atypes from "src/constants/actionTypes";

import type { Action } from "src/types";

const currentSection = (state: string | null = null, { type }: Action) => {
  switch (type) {
    default:
      return state;
  }
};

const emailNotification = (state: number = 1, { type, payload }: Action) => {
  switch (type) {
    case atypes.GET_USER_NOTIFICATION_SETTINGS_SUCCESS:
    case atypes.SET_USER_NOTIFICATION_SETTINGS_OPTIMISTIC:
    case atypes.SET_USER_NOTIFICATION_SETTINGS_FAILURE:
      return payload.notification;
    default:
      return state;
  }
};

export default combineReducers<Object, Action>({
  currentSection,
  emailNotification
});
