// @flow

import React, { useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getFileUploadModalStatus,
  getCurrentRoomId,
  getIsSrwMobile,
  getCurrentRoomAccess,
  getProcessLayoutSettings,
  getIsChatroomOpen,
  getActiveDock
} from "src/reducers";
import { currentRoomAccessStatuses } from "src/reducers/chatRooms";
import { hideChatroomComponent } from "src/actions/chatmenu";

import Overlay from "./Overlay";
import ChatroomHeader from "src/components/chatroom/Header";
import ChatArea from "src/components/chatroom/ChatArea";
import PreviewModal from "src/components/file/PreviewModal";
import RestrictedModal from "./RestrictedModal";
import { Current as StyledCurrent, Main } from "src/styles";
import Dock from "src/containers/CurrentRoomDock";

const Current = () => {
  const dispatch = useDispatch();
  const currentRef = useRef(null);
  const mainContainerRef = useRef(null);

  const roomId = useSelector(({ app }) => getCurrentRoomId(app));
  const isSrwMobile = useSelector(({ app }) => getIsSrwMobile(app));

  const showFilesPreviewModal = useSelector(({ app }) =>
    getFileUploadModalStatus(app, roomId)
  );

  const currentRoomAccessStatus = useSelector(({ app }) =>
    getCurrentRoomAccess(app)
  );

  const layout = useSelector(({ app }) =>
    getProcessLayoutSettings(app, roomId)
  );

  const activeDock = useSelector(({ app }) => getActiveDock(app));
  const isChatroomActive = useSelector(({ app }) => getIsChatroomOpen(app));
  const isFullWidth = layout?.checklistWidth === "full";
  const currentRoomRestricted =
    currentRoomAccessStatus === currentRoomAccessStatuses.restricted;

  useEffect(() => {
    if (isFullWidth && activeDock) {
      dispatch(hideChatroomComponent());
    }
  }, [activeDock, roomId]);

  return (
    <>
      <RestrictedModal />
      <StyledCurrent ref={currentRef}>
        {showFilesPreviewModal && <PreviewModal roomId={roomId} />}
        {!currentRoomRestricted && (
          <>
            <ChatroomHeader currentRef={currentRef} />
            <Main ref={mainContainerRef}>
              {isChatroomActive && <ChatArea roomId={roomId} />}
              {!isSrwMobile && <Dock parentRef={mainContainerRef} />}

              <Overlay />
            </Main>
          </>
        )}
      </StyledCurrent>
    </>
  );
};

export default Current;
