// @flow

import type {
  ChecklistFieldType,
  LinkedFieldType,
  chatPickListField,
  TaskField,
  GroupField,
  workflowField
} from "src/types";

function text({
  label = "",
  importError = false,
  autoCheck = false,
  settings
}: ChecklistFieldType) {
  this.label = label;
  this.importError = importError;
  this.autoCheck = autoCheck;
  this.settings = settings;
  this.type = "text";
}

function richtext({
  label = "",
  importError = false,
  autoCheck = false,
  settings
}: ChecklistFieldType) {
  this.label = label;
  this.importError = importError;
  this.autoCheck = autoCheck;
  this.settings = settings;
  this.type = "richtext";
}

function number({
  label = "",
  importError = false,
  autoCheck = false,
  settings
}: ChecklistFieldType) {
  this.label = label;
  this.importError = importError;
  this.autoCheck = autoCheck;
  this.settings = settings;
  this.type = "number";
}

function date({
  label = "",
  importError = false,
  autoCheck = false,
  settings
}: ChecklistFieldType) {
  this.label = label;
  this.importError = importError;
  this.autoCheck = autoCheck;
  this.settings = settings;
  this.type = "date";
}

function user({
  label = "",
  importError = false,
  autoCheck = false,
  settings
}: ChecklistFieldType) {
  this.label = label;
  this.importError = importError;
  this.autoCheck = autoCheck;
  this.settings = settings;
  this.type = "user";
}

function conversation({
  label = "",
  importError = false,
  autoCheck = false,
  settings
}: ChecklistFieldType) {
  this.label = label;
  this.importError = importError;
  this.autoCheck = autoCheck;
  this.settings = settings;
  this.type = "conversation";
}

function childConversation({
  label = "",
  importError = false,
  autoCheck = false,
  settings
}: ChecklistFieldType) {
  this.label = label;
  this.importError = importError;
  this.autoCheck = autoCheck;
  this.settings = settings;
  this.type = "childConversation";
}

function select({
  label = "",
  importError = false,
  autoCheck = false,
  settings
}: ChecklistFieldType) {
  this.label = label;
  this.importError = importError;
  this.autoCheck = autoCheck;
  this.settings = settings;
  this.type = "select";
}

function pdf({
  label = "",
  importError = false,
  autoCheck = false,
  settings
}: ChecklistFieldType) {
  this.label = label;
  this.importError = importError;
  this.autoCheck = autoCheck;
  this.settings = settings;
  this.type = "pdf";
}

function section({
  label = "",
  importError = false,
  autoCheck = false,
  settings
}: ChecklistFieldType) {
  this.label = label;
  this.importError = importError;
  this.autoCheck = autoCheck;
  this.settings = settings;
  this.type = "section";
}

function subSection({
  label = "",
  importError = false,
  autoCheck = false,
  settings
}: ChecklistFieldType) {
  this.label = label;
  this.importError = importError;
  this.autoCheck = autoCheck;
  this.settings = settings;
  this.type = "subSection";
}

function file({
  label = "",
  importError = false,
  autoCheck = false,
  settings
}: ChecklistFieldType) {
  this.label = label;
  this.importError = importError;
  this.autoCheck = autoCheck;
  this.settings = settings;
  this.type = "file";
}

function form({
  label = "",
  importError = false,
  autoCheck = false,
  settings
}: ChecklistFieldType) {
  this.label = label;
  this.importError = importError;
  this.autoCheck = autoCheck;
  this.settings = settings;
  this.type = "form";
}

function approval({
  label = "",
  importError = false,
  autoCheck = false,
  settings
}: ChecklistFieldType) {
  this.label = label;
  this.importError = importError;
  this.autoCheck = autoCheck;
  this.settings = settings;
  this.type = "approval";
}

function revision({
  label = "",
  importError = false,
  autoCheck = false,
  settings
}: ChecklistFieldType) {
  this.label = label;
  this.importError = importError;
  this.autoCheck = autoCheck;
  this.settings = settings;
  this.type = "revision";
}

function link({
  label = "",
  importError = false,
  autoCheck = false,
  settings,
  sourceProcessSettings,
  linkedProcessSettings
}: LinkedFieldType) {
  this.label = label;
  this.importError = importError;
  this.autoCheck = autoCheck;
  this.settings = settings;
  this.type = "link";
  /*
    WARNING: The reason for the following code is because some notifications
    have `sourceProcessSettings` as an Object in the transit instead of a
    stringified JSON. Since the notification data is not fixed and to prevent
    crashes we are accommodating this bug.
    Remove this hack only after confirmation that NONE of the notifications
    have an incorrect transit.
  */
  this.sourceProcessSettings =
    typeof sourceProcessSettings === "string"
      ? JSON.parse(sourceProcessSettings || "{}")
      : sourceProcessSettings || {};
  this.linkedProcessSettings =
    typeof linkedProcessSettings === "string"
      ? JSON.parse(linkedProcessSettings || "{}")
      : linkedProcessSettings || {};
}

// Old Transit Types
function chatPickList({
  label = "",
  isRequired = false,
  placeholder = "Choose the chat",
  isChecked = false,
  settings = {}
}: chatPickListField) {
  this.label = label;
  this.isRequired = isRequired;
  this.placeholder = placeholder;
  this.isChecked = isChecked;
  this.settings = settings;
  this.type = "chatPickList";
}

function workflow({
  label = "",
  isRequired = false,
  placeholder = "Choose the workflow template",
  isChecked = false,
  settings = {}
}: workflowField) {
  this.label = label;
  this.isRequired = isRequired;
  this.placeholder = placeholder;
  this.isChecked = isChecked;
  this.settings = settings;
  this.type = "workflow";
}

function task({
  label = "",
  isRequired = false,
  placeholder = "start the task",
  isChecked = false
}: TaskField) {
  this.label = label;
  this.isRequired = isRequired;
  this.placeholder = placeholder;
  this.isChecked = isChecked;
  this.type = "task";
}

function group({
  label = "",
  isRequired = false,
  placeholder = "start the chat",
  isChecked = false
}: GroupField) {
  this.label = label;
  this.isRequired = isRequired;
  this.placeholder = placeholder;
  this.isChecked = isChecked;
  this.type = "group";
}

const transitTypes = {
  form,
  section,
  subSection,
  text,
  number,
  date,
  user,
  conversation,
  select,
  chatPickList,
  file,
  workflow,
  pdf,
  task,
  group,
  childConversation,
  approval,
  revision,
  link,
  richtext
};

// Must be sorted alphabetically
export const newTransitTypes = {
  approval,
  childConversation,
  date,
  file,
  form,
  link,
  number,
  conversation, // parentConversation
  pdf,
  select, // picklist
  revision,
  richtext,
  section,
  subSection,
  text,
  user
};

export default transitTypes;
