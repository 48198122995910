// @flow

import * as atypes from "src/constants/actionTypes";

export const getMyConversatoinChart = ({
  tileId,
  filter
}: {
  tileId: string,
  filter: Object
}) => ({
  type: atypes.GET_MY_CONVERSATION_CHART_REQUEST,
  payload: { tileId, filter }
});

export const setHomescreen = () => ({
  type: atypes.SET_HOME_SCREEN_REQUEST,
  payload: {}
});

export const openHomeScreenTile = (analytics: Object) => ({
  type: atypes.OPEN_HOME_SCREEN_TILE,
  payload: { analytics }
});

export const setTileEmptyStatus = (
  sectionId: number,
  tileId: number,
  isEmpty: boolean
) => ({
  type: atypes.SET_TILE_EMPTY_STATUS,
  payload: {
    sectionId,
    tileId,
    isEmpty
  }
});

export const deleteTileStatus = (sectionId: number, tileId: number) => ({
  type: atypes.DELETE_TILE_STATUS,
  payload: {
    sectionId,
    tileId
  }
});
