// @flow

import * as React from "react";
import {
  chakra,
  omitThemingProps,
  useFormControl,
  useMergeRefs,
  useMultiStyleConfig,
  usePopper
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { mergeWith } from "@chakra-ui/utils";
import { useSelect } from "downshift";
import * as customStyles from "./styles";

type Props<T> = {
  value: T | null,
  children: React.Node,
  placeholder?: string,
  onChange: (item: T) => void
};

// $FlowFixMe
const Select = <T>(): React.AbstractComponent<Props<T>, HTMLElement> =>
  React.forwardRef<Props<T>, HTMLElement>((props, ownRef) => {
    const { value, children, placeholder, onChange, ...rest } =
      omitThemingProps(props);

    const ownButtonProps = useFormControl(rest);

    const styles = useMultiStyleConfig("CustomSelect", props);

    const validChildren = React.useMemo(
      () =>
        React.Children.toArray(children)
          .filter(React.isValidElement)
          .filter(child => "value" in child.props),
      [children]
    );

    const items = validChildren.map(child => child.props.value);

    const { isOpen, getToggleButtonProps, getMenuProps, getItemProps } =
      useSelect({
        items,
        stateReducer: (state, actionAndChanges) => {
          const { changes, type } = actionAndChanges;
          switch (type) {
            case useSelect.stateChangeTypes.MenuKeyDownEnter:
            case useSelect.stateChangeTypes.MenuKeyDownSpaceButton:
            case useSelect.stateChangeTypes.ItemClick:
              return {
                ...changes,
                highlightedIndex: state.highlightedIndex
              };
          }
          return changes;
        },
        onStateChange: ({ type, selectedItem }) => {
          switch (type) {
            case useSelect.stateChangeTypes.MenuKeyDownEnter:
            case useSelect.stateChangeTypes.MenuKeyDownSpaceButton:
            case useSelect.stateChangeTypes.ItemClick: {
              onChange(selectedItem);
              break;
            }
            default:
              break;
          }
        }
      });

    const { referenceRef: popperRef, getPopperProps } = usePopper({
      enabled: isOpen
    });

    const { ref: useSelectToggleButtonRef, ...useSelectToggleButtonProps } =
      getToggleButtonProps();

    const toggleButtonRef = useMergeRefs(
      ownRef,
      useSelectToggleButtonRef,
      popperRef
    );

    const toggleButtonProps = mergeWith(
      ownButtonProps,
      useSelectToggleButtonProps
    );

    const getPreview = () => {
      if (!value && placeholder)
        return <chakra.span>{placeholder}</chakra.span>;

      return (
        validChildren.find(child => child.props.value === value)?.props
          .children ||
        value || <chakra.span>{placeholder}</chakra.span>
      );
    };

    return (
      <chakra.div position="relative">
        <chakra.div
          ref={toggleButtonRef}
          __css={{ ...styles.field, ...customStyles.toggleButton }}
          data-focus-visible-added={isOpen}
          {...toggleButtonProps}
        >
          {getPreview()}

          <ChevronDownIcon />
        </chakra.div>

        <chakra.div
          zIndex="200"
          width="100%"
          {...mergeWith(getPopperProps(), {
            style: {
              visibility: isOpen ? "visible" : "hidden",
              ...customStyles.optionsContainer
            }
          })}
        >
          <chakra.ul
            __css={{
              ...styles.menu,
              ...customStyles.optionsList
            }}
            data-focus-visible-added={isOpen}
            {...getMenuProps()}
          >
            {isOpen &&
              validChildren.map((item, index) =>
                React.cloneElement(item, {
                  __css: styles.option,
                  ...getItemProps({
                    item: item.props.value,
                    index
                  })
                })
              )}
          </chakra.ul>
        </chakra.div>
      </chakra.div>
    );
  });

export default Select;
