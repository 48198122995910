// @flow

import React, { useEffect, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import * as R from "ramda";
import { Resizable } from "re-resizable";
import { Box } from "@chakra-ui/react";
import { useClientDimensions } from "react-client-dimensions";

import { hideDock, showDock, showSrwDock } from "src/actions/dock";
import { hideChatroomComponent } from "src/actions/chatmenu";
import {
  getChatroomType,
  getProcessLayoutSettings,
  getIsSrwMobile,
  getLocation,
  getIsChatroomOpen
} from "src/reducers";
import { getMainActiveDock, getSRWActiveDock } from "src/reducers/activeDock";
import type { AppState, RoomId, ReactRef } from "src/types";

import Checklist from "src/components/Dock/Panels/Checklist";

import * as styles from "./styles";
import { fadeOut } from "./styles";
import { DockContainer } from "./styles";
import * as colors from "src/styles/constants/colors";

type Props = {
  roomId: RoomId,
  isSrw: boolean,
  type: string,
  _hideDock: Function,
  parentRef?: ReactRef
};

const Dock = ({ roomId, _hideDock, isSrw, type, parentRef }: Props) => {
  const dispatch = useDispatch();
  const { vw } = useClientDimensions();
  const [windowSize, setWindowSize] = useState({ current: vw });
  const location = useSelector(state => getLocation(state));
  const isChatroomActive = useSelector(({ app }) => getIsChatroomOpen(app));

  const checklistLayout = useSelector(({ app }) =>
    getProcessLayoutSettings(app, roomId)
  );

  const { checklistOpenState = {} } = checklistLayout;

  useEffect(() => {
    setWindowSize({ current: vw });
  }, [vw]);

  const [checklistMaxWidth, setChecklistMaxWidth] = useState(0);
  const [currentWidth, setCurrentWidth] = useState({ width: "307px" });
  const [affordance, setAffordance] = useState({
    background: "transparent"
  });

  const activeDock = useSelector(({ app }) =>
    !isSrw ? getMainActiveDock(app) : getSRWActiveDock(app)
  );

  const isMobile = useSelector(({ app }) => getIsSrwMobile(app));

  if (type === "direct") _hideDock();

  useEffect(() => {
    const isOpen =
      checklistLayout &&
      (checklistLayout.checklistOpenState || checklistLayout.openStatus);

    const shouldShowDock =
      isOpen &&
      (isSrw ? (isMobile ? isOpen.liteMobile : isOpen.lite) : isOpen.web);

    // Only use this logic for the case when the user is coming to
    // chatroom from the sign in flow or page refresh
    if (location.prev.pathname === "/signin") {
      if (!shouldShowDock && activeDock !== null) {
        // Hide the checklist if it's not supposed to be open by default
        // and it's already open
        dispatch(hideDock());
      } else if (shouldShowDock && activeDock === null) {
        // Show the checklist if it's supposed to be open by default
        // and it isn't already open
        dispatch(showDock("checklist"));
      }
    }

    if (isSrw && !shouldShowDock && activeDock === null) {
      dispatch(showSrwDock("checklist"));
    } else if (isSrw && shouldShowDock && activeDock === null) {
      dispatch(showSrwDock("checklist"));
    }
  }, [roomId, checklistLayout]);

  useEffect(() => {
    if (R.isEmpty(checklistLayout)) {
      setCurrentWidth({
        width: "307px"
      });
    } else if (checklistLayout.checklistWidth === "max") {
      const parentWidth = parentRef?.current?.clientWidth || windowSize.current;
      setCurrentWidth({
        width: `${parentWidth / 2}`
      });
    } else if (checklistLayout?.checklistWidth === "full") {
      const parentWidth = parentRef?.current?.clientWidth || windowSize.current;
      setCurrentWidth({
        width: parentWidth
      });
    } else {
      setCurrentWidth({
        width: `${
          307 *
          (checklistLayout.checklistWidth
            ? parseInt(checklistLayout.checklistWidth)
            : 1)
        }px`
      });
    }

    if (!isChatroomActive) {
      setChecklistMaxWidth(parentRef?.current?.clientWidth || 0);
      const parentWidth = parentRef?.current?.clientWidth || windowSize.current;
      setCurrentWidth({
        width: parentWidth
      });
    } else {
      switch (true) {
        case windowSize.current > 779 && windowSize.current <= 1290:
          setChecklistMaxWidth(windowSize.current * 0.28);
          break;
        case windowSize.current > 1290 && windowSize.current <= 1390:
          setChecklistMaxWidth(windowSize.current * 0.35);
          break;
        case windowSize.current > 1390 && windowSize.current <= 1490:
          setChecklistMaxWidth(windowSize.current * 0.43);
          break;
        case windowSize.current > 1490 && windowSize.current <= 1590:
          setChecklistMaxWidth(windowSize.current * 0.45);
          break;
        case windowSize.current > 1590:
          setChecklistMaxWidth(windowSize.current * 0.48);
          break;
        default:
          break;
      }
    }
  }, [
    windowSize,
    checklistMaxWidth,
    checklistOpenState.web,
    checklistLayout.checklistWidth,
    roomId,
    isChatroomActive
  ]);

  const handleFullWidth = () => {
    const maxWidthInt = Math.floor(checklistMaxWidth);
    const currentWidthInt = parseInt(currentWidth.width.replace("px", ""), 10);

    if (maxWidthInt - currentWidthInt <= 5) {
      dispatch(hideChatroomComponent());
    }
  };

  return (
    activeDock && (
      <DockContainer>
        <Resizable
          maxWidth={`${checklistMaxWidth}px`}
          minWidth={
            windowSize.current <= 779 ? `${windowSize.current}px` : "307px"
          }
          style={styles.ResizableContainer}
          handleStyles={{ left: styles.AffordanceHandle }}
          handleComponent={{
            left: (
              <Box sx={{ ...styles.ChecklistAffordance, ...affordance }}></Box>
            )
          }}
          handleClasses={{ left: "leftHandle" }}
          size={
            windowSize.current <= 779
              ? { width: `${windowSize.current}px` }
              : currentWidth
          }
          onResize={(e, direction, ref) => {
            setCurrentWidth({
              width: ref.style.width
            });
            setAffordance({ background: colors.active });
          }}
          onResizeStop={() => {
            setAffordance({
              background: "transparent",
              animation: `${fadeOut} 1 0.3s`
            });
            handleFullWidth();
          }}
          enable={{
            top: false,
            right: false,
            bottom: false,
            left: isChatroomActive && true,
            topRight: false,
            bottomRight: false,
            bottomLeft: false,
            topLeft: false
          }}
        >
          <Checklist roomId={roomId} />
        </Resizable>
      </DockContainer>
    )
  );
};

const mapStateToProps = (
  { app }: { app: AppState },
  { roomId }: { roomId: RoomId }
) => ({
  type: getChatroomType(app, roomId)
});

export default connect(mapStateToProps, {
  _hideDock: hideDock
})(Dock);
