// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import { connect } from "react-redux";
import React, { useCallback } from "react";
import * as R from "ramda";

import { AttachButton } from "./styles";
import Name from "src/containers/workflow/Name";
import InlineSVG from "src/components/InlineSVG";
import Upload from "src/containers/chatroom/Upload";
import Search from "src/containers/workflow/Search";
import * as colors from "src/styles/constants/colors";
import OutsideClickHandler from "src/components/OutsideClickHandler";
import {
  showConversationalModalWarn,
  setNewWorkflow,
  setConversationAttributes,
  toggleMemberAddDialog
} from "src/actions/chatroom";
import { setComponent } from "src/actions/chatmenu";
import useComponentPermission from "src/hooks/useComponentPermission";
import {
  Workflow as StyledWorkflow,
  ChatroomOptions as StyledMenu,
  SubMenu
} from "src/styles/chatroom";
import { iconSize } from "src/styles/constants";
import { componentPermissions } from "src/constants/roleManagement";

import type { AppState, RoomId } from "src/types";

type Props = {
  roomId: RoomId,
  outerRef: any,
  conversationModal: boolean,
  mostUsedWorkflows: Array<number>,
  _toggleMembers: Function,
  togglePopover: Function,
  _showConversationalModalWarn: Function,
  _setConversationAttributes: Function,
  _setNewWorkflow: Function,
  _setComponent: Function,
  _toggleMemberAddDialog: Function
};

const Menu = ({
  roomId,
  outerRef,
  conversationModal,
  togglePopover,
  mostUsedWorkflows,
  _showConversationalModalWarn,
  _setConversationAttributes,
  _setNewWorkflow,
  _setComponent,
  _toggleMemberAddDialog
}: Props) => {
  const handleWorkflow = useCallback(
    (id: number) => {
      // Don't show the conversation creation modal if the chatroom
      // itself is open in a modal
      if (conversationModal) {
        _showConversationalModalWarn();
      } else {
        _setConversationAttributes({ parent: roomId });
        _setNewWorkflow(id);
        _setComponent();
        togglePopover();
      }
    },
    [conversationModal, _showConversationalModalWarn, roomId, togglePopover]
  );

  const handleConversation = useCallback(
    (type: string) => {
      if (conversationModal) {
        _showConversationalModalWarn();
      } else {
        _setConversationAttributes({ type, parent: roomId });
        _setComponent();
        togglePopover();
      }
    },
    [
      roomId,
      conversationModal,
      _showConversationalModalWarn,
      _setConversationAttributes,
      togglePopover
    ]
  );

  const handleMembers = useCallback(() => {
    _toggleMemberAddDialog(
      conversationModal ? "conversation-dialog" : "chatroom-header"
    );
    togglePopover();
  }, [
    conversationModal,
    _toggleMemberAddDialog,
    togglePopover,
    showConversationalModalWarn
  ]);

  const handleClickOutside = useCallback(
    (event: any) => {
      if (outerRef && !outerRef.current.contains(event.target)) {
        togglePopover();
      }
    },
    [outerRef, togglePopover]
  );

  const chatroomCreationEnabled = useComponentPermission(
    componentPermissions.newConversation
  );

  return (
    <OutsideClickHandler onClickOutside={handleClickOutside}>
      <StyledMenu data-cy="chatroomOptions">
        {chatroomCreationEnabled && (
          <>
            <h4>{i18n.t(k.START_RELATED_CONVERSATION)}</h4>
            <ul data-cy="mostUsedTemplatesChatroom">
              {R.map(
                workflow => (
                  <li key={workflow} onClick={() => handleWorkflow(workflow)}>
                    <div>
                      <InlineSVG icon="workflow" size={iconSize.big} />
                      <span>
                        <Name id={workflow} />
                      </span>
                    </div>
                  </li>
                ),

                mostUsedWorkflows
              )}
              <li>
                <div>
                  <InlineSVG icon="chat" size={iconSize.large} />
                  <span>{i18n.t(k.OTHER)}</span>
                  <InlineSVG
                    icon="arrowRight"
                    size={iconSize.small}
                    color={colors.primary}
                  />
                </div>
                <SubMenu>
                  <StyledWorkflow>
                    <Search
                      exclude={mostUsedWorkflows}
                      handleSelect={id => handleWorkflow(id)}
                      handleConversation={handleConversation}
                    />
                  </StyledWorkflow>
                </SubMenu>
              </li>
            </ul>
            <div />
          </>
        )}
        <h4>{i18n.t(k.ADD_TO_CONVERSATION)}</h4>
        <ul>
          <li onClick={handleMembers}>
            <div>
              <InlineSVG icon="contact" size={iconSize.large} />
              <span>{i18n.t(k.MEMBER1)}</span>
            </div>
          </li>
          <li>
            <div>
              <InlineSVG icon="files" size={iconSize.large} />
              <Upload
                roomId={roomId}
                location="chat-input"
                multipleFiles
                render={openFileBrowser => (
                  <AttachButton onClick={openFileBrowser}>
                    {i18n.t(k.UPLOAD_FILES)}
                  </AttachButton>
                )}
                togglePopover={togglePopover}
              />
            </div>
          </li>
        </ul>
      </StyledMenu>
    </OutsideClickHandler>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  mostUsedWorkflows: app.workflow.mostUsed,
  conversationModal: app.conversationModal.show
});

export default connect(mapStateToProps, {
  _showConversationalModalWarn: showConversationalModalWarn,
  _setNewWorkflow: setNewWorkflow,
  _setConversationAttributes: setConversationAttributes,
  _toggleMemberAddDialog: toggleMemberAddDialog,
  _setComponent: setComponent
})(Menu);
