// @flow
import React from "react";

const Underline = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_255_331"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="14"
      height="14"
    >
      <rect
        x="0.799805"
        y="0.400024"
        width="13.2"
        height="13.2"
        fill="#D9D9D9"
      />
    </mask>
    <g mask="url(#mask0_255_331)">
      <path d="M3.5498 11.95V10.85H11.2498V11.95H3.5498ZM7.3998 9.75005C6.47397 9.75005 5.75439 9.4613 5.24105 8.8838C4.72772 8.3063 4.47105 7.54088 4.47105 6.58755V2.05005H5.8873V6.67005C5.8873 7.18338 6.01564 7.60047 6.27231 7.9213C6.52897 8.24213 6.9048 8.40255 7.3998 8.40255C7.8948 8.40255 8.27064 8.24213 8.52731 7.9213C8.78397 7.60047 8.9123 7.18338 8.9123 6.67005V2.05005H10.3286V6.58755C10.3286 7.54088 10.0719 8.3063 9.55856 8.8838C9.04522 9.4613 8.32564 9.75005 7.3998 9.75005Z" />
    </g>
  </svg>
);

export default Underline;
