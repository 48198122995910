// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import { connect } from "react-redux";
import React, { useCallback, useRef } from "react";
import * as R from "ramda";
import { useClientDimensions } from "react-client-dimensions";

import { Flex, Box } from "@chakra-ui/react";

import Item from "./Item";
import Options from "./Options";
import Tooltip from "src/components/Tooltip";
import { setAttribute } from "src/actions/chatroom";
import { setPriorityTrigger } from "src/actions/uiTriggers";
import { getChatroomPriority } from "src/reducers";
import { capitalize } from "src/utils";
import type { AppState, RoomId } from "src/types";
import appLocation from "src/constants/location";
import * as styles from "./styles";

type Props = {
  roomId: RoomId,
  _setAttribute: Function,
  value: ?string,
  _setPriorityTrigger: Function,
  location: string,
  isSrw: boolean,
  dropdown: boolean,
  activeDock: string
};

export const Priority = ({
  value,
  roomId,
  _setAttribute,
  _setPriorityTrigger,
  location,
  dropdown,
  activeDock
}: Props) => {
  const outerRef = useRef(null);
  const { vw } = useClientDimensions();

  const handleDropdown = useCallback(() => {
    _setPriorityTrigger();
  }, [_setPriorityTrigger]);

  const handleChange = useCallback(
    (newValue: string) => {
      if (newValue !== value) {
        _setAttribute(roomId, { priority: newValue }, location);
      }
      _setPriorityTrigger();
    },
    [roomId, _setAttribute, location, value, _setPriorityTrigger]
  );

  const isInfoPanelOpen = activeDock === "info";

  const isDockOpen = !R.isNil(activeDock);

  return (
    <Box
      position="relative"
      display="inline-block"
      data-cy="priority"
      cursor="pointer"
      ref={outerRef}
      fontSize="xs"
    >
      <Tooltip
        title={`${i18n.t(k.PRIORITY2)} ${value ? capitalize(value) : i18n.t(k.NORMAL)}`}
        description={i18n.t(k.SET_PRIORITY_FOR_CONVERSATION)}
      >
        <Flex
          alignItems="center"
          onClick={handleDropdown}
          sx={styles.PriorityInfo}
          tabIndex={0}
        >
          <Item value={value} dropdown={dropdown} location={location} />

          {(!isDockOpen || location === appLocation.chatroomHeader) &&
            vw > 1200 && (
              <Box ml="1" textTransform="capitalize">
                {value || "normal"}
              </Box>
            )}
        </Flex>
      </Tooltip>

      {dropdown ? (
        <Options
          value={value || i18n.t(k.NORMAL1)}
          outerRef={outerRef}
          handleChange={handleChange}
          handleClose={() => _setPriorityTrigger()}
          isInfoPanelOpen={isInfoPanelOpen}
        />
      ) : null}
    </Box>
  );
};

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  value: getChatroomPriority(app, `${props.roomId}`),
  isSrw: app.srw.isSingleResponse,
  dropdown: app.uiTriggers.priority,
  activeDock: app.activeDock.main
});

export default connect(mapStateToProps, {
  _setAttribute: setAttribute,
  _setPriorityTrigger: setPriorityTrigger
})(Priority);
