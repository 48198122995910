// @flow

import i18n from "i18next";
import k from "src/i18n/keys";
import { connect } from "react-redux";
import React, { useCallback, useRef, useEffect } from "react";
import { useDebouncedCallback } from "use-debounce";
import * as R from "ramda";

import Dropdown from "./Dropdown";
import Selection from "./Selection";
import usePrevious from "src/hooks/usePrevious";
import { searchFile, clearFileUploadDropdownError } from "src/actions/file";
import { SelectMultiple as StyledSelectMultiple } from "src/styles/dropdown";
import useBoolean from "src/hooks/useBoolean";
import { SmallText as SearchInput } from "src/styles/input";
import useInput from "src/hooks/useInput";

import { onManageView } from "src/utils";

import type { AppState } from "src/types";

type Props = {
  uploadedFileId: ?string,
  value: Array<string>,
  handleSelect: Function,
  _searchFile: Function,
  _clearFileUploadDropdownError: Function
};

const SelectMultiple = ({
  value,
  uploadedFileId,
  handleSelect,
  _searchFile,
  _clearFileUploadDropdownError
}: Props) => {
  const outerRef = useRef(null);
  const searchRef = useRef(null);
  const previousUploadedFileId = usePrevious(uploadedFileId);
  const { value: dropdown, setTrue, setFalse } = useBoolean();
  const {
    value: searchText,
    handleChange,
    setValue: setSearchText
  } = useInput("");
  const [debouncedSearchFiles] = useDebouncedCallback(_searchFile, 400);

  const handleSearch = useCallback(
    (event: any) => {
      handleChange(event);
      debouncedSearchFiles(event.target.value);
    },
    [handleChange, debouncedSearchFiles]
  );

  const handleFocus = useCallback(() => {
    setTrue();
    if (searchText.length === 0) {
      _searchFile("");
    }
  }, [setTrue, searchText, _searchFile]);

  const handleSelection = useCallback(
    (newValue: string) => {
      setFalse();
      setSearchText("");
      _searchFile("");
      handleSelect(newValue);
      if (searchRef && searchRef.current) {
        searchRef.current.focus();
      }
    },
    [handleSelect, setFalse, searchRef, setSearchText, _searchFile]
  );

  useEffect(() => {
    if (uploadedFileId && previousUploadedFileId !== uploadedFileId) {
      handleSelection(uploadedFileId);
      _clearFileUploadDropdownError();
    }
  }, [previousUploadedFileId, uploadedFileId]);

  const handleKeyDown = useCallback(
    (event: any) => {
      switch (event.key) {
        case "Backspace":
          const lastValue = R.last(value);
          if (lastValue) {
            handleSelection(lastValue);
          }
          break;
        default:
          break;
      }
    },
    [value]
  );

  return (
    <StyledSelectMultiple ref={outerRef}>
      <div>
        <Selection value={value} handleSelect={handleSelect}>
          <SearchInput
            type="text"
            placeholder={onManageView() ? "Select" : i18n.t(k.SELECT)}
            value={searchText}
            onChange={handleSearch}
            autoFocus={dropdown}
            onKeyPress={handleFocus}
            onKeyDown={handleKeyDown}
            onClick={handleFocus}
            ref={searchRef}
            autoComplete="off"
          />
        </Selection>
      </div>
      <div>
        {dropdown && (
          <Dropdown
            value={value}
            outerRef={outerRef}
            handleSelect={handleSelection}
            handleClose={setFalse}
          />
        )}
      </div>
    </StyledSelectMultiple>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  uploadedFileId: app.files.dropdownFileUpload.id
});

export default connect(mapStateToProps, {
  _searchFile: searchFile,
  _clearFileUploadDropdownError: clearFileUploadDropdownError
})(SelectMultiple);
