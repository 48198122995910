// @flow

import i18n from "i18next";
import k from "src/i18n/keys";
import { connect } from "react-redux";
import React, { useState, useRef, useCallback } from "react";

import usePopoverScroll from "src/hooks/usePopoverScroll";
import Value from "src/components/chatroom/DueDate/Value";
import { DueDate as StyledDueDate } from "src/components/chatroom/DueDate/styles";
import Calendar from "src/components/date/Calendar";
import { getChatroomHideStatus } from "src/reducers";
import autoPosition from "src/components/autoPosition";
import {
  updateChatroomFromManageView,
  updateNestedRow
} from "src/actions/workflows";
import Tooltip from "src/components/Tooltip";
import { getDueDate } from "src/utils";

import type { AppState, RoomId, ColumnId } from "src/types";

type Props = {
  value: ?string,
  roomId: RoomId,
  disabled: boolean,
  index: number,
  active: ?boolean,
  parentId: number,
  depth: number,
  columnId: ?ColumnId,
  labelWidth?: string,
  _setAttribute: Function,
  _updateNestedRow: Function
};

const CalendarSelector = autoPosition(Calendar, {
  bottomOffset: "0px",
  topOffset: "30px",
  rightOffset: "0px",
  position: "top"
});

const DueDate = ({
  value,
  roomId,
  disabled,
  index,
  active,
  depth,
  parentId,
  columnId,
  labelWidth,
  _setAttribute,
  _updateNestedRow
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const outerRef = useRef(null);
  const fieldRef = useRef(null);

  const handleDropdown = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen, setIsOpen]);

  const handleDueDate = useCallback(
    (dueDate: string) => {
      if (depth > 0) {
        _updateNestedRow(roomId, { dueDate }, index, parentId);
      } else {
        _setAttribute(
          roomId,
          {
            dueDate
          },
          index,
          columnId
        );
      }
    },
    [depth, parentId, roomId, _setAttribute, _updateNestedRow]
  );

  usePopoverScroll({
    isOpen,
    handleClose: () => {
      setIsOpen(false);
    },
    parentContainerRef: outerRef,
    offset: 24
  });

  if (disabled) {
    return <></>;
  }

  return (
    <StyledDueDate
      tabIndex="0"
      onClick={handleDropdown}
      onKeyPress={handleDropdown}
      ref={outerRef}
    >
      <Tooltip title={i18n.t(k.SET_DUE_DATE)}>
        <Value
          value={value}
          active={active}
          fieldRef={fieldRef}
          labelWidth={labelWidth}
        />
      </Tooltip>

      {isOpen ? (
        <CalendarSelector
          handleChange={handleDueDate}
          outerRef={outerRef.current}
          value={getDueDate(value)}
          handleClose={handleDropdown}
        />
      ) : null}
    </StyledDueDate>
  );
};

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  disabled: getChatroomHideStatus(app, props.templateId)
});

export default connect(mapStateToProps, {
  _setAttribute: updateChatroomFromManageView,
  _updateNestedRow: updateNestedRow
})(DueDate);
