// @flow

import React from "react";

import * as colors from "src/styles/constants/colors";

type Props = {
  size?: number,
  color?: string,
  style?: Object
};

const Report = ({ size = 50, color = colors.grey42, style = {} }: Props) => {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...style }}
    >
      <defs>
        <mask
          id="mask0_1767_1171"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width={`${size}px`}
          height={`${size}px`}
        >
          <rect
            x="0.189178"
            width={`${size}px`}
            height={`${size}px`}
            fill="#D9D9D9"
          />
        </mask>
      </defs>
      <g mask="url(#mask0_1767_1171)">
        <path
          d="M20.1267 28.3958V24.7708H38.2517V28.3958H20.1267ZM20.1267 18.7292V15.1042H38.2517V18.7292H20.1267ZM14.6892 34.4375H32.8142C33.8892 34.4375 34.8837 34.6699 35.7976 35.1347C36.7119 35.5995 37.488 36.2563 38.126 37.1049L43.6892 44.3555V10.4104C43.6892 10.1933 43.6195 10.0151 43.4801 9.87571C43.3408 9.73635 43.1625 9.66667 42.9455 9.66667H15.4329C15.2158 9.66667 15.0376 9.73635 14.8982 9.87571C14.7589 10.0151 14.6892 10.1933 14.6892 10.4104V34.4375ZM15.4329 48.3333H42.16L35.2725 39.3222C34.9688 38.9222 34.6071 38.6123 34.1874 38.3924C33.7678 38.1725 33.31 38.0625 32.8142 38.0625H14.6892V47.5896C14.6892 47.8067 14.7589 47.9849 14.8982 48.1243C15.0376 48.2637 15.2158 48.3333 15.4329 48.3333ZM42.9455 51.9583H15.4329C14.2121 51.9583 13.1788 51.5354 12.3329 50.6896C11.4871 49.8438 11.0642 48.8104 11.0642 47.5896V10.4104C11.0642 9.18958 11.4871 8.15626 12.3329 7.31042C13.1788 6.46459 14.2121 6.04167 15.4329 6.04167H42.9455C44.1663 6.04167 45.1996 6.46459 46.0454 7.31042C46.8913 8.15626 47.3142 9.18958 47.3142 10.4104V47.5896C47.3142 48.8104 46.8913 49.8438 46.0454 50.6896C45.1996 51.5354 44.1663 51.9583 42.9455 51.9583Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default Report;
