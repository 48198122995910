// @flow

import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton
} from "@chakra-ui/react";
import * as R from "ramda";

import { Field } from "src/components/Manage/Builder/Checklist/styles";
import { newTransitTypes } from "src/transit/checklist/field/types";
import {
  checklistFieldNamesEn as checklistFieldNames,
  checklistFieldIcons
} from "src/constants/display";
import Icon from "src/icons";
import * as colors from "src/styles/constants/colors";

type Props = {
  isOpen: boolean,
  onClose: Function,
  onSelect: Function
};

const unSupportedFieldTypes = [
  "revision",
  "link",
  "approval",
  "form",
  "section"
];

const supportedFieldTypes = R.difference(
  R.keys(newTransitTypes),
  unSupportedFieldTypes
);

const AddFieldModal = ({ isOpen, onClose, onSelect }: Props) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="full">
      <ModalOverlay />
      <ModalContent maxW="78vw" maxH="90vh">
        <ModalHeader bg={colors.grey28}>Add Field</ModalHeader>
        <ModalCloseButton />
        <ModalBody style={{ overflow: "scroll" }}>
          <Field.Container>
            {supportedFieldTypes.map((type, id) => (
              <Field.Card key={id} onClick={() => onSelect(type)}>
                <Field.Icon>
                  <Icon
                    type={checklistFieldIcons[type].icon}
                    width="36px"
                    height="36px"
                    fill={checklistFieldIcons[type].hexCode}
                  />
                </Field.Icon>
                <Field.Title>{checklistFieldNames[type]}</Field.Title>
                <Field.Subtitle>
                  {checklistFieldIcons[type].description}
                </Field.Subtitle>
              </Field.Card>
            ))}
          </Field.Container>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AddFieldModal;
