// @flow
import React, { useState, useEffect } from "react";

import { SortableHandle, SortableElement } from "react-sortable-hoc";
import { HStack, IconButton } from "@chakra-ui/react";

import Icon from "src/icons";
import { icons } from "src/styles";

import Dropdown from "./Dropdown";
import { IconButton as SVGButton } from "src/components/Unifize/Button/styles";
import { mapFieldIntoRow, mapFieldsBySection } from "./utils";
import type { UnifizeDropdownRow } from "./utils";

const DragHandleIcon = ({ ...restProps }) => (
  <IconButton
    display="flex"
    variant="unstyled"
    aria-label="Drag Handle"
    icon={<Icon type="dragIndicator" />}
    {...restProps}
  />
);

const DragHandle = SortableHandle(() => <DragHandleIcon />);

type Props = {
  value: ?number,
  fields: Array<number>,
  itemIndex: number,
  handleField: Function,
  addField: Function,
  removeField: Function,
  checklistFields: Array<Object>
};

const FieldSelect = SortableElement(
  ({
    value,
    fields,
    handleField,
    removeField,
    addField,
    itemIndex,
    checklistFields
  }: Props) => {
    const [elements, setElements] = useState<Array<UnifizeDropdownRow>>([]);
    const [currentElement, setCurrentElement] =
      useState<?UnifizeDropdownRow>(null);

    useEffect(() => {
      let sectionMap = mapFieldsBySection(checklistFields);
      // Map the fields into UnifizeDropdownRows (cells as [label,
      // icon])
      let mappedElements = sectionMap.flatMap(row =>
        !fields.includes(row[0].id) ? mapFieldIntoRow(row) : []
      );
      setElements(mappedElements);
      if (value) {
        let currentSection = sectionMap.find(
          section => section[0].id === value
        );
        currentSection && setCurrentElement(mapFieldIntoRow(currentSection));
      }
    }, [value, fields, checklistFields]);

    return (
      <HStack width="100%">
        <DragHandle />
        <Dropdown
          elements={elements}
          handleSelect={fieldId => handleField({ fieldId, index: itemIndex })}
          handleRemove={removeField}
          label="Select checklist field"
          selectedElement={currentElement || null}
          onChange={({ selectedItem }) => {
            if (
              selectedItem &&
              elements.length > 1 &&
              fields.slice(0, -1).indexOf(null) === -1
            ) {
              addField();
            }
          }}
          onMount={props => {
            if (!value && props?.openMenu) {
              props.openMenu();
            }
          }}
        />
        <SVGButton onClick={() => removeField(value)}>
          <img src={icons.trash} alt="delete icon" />
        </SVGButton>
      </HStack>
    );
  }
);

export default FieldSelect;
