// @flow

import styled from "@emotion/styled";

import * as colors from "src/styles/constants/colors";
import * as spacing from "src/styles/constants/spacing";

import type { ButtonProps } from "@chakra-ui/react";

import type {
  TagProps,
  InputProps,
  ModalProps,
  ModalContentProps,
  ModalBodyProps,
  ModalHeaderProps
} from "@chakra-ui/react";

export const SSOPage = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
`;

export const SSOHeaderContainer = styled.div`
  display: flex;
  padding-top: 2.438rem;
  padding-bottom: 2.125rem;
  width: 100%;
  border-bottom: 1px solid ${colors.grey2};
  height: fit-content;
  > div {
    display: flex;
    width: 100%;
    max-width: 595px;
    align-items: center;
    justify-content: space-between;
  }
`;

export const SSOHeader = styled.h1`
  height: fit-content;
  color: ${colors.inkLight};
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40.8px;
  margin: 0 0 0 1.5rem;
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: auto;
  max-width: 38.6255rem;
  height: fit-content;
  border-top: none;
  padding: 1.375rem 0 0 1.438rem;
`;

export const FieldContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 0.5rem;
  flex-direction: column;
`;

export const Label = styled.label`
  color: ${colors.dark};
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;

  > span {
    color: ${colors.red600};
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const Input = styled.input`
  width: 100%;
  height: 2rem;
  padding: 0.5rem;
  border-radius: 2px;
  border: 1px solid ${colors.grey40};
  background: ${colors.brightLight};
  :read-only {
    background: ${colors.grey1};
    cursor: not-allowed;
  }

  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem;

  &::placeholder {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem;
    color: ${colors.grey42};
  }
`;

export const Textarea = styled.textarea`
  width: 100%;
  height: 9rem;
  padding: 0.5rem;
  border-radius: 2px;
  border: 1px solid ${colors.skyDark};
  background: ${colors.brightLight};
  word-break: break-all;
  white-space: break-spaces;

  font-family: Courier !important;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem;

  &::placeholder {
    font-family: Lato !important;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem;
    color: ${colors.grey42};
  }
`;

const InputMessage = styled.span`
  color: ${colors.inkLightest};
  font-weight: 400;
  font-style: normal;
  font-size: 0.75rem;
  line-height: 1rem;
`;

export const HelperMessage = styled(InputMessage)``;

export const ErrorMessage = styled(InputMessage)`
  color: ${colors.red600};
`;

export const ButtonRow = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;

  > button {
    border-radius: 0.25rem;
    padding-top: 0.125rem;
  }
`;

export const MultiSelectContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  border: 1px solid ${colors.skyDark};
`;

export const DomainInput = styled(Input)`
  width: auto;
  flex-shrink: 1;
  border: none;
`;

export const InputField: InputProps = {
  fontSize: "0.875rem",
  width: "full",
  border: "none",
  _focus: {
    border: "none"
  }
};

export const TagContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.5rem;
  max-width: 100%;
  flex-shrink: 1;
  padding: 0 0.25rem;
`;

export const DomainTag: TagProps = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
};

export const DomainTagClose: TagProps = {
  _hover: {
    backgroundColor: colors.blackAlpha500
  }
};

export const DomainTagFocused = {
  ...DomainTagClose,
  outline: "none",
  backgroundColor: colors.red500,
  color: colors.brightLight
};

export const DropdownList = styled.div`
  max-height: 8rem;
  align-items: stretch;
  overflow: auto;
  minwidth: 16rem;
  padding: 0.25rem 0;
`;

export const MenuItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 0.25rem 0.5rem;
  background-color: ${props =>
    props.isHighlighted ? colors.lightBlue : "transparent"};
  gap: ${spacing.space_s};
  &:hover {
    background-color: ${colors.blackAlpha100};
    cursor: pointer;
  }
`;

export const MenuItemLabel = styled.p`
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem;
  margin: 0;
  padding: 0;
`;

// Modal styles
export const modal: ModalProps = {
  isCentered: true,
  scrollBehaviour: "inside"
};

export const modalContent: ModalContentProps = {
  minHeight: "30rem"
};

export const modalBody: ModalBodyProps = {
  display: "flex",
  flexDirection: "column",
  gap: "1rem"
};

export const modalHeader: ModalHeaderProps = {
  fontSize: "1.25rem",
  fontWeight: "700",
  lineHeight: "1.5rem"
};

export const GeneratedFieldsContainer = styled.div`
  background: ${colors.skyLighter};
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-radius: 0.5rem;

  > div > label {
    color: ${colors.inkDark};
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5rem;
  }

  > div > div > div > input {
    :read-only {
      color: ${colors.grey39};
      background: ${colors.brightLight};
    }
  }
`;

export const saveButton: ButtonProps = {
  ":disabled ": {
    backgroundColor: colors.grey38,
    opacity: 1
  },
  ":hover[disabled]": {
    backgroundColor: colors.grey38,
    opacity: 1
  }
};

export const copyIcon = {
  position: "absolute",
  cursor: "pointer",
  top: 4,
  right: 2
};
