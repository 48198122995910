import { combineReducers } from "redux";
import * as atypes from "src/constants/actionTypes";

const currentLanguage = (
  state = localStorage.getItem("currentLanguage") || "en",
  { type, payload }
) => {
  switch (type) {
    case atypes.CHANGE_LANGUAGE:
      return payload;
    default:
      return state;
  }
};

const language = combineReducers({
  currentLanguage
});

export const getCurrentLanguage = state => state.currentLanguage;

export default language;
