// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React, { useCallback } from "react";
import * as R from "ramda";
import type { Set } from "immutable";
import { connect } from "react-redux";

import Standard from "../StatusBadge";
import Custom from "src/components/chatroom/Status/Custom";
import { getWorkflow } from "src/reducers";
import Icons from "src/icons";
import { setCheckedStatus } from "src/actions/chatroom";
import {
  SecondaryText,
  CustomStatusMainWrapper,
  CustomStatusItemWrapper,
  CustomStatusWrapper
} from "src/components/chatroom-nav/filter/styles";
import type { AppState } from "src/types";

type Props = {
  currentStatus: string,
  isCustomStatusDisabled: boolean,
  setCurrentStatusHandler: Function,
  getCurrentWorkflow: Object,
  customStatuses: Set<number>,
  setSelectedCustomStatuses: Function,
  homeScreen?: boolean
};

const CustomStatus = ({
  currentStatus,
  isCustomStatusDisabled,
  setCurrentStatusHandler,
  getCurrentWorkflow,
  customStatuses,
  setSelectedCustomStatuses,
  homeScreen
}: Props) => {
  const active = currentStatus === "custom";

  // Renders radio button icons
  const renderRadioButton = useCallback(() => {
    if (isCustomStatusDisabled) {
      return "disabledRadioButton";
    }
    if (active) {
      return "activeRadioButton";
    }
    return "defaultRadioButton";
  }, [isCustomStatusDisabled, active]);

  // If custom status disabled do nothing
  const setStatusHandler = useCallback(() => {
    if (isCustomStatusDisabled) {
      return () => {};
    }
    return setCurrentStatusHandler("custom");
  }, [isCustomStatusDisabled, setCurrentStatusHandler]);

  const toggleCustomStatus = useCallback(
    (statusId: number) => {
      if (customStatuses.has(statusId)) {
        setSelectedCustomStatuses(customStatuses.remove(statusId));
      } else {
        setSelectedCustomStatuses(customStatuses.add(statusId));
      }
    },
    [customStatuses, setSelectedCustomStatuses]
  );

  return (
    <CustomStatusMainWrapper active={active}>
      <CustomStatusWrapper
        active={active}
        onClick={setStatusHandler}
        onkeypress={setStatusHandler}
        disabled={isCustomStatusDisabled}
        homeScreen={homeScreen}
      >
        <div>
          {i18n.t(k.CUSTOM)}
          <SecondaryText>
            {i18n.t(k.VIEW_AND_SELECT_FROM_ALL_STATU)}
          </SecondaryText>
        </div>
        <Icons type={renderRadioButton()} />
      </CustomStatusWrapper>

      {!R.equals({}, getCurrentWorkflow)
        ? getCurrentWorkflow.status.map(item => (
            <CustomStatusItemWrapper
              active={active}
              key={item.seqNo}
              onClick={() => toggleCustomStatus(item.id)}
            >
              {item.id < 0 ? (
                <Standard id={item.id} />
              ) : (
                <Custom id={item.id} />
              )}
              {customStatuses.has(item.id) ? (
                <Icons type="activeCheckbox" />
              ) : (
                <Icons type="defaultCheckbox" />
              )}
            </CustomStatusItemWrapper>
          ))
        : null}
    </CustomStatusMainWrapper>
  );
};

const mapStateToProps = ({ app }: { app: AppState }, { id }) => ({
  getCurrentWorkflow: getWorkflow(app, id)
});

export default connect(mapStateToProps, {
  _setCheckedStatus: setCheckedStatus
})(CustomStatus);
