// @flow

import React from "react";
import { useSelector } from "react-redux";

import Link from "redux-first-router-link";

import * as atypes from "src/constants/actionTypes";
import { getReportName } from "src/reducers";

type Props = {
  reportId: string,
  color: string
};

const ReportLink = ({ reportId, color }: Props) => {
  const report = useSelector(({ app }) => getReportName(app, reportId));
  if (report) {
    return (
      <Link
        key={reportId}
        to={{
          type: atypes.SET_REPORTS_REQUEST,
          payload: {
            id: reportId
          }
        }}
        style={{ color, textDecoration: "underline" }}
      >
        {report}
      </Link>
    );
  }
  return null;
};

export default ReportLink;
