// @flow

import React, { useCallback, useRef } from "react";
import { useSelector } from "react-redux";
import * as R from "ramda";

import { getAllRolesById } from "src/reducers";
import { CriteriaSelect as StyledSegmentValue } from "../CriteriaSelect/styles";
import { Value } from "./styles";
import Dropdown from "./RolesDropdown";
import useBoolean from "src/hooks/useBoolean";
import { RelativeBox } from "src/styles/box";
import { SpacedFlex } from "src/styles/box";
import Icons from "src/icons";

type Props = {
  value: Array<number>,
  handleChange: Function
};

const SelectRoles = ({ value, handleChange }: Props) => {
  const { value: dropdown, toggleBoolean } = useBoolean();
  const rolesById = useSelector(({ app }) => getAllRolesById(app)).roles;

  const outerRef = useRef();

  const handleSelect = useCallback(
    (id: number) => {
      if (R.includes(id, value)) {
        const newValue = R.reject(val => val === id, value);
        handleChange(newValue);
      } else {
        handleChange([...value, id]);
      }
    },
    [value, handleChange]
  );

  return (
    <RelativeBox ref={outerRef}>
      <StyledSegmentValue onClick={toggleBoolean}>
        {value.length === 0 && (
          <SpacedFlex>
            <Value>Select Roles</Value>
            <Icons type="caretDown" />
          </SpacedFlex>
        )}

        {value.length !== 0 && (
          <SpacedFlex>
            {value.length === 1 ? (
              <Value>
                <Value>{rolesById[value[0]].title}</Value>
              </Value>
            ) : (
              <Value>{value.length} selected</Value>
            )}
            <Icons type="caretDown" />
          </SpacedFlex>
        )}
      </StyledSegmentValue>

      {dropdown && (
        <Dropdown
          value={value}
          data={rolesById}
          outerRef={outerRef}
          handleClose={toggleBoolean}
          handleSelect={handleSelect}
        />
      )}
    </RelativeBox>
  );
};

export default SelectRoles;
