// @flow

import i18n from "i18next";
import k from "src/i18n/keys";
import { toast } from "react-toastify";
import * as R from "ramda";
import { put, takeEvery, select, call, take } from "redux-saga/effects";

import getAppState, { getUser, getLastOrg } from "src/selectors";
import * as api from "src/api/groups";
import resizeImage from "src/utils/image";
import { rsf } from "src/db";
import * as atypes from "src/constants/actionTypes";

import type { Action } from "src/types";

function* showGroups({ payload, meta }: Action): any {
  try {
    const { uid } = yield select(getUser);
    const query = (meta || {}).query || {};
    const sort = query.sort || [];

    if (!uid) {
      yield put({ type: atypes.SIGN_IN });
      yield put({
        type: atypes.SET_REQUESTED_PAGE,
        payload: {
          page: "groups",
          query: {
            ...query,
            sort: R.type(sort) === "String" ? [sort] : sort
          }
        }
      });
    } else {
      const { id } = query;

      if (id) {
        let groupsById = (yield select(getAppState)).groups.byId;

        if (!groupsById[id]) {
          yield take(atypes.SYNC_GROUPS_SUCCESS);
          groupsById = (yield select(getAppState)).groups.byId;
        }

        if (groupsById[id]) {
          yield put({
            type: atypes.SET_CURRENT_GROUP,
            payload: {
              group: groupsById[id]
            }
          });

          const search = query.search || "";

          yield put({
            type: atypes.SEARCH_GROUP_DETAILS_REQUEST,
            payload: {
              search
            }
          });
        }
      }

      yield put({
        type: atypes.SET_GROUPS_SUCCESS,
        payload
      });

      if (!id) {
        yield put({
          type: atypes.SEARCH_GROUPS_REQUEST,
          payload: query
        });
      }

      yield take(atypes.SYNC_GROUPS_SUCCESS);
      yield put({
        type: atypes.SEARCH_GROUPS_REQUEST,
        payload: query
      });
    }
  } catch (error) {
    yield put({
      type: atypes.SET_GROUPS_FAILURE,
      payload: { error }
    });
  }
}

function* watchShowGroups(): any {
  yield takeEvery(atypes.SET_GROUPS_REQUEST, showGroups);
}

function* createGroup(): any {
  try {
    const newGroup = (yield select(getAppState)).groups.modal;

    let photoUrl = null;

    if (newGroup.image) {
      const resizedImage = yield resizeImage(newGroup.image);
      photoUrl = yield call(api.uploadGroupImage, newGroup.title, resizedImage);
    }

    yield call(api.createGroup, {
      ...R.omit(["image", "error", "loading", "show", "edit"], newGroup),
      photoUrl
    });

    yield put({
      type: atypes.CREATE_GROUP_SUCCESS,
      payload: {}
    });

    yield take(atypes.SYNC_GROUPS_SUCCESS);

    toast.success(`Group ${newGroup.title} was created`);
    yield put({
      type: atypes.SET_GROUPS_REQUEST,
      payload: {}
    });
  } catch (error) {
    yield put({
      type: atypes.SET_GROUP_MODAL_ATTRIBUTES,
      payload: {
        error: "Error creating group",
        loading: false
      }
    });
    yield put({
      type: atypes.CREATE_GROUP_FAILURE,
      payload: { error }
    });
  }
}

function* watchCreateGroup(): any {
  yield takeEvery(atypes.CREATE_GROUP_REQUEST, createGroup);
}

function* editGroup(): any {
  const newGroup = (yield select(getAppState)).groups.modal;

  try {
    let { photoUrl } = newGroup;

    if (newGroup.image) {
      const resizedImage = yield resizeImage(newGroup.image);
      photoUrl = yield call(api.uploadGroupImage, newGroup.title, resizedImage);
    }

    const options = {
      title: newGroup.title,
      owners: newGroup.owners,
      members: newGroup.members,
      photoUrl,
      locked: newGroup.locked
    };

    yield call(api.editGroup, options, newGroup.id);

    const {
      payload: { groups }
    } = yield take(atypes.SYNC_GROUPS_SUCCESS);

    yield put({
      type: atypes.UPDATE_GROUP_SUCCESS,
      payload: newGroup
    });

    toast.success(`Group ${newGroup.title} was modified`);

    const { filter } = (yield select(getAppState)).groups;

    yield put({
      type: atypes.SEARCH_GROUPS_REQUEST,
      payload: filter
    });

    const currentGroup = (yield select(getAppState)).groups.current;

    if (!R.isEmpty(currentGroup)) {
      yield put({
        type: atypes.SET_CURRENT_GROUP,
        payload: {
          group: groups[newGroup.id]
        }
      });

      yield put({
        type: atypes.SEARCH_GROUP_DETAILS_REQUEST,
        payload: {}
      });
    }
  } catch (error) {
    yield put({
      type: atypes.SET_GROUP_MODAL_ATTRIBUTES,
      payload: {
        error: i18n.t(k.ERROR_MODIFYING_GROUP),
        loading: false
      }
    });
    yield put({
      type: atypes.EDIT_GROUP_FAILURE,
      payload: { error }
    });
  }
}

function* watchEditGroup(): any {
  yield takeEvery(atypes.EDIT_GROUP_REQUEST, editGroup);
}

function* searchGroups({ payload }: Action): any {
  try {
    let { byId } = (yield select(getAppState)).groups;

    if (R.isEmpty(byId)) {
      yield take(atypes.SYNC_GROUPS_SUCCESS);
      byId = (yield select(getAppState)).groups.byId;
    }

    const sortAscend: Function = (sort: string) =>
      R.sort(R.ascend(R.prop(sort)));
    const search = R.toLower(payload.search || "");

    const result = sortAscend("title")(
      R.filter(
        g => R.includes(search, R.toLower(g.title || "")),
        R.values(byId)
      )
    );

    if (payload.settings && payload.settings.peoplesTable) {
      yield put({
        type: atypes.SEARCH_UNIQUE_GROUPS_SUCCESS,
        payload: {
          result: R.map(R.prop("id"), result)
        }
      });
    } else {
      yield put({
        type: atypes.SEARCH_GROUPS_SUCCESS,
        payload: {
          groups: R.map(R.prop("id"), result)
        }
      });
    }
  } catch (error) {
    yield put({
      type: atypes.SEARCH_GROUPS_FAILURE,
      payload: { error }
    });
  }
}

function* watchSearchGroups(): any {
  yield takeEvery(atypes.SEARCH_GROUPS_REQUEST, searchGroups);
}

function* searchGroupDetails({ payload }: Action): any {
  try {
    const search = R.toLower(payload.search || "");
    const app = yield select(getAppState);
    const users = app.users.byId;
    const group = app.groups.current;

    const members = R.map(
      u => ({
        uid: u,
        name: R.toLower(users[u].displayName || users[u].email || "")
      }),
      group.members || []
    );

    const filtered = R.filter(u => R.includes(search, u.name), members);

    const result = R.map(R.prop("uid"), R.sortBy(R.prop("name"))(filtered));

    yield put({
      type: atypes.SEARCH_GROUP_DETAILS_SUCCESS,
      payload: {
        users: result
      }
    });
  } catch (error) {
    yield put({
      type: atypes.SEARCH_GROUP_DETAILS_FAILURE,
      payload: { error }
    });
  }
}

function* watchSearchGroupDetails(): any {
  yield takeEvery(atypes.SEARCH_GROUP_DETAILS_REQUEST, searchGroupDetails);
}

function* editGroupMembers({ payload }: Action): any {
  const { remove } = payload;
  try {
    const app = yield select(getAppState);
    const { members } = payload;
    const { current } = app.groups;

    const options = {
      title: current.title,
      owners: current.owners,
      members,
      photoUrl: current.photoUrl,
      locked: current.locked
    };

    yield call(api.editGroup, options, current.id);
    if (remove) {
      toast.success("Participants removed from the group");
      yield put({
        type: atypes.CLEAR_GROUP_DETAILS_SELECTION,
        payload: {}
      });
    } else {
      toast.success("Participants added to the group");
    }
  } catch (error) {
    if (remove) {
      toast.error("Error removing participants from the group");
    } else {
      toast.error("Error adding participants to the group");
    }
    yield put({
      type: atypes.EDIT_GROUP_MEMBERS_FAILURE,
      payload: { error }
    });
  }
}

function* watchEditGroupMembers(): any {
  yield takeEvery(atypes.EDIT_GROUP_MEMBERS_REQUEST, editGroupMembers);
}

function* getUserGroups({ payload }: Action): any {
  try {
    const groups = yield call(api.getUserGroups, payload.uid);

    yield put({
      type: atypes.GET_USER_GROUP_SUCCESS,
      payload: { groups, uid: payload.uid }
    });
  } catch (error) {
    yield put({
      type: atypes.GET_USER_GROUP_FAILURE,
      payload: { error }
    });
  }
}

function* watchGetUserGroups(): any {
  yield takeEvery(atypes.GET_USER_GROUP_REQUEST, getUserGroups);
}

function* syncGroup(): any {
  try {
    const orgId = yield select(getLastOrg);
    const channel = rsf.firestore.channel(`orgs/${orgId}/groups`);

    const users = {};
    const groups = {};

    while (true) {
      const snapshot = yield take(channel);
      const deletedGroups = [];

      for (const { doc, type } of snapshot.docChanges()) {
        if (type === "removed") {
          deletedGroups.push(doc.id);
          if (groups[doc.id]) {
            delete groups[doc.id];
          }
        } else {
          const group = doc.data();

          groups[doc.id] = {
            ...group,
            createdAt: group.createdAt ? group.createdAt.toDate() : null,
            updatedAt: group.updatedAt ? group.updatedAt.toDate() : null,
            id: doc.id
          };
        }
      }

      for (const group of R.keys(groups)) {
        for (const member of groups[group].members) {
          if (users[member]) {
            users[member].push(parseInt(group, 10));
          } else {
            users[member] = [parseInt(group, 10)];
          }
        }
      }

      yield put({
        type: atypes.SYNC_GROUPS_SUCCESS,
        payload: {
          groups
        }
      });

      yield put({
        type: atypes.GET_GROUPS_BY_USERS,
        payload: { users }
      });
    }
  } catch (error) {
    yield put({
      type: atypes.SYNC_GROUPS_FAILURE,
      payload: { error }
    });
  }
}

function* watchSyncGroups(): any {
  yield takeEvery(
    [atypes.LOAD_CHATROOMS_SUCCESS, atypes.SET_CURRENT_ORG_SRW],
    syncGroup
  );
}

export default [
  watchSyncGroups(),
  watchGetUserGroups(),
  watchEditGroupMembers(),
  watchSearchGroupDetails(),
  watchSearchGroups(),
  watchEditGroup(),
  watchShowGroups(),
  watchCreateGroup()
];
