// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";
import useBoolean from "src/hooks/useBoolean";
import ChatroomName from "./Name";
import Icon from "src/icons";
import {
  CaretUp,
  Count
} from "src/components/Manage/Workflow/Instances/Table/Row/ChecklistItem/styles";
import { Conversation as StyledConversation } from "./styles";

type Props = {
  value: Array<number>,
  handleClose: Function
};

const MultiSelect = ({ value, handleClose }: Props) => {
  const { value: expand, toggleBoolean: toggleExpand } = useBoolean();

  if (expand) {
    return (
      <div>
        {value.map((id, index) => (
          <StyledConversation key={id}>
            <ChatroomName id={id} handleClose={handleClose} />{" "}
            {index === 0 && (
              <CaretUp onClick={toggleExpand}>
                <Icon type="caretUp" />
              </CaretUp>
            )}
          </StyledConversation>
        ))}
      </div>
    );
  }

  return (
    <StyledConversation onClick={toggleExpand}>
      <ChatroomName id={value[0]} handleClose={() => {}} hideLink />
      {value.length > 1 && (
        <Count>
          {i18n.t(k._13)}
          {value.length - 1}
        </Count>
      )}
    </StyledConversation>
  );
};

export default MultiSelect;
