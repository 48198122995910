// @flow

import { connect, useSelector } from "react-redux";
import React, { useRef, useEffect } from "react";
import type { Header } from "@tanstack/react-table";

import {
  HeaderItem as StyledHeaderItem,
  SeqNo as StyledSeqNo,
  Title
} from "./styles";
import Resizer from "./Resizer";
import Sort from "./Filter/Sort";
import Priority from "./Filter/Priority";
import Status from "./Filter/Status";
import Date from "./Filter/Date";
import User from "./Filter/User";
import Chatroom from "./Filter/Chatroom";
import { processColumnNames } from "src/constants/display";
import useBoolean from "src/hooks/useBoolean";
import { getInstanceFilterId, getCurrentWorkflow } from "src/reducers";
import { getCanEditReportFilters } from "src/reducers/combinedSelectors";
import { updateColumnWidth } from "src/actions/fieldSizeMap";
import Icon from "src/icons";

import type { AppState, ColumnId, Action, Workflow } from "src/types";

type Props = {
  column: string,
  setFilter: Function,
  toggleSortBy: Function,
  filter: Array<any>,
  header: Header,
  currentWorkflow: ?Workflow,
  _updateColumnWidth: (columnId: ColumnId, width: string) => Action
};

const HeaderItem = ({
  column,
  setFilter,
  filter,
  toggleSortBy,
  header,
  currentWorkflow,
  _updateColumnWidth
}: Props) => {
  const parentRef = useRef(null);
  const {
    value: dropdown,
    toggleBoolean: toggleDropdown,
    setFalse: closeDropdown
  } = useBoolean();

  const id = useSelector(({ app }) => getInstanceFilterId(app));

  const isFilteringAllowed = useSelector(state =>
    getCanEditReportFilters(state)
  );

  // Open filter dropdown only if the header item is clicked
  const handleHeaderClick = e => {
    if (!isFilteringAllowed) {
      return;
    }
    toggleDropdown(e);
  };

  // Update the widths of the columns
  useEffect(() => {
    _updateColumnWidth(header.column.id, header.getSize());
  }, [header.getSize()]);

  const label = processColumnNames[column];
  switch (column) {
    case "seqNo":
      return (
        <StyledSeqNo onClick={handleHeaderClick} ref={parentRef} type={column}>
          <span>{label}</span>
          {isFilteringAllowed && <Icon type="triangleDown" />}
          {dropdown && (
            <Sort
              column={column}
              handleClose={closeDropdown}
              parentRef={parentRef}
              toggleSortBy={toggleSortBy}
            />
          )}
        </StyledSeqNo>
      );

    case "title":
      return (
        <Title
          isResizing={header.column.getIsResizing()}
          width={header.getSize()}
          onClick={handleHeaderClick}
          ref={parentRef}
          type={column}
          isFilteringAllowed={isFilteringAllowed}
        >
          <span>{currentWorkflow?.title ?? "Records"}</span>
          {isFilteringAllowed && <Icon type="triangleDown" />}
          <Resizer header={header} />
          {dropdown && (
            <Sort
              column={column}
              handleClose={closeDropdown}
              parentRef={parentRef}
              toggleSortBy={toggleSortBy}
            />
          )}
        </Title>
      );

    case "age":
      return (
        <StyledHeaderItem
          width={header.getSize()}
          isResizing={header.column.getIsResizing()}
          onClick={handleHeaderClick}
          ref={parentRef}
          type={column}
          isFilteringAllowed={isFilteringAllowed}
        >
          <span>{label}</span>
          {isFilteringAllowed && <Icon type="triangleDown" />}
          <Resizer header={header} />
          {dropdown && (
            <Sort
              column={column}
              handleClose={closeDropdown}
              id={id}
              parentRef={parentRef}
              toggleSortBy={toggleSortBy}
            />
          )}
        </StyledHeaderItem>
      );

    case "priority":
      return (
        <StyledHeaderItem
          isResizing={header.column.getIsResizing()}
          width={header.getSize()}
          onClick={handleHeaderClick}
          ref={parentRef}
          type={column}
          isFilteringAllowed={isFilteringAllowed}
        >
          <span>{label}</span>
          {isFilteringAllowed && <Icon type="triangleDown" />}
          <Resizer header={header} />
          {dropdown && (
            <Priority
              handleClose={closeDropdown}
              parentRef={parentRef}
              setFilter={setFilter}
              filter={filter}
              toggleSortBy={toggleSortBy}
            />
          )}
        </StyledHeaderItem>
      );

    case "status":
      return (
        <StyledHeaderItem
          isResizing={header.column.getIsResizing()}
          width={header.getSize()}
          onClick={handleHeaderClick}
          ref={parentRef}
          type={column}
          isFilteringAllowed={isFilteringAllowed}
        >
          <span>{label}</span>
          {isFilteringAllowed && <Icon type="triangleDown" />}
          <Resizer header={header} />
          {dropdown && (
            <Status
              handleClose={closeDropdown}
              id={id}
              parentRef={parentRef}
              setFilter={setFilter}
              filter={filter}
              toggleSortBy={toggleSortBy}
            />
          )}
        </StyledHeaderItem>
      );

    case "dueDate":
      return (
        <StyledHeaderItem
          isResizing={header.column.getIsResizing()}
          width={header.getSize()}
          onClick={handleHeaderClick}
          ref={parentRef}
          type={column}
          isFilteringAllowed={isFilteringAllowed}
        >
          <span>{label}</span>
          {isFilteringAllowed && <Icon type="triangleDown" />}
          <Resizer header={header} />
          {dropdown && (
            <Date
              column={column}
              handleClose={closeDropdown}
              parentRef={parentRef}
              setFilter={setFilter}
              filter={filter}
              toggleSortBy={toggleSortBy}
            />
          )}
        </StyledHeaderItem>
      );

    case "createdAt":
    case "updatedAt":
    case "completedAt":
      return (
        <StyledHeaderItem
          isResizing={header.column.getIsResizing()}
          width={header.getSize()}
          onClick={handleHeaderClick}
          ref={parentRef}
          type={column}
          isFilteringAllowed={isFilteringAllowed}
        >
          <span>{label}</span>
          {isFilteringAllowed && <Icon type="triangleDown" />}
          <Resizer header={header} />
          {dropdown && (
            <Date
              column={column}
              handleClose={closeDropdown}
              parentRef={parentRef}
              setFilter={setFilter}
              filter={filter}
              toggleSortBy={toggleSortBy}
            />
          )}
        </StyledHeaderItem>
      );

    case "owner":
    case "creator":
    case "members":
    case "completedBy":
      return (
        <StyledHeaderItem
          width={header.getSize()}
          isResizing={header.column.getIsResizing()}
          onClick={handleHeaderClick}
          ref={parentRef}
          type={column}
          isFilteringAllowed={isFilteringAllowed}
        >
          <span>{label}</span>
          {isFilteringAllowed && <Icon type="triangleDown" />}
          <Resizer header={header} />
          {dropdown && (
            <User
              column={column}
              handleClose={closeDropdown}
              parentRef={parentRef}
              setFilter={setFilter}
              filter={filter}
              toggleSortBy={toggleSortBy}
            />
          )}
        </StyledHeaderItem>
      );

    case "parent":
      return (
        <StyledHeaderItem
          isResizing={header.column.getIsResizing()}
          width={header.getSize()}
          onClick={handleHeaderClick}
          ref={parentRef}
          type={column}
          isFilteringAllowed={isFilteringAllowed}
        >
          <span>{label}</span>
          {isFilteringAllowed && <Icon type="triangleDown" />}
          <Resizer header={header} />
          {dropdown && (
            <Chatroom
              column={column}
              handleClose={closeDropdown}
              parentRef={parentRef}
              setFilter={setFilter}
              filter={filter}
              toggleSortBy={toggleSortBy}
            />
          )}
        </StyledHeaderItem>
      );

    default:
      return (
        <StyledHeaderItem
          isResizing={header.column.getIsResizing()}
          width={header.getSize()}
          onClick={handleHeaderClick}
          ref={parentRef}
          type={column}
          isFilteringAllowed={isFilteringAllowed}
        >
          <span>{label}</span>
          {isFilteringAllowed && <Icon type="triangleDown" />}
          <Resizer header={header} />
          {dropdown && (
            <Sort
              column={column}
              handleClose={closeDropdown}
              parentRef={parentRef}
              toggleSortBy={toggleSortBy}
            />
          )}
        </StyledHeaderItem>
      );
  }
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  id: getInstanceFilterId(app),
  currentWorkflow: getCurrentWorkflow(app)
});

export default connect(mapStateToProps, {
  _updateColumnWidth: updateColumnWidth
})(HeaderItem);
