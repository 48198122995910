import { checklistFieldNamesEn as checklistFieldNames } from "src/constants/display";

/**
 * Formats a given path by replacing the second element (field type) with
 * its corresponding field name.
 * @param {Array<string>} path - The path to be formatted.
 * @returns {Array<string>} The formatted path.
 */
export const getFormattedPath = path => {
  if (path.length < 2) return path;

  const fieldName = checklistFieldNames[path[1]];
  const formatted = [path[0], fieldName, ...path.slice(2)];

  return formatted;
};
