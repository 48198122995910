// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React, { useCallback, useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useDebouncedCallback } from "use-debounce";

import {
  Remove,
  Dropdown as StyledDropdown,
  Search,
  SearchDropdown,
  SearchResults,
  StyledAddNewUser
} from "./styles";
import Invite from "./Invite";
import AddNewUser from "./AddNewUser";
import User from "src/components/chatroom/Members/User";
import OutsideClickHandler from "src/components/OutsideClickHandler";
import Icon from "src/icons";
import type { AppState, ReactRef, UID, RoomId } from "src/types";
import { searchUsers } from "src/actions";
import { setAttribute, inviteOwner } from "src/actions/chatroom";
import { getUserSearchResult } from "src/reducers";
import useAutoPosition from "src/hooks/useAutoPosition";
import useBoolean from "src/hooks/useBoolean";
import * as R from "ramda";

type Props = {
  toggleOwner: Function,
  _inviteOwner: Function,
  outerRef: ReactRef,
  results: Array<UID>,
  _searchUsers: Function,
  _setAttribute: Function,
  roomId: RoomId,
  location: string,
  currentOwner: UID,
  isSrwMobile: boolean,
  chatroomOverlay: string,
  value: ?UID,
  isInfoPanelOpen: boolean
};

const Dropdown = ({
  _inviteOwner,
  value,
  toggleOwner,
  outerRef,
  results,
  _searchUsers,
  _setAttribute,
  roomId,
  location,
  currentOwner,
  isSrwMobile,
  chatroomOverlay
}: Props) => {
  const [search, setSearch] = useState("");
  const dropdownRef = useRef(null);
  const { top, bottom, rightOffset } = useAutoPosition({
    outerRef: dropdownRef,
    initialTop: "35px",
    initialBottom: "auto",
    topOffset: "35px",
    bottomOffset: "27px",
    initialRight: "0"
  });
  const {
    value: newUser,
    setTrue: showAddNewUser,
    setFalse: hideAddNewUser
  } = useBoolean();

  const [debouncedSearchUsers] = useDebouncedCallback(_searchUsers, 400);

  const handleKeyDown = useCallback(
    (event: any) => {
      if (event.key === "Escape") {
        toggleOwner();
      }
    },
    [toggleOwner]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown, false);
    _searchUsers("");
    return () => {
      _searchUsers("");
      document.removeEventListener("keydown", handleKeyDown, false);
    };
  }, []);

  const handleClickOutside = useCallback(
    (e: any) => {
      if (!outerRef.current.contains(e.target)) {
        toggleOwner();
        _searchUsers("");
      }
    },
    [toggleOwner]
  );

  const assignOwner = useCallback(
    (owner: ?UID) => {
      if (owner !== currentOwner) _setAttribute(roomId, { owner }, location);
      toggleOwner();
      _searchUsers("");
    },
    [roomId, currentOwner, location, toggleOwner]
  );

  const handleSearch = useCallback(e => {
    setSearch(e.target.value);
    debouncedSearchUsers(e.target.value);
  }, []);

  const handleInvite = useCallback(
    (text: string) => {
      _inviteOwner(roomId, { owner: { email: text } });
      toggleOwner();
    },
    [roomId, _inviteOwner, toggleOwner]
  );

  if (newUser) {
    return (
      <StyledDropdown ref={dropdownRef}>
        <StyledAddNewUser>
          <AddNewUser
            handleInvite={handleInvite}
            handleClose={hideAddNewUser}
            outerRef={dropdownRef}
          />
        </StyledAddNewUser>
      </StyledDropdown>
    );
  }
  return (
    <OutsideClickHandler onClickOutside={handleClickOutside}>
      <StyledDropdown
        data-cy="ownerDropdown"
        ref={dropdownRef}
        isSrwMobile={isSrwMobile}
        top={top}
        bottom={bottom}
        position={chatroomOverlay}
        rightOffset={rightOffset}
      >
        <Search
          placeholder={i18n.t(k.SEARCH_PEOPLE)}
          autoFocus
          value={search}
          onChange={handleSearch}
        />

        <SearchDropdown>
          {value && (
            <Remove
              data-cy="removeOwner"
              type="button"
              onClick={() => assignOwner(null)}
            >
              <span>{i18n.t(k.REMOVE_OWNER)}</span>
              <Icon type="deleteTrash" />
            </Remove>
          )}

          <SearchResults isSrwMobile={isSrwMobile} data-cy="ownerDropdownItems">
            {R.map(
              user => (
                <User
                  key={user}
                  uid={user}
                  addUserMenu
                  handleClick={() => assignOwner(user)}
                />
              ),

              R.filter(user => user !== currentOwner, results)
            )}
          </SearchResults>
        </SearchDropdown>
        <Invite
          text={search}
          handleInvite={handleInvite}
          handleNewUser={showAddNewUser}
        />
      </StyledDropdown>
    </OutsideClickHandler>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  results: getUserSearchResult(app),
  isSrwMobile: app.srw.isMobile
});

export default connect(mapStateToProps, {
  _searchUsers: searchUsers,
  _setAttribute: setAttribute,
  _inviteOwner: inviteOwner
})(Dropdown);
