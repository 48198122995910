// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";

import Tabs from "./Tabs";
import Close from "src/containers/Close";

type Props = {
  mine: boolean
};

const Navigation = ({ mine }: Props) => (
  <div>
    <h4>{i18n.t(k.FILES)}</h4>
    <Tabs mine={mine} />
    <Close />
  </div>
);

export default Navigation;
