// @flow

import React from "react";

import TranslationCell from "./Translation";
import PathCell from "./Path";

import { StyledCell } from "../styles";

import type { Translation } from "src/types";

type Props = {
  isEditing: boolean,
  seeMore: boolean,
  setSeeMore: Function,
  setIsEditing: Function,
  translation: Translation,
  column: string
};

const Cell = ({
  isEditing,
  setIsEditing,
  seeMore,
  setSeeMore,
  translation,
  column
}: Props) => {
  switch (column) {
    case "translated":
      return (
        <TranslationCell
          translation={translation}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          seeMore={seeMore}
          setSeeMore={setSeeMore}
        />
      );
    case "path":
      // $FlowFixMe - It will always be a Array<string> for "path" column
      return (
        <PathCell cellData={translation["path"]} area={translation.area} />
      );
    case "originalText":
      return (
        <StyledCell seeMore={!seeMore} isReadOnly={!isEditing}>
          {translation["originalText"]}
        </StyledCell>
      );
    default:
      return null;
  }
};

export default Cell;
