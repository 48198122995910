// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import ReactFC from "react-fusioncharts";
import FusionCharts from "fusioncharts";
import { Set as ImmutableSet } from "immutable";
import * as R from "ramda";

import Charts from "fusioncharts/fusioncharts.charts";
import * as ExcelExport from "fusioncharts/fusioncharts.excelexport.js";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import { updateFilter } from "src/actions/filter";
import { setNewChartDialogAttributes } from "src/actions/chart";
import {
  setPreviousChatroom,
  fetchChatroomUpdates
} from "src/actions/chatroom";
import { openHomeScreenTile } from "src/actions/homescreen";
import { showView } from "src/actions";
import { chartColors } from "src/styles/constants/colors";
import { getChartTitleForExport, getChartTitle } from "src/reducers";
import * as colors from "src/styles/constants/colors";

import type { AppState } from "src/types";

ReactFC.fcRoot(FusionCharts, Charts, FusionTheme, ExcelExport);

type Props = {
  chartRef: React$ElementRef<any>,
  title: string,
  height?: string,
  width?: string,
  type: string,
  chartId: number,
  field2: ?string,
  baseFontSize?: string,
  dataSource: Object,
  exportChartTitle: string,
  _updateFilter: Function,
  _setNewChartDialogAttributes: Function,
  _setPreviousChatroom: Function,
  _showView: Function,
  _openHomeScreenTile: Function,
  _fetchChatroomUpdates: Function
};

const Visualization = ({
  chartRef,
  title,
  height = "100%",
  width = "100%",
  type,
  chartId,
  baseFontSize = "10",
  dataSource,
  exportChartTitle,
  _updateFilter,
  _setNewChartDialogAttributes,
  _setPreviousChatroom,
  _openHomeScreenTile,
  _showView,
  _fetchChatroomUpdates
}: Props) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(true);
    }, 20);
    return () => clearTimeout(timer);
  }, [setShow]);

  let chartData = {
    type,
    dataFormat: "json",
    dataSource: {
      ...dataSource,
      chart: {
        caption: title ?? "",
        captionFontColor: colors.primary,
        captionFontSize: "16",
        captionAlignment: "left",
        scrollPosition: "top",
        exportEnabled: "1",
        exportMode: "client",
        exportFormats: "pdf",
        exportShowMenuItem: "0",
        exportFileName: exportChartTitle ?? "FusionCharts",
        ...dataSource.chart,
        baseFontSize,
        labelDisplay: "rotate",
        slantLabel: "1",
        showSum: "1",
        palettecolors: chartColors
      },
      dataset: (dataSource?.dataset || []).map(series => ({
        ...series,
        data: (series?.data || []).map(value => ({
          value: value.value,
          label: value.label,
          link: `j-openchart-${JSON.stringify({
            ...value.filter,
            chartId
          })}`
        }))
      }))
    },
    events: {
      dataplotClick: eventObj => {
        eventObj.stopPropagation();
        eventObj.preventDefault();
        eventObj.stopImmediatePropagation();
        window.openchart = (filters: string) => {
          _setNewChartDialogAttributes({ show: false });
          try {
            const currentFilter = JSON.parse(filters);
            const active =
              currentFilter.active === true
                ? "pending"
                : currentFilter.active === false
                  ? "closed"
                  : "all";
            _updateFilter({
              ...R.pick(
                ["mine", "owner", "unread", "sortBy", "overdue"],
                currentFilter
              ),
              workflow: currentFilter.templateId,
              active:
                (currentFilter.status || []).length > 0 ? "custom" : active,
              customStatuses: ImmutableSet(currentFilter.status || []),
              type: currentFilter.workflow
                ? ["workflow"]
                : [
                    "group",
                    "conversation",
                    "task",
                    "approval",
                    "direct",
                    "workflow"
                  ],

              name: "Search results",
              homescreen: true
            });

            _setPreviousChatroom();
            _showView("My Inbox");
            _openHomeScreenTile({
              type: "clicked-tile",
              tileType: "My Conversation chart"
            });
            _fetchChatroomUpdates();
          } catch (error) {
            console.error(error);
          }
        };
      }
    },
    width,
    height,
    dataLoadErrorMessage: i18n.t(k.FAILED_TO_LOAD_CHART),
    typeNotSupportedMessage: i18n.t(k.NO_DATA_TO_DISPLAY),
    dataEmptyMessage: i18n.t(k.NO_DATA_TO_DISPLAY)
  };

  return <>{show && <ReactFC ref={chartRef} {...chartData} />}</>;
};

const mapStateToProps = ({ app }: { app: AppState }, props: Props) => ({
  title: getChartTitle(app, props.chartId),
  exportChartTitle: getChartTitleForExport(app, props.chartId)
});

export default connect(mapStateToProps, {
  _updateFilter: updateFilter,
  _setPreviousChatroom: setPreviousChatroom,
  _setNewChartDialogAttributes: setNewChartDialogAttributes,
  _showView: showView,
  _openHomeScreenTile: openHomeScreenTile,
  _fetchChatroomUpdates: fetchChatroomUpdates
})(Visualization);
