// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React, { useState, memo } from "react";
import { Flex, Text, ListItem } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { ChevronUpIcon, ChevronDownIcon } from "@chakra-ui/icons";

import { getGroup } from "src/reducers";
import GroupUsers from "./GroupUsers";
import * as styles from "./styles";
import Icon from "src/icons";

import type { SelectOrUnselectParticipant } from "src/components/chatroom/Members/AddUser";

type Props = {
  id: number,
  selectParticipant: SelectOrUnselectParticipant,
  activeParticipant: boolean
};

const NewGroup = ({ id, selectParticipant, activeParticipant }: Props) => {
  const group = useSelector(({ app }) => getGroup(app, id));
  const { title, members, photoUrl } = group;

  const [showMembers, setShowMembers] = useState(false);

  const listItemStyles =
    activeParticipant || showMembers
      ? styles.ActiveParticipant
      : styles.UsersAndGroupsWrapper;

  return (
    <>
      <ListItem sx={listItemStyles} data-cy="userItem">
        <Flex
          onClick={() => selectParticipant({ type: "group", id })}
          sx={styles.NewGroup}
        >
          <Icon
            type="groupAvatar"
            text={title}
            size="sm"
            photoUrl={photoUrl}
            id={id}
          />

          <Flex sx={styles.FlexColumn}>
            <Text
              isTruncated
              sx={styles.UserAndGroupName}
              data-cy="participantDisplayName"
            >
              {title}
            </Text>
            <Text sx={styles.LightGreyText}>
              {members.length}{" "}
              {members.length > 1 ? i18n.t(k.MEMBERS) : i18n.t(k.MEMBER)}
            </Text>
          </Flex>
        </Flex>

        <Flex
          onClick={() => setShowMembers(prev => !prev)}
          sx={
            showMembers
              ? styles.ActiveGroupMemberArrowIcon
              : styles.GroupMemberArrowIcon
          }
        >
          {showMembers ? (
            <ChevronUpIcon boxSize={5} />
          ) : (
            <ChevronDownIcon boxSize={5} />
          )}
        </Flex>
      </ListItem>
      {showMembers && members.map(user => <GroupUsers key={user} id={user} />)}
    </>
  );
};

export default memo<Props>(NewGroup);
