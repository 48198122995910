// @flow

import React from "react";
import moment from "moment";

import { OldValue, ValueList } from "../styles";
import UserDiffValue from "./UserDiffValue";
import NewUserValue from "./NewUserValue";
import NameOrEmail from "src/containers/user/NameOrEmail";
import type { UsersAndGroups, UID } from "src/types";
import GroupMembersModal from "../GroupMembersModal";
import { transformUserNotification } from "src/utils/checklist";

type Props = {
  timestamp: string,
  oldValue: Array<UsersAndGroups | UID>,
  newValue: Array<UsersAndGroups | UID>
};

const Users = ({ timestamp, oldValue, newValue }: Props) => {
  const transformedOldValues = (oldValue || []).map(transformUserNotification);
  const transformedNewValues = (newValue || []).map(transformUserNotification);
  const filteredOldValues = transformedOldValues.filter(
    (oldVal: UsersAndGroups) => {
      return !transformedNewValues.some((newVal: UsersAndGroups) =>
        newVal.id ? newVal.id === oldVal.id : newVal.uid === oldVal.uid
      );
    }
  );
  return (
    <>
      {filteredOldValues.length > 0 && (
        <OldValue>
          <ValueList>
            {(filteredOldValues || []).map(value => {
              if (value.type === "user")
                return <NameOrEmail uid={value.uid} key={value.uid} />;
              else return value.id && <GroupMembersModal id={value.id} />;
            })}
          </ValueList>
        </OldValue>
      )}

      {/* This done to prevent old values repeating in notificatoin
           more details at https://app.unifize.com/conversation/8GR5Y61D2 */}
      {moment(timestamp).startOf("day").isAfter("2020-05-27") ? (
        <UserDiffValue
          newValue={transformedNewValues}
          oldValue={transformedOldValues}
        />
      ) : (
        <NewUserValue newValue={transformedNewValues} />
      )}
    </>
  );
};

export default Users;
