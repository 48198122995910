// @flow

import React from "react";
import { connect } from "react-redux";

import FieldWrapper from "src/components/Manage/Workflow/Instances/ChecklistField/styles";
import Text from "src/components/Dock/Checklist/Text";
import Number from "src/components/Dock/Checklist/Number";
import Date from "src/components/Dock/Checklist/Date";
import Conversation from "src/components/Dock/Checklist/Conversation";
import Picklist from "src/components/Dock/Checklist/Picklist";
import File from "src/components/Dock/Checklist/File";
import User from "src/components/Dock/Checklist/User";
import Pdf from "src/components/Dock/Checklist/Pdf";
import RichText from "src/components/Dock/Checklist/RichText";
import useFields from "src/components/Dock/Checklist/useFields";
import { Container, Label } from "src/components/Dock/Checklist/styles";
import { setChecklistFromManageView } from "src/actions/checklist";
import { getChecklistFieldDetails } from "src/reducers";
import { updateChecklistFromFormManageView } from "src/actions/forms";

import type {
  AppState,
  RoomId,
  ChecklistValue,
  ChecklistField
} from "src/types";

type Props = {
  checklistValue: ChecklistValue,
  roomId: RoomId,
  selectedChecklist: Object,
  details: ChecklistField,
  _setChecklistValueFromManageView: Function
};

const Field = ({
  checklistValue,
  roomId,
  selectedChecklist,
  details,
  _setChecklistValueFromManageView
}: Props) => {
  const label = details ? details.get("label") : "";
  const type = details ? details.get("type") : "";
  let FieldComp = null;

  const {
    edit,
    settings,
    value,
    handleChange,
    openEdit,
    closeEdit,
    increment,
    decrement,
    setValue
  } = useFields({
    checklistValue,
    details
  });

  const setChecklistValue = (roomid, fieldId, fieldDetail) => {
    const { value, type } = fieldDetail;
    _setChecklistValueFromManageView(
      selectedChecklist.roomId,
      {
        [fieldId]: value,
        type,
        value,
        checked: true
      },
      selectedChecklist.index,
      fieldId,
      selectedChecklist.formId
    );
  };

  const forwardProps = {
    fieldId: selectedChecklist.fieldId,
    checklistId: selectedChecklist.checklistId,
    formId: selectedChecklist.formId,
    roomId,
    details,
    checklistValue,
    setChecklistValue,
    edit,
    settings,
    value,
    handleChange,
    openEdit,
    closeEdit,
    increment,
    decrement,
    setValue
  };

  switch (type) {
    case "file":
      FieldComp = <File {...forwardProps} />;
      break;
    case "text":
      FieldComp = <Text {...forwardProps} />;
      break;
    case "number":
      FieldComp = <Number {...forwardProps} />;
      break;
    case "date":
      FieldComp = <Date {...forwardProps} />;
      break;
    case "select":
      FieldComp = <Picklist {...forwardProps} />;
      break;
    case "user":
      FieldComp = <User {...forwardProps} />;
      break;
    case "task":
    case "decision":
    case "group":
    case "workflow":
    case "conversation":
    case "chatPickList":
      FieldComp = (
        <Conversation
          {...{
            ...forwardProps,
            value: (forwardProps.value || []).map(conv =>
              typeof conv === "object" ? conv.id : conv
            )
          }}
        />
      );

      break;
    case "childConversation":
      FieldComp = (
        <Conversation
          {...{
            ...forwardProps,
            value: (forwardProps.value || []).map(conv =>
              typeof conv === "object" ? conv.id : conv
            )
          }}
          parentConversation
        />
      );

      break;
    case "pdf":
      FieldComp = <Pdf {...forwardProps} />;
      break;
    case "richtext":
      FieldComp = <RichText {...forwardProps} />;
      break;
    default:
      FieldComp = null;
      break;
  }

  return (
    <>
      <Label>{label}</Label>
      <FieldWrapper>
        <Container isFromManageView>{FieldComp}</Container>
      </FieldWrapper>
    </>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  selectedChecklist: app.checklist.selectedChecklist,
  details: getChecklistFieldDetails(
    app,
    `${app.checklist.selectedChecklist.fieldId}`
  )
});

export default connect(mapStateToProps, {
  _setChecklistValueFromManageView: updateChecklistFromFormManageView,
  _setChecklistFromManageView: setChecklistFromManageView
})(Field);
