// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React, { Component } from "react";
import * as R from "ramda";
import onClickOutside from "react-onclickoutside";

import { DropdownModal } from "./styles";
import AddChatroom from "src/containers/chatroom/creator";
import Name from "src/containers/chatroom/Name";
import Checkbox from "src/components/Checkbox.old";
import { conversationTypes } from "src/constants/display";
import { searchRoom, onManageView } from "src/utils";

import type { UnifizeChatRoom } from "src/types";

type Props = {
  rooms: Array<UnifizeChatRoom>,
  selected: Array<number>,
  outerRef: any,
  settings: {
    type: ?string,
    workflow: ?number
  },

  handleClose: Function,
  toggleRoom: Function,
  startConversation: Function,
  createChatroom: Function
};

type State = {
  search: string,
  showModal: boolean,
  matches: Array<number>
};

class Search extends Component<Props, State> {
  input: any;

  constructor(props: Props) {
    super(props);
    const { rooms, settings } = this.props;
    const { workflow, type } = settings;

    this.state = {
      search: "",
      showModal: false,
      matches: R.map(
        r => parseInt(r.id, 10),
        R.filter(searchRoom(workflow, type, ""), rooms)
      )
    };
  }

  componentDidMount() {
    if (this.input) {
      this.input.focus();
    }
  }

  handleClickOutside = (e: MouseEvent) => {
    const { handleClose, outerRef } = this.props;
    if (e && outerRef) {
      if (!outerRef.contains(e.target)) {
        handleClose();
      }
    }
  };

  toggleModal = () => {
    this.setState(prevState => ({
      showModal: !prevState.showModal
    }));
  };

  search = (e: any) => {
    const { value } = e.target;
    const { rooms, settings } = this.props;
    const { workflow, type } = settings;

    this.setState({
      search: value,
      matches: R.map(
        r => parseInt(r.id, 10),
        R.filter(searchRoom(workflow, type, value), rooms)
      )
    });
  };

  toggleRoom = (id: number) => {
    const { toggleRoom } = this.props;
    toggleRoom(parseInt(id, 10));
  };

  startConversation = (req: any) => {
    const { startConversation, createChatroom } = this.props;
    if (startConversation) {
      startConversation(req);
    } else {
      createChatroom(req);
    }

    this.toggleModal();
  };

  render() {
    const { selected, settings } = this.props;
    const { search, matches, showModal } = this.state;
    const type =
      settings || settings.type !== "related" ? settings.type : "conversation";

    return (
      <DropdownModal>
        <input
          type="text"
          value={search}
          autoComplete="off"
          placeholder="Search Conversations"
          onChange={this.search}
          ref={input => {
            this.input = input;
          }}
        />

        <ul>
          {matches
            .filter(room => !R.isEmpty(`${room}`) && !isNaN(room))
            .map(room => (
              <li key={room}>
                <Checkbox
                  id={`search${room}`}
                  handleChange={() => this.toggleRoom(room)}
                  checked={R.includes(room, selected)}
                >
                  <Name id={room} />
                </Checkbox>
              </li>
            ))}
          {matches.length === 0 ? (
            <li>
              <span>{i18n.t(k.CONVERSATION_NOT_FOUND)}</span>
            </li>
          ) : null}
        </ul>
        <button type="button" onClick={this.toggleModal}>
          {onManageView() ? "Define New" : i18n.t(k.DEFINE_NEW)}{" "}
          {conversationTypes[type || "group"][0]}
        </button>
        {showModal ? (
          <AddChatroom
            position="center"
            modalId="chatroomSearch"
            show={showModal}
            handleClose={this.toggleModal}
            settings={settings}
            modalType={type}
            heading={`${i18n.t(k.START_NEW)} ${conversationTypes[type || "group"][0]}`}
            startConversation={this.startConversation}
            pinToParent
            hidePinTo
          />
        ) : null}
      </DropdownModal>
    );
  }
}

export default onClickOutside(Search);
