// @flow

import styled from "@emotion/styled";

import * as colors from "src/styles/constants/colors";
import * as radius from "src/styles/constants/radius";

export const Action = styled.button`
  background-color: ${props =>
    props.color ? props.color : colors.purpleLighter};
  cursor: pointer;
  > svg {
    height: 1rem;
    width: 1rem;
  }
`;

export const Menu = styled.div`
  visibility: hidden;
  display: flex;
  gap: 0.25em;
`;

export const Tile = styled.div`
  background-color: ${props =>
    props.color ? props.color : colors.purpleLighter};
  border-radius: ${radius.small};
  margin-left: 0.7em;
  padding: 0.3em 1em;
  width: 100%;
  margin-bottom: 0.8em;
  border: 1px solid ${colors.brightLight};
  display: flex;
  justify-content: space-between;
  :hover,
  :focus {
    border: 1px solid ${colors.primary};
    ${Menu} {
      visibility: visible;
    }
  }
`;

export const TileWrapper = styled.li`
  width: 100%;
  display: grid;
  grid-template-columns: 20px 1fr 0.5fr;
  z-index: 4;
  > *:first-of-type {
    cursor: move;
  }
`;

export const UserSegment = styled.div`
  > div:first-of-type {
    width: 89%;
    margin: auto;
  }
`;

export const Color = styled.span`
  padding-left: 0.5em;
  color: ${colors.secondary};
`;

export const SortableList = styled.ul`
  padding-left: 2.7em;
`;
