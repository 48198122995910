// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactFC from "react-fusioncharts";
import FusionCharts from "fusioncharts";
import * as ExcelExport from "fusioncharts/fusioncharts.excelexport.js";
import * as R from "ramda";

import Loader from "src/components/Dashboard/Body/Loader";
import { setReports } from "src/actions/reports";
import { setFieldExpansion } from "src/actions/checklist";
import {
  updateInstanceFilter,
  setAllRecordsFilter,
  filterEmbeddedFields
} from "src/actions/workflows";
import {
  getChartDataLoading,
  getChartReportId,
  getChecklistFieldsById,
  getAllWorkflows,
  getAllRecords,
  getCurrentUser,
  getWorkflowInstancesById,
  getChartTitleForExport
} from "src/reducers";
import { setNewChartDialogAttributes, expandChart } from "src/actions/chart";
import { getModifiedChartData } from "src/utils/charts.js";

import Charts from "fusioncharts/fusioncharts.charts";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";

import WebWorker from "src/workers/WebWorker";
import worker from "src/workers/app.worker";

ReactFC.fcRoot(FusionCharts, Charts, FusionTheme, ExcelExport);

type Props = {
  chartId: number | string,
  chartData: Object,
  height?: string,
  width?: string,
  chartRef: React$ElementRef<any>,
  title: string,
  titleFontSize?: number
};

const Visualization = ({
  chartId,
  chartData,
  height = "100%",
  width = "100%",
  chartRef,
  title,
  titleFontSize = 16
}: Props) => {
  const dispatch = useDispatch();
  const workflow = useSelector(({ app }) => getAllWorkflows(app));
  const instanceFilter = workflow.instanceFilter;
  const allRecords = useSelector(({ app }) => getAllRecords(app));
  const fieldsById = useSelector(({ app }) => getChecklistFieldsById(app));
  const currentUser = useSelector(({ app }) => getCurrentUser(app));
  const instancesById = useSelector(({ app }) => getWorkflowInstancesById(app));
  const exportChartTitle = useSelector(({ app }) =>
    getChartTitleForExport(app, parseInt(chartId))
  );
  const chatroomAttributes = ["owner", "dueDate", "status"];
  const updatedInstances = { ...instancesById };
  const isEmbeddedField = field => {
    const splittedField = field.split(">");
    return splittedField.length > 1;
  };

  const workerData = {
    workflow,
    fieldsById: fieldsById.toJS(),
    instancesById,
    instanceFilter,
    chatroomAttributes,
    updatedInstances,
    allRecords,
    currentUserUid: currentUser?.uid
  };

  const loading = useSelector(({ app }) =>
    getChartDataLoading(app, parseInt(chartId))
  );
  const reportId = useSelector(({ app }) => getChartReportId(app, chartId));

  const [show, setShow] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(true);
    }, 20);
    return () => clearTimeout(timer);
  }, [setShow]);

  if (loading) {
    return <Loader />;
  }

  const handleDeepEmbeddedFilter = ({
    key,
    filter
  }: {
    key: string,
    filter: Object
  }) => {
    const splitKey = key.split(">");
    // Filter applied only for embedded field keys
    if (splitKey.length > 1) {
      const parentId = splitKey[0];
      dispatch(setFieldExpansion({ id: parentId, value: true }));
      dispatch(setAllRecordsFilter({ columnId: parentId, value: true }));

      const webWorker = new WebWorker(worker);
      // $FlowFixMe - Type of event listener not recognized
      webWorker.addEventListener("message", event => {
        dispatch(filterEmbeddedFields({ instances: event.data }));
      });

      webWorker.postMessage({
        ...workerData,
        payload: filter,
        allRecords: {
          ...workerData.allRecords,
          [parentId]: true
        }
      });
    }
  };

  const dataPlotClick = e => {
    window.openchart = (filters: string) => {
      e.stopPropagation();
      e.preventDefault();
      e.stopImmediatePropagation();

      dispatch(setNewChartDialogAttributes({ show: false }));
      dispatch(expandChart(chartId));

      // If the user is not on the manage view, then redirect to the
      // manage view.
      if (!location.pathname.includes("/reports")) {
        dispatch(setReports(reportId));
      }

      // If the field is an embedded field, apply deep filter
      Object.entries(JSON.parse(filters)).reduce((acc, [key, value]) => {
        if (isEmbeddedField(filters)) {
          const parentId = key.split(">")[0];
          // Constructing instance filter object for the selected field
          const filterInstance = {
            [key.replace(/>/g, "-")]: [value],
            id: instanceFilter.id,
            chartId,
            page: 1,
            reportId: reportId,
            deepFilter: [parentId]
          };
          handleDeepEmbeddedFilter({ key, filter: filterInstance });
        }
      }, {});

      const parsedFilters = Object.entries(JSON.parse(filters)).reduce(
        (acc, [key, value]) => {
          // Replace > with - on filters to match our chart form
          // filter implementation
          return { ...acc, [key.replace(/>/g, "-")]: value };
        },
        {}
      );

      dispatch(
        updateInstanceFilter(
          // Make null or empty values "null" to make it work with blank filter.
          R.map(
            value => (value === null || R.isEmpty(value) ? "null" : value),
            parsedFilters
          )
        )
      );
    };
  };

  const modifiedChartData = getModifiedChartData({
    type: chartData.type,
    chartData,
    chartId,
    onDataPlotClick: dataPlotClick,
    exportChartTitle,
    title,
    titleFontSize
  });

  const chartConfigs = {
    type: modifiedChartData.type,
    height: height,
    width: width,
    dataFormat: "json",
    dataSource: modifiedChartData.dataSource,
    events: { dataPlotClick },
    dataLoadErrorMessage: i18n.t(k.FAILED_TO_LOAD_CHART),
    typeNotSupportedMessage: i18n.t(k.NO_DATA_TO_DISPLAY),
    dataEmptyMessage: i18n.t(k.NO_DATA_TO_DISPLAY)
  };

  return <>{show && <ReactFC ref={chartRef} {...chartConfigs} />}</>;
};

export default Visualization;
