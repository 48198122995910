// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";

import MultiSelect from "./MultiSelect";

type Props = {
  value: Array<string>,
  multiple: boolean
};

const Select = ({ value, multiple }: Props) => {
  if ((value || []).length === 0) {
    return (
      <div>
        <i>{i18n.t(k.NO_VALUE)}</i>
      </div>
    );
  }

  if (multiple && value) {
    return <MultiSelect value={value} />;
  }

  return <div>{value[0]}</div>;
};

export default Select;
