// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import { connect } from "react-redux";
import React, { useCallback } from "react";
import * as R from "ramda";

import Search from "src/containers/workflow/Search";
import InlineSVG from "src/components/InlineSVG";
import WorkflowName from "src/containers/workflow/Name";
import { setComponent } from "src/actions/chatmenu";
import { getCurrentRoomId } from "src/reducers";
import {
  setConversationDialogTitle,
  showConversationalModalWarn,
  setConversationAttributes,
  setNewWorkflow
} from "src/actions/chatroom";
import autoPosition from "src/components/autoPosition";
import {
  Workflow as StyledWorkflow,
  AddPopover,
  StyledLeftSubMenu as SubMenu
} from "src/styles/chatroom";
import { iconSize } from "src/styles/constants";
import * as colors from "src/styles/constants/colors";

import type { AppState, RoomId } from "src/types";

type Props = {
  id: string,
  roomId: RoomId,
  conversationModal: boolean,
  setSubmenu: Function,
  mostUsedWorkflow: Array<number>,
  _setConversationAttributes: Function,
  _showConversationalModalWarn: Function,
  _setConversationDialogTitle: Function,
  _setNewWorkflow: Function,
  _setComponent: Function
};

const Menu = ({
  roomId,
  mostUsedWorkflow,
  id,
  conversationModal,
  _showConversationalModalWarn,
  _setConversationDialogTitle,
  _setConversationAttributes,
  _setNewWorkflow,
  setSubmenu,
  _setComponent
}: Props) => {
  const handleWorkflow = useCallback(
    (templateId: number) => {
      if (conversationModal) {
        _showConversationalModalWarn();
      } else {
        _setConversationAttributes({
          type: "workflow",
          parent: roomId
        });
        _setNewWorkflow(templateId);
        _setConversationDialogTitle(id);
      }

      setSubmenu(false);
      _setComponent();
    },
    [
      _showConversationalModalWarn,
      conversationModal,
      setSubmenu,
      id,
      _setComponent
    ]
  );

  const handleConversation = useCallback(
    (type: string) => {
      if (conversationModal) {
        _showConversationalModalWarn();
      } else {
        _setConversationAttributes({
          type,
          parent: roomId
        });
        _setConversationDialogTitle(id);
        _setComponent();
      }
      setSubmenu(false);
      setComponent();
    },
    [
      _showConversationalModalWarn,
      conversationModal,
      setSubmenu,
      roomId,
      id,
      _setComponent
    ]
  );

  return (
    <AddPopover>
      <h4>{i18n.t(k.START_RELATED_CONVERSATION)}</h4>
      <ul>
        {R.map(
          workflow => (
            <li key={workflow} onClick={() => handleWorkflow(workflow)}>
              <div>
                <InlineSVG icon="workflow" size={iconSize.large} />
                <span>
                  <WorkflowName id={workflow} />
                </span>
              </div>
            </li>
          ),

          mostUsedWorkflow
        )}
        <li>
          <div>
            <InlineSVG icon="other" size={iconSize.large} />
            <span>{i18n.t(k.OTHER)}</span>
            <InlineSVG
              icon="arrowRight"
              size={iconSize.small}
              color={colors.primary}
            />
          </div>
          <SubMenu>
            <StyledWorkflow>
              <Search
                exclude={R.take(6, mostUsedWorkflow)}
                handleSelect={workflowId => handleWorkflow(workflowId)}
                handleConversation={handleConversation}
              />
            </StyledWorkflow>
          </SubMenu>
        </li>
      </ul>
    </AddPopover>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  conversationModal: app.conversationModal.show,
  mostUsedWorkflow: app.workflow.mostUsed,
  roomId: getCurrentRoomId(app)
});

export default autoPosition(
  connect(mapStateToProps, {
    _setConversationDialogTitle: setConversationDialogTitle,
    _showConversationalModalWarn: showConversationalModalWarn,
    _setConversationAttributes: setConversationAttributes,
    _setNewWorkflow: setNewWorkflow,
    _setComponent: setComponent
  })(Menu),
  {
    bottomOffset: "36px",
    topOffset: "22px",
    rightOffset: "0px",
    leftOffset: "auto",
    position: "top"
  }
);
