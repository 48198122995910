// @flow

import styled from "@emotion/styled";

import * as colors from "src/styles/constants/colors";
import * as spacing from "src/styles/constants/spacing";
import * as radius from "src/styles/constants/radius";
import { Secondary } from "src/styles/buttons";
import { Overlay as StyledOverlay } from "src/styles/modal";

const Modalwrapper = styled.div`
  width: 520px;
  height: 112px;
  border-radius: ${radius.medium};
  background: ${colors.brightLight};
  padding: ${spacing.space_l};
  display: grid;
  align-items: center;
  justify-items: center;
`;

const Overlay = styled(StyledOverlay)`
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MessageText = styled.span`
  font-size: 13px
  line-height: 20px;
`;

const PendingText = styled.span`
  color: ${colors.secondary};
`;

const CompletedText = styled.span`
  color: ${colors.checked};
`;

const ConformButton = styled(Secondary)`
  color: ${colors.secondary};
  background: ${colors.grey0};
  border: none;
  width: 100px;
`;

export {
  Modalwrapper,
  Overlay,
  MessageText,
  PendingText,
  CompletedText,
  ConformButton
};
