// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";
import { useSelector } from "react-redux";

import useFields from "../useFields";
import Card from "./Card";
import CancelCard from "./CancelCard";

import { getChecklistValue, getChecklistFieldDetails } from "src/reducers";

import type { UID, RoomId, FieldId, Revision, Approval } from "src/types";

type RevisionsProps = {
  setFieldValue: Function,
  allowedSignatories: UID[],
  roomId: RoomId,
  fieldId: FieldId,
  disabled: boolean
};

const ApprovalRevisions = ({
  setFieldValue,
  allowedSignatories,
  roomId,
  fieldId,
  disabled
}: RevisionsProps) => {
  const checklistValue = useSelector(({ app }) =>
    getChecklistValue(app, fieldId, roomId)
  );
  const details = useSelector(({ app }) =>
    getChecklistFieldDetails(app, `${fieldId}`)
  );

  const { settings } = useFields({
    checklistValue,
    details
  });

  const { value } = checklistValue || {
    value: {
      lockChecklistFields: false,
      adhocConfig: null,
      config: null,
      completed: false,
      revisions: [],
      status: "not-started"
    }
  };

  return value.revisions.map((revision: Revision) => (
    <>
      {revision.approvals.map((approval: Approval) => (
        <Card
          key={`${revision.revisionId}-${approval.id}`}
          id={`${revision.revisionId}${i18n.t(k._5)}${approval.id}`}
          settings={settings}
          value={value}
          completed={value.completed}
          approval={approval}
          deprecated={revision.deprecated}
          handleSign={setFieldValue}
          signatories={allowedSignatories}
          roomId={roomId}
          fieldId={fieldId}
          disabled={disabled}
        />
      ))}

      {revision.deprecated &&
        revision.deprecatedBy &&
        revision.deprecatedAt && (
          <CancelCard
            author={revision.deprecatedBy}
            comment={revision.comment}
            date={revision.deprecatedAt}
          />
        )}
    </>
  ));
};

export default ApprovalRevisions;
