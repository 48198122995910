// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import { connect } from "react-redux";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  List,
  ListItem as StyledListItem,
  Input,
  Skeleton
} from "@chakra-ui/react";
import { Card } from "src/styles/card";
import useClickoutside from "src/hooks/useClickoutside";
import useAutoPosition from "src/hooks/useAutoPosition";
import useRovingFocus from "src/hooks/useRovingFocus";
import useInput from "src/hooks/useInput";
import ListItem from "./ListItem";
import * as R from "ramda";
import { searchColumns } from "src/actions/chart";
import { getChartFieldId } from "src/utils/charts";
import { SkeletonContainer, DropdownContainer } from "./styles";

type Props = {
  outerRef: any,
  value: ?Object,
  result: Array<Object>,
  dependentField?: Array<string>,
  dependentFieldList?: any,
  numberField: boolean,
  handleSelect: Function,
  handleClose: Function,
  _searchColumns: Function,
  handleSearch: ?Function,
  isForm: ?boolean,
  fieldType?: string,
  isLoading?: boolean
};

const Dropdown = ({
  outerRef,
  result,
  value,
  numberField,
  dependentField,
  dependentFieldList = [],
  handleSelect,
  handleClose,
  _searchColumns,
  handleSearch: searchHandler,
  isForm,
  fieldType,
  isLoading = false
}: Props) => {
  const [dropdownList, setDropdownList] = useState(result);
  const dropdownRef = useRef(null);
  useClickoutside({ outerRef, handleClose, disableEnter: true });
  const { currentFocus, setCurrentFocus } = useRovingFocus(result.length);
  const {
    value: searchText,
    handleChange,
    setValue: setSearchText
  } = useInput("");

  const type = numberField ? "number" : fieldType;

  const handleKeyDown = (event: any) => {
    if (event.key == "Enter") {
      handleSelect(result[currentFocus || 0]);
    }
  };

  const isHidden = (column: Object) => {
    if (!column.multiValue) {
      return false;
    }
    if (dependentField) {
      const fieldId = getChartFieldId(dependentField);
      const fieldList = dependentFieldList && R.values(dependentFieldList);
      const field = fieldList.find(field => field.id === fieldId);
      return (field && field.multiValue) || false;
    }
    return false;
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keyDown", handleKeyDown);
    };
  }, [handleKeyDown]);

  useEffect(() => {
    searchHandler ? searchHandler("") : _searchColumns("", type);
  }, []);

  const handleSearch = useCallback(
    (event: any) => {
      const query = event.target.value;
      const filtered = result.filter(field =>
        field.name.toLowerCase().includes(query.toLowerCase())
      );
      handleChange(event);
      setDropdownList(filtered);
    },
    [type, handleChange]
  );

  const handleFocus = useCallback(() => {
    if (!searchText) {
      _searchColumns("", type);
    }
  }, [type, searchText, _searchColumns]);

  const { top, bottom } = useAutoPosition({
    outerRef: dropdownRef,
    initialTop: "2.3em",
    initialBottom: "auto",
    topOffset: "2em",
    bottomOffset: "2em"
  });

  const handleField = useCallback(
    column => {
      if (column !== dependentField) {
        handleSelect(column);
        setSearchText("");
      }
    },
    [dependentField, handleSelect, setSearchText]
  );

  return (
    <Card
      position="absolute"
      w="100%"
      top={top}
      bottom={bottom}
      ref={dropdownRef}
    >
      <List p={0} m={0} w="100%">
        <StyledListItem pr={1}>
          <Input
            size="sm"
            type="search"
            p={2}
            h={8}
            mb={2}
            boxShadow="none"
            placeholder={i18n.t(k.SELECT_FIELD1)}
            value={searchText}
            onChange={handleSearch}
            autoFocus
            onKeyPress={handleFocus}
            onClick={handleFocus}
          />
        </StyledListItem>
      </List>
      {isLoading ? (
        <List sx={SkeletonContainer} spacing={1}>
          {[1, 2, 3].map(index => (
            <Skeleton height="1.5rem" key={index} />
          ))}
        </List>
      ) : (
        <List sx={DropdownContainer}>
          {dropdownList.map((column, index) => (
            <ListItem
              key={index}
              selected={value == column.id}
              handleField={handleField}
              column={column}
              hidden={isHidden(column)}
              focused={currentFocus === index}
              setCurrentFocus={setCurrentFocus}
              isForm={isForm}
            />
          ))}
        </List>
      )}
    </Card>
  );
};

export default connect(null, {
  _searchColumns: searchColumns
})(Dropdown);
