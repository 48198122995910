// @flow

import { createSelector } from "reselect";

import {
  getAllChartIds,
  getCurrentDashboardCharts,
  getComponentPermission,
  getCurrentLocation
} from "src/reducers";
import { componentPermissions } from "src/constants/roleManagement";

export const getFilteredDashboardCharts = createSelector(
  [getAllChartIds, getCurrentDashboardCharts],
  (allCharts, currentDashboardCharts) => {
    const currentDashboardChartIds = currentDashboardCharts.map(
      chart => `${chart.id}`
    );

    const filteredChartList = allCharts.filter(
      chart => !currentDashboardChartIds.includes(chart.id)
    );

    return filteredChartList;
  }
);

export const getCanEditReportFilters = createSelector(
  [
    state =>
      getComponentPermission(state.app, componentPermissions.reportFilters),
    state => getCurrentLocation(state.location)
  ],
  (componentPermission, location) => {
    // location can't be undefined, this is only for passing the tests
    // $FlowFixMe - optional chaining is not supported
    return location?.includes("report") ? componentPermission : true;
  }
);
