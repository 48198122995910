// @flow
import k from "src/i18n/keys";
import i18next from "i18next";

const vectors = [
  {
    x1: 44,
    x2: 120,
    y1: 120,
    y2: 120,
    xtext: 120,
    ytext: 170,
    stop: true,
    text: i18next.t(k.INVITE_SENT)
  },
  {
    x1: 128,
    x2: 320,
    y1: 120,
    y2: 120,
    xtext: 320,
    ytext: 170,
    stop: true,
    text: i18next.t(k.INVITE_CLICKED)
  },
  {
    x1: 328,
    x2: 520,
    y1: 120,
    y2: 120,
    xtext: 520,
    ytext: 170,
    stop: true,
    text: i18next.t(k.EMAIL_PASSWORD_REGISTRATION)
  },
  {
    x1: 528,
    x2: 720,
    y1: 120,
    y2: 120,
    xtext: 720,
    ytext: 170,
    stop: true,
    text: i18next.t(k.PHONE_DEPT_REGISTRATION)
  },
  {
    x1: 733,
    x2: 780,
    y1: 120,
    y2: 120,
    xtext: 120,
    ytext: 170,
    stop: false,
    text: ""
  },
  {
    x1: 780,
    x2: 870,
    y1: 120,
    y2: 60,
    xtext: 945,
    ytext: 65,
    text: i18next.t(k.SIGNIN_MOBILE),
    stop: true
  },
  {
    x1: 780,
    x2: 870,
    y1: 120,
    y2: 170,
    xtext: 940,
    ytext: 175,
    text: i18next.t(k.SIGNIN_WEB),
    stop: true
  }
];

export default vectors;
