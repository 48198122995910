// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React, { useEffect, useState, useCallback } from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { useDebouncedCallback } from "use-debounce";

import useEditHover from "../useEditHover";
import AddData from "../AddData";
import {
  SearchContainer,
  Search,
  UserResult as Dropdown,
  DisplayContainer,
  UserDisplay,
  Column,
  IconContainer
} from "../styles";
import { RemoveOption } from "../Search/styles";
import UserMultiSelect from "../UserMultiSelect";
import Results from "./Results";
import Invite from "./Invite";
import ImageLabel from "src/containers/user/ImageLabel/Medium";
import OutsideClickHandler from "src/components/OutsideClickHandler";
import useAutoPositionDropdown from "src/hooks/useAutoPositionDropdown";
import Icon from "src/icons";
import { searchUsers } from "src/actions";
import type { FieldId, ChecklistId, RoomId, UID, ColumnId } from "src/types";

type Props = {
  formId: ?number,
  fieldId: FieldId,
  checklistId: ChecklistId,
  roomId: RoomId,
  columnId: ColumnId,
  _searchUsers: Function,
  setChecklistValue: Function,
  promptCallback?: ?Function,
  edit: boolean,
  settings: Object,
  value: any,
  openEdit: Function,
  closeEdit: Function,
  setValue: Function,
  locked: boolean,
  isMandatory?: boolean
};

const User = ({
  formId,
  fieldId,
  checklistId,
  roomId,
  columnId,
  _searchUsers,
  setChecklistValue,
  promptCallback = null,
  settings,
  value,
  openEdit,
  closeEdit,
  edit,
  setValue,
  locked,
  isMandatory
}: Props) => {
  const [debouncedSearchUsers] = useDebouncedCallback(_searchUsers, 400);

  const { fieldRef, dropdownRef, positionUp } = useAutoPositionDropdown({
    edit
  });

  const [search, setSearch] = useState("");
  const [showInvite, setInvite] = useState(false);

  const { hover, handleMouseEnter, handleMouseLeave } = useEditHover();

  useEffect(() => {
    if (edit) {
      setSearch("");
      _searchUsers("");
    }
  }, [edit]);

  const multiple: boolean = settings && settings.multiple;
  const placeholder: string =
    settings && settings.placeholder
      ? settings.placeholder
      : `Search user${multiple ? "s" : ""}`;

  const handleChange = useCallback(
    (e: any) => {
      setSearch(e.target.value);
      const { value } = e.target;
      debouncedSearchUsers(value);
    },
    [debouncedSearchUsers]
  );

  const selectUser = useCallback(
    (user: UID) => {
      if (multiple) {
        const newValue = [...(value || []), user];
        setValue(newValue);
        setChecklistValue({
          roomId,
          id: fieldId,
          value: {
            value: newValue,
            type: "user",
            checked: true
          },
          progress: true,
          formId,
          columnId
        });
      } else {
        setValue(user);
        setChecklistValue({
          roomId,
          id: fieldId,
          value: {
            value: user,
            type: "user",
            checked: true
          },
          progress: true,
          formId,
          columnId
        });
      }
      if (promptCallback) promptCallback();
      closeEdit();
    },
    [formId, multiple, value, columnId, roomId, fieldId, promptCallback]
  );

  const removeUser = useCallback(
    (user: UID) => {
      const newValue = R.map(
        value =>
          typeof value === "string"
            ? { type: "user", uid: value }
            : { type: "group", id: value },
        R.filter(val => val !== user, value)
      );

      setChecklistValue({
        roomId,
        id: fieldId,
        value: {
          value: newValue,
          type: "user",
          checked: newValue.length > 0
        },
        progress: true,
        formId,
        columnId
      });
    },
    [formId, value, roomId, fieldId, columnId]
  );

  const setNull = useCallback(() => {
    setChecklistValue({
      roomId,
      id: fieldId,
      value: {
        value: null,
        type: "user",
        checked: false
      },
      progress: true,
      formId,
      columnId
    });
  }, [formId, columnId]);

  const renderEdit = () => (
    <OutsideClickHandler onClickOutside={closeEdit}>
      {showInvite ? (
        <Invite
          search={search}
          multiple={multiple}
          setInvite={setInvite}
          fieldId={fieldId}
          roomId={roomId}
          checklistId={checklistId}
          selected={value ?? []}
          setSearch={setSearch}
          handleClose={closeEdit}
        />
      ) : (
        <SearchContainer ref={fieldRef}>
          <Search
            value={search}
            onChange={handleChange}
            placeholder={placeholder}
            autoFocus
          />

          <Dropdown positionUp={positionUp} ref={dropdownRef}>
            {value && (value || []).length > 0 && (
              <RemoveOption onClick={setNull}>
                {i18n.t(k.REMOVE)}
                <Icon type="checklistRemove" />
              </RemoveOption>
            )}
            <Results
              search={search}
              multiple={multiple}
              selectUser={selectUser}
              setInvite={setInvite}
              fieldId={fieldId}
              roomId={roomId}
              checklistId={checklistId}
              selected={value ?? []}
              handleClose={closeEdit}
            />
          </Dropdown>
        </SearchContainer>
      )}
    </OutsideClickHandler>
  );

  if (multiple) {
    return (
      <Column>
        {(value || []).map((user, userIndex) => (
          <DisplayContainer key={userIndex} disabled={locked}>
            <UserMultiSelect
              user={typeof user === "string" ? user : user.uid}
              removeUser={removeUser}
            />
          </DisplayContainer>
        ))}
        {edit ? (
          renderEdit()
        ) : (
          <AddData
            disabled={locked}
            type="user"
            handleClick={openEdit}
            isMandatory={isMandatory}
          />
        )}
      </Column>
    );
  }

  if (edit) return <>{renderEdit()}</>;

  if ((value || []).length > 0) {
    if (typeof value === "string") value = [value];
    return (
      <DisplayContainer disabled={locked}>
        <UserDisplay
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <ImageLabel
            uid={typeof value[0] === "string" ? value[0] : value[0]?.uid}
          />

          <IconContainer>
            {!locked && hover && (
              <Icon type="checklistEdit" handleClick={openEdit} />
            )}
          </IconContainer>
        </UserDisplay>
      </DisplayContainer>
    );
  }
  return (
    <AddData
      disabled={locked}
      type="user"
      handleClick={openEdit}
      isMandatory={isMandatory}
    />
  );
};

export default connect(null, {
  _searchUsers: searchUsers
})(User);
