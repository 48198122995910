// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";
import { useSelector } from "react-redux";
import { Banner } from "src/components/Unifize";
import AdHocParticipants from "./AdHocParticipants";
import type { UnifizeUser } from "src/types";
import {
  getUsersFromIds,
  getAllowedSignatories,
  getAllowedCancellers
} from "src/reducers";

import type {
  FieldId,
  RoomId,
  RevisionFieldSettingsv2,
  ApprovalFieldSettingsV2
} from "src/types";

type Props = {
  path: string,
  settings: RevisionFieldSettingsv2 | ApprovalFieldSettingsV2,
  roomId: RoomId,
  fieldId: FieldId
};

const AdHocDetails = ({ fieldId, roomId, settings, path }: Props) => {
  const { [path]: value = {} } = settings;
  const { preventRequester = false } = value;
  const allowedSignatories = useSelector(({ app }) =>
    getAllowedSignatories(app, fieldId, roomId)
  );
  const allowedCancellers = useSelector(({ app }) =>
    getAllowedCancellers(app, fieldId, roomId)
  );

  const selectedUsers: Array<UnifizeUser> =
    useSelector(({ app }) => getUsersFromIds(app, allowedSignatories)) || [];
  const cancellers: Array<UnifizeUser> =
    useSelector(({ app }) => getUsersFromIds(app, allowedCancellers)) || [];

  return (
    <>
      {preventRequester && (
        <Banner message={i18n.t(k.PREVENT_REQUESTER_MESSAGE)} />
      )}
      <AdHocParticipants
        users={path === "cancellers" ? cancellers : selectedUsers}
        path={path}
      />
    </>
  );
};

export default AdHocDetails;
