// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React, { Component } from "react";
import * as R from "ramda";

import { connect } from "react-redux";
import Sort from "./Sort";
import Status from "./Status";
import Owner from "./Owner";
import Everyone from "./Everyone";
import Unread from "./Unread";
import Archived from "./Archived";
import SaveList from "src/components/chatroom-nav/filter/SaveList";
import Icons from "src/icons";
import { Body as FormBody, CloseOutline } from "src/styles/form";
import { initialFilterState } from "src/reducers/chatRooms";
import Cancelled from "./Cancelled";
import {
  showSaveFilterModal,
  showSortByModal,
  showStatusModal,
  setFilter
} from "src/actions/filter";
import { showView } from "src/actions";
import { clear as clearActiveFilter } from "src/actions/activeFilter";
import { getCurrentFilter } from "src/reducers";

import type { CurrentFilter, AppState } from "src/types";

type Props = {
  showFilter: boolean,
  _clearActiveFilter: Function,
  _hideFilterDialog: Function,
  _showSaveFilterModal: Function,
  _showSortByModal: Function,
  _showStatusModal: Function,
  _setFilter: Function,
  filter: CurrentFilter,
  _showView: Function
};

type State = {
  defaultFilter: ?CurrentFilter
};

class Filter extends Component<Props, State> {
  state = {
    defaultFilter: null
  };

  componentDidMount() {
    const { filter } = this.props;
    const isMyConversations = filter.name === "My Inbox";
    if (!isMyConversations) {
      this.setState({ defaultFilter: filter });
    }
  }

  handleClickOutside(e: MouseEvent) {
    e.stopPropagation();
    const { _hideFilterDialog } = this.props;
    _hideFilterDialog();
  }

  resetFilter = (e: any) => {
    e.preventDefault();
    const { _clearActiveFilter, _setFilter, _showView } = this.props;
    const { defaultFilter } = this.state;
    _setFilter(defaultFilter, null, "My Inbox");
    _showView("My Inbox");
    _clearActiveFilter();
  };

  render() {
    const {
      _showSaveFilterModal,
      _showSortByModal,
      _showStatusModal,
      showFilter,
      filter: { text },
      filter
    } = this.props;

    if (!showFilter) {
      return <></>;
    }

    const isQuickFilter = R.equals(filter, initialFilterState);
    return (
      <FormBody>
        {!isQuickFilter ? (
          <CloseOutline outline onClick={this.resetFilter}>
            <Icons type="closeFilter" />
            <span>{i18n.t(k.CLEAR)}</span>
          </CloseOutline>
        ) : null}
        <Unread />
        <Owner />
        <Sort handleClick={_showSortByModal} />
        <Status handleClick={_showStatusModal} />
        <Everyone />
        <Cancelled />
        <Archived />
        {!isQuickFilter || text ? (
          <SaveList handleClick={_showSaveFilterModal} />
        ) : null}
      </FormBody>
    );
  }
}

const mapStateToProps = ({ app }: { app: AppState }) => ({
  showFilter: app.chatRooms.filters.show,
  filter: getCurrentFilter(app)
});

export default connect(mapStateToProps, {
  _clearActiveFilter: clearActiveFilter,
  _showSaveFilterModal: showSaveFilterModal,
  _showSortByModal: showSortByModal,
  _showStatusModal: showStatusModal,
  _setFilter: setFilter,
  _showView: showView
})(Filter);
