// @flow

import i18n from "i18next";
import k from "src/i18n/keys";
import { connect, useSelector } from "react-redux";
import React from "react";
import * as R from "ramda";

import User from "./User";
import Parent from "./Parent";
import Status from "./Status";
import Priority from "./Priority";
import Checklist from "./Checklist";
import Date from "./Date";

import WebWorker from "src/workers/WebWorker";
import worker from "src/workers/app.worker.js";

import {
  setManageViewFilter,
  filterEmbeddedFields
} from "src/actions/workflows";
import { getWorkerData } from "src/reducers";
import { getCanEditReportFilters } from "src/reducers/combinedSelectors";

type Props = {
  column: string,
  value: any,
  _filterEmbeddedFields: Function,
  removeFilter: Function,
  fullColumnId: string
};

const FilterItem = ({
  column,
  value,
  _filterEmbeddedFields,
  removeFilter,
  fullColumnId
}: Props) => {
  const workerData = useSelector(({ app }) => getWorkerData(app));

  const {
    workflow,
    fieldsById,
    instancesById,
    filters,
    chatroomAttributes,
    updatedInstances,
    allRecords
  } = workerData;

  const isFilteringAllowed = useSelector(state =>
    getCanEditReportFilters(state)
  );

  const clearFilter = () => {
    if (!isFilteringAllowed) return;
    // $FlowFixMe
    const parentColumnId = fullColumnId?.split("-")[0] || column?.split("-")[0];
    if (allRecords[parentColumnId || parseInt(parentColumnId)]) {
      const webWorker = new WebWorker(worker);
      // $FlowFixMe- Flow type infer error, type of event listener not clear
      webWorker.addEventListener("message", event => {
        _filterEmbeddedFields({ instances: event.data });
      });

      const workerPayload = {
        workflow,
        fieldsById: fieldsById.toJS(),
        instancesById,
        filters,
        chatroomAttributes,
        updatedInstances,
        allRecords,
        payload: {
          ...filters,
          [fullColumnId ?? column]: R.reject(
            R.equals(value),
            filters[fullColumnId ?? column]
          )
        }
      };

      webWorker.postMessage(workerPayload);
    }
    removeFilter(fullColumnId ?? column, value);
  };

  let blanksFilterText = null;
  if (value === "null" || value === "notnull") {
    blanksFilterText = value === "null" ? i18n.t(k.BLANK) : i18n.t(k.NON_BLANK);
  }

  if (!isNaN(column)) {
    return (
      <Checklist
        column={column}
        value={value}
        handleClose={clearFilter}
        blanksFilterText={blanksFilterText}
        fullColumnId={fullColumnId ?? column}
      />
    );
  }

  switch (column) {
    case "creator":
    case "owner":
    case "members":
    case "completedBy":
      return (
        <User
          uid={value}
          column={fullColumnId ?? column}
          handleClose={clearFilter}
          blanksFilterText={blanksFilterText}
        />
      );

    case "parent":
      return (
        <Parent
          id={value}
          column={column}
          handleClose={clearFilter}
          blanksFilterText={blanksFilterText}
        />
      );

    case "status":
      return (
        <Status
          id={value}
          column={fullColumnId ?? column}
          handleClose={clearFilter}
          blanksFilterText={blanksFilterText}
        />
      );

    case "priority":
      return (
        <Priority value={value} column={column} handleClose={clearFilter} />
      );

    case "createdAt":
    case "completedAt":
    case "updatedAt":
    case "dueDate":
      return (
        <Date
          value={value}
          column={fullColumnId ?? column}
          handleClose={clearFilter}
          blanksFilterText={blanksFilterText}
        />
      );

    default:
      return <></>;
  }
};

export default connect(null, {
  _setManageViewFilter: setManageViewFilter,
  _filterEmbeddedFields: filterEmbeddedFields
})(FilterItem);
