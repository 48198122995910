// @flow

import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";

import Tooltip from "src/components/Tooltip";
import { useLanguage } from "src/contexts/LanguageContext";
import Icon from "src/icons";
import useClickOutside from "src/hooks/useClickoutside";
import { getUserPermissions } from "src/reducers";
import {
  StyledCell,
  TranslationEditInput,
  TranslationCellWrapper,
  SeeMore
} from "../styles";
import { languagePermission } from "src/constants";

import type { Translation } from "src/types";

type Props = {
  isEditing: boolean,
  seeMore: boolean,
  setSeeMore: Function,
  setIsEditing: Function,
  translation: Translation
};

const TranslationCell = ({
  isEditing,
  setIsEditing,
  seeMore,
  setSeeMore,
  translation
}: Props) => {
  const ref = useRef();
  const { refreshTranslation, updateTranslation } = useLanguage();
  const [translatedValue, setTranslatedValue] = useState(
    translation["manualTranslation"] || translation["autoTranslation"]
  );
  const [isContentOverflowing, setIsContentOverflowing] = useState(false);
  const [lineCount, setLineCount] = useState(1);

  const cellData =
    translation["manualTranslation"] || translation["autoTranslation"];

  // TODO: Ideally this component shouldn't receive all permissions.
  const userPermissions = useSelector(({ app }) => getUserPermissions(app));

  const { resourcePermissions } = userPermissions;
  const isEditingAllowed = resourcePermissions.includes(
    languagePermission.edit
  );

  useClickOutside({
    outerRef: ref,
    handleClose: () => {
      setIsEditing(false);
    },
    disableEnter: true
  });

  const activateEdit = () => {
    const element = ref.current;

    if (element) {
      const lineHeight = parseInt(getComputedStyle(element).lineHeight, 10);
      const lines = Math.ceil(element.scrollHeight / lineHeight);
      setLineCount(Math.min(lines, 7));
      setIsEditing(true);
    }
  };

  const toggleSeeMore = e => {
    e.stopPropagation();
    setSeeMore(prev => !prev);
  };

  const handleChange = e => {
    setTranslatedValue(e.target.value);
  };

  const handleKeyUp = async e => {
    if (e.scrollHeight > e.clientHeight) {
      e.style.height = `${e.scrollHeight}px`;
    }

    if (e.key === "Enter") {
      // If an empty translation is being saved use the manual translation
      // to fill the cell
      if (e.target.value.trim()) {
        setTranslatedValue(e.target.value.trim());
      } else {
        setTranslatedValue(translation["autoTranslation"]);
      }
      setIsEditing(false);
      await updateTranslation(translation.id, e.target.value.trim());
    }
  };

  const handleRefreshTranslation = async e => {
    e.stopPropagation();

    await refreshTranslation(translation.id);
  };

  useEffect(() => {
    if (ref.current) {
      const element = ref.current;
      const lineHeight = parseInt(getComputedStyle(element).lineHeight, 10);
      const lines = Math.ceil(element.scrollHeight / lineHeight);
      if (lines > 3) {
        setIsContentOverflowing(true);
      } else {
        setIsContentOverflowing(false);
      }
    }
  }, [cellData, isEditing]);

  if (isEditingAllowed && isEditing) {
    return (
      <TranslationEditInput
        autoFocus={true}
        ref={el => {
          ref.current = el;
          // Set cursor to end when input is focused
          if (el) {
            el.selectionStart = el.selectionEnd = translatedValue?.length || 0;
          }
        }}
        onKeyUp={handleKeyUp}
        value={translatedValue}
        onChange={handleChange}
        rows={lineCount}
        maxRows={6}
      />
    );
  }

  return (
    <StyledCell
      ref={ref}
      onClick={activateEdit}
      seeMore={!seeMore}
      isReadOnly={!isEditing}
    >
      {!isContentOverflowing ? (
        <TranslationCellWrapper>
          <Tooltip
            title="Refresh translation"
            placement="top-end"
            hasArrow={false}
          >
            <Icon
              type="translation"
              color="black"
              onClick={handleRefreshTranslation}
            />
          </Tooltip>
          <span>{cellData}</span>
        </TranslationCellWrapper>
      ) : (
        <TranslationCellWrapper seeMore={!seeMore}>
          <Tooltip
            title="Refresh translation"
            placement="top-end"
            hasArrow={false}
          >
            <Icon
              type="translation"
              color="black"
              onClick={handleRefreshTranslation}
            />
          </Tooltip>
          <span>{cellData}</span>
          <SeeMore seeMore={!seeMore} onClick={toggleSeeMore}>
            {!seeMore ? "... See more" : "\u00A0See less"}
          </SeeMore>
        </TranslationCellWrapper>
      )}
    </StyledCell>
  );
};

export default TranslationCell;
