// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";
import Link from "redux-first-router-link";

import * as atypes from "src/constants/actionTypes";
import { TabArea, Tab } from "src/styles";

type Props = {
  mine: boolean
};

const Tabs = ({ mine }: Props) => {
  if (mine) {
    return (
      <TabArea>
        <Tab active={mine}>{i18n.t(k.MY_FILES)}</Tab>
        <Link to={{ type: atypes.SET_FILE_REQUEST, payload: {} }}>
          <Tab>{i18n.t(k.ALL_FILES)}</Tab>
        </Link>
      </TabArea>
    );
  }

  return (
    <TabArea>
      <Link
        to={{
          type: atypes.SET_FILE_REQUEST,
          payload: {
            query: {
              mine: true
            }
          }
        }}
      >
        <Tab>{i18n.t(k.MY_FILES)}</Tab>
      </Link>
      <Tab active={!mine}>{i18n.t(k.ALL_FILES)}</Tab>
    </TabArea>
  );
};

export default Tabs;
