// @flow

import React from "react";

import LowIcon from "src/icons/Low";
import LowIconActive from "src/icons/LowIconActive";
import NormalIcon from "src/icons/Normal";
import NormalIconActive from "src/icons/NormalIconActive";
import HighIcon from "src/icons/High";
import HighIconActive from "src/icons/HighIconActive";
import CriticalIcon from "src/icons/Critical";
import CriticalIconActive from "src/icons/CriticalIconActive";
import appLocation from "src/constants/location";

type Props = {
  value: ?string,
  dropdown?: boolean,
  location?: string
};

const Item = ({ value, dropdown, location }: Props) => {
  switch (value) {
    case "low":
      return dropdown && location === appLocation.chatroomHeader ? (
        <LowIconActive h={4} w={5} />
      ) : (
        <LowIcon h={4} w={5} />
      );

    case "normal":
      return dropdown && location === appLocation.chatroomHeader ? (
        <NormalIconActive h={4} w={5} />
      ) : (
        <NormalIcon h={4} w={5} />
      );

    case "high":
      return dropdown && location === appLocation.chatroomHeader ? (
        <HighIconActive h={4} w={5} />
      ) : (
        <HighIcon h={4} w={5} />
      );

    case "critical":
      return dropdown && location === appLocation.chatroomHeader ? (
        <CriticalIconActive h={4} w={5} />
      ) : (
        <CriticalIcon h={4} w={5} />
      );

    default:
      return dropdown && location === appLocation.chatroomHeader ? (
        <NormalIconActive h={4} w={5} />
      ) : (
        <NormalIcon h={4} w={5} />
      );
  }
};

export default Item;
