// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import { connect } from "react-redux";
import React, { useRef, useCallback } from "react";

import { AddSection as StyledAddSection } from "../styles";
import Dropdown from "./Dropdown";
import {
  Dot1,
  Dot2,
  Dot3,
  Loader as StyledLoader
} from "src/components/ButtonLoader/styles";
import { createSection } from "src/actions/orgSettings";
import useBoolean from "src/hooks/useBoolean";

type Props = {
  loading: boolean,
  _createSection: Function
};

const AddSection = ({ loading, _createSection }: Props) => {
  const { value: dropdown, toggleBoolean: toggleDropdown } = useBoolean();
  const outerRef = useRef(null);

  const handleCreate = useCallback(
    (position: string) => {
      if (!loading) {
        _createSection({ title: "Untitled", position });
        toggleDropdown();
      }
    },
    [loading, _createSection, toggleDropdown]
  );

  return (
    <StyledAddSection
      type="button"
      ref={outerRef}
      loading={loading}
      onClick={toggleDropdown}
    >
      {loading ? (
        <StyledLoader>
          <Dot1 />
          <Dot2 />
          <Dot3 />
        </StyledLoader>
      ) : (
        <>{i18n.t(k.ADD_SECTION)}</>
      )}
      {dropdown && (
        <Dropdown
          handleCreate={handleCreate}
          outerRef={outerRef}
          handleClose={toggleDropdown}
        />
      )}
    </StyledAddSection>
  );
};

export default connect(null, {
  _createSection: createSection
})(AddSection);
