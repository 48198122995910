// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import { connect } from "react-redux";
import React, { useState, useCallback } from "react";
import Modal from "react-modal";

import Icon from "src/components/Icon";
import { Small as StyledModal, Centered } from "src/styles/modal";
import { Button, Secondary } from "src/styles/buttons";
import { setAttribute } from "src/actions/chatroom";
import { Comments as StyledComments } from "src/styles/chatroom";
import { getActiveStatus, getTemplateId } from "src/reducers";

import type { AppState, RoomId } from "src/types";

type Props = {
  showModal: boolean,
  roomId: RoomId,
  location: string,
  status: number,
  _setAttribute: Function,
  handleClose: Function,
  isMobile: boolean,
  active: boolean
};

const Comment = ({
  showModal,
  roomId,
  location,
  status,
  _setAttribute,
  handleClose,
  isMobile,
  active
}: Props) => {
  const [comment, setComment] = useState("");

  const handleChange = useCallback(
    (e: any) => {
      const { value } = e.target;
      setComment(value);
    },
    [setComment]
  );

  const handleSubmit = useCallback(
    (e: any) => {
      e.stopPropagation();
      _setAttribute(
        roomId,
        {
          status,
          comment
        },
        location
      );
      handleClose();
    },
    [location, status, roomId, comment, _setAttribute, handleClose]
  );

  const handleSkip = useCallback(
    (e: any) => {
      e.stopPropagation();
      _setAttribute(
        roomId,
        {
          status,
          active
        },
        location
      );
      handleClose();
    },
    [location, status, roomId, _setAttribute, handleClose]
  );

  const renderModal = () => (
    <Modal
      isOpen={showModal}
      onRequestClose={handleClose}
      parentSelector={() => document.querySelector("#comments-modal")}
    >
      <StyledComments isMobile={isMobile}>
        <h2>
          {i18n.t(k.ADDITIONAL_COMMENTS)}
          <Icon icon="close" onClick={handleClose} />
        </h2>
        <textarea
          data-cy="statusComments"
          placeholder={i18n.t(k.ENTER_ADDITIONAL_COMMENTS)}
          value={comment}
          onChange={handleChange}
          autoFocus
        />

        <div>
          <Button
            data-cy="additonalComments"
            type="button"
            disabled={comment.length === 0}
            onClick={handleSubmit}
          >
            {i18n.t(k.SUBMIT1)}
          </Button>
          <Secondary type="button" onClick={handleSkip}>
            {i18n.t(k.SKIP)}
          </Secondary>
        </div>
      </StyledComments>
    </Modal>
  );

  if (isMobile) {
    return <Centered id="comments-modal">{renderModal()}</Centered>;
  }

  return <StyledModal id="comments-modal">{renderModal()}</StyledModal>;
};

const mapStateToProps = ({ app }: { app: AppState }, { roomId, status }) => {
  const templateId = getTemplateId(app, `${roomId}`);
  return {
    isMobile: app.srw.isMobile,
    active: getActiveStatus(app, `${templateId}`, `${status}`)
  };
};

export default connect(mapStateToProps, { _setAttribute: setAttribute })(
  Comment
);
