// @flow

import styled from "@emotion/styled";

import { Status as Badge } from "src/styles/badge";
import { DropdownMenuSmall } from "src/styles/dropdown.old";
import * as spacing from "src/styles/constants/spacing";
import * as colors from "src/styles/constants/colors";

const Creator = styled.td`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 15vw;
  cursor: pointer;
  > svg {
    visibility: hidden;
    position: relative;
    top: 4px;
  }
  :hover {
    > svg {
      visibility: visible;
    }
  }
`;

const Menu = styled(DropdownMenuSmall)`
  top: 4.7em;
  right: 0em;
`;

const Title = styled.td`
  a {
    display: flex;
    width: 25vw;
    white-space: nowrap;
    :focus {
      color: ${colors.unifizeBlue};
      outline: none;
      font-weight: bold;
    }

    :hover {
      > span > svg {
        visibility: visible !important;
      }
    }
  }
`;

const IconContainer = styled.span`
  > svg {
    visibility: hidden;
    cursor: pointer;
    position: relative;
    left: 8px;
    top: -2px;
    margin-right: 12px;
  }
`;

const New = styled(Badge)`
  display: inline-block;
  font-weight: normal;
  line-height: 1.5;
  margin-right: ${spacing.space_m};
  cursor: pointer;
  text-transform: uppercase;
  line-height: 1.6 !important;
  padding: 0 ${spacing.space_default} 0 ${spacing.space_default};
`;

const Count = styled.td`
  text-align: center;
  width: 10vw;
`;

const Time = styled.td`
  width: 12vw;
`;

const Privacy = styled.span`
  padding-left: ${spacing.space_s};
  position: relative;
  top: 1px;
`;

export const NoValue = styled.div`
  padding-left: 2em;
`;

const HeadCheckboxWrapper = styled.span`
  border: none;
  max-height: 100%;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  align-items: center;
  margin-left: 0.5rem;
`;

const CheckboxWrapper = styled.div`
  align-self: baseline;
  padding-right: 0.5em;
`;

const ImportErrorWrapper = styled.span`
  display: flex;
  align-items: center;
`;

export {
  Creator,
  Menu,
  Title,
  Count,
  Time,
  New,
  IconContainer,
  Privacy,
  CheckboxWrapper,
  HeadCheckboxWrapper,
  ImportErrorWrapper
};
