// @flow

import React, { useState, useEffect } from "react";

import Checkbox from "src/components/Checkbox.old";
import { ChecklistSettings } from "src/styles/checklist";
import type { TextFieldSettings } from "src/types";

type Props = {
  settings: TextFieldSettings,
  saveSettings: Function
};

type State = {
  placeholder: string,
  multiline: boolean,
  defaultValue: string,
  suppressDefaultNotification: boolean
};

const Text = ({ settings, saveSettings }: Props) => {
  const [state, setState] = useState<State>({
    placeholder: "",
    multiline: false,
    defaultValue: "",
    suppressDefaultNotification: false
  });

  useEffect(() => {
    if (settings) {
      const {
        placeholder,
        multiline,
        defaultValue,
        suppressDefaultNotification
      } = settings;
      setState({
        placeholder,
        multiline,
        defaultValue,
        suppressDefaultNotification
      });
    }
  }, [settings]);

  const handleChecked = (e: any) => {
    const { checked } = e.target;
    setState(prev => ({ ...prev, multiline: checked }));
  };

  const handleChange = (e: any) => {
    const { value } = e.target;
    setState(prev => ({ ...prev, placeholder: value }));
  };

  const handleDefaultValue = (e: any) => {
    const { value } = e.target;
    setState(prev => ({ ...prev, defaultValue: value }));
  };

  const handleSuppressDefaultNotification = (e: any) => {
    const { checked } = e.target;
    setState(prev => ({ ...prev, suppressDefaultNotification: checked }));
  };

  useEffect(() => {
    saveSettings(state);
  }, [state]);

  const { placeholder, multiline, defaultValue, suppressDefaultNotification } =
    state;

  return (
    <ChecklistSettings>
      <form>
        <div>
          <label htmlFor="placeholder">Placeholder</label>
          <input
            type="text"
            id="placeholder"
            value={placeholder}
            onChange={handleChange}
            autoFocus
          />
        </div>

        <div>
          <Checkbox
            handleChange={handleChecked}
            checked={multiline}
            id="multiline"
          >
            Multiline
          </Checkbox>
        </div>

        <div>
          <label htmlFor="defaultValue">Default Value</label>
          <input
            type="text"
            id="defaultValue"
            value={defaultValue}
            onChange={handleDefaultValue}
          />
        </div>

        <div>
          <Checkbox
            handleChange={handleSuppressDefaultNotification}
            checked={suppressDefaultNotification}
            id="suppressDefaultNotification"
          >
            Suppress notifications for default values
          </Checkbox>
        </div>
      </form>
    </ChecklistSettings>
  );
};

export default Text;
