// @flow

import * as R from "ramda";

import type { LinkedFieldChatroom, RoomId } from "src/types";

const getAutoLinkedRooms = (value: LinkedFieldChatroom[]): number[] => {
  const iter = (acc, item) => {
    if (item?.linkAllVersions) {
      acc.push(item.chatroom.id);
    }
    return acc;
  };
  return R.reduce(iter, [], value);
};

export const getOriginChatroomId = (
  roomId: number,
  result: number[],
  chatrooms: {
    [number]: LinkedFieldChatroom
  }
) =>
  result.find(
    currOriginRoomId => chatrooms[currOriginRoomId].chatroom.id === roomId
  );

export const groupVersionsTogether = (
  result: number[],
  chatrooms: {
    [number]: LinkedFieldChatroom
  }
) => {
  const selectedRooms = result.map(id => chatrooms[id]);

  const selectedRoomIds: number[] = selectedRooms.map(room => room.chatroom.id);

  const autoLinkedRooms = getAutoLinkedRooms(selectedRooms);

  return [selectedRoomIds, autoLinkedRooms];
};

/**
 * Filters archived items from a list of room Ids
 * @param {Object} value - The object containing entities and result.
 * @param {Array<number>} archived - The array of archived chatrooms.
 * @returns {Object} The filtered collection with linked field value.
 */
export const filterArchived = (value: Object, archived: Array<RoomId>) => {
  const { result } = value;
  if ((result || []).length > 0 && archived.length > 0) {
    const active = R.difference(result || [], archived);
    return { ...value, result: active };
  } else {
    return value;
  }
};
