// @flow

import { connect } from "react-redux";

import People from "src/components/user/Contacts/People";
import { getCurrentRoomId, getCurrentLanguage } from "src/reducers";
import { changeCurrentChatroom } from "src/actions/chatroom";
import { openContactsPage } from "src/actions/modal";
import * as actions from "src/actions/user";

import type { AppState } from "src/types";

const mapStateToProps = ({ app }: { app: AppState }) => ({
  searchResults: app.users.contacts,
  currentRoom: getCurrentRoomId(app),
  filter: app.users.contactFilter,
  language: getCurrentLanguage(app)
});

export default connect(mapStateToProps, {
  ...actions,
  changeCurrentChatroom,
  openContactsPage
})(People);
