// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";
import moment from "moment";

import Common from "./Common";

import type { Message } from "src/types";

type Props = {
  message: Message
};

const Duedate = ({ message }: Props) => {
  const { data } = message;
  const { new: newDate, old: oldDate } = data;
  if (newDate && oldDate)
    return (
      <Common {...message}>
        {" "}
        {i18n.t(k.UPDATED_THE_DUE_DATE_TO)}{" "}
        <strong>{moment(newDate.toDate()).format("MMM DD, YYYY")}</strong>
      </Common>
    );

  if (newDate)
    return (
      <Common {...message}>
        {" "}
        {i18n.t(k.SET_THE_DUE_DATE_TO)}{" "}
        <strong data-cy="newDueDateNotification">
          {moment(newDate.toDate()).format("MMM DD, YYYY")}
        </strong>
      </Common>
    );

  return <Common {...message}> {i18n.t(k.REMOVED_THE_DUE_DATE)}</Common>;
};

export default Duedate;
