// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  FormControl,
  FormErrorMessage
} from "@chakra-ui/react";
import isURL from "validator/lib/isURL";

type LinkModalProps = {
  toggleModal: Function,
  setLink: Function,
  showModal: boolean
};

const LinkModal = ({ toggleModal, showModal, setLink }: LinkModalProps) => {
  const [url, setUrl] = useState("");
  const [error, setError] = useState(false);
  const handleSave = e => {
    e.preventDefault();
    if (!error) {
      setLink(url);
      toggleModal();
    }
  };

  const handleChange = value => {
    if (isURL(value)) {
      setError(false);
    } else {
      setError(true);
    }
    setUrl(value);
  };

  return (
    <Modal isOpen={showModal} onClose={toggleModal}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{i18n.t(k.ADD_LINK)}</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={handleSave}>
          <ModalBody>
            <FormControl isInvalid={error}>
              <Input
                autoFocus
                placeholder="Enter URL"
                value={url}
                onChange={e => handleChange(e.target.value)}
              />

              {error && (
                <FormErrorMessage>{i18n.t(k.ENTER_VALID_URL)}</FormErrorMessage>
              )}
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={() => toggleModal(false)}>
              {i18n.t(k.CANCEL)}
            </Button>
            <Button colorScheme="blue" type="submit">
              {i18n.t(k.SAVE)}
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default LinkModal;
