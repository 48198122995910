// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";
import { connect } from "react-redux";

import { DemoUser as StyledDemoUser } from "./styles";

import { getRole, getCurrentUserId } from "src/reducers";

import type { AppState } from "src/types";

type Props = {
  role: string
};

const DemoUser = ({ role }: Props) => {
  if (role === "demo") {
    return (
      <StyledDemoUser type="button" id="demo-user">
        {i18n.t(k.START_WITH_THIS_SOLUTION)}
      </StyledDemoUser>
    );
  }

  return <></>;
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  role: getRole(app, getCurrentUserId(app))
});

export default connect(mapStateToProps)(DemoUser);
