// @flow

/*
 * Sequence of events
 *
 * \-> LOAD_CONVERSATIONS
 *    \-> getOrgs
 *       \-> getCurrentOrg
 *          (success) -> sendAuthToken | getOrgDetails
 *          (failure) -> getDefaultOrg     -> (sendAuthToken | getOrgDetails)
 *                      \-> setDefaultOrg -/
 */

import * as R from "ramda";
import { toast } from "react-toastify";
import {
  takeEvery,
  call,
  put,
  select,
  retry,
  take,
  cancel,
  fork,
  takeLatest
} from "redux-saga/effects";

import { rsf } from "../db";
import getAppState, {
  getUser,
  getLastOrg,
  getDefaultOrg,
  getCurrentUserId,
  getOAuthAppName
} from "src/selectors";
import { getInvitedOrg } from "src/reducers";
import * as org from "src/api/organization";
import * as auth from "src/api/auth";
import * as atypes from "src/constants/actionTypes";
import {
  setOrgFileUploadSetting,
  syncUserOrgSuccess
} from "src/actions/organization";
import { clearAllErrors, setupOrg } from "src/actions/signup";
import type { Action } from "src/types";

function* getOrgs({ payload }): any {
  const { uid } = payload;
  if (uid) {
    try {
      const allOrgs = yield call(org.getAll, uid);

      yield put({
        type: atypes.GET_ORGS_SUCCESS,
        payload: {
          allOrgs
        }
      });

      yield put({
        type: atypes.GET_CURRENT_ORG_REQUEST,
        payload: {
          orgs: R.keys(allOrgs)
        }
      });
    } catch (e) {
      console.error(e);
      yield put({
        type: atypes.GET_ORGS_FAILURE,
        payload: e
      });
    }
  }
}

function* watchGetOrgs(): any {
  yield takeEvery(atypes.GET_ORGS_REQUEST, getOrgs);
}

function* getCurrentOrg() {
  const { uid } = yield select(getUser);
  try {
    const allowedOrgs = yield call(org.getUserOrgDetails);
    const orgInSession = sessionStorage.getItem("lastOrg");

    const defaultOrg = yield select(getDefaultOrg);
    const invitedOrg = getInvitedOrg(yield select(getAppState));
    const requestedOrg = parseInt(orgInSession, 10);
    const lastOrg = yield call(org.getLastOrg, uid);
    const defaultAllowedOrg = allowedOrgs.length > 0 && allowedOrgs[0].id;

    const verifyAccess = (orgId: number) =>
      allowedOrgs.some(org => org.id === orgId);

    if (invitedOrg) {
      yield put({
        type: atypes.GET_CURRENT_ORG_SUCCESS,
        payload: { last: invitedOrg }
      });
    } else if (requestedOrg && verifyAccess(requestedOrg)) {
      yield put({
        type: atypes.GET_CURRENT_ORG_SUCCESS,
        payload: { last: requestedOrg }
      });
    } else if (lastOrg && verifyAccess(lastOrg)) {
      yield put({
        type: atypes.GET_CURRENT_ORG_SUCCESS,
        payload: { last: lastOrg }
      });

      sessionStorage.setItem("lastOrg", lastOrg);
    } else if (defaultOrg && verifyAccess(defaultOrg)) {
      sessionStorage.setItem("lastOrg", defaultOrg);
      yield put({
        type: atypes.GET_DEFAULT_ORG_SUCCESS,
        payload: { last: defaultOrg }
      });
    } else if (defaultAllowedOrg) {
      sessionStorage.setItem("lastOrg", defaultAllowedOrg);
      yield put({
        type: atypes.GET_DEFAULT_ORG_SUCCESS,
        payload: { last: defaultAllowedOrg }
      });
    } else {
      yield put({
        type: atypes.GET_CURRENT_ORG_FAILURE,
        payload: {
          error: "Error getting org"
        }
      });
    }
  } catch (error) {
    yield put({
      type: atypes.GET_CURRENT_ORG_FAILURE,
      payload: {
        error
      }
    });
  }
}

function* watchGetCurrentOrg(): any {
  yield takeEvery(atypes.GET_CURRENT_ORG_REQUEST, getCurrentOrg);
}

function* sendAuthToken(action: Action): any {
  try {
    const orgId = yield select(getLastOrg);
    const token = yield call(auth.getAuthToken, {});
    sessionStorage.setItem("authToken", token);
    sessionStorage.setItem("lastOrg", orgId);
    const { payload } = action;

    const oAuthAppName = yield select(getOAuthAppName);

    // If app is in the normal flow then the following actions are needed
    if (R.isNil(oAuthAppName)) {
      yield put({
        type: atypes.GET_USER_MEMBERSHIP_REQUEST,
        payload: action.payload
      });

      yield put({
        type: atypes.SYNC_CUSTOM_PROCESS_COLUMN_REQUEST,
        payload: action.payload
      });

      yield put({
        type: atypes.SYNC_USERS_REQUEST,
        payload: action.payload
      });

      yield put({
        type: atypes.SERVER_AUTH_REQUEST,
        payload: { token, orgId }
      });

      yield take(atypes.SERVER_AUTH_SUCCESS);

      yield put({
        type: atypes.API_AUTH_SUCCESS,
        payload
      });
    }

    const currentOrgDetails = yield call(org.getDetails, {
      orgId
    });

    yield put({
      type: atypes.GET_CURRENT_ORG_DETAILS_SUCCESS,
      payload: currentOrgDetails
    });

    yield put({
      type: atypes.GET_USERS_ORG_DETAILS_REQUEST,
      payload: {}
    });
  } catch (error) {
    yield put({
      type: atypes.SEND_AUTH_TOKEN_FAILURE,
      payload: { error }
    });
  }
}

function* watchSendAuthToken(): any {
  yield takeEvery(atypes.GET_CURRENT_ORG_SUCCESS, sendAuthToken);
}

function* getOrgDetail({ payload }: Action): any {
  try {
    if (payload) {
      const detail = yield call(org.getDetails, payload);

      yield put({
        type: atypes.GET_ORG_DETAILS_SUCCESS,
        payload: detail
      });
    }
  } catch (e) {
    yield put({
      type: atypes.GET_ORG_DETAILS_FAILURE,
      payload: { e }
    });
  }
}

function* watchGetOrgDetails(): any {
  yield takeEvery(atypes.GET_ORG_DETAILS_REQUEST, getOrgDetail);
}

function* setCurrentOrg({ payload }: Action): any {
  const { uid } = yield select(getUser);
  try {
    yield call(org.setLastOrg, { orgId: payload.last, uid });
    yield put({
      type: atypes.SET_CURRENT_ORG_SUCCESS,
      payload: { last: payload.last }
    });
    yield put({
      type: atypes.GET_CURRENT_ORG_SUCCESS,
      payload: { last: payload.last }
    });

    if (payload.reload) {
      // Set lastOrg in sessionStorage before reload
      sessionStorage.setItem("lastOrg", payload.last);
      sessionStorage.removeItem("switchingOrgs");

      // Takes the user to sign in page (if tenants are different)
      // or reloads the app (if tenants are same)
      window.location.assign("/");
    }

    window.currentOrgId = payload.last;
  } catch (e) {
    yield put({
      type: atypes.SET_CURRENT_ORG_FAILURE,
      payload: { e }
    });
  }
}

function* watchSetCurrentOrg(): any {
  yield takeEvery(
    [atypes.SET_CURRENT_ORG_REQUEST, atypes.GET_DEFAULT_ORG_SUCCESS],
    setCurrentOrg
  );
}

function* retryGetOrgs(): any {
  const { uid } = yield select(getUser);
  try {
    yield retry(5, 3000, org.getAll, uid);
    yield put({ type: atypes.SYNC_USER_ORG_REQUEST });
    yield put({ type: atypes.LOAD_CONVERSATIONS });
  } catch (error) {
    console.error("Firebase", error);
    console.log("Firebase urls are probably blocked or internet is really bad");
    yield put({
      type: atypes.NO_NETWORK,
      payload: {}
    });
  }
}

function* watchGetOrgsError(): any {
  yield takeEvery(atypes.GET_ORGS_FAILURE, retryGetOrgs);
}

function* createNewOrg(action: Action): any {
  const { payload } = action;
  const { title } = payload;

  try {
    yield put(clearAllErrors());
    const { domainName: domain, title, member1, member2, member3 } = payload;

    const autoJoin = payload.domainAutoJoin || false;

    yield put(
      setupOrg({
        domain,
        autoJoin,
        title,
        member1,
        member2,
        member3
      })
    );

    // Wait for SETUP_ORG_SUCCESS
    const {
      payload: { orgId }
    } = yield take(atypes.SETUP_ORG_SUCCESS);

    yield put({
      type: atypes.CREATE_NEW_ORG_SUCCESS,
      payload: {
        orgId
      }
    });

    yield put({
      type: atypes.TOGGLE_CREATE_ORG_MODAL,
      payload: { open: false }
    });

    toast.success(`Created ${title}`);

    yield put({
      type: atypes.GET_ORG_DETAILS_REQUEST,
      payload: { orgId }
    });
  } catch (error) {
    toast.error(`Error Creating  ${title}`);
    yield put({
      type: atypes.CREATE_NEW_ORG_FAILURE,
      payload: {
        error
      }
    });
  }
}

function* watchCreateNewOrg(): any {
  yield takeEvery(atypes.CREATE_NEW_ORG_REQUEST, createNewOrg);
}

function* getUserOrgDetails(): any {
  try {
    const orgDetails = yield call(org.getUserOrgDetails);

    yield put({
      type: atypes.GET_USERS_ORG_DETAILS_SUCCESS,
      payload: {
        orgDetails
      }
    });
  } catch (error) {
    yield put({
      type: atypes.GET_USERS_ORG_DETAILS_FAILURE,
      payload: { error }
    });
  }
}

function* watchGetUsersOrgDetails(): any {
  yield takeEvery(atypes.GET_USERS_ORG_DETAILS_REQUEST, getUserOrgDetails);
}

function* syncFileUploadSettings(): any {
  try {
    const orgId = yield select(getLastOrg);

    const allowFileUpload = yield fork(
      rsf.firestore.syncDocument,
      `orgs/${orgId}`,
      {
        successActionCreator: snapshot => setOrgFileUploadSetting(snapshot)
      }
    );

    yield take(atypes.SET_CURRENT_ORG_REQUEST);
    yield cancel(allowFileUpload);
  } catch (error) {
    yield put({
      type: atypes.SYNC_ORG_FILE_UPLOAD_SETTING_FAILURE,
      payload: { error }
    });
  }
}

function* watchSyncFileUploadSettings(): any {
  yield takeEvery(atypes.API_AUTH_SUCCESS, syncFileUploadSettings);
}

function* watchSyncSRWFileUploadSettings(): any {
  yield takeEvery(atypes.SRW_SERVER_AUTH_SUCCESS, syncFileUploadSettings);
}

function* setFileUploadSetting({ payload }: Action) {
  try {
    const { allowFileUpload, homeScreenEnabled } = payload.snapshot.data();

    yield put({
      type: atypes.SET_FILE_UPLOAD_SETTING,
      payload: allowFileUpload
    });

    yield put({
      type: atypes.SET_HOME_SCREEN_STATUS,
      payload: homeScreenEnabled === undefined ? true : homeScreenEnabled
    });
  } catch (error) {
    console.log(error);
  }
}

function* watchFileUploadSettings(): any {
  yield takeEvery(
    atypes.SYNC_ORG_FILE_UPLOAD_SETTING_SUCCESS,
    setFileUploadSetting
  );
}

function* syncUserOrg(): any {
  try {
    const currentUser = yield select(getCurrentUserId);

    yield fork(rsf.firestore.syncDocument, `userData/${currentUser}`, {
      successActionCreator: snapshot => syncUserOrgSuccess(snapshot)
    });
  } catch (error) {
    yield put({
      type: atypes.SYNC_USER_ORG_FAILURE,
      payload: { error }
    });
  }
}

function* watchSyncUserorg(): any {
  yield takeLatest(atypes.SYNC_USER_ORG_REQUEST, syncUserOrg);
}

function* resolveUserOrg({ payload }: Action): any {
  try {
    const currentOrgId = yield select(getLastOrg);

    const { orgs } = payload.snapshot.data();

    for (const orgId in orgs) {
      if (orgId == currentOrgId) {
        if (orgs[orgId].disabled) {
          yield put({
            type: atypes.LOGOUT_REQUEST,
            payload: {}
          });
        }
      }
    }
  } catch (error) {
    console.log(error);
  }
}

function* watchResolveUserOrg(): any {
  yield takeLatest(atypes.SYNC_USER_ORG_SUCCESS, resolveUserOrg);
}

function* deleteLastOrg({ payload }: Action): any {
  const { uid } = payload;
  try {
    yield call(org.deleteLastOrg, uid);
    yield put({
      type: atypes.DELETE_LAST_ORG_SUCCESS
    });

    sessionStorage.removeItem("lastOrg");
    window.location.reload();
  } catch (e) {
    console.error(e);
    yield put({
      type: atypes.DELETE_LAST_ORG_FAILURE,
      payload: { e }
    });
  }
}

function* watchDeleteLastOrg(): any {
  yield takeEvery(atypes.DELETE_LAST_ORG_REQUEST, deleteLastOrg);
}

export default [
  watchResolveUserOrg(),
  watchSyncUserorg(),
  watchFileUploadSettings(),
  watchSyncFileUploadSettings(),
  watchSyncSRWFileUploadSettings(),
  watchGetUsersOrgDetails(),
  watchGetOrgs(),
  watchGetCurrentOrg(),
  watchSendAuthToken(),
  watchGetOrgDetails(),
  watchSetCurrentOrg(),
  watchGetOrgsError(),
  watchCreateNewOrg(),
  watchDeleteLastOrg()
];
