// @flow

import { takeLatest, put, select, call, delay } from "redux-saga/effects";
import { toast } from "react-toastify";

import getAppState from "src/selectors";
import * as api from "src/api/settings";
import * as atypes from "src/constants/actionTypes";

import type { Action } from "src/types";

function* getUserNotifications(): any {
  try {
    const response = yield call(api.getUserNotificationSettings);
    yield put({
      type: atypes.GET_USER_NOTIFICATION_SETTINGS_SUCCESS,
      payload: {
        notification: response.email.id || 1
      }
    });
  } catch (error) {
    console.log(error);
    toast.error("Unable to fetch your settings");
  }
}

function* watchGetUserNotifications(): any {
  yield takeLatest(atypes.SET_SETTINGS_SUCCESS, getUserNotifications);
}

function* setUserNotifications({ payload }: Action) {
  const oldId = (yield select(getAppState)).settings.emailNotification;
  try {
    yield put({
      type: atypes.SET_USER_NOTIFICATION_SETTINGS_OPTIMISTIC,
      payload
    });
    yield call(api.setUserNotificationSettings, {
      email: { id: payload.notification }
    });
    toast.success("Notification settings has been updated");
  } catch (error) {
    console.log(error);
    yield delay(500);
    yield put({
      type: atypes.SET_USER_NOTIFICATION_SETTINGS_FAILURE,
      payload: {
        notification: oldId
      }
    });
    toast.error("Unable to change your notification settings");
  }
}

function* watchSetUserNotifications(): any {
  yield takeLatest(
    atypes.SET_USER_NOTIFICATION_SETTINGS_REQUEST,
    setUserNotifications
  );
}

export default [watchGetUserNotifications(), watchSetUserNotifications()];
