// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React, { useState } from "react";
import {
  Radio,
  Text,
  NumberInput,
  NumberInputField,
  Select
} from "@chakra-ui/react";

import { ListItem } from "../styles";

type Props = {
  id: string,
  label: string,
  handleSelect: Function,
  currentFilter: string
};

const LastNFilter = ({
  id,
  label,
  handleSelect,
  currentFilter = ""
}: Props) => {
  const isFilterApplied = currentFilter.startsWith(id);
  const [numberInputValue, setNumberInputValue] = useState(
    isFilterApplied ? currentFilter.split("-")[1] : 1
  );
  const [selectValue, setSelectValue] = useState(
    isFilterApplied ? currentFilter.split("-")[2] : "days"
  );

  const handleSelectChange = event => {
    setSelectValue(event.target.value);

    handleSelect(`${id}-${numberInputValue}-${event.target.value}`);
  };

  const handleNumberInputChange = event => {
    setNumberInputValue(event.target.value);
    handleSelect(`${id}-${event.target.value}-${selectValue}`);
  };

  const handleRadio = () => {
    handleSelect(`${id}-${numberInputValue}-${selectValue}`);
  };

  return (
    <ListItem role="button" onClick={handleRadio}>
      <Radio size="sm" isChecked={isFilterApplied}>
        <Text fontSize="xs" m={0}>
          {label}
        </Text>
      </Radio>
      <NumberInput
        ml={2}
        w={12}
        min={1}
        defaultValue={1}
        size="xs"
        value={numberInputValue}
      >
        <NumberInputField
          p={2}
          borderRadius={2}
          onChange={handleNumberInputChange}
        />
      </NumberInput>
      <Select
        size="sm"
        variant="filled"
        fontSize="xs"
        w={24}
        onChange={handleSelectChange}
        value={selectValue}
      >
        <option value="days">{i18n.t(k.DAYS1)}</option>
        <option value="months">{i18n.t(k.MONTHS)}</option>
        <option value="years">{i18n.t(k.YEARS)}</option>
      </Select>
    </ListItem>
  );
};

export default LastNFilter;
