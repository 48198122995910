// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import { connect } from "react-redux";
import React from "react";
import moment from "moment";

import { DateTime as StyledDateInvited } from "./styles";
import { getUser } from "src/reducers";

import type { AppState, UnifizeUser } from "src/types";

type Props = {
  user: UnifizeUser
};

const DateInvited = ({ user }: Props) => (
  <StyledDateInvited>
    {user.dateInvited && user.dateInvited.toDate
      ? moment(user.dateInvited.toDate()).format(i18n.t(k.MMM_DD_YYYY_H_MM_A))
      : null}
  </StyledDateInvited>
);

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  user: getUser(app, props.uid)
});

export default connect(mapStateToProps)(DateInvited);
