// @flow

import React, { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { Text } from "@unifize/sarah";
import i18n from "i18next";
import k from "src/i18n/keys";

import { getReportsById } from "src/selectors";
import { IconButton } from "src/components/Unifize/Button/styles";
import { icons } from "src/styles";
import Icon from "src/icons";
import * as colors from "src/styles/constants/colors";
import Dropdown from "src/components/Manage/Builder/Checklist/SettingsBuilder/FieldSettings/Conversation/FieldSelect/Dropdown";

import type { Report, ReportById } from "src/types";

import {
  SelectContainer,
  ReportSelect as StyledReportSelect,
  AddReport
} from "./styles";

type Props = {
  label: string,
  selected: Array<string>,
  onChange: Function
};

const ReportSelect = ({ label, selected, onChange }: Props) => {
  const reportsById: ReportById = useSelector(state => getReportsById(state));
  const reportTitles = useMemo(
    () =>
      (Object.values(reportsById): Array<any>).flatMap((report: Report) =>
        selected.includes(report.id)
          ? []
          : {
              cells: [[report.title, null]],
              id: String(report.id)
            }
      ),
    [reportsById, selected]
  );
  const [showEmptyDropdown, setShowEmptyDropdown] = useState(false);

  return (
    <SelectContainer>
      <Text variant="caption" weight="bold">
        {label}
      </Text>
      {(showEmptyDropdown ? [...selected, null] : selected).map(
        (report, index) =>
          (report === null && showEmptyDropdown) ||
          reportsById[report ?? ""] ? (
            <StyledReportSelect
              key={`reportSelect${report ?? "null"}-${index}`}
            >
              <Dropdown
                elements={reportTitles}
                handleSelect={id => {
                  const newSelected = [...selected];
                  newSelected[index] = id;
                  onChange(newSelected.filter(item => item != null)); // clear out the empty value(s)
                  if (showEmptyDropdown) {
                    setShowEmptyDropdown(false);
                  }
                }}
                label={i18n.t(k.SELECT_REPORT)}
                selectedElement={
                  report
                    ? { cells: [[reportsById[report].title, null]], id: report }
                    : null
                }
                onMount={props => {
                  if (!report && props?.openMenu) {
                    props.openMenu();
                  }
                }}
                fullWidth
              />
              <IconButton
                onClick={e => {
                  e.preventDefault();
                  if (report === null) {
                    setShowEmptyDropdown(false);
                  } else {
                    onChange(selected.filter(r => r !== report));
                  }
                }}
              >
                <img
                  src={icons.trash}
                  alt="delete icon"
                  style={{
                    backgroundColor: colors.skyLighter,
                    userSelect: "none"
                  }}
                />
              </IconButton>
            </StyledReportSelect>
          ) : null
      )}
      <AddReport
        data-cy="addEmbeddedFieldButton"
        type="button"
        disabled={showEmptyDropdown || reportTitles.length === 0}
        onClick={() => setShowEmptyDropdown(true)}
      >
        <Icon type="hollowPlus" />
        <span>{i18n.t(k.ADD_REPORT)}</span>
      </AddReport>
    </SelectContainer>
  );
};

export default ReportSelect;
