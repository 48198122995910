// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { CopyToClipboard } from "react-copy-to-clipboard";

import FormTextInput from "./Form/FormTextInput";
import Icon from "src/icons";

import { getSSOConfig, getCurrentOrg } from "src/reducers";

import { GeneratedFieldsContainer, FieldContainer, Label } from "./styles";
import * as colors from "src/styles/constants/colors";
import * as styles from "./styles";

import type { SSOConfig } from "src/types";

const GeneratedFields = () => {
  const ssoConfig: SSOConfig = useSelector(({ app }) => getSSOConfig(app));
  const currentOrgDetails = useSelector(({ app }) => getCurrentOrg(app));
  const orgSSOUrl = `${window.location.origin}/sso-redirect?slug=${currentOrgDetails.nickName}`;

  return (
    <GeneratedFieldsContainer>
      <FieldContainer>
        <Label htmlFor="generated-entity">
          {i18n.t(k.SERVICE_PROVIDER_ENTITY_ID)}
        </Label>
        <div
          style={{
            position: "relative"
          }}
        >
          <FormTextInput
            id="generated-entity"
            name="generated-entity"
            readOnly={true}
            value={ssoConfig.serviceProviderEntityId}
          />

          <CopyToClipboard
            text={ssoConfig.serviceProviderEntityId}
            onCopy={() => {
              toast.success("Copied service provider entity ID");
            }}
          >
            <Icon
              type="copy"
              size={24}
              style={styles.copyIcon}
              fill={colors.lightBlue600}
            />
          </CopyToClipboard>
        </div>
      </FieldContainer>
      <FieldContainer>
        <Label htmlFor="generated-callback-url">
          {i18n.t(k.AUTHORIZATION_CALLBACK_URL)}
        </Label>
        <div
          style={{
            position: "relative"
          }}
        >
          <FormTextInput
            id="generated-callback-url"
            name="generated-callback-url"
            readOnly={true}
            value={ssoConfig.callbackUrl}
          />

          <CopyToClipboard
            text={ssoConfig.callbackUrl}
            onCopy={() => {
              toast.success("Copied callback URL");
            }}
          >
            <Icon
              type="copy"
              size={24}
              style={styles.copyIcon}
              fill="#1581BA"
            />
          </CopyToClipboard>
        </div>
      </FieldContainer>
      <FieldContainer>
        <Label htmlFor="generated-org-sso-url">Org SSO URL</Label>
        <div
          style={{
            position: "relative"
          }}
        >
          <FormTextInput
            id="generated-org-sso-url"
            name="generated-org-sso-url"
            readOnly={true}
            value={orgSSOUrl}
          />
          <CopyToClipboard
            text={orgSSOUrl}
            onCopy={() => {
              toast.success("Copied Org SSO URL");
            }}
          >
            <Icon
              type="copy"
              size={24}
              style={styles.copyIcon}
              fill="#1581BA"
            />
          </CopyToClipboard>
        </div>
      </FieldContainer>
    </GeneratedFieldsContainer>
  );
};

export default GeneratedFields;
