// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import { connect } from "react-redux";
import React, { useCallback } from "react";
import Modal from "react-modal";

import Tabs from "../Tabs";
import { Contacts as StyledGroups } from "../styles";
import New from "./New";
import Table from "./Table";
import Header from "src/components/SearchHeader.old";
import { getParent } from "src/utils";
import Close from "src/containers/Close";
import Icon from "src/icons";
import { getCurrentUserId } from "src/reducers";
import {
  setGroupModalAttributes,
  closeGroupModal,
  setGroups
} from "src/actions/group";

import type { AppState, UID, GroupFilter } from "src/types";

Modal.setAppElement("#root");

type Props = {
  show: boolean,
  currentUser: UID,
  filter: GroupFilter,
  _setGroups: Function,
  _setGroupModalAttributes: Function,
  _closeGroupModal: Function
};

const Groups = ({
  show,
  filter,
  currentUser,
  _setGroups,
  _setGroupModalAttributes,
  _closeGroupModal
}: Props) => {
  const openCreateGroup = useCallback(() => {
    _setGroupModalAttributes({
      title: "",
      show: !show,
      members: [currentUser],
      owners: [currentUser],
      locked: false,
      error: null,
      loading: false
    });
  }, [show, _setGroupModalAttributes]);

  const closeGroup = useCallback(() => {
    _closeGroupModal();
  }, [_closeGroupModal]);

  const handleSearch = useCallback(
    (search: string) => {
      _setGroups({ ...filter, search });
    },
    [filter, _setGroups]
  );

  const search = filter.search || "";

  return (
    <StyledGroups id="groups">
      <Modal isOpen parentSelector={() => getParent("#groups")}>
        <h2>
          <Icon type="contacts" />
          <span>{i18n.t(k.CONTACTS)}</span>
        </h2>
        <Close />
        <Tabs tab="groups" />
        <Header
          text={search}
          handleChange={handleSearch}
          handleCreate={openCreateGroup}
          button={i18n.t(k.CREATE_GROUP)}
        />

        <Table />

        {show ? <New handleClose={closeGroup} /> : null}
      </Modal>
    </StyledGroups>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  show: app.groups.modal.show,
  currentUser: getCurrentUserId(app)
});

export default connect(mapStateToProps, {
  _setGroupModalAttributes: setGroupModalAttributes,
  _closeGroupModal: closeGroupModal,
  _setGroups: setGroups
})(Groups);
