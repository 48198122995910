// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React, { Fragment, useCallback } from "react";
import { connect } from "react-redux";

import {
  Common as StyledCommon,
  NotificationContainer,
  Comment,
  UserName
} from "./styles";
import NameOrEmail from "src/containers/user/NameOrEmail";
import ChatTimestamp from "src/components/messages/Chat/ChatTimestamp";
import { getAuthorColor } from "src/utils";
import { getCurrentRoomId, getChatroomAddress } from "src/reducers";

import type { UID, RoomId } from "src/types";

type Props = {
  currentUser: UID,
  author: UID,
  dataCy?: ?string,
  timestamp: Date,
  children: any,
  read: boolean,
  delivered: boolean,
  comment: string,
  currentRoomAddress: RoomId,
  blackText: boolean
};

const Common = ({
  currentUser,
  author,
  timestamp,
  children,
  read,
  dataCy = null,
  delivered,
  comment,
  currentRoomAddress,
  blackText
}: Props) => {
  const addSpace = useCallback(
    () => [...Array(40)].map((val, id) => <Fragment key={id}>&nbsp;</Fragment>),
    []
  );

  return (
    <NotificationContainer data-cy="notificationContainer">
      <StyledCommon data-cy={dataCy} isItYou={currentUser === author}>
        {comment && <Comment data-cy="notificationComment">{comment}</Comment>}
        <UserName
          hashColor={!blackText && getAuthorColor(author, currentRoomAddress)}
        >
          <strong>
            {currentUser === author ? (
              i18n.t(k.YOU)
            ) : (
              <NameOrEmail uid={author} showPending={false} />
            )}
          </strong>
        </UserName>
        {children}
        {addSpace()}
        <ChatTimestamp
          read={read}
          delivered={delivered}
          timestamp={timestamp}
        />
      </StyledCommon>
    </NotificationContainer>
  );
};

const mapStateToProps = ({ app }) => ({
  currentUser: app.currentUser.uid,
  currentRoomAddress: getChatroomAddress(app, getCurrentRoomId(app))
});

export default connect(mapStateToProps)(Common);
