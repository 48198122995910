// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  Button
} from "@chakra-ui/react";

type Props = {
  onClose: Function,
  isOpen: boolean,
  title: string,
  onDisconnect: Function
};

const DisconnectModal = ({ onClose, isOpen, title, onDisconnect }: Props) => {
  return (
    <Modal
      isCentered
      onClose={onClose}
      isOpen={isOpen}
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody mt={4}>
          {i18n.t(k.ARE_YOU_SURE_YOU_WANT_TO_DISCO)} {title}{" "}
          {i18n.t(k.INTEGRATION_YOU_COULD)}
          {title}
          {i18n.t(k._9)}
          <br />
          <br />
          {i18n.t(k.YOU_CANNOT_UNDO_THIS_ACTION_C)}
        </ModalBody>
        <ModalFooter>
          <Button mr={4} variant="ghost" onClick={onClose}>
            {i18n.t(k.CANCEL)}
          </Button>
          <Button
            colorScheme="red"
            onClick={() => {
              onDisconnect();
              onClose();
            }}
          >
            {i18n.t(k.DISCONNECT)}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DisconnectModal;
