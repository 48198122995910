// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";
import moment from "moment";
import * as R from "ramda";

import { Time as StyledTime } from "./styles";

type Props = {
  seconds: number | string
};

const Time = ({ seconds }: Props) => (
  <StyledTime>
    {R.type(seconds) === "String"
      ? moment(seconds).format(i18n.t(k.MMM_DD_YYYY_HH_MM_A))
      : moment(moment.unix(seconds).toString()).format(
          i18n.t(k.MMM_DD_YYYY_HH_MM_A)
        )}
  </StyledTime>
);

export default Time;
