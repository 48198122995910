// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import { connect } from "react-redux";
import React, { useCallback } from "react";

import { FormGroup, Content } from "./styles";
import { Input } from "src/styles/input";
import ChartSelect from "src/components/chart/Select";
import { setHomeScreenBuilderAttributes } from "src/actions/orgSettings";

import type { AppState } from "src/types";

import TileColorSelection from "./TileColorSelection";

type Props = {
  modal: Object,
  _setHomeScreenBuilderAttributes: Function
};

const DashboardChart = ({ modal, _setHomeScreenBuilderAttributes }: Props) => {
  const handleTitle = useCallback(
    (event: any) => {
      _setHomeScreenBuilderAttributes({ title: event.target.value });
    },
    [_setHomeScreenBuilderAttributes]
  );

  const handleSubTitle = useCallback(
    (event: any) => {
      _setHomeScreenBuilderAttributes({ subTitle: event.target.value });
    },
    [_setHomeScreenBuilderAttributes]
  );

  const handleChart = useCallback(
    (chart: ?number) => {
      _setHomeScreenBuilderAttributes({ chart });
    },
    [_setHomeScreenBuilderAttributes]
  );

  return (
    <Content>
      <FormGroup>
        <label>{i18n.t(k.TITLE)}</label>
        <Input
          type="text"
          placeholder={i18n.t(k.PENDING_CONVERSATIONS)}
          value={modal.title}
          onChange={handleTitle}
          autoFocus
          required
        />
      </FormGroup>

      <FormGroup>
        <label htmlFor="subtitle">
          {i18n.t(k.SUB_TITLE)}
          <span>{i18n.t(k.OPTIONAL)}</span>
        </label>
        <Input
          id="subtitle"
          type="text"
          placeholder={i18n.t(k.YOU_HAVE_PENDING_TASKS)}
          value={modal.subTitle}
          onChange={handleSubTitle}
        />
      </FormGroup>

      <FormGroup>
        <label htmlFor="subtitle">{i18n.t(k.SELECT_A_CHART)}</label>
        <ChartSelect value={modal.chart} handleSelect={handleChart} />
      </FormGroup>

      <TileColorSelection
        modal={modal}
        _setHomeScreenBuilderAttributes={_setHomeScreenBuilderAttributes}
      />
    </Content>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  modal: app.orgSettings.homeScreenBuilderDialog
});

export default connect(mapStateToProps, {
  _setHomeScreenBuilderAttributes: setHomeScreenBuilderAttributes
})(DashboardChart);
