// @flow

import React, { useCallback } from "react";
import moment from "moment";
import { connect } from "react-redux";

import { fetchMessageRange } from "src/actions/chatroom";
import { getChatroomAddress, getCurrentRoomId } from "src/reducers";
import Name from "src/containers/checklist/Name";
import NameOrEmail from "src/containers/user/NameOrEmail";
import MessageText from "src/components/messages/MessageText";
import Datestamp from "src/components/messages/Datestamp";
import InlineSVG from "src/components/InlineSVG";
import { ReplyTo, IconNotification } from "src/styles";
import Timestamp from "src/components/messages/Timestamp";
import { colors, size } from "src/styles/constants/";
import { getAuthorColor } from "src/utils";

import type { ChatroomAddress } from "src/types";

type Props = {
  currentMessage: Object,
  _fetchMessageRange: Function,
  currentChatroomAddress: ChatroomAddress,
  isCurrentUser: boolean
};

const Message = ({
  currentMessage,
  _fetchMessageRange,
  currentChatroomAddress,
  isCurrentUser
}: Props) => {
  const getIcon = useCallback(() => {
    const { action } = currentMessage;
    const icons = {
      WORKFLOW_CREATE: "workflow",
      DECISION_CREATE: "decision",
      CHECKLIST_CREATE: "checklist",
      CHECKLIST_FIELD_FILL: "Checklist",
      TASK_CREATE: "task",
      CONVERSATION_CREATE: "conversation"
    };
    return icons[action];
  }, [currentMessage]);

  const scrollToMessage = useCallback(() => {
    const { timestamp, id } = currentMessage;
    _fetchMessageRange({ timestamp, id });
  }, [currentMessage, _fetchMessageRange]);

  // Format the timestamp if it's in this format:
  // {
  //    seconds: number,
  //    nanoseconds: number
  // }
  const isValidTimestamp = !(
    typeof currentMessage.timestamp === "object" &&
    currentMessage.timestamp.seconds &&
    currentMessage.timestamp.nanoseconds
  );

  if (!isValidTimestamp) {
    const formattedDate = new Date(
      currentMessage.timestamp.seconds * 1000
    ).toISOString();
    currentMessage.timestamp = formattedDate;
  }

  const dateDiff = moment().diff(currentMessage.timestamp, "days");

  return (
    <div>
      <ReplyTo onClick={scrollToMessage} isCurrentUser={isCurrentUser}>
        <div>
          <div>
            <div>
              <NameOrEmail
                uid={currentMessage.author}
                hashColor={getAuthorColor(
                  currentMessage.author,
                  currentChatroomAddress
                )}
              />
            </div>
            {dateDiff ? (
              <Datestamp timestamp={currentMessage.timestamp} />
            ) : null}
            {!dateDiff && currentMessage.timestamp ? (
              <Timestamp timestamp={currentMessage.timestamp} />
            ) : null}
          </div>
          {currentMessage.action ? (
            <div>
              <div>
                <IconNotification>
                  <InlineSVG
                    icon={getIcon()}
                    color={colors.pureWhite}
                    size={size.iconSize.medium}
                  />
                </IconNotification>
                <div>
                  {currentMessage.action === "CHECKLIST_FIELD_FILL" ? (
                    <Name id={currentMessage.data.id} type="default" />
                  ) : (
                    currentMessage.data.title
                  )}
                </div>
              </div>
            </div>
          ) : (
            <MessageText message={currentMessage} />
          )}
        </div>
      </ReplyTo>
    </div>
  );
};

const mapStateToProps = ({ app }) => ({
  currentChatroomAddress: getChatroomAddress(app, getCurrentRoomId(app))
});

export default connect(mapStateToProps, {
  _fetchMessageRange: fetchMessageRange
})(Message);
