// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import * as R from "ramda";
import React, { useCallback, useState, useRef } from "react";
import { connect } from "react-redux";

import { SingleSelectContainer, SingleSelect, CreateNewState } from "../styles";
import { inviteUserFromChecklist } from "src/actions/invite";
import { getCurrentUserId, getRole } from "src/reducers";

import type { AppState, RoomId, UID } from "src/types";

type Props = {
  role: string,
  multiple: boolean,
  fieldId: number,
  roomId: RoomId,
  checklistId: number,
  selected: Array<UID>,
  setInvite: Function,
  handleClose: Function,
  _inviteUserFromChecklist: Function,
  setSearch: Function
};

const Invite = ({
  role,
  multiple,
  fieldId,
  roomId,
  checklistId,
  selected,
  setInvite,
  handleClose,
  _inviteUserFromChecklist,
  setSearch
}: Props) => {
  const [text, setText] = useState("");
  const fieldRef = useRef(null);

  const handleInvite = useCallback(() => {
    _inviteUserFromChecklist({
      email: text,
      displayName: R.head(text.split("@")) || "",
      multiple: multiple || false,
      fieldId,
      roomId,
      checklistId,
      selected
    });
    setText("");
    setInvite(false);
    setSearch("");
    handleClose();
  }, [
    handleClose,
    setText,
    setInvite,
    fieldId,
    roomId,
    checklistId,
    text,
    multiple,
    selected,
    _inviteUserFromChecklist
  ]);

  const handleChange = useCallback(
    (event: any) => {
      setText(event.target.value);
    },
    [setText]
  );

  return (
    <SingleSelectContainer>
      <SingleSelect
        autoFocus
        placeholder="Enter users email"
        value={text}
        onChange={handleChange}
        ref={fieldRef}
      />

      {role !== "demo" && (
        <CreateNewState
          onClick={handleInvite}
          title={text}
          highlight={text.length > 0}
        >
          <span>{i18n.t(k.ADD_NEW_USER1)} </span>
        </CreateNewState>
      )}
    </SingleSelectContainer>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  role: getRole(app, getCurrentUserId(app))
});

export default connect(mapStateToProps, {
  _inviteUserFromChecklist: inviteUserFromChecklist
})(Invite);
