// @flow

import { connect } from "react-redux";
import React, { useCallback, useEffect } from "react";
import { Text } from "@unifize/sarah";

import type { WorkflowId } from "src/types";
import { setNewWorkflow } from "src/actions/chatroom";
import useBoolean from "src/hooks/useBoolean";
import AddChatroom from "src/containers/chatroom/creator";
import tileColors from "src/styles/constants/tileColors";
import { setTileEmptyStatus } from "src/actions/homescreen";

import { StartNew as StyledStartNew } from "./styles";
import WorkflowTile from "./WorkflowTile";

import type { HomescreenTile, Action } from "src/types";

type Props = {
  tile: HomescreenTile,
  sectionId: number,
  _setNewWorkflow: Function,
  _setTileEmptyStatus: (
    sectionId: number,
    tileId: number,
    isEmpty: boolean
  ) => Action
};

const StartNew = ({
  tile,
  sectionId,
  _setNewWorkflow,
  _setTileEmptyStatus
}: Props) => {
  const { value: newConversation, toggleBoolean: toggleConversation } =
    useBoolean();

  useEffect(() => {
    _setTileEmptyStatus(sectionId, tile?.id, false);
  }, []);

  const { workflow1, workflow2, workflow3, workflow4 } = tile.settings;
  const tileIds: Array<WorkflowId> = [
    workflow1,
    workflow2,
    workflow3,
    workflow4
  ];

  const handleConversation = useCallback(
    (id: WorkflowId) => {
      _setNewWorkflow(id);
      toggleConversation();
    },
    [_setNewWorkflow]
  );

  const colorScheme = tileColors[tile.settings.tileColor];

  const tileSize: number = [workflow1, workflow2, workflow3, workflow4].filter(
    w => w && w !== null
  ).length;

  let tile1,
    tile2,
    tile3,
    tile4 = 1;

  switch (tileSize) {
    case 1:
      tile1 = 2;
      break;
    case 2:
      tile1 = 1;
      tile2 = 1;
      break;
    case 3:
      tile1 = 1;
      tile2 = 1;
      tile3 = 2;
      break;
    case 4:
      tile1 = 1;
      tile2 = 1;
      tile3 = 1;
      tile4 = 1;
      break;
    default:
  }

  return (
    <>
      <StyledStartNew
        tile1={tile1}
        tile2={tile2}
        tile3={tile3}
        tile4={tile4}
        color={tile.settings.tileColor}
        gap="16px"
      >
        {tile.title && (
          <Text variant="body" weight="bold" color={colorScheme.title}>
            {tile.title}
          </Text>
        )}
        <div>
          {tileIds.map(
            id =>
              id && (
                <WorkflowTile
                  key={id}
                  id={id}
                  onClick={handleConversation}
                  color={colorScheme.secondaryText}
                />
              )
          )}
        </div>
      </StyledStartNew>
      {newConversation && (
        <AddChatroom
          show={newConversation}
          position="center"
          modalId="chatroomSearch"
          handleClose={toggleConversation}
          hidePinTo
        />
      )}
    </>
  );
};

export default connect(null, {
  _setNewWorkflow: setNewWorkflow,
  _setTileEmptyStatus: setTileEmptyStatus
})(StartNew);
