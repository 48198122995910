// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React, { Component } from "react";

import { NewRoom as StyledNewRoom, Button as StyledButton } from "./styles";

import Options from "src/containers/chatroom/NewRoom/Options";
import AddChatroom from "src/containers/chatroom/creator";
import Icons from "src/icons";

import type { WorkflowId, SystemProcess } from "src/types";

type Props = {
  filter: Object,
  systemProcess: SystemProcess,
  setConversationAttributes: Function,
  clearConversationDialog: Function,
  setNewWorkflow: Function,
  chatroomCreationEnabled: boolean
};

type State = {
  addNewRoom: boolean,
  showOptions: boolean,
  type: ?string,
  workflow: ?WorkflowId
};

class NewRoom extends Component<Props, State> {
  outerRef: any;

  state = {
    addNewRoom: false,
    showOptions: false,
    type: null,
    workflow: null
  };

  toggleNew = () => {
    const { addNewRoom } = this.state;
    // Clearing local state on close of modal
    this.setState({
      addNewRoom: !addNewRoom,
      type: null,
      workflow: null
    });
  };

  toggleOptions = () => {
    this.setState(prevState => ({ showOptions: !prevState.showOptions }));
  };

  setModalType = (type: ?string = null, workflow: ?WorkflowId = null) => {
    const {
      systemProcess,
      setConversationAttributes,
      clearConversationDialog,
      setNewWorkflow
    } = this.props;
    clearConversationDialog();

    const isSystemTask = systemProcess?.task;

    let newState: Object = {
      type: type === "task" && isSystemTask ? "workflow" : type,
      showOptions: false,
      addNewRoom: true
    };

    if (type === "task" && isSystemTask) {
      setNewWorkflow(systemProcess.task);
      newState.workflow = systemProcess.task;
    } else if (workflow) {
      setNewWorkflow(workflow);
      newState.workflow = workflow;
    }

    setConversationAttributes({
      type: newState.type,
      analytics: {
        location: "main-plus"
      }
    });

    this.setState(newState);
  };

  getType = (type: Array<string>, workflow: ?WorkflowId) => {
    // Type and workflow from filter
    if (workflow === null) {
      return type.length === 1 ? type[0] : null;
    }
    return "workflow";
  };

  getModalType = (type: Array<string>, workflow: ?WorkflowId) => {
    const { type: localType, workflow: localWorkflow } = this.state;

    // Type and Workflow set in state
    if (localWorkflow !== null) {
      return "workflow";
    }

    if (localType !== null) {
      return localType;
    }

    return this.getType(type, workflow);
  };

  render() {
    const { filter, chatroomCreationEnabled } = this.props;
    const { addNewRoom, showOptions, workflow: localWorkflow } = this.state;
    const { type, workflow } = filter;

    if (!chatroomCreationEnabled) {
      return null;
    }

    return (
      <>
        <StyledNewRoom>
          <StyledButton
            type="button"
            data-cy="newConversation"
            onClick={this.toggleOptions}
            ref={node => {
              this.outerRef = node;
            }}
          >
            <Icons type="hollowPlus" />
            <span>{i18n.t(k.NEW1)}</span>
          </StyledButton>
          {showOptions ? (
            <Options
              handleClose={this.toggleOptions}
              outerRef={this.outerRef}
              type={this.getType(type, workflow)}
              workflow={workflow}
              setModalType={this.setModalType}
            />
          ) : null}
        </StyledNewRoom>

        {addNewRoom ? (
          <AddChatroom
            position="left"
            modalType={this.getModalType(type, workflow)}
            show={addNewRoom}
            handleClose={this.toggleNew}
            settings={{
              workflow: localWorkflow !== null ? localWorkflow : workflow
            }}
          />
        ) : null}
      </>
    );
  }
}

export default NewRoom;
