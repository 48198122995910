// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Text } from "@chakra-ui/react";

import Owner from "../Owner";
import Members from "../Members";
import Parent from "../Parent";

import Title from "src/containers/chatroom/creator/process/Title";
import Advanced from "src/containers/chatroom/creator/process/Advanced";
import { Body as FormBody } from "src/styles/form";
import * as styles from "./styles";

import { setConversationAttribute } from "src/actions/chatroom";
import { getNextSeqNo } from "src/actions/workflows";
import { getCurrentUserId, getConversationDialog } from "src/reducers";

type Props = {
  advanced: boolean,
  hideAdvanced: Function,
  handleChange: Function,
  handleOwner: Function,
  handleMembers: Function,
  handleGroups: Function,
  handleDueDate: Function,
  handleParent: Function
};

const Process = ({
  advanced,
  handleChange,
  handleOwner,
  handleMembers,
  handleGroups,
  handleDueDate,
  handleParent
}: Props) => {
  const dispatch = useDispatch();
  const { parent, templateId, members, groups, owner } = useSelector(
    ({ app }) => getConversationDialog(app)
  );
  const currentUser = useSelector(({ app }) => getCurrentUserId(app));
  const [showParent, setShowParent] = useState(false);

  useEffect(() => {
    if (templateId) {
      dispatch(getNextSeqNo(templateId));
      setShowParent(!!parent);
    }
  }, [
    templateId,
    parent,
    owner,
    currentUser,
    getNextSeqNo,
    setConversationAttribute
  ]);

  return (
    <FormBody>
      <Title id="title" handleChange={handleChange} templateId={templateId} />
      <Owner owner={owner} handleOwner={handleOwner} />
      <Members
        members={members}
        groups={groups}
        handleMembers={handleMembers}
        handleGroups={handleGroups}
      />
      <Text px={4} sx={styles.ExternalInviteInfo}>
        {i18n.t(k.TO_INVITE_SOMEONE_EXTERNALLY1)}
      </Text>
      {showParent && !advanced ? (
        <Parent parent={parent} handleParent={handleParent} />
      ) : null}
      {advanced ? (
        <Advanced
          {...{
            templateId,
            parent,
            members,
            groups,
            handleChange,
            handleOwner,
            handleMembers,
            handleGroups,
            handleDueDate,
            handleParent
          }}
        />
      ) : null}
    </FormBody>
  );
};

export default Process;
