// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React, { useCallback } from "react";
import * as R from "ramda";

import { StyledFiles } from "./styles";
import SelectFiles from "src/components/file/SelectMultiple";

type Props = {
  files: Array<string>,
  handleFiles: Function
};

const Files = ({ files, handleFiles }: Props) => {
  const handleSelect = useCallback(
    (newFile: string) => {
      if (R.includes(newFile, files)) {
        handleFiles(files.filter(file => file !== newFile));
      } else {
        handleFiles([...files, newFile]);
      }
    },
    [handleFiles, files]
  );

  return (
    <StyledFiles>
      <label>{i18n.t(k.FILES)}</label>
      <SelectFiles
        placeholder="Select files"
        value={files}
        handleSelect={handleSelect}
      />
    </StyledFiles>
  );
};

export default Files;
