// @flow

import i18n from "i18next";
import k from "src/i18n/keys";
import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";

import { Search as StyledSearch } from "./styles";
import { getWorkflowTemplates } from "src/reducers";
import { GreyInput } from "src/styles/input";

type Props = {
  search: string,
  handleSearch: Function
};

const Search = ({ search, handleSearch }: Props) => {
  const [text, setText] = useState("");
  const workflowTemplates = useSelector(({ app }) => getWorkflowTemplates(app));

  useEffect(() => {
    setText(search);
  }, [search]);

  const handleChange = useCallback(
    (e: any) => {
      const { value } = e.target;
      setText(value);
      handleSearch(value || "");
    },
    [text, workflowTemplates]
  );

  return (
    <StyledSearch>
      <GreyInput
        placeholder={i18n.t(k.SEARCH)}
        type="text"
        value={text}
        onChange={handleChange}
      />
    </StyledSearch>
  );
};

export default Search;
