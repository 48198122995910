// @flow

import React from "react";
import { useSelector } from "react-redux";
import { Tag, TagLabel, Avatar, TagRightIcon, Box } from "@chakra-ui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import { getUser } from "src/reducers";
import Icon from "src/icons";

import type { UID, UnifizeUser } from "src/types";

import * as styles from "./styles";

interface UseBooleanReturn {
  value: boolean;
  setTrue: () => void;
  setFalse: () => void;
}

type Props = {
  uid: UID,
  closeDropdown?: $PropertyType<UseBooleanReturn, "setFalse">,
  toggleDropdown?: $PropertyType<UseBooleanReturn, "setTrue">,
  edit?: $PropertyType<UseBooleanReturn, "value">,
  handleRemove?: (uid: UID) => void
};

const UserTag = ({
  uid,
  closeDropdown,
  toggleDropdown,
  edit,
  handleRemove
}: Props) => {
  const user: UnifizeUser = useSelector(({ app }) => getUser(app, uid));
  const { displayName, photoUrl } = user;
  return (
    <Tag
      data-cy="displayContainerUser"
      title={displayName}
      sx={styles.UserOrGroupTag}
      size={"lg"}
      tabIndex={0}
      onClick={() => {
        if (handleRemove) handleRemove(uid);
        else {
          if (edit) {
            closeDropdown && closeDropdown();
          } else {
            toggleDropdown && toggleDropdown();
          }
        }
      }}
    >
      <Avatar
        sx={styles.UserAndGroupAvatar}
        src={photoUrl}
        name={displayName}
        size="2xs"
      />

      <TagLabel sx={styles.UserAndGroupTagText}>{displayName}</TagLabel>
      {toggleDropdown && closeDropdown ? (
        edit ? (
          <TagRightIcon
            onClick={closeDropdown}
            sx={styles.UserOrGroupClose}
            as={ChevronUpIcon}
          />
        ) : (
          <TagRightIcon
            data-cy="toggleUserDropdown"
            onClick={toggleDropdown}
            sx={styles.UserOrGroupClose}
            as={ChevronDownIcon}
          />
        )
      ) : (
        <Box display="flex" sx={styles.IconBox}>
          <Icon
            data-cy="removeUser"
            type="decrement"
            handleClick={() => handleRemove && handleRemove(uid)}
            aria-label="delete"
          />
        </Box>
      )}
    </Tag>
  );
};

export default UserTag;
