// @flow
import React from "react";

const Italic = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_255_328"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="14"
      height="14"
    >
      <rect
        x="0.600098"
        y="0.400024"
        width="13.2"
        height="13.2"
        fill="#D9D9D9"
      />
    </mask>
    <g mask="url(#mask0_255_328)">
      <path d="M3.3501 10.85V9.47502H5.5501L7.2001 4.52502H5.0001V3.15002H10.5001V4.52502H8.5751L6.9251 9.47502H8.8501V10.85H3.3501Z" />
    </g>
  </svg>
);

export default Italic;
