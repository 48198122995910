import i18n from "i18next";
import k from "src/i18n/keys";

// TODO: Add JSDoc

export const getFilteredFields = (fieldsBySection, allFieldsById) => {
  const forbiddenFieldTypes = ["section", "subSection"];
  let filteredFields = [];
  (fieldsBySection ?? []).forEach(item => {
    if (typeof item !== "object") {
      if (
        !forbiddenFieldTypes.includes(
          allFieldsById.get(String(item)).get("type")
        )
      ) {
        filteredFields = filteredFields.concat([item]);
      }
    } else {
      filteredFields = filteredFields.concat([
        ...getFilteredFields(item.fields, allFieldsById)
      ]);
    }
  });

  return filteredFields;
};

export const metaDataLabels = {
  title: i18n.t(k.TITLE),
  owner: i18n.t(k.OWNER),
  members: i18n.t(k.PARTICIPANTS),
  dueDate: i18n.t(k.DUE_DATE),
  files: i18n.t(k.FILES),
  messages: i18n.t(k.ALL_COMMENTS_AND_MESSAGES)
};

const defaultUncheckedMetaData = ["messages"];

export const getMetaDataInitialState = () => {
  const initialState = {};

  Object.keys(metaDataLabels).forEach(key => {
    initialState[key] = !defaultUncheckedMetaData.includes(key);
  });

  return initialState;
};

export const getChecklistFieldsToCopyInitialState = (
  fields,
  defaultCopyableFields,
  fieldsById
) => {
  const initialState = {};

  fields.forEach(key => {
    const fieldType = fieldsById.get(String(key)).get("type");

    initialState[key] = forbiddenFieldTypes.includes(fieldType)
      ? false
      : defaultCopyableFields.includes(Number(key));
  });

  return initialState;
};

export const forbiddenFieldTypes = [
  "revision",
  "approval",
  "link",
  "section",
  "subSection"
];
