// @flow

import React, { useState, useEffect } from "react";

import { Picture as StyledPicture } from "../styles";
import Initial from "./initial";

import type { UnifizeUser } from "src/types";

type Props = {
  user: UnifizeUser,
  location?: string,
  isActive?: boolean
};

const Picture = ({ user, location, isActive, ...restProps }: Props) => {
  const [error, setError] = useState({});

  useEffect(() => {
    setError({
      [user?.uid]: false
    });
  }, [user?.uid]);

  if (user?.photoUrl && !error[user?.uid]) {
    return (
      <StyledPicture
        src={user.photoUrl}
        onError={err => {
          console.error(err);
          setError({
            [user?.uid]: true
          });
        }}
        alt={user?.displayName}
        location={location}
        isActive={isActive}
        {...restProps}
      />
    );
  }

  return <Initial user={user} {...restProps} />;
};

export default Picture;
