import * as R from "ramda";

/**
 * Extracts user and group IDs from the selected values
 * @param {Object[]} value - the list of user and group details
 * @return {Array<string | number>} - a list of user and groupIds
 */
export const getUserAndGroupIds = value => {
  return R.pipe(R.head, R.is(Object))(value || [])
    ? value.map(
        userOrGroup =>
          userOrGroup?.[userOrGroup?.type === "group" ? "id" : "uid"]
      )
    : value || [];
};

/**
 * Filters and maps user and group details to selected values
 * @param {Object[]} orgUsersAndGroups - list of all org user and group details
 * @param {Array<string | number>} userAndGroupIds - list of selected user and group IDs
 * @return {Object[]} - list of selected users and group details
 */
export const getMultipleSelectedValues = (
  orgUsersAndGroups,
  userAndGroupIds
) => {
  return orgUsersAndGroups.reduce((acc, { type, id }) => {
    if (userAndGroupIds.includes(id)) {
      if (type === "user") acc.push({ type, uid: id });
      else acc.push({ type, id });
    }
    return acc;
  }, []);
};
