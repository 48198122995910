// @flow
import React from "react";

const NumberedList = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_255_343"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="14"
      height="14"
    >
      <rect
        x="0.600098"
        y="0.400024"
        width="13.2"
        height="13.2"
        fill="#D9D9D9"
      />
    </mask>
    <g mask="url(#mask0_255_343)">
      <path d="M2.25 12.5V11.675H3.625V11.2625H2.8V10.4375H3.625V10.025H2.25V9.2H3.9C4.05583 9.2 4.18646 9.25271 4.29187 9.35813C4.39729 9.46354 4.45 9.59417 4.45 9.75V10.3C4.45 10.4558 4.39729 10.5865 4.29187 10.6919C4.18646 10.7973 4.05583 10.85 3.9 10.85C4.05583 10.85 4.18646 10.9027 4.29187 11.0081C4.39729 11.1135 4.45 11.2442 4.45 11.4V11.95C4.45 12.1058 4.39729 12.2365 4.29187 12.3419C4.18646 12.4473 4.05583 12.5 3.9 12.5H2.25ZM2.25 8.65V7.1375C2.25 6.98167 2.30271 6.85104 2.40812 6.74563C2.51354 6.64021 2.64417 6.5875 2.8 6.5875H3.625V6.175H2.25V5.35H3.9C4.05583 5.35 4.18646 5.40271 4.29187 5.50812C4.39729 5.61354 4.45 5.74417 4.45 5.9V6.8625C4.45 7.01833 4.39729 7.14896 4.29187 7.25438C4.18646 7.35979 4.05583 7.4125 3.9 7.4125H3.075V7.825H4.45V8.65H2.25ZM3.075 4.8V2.325H2.25V1.5H3.9V4.8H3.075ZM5.55 10.85V9.75H12.15V10.85H5.55ZM5.55 7.55V6.45H12.15V7.55H5.55ZM5.55 4.25V3.15H12.15V4.25H5.55Z" />
    </g>
  </svg>
);

export default NumberedList;
