// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import { connect } from "react-redux";
import React, { useCallback, useState } from "react";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";

import {
  FormControl,
  FormLabel,
  Box,
  Button,
  Input,
  FormHelperText
} from "@chakra-ui/react";
import FieldSelect from "../FieldSelect";
import {
  setNewChartDialogAttributes,
  createChart,
  updateChart
} from "src/actions/chart";
import MultipleSelection from "./MultipleSelection";

import type { AppState, NewChartDialog } from "src/types";
import SortBySelector from "./SortBySelector";
import { AdvanceBtn, LabelInfo } from "./styles";
import { dark, inkLight } from "src/styles/constants/colors";

type Props = {
  newChartDialog: NewChartDialog,
  _editChart: Function,
  _createChart: Function,
  _setNewChartDialogAttributes: Function,
  name: string
};

const CountBarChart = ({
  newChartDialog,
  _editChart,
  _createChart,
  _setNewChartDialogAttributes,
  name
}: Props) => {
  const [showAdvance, setShowAdvance] = useState(false);
  const { title, x, computationField } = newChartDialog;
  const isDisabled =
    title.length === 0 ||
    x === null ||
    (name === "form-count-stacked" && computationField === null);
  const handleYaxisLabel = useCallback(
    (event: any) => {
      _setNewChartDialogAttributes({
        yAxisLabel: event.target.value
      });
    },
    [_setNewChartDialogAttributes]
  );
  const handlePrimaryField = useCallback(
    (value: ?string) => {
      _setNewChartDialogAttributes({
        x: value
      });
    },
    [_setNewChartDialogAttributes]
  );

  const handleGroupByField = useCallback(
    (value: ?string) => {
      _setNewChartDialogAttributes({
        y: value
      });
    },
    [_setNewChartDialogAttributes]
  );

  const handleFields = useCallback(() => {
    _setNewChartDialogAttributes({
      loading: true
    });
    if (newChartDialog.edit) {
      _editChart();
    } else {
      _createChart();
    }
  }, [_setNewChartDialogAttributes, _editChart, _createChart, newChartDialog]);

  const handleShowAdvanceToggle = e => {
    // Since the button is inside a form, using this to prevent the
    // form from submitting
    e.preventDefault();
    setShowAdvance(s => !s);
  };

  const handleFormFieldSelect = (value: string = "") => {
    _setNewChartDialogAttributes({
      computationField: value
    });
  };

  return (
    <>
      {name === "form-count-stacked" ? (
        <FormControl id="form" mb={3}>
          <FormLabel fontWeight={600}>{i18n.t(k.SELECT_FORM1)}</FormLabel>
          <FormHelperText color={inkLight}>
            {i18n.t(k.THE_COUNT_WILL_REPRESENT_THE_T)}
          </FormHelperText>
          <FieldSelect
            value={newChartDialog.computationField}
            handleSelect={handleFormFieldSelect}
            isForm={true}
            fieldType="form"
          />
        </FormControl>
      ) : null}

      <FormControl id="primaryField" mb={3}>
        <FormLabel fontWeight={600}>
          {i18n.t(k.PRIMARY_FIELD)} <LabelInfo>{i18n.t(k.X_AXIS1)}</LabelInfo>
        </FormLabel>
        <FormHelperText color={inkLight}>
          {i18n.t(k.PRIMARY_FIELDS_ARE_THE_MAIN_UN)}
        </FormHelperText>
        <FieldSelect
          value={newChartDialog.x}
          dependentField={newChartDialog.y || null}
          handleSelect={handlePrimaryField}
        />
      </FormControl>

      <FormControl id="groupByField" mb={3}>
        <FormLabel fontWeight={600}>
          {i18n.t(k.GROUP_BY_FIELD)} <LabelInfo>{i18n.t(k.OPTIONAL)}</LabelInfo>
        </FormLabel>
        <FormHelperText color={inkLight}>
          {i18n.t(k.GROUP_BY_FIELD_TYPE_IS_APPLIED)}
        </FormHelperText>
        <FieldSelect
          value={newChartDialog.y || null}
          dependentField={newChartDialog.x}
          handleSelect={handleGroupByField}
        />
      </FormControl>
      <AdvanceBtn onClick={handleShowAdvanceToggle}>
        {i18n.t(k.ADVANCED_SETTINGS1)}
        {showAdvance ? (
          <ChevronUpIcon marginLeft="1.625rem" color={dark} />
        ) : (
          <ChevronDownIcon marginLeft="1.625rem" color={dark} />
        )}
      </AdvanceBtn>
      {showAdvance && (
        <>
          <FormControl id="multipleValue" mb={3}>
            <FormLabel fontWeight={600}>
              {i18n.t(k.MULTIPLE_VALUE_CALCULATION)}
            </FormLabel>
            <FormHelperText color={inkLight}>
              {i18n.t(k.IF_A_FIELD_CONTAINS_MULTIPLE_V1)}
            </FormHelperText>
            <MultipleSelection value={newChartDialog.relativeCount} />
          </FormControl>
          <FormControl id="sortBy" mb={3}>
            <FormLabel fontWeight={600}>{i18n.t(k.SORT_BY2)}</FormLabel>
            <SortBySelector
              setNewChartDialogAttributes={_setNewChartDialogAttributes}
              value={newChartDialog.sort.orderBy || "default"}
            />
          </FormControl>
          <FormControl id="yAxisLabel" mb={3}>
            <FormLabel fontWeight={600}>{i18n.t(k.Y_AXIS_LABEL)}</FormLabel>
            <Input
              type="text"
              value={newChartDialog.yAxisLabel}
              onChange={handleYaxisLabel}
              placeholder={i18n.t(k.ENTER_LABEL)}
              size="sm"
            />
          </FormControl>
        </>
      )}

      <Box mb={3} mt={6}>
        {newChartDialog.edit ? (
          <Button
            onClick={handleFields}
            disabled={isDisabled}
            type="button"
            isLoading={newChartDialog.loading}
            size="sm"
            colorScheme="blue"
          >
            {i18n.t(k.SAVE_CHANGES1)}
          </Button>
        ) : (
          <Button
            onClick={handleFields}
            disabled={isDisabled}
            type="button"
            isLoading={newChartDialog.loading}
            size="sm"
            colorScheme="blue"
          >
            {i18n.t(k.CREATE_CHART)}
          </Button>
        )}
      </Box>
    </>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  newChartDialog: app.charts.newChartDialog
});

export default connect(mapStateToProps, {
  _setNewChartDialogAttributes: setNewChartDialogAttributes,
  _createChart: createChart,
  _editChart: updateChart
})(CountBarChart);
