// @flow

/**
 * Sagas to handle file Upload
 *
 */
import { toast } from "react-toastify";
import uuid from "uuid/v4";
import type { Saga } from "redux-saga";
import { call, put, takeEvery, select } from "redux-saga/effects";
import * as R from "ramda";

import * as atypes from "src/constants/actionTypes";
import * as fileApi from "src/api/file";
import getAppState from "src/selectors";
import type { Action } from "src/types";

function* uploadFile({ payload }: Action): Saga<void> {
  const { data, folderId } = payload;
  try {
    const { allowFileUpload } = (yield select(getAppState)).orgs;

    if (allowFileUpload) {
      const response = yield call(
        fileApi.uploadFile,
        data,
        folderId,
        payload.currentFile,
        payload.description
      );

      yield put({
        type: atypes.UPLOAD_FILE_SUCCESS,
        payload: response
      });
    } else {
      toast.error("Your org has disabled file uploads");
      yield put({ type: atypes.UPLOAD_FILE_FAILURE, payload: {} });
    }
  } catch (error) {
    yield put({ type: atypes.UPLOAD_FILE_FAILURE, payload: error });
  }
}

function* watchUploadFile(): any {
  yield takeEvery(atypes.UPLOAD_FILE_REQUEST, uploadFile);
}

function* pinFileToChat(action: Action): any {
  const { name, roomId, description } = action.payload;
  try {
    yield call(fileApi.pinFile, roomId, name, description);
    yield put({
      type: atypes.PIN_FILE_TO_CHAT_SUCCESS,
      payload: { name, roomId }
    });
    toast.success("File successfully uploaded!");
  } catch (error) {
    yield put({
      type: atypes.PIN_FILE_TO_CHAT_FAILURE,
      payload: { roomId, error }
    });
    toast.error("Error uploading the file!");
  }
}

function* watchPinFileToChat(): any {
  yield takeEvery(atypes.PIN_FILE_TO_CHAT_REQUEST, pinFileToChat);
}

function* uploadFileFromChatroom({ payload }: Action): Saga<void> {
  const { roomId, data: filesData, location } = payload;
  const files: Array<File> =
    R.type(filesData) === "Array" ? filesData : [filesData];

  let currentFile: ?File = null;
  try {
    const { allowFileUpload } = (yield select(getAppState)).orgs;
    if (allowFileUpload) {
      for (let file of files) {
        currentFile = file;
        const {
          name,
          url: signedURL,
          contentType
        } = yield call(fileApi.getSignedURLForUpload, file.name);

        const handleProgress = (progressEvent: any) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          payload.dispatch({
            type: atypes.UPLOAD_FILE_TO_CHAT_PROGRESS,
            payload: { roomId, progress: percentCompleted, name: file.name }
          });
        };

        const response = yield call(fileApi.uploadFileToAWS, {
          signedURL,
          fileData: file,
          name,
          handleProgress,
          contentType
        });

        const versionId =
          response.headers["x-ms-version-id"] ??
          response.headers["x-amz-version-id"];
        yield call(fileApi.createFile, {
          name,
          versionId,
          size: file.size,
          originalName: file.name,
          folderId: null,
          mimeType: file.type
        });

        yield call(
          fileApi.pinFile,
          roomId,
          name,
          R.type(payload?.description) === "String"
            ? payload.description
            : payload?.description[file.name] || ""
        );

        yield put({
          type: atypes.CLEAR_FILE_UPLOAD_STATE,
          payload: { roomId, name: file.name }
        });

        yield put({
          type: atypes.UPLOAD_FILE_TO_CHAT_SUCCESS
        });
      }
    } else {
      toast.error("Your org has disabled file uploads");
      throw new Error("Your org has disabled file uploads");
    }
  } catch (error) {
    console.error("Couldn't Upload File", currentFile, "Error:", error);
    if (
      currentFile &&
      (currentFile.name.lastIndexOf(".") < 0 ||
        currentFile.name.lastIndexOf(".") >= currentFile.name.length - 1)
    ) {
      toast.error(
        `Cannot upload "${String(
          currentFile?.name
        )}". Please ensure your file name includes an extension.`
      );
    }
    yield put({
      type: atypes.UPLOAD_FILE_TO_CHAT_FAILURE,
      payload: {
        name: currentFile?.name ?? "unknown",
        roomId,
        error,
        analytics: {
          location
        }
      }
    });
  }
}

function* watchUploadFileFromChatroom(): any {
  yield takeEvery(atypes.UPLOAD_FILE_TO_CHAT_REQUEST, uploadFileFromChatroom);
}

function* uploadFileToTempStorage({ payload }: Action): any {
  try {
    const { allowFileUpload } = (yield select(getAppState)).orgs;

    if (allowFileUpload) {
      const { data: fileData } = payload;
      const name = uuid(Math.random() * 1000);
      const tempFile = yield call(
        fileApi.uploadFileToTempStorage,
        name,
        fileData
      );

      yield put({
        type: atypes.UPLOAD_FILE_TO_TEMP_STORAGE_SUCCESS,
        payload: tempFile
      });
    } else {
      toast.error("Your org has disabled file uploads");
      throw new Error("Your org has disabled file uploads");
    }
  } catch (err) {
    yield put({
      type: atypes.UPLOAD_FILE_TO_TEMP_STORAGE_FAILURE,
      payload: { err }
    });
  }
}

function* watchUploadFileToTempStorage(): any {
  yield takeEvery(
    atypes.UPLOAD_FILE_TO_TEMP_STORAGE_REQUEST,
    uploadFileToTempStorage
  );
}

export default [
  watchUploadFile(),
  watchUploadFileFromChatroom(),
  watchPinFileToChat(),
  watchUploadFileToTempStorage()
];
