// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";
import { useSelector } from "react-redux";

import { getGroup } from "src/reducers";

import {
  Box,
  List,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton
} from "@chakra-ui/react";
import Icon from "src/icons";
import * as styles from "src/components/messages/Chat/Notification/styles";
import GroupMemberCard from "src/components/messages/Chat/Notification/GroupMemberCard";

type Props = {
  id: number,
  isOpen: boolean,
  onClose: Function
};

const GroupMembersModal = ({ id, isOpen, onClose }: Props) => {
  const { title, members } = useSelector(({ app }) => getGroup(app, id)) || {
    title: i18n.t(k._4),
    members: []
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      onEsc={onClose}
      size={"xs"}
      isCentered
    >
      <ModalOverlay />
      <ModalContent sx={styles.Modal}>
        <Box sx={styles.ModalHeader}>
          <ModalHeader sx={styles.GroupMembersModalTitle}>{title}</ModalHeader>
          <ModalCloseButton sx={styles.GroupModalCloseButton}>
            <Icon type="close" color="black" size={14} />
          </ModalCloseButton>
        </Box>

        <ModalBody sx={{ py: 0 }}>
          <List sx={{ pl: 0 }}>
            {members.map(user => (
              <GroupMemberCard key={user} id={user} />
            ))}
          </List>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default GroupMembersModal;
