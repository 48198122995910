// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import { connect } from "react-redux";
import React from "react";

import Refresh from "./Refresh";
import Share from "./Share";
import {
  MenuBar as StyledMenuBar,
  Count
} from "src/components/Manage/Workflow/Instances/MenuBar/styles";
import { getInstanceFilterId } from "src/reducers";
import { downloadInstance } from "src/actions/forms";
import { setNewWorkflow } from "src/actions/chatroom";
import Icon from "src/icons";
import BlueOutlineButton from "src/components/Buttons/BlueOutline";
import { HStack } from "@chakra-ui/react";

import type { AppState } from "src/types";

type Props = {
  id: number,
  count: string,
  _downloadInstance: Function,
  reportId: ?number
};

const MenuBar = ({ id, count, _downloadInstance, reportId }: Props) => {
  return (
    <StyledMenuBar>
      <Count>
        {count} {i18n.t(k.RESULTS)}
      </Count>
      <HStack spacing={4}>
        <Refresh reportId={reportId} />

        <BlueOutlineButton
          leftIcon={<Icon type="saveAlt" />}
          onClick={() => _downloadInstance(id)}
        >
          {i18n.t(k.DOWNLOAD)}
        </BlueOutlineButton>

        <Share />
      </HStack>
    </StyledMenuBar>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  id: getInstanceFilterId(app),
  count: app.form.instanceCount
});

export default connect(mapStateToProps, {
  _downloadInstance: downloadInstance,
  _setNewWorkflow: setNewWorkflow
})(MenuBar);
