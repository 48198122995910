// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React, { useCallback, useState, useEffect } from "react";
import { connect } from "react-redux";
import { useDebounce } from "use-debounce";

import { StyledInput } from "./styles";

import type { ReactRef, AppState } from "src/types";

type Props = {
  title: string,
  outerRef: ReactRef,
  handleSearch: Function,
  onFocus: Function,
  name: string
};

const Input = ({ title, outerRef, handleSearch, onFocus, name }: Props) => {
  const [text, setText] = useState(title || "");
  const [value] = useDebounce(text, 80);
  const [changedValue] = useDebounce(value, 50);

  useEffect(() => {
    if ((title || "") !== changedValue) {
      setText(title);
    }
  }, [changedValue, title, setText]);

  // Updating redux state when local state is changed after time interval
  useEffect(() => {
    handleSearch(value);
  }, [value]);

  const handleText = useCallback(
    (e: any) => {
      setText(e.target.value);
    },
    [setText]
  );

  return (
    <StyledInput
      type="text"
      value={text}
      placeholder={`${i18n.t(k.SEARCH)} ${name || ""}`}
      onChange={handleText}
      ref={outerRef}
      onFocus={onFocus}
    />
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  name: app.chatRooms.filters.current.name
});

export default connect(mapStateToProps, {})(Input);
