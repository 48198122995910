// @flow

import type { State } from "src/types";

const routesMap = {
  HOME: "/",
  SIGN_IN: "/signin/:page?",
  MOBILE_SSO_SIGNIN: "/mobile-sso-signin",
  SIGN_UP: "/signup/:page?",
  SET_CURRENT_CHATROOM_REQUEST: {
    path: "/conversation/:id/:query?",
    confirmLeave: (state: State) => {
      // Prevent navigating to other routes if the user is on the lite
      // app
      if (state.app.srw.isSingleResponse) {
        return {
          title: "Restricted page",
          body: "Not possible to access this page from the lite app, please login to continue.",
          submitBtnText: "Ok",
          hideCancelBtn: true
        };
      }
    }
  },
  SET_CURRENT_CHATROOM_ALIAS: "/chatroom/:id",
  SET_CONTACTS_REQUEST: "/contacts/:query?",
  SET_GROUPS_REQUEST: "/groups/:query?",
  SET_PROCESS_REQUEST: "/manage/:query?",
  SET_FORMS_REQUEST: "/forms/:query?",
  SET_PROCESS_ALIAS_REQUEST: "/process/:query?", // Adding /process as alias to /manage
  SET_REPORTS_REQUEST: "/reports/:id?",
  SET_FILE_REQUEST: "/file/:query?",
  SET_FILTER_VIEW: "/filter/:query?",
  JOIN: "/join",
  REDIRECT_TO_MOBILE_APP: "/app",
  NO_NETWORK: "/offline",
  SET_USER_ANALYTICS_REQUEST: "/user-analytics",
  SET_DASHBOARD_REQUEST: "/dashboard/:id?",
  SET_ORG_SETTINGS_REQUEST: "/org-settings/:id?",
  EDIT_PROCESS_REQUEST: {
    path: "/manage/process/edit/:id",
    confirmLeave: (state: State) => {
      if (
        !state.app.workflow.builderDialog.builderComplete &&
        state.app.workflow.builderDialog.isModified
        // If access to process settings is disabled, prevent showing
        // the confirmation modal
      ) {
        return {
          title: "Save Changes",
          body: "Do you want to save before exiting the process builder?",
          cancelText: "Exit",
          submitText: "Save & Exit"
        };
      }
    }
  },
  CREATE_PROCESS_REQUEST: {
    path: "/manage/process/new",
    confirmLeave: (state: State) => {
      if (
        !state.app.workflow.builderDialog.builderComplete &&
        state.app.workflow.builderDialog.isModified
      ) {
        return {
          title: "Create Process",
          body: "Process creation is in progress. How do you want to proceed?",
          cancelText: "Exit",
          submitText: "Create Process & Exit"
        };
      }
    }
  },
  SET_HOME_SCREEN_REQUEST: "/home",
  AUTHORIZE_OAUTH_APP_PAGE: "/oauth/:query?",
  ORG_CLONE_PAGE: "/clone",
  SET_CHART_REQUEST: "/chart/new/:query?",
  SELECTED_CHART_REQUEST: "/chart/new/:query?",
  EDIT_CHART: "/chart/edit/:id/:query?",
  SSO_REDIRECT: "/sso-redirect"
};

export { routesMap };
