// @flow
import React from "react";

const RichText = ({ ...restProps }: {}) => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...restProps}
  >
    <mask
      id="mask0_299_187"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="36"
      height="36"
    >
      <rect width="36" height="36" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_299_187)">
      <path d="M13.6875 31.5L14.2875 27.675H17.775L20.85 8.325H17.1L16.0125 15.075H12.0375L13.725 4.5H33.1875L31.5 15.075H27.525L28.575 8.325H24.825L21.75 27.675H25.2375L24.6375 31.5H13.6875ZM5.625 10.5L6.1125 7.5H11.7375L11.25 10.5H5.625ZM4.6875 16.5L5.175 13.5H10.8L10.3125 16.5H4.6875ZM2.8125 28.5L3.2625 25.5H12.6375L12.1875 28.5H2.8125ZM3.75 22.5L4.2375 19.5H13.6125L13.125 22.5H3.75Z" />
    </g>
  </svg>
);

export default RichText;
