// @flow

import React from "react";
import { useSelector } from "react-redux";
import { Skeleton } from "@chakra-ui/react";

import FilePreview from "src/components/messages/Chat/Notification/FileOrEmail/FilePreview";

import { getFilePreviewURL } from "src/reducers";
import { getFilePreviewSupport } from "src/utils/file";

import type { UnifizeFile } from "src/types";

type Props = {
  file: UnifizeFile,
  isPreviewEnabled: boolean
};

const FilePreviewWrapper = ({ file, isPreviewEnabled }: Props) => {
  const filePreview = useSelector(({ app }) =>
    getFilePreviewURL(app, file?.name)
  );
  const hasPreviewSupport = getFilePreviewSupport(file);

  return hasPreviewSupport && isPreviewEnabled ? (
    filePreview ? (
      <FilePreview preview={filePreview?.url} />
    ) : (
      <Skeleton height="372px" width="100%" />
    )
  ) : null;
};

export default FilePreviewWrapper;
