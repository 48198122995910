// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";

import { Menu as StyledMenu } from "./styles";

import type { Workflow } from "src/types";

type Props = {
  workflow: Workflow,
  handleEdit: Function,
  handleDelete: Function
};

const Menu = ({ workflow, handleEdit, handleDelete }: Props) => (
  <StyledMenu hover>
    {workflow?.settings?.editable !== false && (
      <li onClick={() => handleEdit(workflow)}>{i18n.t(k.EDIT_PROCESS)}</li>
    )}
    <li onClick={() => handleDelete(workflow)}>{i18n.t(k.DELETE_PROCESS)}</li>
  </StyledMenu>
);

export default Menu;
