// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";
import { connect } from "react-redux";

import Common from "./Common";
import LinkedRoom from "./LinkedRoom";
import { getCurrentRoomId, getChatroomAddress } from "src/reducers";
import type { Message, RoomId, ChatroomAddress } from "src/types";

type Props = {
  currentRoom: RoomId,
  roomAddress: ChatroomAddress,
  message: Message
};

const Title = ({ currentRoom, roomAddress, message }: Props) => {
  const { data } = message;
  const { new: newTitle, old: oldTitle, roomId } = data;
  let showLink = false;
  let notifMssg = i18n.t(k._4);
  if (roomId === currentRoom) notifMssg = i18n.t(k.THE);
  else [notifMssg, showLink] = ["related conversation", true];

  if (newTitle && oldTitle)
    return (
      <Common {...message}>
        {" "}
        {i18n.t(k.UPDATED1)} {notifMssg} {i18n.t(k.TITLE_TO)}{" "}
        {showLink ? (
          <LinkedRoom id={roomId} chatroomAddress={roomAddress} />
        ) : (
          <strong>{newTitle}</strong>
        )}{" "}
        {i18n.t(k.FROM1)} {oldTitle}
      </Common>
    );

  if (newTitle)
    return (
      <Common {...message}>
        {" "}
        {i18n.t(k.ADDED)} {notifMssg} {i18n.t(k.TITLE1)}{" "}
        {showLink ? (
          <LinkedRoom id={roomId} chatroomAddress={roomAddress} />
        ) : (
          <strong>{newTitle}</strong>
        )}
      </Common>
    );

  return (
    <Common {...message}>
      {" "}
      {i18n.t(k.REMOVED)} {notifMssg} {i18n.t(k.TITLE1)}{" "}
      <strong>{oldTitle}</strong>
    </Common>
  );
};

const mapStateToProps = ({ app }, { message }) => {
  const { data } = message;
  const { roomId } = data;
  return {
    currentRoom: parseInt(getCurrentRoomId(app), 10),
    roomAddress: getChatroomAddress(app, roomId)
  };
};

export default connect(mapStateToProps)(Title);
