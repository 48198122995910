// @flow

import React, { useState, useEffect } from "react";

import { ChecklistSettings } from "src/styles/checklist";
import type { RichTextFieldSettings } from "src/types";

type Props = {
  settings: RichTextFieldSettings,
  saveSettings: Function
};

const RichText = ({ settings, saveSettings }: Props) => {
  const [state, setState] = useState<RichTextFieldSettings>({
    placeholder: ""
  });

  useEffect(() => {
    if (settings) {
      const { placeholder } = settings;
      setState({
        placeholder
      });
    }
  }, [settings]);

  const handleChange = (e: any) => {
    const { value } = e.target;
    setState(prev => ({ ...prev, placeholder: value }));
  };

  useEffect(() => {
    saveSettings(state);
  }, [state]);

  const { placeholder } = state;

  return (
    <ChecklistSettings>
      <form>
        <div>
          <label htmlFor="placeholder">Placeholder</label>
          <input
            type="text"
            id="placeholder"
            value={placeholder}
            onChange={handleChange}
            autoFocus
          />
        </div>
      </form>
    </ChecklistSettings>
  );
};

export default RichText;
