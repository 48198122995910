// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React, { useRef, useCallback, useState } from "react";
import uuid from "uuid/v4";
import { useDispatch } from "react-redux";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { Button, VStack } from "@chakra-ui/react";
import type { SystemCSSProperties } from "@chakra-ui/react";
import {
  getCroppedImgBlob,
  getFileExtension,
  knownFileFormats
} from "src/utils/file";
import * as R from "ramda";

import { UploadButton } from "./styles";
import type { RoomId, ColumnId } from "src/types";
import { toast } from "react-toastify";
import { showFileUploadModal } from "src/actions/file";

type Props = {
  isUploading: boolean,
  isEmailAttachment: boolean,
  roomId: RoomId,
  location: string,
  togglePopover?: ?Function,
  uploadFileToChatroom: Function,
  render: Function,
  uploadTempAttachment: Function,
  originalFile?: ?Object,
  uploadFileToChecklist: Function,
  fieldId: number,
  columnId: ColumnId,
  multiple: boolean,
  formId?: number,
  value: Array<string>,
  uploadCustomSignature: Function,
  cropping?: boolean,
  setCropping: Function,
  setChecklistValue?: Function,
  handleFiles?: Function,
  multipleFiles?: boolean,
  onClose?: Function,
  promptCallback?: ?Function,
  variant?: string,
  styles?: SystemCSSProperties
};

const Upload = ({
  isUploading,
  location,
  render,
  roomId,
  togglePopover = () => {},
  uploadFileToChatroom,
  uploadTempAttachment,
  isEmailAttachment,
  originalFile = null,
  uploadFileToChecklist,
  fieldId,
  columnId,
  multiple,
  value,
  formId = null,
  uploadCustomSignature,
  cropping,
  setCropping = () => {},
  setChecklistValue,
  handleFiles,
  multipleFiles,
  onClose,
  promptCallback,
  variant = "uBlueFilled",
  styles = {}
}: Props) => {
  const fileRef: { current: any } = useRef();
  const dispatch = useDispatch();
  const [crop, setCrop] = useState({});

  const [file, setFile] = useState([]);
  const [fileExtension, setFileExtension] = useState("");
  const imgRef = useRef(null);

  const storageFileName = uuid();

  const handleFileChange = useCallback(() => {
    const fileData = fileRef.current.files[0];
    const fileName = fileData.name;
    const currFileExtension = getFileExtension(fileName);

    if (handleFiles) {
      handleFiles(Array.from(fileRef.current.files));
    } else if (originalFile !== null && location !== "checklist-upload") {
      uploadFileToChatroom(
        dispatch,
        location,
        fileData,
        roomId,
        fileData.name,
        originalFile
      );
    } else if (location === "signature-upload") {
      if (!knownFileFormats.image.includes(currFileExtension)) {
        return toast.error("Invalid file format");
      }

      const reader = new FileReader();
      reader.addEventListener("load", () => setFile(reader.result));
      reader.readAsDataURL(fileData);

      setCropping(true);
      setFileExtension(currFileExtension);
    } else if (!isEmailAttachment && location !== "checklist-upload") {
      dispatch(
        showFileUploadModal({
          viewId: roomId,
          files: Array.from(fileRef.current.files)
        })
      );
    } else if (location === "checklist-upload") {
      const fileInfo = multipleFiles ? fileRef.current.files : fileData;
      uploadFileToChecklist({
        fileData: fileInfo,
        roomId,
        storageFileName,
        fieldId,
        columnId,
        value: (value || []).map(file =>
          typeof file === "object" ? file.name : file
        ),
        multiple,
        location,
        dispatch,
        setChecklistValue,
        formId
      });
      if (promptCallback) promptCallback();
    } else uploadTempAttachment(fileData, dispatch);

    if (togglePopover) togglePopover();
    if (onClose) onClose();
  }, [
    location,
    roomId,
    null,
    fieldId,
    value,
    multiple,
    isEmailAttachment,
    uploadFileToChatroom,
    uploadTempAttachment,
    togglePopover,
    originalFile,
    uploadFileToChecklist
  ]);

  const uploadCroppedImage = async () => {
    const image = await getCroppedImgBlob(
      imgRef.current,
      crop,
      "signature",
      fileExtension
    );
    const imageFile = new File([image], `signature.${fileExtension}`);

    if (location === "signature-upload") {
      uploadCustomSignature({
        fileName: imageFile.name,
        fileData: imageFile,
        roomId,
        fieldId,
        dispatch
      });
      setCropping(false);
    }
  };

  const onLoad = useCallback(img => {
    imgRef.current = img;
  }, []);

  const triggerFileBrowser = useCallback(() => {
    fileRef.current.click();
  }, []);

  return cropping ? (
    <VStack alignItems="start">
      <Button
        size="xs"
        variant={variant}
        sx={{ ...styles, maxWidth: "162px" }}
        onClick={uploadCroppedImage}
      >
        {i18n.t(k.CROP_UPLOAD)}
      </Button>
      <ReactCrop
        src={file}
        crop={crop}
        onChange={newCrop => setCrop(newCrop)}
        onImageLoaded={onLoad}
        imageStyle={{ maxWidth: "100%", maxHeight: "300px" }}
      />
    </VStack>
  ) : (
    <UploadButton>
      <input
        data-cy="fileInput"
        type="file"
        ref={fileRef}
        onChange={handleFileChange}
        disabled={isUploading}
        multiple={R.type(multipleFiles) === "Boolean" ? multipleFiles : false}
      />

      {render(triggerFileBrowser)}
    </UploadButton>
  );
};

export default Upload;
