// @flow

import React, { Component } from "react";
import * as R from "ramda";

import Checkbox from "src/components/Checkbox.old";
import Radio from "src/components/Radio";
import { ChecklistSettings } from "src/styles/checklist";
import { Label } from "./styles";
import { InlineFormGroup, Section } from "./Conversation/styles";
import AlertModal from "src/components/Modal/Alert";
import type { SelectFieldSettings } from "src/types";

type Props = {
  type: string,
  settings: ?SelectFieldSettings,
  saveSettings: Function
};

type State = {
  placeholder: ?string,
  options: ?string,
  adhoc: ?boolean,
  multiple: ?boolean,
  sortBy: ?string,
  warningModalShown: boolean
};

class Select extends Component<Props, State> {
  state = {
    placeholder: "",
    options: "",
    adhoc: false,
    multiple: false,
    sortBy: "default",
    warningModalShown: false
  };

  componentDidMount() {
    const { settings } = this.props;
    if (settings) {
      const { options } = settings; // $FlowFixMe
      this.setState({ ...settings, options: R.join("\n", options || []) });
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      prevState.options !== this.state.options ||
      prevState.adhoc !== this.state.adhoc ||
      prevState.multiple !== this.state.multiple ||
      prevState.placeholder !== this.state.placeholder ||
      prevState.sortBy !== this.state.sortBy
    ) {
      const { options } = this.state;

      const newSettings = {
        ...this.state,
        options:
          options && options.length > 0 ? R.split("\n", options || "") : []
      };

      this.props.saveSettings(newSettings);
    }
  }

  handleChange = (e: any) => {
    const { id, value } = e.target;
    this.setState({ [id]: value });
  };

  handleChecked = (e: any) => {
    const { id, checked } = e.target;
    this.setState({ [id]: checked });
  };

  toggleMultiple = () => {
    if (!this.state.multiple) {
      this.setState({ multiple: true });
    } else {
      this.setState({ warningModalShown: true });
    }
  };

  setSortBy = (sortBy: string) => {
    this.setState({ sortBy });
  };

  updateMultiPicklistSetting = () => {
    this.setState({ multiple: false, warningModalShown: false });
  };

  closeWarningModal = () => {
    this.setState({ warningModalShown: false });
  };

  render() {
    const { placeholder, adhoc, options, multiple, sortBy, warningModalShown } =
      this.state;

    return (
      <>
        <AlertModal
          shown={warningModalShown}
          onSubmit={this.updateMultiPicklistSetting}
          onClose={this.closeWarningModal}
          title="Change picklist settings"
          body="Are you sure you want to change multi select to single select? For any fields where multiple values exist, it will get replaced by a single value."
          submitBtnText="Change setting"
        />

        <ChecklistSettings>
          <form>
            <div>
              <label htmlFor="placeholder">Placeholder</label>
              <input
                type="text"
                id="placeholder"
                value={placeholder}
                onChange={this.handleChange}
                autoFocus
              />
            </div>

            <div>
              <label htmlFor="placeholder">Options</label>
              <textarea
                value={options}
                id="options"
                onChange={this.handleChange}
              />
            </div>

            <div>
              <Checkbox
                handleChange={this.toggleMultiple}
                checked={Boolean(multiple)}
                id="multiple"
              />

              <span>Allow multiple</span>
              <Checkbox
                handleChange={this.handleChecked}
                checked={Boolean(adhoc)}
                id="adhoc"
              >
                Add Options Dynamically
              </Checkbox>
            </div>

            <Section>
              <Label>Sort by</Label>
              <InlineFormGroup title="Default">
                <Radio
                  id="sortByDefault"
                  checked={sortBy === "default"}
                  handleChange={() => this.setSortBy("default")}
                />

                <span>Default</span>
              </InlineFormGroup>
              <InlineFormGroup title="Alphabetical">
                <Radio
                  id="sortByAlphabetical"
                  checked={sortBy === "alphabetical"}
                  handleChange={() => this.setSortBy("alphabetical")}
                />

                <span>Alphabetical</span>
              </InlineFormGroup>
            </Section>
          </form>
        </ChecklistSettings>
      </>
    );
  }
}

export default Select;
