// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";

import { NameOrEmail as StyledEmail } from "./styles";

import type { UnifizeUser } from "src/types";

type Props = {
  user: UnifizeUser
};

const Email = ({ user }: Props) => (
  <StyledEmail title={user.email}>
    <span>{user.loading ? i18n.t(k.LOADING_USER) : user.email}</span>
  </StyledEmail>
);

export default Email;
