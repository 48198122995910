// @flow

import { useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import * as R from "ramda";
import { CopyToClipboard } from "react-copy-to-clipboard";

import {
  getConditionsSettings,
  getChecklistFieldType,
  getFormTitle,
  getChecklistFieldLabel
} from "src/reducers";
import Condition from "./Condition";
import Behavior from "./Behavior";
import { initialConditionBlock } from "src/conditions";
import Icons from "src/icons";

import {
  Flex,
  RadioGroup,
  Stack,
  Radio,
  Button,
  HStack,
  Text
} from "@chakra-ui/react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { Conditions as StyledConditions } from "src/styles/popover.old";
import { NewConditionButton, styles } from "./styles";

import type { ConditionBlocks, FieldId } from "src/types";

type Props = {
  saveSettings: Function,
  position: number,
  currentFieldId: ?FieldId,
  selectedFormId?: number,
  selectedFormField?: number
};

const Conditions = ({
  saveSettings,
  position,
  currentFieldId,
  selectedFormId,
  selectedFormField
}: Props) => {
  const settings = useSelector(({ app }) =>
    getConditionsSettings(app, position)
  );

  const currentFieldType = useSelector(({ app }) =>
    getChecklistFieldType(app, currentFieldId)
  );

  const formTitle = useSelector(
    ({ app }) => selectedFormId && getFormTitle(app, selectedFormId)
  );
  const selectedFormFieldLabel = useSelector(
    ({ app }) =>
      selectedFormField && getChecklistFieldLabel(app, selectedFormField)
  );

  const [conditionBlocks, setConditionBlocks] = useState<ConditionBlocks>(
    selectedFormId && selectedFormField
      ? settings?.conditionsByForm?.[selectedFormId]?.[selectedFormField]
          ?.conditionBlocks || initialConditionBlock
      : settings?.conditionBlocks || initialConditionBlock
  );

  const [defaultState, setDefaultState] = useState(
    selectedFormId && selectedFormField
      ? settings?.conditionsByForm?.[selectedFormId]?.[selectedFormField]
          ?.defaultState || "shown"
      : settings?.defaultState || "shown"
  );

  useEffect(() => {
    saveSettings({
      conditionBlocks,
      defaultState
    });
  }, [conditionBlocks, defaultState]);

  useEffect(() => {
    const handlePaste = e => {
      const clipboardText = e.clipboardData.getData("text");

      try {
        const clipboardJSON = JSON.parse(clipboardText);

        if (clipboardJSON.conditionBlocks) {
          setConditionBlocks(prevBlocks =>
            R.concat(prevBlocks, clipboardJSON.conditionBlocks)
          );
        }
      } catch (error) {
        console.error(error);
      }
    };

    window.addEventListener("paste", handlePaste);

    return () => {
      window.removeEventListener("paste", handlePaste);
    };
  }, []);

  const updateCondition = (
    condition,
    blockIndex,
    conditionIndex,
    resetBehavior?: boolean = true
  ) => {
    const conditions = R.update(
      conditionIndex,
      {
        ...conditionBlocks[blockIndex].conditions[conditionIndex],
        ...condition
      },
      conditionBlocks[blockIndex].conditions
    );
    const updatedConditionBlocks = R.update(
      blockIndex,
      {
        conditions,
        behavior: resetBehavior ? null : conditionBlocks[blockIndex].behavior
      },
      conditionBlocks
    );

    setConditionBlocks(updatedConditionBlocks);
  };

  const addCondition = (blockIndex: number) => {
    const updatedConditionsBlocks = R.update(
      blockIndex,
      {
        ...conditionBlocks[blockIndex],
        conditions: [
          ...conditionBlocks[blockIndex].conditions,
          {
            field: "",
            checklistFieldId: null,
            type: null,
            value: []
          }
        ]
      },
      conditionBlocks
    );

    setConditionBlocks(updatedConditionsBlocks);
  };

  const updateBehavior = (blockIndex, behavior) => {
    const updatedConditionsBlocks = R.update(
      blockIndex,
      { ...conditionBlocks[blockIndex], behavior },
      conditionBlocks
    );

    setConditionBlocks(updatedConditionsBlocks);
  };

  const removeBlock = (blockIndex: number) => {
    setConditionBlocks(
      conditionBlocks.filter(function (item, index) {
        return index !== blockIndex;
      })
    );
  };

  const removeCondition = (blockIndex: number, conditionIndex: number) => {
    const updatedConditionsBlocks = R.update(
      blockIndex,
      {
        ...conditionBlocks[blockIndex],
        conditions: conditionBlocks[blockIndex].conditions.filter(
          (condition, index) => index !== conditionIndex
        )
      },
      conditionBlocks
    );

    setConditionBlocks(updatedConditionsBlocks);
  };

  return (
    <StyledConditions>
      {formTitle && selectedFormFieldLabel && (
        <HStack mb={3}>
          <Text as="h3" my={0}>
            {formTitle}
          </Text>
          <ChevronRightIcon w={4} h={4} />
          <Text as="h3" my={0}>
            {selectedFormFieldLabel}
          </Text>
        </HStack>
      )}

      <Flex justifyContent="space-between" paddingBottom={4}>
        <RadioGroup
          onChange={behavior => setDefaultState(behavior)}
          value={defaultState}
        >
          <Stack direction="row">
            <Radio value="shown">Show until condition met</Radio>
            <Radio value="hidden">Hidden until condition met</Radio>
            <Radio value="mandatory">Mandatory until condition met</Radio>
            <Radio value="locked">Disable until condition met</Radio>
          </Stack>
        </RadioGroup>
      </Flex>

      {conditionBlocks.map((conditionBlock, index) => (
        <Flex key={index} {...styles.conditionContainer}>
          <HStack justifyContent="flex-end" spacing={2}>
            <CopyToClipboard text={JSON.stringify({ conditionBlocks })}>
              <Button variant="transparentLink">Copy</Button>
            </CopyToClipboard>

            <span
              style={styles.iconContainer}
              onClick={() => removeBlock(index)}
            >
              <Icons type="removeCircle" />
            </span>
          </HStack>
          {conditionBlock.conditions.map((condition, conditionIndex) => (
            <Condition
              key={condition.field}
              field={condition.field}
              fieldId={condition.checklistFieldId}
              blockIndex={index}
              conditionIndex={conditionIndex}
              updateCondition={updateCondition}
              type={condition.type}
              value={condition.value}
              removeCondition={removeCondition}
              selectedFormId={selectedFormId}
            />
          ))}
          <Button
            variant="link"
            {...styles.addButton}
            onClick={() => addCondition(index)}
          >
            + Add condition (AND)
          </Button>
          <Behavior
            behavior={conditionBlock.behavior?.current}
            mandatory={!!conditionBlock.behavior?.mandatory}
            options={conditionBlock.behavior?.options || []}
            fieldId={currentFieldId}
            updateBehavior={updateBehavior}
            blockIndex={index}
            currentFieldType={currentFieldType}
            settings={settings}
            isFormField={Boolean(selectedFormId && selectedFormField)}
          />
        </Flex>
      ))}
      <NewConditionButton
        onClick={() =>
          setConditionBlocks([
            ...conditionBlocks,
            {
              conditions: [
                {
                  field: "",
                  checklistFieldId: null,
                  type: null,
                  value: []
                }
              ],

              behavior: {}
            }
          ])
        }
      >
        + Add New Condition
      </NewConditionButton>
    </StyledConditions>
  );
};

export default Conditions;
