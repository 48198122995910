// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";
import { connect } from "react-redux";

import Common from "../Common";
import LinkedRoom from "../LinkedRoom";
import Status from "src/components/chatroom/Status/Standard";
import { getChatroomAddress } from "src/reducers";
import type { Message, ChatroomAddress } from "src/types";

type Props = {
  message: Message,
  address: ChatroomAddress
};

const Cancel = ({ message, address }: Props) => {
  const { data, roomId } = message;
  const { id, comment } = data;
  const isCurrentRoom = id === parseInt(roomId, 10);
  if (isCurrentRoom)
    return (
      <Common {...message} comment={comment}>
        {" "}
        {i18n.t(k.CANCELLED_THIS_CONVERSATION)}
        <Status status="cancelled" />
      </Common>
    );

  return (
    <Common {...message} comment={comment}>
      {" "}
      {i18n.t(k.CANCELLED1)} <LinkedRoom id={id} chatroomAddress={address} />
      <Status status="cancelled" />
    </Common>
  );
};

const mapStateToProps = ({ app }, { message }) => ({
  address: getChatroomAddress(app, message.data.id)
});

export default connect(mapStateToProps)(Cancel);
