// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React, { useCallback } from "react";

import {
  SortOptions as StyledSortOptions,
  SortHeader,
  Heading,
  SubHeading
} from "./styles";
import SortableList from "./SortableList";
import OutsideClickHandler from "src/components/OutsideClickHandler";

type Props = {
  processLabels: Object,
  fields: Object,
  outerRef: any,
  sort: Array<string>,
  clearSort: Function,
  handleSort: Function,
  handleClose: Function
};

const SortOptions = ({
  sort,
  processLabels,
  fields,
  clearSort,
  handleSort,
  outerRef,
  handleClose
}: Props) => {
  const handleClickOutside = useCallback(
    (event: any) => {
      if (outerRef && !outerRef.current.contains(event.target)) {
        handleClose();
      }
    },
    [outerRef, handleClose]
  );

  return (
    <OutsideClickHandler onClickOutside={handleClickOutside}>
      <StyledSortOptions>
        <Heading>{i18n.t(k.SORTED_BY)}</Heading>
        <SubHeading>{i18n.t(k.TO_ARRANGE_DRAG_UP_OR_DOWN)}</SubHeading>
        <SortHeader>
          <SubHeading>{i18n.t(k.FILTER_NAME)}</SubHeading>
          <SubHeading>{i18n.t(k.ORDER)}</SubHeading>
        </SortHeader>
        <SortableList
          items={sort}
          processLabels={processLabels}
          fields={fields}
          clearSort={clearSort}
          onSortEnd={handleSort}
          useDragHandle
        />
      </StyledSortOptions>
    </OutsideClickHandler>
  );
};

export default SortOptions;
