// @flow

import { connect } from "react-redux";
import React, { useEffect } from "react";
import { Portal } from "@chakra-ui/react";
import { Overlay } from "src/components/Manage/Workflow/Instances/EditChecklistModal/styles";
import OutsideClickHandler from "src/components/OutsideClickHandler";
import { getChecklistFieldValue } from "src/actions/checklist";
import FieldName from "src/containers/checklist/FieldName";
import { getFormChecklistFields, getRoomFieldValueStatus } from "src/reducers";
import { dataStages } from "src/constants";
import PulseLoader from "src/components/PulseLoader";
import Value from "./Value";
import Icon from "src/icons";
import type { AppState, DataStage } from "src/types";
import {
  Table as StyledTable,
  Th,
  Modal,
  CloseButton,
  EditWrapper
} from "./styles";

type Props = {
  fields: Array<number>,
  form: Object,
  handleClose: Function,
  getChecklistFieldValue: Function,
  valueStatus: ?DataStage
};

const FormTable = ({
  fields,
  form,
  handleClose,
  valueStatus,
  getChecklistFieldValue
}: Props) => {
  const { fieldId, roomId, index, value, openChecklistEditModal } = form;

  const loading =
    !valueStatus ||
    [dataStages.idle, dataStages.fetching].includes(valueStatus);

  // For manage view, since the API does not have form field values
  useEffect(() => {
    if (!valueStatus) {
      getChecklistFieldValue(roomId, fieldId, true);
    }
  }, [valueStatus]);

  return (
    <Portal>
      <Overlay>
        <OutsideClickHandler onClickOutside={handleClose}>
          <Modal>
            {loading ? (
              <div>
                <PulseLoader w="100%" h="8px" />
                <PulseLoader w="80%" h="8px" />
                <PulseLoader w="60%" h="8px" />
              </div>
            ) : (
              <>
                <CloseButton type="button" onClick={handleClose}>
                  <Icon type="close" />
                </CloseButton>
                <StyledTable>
                  <thead>
                    <tr>
                      {fields.map(id => (
                        <Th key={id}>
                          <FieldName id={id} />
                        </Th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      {fields.map(id => (
                        <Value
                          key={id}
                          id={id}
                          roomId={form.roomId}
                          formId={form.form.id}
                          handleClose={handleClose}
                        />
                      ))}
                    </tr>
                  </tbody>
                </StyledTable>
              </>
            )}
            <EditWrapper
              onClick={e => {
                handleClose();
                openChecklistEditModal(e, index, fieldId, roomId, value);
              }}
            >
              <Icon type="editPencil" />
            </EditWrapper>
          </Modal>
        </OutsideClickHandler>
      </Overlay>
    </Portal>
  );
};

const mapStateToProps = ({ app }: { app: AppState }, { form }: Props) => {
  return {
    fields: getFormChecklistFields(app, `${form.form.id}`),
    valueStatus: getRoomFieldValueStatus(app, form.fieldId, form.roomId)
  };
};

const mapDispatchToProps = {
  getChecklistFieldValue
};

export default connect(mapStateToProps, mapDispatchToProps)(FormTable);
