// @flow

import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Tag, TagLabel, TagCloseButton, Avatar } from "@chakra-ui/react";
import { getUser } from "src/reducers";

import type { UID, UnifizeUser } from "src/types";

import * as styles from "./styles";

import type { HandleRemove } from "./index";

type Props = {|
  id: UID,
  handleRemove: HandleRemove,
  name: string
|};

const UserTag = ({ id, handleRemove, name }: Props) => {
  const user: UnifizeUser = useSelector(({ app }) => getUser(app, id));
  const { displayName, photoUrl } = user;
  const [isFocused, setIsFocused] = useState(false);

  const onFocus = () => setIsFocused(true);

  const onBlur = () => setIsFocused(false);

  const handleKeyboardEvents = e => {
    if (e.key === "Enter") handleRemove({ id });
  };

  return (
    <Tag
      name={name}
      sx={styles.UserOrGroupTag}
      tabIndex={0}
      onFocus={onFocus}
      onBlur={onBlur}
      onKeyDown={handleKeyboardEvents}
    >
      <Avatar
        sx={styles.UserAndGroupAvatar}
        src={photoUrl}
        name={displayName}
        size="2xs"
      />

      <TagLabel sx={styles.UserAndGroupTagText}>{displayName}</TagLabel>
      <TagCloseButton
        onClick={() => handleRemove({ id })}
        sx={
          isFocused ? styles.UserOrGroupCloseFocused : styles.UserOrGroupClose
        }
        tabIndex={-1}
      />
    </Tag>
  );
};

export default UserTag;
