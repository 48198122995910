// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React, { useState, useCallback } from "react";
import { connect } from "react-redux";

import { SelectMenu as StyledMenu, MenuOption, DeleteMenu } from "./styles";
import Icon from "src/icons";
import { ButtonContainer } from "src/components/chatroom/Input/styles";
import ButtonLoader from "src/components/ButtonLoader";
import type { AppState, RoomId } from "src/types";
import { colors } from "src/styles/constants";
import {
  hideSelectOptions,
  deleteMessage,
  hideMessage,
  setReplyTo,
  showDeleteOptions,
  showMobileOptions
} from "src/actions/chatmenu";

type Props = {
  _hideSelectOptions: Function,
  selectedMessages: Array<number>,
  deletableMessages: Array<number>,
  hideMssg: Function,
  deleteMssg: Function,
  roomId: RoomId,
  _setReplyTo: Function,
  _showDeleteOptions: Function,
  _showMobileOptions: Function
};

const SelectMenu = ({
  _hideSelectOptions,
  selectedMessages,
  deletableMessages,
  hideMssg,
  deleteMssg,
  roomId,
  _setReplyTo,
  _showDeleteOptions,
  _showMobileOptions
}: Props) => {
  const [showDeleteMenu, setShowDeleteMenu] = useState(false);
  const [hideIsLoading, setHideIsLoading] = useState(false);
  const [deleteIsLoading, setDeleteIsLoading] = useState(false);

  const initiateHide = useCallback(() => {
    setHideIsLoading(true);
    hideMssg(roomId);
  }, [hideMssg, roomId]);

  const initiateDelete = useCallback(() => {
    setDeleteIsLoading(true);
    deleteMssg(roomId);
  }, [deleteMssg, roomId]);

  const hideMenu = useCallback(() => {
    _showMobileOptions();
    setShowDeleteMenu(false);
  }, []);
  const showMenu = useCallback(() => {
    _showDeleteOptions();
    setShowDeleteMenu(true);
  }, []);

  const reply = useCallback(() => {
    if (selectedMessages.length === 1) {
      _setReplyTo(selectedMessages[0]);
      _hideSelectOptions();
    }
  }, [selectedMessages]);

  if (showDeleteMenu)
    return (
      <DeleteMenu>
        <MenuOption onClick={hideMenu}>
          <Icon type="close" color={colors.dullGrey} />
        </MenuOption>
        <ButtonContainer isSrwMobile>
          <ButtonLoader
            type="button"
            isLoading={hideIsLoading}
            disabled={!selectedMessages.length}
            handleClick={initiateHide}
          >
            {i18n.t(k.HIDE)} {selectedMessages.length}
          </ButtonLoader>{" "}
          {i18n.t(k.OR)}
          <ButtonLoader
            type="button"
            isLoading={deleteIsLoading}
            disabled={!deletableMessages.length}
            handleClick={initiateDelete}
          >
            {i18n.t(k.DELETE)} {deletableMessages.length}
          </ButtonLoader>
        </ButtonContainer>
      </DeleteMenu>
    );

  return (
    <StyledMenu>
      <MenuOption onClick={reply}>
        <Icon type="reply" color={colors.blue} />
      </MenuOption>
      <MenuOption onClick={showMenu}>
        <Icon type="delete" color={colors.blue} />
      </MenuOption>
      <MenuOption onClick={_hideSelectOptions}>
        <Icon type="close" color={colors.dullGrey} />
      </MenuOption>
    </StyledMenu>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  selectedMessages: app.chatRooms.selectedMessages,
  deletableMessages: app.chatRooms.deletableMessages,
  roomId: app.chatRooms.current
});

export default connect(mapStateToProps, {
  _hideSelectOptions: hideSelectOptions,
  deleteMssg: deleteMessage,
  hideMssg: hideMessage,
  _setReplyTo: setReplyTo,
  _showDeleteOptions: showDeleteOptions,
  _showMobileOptions: showMobileOptions
})(SelectMenu);
