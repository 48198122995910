// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import { connect } from "react-redux";
import * as R from "ramda";
import React, { useEffect, useCallback } from "react";

import UniqueValues from "./UniqueValues";
import SortBy from "./SortBy";
import { setContact, getUniqueContactsValues } from "src/actions/user";
import { Options as StyledOptions, SortItem } from "src/styles/table";

import type { AppState, ContactFilter, UniqueContactsValue } from "src/types";

type Props = {
  column: string,
  filter: ContactFilter,
  uniqueValues: UniqueContactsValue,
  handleClose: Function,
  _setContact: Function,
  _getUniqueContactsValues: Function
};

const Options = ({
  column,
  uniqueValues,
  handleClose,
  filter,
  _setContact,
  _getUniqueContactsValues
}: Props) => {
  useEffect(() => {
    _getUniqueContactsValues();
  }, [_getUniqueContactsValues]);

  const sortAscending = useCallback(() => {
    const newColumn = R.uniq([
      `${column}:asc`,
      ...R.reject(R.equals(`${column}:desc`), filter.sort || [])
    ]);
    _setContact({
      query: {
        ...filter,
        sort: newColumn
      }
    });
  }, [_setContact, filter, column]);

  const sortDescending = useCallback(() => {
    const newColumn = R.uniq([
      `${column}:desc`,
      ...R.reject(R.equals(`${column}:asc`), filter.sort || [])
    ]);

    _setContact({
      query: {
        ...filter,
        sort: newColumn
      }
    });
  }, [_setContact, filter, column]);

  return (
    <StyledOptions>
      {column !== i18n.t(k.STATUS1) ? (
        <SortBy sortAsc={sortAscending} sortDesc={sortDescending} />
      ) : null}
      <UniqueValues column={column} values={uniqueValues[column]} />
    </StyledOptions>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  filter: app.users.contactFilter,
  uniqueValues: app.users.uniqueContactsValue
});

export default connect(mapStateToProps, {
  _setContact: setContact,
  _getUniqueContactsValues: getUniqueContactsValues
})(Options);
