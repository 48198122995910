// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";
import { connect } from "react-redux";
import Chat from "../Chat";
import SegmentedList from "./index";
import type { State } from "src/types";
import { getCurrentFilterSortBy } from "src/reducers";
import { priorityOrder } from "src/constants/priority";

type Props = {
  segmentedStatus: Object,
  currentSortby: string
};

const DefaultSegmentPriority = ({ segmentedStatus, currentSortby }: Props) => {
  const renderSegment = (key: string) => (index: number) => {
    const segmentedChats = segmentedStatus[`${key}`];
    if (segmentedChats) {
      const roomId = segmentedStatus[key][index];
      if (roomId) {
        return <Chat key={roomId} role="listitem" roomId={roomId} />;
      }
    }
  };

  return priorityOrder[currentSortby].map(key => {
    const count = segmentedStatus[key] ? segmentedStatus[key].length : 0;
    return (
      <SegmentedList
        key={key}
        title={key}
        count={count}
        render={renderSegment(key)}
        header={i18n.t(k.PRIORITY)}
      />
    );
  });
};

const mapStateToProps = ({ app }: State) => ({
  segmentedStatus: app.chatRooms.filters.segmentedStatus.byPriority,
  currentSortby: getCurrentFilterSortBy(app)
});

export default connect(mapStateToProps)(DefaultSegmentPriority);
