// @flow

import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";

import SelectMenu from "./SelectMenu";
import {
  ChatInputWrapper,
  ChatInput,
  UploadIcon,
  Input as StyledInput,
  Mentions as StyledMentions
} from "./styles";
import TextInput from "src/components/chatroom/Input/TextInput";
import Upload from "src/containers/chatroom/Upload";
import ProgressBar from "src/containers/chatroom/ProgressBar";
import Reply from "src/containers/messages/Reply";
import InlineSVG from "src/components/InlineSVG";
import { iconSize } from "src/styles/constants";
import type {
  AppState,
  RoomId,
  UID,
  MessageText,
  Reply as ReplyType
} from "src/types";
import { insertChat } from "src/actions/chatroom";
import { clearReplyTo } from "src/actions/chatmenu";
import { getMessageData, getReplyTo, getCurrentUserId } from "src/reducers";
import { formatMentions } from "src/utils";
import { getSelectedAction } from "src/reducers";

type Props = {
  roomId: RoomId,
  _insertChat: Function,
  currentUser: UID,
  currentMessage: MessageText,
  _clearReplyTo: Function,
  selectedAction: string
};

const Input = ({
  roomId,
  _insertChat,
  currentUser,
  currentMessage,
  _clearReplyTo,
  selectedAction
}: Props) => {
  const handleInsert = (
    text: string,
    currentMessage: MessageText,
    plainText: string
  ) => {
    _insertChat({
      roomId,
      author: currentUser,
      text: formatMentions(plainText),
      html: text,
      inReplyTo: currentMessage ? currentMessage : null
    });
    _clearReplyTo();
  };

  const handleSendClick = (
    input: string,
    currentMessage: MessageText,
    text: string
  ) => {
    handleInsert(R.trim(input || ""), currentMessage, text);
  };

  if (selectedAction) return <SelectMenu />;

  return (
    <div>
      <StyledMentions id="mention-suggestions" />
      {currentMessage ? <Reply /> : null}
      <ProgressBar roomId={roomId} />
      <ChatInputWrapper id="chatInput">
        <Upload
          roomId={roomId}
          location="chat-input"
          render={openFileBrowser => (
            <UploadIcon onClick={openFileBrowser}>
              <InlineSVG icon="plus" size={iconSize.medium} />
            </UploadIcon>
          )}
        />

        <ChatInput>
          <StyledInput>
            <TextInput
              roomId={roomId}
              handleSendClick={handleSendClick}
              currentMessage={currentMessage}
            />
          </StyledInput>
        </ChatInput>
      </ChatInputWrapper>
    </div>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => {
  const replyTo: ReplyType = getReplyTo(app);
  const { to } = replyTo;
  return {
    currentUser: getCurrentUserId(app),
    currentMessage: getMessageData(app, to),
    selectedAction: getSelectedAction(app)
  };
};

export default connect(mapStateToProps, {
  _insertChat: insertChat,
  _clearReplyTo: clearReplyTo
})(Input);
