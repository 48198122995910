// @flow

import * as R from "ramda";
import {
  race,
  take,
  call,
  takeEvery,
  put,
  select,
  takeLatest,
  all
} from "redux-saga/effects";

import * as atypes from "src/constants/actionTypes";
import {
  initialConditionBlock,
  conditionSatisfactionMap,
  fields,
  mandatoryBehaviors
} from "src/conditions";
import {
  getCurrentUserId,
  getCurrentChatroom,
  getChatroom,
  getChecklistValues,
  getFormValues,
  getCurrentChecklistId,
  getChecklistFields,
  getChecklistFieldsById,
  getRoleId,
  getUserGroups
} from "src/selectors";

import type {
  Action,
  ConditionBlocks,
  ConditionDefaultState,
  UnifizeChatRoom,
  UID
} from "src/types";

/**
 * Determines whether a field's value is not filled
 * @param {any} fieldValue - value of the field
 * @return {boolean}
 */
const isFieldUnfilled = (fieldValue: any) => {
  // if it's a linked field
  if (fieldValue?.result && fieldValue.result.length === 0) {
    return true;
  }

  return R.isEmpty(fieldValue) || R.isNil(fieldValue);
};

/** Evaluates the field's configured conditions
 *  and returns the behavior for the field
 */
const getFieldBehavior = (
  conditionBlocks: ?ConditionBlocks,
  defaultState: ?ConditionDefaultState,
  fieldId: string,
  currentUserRole: string,
  currentChatroom: UnifizeChatRoom,
  currentUserId: UID,
  currentUserGroups: Array<number>,
  chatroomOwnerGroups: Array<number>,
  currentFieldValue: any,
  fieldValues: any
) => {
  let mandatoryField = null;
  let fieldBehavior = {};

  if (
    conditionBlocks &&
    conditionBlocks.length &&
    !R.equals(conditionBlocks, initialConditionBlock)
  ) {
    for (let conditionBlock of conditionBlocks) {
      try {
        let conditionsSatisfied = null;
        for (let condition of conditionBlock.conditions) {
          // IIFE that gets the approriate field value
          // depending on whether a field belongs to
          // a form or checklist
          const fieldValue = (() => {
            if (condition.field === fields.formField) {
              return R.find(R.propEq("fieldId", condition.checklistFieldId))(
                fieldValues
              )?.value;
            }

            return R.find(R.propEq("fieldId", condition.checklistFieldId))(
              fieldValues
            )?.value;
          })();

          // Check if the condition passes
          if (
            condition.type &&
            conditionSatisfactionMap[condition.type] &&
            conditionSatisfactionMap[condition.type]({
              conditionValue: condition.value,
              fieldValue,
              currentUserRole,
              currentChatroom,
              currentUserId,
              currentUserGroups,
              conditionField: condition.field,
              chatroomOwnerGroups
            })
          ) {
            conditionsSatisfied = conditionsSatisfied ?? true;
          } else {
            conditionsSatisfied = false;
            break;
          }
        }

        if (conditionsSatisfied) {
          fieldBehavior[fieldId] = {
            behavior: conditionBlock.behavior
          };
          const currentBehavior = conditionBlock.behavior?.current;

          // Check if field is mandatory when the conditions are
          // passed
          if (
            conditionBlock.behavior?.mandatory &&
            mandatoryBehaviors.includes(currentBehavior) &&
            isFieldUnfilled(currentFieldValue)
          ) {
            mandatoryField = fieldId;
          }
          break;
        }
        // If the conditions fail fallback to default state
        else if (
          defaultState === "mandatory" &&
          isFieldUnfilled(currentFieldValue)
        ) {
          mandatoryField = fieldId;
        }

        // Set default state for the field
        fieldBehavior[fieldId] = {
          behavior: { current: defaultState }
        };
      } catch {
        continue;
      }
    }
  }

  // If there are no conditions, fall back to default state
  else if (defaultState === "mandatory" && isFieldUnfilled(currentFieldValue)) {
    mandatoryField = fieldId;
  } else if (
    defaultState === "hidden" ||
    defaultState === "locked" ||
    defaultState === "shown"
  ) {
    fieldBehavior = {
      ...fieldBehavior,
      [fieldId]: {
        behavior: { current: defaultState }
      }
    };
  }

  return { fieldBehavior, mandatoryField };
};

// Get behavior of fields based on the conditions
function* setFieldsBehavior(action: Action): any {
  const { type } = action;
  try {
    if (type === atypes.GET_CHECKLIST_FIELDS_BY_SECTIONS) {
      yield take(atypes.GET_CHECKLIST_FIELD_VALUES_SUCCESS);
    }

    const currentChatroomId = yield select(getCurrentChatroom);

    const roomId = String(action.payload.roomId || action.payload.chatroomId);

    const currentChatroom = yield select(getChatroom(roomId));

    const checklistValues = yield select(getChecklistValues(roomId));

    const fieldsById = yield select(getChecklistFieldsById);
    const currentUserId = yield select(getCurrentUserId);
    const currentUserRole = yield select(getRoleId, currentUserId);
    const currentUserGroups = yield select(getUserGroups, currentUserId);
    const chatroomOwnerGroups = yield select(
      getUserGroups,
      currentChatroom.owner || ""
    );

    let mandatoryFields = [];

    let behaviorByField = {};

    for (const fieldValue of checklistValues) {
      try {
        const currentFieldValue = fieldValue.value;
        const { fieldId } = fieldValue;
        const settings = JSON.parse(
          // $FlowFixMe
          fieldsById.get(`${fieldId}`)?.get("settings") || "{}"
        );

        const hiddenFieldValue =
          // $FlowFixMe
          fieldsById && fieldsById.get(`${fieldId}`)?.get("hidden");
        const isHidden = hiddenFieldValue
          ? JSON.parse(hiddenFieldValue)
          : false;

        const { defaultState, conditionBlocks } = settings;

        const { fieldBehavior, mandatoryField } = getFieldBehavior(
          conditionBlocks,
          defaultState,
          fieldId,
          currentUserRole,
          currentChatroom,
          currentUserId,
          currentUserGroups,
          chatroomOwnerGroups,
          currentFieldValue,
          checklistValues
        );

        behaviorByField = { ...behaviorByField, ...fieldBehavior };
        if (mandatoryField && !isHidden) {
          mandatoryFields = [...mandatoryFields, mandatoryField];
        }
      } catch {
        continue;
      }
    }

    yield put({
      type: atypes.SET_FIELDS_BEHAVIOR,
      payload: {
        roomId,
        behaviorByField,
        // Don't set mandatory fields if the room is not the current room
        mandatoryFields: roomId === currentChatroomId ? mandatoryFields : null
      }
    });
  } catch (error) {
    console.error("Error getting behavior", error);
  }
}

// Get behavior of form fields based on the conditions
function* setFormFieldsBehavior(): any {
  try {
    const currentChatroomId = yield select(getCurrentChatroom);
    const currentChatroom = yield select(getChatroom(currentChatroomId));
    const checklistValues = yield select(getChecklistValues(currentChatroomId));
    const formValues = yield select(getFormValues);
    const currentChecklistId = yield select(getCurrentChecklistId);
    const fields = yield select(getChecklistFields, `${currentChecklistId}`);

    const fieldsById = yield select(getChecklistFieldsById);
    const currentUserId = yield select(getCurrentUserId);
    const currentUserRole = yield select(getRoleId, currentUserId);
    const currentUserGroups = yield select(getUserGroups, currentUserId);
    const chatroomOwnerGroups = yield select(
      getUserGroups,
      currentChatroom.owner || ""
    );

    let mandatoryFields = [];
    let behaviorByField = {};

    for (const fieldId of fields) {
      const currentFieldValue = R.find(R.propEq("fieldId", fieldId))(
        checklistValues
      )?.value;
      // $FlowFixMe - Optional Chaining
      const { hidden, settings } = fieldsById.get(`${fieldId}`)?.toJS() || {};
      const fieldSettings = JSON.parse(settings || "{}");
      const { conditionBlocks, defaultState, conditionsByForm } = fieldSettings;

      if (conditionsByForm) {
        const { fieldBehavior } = getFieldBehavior(
          conditionBlocks,
          defaultState,
          fieldId,
          currentUserRole,
          currentChatroom,
          currentUserId,
          currentUserGroups,
          chatroomOwnerGroups,
          currentFieldValue,
          checklistValues
        );

        // Behavior of current form field.
        const formBehavior =
          fieldBehavior?.[fieldId]?.behavior?.current || null;

        for (let formId of R.keys(conditionsByForm)) {
          for (let fieldId of R.keys(conditionsByForm[formId])) {
            const formInstanceIds = R.map(
              form => form.id,
              currentFieldValue || []
            );
            for (let formInstanceId of formInstanceIds) {
              const formField =
                formValues[`${currentChatroomId}-${fieldId}-${formInstanceId}`];

              const formFieldValue = formField?.val?.value;

              let formInstanceValues = [];
              const regexp = new RegExp(
                `${currentChatroomId}-.*-${formInstanceId}`
              );

              // Iterate over formValues & get all
              // values that belong to a form instance
              for (let key in formValues) {
                if (key.match(regexp)) {
                  formInstanceValues.push({
                    ...formValues[key],
                    // Get the fieldId.
                    // Example key: 4234-12-32 -> key.split("-")[1] -> 12
                    fieldId: parseInt(key.split("-")[1])
                  });
                }
              }

              const { conditionBlocks, defaultState } =
                conditionsByForm[formId][fieldId];

              const { fieldBehavior, mandatoryField } = getFieldBehavior(
                conditionBlocks,
                defaultState,
                `${currentChatroomId}-${fieldId}-${formInstanceId}`,
                currentUserRole,
                currentChatroom,
                currentUserId,
                currentUserGroups,
                chatroomOwnerGroups,
                formFieldValue,
                [...checklistValues, ...formInstanceValues]
              );

              behaviorByField = { ...behaviorByField, ...fieldBehavior };
              // If the field is hidden or form field behavior is hidden,
              // exclude mandatory fields present inside it. Also to prevent
              // deleted fields from form getting counted as mandatory,
              // formField value is also being verified for ignoring deleted fields.
              if (
                mandatoryField &&
                formBehavior !== "hidden" &&
                !hidden &&
                formField?.fieldId
              ) {
                mandatoryFields = [...mandatoryFields, mandatoryField];
              }
            }
          }
        }
      }
    }

    yield put({
      type: atypes.SET_FORM_FIELDS_BEHAVIOR,
      payload: { behaviorByField, mandatoryFields }
    });
  } catch (error) {
    console.error("Error getting behavior", error);
  }
}

function* handleSetFieldsBehavior(action: Action): any {
  yield race([
    call(setFieldsBehavior, action),
    take(atypes.SET_CURRENT_CHATROOM_REQUEST),
    take(atypes.HIDE_DOCK)
  ]);
}

function* watchSetFieldsBehavior(): any {
  yield takeEvery(atypes.RUN_CONDITIONS_CODE, handleSetFieldsBehavior);
}

function* watchSetChecklistValue(): any {
  yield all([
    takeLatest(atypes.SET_CHECKLIST_VALUE_SUCCESS, handleSetFieldsBehavior),
    takeLatest(atypes.SET_CHECKLIST_VALUE_SUCCESS, setFormFieldsBehavior)
  ]);
}

function* watchGetChecklistFormValues(): any {
  yield all([
    takeLatest(atypes.RUN_CONDITIONS_CODE, setFormFieldsBehavior),
    takeLatest(atypes.GET_CHECKLIST_FORM_VALUES, setFormFieldsBehavior)
  ]);
}

function* watchSetChatroomAttribute(): any {
  yield all([
    takeLatest(atypes.SET_CHATROOM_ATTRIBUTE_SUCCESS, setFormFieldsBehavior),
    takeLatest(atypes.SET_CHATROOM_ATTRIBUTE_SUCCESS, handleSetFieldsBehavior)
  ]);
}

export default [
  watchSetFieldsBehavior(),
  watchSetChecklistValue(),
  watchGetChecklistFormValues(),
  watchSetChatroomAttribute()
];
