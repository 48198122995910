// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import { connect } from "react-redux";
import React, { useCallback, useState, useMemo } from "react";
import * as R from "ramda";

import SortIcon from "./SortIcon";
import CreatedFilter from "./Date/Created";
import CompletedFilter from "./Date/Completed";
import CreatedDate from "./CreatedDate";
import CompletedDate from "./CompletedDate";
import {
  Th,
  User,
  Created,
  Pending,
  Completed,
  LastActive,
  SubScript,
  Button,
  TopSection
} from "./styles";
import { setUserAnalytics } from "src/actions/userAnalytics";

import type { AppState, UserAnalyticsFilter } from "src/types";

type Props = {
  filter: UserAnalyticsFilter,
  _setUserAnalytics: Function
};

const Header = ({ filter, _setUserAnalytics }: Props) => {
  const [createdDropdown, showCreatedDropdown] = useState(false);
  const [completedDropdown, showCompletedDropdown] = useState(false);

  const sortValue = useMemo(() => {
    const sort = (filter || {}).sort || [];
    return R.map(column => {
      const header = column.split(":") || [];
      return {
        order: R.last(header),
        column: R.head(header)
      };
    }, sort);
  }, [filter]);

  const sortMap = useMemo(() => {
    const sort = (filter || {}).sort || [];
    return R.mergeAll(
      R.map(column => {
        const header = column.split(":") || [];
        return {
          [R.head(header)]: R.last(header)
        };
      }, sort)
    );
  }, [filter]);

  const handleToggleCreated = useCallback(() => {
    showCreatedDropdown(!createdDropdown);
  }, [createdDropdown, showCreatedDropdown]);

  const handleToggleCompleted = useCallback(() => {
    showCompletedDropdown(!completedDropdown);
  }, [completedDropdown, showCompletedDropdown]);

  const handleSort = useCallback(
    (column: string) => {
      const order = sortMap[column] === "asc" ? "desc" : "asc";

      // Reversing sort order of the selected column
      const newSort = [
        `${column}:${order}`,
        ...R.map(
          s => `${s.column}:${s.order || ""}`,
          R.reject(value => value.column === column, sortValue || [])
        )
      ];

      _setUserAnalytics({
        query: {
          ...filter,
          sort: newSort
        }
      });
    },
    [sortValue, _setUserAnalytics]
  );

  return (
    <>
      <User>
        <Th>{i18n.t(k.USER_NAME)}</Th>
      </User>

      <Created>
        <TopSection>
          <div>
            <strong>{i18n.t(k.CONVERSATIONS_CREATED_DURING)}</strong>
            <Button type="button" onClick={handleToggleCreated}>
              <CreatedDate />
            </Button>
            {createdDropdown ? <CreatedFilter /> : null}
          </div>
          <SubScript>{i18n.t(k.WHERE_USER_IS_A_PARTICIPANT)}</SubScript>
        </TopSection>

        <Th onClick={() => handleSort("createdAll")}>
          <div>
            <SortIcon order={sortMap.createdAll} />
            <span>{i18n.t(k.ALL)}</span>
          </div>
        </Th>
        <Th onClick={() => handleSort("createdOverdue")}>
          <div>
            <SortIcon order={sortMap.createdOverdue} />
            <span>{i18n.t(k.OVERDUE)}</span>
          </div>
        </Th>
        <Th onClick={() => handleSort("createdCompleted")}>
          <div>
            <SortIcon order={sortMap.createdCompleted} />
            <span>{i18n.t(k.NOW_COMPLETED)}</span>
          </div>
        </Th>
        <Th onClick={() => handleSort("createdPending")}>
          <div>
            <SortIcon order={sortMap.createdPending} />
            <span>{i18n.t(k.STILL_PENDING)}</span>
          </div>
        </Th>
      </Created>

      <Pending>
        <TopSection>
          <div>
            <strong>{i18n.t(k.CURRENT_PENDING_CONVERSATIONS)}</strong>
          </div>
          <SubScript>{i18n.t(k.WHERE_USER_IS_A_PARTICIPANT)}</SubScript>
        </TopSection>

        <Th onClick={() => handleSort("pendingAll")}>
          <div>
            <SortIcon order={sortMap.pendingAll} />
            <span>{i18n.t(k.ALL)}</span>
          </div>
        </Th>
        <Th onClick={() => handleSort("pendingOverdue")}>
          <div>
            <SortIcon order={sortMap.pendingOverdue} />
            <span>{i18n.t(k.OVERDUE)}</span>
          </div>
        </Th>
        <Th onClick={() => handleSort("pendingUnread")}>
          <div>
            <SortIcon order={sortMap.pendingUnread} />
            <span>{i18n.t(k.UNREAD_BY_USER)}</span>
          </div>
        </Th>
        <Th onClick={() => handleSort("pendingOwner")}>
          <div>
            <SortIcon order={sortMap.pendingOwner} />
            <span>{i18n.t(k.OWNED_BY_USER)}</span>
          </div>
        </Th>
        <Th onClick={() => handleSort("pendingCritical")}>
          <div>
            <SortIcon order={sortMap.pendingCritical} />
            <span>{i18n.t(k.MARKED_AS_CRITICAL)}</span>
          </div>
        </Th>
      </Pending>

      <Completed>
        <TopSection>
          <div>
            <strong>{i18n.t(k.CONVERSATIONS_COMPLETED_DURING)}</strong>
            <Button onClick={handleToggleCompleted}>
              <CompletedDate />
            </Button>
            {completedDropdown ? <CompletedFilter /> : null}
          </div>
          <SubScript>{i18n.t(k.WHERE_USER_IS_A_PARTICIPANT)}</SubScript>
        </TopSection>

        <Th onClick={() => handleSort("completedAll")}>
          <div>
            <SortIcon order={sortMap.completedAll} />
            <span>{i18n.t(k.ALL)}</span>
          </div>
        </Th>
        <Th onClick={() => handleSort("completedOwner")}>
          <div>
            <SortIcon order={sortMap.completedOwner} />
            <span>{i18n.t(k.OWNED_BY_USER)}</span>
          </div>
        </Th>
        <Th onClick={() => handleSort("completedCreator")}>
          <div>
            <SortIcon order={sortMap.completedCreator} />
            <span>{i18n.t(k.CREATED_BY_USER)}</span>
          </div>
        </Th>
        <Th onClick={() => handleSort("completedBy")}>
          <div>
            <SortIcon order={sortMap.completedBy} />
            <span>{i18n.t(k.COMPLETED_BY_USER)}</span>
          </div>
        </Th>
      </Completed>

      <LastActive onClick={() => handleSort("lastActive")}>
        <Th>
          <div>
            <SortIcon order={sortMap.lastActive} />
            <span>{i18n.t(k.LAST_ACTIVE)}</span>
          </div>
        </Th>
      </LastActive>
    </>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  filter: app.userAnalytics.filter
});

export default connect(mapStateToProps, {
  _setUserAnalytics: setUserAnalytics
})(Header);
