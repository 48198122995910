// @flow

import { EventTypes } from "redux-segment";
import * as R from "ramda";
import segmentActions from "src/constants/segmentActions";
import { getCurrentOrg } from "src/reducers";

// A function to get value of the key from the object.
// The key can be dot (.) separated string
const getValue = (obj, key) => {
  try {
    return key.split(".").reduce((o, i) => o[i], obj);
  } catch (ex) {
    // The key is not found in the object so return undefined
    return undefined;
  }
};

// Predicate to select keys which value is a string
const isValueString = val => typeof val === "string";

// Predicate to select keys which value is an array
const isValueArray = val => Array.isArray(val);

const segmentActionMiddleware =
  (store: any) => (next: Function) => (action: Object) => {
    let eventType = EventTypes.track;

    if (action.segmentEventType) {
      eventType = EventTypes[action.segmentEventType];
    }

    if (segmentActions[action.type]) {
      // Get orgId and orgName
      const { id: orgId, title: orgName } =
        getCurrentOrg(store.getState().app) || {};

      const { event, data: analyticsData } = segmentActions[action.type];

      let metadata = {};

      // Evalute the function defined which is first element in the array.
      // The function first argument is `AppState` and rest of arguments are
      // the rest of elements of the array applied in same order
      // as they are specified.
      const evalFunction = x => {
        try {
          const [fn, ...rest] = x;
          const args = R.prepend(
            store.getState().app,
            R.map(i => getValue(action.payload, i), rest)
          );
          return R.apply(fn, args);
        } catch (ex) {
          console.log(
            "Error in evaluating function to get additionalData - ",
            x
          );
          return {};
        }
      };

      if (!(R.isNil(analyticsData) || R.isEmpty(analyticsData))) {
        try {
          // Select all keys from the Segment data which value is string.
          // Extract the value of all the seleted keys from the payload
          metadata = R.map(
            x => getValue(action.payload, x),
            R.pickBy(isValueString, analyticsData)
          );

          // Evaluate Segement data's attributes whose value is an array.
          // First element is a function and rest of elements are arguments
          // which value will be read from payload and applied to the function.
          // The function first argument is always `AppState`
          const additionalData = R.map(
            evalFunction,
            R.pickBy(isValueArray, analyticsData)
          );

          // Flatten the nested object
          const addtionalMetadata = R.mergeAll(
            R.keys(additionalData).map(x => additionalData[x])
          );

          metadata = { ...metadata, ...addtionalMetadata };
        } catch (ex) {
          console.log(
            "Unable to get requested analytics metadata - ",
            analyticsData
          );
          metadata = {};
        }
      } else {
        // When Segment data transformation is not defined then
        // send payload as Segment metadata
        metadata = R.is(Object, action.payload)
          ? action.payload
          : { payload: action.payload };
      }

      // If action is type of FAILURE or ERROR then add failure true in metadata
      if (/_FAILURE$|_ERROR$/gi.test(action.type)) {
        metadata = { ...metadata, failure: true };
      }

      // Append orgId and orgName
      metadata = {
        ...metadata,
        orgId,
        orgName
      };

      action.meta = {
        // action.meta can have query and location attribute
        ...action.meta,
        analytics: {
          eventType,
          eventPayload: {
            event,
            properties: metadata
          }
        }
      };
    }

    return next(action);
  };

export default segmentActionMiddleware;
