// @flow

import { connect } from "react-redux";
import React, { useCallback } from "react";
import onClickOutside from "react-onclickoutside";
import * as R from "ramda";
import i18n from "i18next";

import { Row } from "../../styles";
import Options from "./Options";
import { Th, THContent } from "src/styles/table";
import Checkbox from "src/components/Checkbox";
import columns from "src/constants/peopleColumns";
import { getPeopleTablesort } from "src/reducers";
import Icon from "src/icons";

import type { AppState } from "src/types";

type Props = {
  sort: Array<string>,
  showSort: string,
  stickiness: boolean,
  toggleSortMenu: Function,
  handleClose: Function
};

const Header = ({ sort, showSort, toggleSortMenu, handleClose }: Props) => {
  Header.handleClickOutside = useCallback(() => {
    handleClose();
  }, []);
  return (
    <thead>
      <Row>
        <Th>
          <Checkbox id="selectAllrows" handleChange={() => {}} />
        </Th>
        {columns.map(column => (
          <Th
            onClick={e => toggleSortMenu(e, column.key)}
            id={`column-${column.key}`}
            key={column.key}
            active={R.includes(column.key, sort)}
          >
            <THContent>
              {i18n.t(column.display)}
              <Icon type="triangleDown" />
            </THContent>
            {showSort === column.key ? (
              <Options column={showSort} handleClose={handleClose} />
            ) : null}
          </Th>
        ))}
      </Row>
    </thead>
  );
};

const clickOutsideConfig = {
  handleClickOutside: () => Header.handleClickOutside
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  sort: getPeopleTablesort(app)
});

export default connect(mapStateToProps)(
  onClickOutside(Header, clickOutsideConfig)
);
