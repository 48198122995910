// @flow

import { connect } from "react-redux";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getCurrentChecklistBuilderFields } from "src/reducers";

import { FormGroup, Label } from "../styles";
import { InlineFormGroup, Section } from "../Conversation/styles";
import Radio from "src/components/Radio";
import { Input } from "src/styles/input";
import { getPrincipalChecklist } from "src/actions/workflows";
import Select from "src/components/Select";
import { Box, Text } from "@chakra-ui/react";
import type { PdfFieldSettings } from "src/types";
import Stitches from "./Stitches";
import * as styles from "./styles";

type Props = {
  fieldName: string,
  settings: PdfFieldSettings,
  saveSettings: Function,
  _getPrincipalChecklist: Function
};

const Pdf = ({ settings, saveSettings }: Props) => {
  const [buttonText, setButtonText] = useState("");
  const [templateName, setTemplateName] = useState("");
  const [preview, setPreview] = useState("");
  const [sort, setSort] = useState("");
  const [targetField, setTargetField] = useState(null);
  const [stitches, setStitches] = useState([]);

  const checklistFields = useSelector(({ app }) =>
    getCurrentChecklistBuilderFields(app)
  );

  const fileFields = checklistFields.filter(field => field.type === "file");

  useEffect(() => {
    if (settings) {
      setButtonText(settings.buttonText);
      setTemplateName(settings.templateName);
      setPreview(settings.preview || "none");
      setSort(settings.sortBy);
      setTargetField(settings.targetField);
      setStitches(settings.pdf || []);
    }
  }, [settings]);

  useEffect(() => {
    saveSettings({
      buttonText,
      templateName,
      preview,
      sortBy: sort,
      targetField,
      pdf: stitches
    });
  }, [buttonText, templateName, preview, sort, targetField, stitches]);

  const handleText = (event: any) => {
    setButtonText(event.target.value);
  };

  const handleTemplateName = (event: any) => {
    setTemplateName(event.target.value);
  };

  const handleTargetFieldChange = (fieldId: number) => {
    if (preview !== "all") {
      setPreview("all");
    }

    setTargetField(fieldId);
  };

  return (
    <Box {...styles.root}>
      <Stitches stitches={stitches} setStitches={setStitches} />

      <FormGroup>
        <Label>Button Text (Optional)</Label>
        <Input
          type="text"
          value={buttonText}
          placeholder="Generate PDF"
          onChange={handleText}
        />
      </FormGroup>

      <FormGroup>
        <Label>Name of saved reports (Optional)</Label>
        <Input
          type="text"
          value={templateName}
          placeholder="Name of saved reports"
          onChange={handleTemplateName}
        />
      </FormGroup>

      <Section>
        <Label>Preview Options</Label>
        <InlineFormGroup title="Upload to another file field:">
          <Radio
            id="fileFieldUpload"
            checked={typeof targetField === "number"}
            handleChange={null}
          />

          <span>Upload to another file field:</span>
        </InlineFormGroup>

        <InlineFormGroup>
          <Select
            value={targetField}
            onChange={handleTargetFieldChange}
            label={fileFields.find(field => field.id === targetField)?.label}
            placeholder="Select checklist field to upload"
          >
            {fileFields.map(item => (
              <Text key={item.id} value={item.id} my={0}>
                {item.label}
              </Text>
            ))}
          </Select>
        </InlineFormGroup>

        <InlineFormGroup title="Don’t attach file to checklist">
          <Radio
            id="noPDF"
            checked={preview === "none"}
            handleChange={() => setPreview("none")}
          />

          <span>Don&apos;t attach file to checklist</span>
        </InlineFormGroup>
        <InlineFormGroup title="Attach latest version only">
          <Radio
            id="latestPDF"
            checked={preview === "latest"}
            handleChange={() => setPreview("latest")}
          />

          <span>Attach latest version only</span>
        </InlineFormGroup>
        <InlineFormGroup title="Attach all versions">
          <Radio
            id="allPDFs"
            checked={preview === "all" && !targetField}
            handleChange={() => setPreview("all")}
          />

          <span>Attach all versions</span>
        </InlineFormGroup>
      </Section>

      <Section>
        <Label>Sort by</Label>
        <InlineFormGroup title="Date added">
          <Radio
            id="sortPDFsByDate"
            checked={sort === "date"}
            handleChange={() => setSort("date")}
          />

          <span>Date added</span>
        </InlineFormGroup>
        <InlineFormGroup title="Name">
          <Radio
            id="sortPDFsByName"
            checked={sort === "name"}
            handleChange={() => setSort("name")}
          />

          <span>Name</span>
        </InlineFormGroup>
      </Section>
    </Box>
  );
};

export default connect(null, {
  _getPrincipalChecklist: getPrincipalChecklist
})(Pdf);
