// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { HStack, Text } from "@chakra-ui/react";
import { removeRoomMember } from "src/actions/chatroom";
import { getCurrentUserId } from "src/reducers";
import type { RoomId } from "src/types";
import Icon from "src/icons";
import * as styles from "./styles";

type Props = {
  roomId: RoomId,
  setIsMoreMenuOpen: (value: boolean) => void
};

const LeaveConversation = ({ roomId, setIsMoreMenuOpen }: Props) => {
  const dispatch = useDispatch();
  const currentUser = useSelector(({ app }) => getCurrentUserId(app));
  return (
    <HStack
      sx={styles.MenuItem}
      onClick={() => {
        dispatch(removeRoomMember(currentUser, roomId));
        setIsMoreMenuOpen(false);
      }}
      tabIndex={0}
      onKeyPress={e => {
        e.stopPropagation();
        e.key === "Enter" && dispatch(removeRoomMember(currentUser, roomId));
      }}
    >
      <Icon type="logOut" height={"16"} width={"16"} />
      {i18n.t(k._35)}
      <Text sx={styles.LeaveConversationText}>
        {i18n.t(k.LEAVE_CONVERSATION)}
      </Text>
    </HStack>
  );
};

export default LeaveConversation;
