// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import * as R from "ramda";
import { connect, useDispatch } from "react-redux";
import React, { useState, useCallback } from "react";

import {
  ApprovalBar as StyledApprovalBar,
  Request,
  Accept,
  Deny,
  Respond
} from "./styles";
import Approvals from "./Approvals";
import { getCurrentUserId } from "src/reducers";
import NameOrEmail from "src/containers/user/NameOrEmail";
import { addRoomMember, removeRoomMember } from "src/actions/chatroom";

import type { AppState, UID, RoomId } from "src/types";

type Props = {
  currentUser: UID,
  roomId: RoomId,
  members: Array<UID>,
  pendingMembers: Array<UID>
};

const ApprovalBar = ({
  currentUser,
  members,
  pendingMembers,
  roomId
}: Props) => {
  const [modal, showModal] = useState(false);
  const dispatch = useDispatch();

  const handleDeny = useCallback(
    (uid: UID) => {
      dispatch(removeRoomMember(uid, roomId));
    },
    [pendingMembers, removeRoomMember]
  );

  const handleAccept = useCallback(
    (uid: UID) => {
      dispatch(
        addRoomMember({ users: uid, groups: null, roomId, request: false })
      );
    },
    [pendingMembers, addRoomMember]
  );

  const handleModal = useCallback(() => {
    showModal(!modal);
  }, [modal, showModal]);

  if (
    R.includes(currentUser, pendingMembers) ||
    !R.includes(currentUser, members) ||
    (pendingMembers || []).length === 0
  ) {
    return <></>;
  }

  if ((pendingMembers || []).length > 1) {
    return (
      <>
        <StyledApprovalBar>
          <span>
            <strong>
              {pendingMembers.length} {i18n.t(k.PEOPLE)}
            </strong>{" "}
            {i18n.t(k.HAVE_REQUESTED_TO)}
          </span>
          <Request>
            <Respond onClick={handleModal}>{i18n.t(k.RESPOND)}</Respond>
          </Request>
        </StyledApprovalBar>
        {modal ? (
          <Approvals
            handleClose={handleModal}
            pendingMembers={pendingMembers}
            handleAccept={handleAccept}
            handleDeny={handleDeny}
            show={modal}
          />
        ) : null}
      </>
    );
  }

  return (
    <StyledApprovalBar>
      <span>
        <NameOrEmail uid={R.head(pendingMembers || [])} />{" "}
        {i18n.t(k.HAS_REQUESTED_TO_JOIN)}
      </span>
      <Request>
        <Accept onClick={() => handleAccept(R.head(pendingMembers || []))}>
          {i18n.t(k.ACCEPT)}
        </Accept>
        <Deny onClick={() => handleDeny(R.head(pendingMembers || []))}>
          {i18n.t(k.DENY)}
        </Deny>
      </Request>
    </StyledApprovalBar>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  pendingMembers: app.pendingRoomMembers,
  members: app.roomMembers,
  currentUser: getCurrentUserId(app)
});

export default connect(mapStateToProps)(ApprovalBar);
