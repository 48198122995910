// @flow

import styled from "styled-components";

import * as colors from "src/styles/constants/colors";

export const Reports = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  font-size: 12px;
  color: ${props => props.color || colors.primary};
`;
