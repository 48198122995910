// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import { connect } from "react-redux";
import React from "react";
import moment from "moment";

import { DateTime as StyledDateJoined } from "./styles";
import { getUser } from "src/reducers";

import type { AppState, UnifizeUser } from "src/types";

type Props = {
  user: UnifizeUser
};

const DateJoined = ({ user }: Props) => (
  <StyledDateJoined>
    {user.dateJoined && user.dateJoined.toDate
      ? moment(user.dateJoined.toDate()).format(i18n.t(k.MMM_DD_YYYY_H_MM_A1))
      : null}
  </StyledDateJoined>
);

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  user: getUser(app, props.uid)
});

export default connect(mapStateToProps)(DateJoined);
