// @flow

import { toast } from "react-toastify";
import {
  call,
  put,
  select,
  take,
  takeEvery,
  takeLatest
} from "redux-saga/effects";
import * as R from "ramda";

import getAppState, { getUser } from "src/selectors";
import { getSection, getTile } from "src/reducers";
import * as api from "src/api/orgSettings";
import * as atypes from "src/constants/actionTypes";
import { deleteTileStatus } from "src/actions/homescreen";

import type { Action } from "src/types";

function* showOrgSettings({ payload }: Action): any {
  try {
    const { uid } = yield select(getUser);

    if (!uid) {
      yield put({ type: atypes.SIGN_IN });
      yield put({
        type: atypes.SET_REQUESTED_PAGE,
        payload: {
          query: { id: payload.id || null },
          page: "orgSettings"
        }
      });
    } else {
      yield put({
        type: atypes.SET_ORG_SETTINGS_SUCCESS,
        payload
      });
    }
  } catch (error) {
    yield put({
      type: atypes.SET_ORG_SETTINGS_FAILURE,
      payload: { error }
    });
  }
}

function* watchShowOrgSettings(): any {
  yield takeEvery(atypes.SET_ORG_SETTINGS_REQUEST, showOrgSettings);
}

function* createSection({ payload }: Action): any {
  try {
    const newSection = yield call(api.createSection, payload);

    yield put({
      type: atypes.CREATE_SECTION_SUCCESS,
      payload: newSection
    });
  } catch (error) {
    toast.error("Unable to create new section");
    yield put({
      type: atypes.CREATE_SECTION_FAILURE,
      payload: {
        error
      }
    });
  }
}

function* watchCreateSection(): any {
  yield takeEvery(atypes.CREATE_SECTION_REQUEST, createSection);
}

function* getAllSection(): any {
  try {
    const sections = yield call(api.getAllSections);
    yield put({
      type: atypes.GET_ALL_SECTION_SUCCESS,
      payload: {
        allIds: R.map(R.prop("id"), sections),
        allSections: R.mergeAll(
          sections.map(section => ({
            [section.id]: {
              ...section,
              settings: {
                position: section.settings.position || "right"
              },
              tiles: (section.tiles || []).map(tile => tile.id)
            }
          }))
        )
      }
    });
  } catch (error) {
    yield put({
      type: atypes.GET_ALL_SECTION_FAILURE,
      payload: { error }
    });
  }
}

function* watchGetAllSections(): any {
  yield takeEvery(atypes.SET_ORG_SETTINGS_SUCCESS, getAllSection);
}

function* updateSection({ payload }: Action): any {
  const oldValue = getSection(yield select(getAppState), payload.id);
  try {
    const newValue = { ...oldValue, ...payload };

    yield put({
      type: atypes.UPDATE_SECTION_OPTIMISTIC,
      payload: newValue
    });

    yield call(api.updateSection, newValue);

    // tileId will be present only if the saga is triggered by a
    // delete request
    if (payload.tileId) {
      yield put(deleteTileStatus(payload.sectionId, payload.tileId));
    }
  } catch (error) {
    console.error(error);
    toast.error("Unable to update section");
    yield put({
      type: atypes.UPDATE_SECTION_FAILURE,
      payload: oldValue
    });
  }
}

function* watchUpdateSection(): any {
  yield takeEvery(atypes.UPDATE_SECTION_REQUEST, updateSection);
}

function* createTile({ payload }: Action): any {
  try {
    const { homeScreenBuilderDialog } = payload;

    yield put({
      type: atypes.SET_HOME_SCREEN_BUILDER_ATTRIBUTES,
      payload: {
        loading: true
      }
    });

    const response = yield call(api.createTile, {
      title: homeScreenBuilderDialog.title,
      subTitle: homeScreenBuilderDialog.subTitle,
      tileTypeId: homeScreenBuilderDialog.tileTypeId,
      settings: {
        ...R.omit(
          ["title", "subTitle", "sectionId", "show", "tileType", "tileTypeId"],
          homeScreenBuilderDialog
        )
      }
    });

    yield put({
      type: atypes.CREATE_TILE_SUCCESS,
      payload: response
    });

    const section = getSection(
      yield select(getAppState),
      homeScreenBuilderDialog.sectionId
    );

    yield put({
      type: atypes.UPDATE_SECTION_REQUEST,
      payload: {
        id: homeScreenBuilderDialog.sectionId,
        tiles: [...(section.tiles || []), response.id]
      }
    });

    yield put({
      type: atypes.CLEAR_HOME_SCREEN_BUILDER_ATTRIBUTES,
      payload: {}
    });

    toast.success("Created tile");
  } catch (error) {
    toast.error("Unable to create tile");
    yield put({
      type: atypes.CREATE_TILE_FAILURE,
      payload: { error }
    });
  }
}

function* watchCreateTile(): any {
  yield takeEvery(atypes.CREATE_TILE_REQUEST, createTile);
}

function* editTile(): any {
  try {
    const { homeScreenBuilderDialog } = (yield select(getAppState)).orgSettings;
    const { segments } = getTile(
      yield select(getAppState),
      homeScreenBuilderDialog.id
    );

    yield put({
      type: atypes.SET_HOME_SCREEN_BUILDER_ATTRIBUTES,
      payload: {
        loading: true
      }
    });

    const response = yield call(api.editTile, homeScreenBuilderDialog.id, {
      title: homeScreenBuilderDialog.title,
      subTitle: homeScreenBuilderDialog.subTitle,
      tileTypeId: homeScreenBuilderDialog.tileTypeId,
      segments: segments || [],
      settings: {
        ...R.omit(
          ["title", "subTitle", "sectionId", "show", "tileType", "tileTypeId"],
          homeScreenBuilderDialog
        )
      }
    });

    yield put({
      type: atypes.EDIT_TILE_SUCCESS,
      payload: {
        ...response,
        segments: (response.segments || []).map(R.prop("id"))
      }
    });

    yield put({
      type: atypes.CLEAR_HOME_SCREEN_BUILDER_ATTRIBUTES,
      payload: {}
    });

    toast.success("Edited tile");
  } catch (error) {
    toast.error("Unable to edit tile");
    yield put({
      type: atypes.EDIT_TILE_FAILURE,
      payload: { error }
    });

    yield put({
      type: atypes.SET_HOME_SCREEN_BUILDER_ATTRIBUTES,
      payload: {
        loading: false
      }
    });
  }
}

function* watchEditTile(): any {
  yield takeEvery(atypes.EDIT_TILE_REQUEST, editTile);
}

function* getTileTypes(): any {
  try {
    const response = yield call(api.getTileTypes);

    yield put({
      type: atypes.GET_TILE_TYPES_SUCCESS,
      payload: response
    });
  } catch (error) {
    yield put({
      type: atypes.GET_TILE_TYPES_FAILURE,
      payload: { error }
    });
  }
}

function* watchGetTileTypes(): any {
  yield takeEvery(atypes.SET_ORG_SETTINGS_SUCCESS, getTileTypes);
}

function* getAllTiles(): any {
  try {
    const tiles = yield call(api.getAllTiles);

    const tilesById = {};
    for (const tile of tiles) {
      tilesById[tile.id] = {
        ...tile,
        segments: (tile.segments || []).map(segment => segment.id)
      };
    }

    yield put({
      type: atypes.GET_ALL_TILES_SUCCESS,
      payload: tilesById
    });
  } catch (error) {
    yield put({
      type: atypes.GET_ALL_TILES_FAILURE,
      payload: { error }
    });
  }
}

function* watchGetAllTiles(): any {
  yield takeEvery(atypes.SET_ORG_SETTINGS_SUCCESS, getAllTiles);
}

function* getAllUserSegments(): any {
  try {
    const userSegments = yield call(api.getAllSegments);
    const userSegmentsById = {};

    for (const segment of userSegments) {
      userSegmentsById[segment.id] = segment;
    }

    yield put({
      type: atypes.GET_ALL_USER_SEGMENTS_SUCCESS,
      payload: userSegmentsById
    });
  } catch (error) {
    yield put({
      type: atypes.GET_ALL_USER_SEGMENTS_FAILURE,
      payload: { error }
    });
  }
}

function* watchGetAllUserSegments(): any {
  yield takeEvery(atypes.SET_ORG_SETTINGS_SUCCESS, getAllUserSegments);
}

function* searchUserSegments({ payload }: Action): any {
  try {
    const { userSegmentById } = (yield select(getAppState)).orgSettings;
    const result = R.map(
      R.prop("id"),
      R.filter(
        segment =>
          R.includes(payload.text || "", R.toLower(segment.title || "")),
        R.values(userSegmentById || {})
      )
    );

    yield put({
      type: atypes.SEARCH_USER_SEGMENTS_SUCCESS,
      payload: {
        result
      }
    });
  } catch (error) {
    yield put({
      type: atypes.SEARCH_USER_SEGMENTS_FAILURE,
      payload: { error }
    });
  }
}

function* watchSearchUserSegments(): any {
  yield takeEvery(atypes.SEARCH_USER_SEGMENTS_REQUEST, searchUserSegments);
}

function* getSegmentTypes(): any {
  try {
    const segmentTypes = yield call(api.getSegmentTypes);
    const segmentTypeById = {};

    for (const type of segmentTypes) {
      segmentTypeById[type.id] = type;
    }

    yield put({
      type: atypes.GET_SEGMENT_TYPE_SUCCESS,
      payload: segmentTypeById
    });
  } catch (error) {
    yield put({
      type: atypes.GET_SEGMENT_TYPE_FAILURE,
      payload: { error }
    });
  }
}

function* watchGetSegmentTypes(): any {
  yield takeEvery(atypes.SET_ORG_SETTINGS_SUCCESS, getSegmentTypes);
}

function* createUserSegment({ payload }: Action): any {
  try {
    const { segmentUserModal } = (yield select(getAppState)).orgSettings;

    if (segmentUserModal.title) {
      const userSegment = yield call(api.createUserSegment, segmentUserModal);
      yield put({
        type: atypes.CREATE_USER_SEGMENT_SUCCESS,
        payload: userSegment
      });

      const oldTile = getTile(yield select(getAppState), payload.tileId);

      yield put({
        type: atypes.UPDATE_TILE_REQUEST,
        payload: {
          ...oldTile,
          segments: [...(oldTile.segments || []), userSegment.id],
          id: payload.tileId
        }
      });

      yield take(atypes.UPDATE_TILE_SUCCESS);
      yield put({
        type: atypes.SEARCH_USER_SEGMENTS_REQUEST,
        payload: { text: "" }
      });
      yield put({ type: atypes.CLOSE_SEGMENT_USER_MODAL });
    } else {
      toast.error("Cannot create user segment with empty title");
    }
  } catch (error) {
    console.log(error);
    yield put({
      type: atypes.CREATE_USER_SEGMENT_FAILURE,
      payload: { error }
    });
  }
}

function* watchCreateUserSegment(): any {
  yield takeEvery(atypes.CREATE_USER_SEGMENT_REQUEST, createUserSegment);
}

function* updateTile({ payload }: Action): any {
  const oldTile = getTile(yield select(getAppState), payload.id);
  try {
    yield put({
      type: atypes.UPDATE_TILE_OPTIMISTIC,
      payload: {
        oldTile,
        ...payload
      }
    });

    yield call(api.updateTile, { ...oldTile, ...payload }, payload.id);

    yield put({
      type: atypes.UPDATE_TILE_SUCCESS
    });

    toast.success("Updated tile");
  } catch (error) {
    console.error(error);
    toast.error("Unable to update tile");
    yield put({
      type: atypes.UPDATE_TILE_FAILURE,
      payload: oldTile
    });
  }
}

function* watchUpdateTile(): any {
  yield takeEvery(atypes.UPDATE_TILE_REQUEST, updateTile);
}

function* getPlatformUsage(): any {
  try {
    const platformUsage = yield call(api.getPlatformUsage);
    yield put({
      type: atypes.GET_PLATFORM_SUCCESS,
      payload: R.mergeAll(
        (platformUsage || []).map(usage => ({ [usage.id]: usage }))
      )
    });
  } catch (error) {
    yield put({
      type: atypes.GET_PLATFORM_FAILURE,
      payload: { error }
    });
  }
}

function* watchGetPlatformUsage(): any {
  yield takeEvery(atypes.SET_ORG_SETTINGS_SUCCESS, getPlatformUsage);
}

function* updateSectionOrder({ payload }: Action): any {
  try {
    yield call(api.updateSectionOrder, payload.id);
  } catch (error) {
    toast.error("Unable to change order of sections");
    yield put({
      type: atypes.UPDATE_SECTION_ORDER_FAILURE,
      payload: { error }
    });
  }
}

function* watchUpdateSectionOrder(): any {
  yield takeEvery(atypes.UPDATE_SECTION_ORDER_REQUEST, updateSectionOrder);
}

function* deleteSection({ payload }: Action): any {
  try {
    yield call(api.deleteSection, payload.id);
    yield put({
      type: atypes.DELETE_SECTION_SUCCESS,
      payload
    });
    toast.success("Section has been deleted");
  } catch (error) {
    toast.error("Unable to delete the section");
    yield put({
      type: atypes.DELETE_SECTION_FAILURE,
      payload: { error }
    });
  }
}

function* watchDeleteSection(): any {
  yield takeEvery(atypes.DELETE_SECTION_REQUEST, deleteSection);
}

function* editUserSegment(): any {
  try {
    const { segmentUserModal } = (yield select(getAppState)).orgSettings;

    if (segmentUserModal.title) {
      const userSegment = yield call(api.editUserSegment, segmentUserModal);
      yield put({
        type: atypes.EDIT_USER_SEGMENT_SUCCESS,
        payload: userSegment
      });

      yield put({
        type: atypes.SEARCH_USER_SEGMENTS_REQUEST,
        payload: { text: "" }
      });
      yield put({ type: atypes.CLOSE_SEGMENT_USER_MODAL });
    } else {
      toast.error("Cannot save user segment with empty title");
    }
  } catch (error) {
    yield put({
      type: atypes.EDIT_USER_SEGMENT_FAILURE,
      payload: { error }
    });
  }
}

function* watchEditUserSegment(): any {
  yield takeEvery(atypes.EDIT_USER_SEGMENT_REQUEST, editUserSegment);
}

function* toggleHomescreen({ payload }: Action): any {
  try {
    yield call(api.toggleHomescreen, payload.enabled);
    yield put({
      type: atypes.TOGGLE_HOME_SCREEN_SUCCESS,
      payload: payload.enabled
    });
  } catch (error) {
    console.log(error);
    toast.error("Unable to toggle home screen");
    yield put({
      type: atypes.TOGGLE_HOME_SCREEN_FAILURE,
      payload: { error }
    });
  }
}

function* watchToggleHomescreen(): any {
  yield takeLatest(atypes.TOGGLE_HOME_SCREEN_REQUEST, toggleHomescreen);
}

function* deleteTile({ payload }: Action): any {
  const tile = getTile(yield select(getAppState), payload.id);
  try {
    yield put({
      type: atypes.DELETE_TILE_OPTIMISTIC,
      payload
    });
    yield call(api.deleteTile, payload.id);
  } catch (error) {
    if ((error || {}).status === 403) {
      toast.error("You can't delete tiles that is being used");
    } else {
      toast.error("Unable to delete the tile");
    }
    yield put({
      type: atypes.DELETE_TILE_FAILURE,
      payload: tile
    });
  }
}

function* watchDeletetile(): any {
  yield takeLatest(atypes.DELETE_TILE_REQUEST, deleteTile);
}

function* updateOrgDetails({ payload }: Action): any {
  try {
    yield call(api.updateOrgDetails, payload.orgId, payload.attrs);
    toast.success("Organization details updated");
    yield put({
      type: atypes.UPDATE_ORG_DETAILS_SUCCESS,
      payload
    });
  } catch (err) {
    toast.error("Unable to update the organization");
    yield put({
      type: atypes.UPDATE_ORG_DETAILS_FAILURE,
      payload: {
        err
      }
    });
  }
}

function* watchUpdateOrgDetails(): any {
  yield takeEvery(atypes.UPDATE_ORG_DETAILS_REQUEST, updateOrgDetails);
}

function* addOrgDomain({ payload }: Action): any {
  try {
    yield call(api.addOrgDomain, payload.orgId, payload.domain);
    toast.success("New domain added succesfully");
    yield put({
      type: atypes.ADD_ORG_DOMAIN_SUCCESS,
      payload
    });
  } catch (err) {
    toast.err("Unable to add new domain");
    yield put({
      type: atypes.ADD_ORG_DOMAIN_FAILURE,
      payload: {
        err
      }
    });
  }
}

function* watchAddOrgDomain(): any {
  yield takeLatest(atypes.ADD_ORG_DOMAIN_REQUEST, addOrgDomain);
}

function* deleteOrgDomain({ payload }: Action): any {
  try {
    yield call(api.deleteOrgDomain, payload.orgId, payload.domain);
    toast.success("Domain deleted succesfully");
    yield put({
      type: atypes.DELETE_ORG_DOMAIN_SUCCESS,
      payload
    });
  } catch (err) {
    toast.error("Unable to delete domain");
    yield put({
      type: atypes.DELETE_ORG_DOMAIN_FAILURE,
      payload: {
        err
      }
    });
  }
}

function* watchDeleteOrgDomain(): any {
  yield takeEvery(atypes.DELETE_ORG_DOMAIN_REQUEST, deleteOrgDomain);
}

export default [
  watchDeletetile(),
  watchToggleHomescreen(),
  watchEditUserSegment(),
  watchDeleteSection(),
  watchUpdateSectionOrder(),
  watchGetPlatformUsage(),
  watchUpdateTile(),
  watchCreateUserSegment(),
  watchGetSegmentTypes(),
  watchSearchUserSegments(),
  watchGetAllUserSegments(),
  watchGetAllTiles(),
  watchGetTileTypes(),
  watchShowOrgSettings(),
  watchCreateSection(),
  watchGetAllSections(),
  watchUpdateSection(),
  watchCreateTile(),
  watchEditTile(),
  watchUpdateOrgDetails(),
  watchAddOrgDomain(),
  watchDeleteOrgDomain()
];
