// @flow

import React from "react";
import * as colors from "src/styles/constants/colors";

type Props = {
  size?: number,
  color?: string
};

const InstantMix = ({ size = 24, color = colors.navyBlue }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 -960 960 960"
      fill=""
    >
      <path d="M200-160v-280h-80v-80h240v80h-80v280h-80Zm0-440v-200h80v200h-80Zm160 0v-80h80v-120h80v120h80v80H360Zm80 440v-360h80v360h-80Zm240 0v-120h-80v-80h240v80h-80v120h-80Zm0-280v-360h80v360h-80Z" />
    </svg>
  );
};

export default InstantMix;
