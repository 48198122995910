import i18n from "i18next";
import k from "src/i18n/keys";

import React, { useState } from "react";
import * as R from "ramda";

import chartLoader from "src/assets/charts/chartLoader.svg";
import { Loader as StyledLoader } from "./styles";
import useInterval from "src/hooks/useInterval";

const Loader = () => {
  const [count, setCount] = useState(0);

  useInterval(() => {
    if (count < 3) {
      setCount(count + 1);
    } else {
      setCount(1);
    }
  }, 300);

  return (
    <StyledLoader>
      <h3>
        {i18n.t(k.LOADING_CHART)}
        {R.repeat(".", count)}
      </h3>
      <img src={chartLoader} alt="loading charts..." />
    </StyledLoader>
  );
};

export default Loader;
