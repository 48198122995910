// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React, { useRef, useState, useEffect } from "react";
import * as R from "ramda";
import ReactProgressiveList from "react-progressive-list";
import { Box, Button, Skeleton, VStack, Flex } from "@chakra-ui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";

import { setChartSortSeriesId } from "src/utils/charts.js";
import Dropdown from "src/components/Dropdown";
import useBoolean from "src/hooks/useBoolean";
import SortDropdownRow from "./SortDropdownRow";
import { generateById } from "src/utils";
import { keyboardHandler } from "src/utils/charts.js";
import * as styles from "./styles";

import type { DownshiftProps } from "downshift";

type Props = {
  series: Array<Object>,
  setChartDetails: Function,
  seriesId: number | string
};

const SortDropdown = ({ series, setChartDetails, seriesId }: Props) => {
  // $FlowFixMe  - Flow does not yet support method or property calls in optional chains
  const seriesList = series?.map(item => {
    if (item.seriesId) {
      return { title: item.title, id: item.seriesId };
    } else {
      return { title: item.title, id: item.tempId };
    }
  });

  const mappedSeriesById = generateById({ items: seriesList });

  const selectedSeries = mappedSeriesById[seriesId];

  const DropdownList = [
    { title: i18n.t(k.X_AXIS), seriesId: null },
    ...seriesList
  ];

  const [filteredFields, setFilteredFields] = useState(DropdownList);

  useEffect(() => {
    if (filteredFields === undefined) {
      setFilteredFields(DropdownList);
    }
  }, [DropdownList]);

  const {
    value: isOpen,
    setFalse: closeDropdown,
    toggleBoolean: toggleDropdown
  } = useBoolean();

  const downshiftRef = useRef<DownshiftProps>(null);

  const handleSelect = ({ item }: Object) => {
    setChartSortSeriesId(setChartDetails, item.id);
    closeDropdown();
  };

  const onKeyboardSelect = selectedIndex => {
    handleSelect({ item: filteredFields[selectedIndex] });
  };

  return (
    <Box sx={{ marginLeft: "auto" }}>
      <Button
        onClick={toggleDropdown}
        onKeyDown={e =>
          keyboardHandler({
            event: e,
            ref: downshiftRef,
            onSelect: onKeyboardSelect,
            onClose: closeDropdown
          })
        }
        sx={isOpen ? styles.SortDropdownExpanded : styles.SortDropdown}
        rightIcon={
          isOpen ? (
            <ChevronUpIcon w={6} h={6} />
          ) : (
            <ChevronDownIcon w={6} h={6} />
          )
        }
      >
        {selectedSeries?.title ?? `${i18n.t(k.X_AXIS)}`}
      </Button>

      {isOpen && (
        <Dropdown
          onItemClick={handleSelect}
          isOpen={isOpen}
          onOuterClick={closeDropdown}
          ref={downshiftRef}
          position="absolute"
        >
          {({
            onItemClick,
            getItemProps,
            highlightedIndex,
            scrollIntoView
          }) => {
            return (
              <Flex sx={styles.DropdownList}>
                {R.isEmpty(DropdownList) ? (
                  <VStack sx={{ m: "0 0.5rem 0.5rem" }}>
                    <Skeleton sx={styles.Skeleton} />
                    <Skeleton sx={styles.Skeleton} />
                    <Skeleton sx={styles.Skeleton} />
                    <Skeleton sx={styles.Skeleton} />
                  </VStack>
                ) : (
                  <ReactProgressiveList
                    initialAmount={12}
                    progressiveAmount={5}
                    role="list"
                    rowCount={filteredFields?.length}
                    renderItem={index => {
                      if (filteredFields[index]) {
                        return (
                          <SortDropdownRow
                            item={filteredFields[index]}
                            key={filteredFields[index].seriesId}
                            elId={index}
                            index={index}
                            highlightedIndex={highlightedIndex}
                            scrollIntoView={scrollIntoView}
                            {...getItemProps({
                              item: filteredFields[index],
                              index,
                              id: filteredFields[index].seriesId,
                              onItemClick: onItemClick
                            })}
                          />
                        );
                      }
                    }}
                  />
                )}
              </Flex>
            );
          }}
        </Dropdown>
      )}
    </Box>
  );
};

export default SortDropdown;
