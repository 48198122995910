// @flow

import React from "react";
import { connect } from "react-redux";

import CloseComponent from "src/components/Close";
import { showView } from "src/actions";
import { setCurrentChatroom } from "src/actions/chatroom";
import { getCurrentRoomId, getChatroomAddress } from "src/reducers";

import type { AppState } from "src/types";

type Props = {
  address: string,
  activeFilter: Object,
  _setCurrentChatroom: Function,
  _showView: Function
};

const Close = ({
  address,
  activeFilter,
  _setCurrentChatroom,
  _showView
}: Props) => {
  const handleClose = () => {
    _setCurrentChatroom(address);
    if (activeFilter && activeFilter.name) {
      _showView(activeFilter.name);
    } else {
      _showView("My Inbox");
    }
  };

  return <CloseComponent handleClose={handleClose} />;
};

const mapStateToProps = ({ app }: { app: AppState }) => {
  const currentRoom = getCurrentRoomId(app);
  return {
    address: getChatroomAddress(app, currentRoom),
    activeFilter: app.chatRooms.filters.activeFilter
  };
};

export default connect(mapStateToProps, {
  _setCurrentChatroom: setCurrentChatroom,
  _showView: showView
})(Close);
