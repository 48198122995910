// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";
import { connect } from "react-redux";
import { Text } from "@chakra-ui/react";
import SuggestedListItem from "./SuggestedListItem";
import type { AppState } from "src/types";
import { getSuggestedWorkflows } from "src/reducers";

type Props = {
  emptySearchField: Function,
  suggestedWorkflows: Array<number>
};

const SuggestedList = ({ emptySearchField, suggestedWorkflows }: Props) => (
  <>
    {suggestedWorkflows?.length === 0 ? null : (
      <Text fontSize="xs" color="gray.500" mb="1" pl="4" letterSpacing="1.5px">
        {i18n.t(k.SUGGESTED)}
      </Text>
    )}
    {(suggestedWorkflows || []).map(workflowId => (
      <SuggestedListItem
        key={workflowId}
        workflowId={workflowId}
        type="saved"
        emptySearchField={emptySearchField}
      />
    ))}
  </>
);

const mapStateToProps = ({ app }: { app: AppState }) => ({
  customPinned: app.chatRooms.filters.saved,
  searchedPinned: app.header.searchedPinnedLists,
  view: app.view,
  suggestedWorkflows: getSuggestedWorkflows(app)
});

export default connect(mapStateToProps)(SuggestedList);
