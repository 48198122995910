// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React, { useMemo } from "react";
import moment from "moment";

import Conversation from "./Conversation";
import Select from "./Select";
import User from "./User";
import type { FieldId } from "src/types";

type Props = {
  type: string,
  settings: string,
  value: any,
  fieldId: FieldId,
  handleClose: Function
};

const ChecklistItem = ({ value, type, settings, handleClose }: Props) => {
  const settingsJSON = useMemo(() => {
    try {
      return JSON.parse(settings);
    } catch (error) {
      return {};
    }
  }, [settings]);

  switch (type) {
    case "text":
    case "number":
      return <div>{value ? value : <i>{i18n.t(k.NO_VALUE)}</i>}</div>;
    case "date":
      return (
        <div>
          {value ? (
            moment(value).format(i18n.t(k.MMM_DD_YYYY_HH_MM_A))
          ) : (
            <i>{i18n.t(k.NO_VALUE)}</i>
          )}
        </div>
      );

    case "conversation":
    case "chatPickList":
    case "workflow":
    case "task":
    case "group":
    case "childConversation":
      return (
        <Conversation
          value={value}
          multiple={settingsJSON.multiple}
          handleClose={handleClose}
        />
      );

    case "select":
      return <Select value={value} multiple={settingsJSON.multiple} />;
    case "user":
      return <User value={value} multiple={settingsJSON.multiple} />;
    default:
      return <div></div>;
  }
};

export default ChecklistItem;
