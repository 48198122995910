// @flow

import * as R from "ramda";
import { connect } from "react-redux";

import { searchWorkflow, resetSearch } from "src/actions/workflows";
import Select from "src/components/workflow/Select";
import { getWorkflow } from "src/reducers";

import type { AppState } from "src/types";

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  result: R.difference(app.workflow.searchResults, props.exclude || []),
  processDetails: getWorkflow(app, props.workflowId)
});

export default connect(mapStateToProps, {
  handleSearch: searchWorkflow,
  resetSearch
})(Select);
