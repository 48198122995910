// @flow

import React from "react";

import * as colors from "src/styles/constants/colors";

type Props = {
  size?: number,
  color?: string,
  style?: Object
};

const Home = ({ size = 16, color = colors.grey42, style = {} }: Props) => {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...style }}
    >
      <path
        d="M3.00001 13.6667V7.49358L1.75517 8.44875L1.15384 7.65141L8.00001 2.41675L14.8462 7.65141L14.2448 8.43858L13 7.49358V13.6667H3.00001ZM4.00001 12.6667H12V6.73342L8.00001 3.68341L4.00001 6.73342V12.6667ZM5.33334 9.92308C5.17012 9.92308 5.03101 9.86564 4.91601 9.75075C4.80112 9.63575 4.74367 9.49664 4.74367 9.33342C4.74367 9.17019 4.80112 9.03108 4.91601 8.91608C5.03101 8.80119 5.17012 8.74375 5.33334 8.74375C5.49656 8.74375 5.63567 8.80119 5.75067 8.91608C5.86556 9.03108 5.92301 9.17019 5.92301 9.33342C5.92301 9.49664 5.86556 9.63575 5.75067 9.75075C5.63567 9.86564 5.49656 9.92308 5.33334 9.92308ZM8.00001 9.92308C7.83678 9.92308 7.69767 9.86564 7.58267 9.75075C7.46778 9.63575 7.41034 9.49664 7.41034 9.33342C7.41034 9.17019 7.46778 9.03108 7.58267 8.91608C7.69767 8.80119 7.83678 8.74375 8.00001 8.74375C8.16323 8.74375 8.30234 8.80119 8.41734 8.91608C8.53223 9.03108 8.58967 9.17019 8.58967 9.33342C8.58967 9.49664 8.53223 9.63575 8.41734 9.75075C8.30234 9.86564 8.16323 9.92308 8.00001 9.92308ZM10.6667 9.92308C10.5035 9.92308 10.3643 9.86564 10.2493 9.75075C10.1345 9.63575 10.077 9.49664 10.077 9.33342C10.077 9.17019 10.1345 9.03108 10.2493 8.91608C10.3643 8.80119 10.5035 8.74375 10.6667 8.74375C10.8299 8.74375 10.969 8.80119 11.084 8.91608C11.1989 9.03108 11.2563 9.17019 11.2563 9.33342C11.2563 9.49664 11.1989 9.63575 11.084 9.75075C10.969 9.86564 10.8299 9.92308 10.6667 9.92308Z"
        fill={color}
      />
    </svg>
  );
};

export default Home;
