// @flow
import React from "react";

const Bold = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_255_325"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="14"
      height="14"
    >
      <rect
        x="0.399902"
        y="0.400024"
        width="13.2"
        height="13.2"
        fill="#D9D9D9"
      />
    </mask>
    <g mask="url(#mask0_255_325)">
      <path d="M4.13965 10.85V3.15002H7.1784C7.77423 3.15002 8.32423 3.33336 8.8284 3.70002C9.33257 4.06669 9.58465 4.57544 9.58465 5.22627C9.58465 5.69377 9.47923 6.05357 9.2684 6.30565C9.05757 6.55773 8.86048 6.73877 8.67715 6.84877C8.90632 6.94961 9.16069 7.13752 9.44027 7.41252C9.71986 7.68752 9.85965 8.10002 9.85965 8.65002C9.85965 9.46586 9.56173 10.0365 8.9659 10.3619C8.37007 10.6873 7.8109 10.85 7.2884 10.85H4.13965ZM5.8034 9.31002H7.2334C7.6734 9.31002 7.94152 9.19773 8.03777 8.97315C8.13402 8.74857 8.18215 8.58586 8.18215 8.48502C8.18215 8.38419 8.13402 8.22148 8.03777 7.9969C7.94152 7.77232 7.65965 7.66002 7.19215 7.66002H5.8034V9.31002ZM5.8034 6.17502H7.08215C7.38465 6.17502 7.60465 6.09711 7.74215 5.94127C7.87965 5.78544 7.9484 5.61127 7.9484 5.41877C7.9484 5.19877 7.87048 5.02002 7.71465 4.88252C7.55882 4.74502 7.35715 4.67627 7.10965 4.67627H5.8034V6.17502Z" />
    </g>
  </svg>
);

export default Bold;
