// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalHeader,
  CloseButton
} from "@chakra-ui/react";
import Button from "src/components/Buttons/ChakraButton";
import { modalContent, modalHeader, closeButton } from "./styles";

type Props = {
  onAuthenticate: Function,
  onClose: Function,
  isOpen: boolean
};

const AuthenticationPopup = ({ onAuthenticate, onClose, isOpen }: Props) => {
  return (
    <Modal
      isCentered
      onClose={onClose}
      isOpen={isOpen}
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent sx={modalContent}>
        <ModalHeader sx={modalHeader}>
          {i18n.t(k.ALLOW_OFFICE_INTEGRATION)}
          <CloseButton onClick={onClose} sx={closeButton} />
        </ModalHeader>
        <ModalBody>{i18n.t(k.YOU_MUST_SELECT_A_DEFAULT_FOLD)}</ModalBody>
        <ModalFooter>
          <Button
            variant="uPrimary"
            onClick={() => {
              onClose();
              onAuthenticate();
            }}
          >
            {i18n.t(k.AUTHENTICATE_AND_SELECT_FOLDER)}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AuthenticationPopup;
