// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React, { Component } from "react";
import moment from "moment";

import Calendar from "./Calendar";
import { Date as StyledDate, DateSelected as StyledSelected } from "./styles";
import { iconSize } from "src/styles/constants/size";
import Icon from "src/components/Icon";
import autoPosition from "src/components/autoPosition";

type Props = {
  placeholder: string,
  value: ?string | Date,
  handleChange: Function
};

type State = {
  dropdown: boolean
};

const CalendarSelector = autoPosition(Calendar, {
  bottomOffset: "34px",
  topOffset: "34px",
  position: "top"
});

class Select extends Component<Props, State> {
  outerRef: any;

  state = {
    dropdown: false
  };

  handleDropdown = () => {
    this.setState(prevState => ({ dropdown: !prevState.dropdown }));
  };

  handleChange = (date: string) => {
    const { handleChange } = this.props;
    handleChange(date);
  };

  handleRemove = () => {
    const { handleChange } = this.props;
    handleChange(null);
    this.handleDropdown();
  };

  render() {
    const { placeholder, value } = this.props;
    const { dropdown } = this.state;

    return (
      <StyledDate
        ref={node => {
          this.outerRef = node;
        }}
        active={value}
      >
        {dropdown ? (
          <CalendarSelector
            handleChange={this.handleChange}
            value={value}
            outerRef={this.outerRef}
            handleClose={this.handleDropdown}
          />
        ) : null}

        <div onClick={this.handleDropdown}>
          {value ? (
            <StyledSelected>
              <div>
                {value ? moment(value).format(i18n.t(k.MMM_DD_YYYY)) : null}
                <Icon
                  icon="close"
                  onClick={this.handleRemove}
                  size={iconSize.space_l}
                />
              </div>
              <Icon icon="triangleDown" />
            </StyledSelected>
          ) : (
            <StyledSelected>
              <div>{placeholder}</div>
              <Icon icon="triangleDown" />
            </StyledSelected>
          )}
        </div>
      </StyledDate>
    );
  }
}

export default Select;
