// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";

import { Invitation as StyledInvitation, InviteButton } from "./styles";

type Props = {
  text?: string,
  handleInvite: Function
};

const Invitation = ({ text = i18n.t(k._4), handleInvite }: Props) => (
  <StyledInvitation>
    <span>
      {text ? (
        <div> {i18n.t(k.CAN_T_FIND_ANYONE_WITH_THIS_NA)}</div>
      ) : (
        <div>{i18n.t(k.CAN_T_FIND_SOMEONE)}</div>
      )}

      {text ? (
        <InviteButton type="button" onClick={handleInvite}>
          {i18n.t(k.INVITE1)} {text} {i18n.t(k.TO_UNIFIZE_VIA_EMAIL)}
        </InviteButton>
      ) : (
        <InviteButton type="button" onClick={handleInvite}>
          {i18n.t(k.INVITE_THEM_TO_UNIFIZE_VIA_EMA)}
        </InviteButton>
      )}
    </span>
  </StyledInvitation>
);

export default Invitation;
