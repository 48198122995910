import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";

import { Th } from "src/styles/table";

const Header = () => {
  return (
    <thead>
      <tr>
        <Th>{i18n.t(k.GROUP_NAME1)}</Th>
        <Th>{i18n.t(k.PARTICIPANTS)}</Th>
        <Th>{i18n.t(k.GROUP_OWNERS1)}</Th>
        <Th>{i18n.t(k.CREATED_BY2)}</Th>
      </tr>
    </thead>
  );
};

export default Header;
