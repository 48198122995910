// @flow

import { connect } from "react-redux";
import React, { useCallback } from "react";
import { arrayMove } from "react-sortable-hoc";

import SortableList from "./SortableList";
import { updateSection } from "src/actions/orgSettings";
import { setHomeScreenBuilderAttributes } from "src/actions/orgSettings";

import type { AppState } from "src/types";

type Props = {
  right: boolean,
  sectionId: number,
  ids: Array<number>,
  tileTypes: Object,
  _updateSection: Function,
  _setHomeScreenBuilderAttributes: Function
};

const Tiles = ({
  right,
  sectionId,
  ids,
  tileTypes,
  _updateSection,
  _setHomeScreenBuilderAttributes
}: Props) => {
  const onSortEnd = useCallback(
    ({ oldIndex, newIndex }) => {
      _updateSection({
        id: sectionId,
        tiles: arrayMove(ids, oldIndex, newIndex)
      });
    },
    [sectionId, ids, _updateSection]
  );

  const deleteTile = useCallback(
    (deletionId: number) => {
      _updateSection({
        id: sectionId,
        tiles: (ids || []).filter(id => id !== deletionId),
        tileId: deletionId
      });
    },
    [sectionId, ids, _updateSection]
  );

  const editTile = useCallback(
    ({ id, tile }: { id: number, tile: Object }) => {
      _setHomeScreenBuilderAttributes({
        ...tile.settings,
        title: tile.title,
        subTitle: tile.subTitle,
        tileTypeId: tile.tileTypeId,
        tileType: tileTypes[tile.tileTypeId],
        edit: true,
        loading: false,
        show: true,
        id
      });
    },
    [tileTypes, _setHomeScreenBuilderAttributes]
  );

  return (
    <SortableList
      items={ids}
      right={right}
      useDragHandle
      onSortEnd={onSortEnd}
      deleteTile={deleteTile}
      editTile={editTile}
    />
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  tileTypes: app.orgSettings.tileTypes
});

export default connect(mapStateToProps, {
  _updateSection: updateSection,
  _setHomeScreenBuilderAttributes: setHomeScreenBuilderAttributes
})(Tiles);
