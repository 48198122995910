// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";

import { AllRecordsContainer } from "../styles";
import Icon from "src/icons";

type Props = {
  fieldType: string
};

const AllRecords = ({ fieldType }: Props) => {
  return (
    <AllRecordsContainer>
      <Icon type="records" />
      <span>
        {i18n.t(k.ALL)} {fieldType} {i18n.t(k.RECORDS)}
      </span>
    </AllRecordsContainer>
  );
};

export default AllRecords;
