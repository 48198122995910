// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";

import { Flex, Text, Spinner, HStack, Box, Grid } from "@chakra-ui/react";
import { ChevronDownIcon, CloseIcon } from "@chakra-ui/icons";
import FieldName from "src/containers/checklist/FieldName";
import { CloseIconStyle, ListItemText, ListItemBox } from "./styles";
import Icon from "src/icons";
import * as colors from "src/styles/constants/colors";

type Props = {
  value: Array<string>,
  disable: ?boolean,
  handleSelect: Function,
  toggleDropdown: Function,
  isForm: ?boolean,
  fieldData?: Object,
  isLoading?: boolean
};

const Selection = ({
  value,
  disable,
  handleSelect,
  toggleDropdown,
  isForm,
  fieldData = {},
  isLoading = false
}: Props) => {
  const handleRemove = (event: any) => {
    event.stopPropagation();
    handleSelect(null);
  };
  const handleKeydown = (event: any) => {
    event.stopPropagation();
    if (event.key === "Delete" || event.key === "Backspace") {
      handleSelect(null);
    } else if (!disable) {
      toggleDropdown();
    }
  };

  if (value) {
    return (
      <Flex
        size="sm"
        tabIndex="0"
        border="1px solid"
        borderColor="gray.300"
        borderRadius="2px"
        p={2}
        h={8}
        justifyContent="space-between"
        backgroundColor={disable ? "gray.100" : "white"}
        alignItems="center"
        type="button"
        cursor="pointer"
        fontSize="sm"
        onClick={e => !disable && toggleDropdown(e)}
        onKeyDown={handleKeydown}
        role="select"
        _focus={{
          borderColor: "blue.400",
          boxShadow: "xs"
        }}
      >
        {isLoading && <Spinner size="xs" color="unifize_inkLightest" />}
        <Grid gridTemplateColumns={"2fr 1fr 1fr"} sx={ListItemBox}>
          <Text noOfLines={1} sx={ListItemText}>
            {fieldData.name}
          </Text>

          {fieldData.multiValue ? (
            <Text m={0} color="unifize_inkLightest">
              {i18n.t(k.MULTIPLE_VALUES)}
            </Text>
          ) : (
            <div />
          )}

          {isForm ? (
            <Flex>
              {fieldData.linkedOrFormFieldId > 0 && (
                <Text sx={ListItemText} color="unifize_inkLightest">
                  <HStack>
                    <Box mt={1}>
                      <Icon
                        type="commonChecklist"
                        stroke={colors.inkLightest}
                      />
                    </Box>
                    <Text noOfLines={1}>
                      <FieldName id={fieldData.linkedOrFormFieldId} />
                    </Text>
                  </HStack>
                </Text>
              )}
            </Flex>
          ) : (
            fieldData.linkedOrFormFieldId > 0 && (
              <Text sx={ListItemText} color="unifize_inkLightest">
                <HStack>
                  <Box>
                    <Icon
                      type={
                        fieldData.linkedProcessOrFormType === "form"
                          ? "layout"
                          : "anchor"
                      }
                      fill={colors.inkLightest}
                      height="12px"
                      width="12px"
                    />
                  </Box>
                  <Text noOfLines={1}>
                    <FieldName id={fieldData.linkedOrFormFieldId} />
                  </Text>
                </HStack>
              </Text>
            )
          )}
        </Grid>
        <CloseIcon sx={CloseIconStyle} onClick={handleRemove} />
      </Flex>
    );
  }

  return (
    <Flex
      size="sm"
      border="1px solid"
      tabIndex="0"
      borderColor="gray.300"
      borderRadius="2px"
      p={2}
      h={8}
      justifyContent="space-between"
      alignItems="center"
      type="button"
      fontSize="sm"
      cursor={disable ? "not-allowed" : "pointer"}
      color={disable ? "gray.400" : "black"}
      backgroundColor={disable ? "gray.100" : "white"}
      onClick={e => !disable && toggleDropdown(e)}
      onKeyDown={handleKeydown}
      role="select"
      _focus={{
        borderColor: "blue.400"
      }}
    >
      <span>{i18n.t(k.SELECT_FIELD)}</span>
      <ChevronDownIcon w={4} h={4} />
    </Flex>
  );
};

export default Selection;
