// @flow

import { backendUrl } from "src/config/firebase";
import type { EmailApiPayload, EmailChecklistPayload } from "src/types";
import * as easyFetch from "src/utils/fetch";

/**
  Send email and choose roles for non org members
 */
export const sendEmail = ({ chatroomId, payload }: EmailApiPayload) =>
  easyFetch.post(`/chatroom/${chatroomId}/email`, {
    body: payload
  });

/**
 * This creates an email address for the given chatroom
 * @param chatroomId - Chatroom ID
 */
export const generateChatroomEmail = async (chatroomId: number) => {
  const response = await fetch(
    `${backendUrl}/chatroom/${chatroomId}/email-address`,
    {
      credentials: "include",
      method: "GET",
      headers: {
        "Content-type": "application/json"
      }
    }
  );
  return response.text();
};

/**
 * Fill checklist based on email message
 * @param message - Email message
 */
export const fillChecklist = async (payload: EmailChecklistPayload) => {
  easyFetch.post(`/fill-checklist-on-email`, {
    body: payload
  });
};
