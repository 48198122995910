import { useMemo } from "react";
import i18n from "i18next";

const useTranslatedFieldNames = () => {
  return useMemo(
    () => ({
      text: i18n.t("TEXT"),
      number: i18n.t("NUMBER"),
      date: i18n.t("DATE"),
      user: i18n.t("USER"),
      section: i18n.t("SECTION"),
      subSection: i18n.t("SUBSECTION"),
      conversation: i18n.t("CONVERSATION"),
      childConversation: i18n.t("CHILD_CONVERSATION"),
      select: i18n.t("SELECT"),
      pdf: i18n.t("PDF"),
      file: i18n.t("FILE_UPLOAD"),
      form: i18n.t("FORM"),
      approval: i18n.t("APPROVAL"),
      revision: i18n.t("REVISION"),
      link: i18n.t("LINKED_FIELD"),
      uploadFieldsFromCSV: i18n.t("UPLOAD_FIELDS_FROM_CSV"),
      uploadFieldsJSON: i18n.t("UPLOAD_FIELDS_JSON"),
      richtext: i18n.t("RICH_TEXT")
    }),
    [i18n.language]
  );
};

export default useTranslatedFieldNames;
