// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";

import { Tooltip } from "@chakra-ui/react";
import {
  PicklistContainer,
  PicklistSearch,
  SelectMenu,
  MenuOptions,
  MenuOption,
  CreateNewButton,
  CheckSelect
} from "../styles";
import { RemoveOption } from "../Search/styles";
import Icon from "src/icons";
import OutsideClickHandler from "src/components/OutsideClickHandler";
import type { ReactRef } from "src/types";

type Props = {
  onClickOutside: Function,
  fieldRef: ReactRef,
  onChange: Function,
  placeholder: string,
  positionUp: boolean,
  dropdownRef: ReactRef,
  onClick: Function,
  multipleResults: Array<string>,
  setMultipleOption: Function,
  value: Array<string>,
  adhoc: boolean,
  search: string,
  addPicklistOption: Function,
  fromManageView?: boolean
};

const MultiPicklist = ({
  onClickOutside,
  fieldRef,
  onChange,
  placeholder,
  dropdownRef,
  onClick,
  multipleResults,
  setMultipleOption,
  value,
  adhoc,
  search,
  positionUp,
  addPicklistOption,
  fromManageView = false
}: Props) => (
  <OutsideClickHandler onClickOutside={onClickOutside}>
    <PicklistContainer fieldRef={fieldRef}>
      <PicklistSearch
        value={search}
        onChange={onChange}
        autoFocus
        placeholder={placeholder}
      />

      <SelectMenu
        fromManageView={fromManageView}
        positionUp={positionUp}
        ref={dropdownRef}
      >
        {value && value.length > 0 && (
          <RemoveOption data-cy="clearPicklist" onClick={onClick}>
            {i18n.t(k.REMOVE)}
            <Icon type="checklistRemove" />
          </RemoveOption>
        )}
        <MenuOptions>
          {multipleResults.map(option => (
            <MenuOption
              data-cy="picklistOption"
              key={option}
              onClick={() => setMultipleOption(option)}
            >
              <Tooltip label={option.length > 25 && option}>
                <span>{option}</span>
              </Tooltip>
              {option === value && (
                <CheckSelect>
                  <Icon type="checkSelect" />
                </CheckSelect>
              )}
            </MenuOption>
          ))}
        </MenuOptions>
        {adhoc && (
          <CreateNewButton onClick={addPicklistOption}>
            {i18n.t(k.CREATE)}{" "}
            {search.length > 0 ? (
              <span>&nbsp;{`${i18n.t(k._10)}${search}${i18n.t(k._10)}`}</span>
            ) : (
              `${i18n.t(k.NEW_ITEM)}`
            )}
          </CreateNewButton>
        )}
      </SelectMenu>
    </PicklistContainer>
  </OutsideClickHandler>
);

export default MultiPicklist;
