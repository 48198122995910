// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React, { useState } from "react";
import { connect } from "react-redux";

import type { State, OrgDetails as OrgDetailsState } from "src/types";
import { isCurrentUserUnifizeEmployee } from "src/reducers";
import { getCurrentOrg, getCurrentLanguage } from "src/reducers";
import { updateOrgDetails } from "src/actions/orgSettings";
import { sanitizeTitle } from "src/utils";

import {
  Button,
  HStack,
  VStack,
  Box,
  FormControl,
  FormLabel,
  Input,
  Switch,
  Textarea
} from "@chakra-ui/react";
import OrgDomains from "./OrgDomains";
import * as styles from "./index.styles";

const getCurrentOrgDetails = currentOrg => ({
  title: currentOrg?.title || "",
  nickName: currentOrg?.nickName || "",
  cloneable: currentOrg?.cloneable || false,
  description: currentOrg?.description || null,
  videoLink: currentOrg?.videoLink || null
});

type Props = {
  currentOrg: OrgDetailsState,
  cloneDataUpdateAllowed: boolean,
  updatingDetails: boolean,
  updateOrgDetails: Function,
  language: string
};

const OrgDetails = ({
  cloneDataUpdateAllowed,
  currentOrg,
  updatingDetails,
  updateOrgDetails,
  language
}: Props) => {
  const [newDetails, setNewDetails] = useState(
    getCurrentOrgDetails(currentOrg)
  );

  const handleCancel = () => {
    setNewDetails(getCurrentOrgDetails(currentOrg));
  };

  const handleSave = () => {
    updateOrgDetails(currentOrg.id, newDetails);
  };

  return (
    <VStack key={language} sx={styles.container}>
      <Box sx={styles.content}>
        <VStack sx={styles.formContainer} spacing={5}>
          <FormControl>
            <FormLabel>{i18n.t(k.ORG_NAME)}</FormLabel>
            <Input
              value={newDetails.title}
              onChange={e =>
                setNewDetails(prev => ({
                  ...prev,
                  title: e.target.value
                }))
              }
            />
          </FormControl>

          <OrgDomains />

          <FormControl mt={4}>
            <FormLabel>{i18n.t(k.EMAIL_PREFIX)}</FormLabel>
            <Input
              value={newDetails.nickName}
              onChange={e =>
                setNewDetails(prev => ({
                  ...prev,
                  nickName: sanitizeTitle(e.target.value || "")
                }))
              }
            />
          </FormControl>

          {cloneDataUpdateAllowed && (
            <>
              <HStack
                sx={{
                  width: "100%",
                  justifyContent: "space-between"
                }}
              >
                <FormLabel>{i18n.t(k.ALLOW_CLONING)}</FormLabel>
                <Switch
                  isChecked={newDetails.cloneable}
                  onChange={e =>
                    setNewDetails(prev => ({
                      ...prev,
                      cloneable: e.target.checked
                    }))
                  }
                />
              </HStack>

              <FormControl>
                <FormLabel>{i18n.t(k.ORG_DESCRIPTION)}</FormLabel>
                <Textarea
                  value={newDetails.description}
                  onChange={e =>
                    setNewDetails(prev => ({
                      ...prev,
                      description: e.target.value
                    }))
                  }
                />
              </FormControl>

              <FormControl>
                <FormLabel>{i18n.t(k.ORG_DESCRIBER_VIDEO_PASTE_IFR)}</FormLabel>
                <Input
                  value={newDetails.videoLink}
                  onChange={e =>
                    setNewDetails(prev => ({
                      ...prev,
                      videoLink: e.target.value
                    }))
                  }
                />
              </FormControl>
            </>
          )}
        </VStack>
      </Box>

      <HStack sx={styles.footer}>
        <Button onClick={handleCancel} disabled={updatingDetails}>
          {i18n.t(k.CANCEL)}
        </Button>
        <Button
          variant="uPrimary"
          onClick={handleSave}
          isLoading={updatingDetails}
        >
          {i18n.t(k.SAVE_CHANGES)}
        </Button>
      </HStack>
    </VStack>
  );
};

const mapStateToProps = ({ app }: State) => ({
  currentOrg: getCurrentOrg(app),
  cloneDataUpdateAllowed: isCurrentUserUnifizeEmployee(app),
  updatingDetails: app.orgs.updatingDetails,
  language: getCurrentLanguage(app)
});

export default connect(mapStateToProps, {
  updateOrgDetails
})(OrgDetails);
