// @flow

import styled from "@emotion/styled";

import * as colors from "src/styles/constants/colors";
import * as radius from "src/styles/constants/radius";
import * as spacing from "src/styles/constants/spacing";
import { RoundButton } from "src/components/chatroom-nav/filter/styles";
import { List } from "src/components/user/Analytics/Filters/styles";
import { ButtonLink } from "src/styles/buttons";

export const Modal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 600px;
  min-height: 500px;
  background: ${colors.brightLight};
  z-index: 999;
  padding: 18px 16px;
  border-radius: ${radius.small};
`;

export const Content = styled.form`
  display: grid;
  grid-template-columns: minmax(200px, 1fr);
`;

export const Heading = styled.h3`
  margin: 0;
`;

export const FormGroup = styled.div`
  margin-top: 1.2em;
  max-width: 566px;
  > label {
    font-weight: bold;
    font-size: 13px;
    display: block;
    margin-bottom: 0.5em;
    > span {
      font-weight: normal;
      padding-left: 0.5em;
      font-size: 10px;
    }
  }
`;

export const FilterGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  > ${RoundButton} {
    height: 2.14em;
  }
`;

const getColor = color =>
  color === "#ffff" || color === "#F9fafb" ? colors.grey5 : colors.brightLight;

export const TileColor = styled.div`
  border-radius: 50%;
  border: 1.2px solid
    ${props => (props.active ? props.primary : getColor(props.color))};
  background: ${props => props.color || colors.brightLight};
  height: 16px;
  width: 16px;
  margin-right: 0.5em;
  cursor: pointer;
`;

export const Dropdown = styled.div``;

export const RemoveButton = styled.button`
  border: 1px solid
    ${props => (props.active ? colors.activeFilter : colors.disabled)};
  background-color: ${props =>
    props.active ? colors.activeFilter : colors.brightLight};
  cursor: pointer;
  padding-right: 0;
  > svg {
    cursor: pointer !important;
    height: 0.8em;
    width: 0.8em;
  }
`;

export const RemoveStatusButton = styled(RemoveButton)`
  background-color: ${colors.brightLight};
  border: none;
  > svg > g {
    stroke-width: 2px;
    stroke: ${props => (props.active ? colors.activeFilter : colors.disabled)};
  }
`;

export const ProcessButton = styled(RoundButton)`
  position: relative;
  padding: 4px 8px 4px 8px;
  height: 34px;
  border: 1px solid
    ${props => (props.active ? colors.activeFilter : colors.disabled)};
  background-color: ${props =>
    props.active ? colors.activeFilter : colors.brightLight};
  color: ${props => (props.active ? colors.brightLight : colors.primary)};

  > span:first-of-type {
    max-width: 90px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  > ${List} {
    > input {
      min-width: auto;
    }

    top: 26px;
    left: 0;
    min-width: auto;
    width: 185px;
  }
`;

export const InlineFormGroup = styled.div`
  display: flex;
  justify-content: space-between;
  width: 18em;
  label {
    cursor: pointer;
    font-weight: bold;
  }
`;

export const SelectContainer = styled.div`
  margin-top: 1.2em;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const ReportSelect = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  padding: 8px;
  background: ${colors.skyLighter};
`;

export const AddReport = styled(ButtonLink)`
  display: flex;
  span {
    padding-left: ${spacing.space_s};
  }
  :disabled {
    cursor: not-allowed;
    color: ${colors.secondary};
    svg > path {
      stroke: ${colors.secondary};
    }
  }
`;
