// @flow

import React, { Fragment } from "react";

import DueDate from "../DueDate";
import Details from "../Details";
import Parent from "../Parent";

import type { RoomId } from "src/types";

type Props = {
  description: string,
  dueDate: ?string,
  parent: RoomId,
  handleDueDate: Function,
  handleChange: Function,
  handleParent: Function,
  settings: any
};

const Advanced = ({
  description,
  parent,
  dueDate,
  handleDueDate,
  handleChange,
  handleParent,
  settings
}: Props) => (
  <Fragment>
    <DueDate
      dueDate={dueDate}
      handleDueDate={handleDueDate}
      settings={settings}
    />

    <Details description={description} handleChange={handleChange} />
    <Parent parent={parent} handleParent={handleParent} />
  </Fragment>
);

export default Advanced;
