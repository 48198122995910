// @flow

import * as R from "ramda";
import * as XLSX from "xlsx";

import {
  importProcessesFromJSON,
  importJSONCollisions
} from "src/api/workflow";
import { capitalize } from "src/utils";
import defaultStatus from "src/constants/status";

import type {
  ImportedProcess,
  ImportedProcessStatus,
  Workflow,
  WorkflowById,
  UsersById,
  StatusById,
  UnifizeUser
} from "src/types";

/**
 * Imports the given list of JSON imported processes using the import
 * API
 * @param {string} filename - The UUID of the uploaded JSON returned by
 * the storage
 * @param {Array<ImportedProcess>} importProcesses - A list of processes
 * parsed out from the JSON file
 */
const handleJSONImport = async (
  filename: string,
  importProcesses: Array<ImportedProcess>
) => {
  let copies = [];
  let excludes = [];
  importProcesses.forEach(p => {
    if (p.copy) {
      copies.push(p.id);
    } else if (p.exclude) {
      excludes.push(p.id);
    }
  });
  const res = await importProcessesFromJSON(
    filename,
    excludes || null,
    copies || null
  );
  const result: Array<
    ImportedProcess & {
      status: ImportedProcessStatus
    }
  > = await res.json();
  return result;
};

/**
 * Fetches the list of processes colliding for the given JSON-import
 * file and returns it
 * @param {string} filename - The UUID of the uploaded JSON returned by
 * the storage
 * @return A list of the collision processes or null
 */
const fetchJSONImportCollisions = async (
  fileName: string
): Promise<?{ [string]: Array<ImportedProcess> }> => {
  try {
    const res = await importJSONCollisions(fileName);
    const data: { collisions: Array<ImportedProcess> } = await res.json();
    return data;
  } catch (e) {
    console.error(e);
    return null;
  }
};

/**
 * Appends a '(1)' and a zero-width space Unicode character to the given
 * JSON import process's unmodified title as a modification flag.
 * Removes the character if it's already present.
 * @param {ImportedProcess} process - The imported process to be used
 * for the title
 * @return {string} The modified title
 */
const appendImportTitleString = (process: ImportedProcess) => {
  if (process.title.endsWith("\u200B")) {
    return process.title.slice(0, -5);
  } else {
    return process.title.concat(" (1)\u200B");
  }
};

/**
 * Formats the auto number correctly for chatRoom state by appending the
 * current version marker ('C') if necessary
 * @param {string} autoNo - The auto number of a UnifizeChatroom
 * @return {string} The formatted auto number
 */
const formatAutoNoForVersion = (
  autoNo: ?string,
  versionCount: number,
  currentVersion?: boolean
) => {
  if (autoNo && versionCount > 1 && currentVersion) {
    return `${autoNo}C`;
  }
  return autoNo;
};

/**
 * Checks if the process attached to a linked field has been deleted
 * @param {Object} process details
 * @param {number | null} processId
 * @return {boolean}
 */
const checkIfProcessDeleted = (process: Workflow, processId: number | null) => {
  // If there's a valid process ID attached it means there was a process
  // attached to this field previously
  if (processId !== null) return R.isEmpty(process) || process?.deleted;
  return false;
};

/**
 * Converts a value to a string suitable for inclusion in a CSV file.
 * @param {any} fieldValue - The value to convert.
 * @returns {string} - The converted string.
 */
const valueToCSVField = (fieldValue: any) => {
  if (R.type(fieldValue) === "Array") {
    // Replace all occurrences of double quotes with two consecutive
    // double quotes for each element in the array
    const escapedArrayElements = fieldValue.map((value = "") =>
      `${value}`.replace(/"/g, '""')
    );
    return `"${escapedArrayElements.join(", ")}"`;
  }

  // Replace all occurrences of double quotes with two consecutive
  // double quotes. This is necessary because CSV files use double
  // quotes to enclose fields that contain commas or double quotes.
  const escapedValue = `${fieldValue ?? ""}`.replace(/"/g, '""');

  return `"${escapedValue}"`;
};

/**
 * Converts a value to a string suitable for inclusion in an XLSX file.
 * @param {any} fieldValue - The value to convert.
 * @return {string} - The value as a string, with necessary escaping applied.
 */
const valueToXLSXField = (fieldValue: any) => {
  if (R.type(fieldValue) === "Array") {
    return fieldValue.map(valueToXLSXField).join(", ");
  }

  if (R.isNil(fieldValue)) {
    return "";
  }

  // Convert the value to a string and remove tab character to prevent
  // formatting issues in the XLSX file
  return `${fieldValue}`.replace(/\t/g, "");
};

/**
 * Converts an array of rows to an XLSX file
 * @param {Array} rows The array of rows to convert
 * @return {Buffer} The XLSX file data as a buffer
 */
const rowsToXLSX = (rows: Array<Object>) => {
  // Create a new workbook
  const wb = XLSX.utils.book_new();

  // Add the rows to the workbook as a sheet
  const ws = XLSX.utils.aoa_to_sheet(rows);
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

  // Generate the XLSX file data
  const xlsxData = XLSX.write(wb, { type: "array", bookType: "xlsx" });

  // Convert the XLSX data to a buffer
  const xlsxBuffer = new Uint8Array(xlsxData).buffer;

  // Return the XLSX buffer
  return xlsxBuffer;
};

/**
 * Converts the user values in table to a format suitable for exporting
 * @param {string | UnifizeUser | Array<string | UnifizeUser>} value - user details or UID
 * @param {UsersById} usersById - details of all the users present in redux store
 * @returns {string} - The resolved value to be shown in csv.
 */
const resolveUserValueForExport = (
  value: string | UnifizeUser | Array<string | UnifizeUser>,
  usersById: UsersById
) => {
  if (Array.isArray(value)) {
    // if it's an embedded field go a level deeper
    const resolvedUsers = value.map(nestedUser => {
      const nestedUserId =
        typeof nestedUser === "string" ? nestedUser : nestedUser?.uid;
      return `${usersById[nestedUserId]?.displayName || ""} <${
        usersById[nestedUserId]?.email || ""
      }>`;
    });
    return resolvedUsers;
  }
  const userId = typeof value === "string" ? value : value?.uid;
  return `${usersById[userId]?.displayName || ""} <${
    usersById[userId]?.email || ""
  }>`;
};

const resolveValueForExport = (
  fieldValue: any,
  type: string,
  settings: string,
  format: "csv" | "xlsx",
  usersById: UsersById,
  statusesById: StatusById,
  workflowsById: WorkflowById
) => {
  let resolvedValue;

  switch (type) {
    case "select":
      const multiple = JSON.parse(settings || "{}").multiple || false;
      resolvedValue =
        !multiple && R.type(fieldValue) === "Array" && fieldValue.length
          ? fieldValue[0]
          : fieldValue || [];
      break;
    case "creator":
    case "owner":
      resolvedValue = usersById[fieldValue || ""]?.displayName || "";
      break;
    case "completedBy":
      // Once Bug #4323 is fixed, remove this & move case next to owner
      resolvedValue =
        usersById[(fieldValue || "").replace("user/", "")]?.displayName || "";
      break;
    case "members":
    case "user":
      resolvedValue = (fieldValue || []).map(user => {
        const resolvedUserValue = resolveUserValueForExport(user, usersById);
        return resolvedUserValue;
      });
      break;
    case "conversation":
    case "chatPickList":
    case "workflow":
    case "task":
    case "group":
    case "childConversation":
    case "revision":
    case "parent":
      resolvedValue = (fieldValue || []).map(room => {
        if (!room) {
          return "Untitled";
        }
        // Exclude autoNo & processTitle if it's configured in the
        // process settings
        if (
          room.templateId &&
          workflowsById[room.templateId]?.settings?.hideAutoNumber
        ) {
          return `${room.title || "Untitled"}`;
        }

        return `${room.processTitle} ${room.autoNo}: ${
          room.title || "Untitled"
        }`;
      });
      break;
    case "form":
      resolvedValue = (fieldValue || []).map(form =>
        form ? `${form.templateTitle} ${form.address}` : "No value"
      );
      break;
    case "link":
      {
        const chatroomIds = fieldValue?.result || [];
        const chatrooms = fieldValue?.entities?.chatrooms || {};

        resolvedValue = chatroomIds.map(id => {
          const room = chatrooms[id].chatroom;

          // Exclude autoNo & processTitle if it's configured in the
          // process settings
          if (
            room.templateId &&
            workflowsById[room.templateId]?.settings?.hideAutoNumber
          ) {
            return `${room.title || "Untitled"}`;
          }

          return `${room.processTitle} ${room.autoNo}: ${
            room.title || "Untitled"
          }`;
        });
      }
      break;
    case "status":
      {
        if (!fieldValue) {
          return "";
        }
        if (fieldValue > 0) {
          // $FlowFixMe
          resolvedValue = statusesById.get(`${fieldValue}`)?.get("title") || "";
          break;
        }

        resolvedValue = capitalize(defaultStatus[fieldValue].text);
      }
      break;
    case "file":
    case "pdf": {
      if (
        R.type(fieldValue) === "Array" &&
        R.type(fieldValue[0]) === "Object"
      ) {
        resolvedValue = fieldValue.map(file => (file ? file.originalName : ""));
        break;
      }

      resolvedValue = [];
      break;
    }
    case "text":
      resolvedValue = `${fieldValue || ""}`.replace(/\n/g, " ");
      break;
    case "number":
      resolvedValue = fieldValue ?? "";
      break;
    default:
      resolvedValue = fieldValue || "";
  }
  return format === "xlsx"
    ? valueToXLSXField(resolvedValue)
    : valueToCSVField(resolvedValue);
};

export {
  handleJSONImport,
  fetchJSONImportCollisions,
  appendImportTitleString,
  formatAutoNoForVersion,
  checkIfProcessDeleted,
  resolveValueForExport,
  rowsToXLSX
};
