// @flow

import styled from "@emotion/styled";

import * as colors from "src/styles/constants/colors";
import * as spacing from "src/styles/constants/spacing";
import {
  Button as DefaultButton,
  Ternary,
  ButtonLink,
  ButtonOutline as StyledButtonOutline
} from "src/styles/buttons";

export const ButtonOutline = styled(StyledButtonOutline)`
  width: auto !important;
  display: flex;
  align-items: center;
  float: right;
  > svg {
    margin-right: 0.3em;
  }
  > span {
    position: relative;
    top: 0.1em;
  }
  :hover,
  :focus {
    > svg > path {
      stroke: ${colors.brightLight};
    }
  }
`;

export const Breadcrumbs = styled.ul`
  margin-bottom: 0;
  list-style: none;
  padding-left: 0;
  display: flex;
  align-items: center;
`;

export const Trail = styled.li`
  display: inline-flex;
  align-items: center;
  color: ${props => (props.active ? colors.active : colors.primary)};
  padding-right: ${spacing.space_m};
  h4 {
    margin: 0;
    font-size: 13px;
    font-weight: bold;
  }
  a {
    display: flex;
    align-items: center;
  }

  svg {
    width: 1.4em;
    height: 0.8em;
  }
`;

export const BackButton = styled.div`
  position: relative;
  top: 1px;
  padding-right: ${spacing.space_s};
`;

export const Search = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
  margin-bottom: 16px;
  > input {
    width: 35em;
    margin-right: ${spacing.space_l};
  }
  > button {
    width: 8em;
  }
`;

export const Button = styled(DefaultButton)`
  background: ${props =>
    props.active ? colors.active : colors.secondary} !important;
  color: ${colors.brightLight} !important;
  outline: none !important;

  border: ${props =>
    props.active ? colors.active : colors.secondary} !important;
`;

export const Filters = styled.div`
  background-color: ${colors.grey25};
  min-width: 700px;
  height: auto;
  padding: ${spacing.space_m};
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: ${spacing.space_s};
  margin-top: 8px;
  margin-bottom: 8px;
`;

export const SectionLabel = styled.div`
  padding-right: ${spacing.space_s};
`;

export const FilterItem = styled.div`
  background-color: ${colors.grey12};
  font-size: 10px;
  display: flex;
  position: relative;
  align-items: center;
  margin-right: ${spacing.space_s};
  padding: ${props =>
    props.isFilteringAllowed ? "4px 4px 4px 8px" : "4px 8px 4px 8px"};

  > span {
    color: ${colors.active};
    padding-right: 4px;
  }
`;

export const CloseButton = styled.button`
  outline: none;
  border: none;
  cursor: pointer;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  > svg {
    height: 0.8em;
    width: 0.8em;
    cursor: pointer !important;
    > g {
      stroke: ${colors.active};
      stroke-width: 1.5px;
    }
  }
`;

export const UpperCase = styled.strong`
  text-transform: uppercase;
`;

export const TernaryButton = styled(Ternary)`
  padding: ${spacing.space_s};
`;

export const SecondaryButton = styled(ButtonLink)`
  margin-right: ${spacing.space_m};
`;

export const AllRecordsContainer = styled.div`
  height: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 1.25rem;
  border: 1px solid ${colors.inkLighter};
  margin-left: 0.25rem;
  gap: 0.25rem;
  padding: 0.25rem 0.5rem;
  > svg {
    color: ${colors.inkLighter};
  }
  > span {
    color: ${colors.inkLighter};
  }
`;
