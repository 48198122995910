import styled from "styled-components";
import * as colors from "src/styles/constants/colors";
import { iconSize } from "src/styles/constants/size";

const BG_VARIANT = {
  error: colors.red500,
  secondary: colors.grey33,
  default: colors.blue
};

const COLOR_VARIANT = {
  error: colors.brightLight,
  secondary: colors.dark,
  default: colors.brightLight
};

const getBackgroundColor = props => {
  if (props.bgColor) return props.bgColor;
  return BG_VARIANT[props.variant] || BG_VARIANT.default;
};

const getTextColor = props => {
  if (props.color) return props.color;
  return COLOR_VARIANT[props.variant] || COLOR_VARIANT.default;
};

export const Button = styled.button`
  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
  word-wrap: break-word;
  box-sizing: border-box;
  font-family: "Lato", sans-serif !important;
  margin: ${props => props.margin || 0};
  overflow: visible;
  text-transform: none;
  border: ${props => props.border || "none"};
  cursor: pointer;
  display: inline-flex;
  gap: ${props => props.gap || "0.5rem"};
  appearance: none;
  -webkit-box-align: center;
  align-items: center;
  align-self: ${props => props.alignSelf || "stretch"};
  -webkit-box-pack: center;
  justify-content: center;
  user-select: none;
  position: ${props => props.position || "relative"};
  white-space: nowrap;
  vertical-align: middle;
  outline: none;
  width: ${props => props.width || "auto"};
  line-height: 1.2;
  border-radius: ${props => props.borderRadius || "0.5rem"};
  font-weight: ${props => props.fontWeight || "500"};
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform;
  transition-duration: 200ms;
  height: ${props => props.height || "2rem"};
  min-width: ${props => props.width || "118.16px"};
  font-size: 0.875rem;
  padding-inline: ${props => props.padding || "0.75rem 0.75rem"};
  color: ${getTextColor} !important;
  background-color: ${getBackgroundColor};
`;

export const AnimatedButton = styled(Button)`
  cursor: not-allowed;
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  > svg {
    animation: rotate 0.6s linear infinite;
  }
`;

export const IconButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  background-color: white;
  border-radius: 50%;
  border: none;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  svg {
    width: ${props => iconSize[props.size] ?? "16px"};
    height: ${props => iconSize[props.size] ?? "16px"};
  }
`;
