// @flow

import * as atypes from "src/constants/actionTypes";

import type { Action, EmailModalStages, WorkflowId } from "src/types";

const toggleProfileModal = (payload: { open: boolean }): Action => ({
  type: atypes.TOGGLE_DISPLAY_NAME_MODAL,
  payload
});

const toggleCreateOrgModal = (payload: { open: boolean }): Action => ({
  type: atypes.TOGGLE_CREATE_ORG_MODAL,
  payload
});

const toggleCloneOrgModal = (payload: { open: boolean }): Action => ({
  type: atypes.TOGGLE_CLONE_ORG_MODAL,
  payload
});

const toggleRelatedModal = (payload: { open: boolean }): Action => ({
  type: atypes.TOGGLE_RELATED_MODAL,
  payload
});

export const setEmailModalStage = (payload: EmailModalStages): Action => ({
  type: atypes.SET_EMAIL_MODAL_STAGE,
  payload
});

const setInfoModal = (payload: {
  isOpen: boolean,
  message: string
}): Action => ({
  type: atypes.SET_INFO_MODAL,
  payload
});

const closeInfoModal = () => ({
  type: atypes.CLOSE_INFO_MODAL,
  payload: {}
});

const openManagePage = (): Action => ({
  type: atypes.OPEN_MANAGE_PAGE,
  payload: {},
  segmentEventType: "page"
});

const openContactsPage = (): Action => ({
  type: atypes.OPEN_CONTACTS_PAGE,
  payload: {},
  segmentEventType: "page"
});

export const toggleWorkflowDeletionModal = (
  isOpen: boolean,
  workflowId?: WorkflowId
): Action => ({
  type: atypes.TOGGLE_WORKFLOW_DELETION_MODAL,
  payload: { isOpen, workflowId }
});

export {
  toggleProfileModal,
  toggleCreateOrgModal,
  toggleCloneOrgModal,
  toggleRelatedModal,
  setInfoModal,
  closeInfoModal,
  openManagePage,
  openContactsPage
};

export const hideForwardModal = (): Action => ({
  type: atypes.HIDE_FORWARD_MODAL,
  payload: {}
});

export const showForwardModal = (): Action => ({
  type: atypes.SHOW_FORWARD_MODAL,
  payload: {}
});

export const closePromptRules = (): Action => ({
  type: atypes.HIDE_PROMPT_RULES,
  payload: {}
});

export const openPromptRules = (promptField: number): Action => ({
  type: atypes.SHOW_PROMPT_RULES,
  payload: promptField
});

export const toggleChangePasswordModal = (payload: {
  open: boolean
}): Action => ({
  type: atypes.TOGGLE_CHANGE_PASSWORD_MODAL,
  payload
});

export const toggleAboutModal = (payload: { value?: boolean }): Action => ({
  type: atypes.TOGGLE_ABOUT_MODAL,
  payload
});
export const togglePreferencesModal = (payload: { open: boolean }): Action => ({
  type: atypes.TOGGLE_PREFERENCES_MODAL,
  payload
});
