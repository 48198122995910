// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React, { useEffect, useCallback } from "react";

import { Filter as StyledFilter, ListItem, SubHeading } from "./styles";

type Props = {
  parentRef: any,
  handleClose: Function,
  toggleSortBy: Function
};

const Sort = ({ parentRef, handleClose, toggleSortBy }: Props) => {
  const closeModal =
    ((event: any) => {
      if (event.keyCode === 13) {
        handleClose();
      }
    },
    [handleClose]);

  const clickOutside = useCallback(
    (event: any) => {
      if (parentRef && parentRef.current) {
        if (!parentRef.current.contains(event.target)) {
          handleClose();
        }
      }
    },
    [parentRef, handleClose]
  );

  useEffect(() => {
    // $FlowFixMe
    document.addEventListener("keydown", closeModal, false);
    document.addEventListener("click", clickOutside, false);
    return () => {
      // $FlowFixMe
      document.removeEventListener("keydown", closeModal, false);
      document.removeEventListener("click", clickOutside, false);
    };
  }, []);

  const handleSort = (ascending: boolean) => {
    if (ascending) {
      toggleSortBy(false, true);
    } else {
      toggleSortBy(true, true);
    }
    handleClose();
  };

  const preventDefault = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <StyledFilter onClick={preventDefault}>
      <SubHeading>{i18n.t(k.SORT_BY2)}</SubHeading>
      <ul>
        <ListItem tabIndex="0" role="button" onClick={() => handleSort(true)}>
          {i18n.t(k.ASCENDING)}
        </ListItem>
        <ListItem tabIndex="0" role="button" onClick={() => handleSort(false)}>
          {i18n.t(k.DESCENDING)}
        </ListItem>
      </ul>
    </StyledFilter>
  );
};

export default Sort;
