// @flow

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import i18n from "i18next";
import k from "src/i18n/keys";

import Picture from "src/components/user/picture";
import { profile } from "src/styles/constants/size";
import { getUser } from "src/reducers";
import type { UnifizeUser } from "src/types";

import {
  TextFieldUpdate as StyledTextFieldUpdate,
  TextFieldUpdateUsername
} from "../styles";

type Props = {
  lastMessage?: { author: string, timestamp: string },
  modified: boolean,
  edit: boolean
};

const TextFieldUpdate = ({ lastMessage, modified, edit }: Props) => {
  const [lastUpdated, setLastUpdated] = useState("");

  const user: ?UnifizeUser = useSelector(({ app }) =>
    getUser(app, lastMessage?.author ?? "")
  );

  const refreshLastUpdated = () => {
    if (lastMessage) {
      let secondsElapsed = Math.floor(
        (new Date(Date.now()).getTime() -
          new Date(lastMessage.timestamp).getTime()) /
          1000
      );

      if (secondsElapsed < 60) {
        setLastUpdated(i18n.t(k.A_FEW_SECONDS_AGO));
      } else {
        setLastUpdated(i18n.t(k.A_FEW_MINUTES_AGO));
      }
    }
  };

  useEffect(() => {
    if (!modified || !edit) {
      return;
    }

    refreshLastUpdated();
    const timeoutId = setTimeout(() => {
      refreshLastUpdated();
    }, 60000);

    return () => clearTimeout(timeoutId);
  }, [modified, edit, lastMessage]);

  if (modified) {
    return (
      <StyledTextFieldUpdate>
        {i18n.t(k.UPDATED)} {lastUpdated} {i18n.t(k.BY)}{" "}
        {lastMessage && user && (
          <Picture
            uid={lastMessage.author}
            user={user}
            size={profile.space_s}
            radius="100%"
          />
        )}
        <TextFieldUpdateUsername>
          {user?.loading
            ? i18n.t(k.LOADING_USER)
            : user?.displayName || user?.email}
        </TextFieldUpdateUsername>
      </StyledTextFieldUpdate>
    );
  } else {
    return null;
  }
};

export default TextFieldUpdate;
