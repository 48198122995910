import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";

import { NoUnreadWrapper, Heading, NoResultSubtitle } from "./styles";

import Icons from "src/icons";

const NoResultFound = () => {
  return (
    <NoUnreadWrapper>
      <Heading>
        <Icons type="noResult" />
        <div>
          <h3>{i18n.t(k.NO_MATCHING_RESULTS1)}</h3>
        </div>
        <NoResultSubtitle>
          <div>{i18n.t(k.THERE_WEREN_T_ANY_CONVERSATION)}</div>
          <div>{i18n.t(k.TRY_WIDENING_YOUR_SEARCH)}</div>
        </NoResultSubtitle>
      </Heading>
    </NoUnreadWrapper>
  );
};

export default NoResultFound;
