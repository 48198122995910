// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import moment from "moment";
import React, { useCallback } from "react";

import Range from "./Range";
import { Label, Date as StyledDate } from "./styles";
import Radio from "src/components/Radio";

type Props = {
  name: string,
  value: ?string,
  handleChange: Function
};

const Date = ({ name, value, handleChange }: Props) => {
  const lastWeek = `${moment()
    .subtract(7, "days")
    .startOf("day")
    .format("YYYY-MM-DD")}::${moment().startOf("day").format("YYYY-MM-DD")}`;

  const lastMonth = `${moment()
    .startOf("month")
    .format("YYYY-MM-DD")}::${moment().endOf("month").format("YYYY-MM-DD")}`;

  const handleLastWeek = useCallback(() => {
    handleChange(lastWeek);
  }, [lastWeek, handleChange]);

  const handleLastMonth = useCallback(() => {
    handleChange(lastMonth);
  }, [lastMonth, handleChange]);

  const handleCurrentDate = useCallback(() => {
    handleChange(
      `${moment()
        .subtract(1, "month")
        .startOf("month")
        .format("YYYY-MM-DD")}::${moment().endOf("month").format("YYYY-MM-DD")}`
    );
  }, [handleChange]);

  return (
    <StyledDate>
      <ul>
        <li>
          <Radio
            name={name}
            checked={value === lastWeek}
            id="lastWeek"
            handleChange={handleLastWeek}
          >
            <Label>{i18n.t(k.LAST_WEEK)}</Label>
          </Radio>
        </li>

        <li>
          <Radio
            name={name}
            checked={value === lastMonth}
            id="lastMonth"
            handleChange={handleLastMonth}
          >
            <Label>{i18n.t(k.LAST_MONTH)}</Label>
          </Radio>
        </li>

        <li>
          <Radio
            name={name}
            checked={
              value && value !== lastWeek && value !== lastMonth ? true : false
            }
            id="manual"
            handleChange={handleCurrentDate}
          >
            <Label>{i18n.t(k.ADD_DATE_RANGE_MANUALLY)}</Label>
          </Radio>
        </li>

        <Range value={value} handleChange={handleChange} />
      </ul>
    </StyledDate>
  );
};

export default Date;
