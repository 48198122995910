// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";
import { useSelector } from "react-redux";

import { FilterItem as StyledFilterItem, CloseButton } from "../styles";
import * as colors from "src/styles/constants/colors";
import { getChecklistFieldsById } from "src/reducers";
import { getCanEditReportFilters } from "src/reducers/combinedSelectors";
import { processColumnNames } from "src/constants/display";
import NameOrEmail from "src/containers/user/NameOrEmail";
import { ME_FILTER } from "src/constants/users";
import Icon from "src/icons";

type Props = {
  uid: any,
  column: string,
  handleClose: Function,
  blanksFilterText: ?string
};

const User = ({ uid, column, handleClose, blanksFilterText }: Props) => {
  const fields = useSelector(({ app }) => getChecklistFieldsById(app));
  const parentLinkedField = column.split("-")[0];
  const parentFieldName = fields.toJS()[parentLinkedField]?.label;

  const isFilteringAllowed = useSelector(state =>
    getCanEditReportFilters(state)
  );

  return (
    <StyledFilterItem isFilteringAllowed={isFilteringAllowed}>
      <span>
        {processColumnNames[column]}
        {column.includes("-") && parentFieldName}
        {i18n.t(k._)}
      </span>
      <strong>
        {blanksFilterText ? (
          blanksFilterText
        ) : uid.includes("me-") || uid === ME_FILTER ? (
          i18n.t(k.ME1)
        ) : (
          <NameOrEmail uid={uid} />
        )}
      </strong>
      {isFilteringAllowed && (
        <CloseButton onClick={handleClose}>
          <Icon type="close" color={colors.active} />
        </CloseButton>
      )}
    </StyledFilterItem>
  );
};

export default User;
