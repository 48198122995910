// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";
import useBoolean from "src/hooks/useBoolean";
import User from "src/containers/user/NameOrEmail";
import Icon from "src/icons";
import {
  CaretUp,
  Count
} from "src/components/Manage/Workflow/Instances/Table/Row/ChecklistItem/styles";
import { User as StyledUser } from "./styles";

type Props = {
  value: Array<number>
};

const MultiSelect = ({ value }: Props) => {
  const { value: expand, toggleBoolean: toggleExpand } = useBoolean();

  if (expand) {
    return (
      <div>
        {value.map((id, index) => (
          <StyledUser key={id}>
            <User uid={id} showPending={false} />{" "}
            {index === 0 && (
              <CaretUp onClick={toggleExpand}>
                <Icon type="caretUp" />
              </CaretUp>
            )}
          </StyledUser>
        ))}
      </div>
    );
  }

  return (
    <StyledUser onClick={toggleExpand}>
      <User uid={value[0]} showPending={false} />
      {value.length > 1 && (
        <Count>
          {i18n.t(k._13)}
          {value.length - 1}
        </Count>
      )}
    </StyledUser>
  );
};

export default MultiSelect;
