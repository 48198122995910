// @flow

import React from "react";
import { useSelector, useDispatch } from "react-redux";
import i18n from "i18next";
import k from "src/i18n/keys";

import { setHomeScreenBuilderAttributes } from "src/actions/orgSettings";
import { Input } from "src/styles/input";
import { getHomeScreenBuilderDialog } from "src/reducers";

import ReportSelect from "./ReportSelect";
import EmptyDropdown from "./EmptyDropdown";
import TileColorSelection from "./TileColorSelection";
import { FormGroup } from "./styles";

const Reports = () => {
  const modal = useSelector(({ app }) => getHomeScreenBuilderDialog(app));
  const dispatch = useDispatch();

  const handleTitle = (event: any) => {
    dispatch(setHomeScreenBuilderAttributes({ title: event.target.value }));
  };

  return (
    <>
      <FormGroup>
        <label>{i18n.t(k.TILE_TITLE)}</label>
        <Input
          type="text"
          placeholder={i18n.t(k.PENDING_CONVERSATIONS)}
          value={modal.title}
          onChange={handleTitle}
          autoFocus
          required
        />
      </FormGroup>
      <FormGroup>
        <label>{i18n.t(k.WHEN_EMPTY)}</label>
        <EmptyDropdown
          emptyMessage={modal.emptyMessage}
          value={modal.displayEmpty}
          handleSelect={values =>
            dispatch(setHomeScreenBuilderAttributes(values))
          }
        />
      </FormGroup>
      <ReportSelect
        label={i18n.t(k.REPORTS)}
        onChange={reportId =>
          dispatch(
            setHomeScreenBuilderAttributes({
              reports: reportId
            })
          )
        }
        selected={modal.reports ?? []}
      />
      <TileColorSelection
        modal={modal}
        _setHomeScreenBuilderAttributes={payload =>
          dispatch(setHomeScreenBuilderAttributes(payload))
        }
      />
    </>
  );
};

export default Reports;
