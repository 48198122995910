// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";
import * as R from "ramda";
import { Text } from "@chakra-ui/react";

import * as styles from "../styles";

import type { DownshiftProps } from "downshift";
import type { OnItemClick } from "src/components/Dropdown";

type Props = {
  item: Object,
  onItemClick?: OnItemClick,
  highlightedIndex: number,
  index: number,
  scrollIntoView: DownshiftProps.scrollIntoView
};

const RateDropdownRow = ({
  item,
  onItemClick,
  highlightedIndex,
  index
}: Props) => {
  return (
    <Text
      onClick={() =>
        onItemClick && onItemClick({ id: item.seriesId || item.tempId })
      }
      sx={
        (highlightedIndex !== null && highlightedIndex === index) ||
        (highlightedIndex === null && index === 0)
          ? styles.ActiveDropdownItem
          : styles.InActiveDropdownItem
      }
    >
      {R.isEmpty(item.title) ? `${i18n.t(k.SERIES1)}${item.seqNo}` : item.title}
    </Text>
  );
};

export default RateDropdownRow;
