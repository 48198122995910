// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React, {
  useRef,
  useCallback,
  useState,
  useEffect,
  useMemo
} from "react";
import * as R from "ramda";
import type { DownshiftProps } from "downshift";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Button,
  Skeleton,
  Flex,
  VStack,
  Spinner,
  Input
} from "@chakra-ui/react";
import ReactProgressiveList from "react-progressive-list";
import { ChevronDownIcon, ChevronUpIcon, CloseIcon } from "@chakra-ui/icons";

import DropdownRow from "src/components/PrimaryFieldDropdown/DropdownRow.js";
import Dropdown from "src/components/Dropdown";
import useBoolean from "src/hooks/useBoolean";
import useDebounce from "src/hooks/useDebounce";
import * as styles from "../styles";
import {
  getValueFromQuery,
  getPrincipalChecklistFieldsList,
  getChartGroupByListLoading,
  getChartGroupByList,
  getLocationPayload
} from "src/reducers";
import { comparisonCharts } from "src/constants/charts.js";
import { getGroupByFieldList } from "src/actions/chart";
import { setChartSeries, generateFieldId } from "src/utils/charts.js";
import SelectedField from "src/components/Manage/Reports/Instances/Chart/New/ComparisonChart/Builder/SelectedField";
import { DropdownInputField } from "src/components/Dropdown/styles";

type Props = {
  linkedOrFormFieldId: ?number,
  chartDetails: Object,
  setChartDetails: Function,
  seriesId: Function,
  groupByFieldId: number
};

const GroupBy = ({
  linkedOrFormFieldId,
  chartDetails,
  setChartDetails,
  seriesId,
  groupByFieldId
}: Props) => {
  const dispatch = useDispatch();
  const downshiftRef = useRef<DownshiftProps>(null);

  const allFields = useSelector(({ app }) =>
    getPrincipalChecklistFieldsList(app)
  );
  const workflowId = useSelector(({ location }) =>
    getValueFromQuery("workflowId")(location)
  );

  const builder = useSelector(({ location }) =>
    getValueFromQuery("builder")(location)
  );

  const editId = useSelector(({ location }) =>
    getLocationPayload("id")(location)
  );

  const dropdownList = useSelector(({ app }) => getChartGroupByList(app));
  const loading = useSelector(({ app }) => getChartGroupByListLoading(app));

  const memoizedFieldList = useMemo(
    () => R.values(dropdownList),
    [dropdownList]
  );

  const [query, setQuery] = useState("");
  const [filteredFields, setFilteredFields] = useState(memoizedFieldList);

  const selectedField = dropdownList[groupByFieldId];

  const {
    value: isOpen,
    setFalse: closeDropdown,
    toggleBoolean: toggleDropdown
  } = useBoolean();

  useEffect(() => {
    if (selectedField?.multiValue) {
      setChartSeries({
        setChartDetails,
        seriesId,
        key: "seriesMultiValue",
        value: selectedField.multiValue
      });
    }

    if (!R.isEmpty(memoizedFieldList)) {
      setFilteredFields(memoizedFieldList);
    }

    if (editId && R.isEmpty(dropdownList) && groupByFieldId) {
      dispatch(getGroupByFieldList(allFields, workflowId, linkedOrFormFieldId));
    }
  }, [
    dropdownList,
    memoizedFieldList,
    groupByFieldId,
    selectedField?.multiValue
  ]);

  const filteredResults = query => {
    const result = memoizedFieldList.filter(({ name }) =>
      name.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredFields(result);
  };

  const debouncedSearch = useDebounce(filteredResults, 300);

  const handleSearch = useCallback(
    input => {
      setQuery(input);
      debouncedSearch(input);
    },
    [debouncedSearch]
  );

  const handleSelect = ({ item: field }) => {
    const {
      id,
      type,
      multiValue,
      linkedProcessOrFormType,
      linkedOrFormFieldId,
      linkedProcessOrFormId
    } = field;

    const selectedFieldId = generateFieldId({
      id,
      type,
      linkedProcessOrFormType,
      linkedProcessOrFormId,
      linkedOrFormFieldId
    });

    setChartSeries({
      setChartDetails,
      seriesId,
      key: "y",
      value: selectedFieldId,
      multiValue
    });

    closeDropdown();
  };

  const handleClick = () => {
    toggleDropdown();
    R.isEmpty(dropdownList) &&
      dispatch(getGroupByFieldList(allFields, workflowId, linkedOrFormFieldId));
  };

  const removeGroupByHandler = e => {
    setChartSeries({
      setChartDetails,
      seriesId,
      key: "y",
      value: null
    });
    e.stopPropagation();
  };

  const onKeyboardSelect = selectedIndex => {
    handleSelect({ item: filteredFields[selectedIndex] });
  };

  return (
    <Box>
      <Button
        disabled={builder === comparisonCharts.DualYAxis ? true : false}
        onClick={handleClick}
        sx={isOpen ? styles.DropdownExpanded : styles.DropdownButton}
        rightIcon={
          isOpen ? (
            <ChevronUpIcon w={6} h={6} />
          ) : selectedField ? (
            <CloseIcon
              onClick={removeGroupByHandler}
              sx={{ m: "6px" }}
              w="12px"
              h="12px"
            />
          ) : (
            <ChevronDownIcon w={6} h={6} />
          )
        }
      >
        {loading ? (
          <Spinner size="sm" />
        ) : selectedField ? (
          <SelectedField selectedField={selectedField} />
        ) : (
          i18n.t(k.SELECT_FIELD)
        )}
      </Button>

      {isOpen && (
        <Dropdown
          onItemClick={handleSelect}
          isOpen={isOpen}
          onOuterClick={closeDropdown}
          ref={downshiftRef}
          onKeyboardSelect={onKeyboardSelect}
        >
          {({
            onItemClick,
            getItemProps,
            highlightedIndex,
            scrollIntoView,
            keyboardHandler
          }) => {
            return (
              <Flex sx={styles.DropdownList}>
                <Input
                  placeholder={i18n.t(k.SEARCH)}
                  sx={DropdownInputField}
                  value={query}
                  onChange={event => handleSearch(event.target.value)}
                  onKeyDown={e =>
                    keyboardHandler({
                      event: e,
                      ref: downshiftRef,
                      onSelect: onKeyboardSelect,
                      onClose: closeDropdown
                    })
                  }
                  autoFocus
                />

                {loading ? (
                  <VStack sx={{ m: "0 0.5rem 0.5rem" }}>
                    <Skeleton sx={styles.Skeleton} />
                    <Skeleton sx={styles.Skeleton} />
                    <Skeleton sx={styles.Skeleton} />
                    <Skeleton sx={styles.Skeleton} />
                  </VStack>
                ) : (
                  <ReactProgressiveList
                    initialAmount={12}
                    progressiveAmount={5}
                    role="list"
                    rowCount={filteredFields.length}
                    renderItem={index => {
                      if (filteredFields[index]) {
                        return (
                          <DropdownRow
                            isGroupBy
                            selectedField={selectedField}
                            seriesId={seriesId}
                            chartDetails={chartDetails}
                            item={filteredFields[index]}
                            key={filteredFields[index].id}
                            elId={index}
                            index={index}
                            highlightedIndex={highlightedIndex}
                            scrollIntoView={scrollIntoView}
                            {...getItemProps({
                              item: filteredFields[index],
                              id: filteredFields[index].id,
                              onItemClick: onItemClick
                            })}
                          />
                        );
                      }
                    }}
                  />
                )}
              </Flex>
            );
          }}
        </Dropdown>
      )}
    </Box>
  );
};

export default GroupBy;
