// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import { connect } from "react-redux";
import React, { useState } from "react";
import { Box, Heading, Image } from "@chakra-ui/react";
import * as R from "ramda";

import Close from "../../Close";
import MenuBar from "../MenuBar";
import { Header } from "../styles";
import chartLoader from "src/assets/charts/chartLoader.svg";
import useInterval from "src/hooks/useInterval";
import * as styles from "./styles";

import type { AppState } from "src/types";

type Props = {
  title: string,
  handleClose: Function
};

const Loader = ({ title, handleClose }: Props) => {
  const [count, setCount] = useState(0);

  useInterval(() => {
    if (count < 3) {
      setCount(count + 1);
    } else {
      setCount(1);
    }
  }, 300);

  return (
    <>
      <Header>
        <MenuBar />
        <Close handleClose={handleClose} />
        <h4>{title}</h4>
      </Header>

      <Box sx={{ position: "relative" }}>
        <Heading sx={styles.H6} as="h6">
          {i18n.t(k.LOADING_CHART)}
          {R.repeat(".", count)}
        </Heading>
        <Image sx={styles.Image} src={chartLoader} alt="loading charts..." />
      </Box>
    </>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  title: app.charts.newChartDialog.title
});

export default connect(mapStateToProps)(Loader);
