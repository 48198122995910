// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import * as R from "ramda";
import React, { useRef, useCallback, useEffect, useState } from "react";
import { useDebounce } from "use-debounce";
import { connect, useSelector, useDispatch } from "react-redux";
import { Text, Flex, CloseButton, Button, List } from "@chakra-ui/react";

import {
  AddParticipantDropdown,
  AddParticipantHeader,
  AddButton,
  Search,
  EmailOrNameTitle,
  SearchContainer,
  Selected
} from "./styles";
import * as styles from "./styles";
import OutsideClickHandler from "src/components/OutsideClickHandler";
import { addRoomMember, toggleMemberAddDialog } from "src/actions/chatroom";
import ChatroomInvitationModal from "./ChatroomInvitationModal";
import { getRoomSearchedFilteredResults, getGroups } from "src/reducers";
import SelectedNewUser from "./SelectedNewUser";
import SelectedNewGroup from "./SelectedNewGroup";
import SelectedNewEmail from "./SelectedNewEmail";
import NewUser from "./NewUser";
import NewGroup from "./NewGroup";
import { validateEmail, onManageView } from "src/utils";

import type {
  AppState,
  RoomId,
  ReactRef,
  UID,
  NonOrgParticipant
} from "src/types";

export type SelectOrUnselectParticipant = (
  params: { type: "user", id: string } | { type: "group", id: number }
) => void;

const getSelectedParticipantsCount = (
  members: Array<UID | NonOrgParticipant>,
  groupDetails: Array<Object>
) => {
  const selectedGroupsMembers = R.flatten(
    R.map(group => group.members, groupDetails)
  );

  const allMembers = [...members, ...selectedGroupsMembers];
  return new Set(allMembers).size;
};

type Props = {
  id?: number,
  location: string,
  outerRef: ReactRef,
  room: RoomId,
  _toggleMemberAddDialog: typeof toggleMemberAddDialog,
  activeDock: string,
  onAdd?: Function,
  onClose?: Function
};

const AddUser = ({
  id,
  location,
  outerRef,
  room,
  _toggleMemberAddDialog,
  activeDock,
  onAdd,
  onClose,
  ...restProps
}: Props) => {
  const dropdownRef: ReactRef = useRef(null);
  const searchRef: ReactRef = useRef(null);
  const [selectedUsers, setSelectedUsers] = useState<
    Array<UID | NonOrgParticipant>
  >([]);
  const [selectedGroups, setSelectedGroups] = useState<Array<number>>([]);
  const [showSearch, setShowSearch] = useState(false);
  const [search, setSearch] = useState("");
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [emailInvite, setEmailInvite] = useState(false);
  const [activeParticipantIndex, setActiveParticipantIndex] = useState(0);
  const dispatch = useDispatch();

  const [debouncedSearch] = useDebounce(search, 400);

  const searchedFilteredResults = useSelector(({ app }) =>
    getRoomSearchedFilteredResults(app, debouncedSearch)
  );

  const filteredResults = searchedFilteredResults.filter(
    ({ id }) => !(selectedUsers.includes(id) || selectedGroups.includes(id))
  );

  const selectedGroupDetails = useSelector(({ app }) =>
    getGroups(app, selectedGroups)
  );

  const totalSelectedParticipantsCount = getSelectedParticipantsCount(
    selectedUsers,
    selectedGroupDetails
  );

  const hideSearchResults = useCallback((e: any) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(e.target) &&
      // eslint-disable-next-line lingui/no-unlocalized-strings
      !e.target.className.includes("AddButton")
    )
      setShowSearch(false);
  }, []);

  const handleFocus = useCallback(() => setShowSearch(true), []);

  const handleClickOutside = useCallback(
    (e: any) => {
      if (outerRef.current && !outerRef.current.contains(e.target))
        _toggleMemberAddDialog(location);

      onClose && onClose();
    },
    [location, outerRef, toggleMemberAddDialog]
  );

  useEffect(() => {
    const participantListNode = dropdownRef.current;
    if (participantListNode) {
      const participant =
        participantListNode.querySelectorAll("li")[activeParticipantIndex];

      participant.scrollIntoView({
        behavior: "smooth",
        block: "center"
      });
    }
  }, [activeParticipantIndex]);

  const selectParticipant: SelectOrUnselectParticipant = useCallback(params => {
    switch (params.type) {
      case "user":
        setSelectedUsers(prev => [...prev, params.id]);
        setSearch("");
        searchRef.current.focus();
        break;

      case "group":
        setSelectedGroups(prev => [...prev, params.id]);
        setSearch("");
        searchRef.current.focus();
        break;

      default:
        break;
    }
  }, []);

  const unselectParticipant: SelectOrUnselectParticipant = useCallback(
    params => {
      switch (params.type) {
        case "user":
          const filterUsers = uid =>
            typeof uid === "object"
              ? uid.email !== params.id
              : uid !== params.id;
          setSelectedUsers(prev => prev.filter(filterUsers));
          setSearch("");
          searchRef.current.focus();
          break;

        case "group":
          const filterGroups = gid => gid !== params.id;
          setSelectedGroups(prev => prev.filter(filterGroups));
          setSearch("");
          searchRef.current.focus();
          break;

        default:
          break;
      }
    },
    []
  );

  const emailInviteCheck = selected => {
    for (let i = 0; i < selected.length; i++) {
      if (typeof selected[i] === "object" && selected[i].email) {
        return true;
      }
    }
    return false;
  };

  const handleSelectEmail = () => {
    if (
      !R.find(item => typeof item === "object" && item.email === search)(
        selectedUsers
      )
    ) {
      setSelectedUsers(prev => R.uniq([...prev, { email: search }]));
      setSearch("");
      searchRef.current.focus();
    }
    setEmailInvite(false);
  };

  const addUsers = useCallback(() => {
    if (!onAdd) {
      if (!R.isEmpty(selectedUsers) || !R.isEmpty(selectedGroups)) {
        const selected = [...selectedUsers, ...selectedGroups];
        if (!emailInviteCheck(selected)) {
          dispatch(
            addRoomMember({
              users: selectedUsers,
              groups: selectedGroups,
              roomId: room,
              request: false
            })
          );
          _toggleMemberAddDialog(location);
          setSelectedUsers([]);
          setSelectedGroups([]);
        } else {
          setShowInviteModal(true);
        }
      }
    } else {
      onAdd(id, selectedUsers);
      setSelectedUsers([]);
      setSelectedGroups([]);
    }
  }, [
    location,
    search,
    addRoomMember,
    selectedUsers,
    selectedGroups,
    room,
    _toggleMemberAddDialog
  ]);

  const handleSubmit = useCallback((e: Event) => {
    e.preventDefault();
  }, []);

  const handleSearch = useCallback(e => {
    setSearch(e.target.value);
    const email = validateEmail(e.target.value);

    if (email) {
      setEmailInvite(true);
    } else {
      setEmailInvite(false);
    }
  }, []);

  const isInfoPanelOpen = activeDock === "info";

  const setParticipantRole = ({ email, role }) => {
    setSelectedUsers(prevState =>
      prevState.map(item => {
        if (!R.is(Object, item) || item.email !== email) return item;

        return { email, role };
      })
    );
  };

  const sendInvites = () => {
    const participants = selectedUsers.map(item => {
      if (typeof item === "string") return item;

      return !item.role
        ? { email: item.email, skipInvite: true }
        : { email: item.email, orgRole: item.role };
    });

    dispatch(
      addRoomMember({
        users: participants,
        groups: null,
        roomId: room,
        request: false
      })
    );
    _toggleMemberAddDialog(location);
  };

  if (showInviteModal) {
    return (
      <ChatroomInvitationModal
        open
        // $FlowFixMe
        participants={R.filter(R.is(Object, R.__), selectedUsers)}
        setParticipantRole={setParticipantRole}
        onBack={() => setShowInviteModal(false)}
        onClose={() => setShowInviteModal(false)}
        sendWithInvites={sendInvites}
      />
    );
  }

  const showErrorText =
    filteredResults.length === 0 && emailInvite === false && search.length > 0;

  const keyboardHandler = e => {
    const keyPressed = e.key;
    if (keyPressed === "ArrowDown") {
      setActiveParticipantIndex(index =>
        index === filteredResults.length - 1 ? index : index + 1
      );
    }
    if (keyPressed === "ArrowUp") {
      setActiveParticipantIndex(index => (index === 0 ? index : index - 1));
    }

    if (keyPressed === "Enter") {
      if (filteredResults[activeParticipantIndex]) {
        const participant = filteredResults[activeParticipantIndex];
        const { type, id } = participant;
        if (filteredResults.length - 1 === activeParticipantIndex) {
          setActiveParticipantIndex(index => index - 1);
        }
        if (type === "user") {
          selectParticipant({ type: "user", id });
        } else if (type === "group") {
          selectParticipant({ type: "group", id });
        }
      }
    }
  };

  return (
    <OutsideClickHandler onClickOutside={handleClickOutside}>
      <form
        onSubmit={handleSubmit}
        autoComplete="off"
        data-cy="addParticipantsForm"
      >
        <AddParticipantDropdown
          onKeyDown={keyboardHandler}
          isInfoPanelOpen={isInfoPanelOpen}
          {...restProps}
        >
          <Flex sx={styles.AddParticipantHeaderWrapper}>
            <AddParticipantHeader>
              {i18n.t(k.ADD_PARTICIPANTS_OR_GROUPS)}
            </AddParticipantHeader>
            <CloseButton onClick={handleClickOutside} sx={styles.CloseButton} />
          </Flex>

          <EmailOrNameTitle>{i18n.t(k.NAME_EMAIL_OR_GROUP)}</EmailOrNameTitle>
          <OutsideClickHandler onClickOutside={hideSearchResults}>
            <SearchContainer>
              {(selectedUsers.length > 0 || selectedGroups.length > 0) && (
                <Selected>
                  {selectedUsers.map((user: any) => {
                    if (R.is(Object, user)) {
                      return (
                        <SelectedNewEmail
                          key={user.email}
                          newEmail={user.email}
                          unselectParticipant={unselectParticipant}
                        />
                      );
                    }
                    return (
                      <SelectedNewUser
                        key={user}
                        id={user}
                        unselectParticipant={unselectParticipant}
                      />
                    );
                  })}
                  {selectedGroups.map(group => {
                    return (
                      <SelectedNewGroup
                        key={group}
                        id={group}
                        unselectParticipant={unselectParticipant}
                      />
                    );
                  })}
                </Selected>
              )}

              <Search
                placeholder={
                  onManageView()
                    ? // eslint-disable-next-line lingui/no-unlocalized-strings
                      "Search People or Groups"
                    : i18n.t(k.SEARCH_PEOPLE_OR_GROUPS)
                }
                autoFocus
                value={search}
                onChange={handleSearch}
                onFocus={handleFocus}
                ref={searchRef}
                type="search"
                data-cy="searchMembers"
              />
            </SearchContainer>

            {emailInvite && filteredResults.length === 0 && (
              <Button onClick={handleSelectEmail} sx={styles.AddEmail}>
                {i18n.t(k.INVITE)}
                {search}
                {i18n.t(k._10)}
              </Button>
            )}

            {showSearch && filteredResults.length > 0 && (
              <List sx={styles.SearchDropdown} ref={dropdownRef}>
                {filteredResults.map((userOrGroup, index) => {
                  const { id, type } = userOrGroup;

                  return type === "user" ? (
                    <NewUser
                      key={id}
                      id={id}
                      selectParticipant={selectParticipant}
                      activeParticipant={index === activeParticipantIndex}
                    />
                  ) : (
                    <NewGroup
                      key={id}
                      id={id}
                      selectParticipant={selectParticipant}
                      activeParticipant={index === activeParticipantIndex}
                    />
                  );
                })}
              </List>
            )}
          </OutsideClickHandler>
          {showErrorText && (
            <Text sx={styles.ErrorText}>
              {i18n.t(k.NO_RESULTS_FOR1)}
              {search}
              {i18n.t(k._10)}
            </Text>
          )}
          <Text sx={styles.EmailExampleText}>
            {i18n.t(k.TO_INVITE_SOMEONE_EXTERNALLY)}{" "}
            <b>
              <i>{i18n.t(k.JOHN_DOE_ACME_COM)}</i>
            </b>
          </Text>
          <AddButton
            type="button"
            onClick={addUsers}
            data-cy="addToConversation"
          >
            {i18n.t(k.ADD1)}{" "}
            {totalSelectedParticipantsCount > 0 &&
              totalSelectedParticipantsCount}{" "}
            {i18n.t(k.TO_CONVERSATION)}
          </AddButton>
        </AddParticipantDropdown>
      </form>
    </OutsideClickHandler>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  isSrwMobile: app.srw.isMobile,
  activeDock: app.activeDock.main
});

export default connect(mapStateToProps, {
  _toggleMemberAddDialog: toggleMemberAddDialog
})(AddUser);
