// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import { connect } from "react-redux";
import React, { useCallback } from "react";
import { toast } from "react-toastify";

import Icon from "src/icons";
import { setWorkflow, clearWorkflowInstances } from "src/actions/workflows";
import { getWorkflowInstanceFilter } from "src/reducers";
import BlueOutlineButton from "src/components/Buttons/BlueOutline";

import type { AppState, WorkflowInstanceFilter } from "src/types";

type Props = {
  filter: WorkflowInstanceFilter,
  _setWorkflow: Function,
  _clearWorkflowInstances: Function
};

const Refresh = ({ filter, _setWorkflow, _clearWorkflowInstances }: Props) => {
  const handleReload = useCallback(() => {
    _clearWorkflowInstances();
    _setWorkflow({ query: filter });
    toast.success(i18n.t(k.REFRESHED_PROCESS_TABLE));
  }, [filter, _setWorkflow, _clearWorkflowInstances]);

  return (
    <BlueOutlineButton
      leftIcon={<Icon type="circularArrows" />}
      onClick={handleReload}
    >
      {i18n.t(k.REFRESH)}
    </BlueOutlineButton>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  filter: getWorkflowInstanceFilter(app)
});

export default connect(mapStateToProps, {
  _setWorkflow: setWorkflow,
  _clearWorkflowInstances: clearWorkflowInstances
})(Refresh);
