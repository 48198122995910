// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";
import moment from "moment";

import type { UnifizeUser } from "src/types";

type Props = {
  user: UnifizeUser
};

const LastLoggedIn = ({ user }: Props) => (
  <span>
    {user.lastLogin && user.lastLogin.toDate
      ? moment(user.lastLogin.toDate()).format(i18n.t(k.MMM_DD_YYYY_HH_MM_A))
      : null}
  </span>
);

export default LastLoggedIn;
