// @flow

import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useDropzone } from "react-dropzone";

import { showFileUploadModal } from "src/actions/file";
import ChatHistory from "src/components/messages/History";
import ChatInput from "src/components/chatroom/Input";
import { ChatArea as ChatAreaWrapper } from "src/styles";
import type { RoomId } from "src/types";

type Props = {
  roomId: RoomId
};

const ChatArea = ({ roomId }: Props) => {
  const dispatch = useDispatch();

  const onDrop = useCallback(
    acceptedFiles => {
      dispatch(showFileUploadModal({ viewId: roomId, files: acceptedFiles }));
    },
    [dispatch, roomId]
  );

  const { getRootProps, isDragActive } = useDropzone({ onDrop });
  const dragAndDropProps = getRootProps();

  return (
    <ChatAreaWrapper
      {...dragAndDropProps}
      isDragActive={isDragActive}
      onFocus={null}
      onBlur={null}
    >
      <ChatHistory />
      <ChatInput />
    </ChatAreaWrapper>
  );
};

export default ChatArea;
