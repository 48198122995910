// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React, { useState, useEffect } from "react";
import * as R from "ramda";
import { useSelector, useDispatch } from "react-redux";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  TableContainer,
  Button,
  Text
} from "@chakra-ui/react";
import { v4 as uuid } from "uuid";

import { getCurrentUser, getCSVFieldMappingsFile } from "src/reducers";
import { storeFieldMapping } from "src/actions/workflows";
import { dataStages } from "src/constants";
import SelectFields from "./SelectFields";
import * as styles from "./styles";

import type { ProcessTemplateId, DataStage, FieldMappings } from "src/types";

type Props = {
  templateId: ProcessTemplateId,
  mappedFields: FieldMappings,
  loading: DataStage
};

type clickHandlerType = {
  e: SyntheticMouseEvent<HTMLButtonElement>,
  formFieldId: ?number,
  checklistId: ?number,
  mappedFieldIndex: number
};

const FieldMappingTable = ({ templateId, mappedFields, loading }: Props) => {
  const [localFieldMapping, setLocalFieldMapping] = useState({});
  const dispatch = useDispatch();
  const currentUserEmail = useSelector(({ app }) => getCurrentUser(app).email);
  const file = useSelector(({ app }) =>
    getCSVFieldMappingsFile(app, templateId)
  );

  const headers = R.keys(mappedFields);
  const fieldIds = R.values(mappedFields);

  useEffect(() => {
    if (R.isEmpty(localFieldMapping) && mappedFields) {
      let savedFieldMapping = {};
      (Object.values(mappedFields): Array<any>).forEach(
        (field: $ElementType<FieldMappings, string>, index) => {
          if (field?.fieldId != null) {
            const { formFieldId, checklistId, fieldId } = field;
            savedFieldMapping = {
              ...savedFieldMapping,
              [headers[index]]: { formFieldId, checklistId, fieldId }
            };
          }
        }
      );
      if (!R.isEmpty(savedFieldMapping)) {
        setLocalFieldMapping(savedFieldMapping);
      }
    }
  }, []);

  const clickHandler = ({
    e,
    formFieldId,
    checklistId,
    mappedFieldIndex
  }: clickHandlerType) => {
    const tableHeaderValue = headers[mappedFieldIndex];

    const selectedFieldId = Number(e.currentTarget.dataset.value);
    setLocalFieldMapping(prev => ({
      ...prev,
      [tableHeaderValue]: { formFieldId, checklistId, fieldId: selectedFieldId }
    }));
  };

  const submitHandler = () => {
    dispatch(
      storeFieldMapping({
        templateId,
        fileName: file.name,
        localMappedField: localFieldMapping,
        file
      })
    );
  };

  if (loading === dataStages.updated) {
    return (
      <Text fontSize="lg">
        {i18n.t(k.YOU_WILL_RECEIVE_AN_EMAIL_ON)}{" "}
        <strong>{currentUserEmail}</strong> {i18n.t(k.ONCE_THE_IMPORT_IS_DON)}
      </Text>
    );
  }

  return (
    <>
      <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr>
              {headers.map(header => (
                <Th key={header}>{header}</Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              {fieldIds.map((id, index) => {
                const headerValue = headers[index];
                return (
                  <SelectFields
                    key={uuid()}
                    mappedFields={mappedFields}
                    localFieldMapping={localFieldMapping}
                    headerValue={headerValue}
                    clickHandler={clickHandler}
                    mappedFieldIndex={index}
                  />
                );
              })}
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
      <Button
        sx={styles.SubmitButton}
        isLoading={loading === dataStages.updating}
        isDisabled={R.isEmpty(localFieldMapping)}
        variant="uPrimary"
        onClick={submitHandler}
      >
        {i18n.t(k.SUBMIT1)}
      </Button>
    </>
  );
};

export default FieldMappingTable;
