// @flow

import React from "react";
import { Portal } from "@chakra-ui/react";

import { OpaqueOverlay as StyledAnalytics } from "src/components/Manage/Workflow/styles";
import Table from "./Table";
import Filters from "./Filters";
import Header from "./Header";
import Navigation from "src/components/Manage/Navigation";

const Analytics = () => {
  return (
    <Portal>
      <StyledAnalytics id="user-analytics">
        <Navigation tab="user-analytics" />
        <Header />
        <Filters />
        <Table />
      </StyledAnalytics>
    </Portal>
  );
};

export default Analytics;
