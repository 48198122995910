// @flow

import React from "react";

import * as colors from "src/styles/constants/colors";

type Props = {
  size?: number,
  color?: string,
  style?: Object,
  onClick?: Function
};

const Translation = ({
  size = 50,
  color = colors.grey42,
  style = {},
  onClick = () => {}
}: Props) => {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...style }}
      onClick={onClick}
    >
      <defs>
        <mask
          id="mask0_1989_1177"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width={`${size}px`}
          height={`${size}px`}
        >
          <rect width={`${size}px`} height={`${size}px`} fill="#D9D9D9" />
        </mask>
      </defs>
      <g mask="url(#mask0_1989_1177)">
        <path
          d="M24.7916 45.8333L34.2708 20.8333H38.6458L48.125 45.8333H43.75L41.5104 39.4792H31.4062L29.1666 45.8333H24.7916ZM8.3333 39.5833L5.41663 36.6667L15.9375 26.1458C14.7222 24.9306 13.6198 23.5417 12.6302 21.9792C11.6406 20.4167 10.7291 18.6458 9.8958 16.6667H14.2708C14.9652 18.0208 15.6597 19.2014 16.3541 20.2083C17.0486 21.2153 17.8819 22.2222 18.8541 23.2292C20 22.0833 21.1892 20.4774 22.4218 18.4115C23.6545 16.3455 24.5833 14.375 25.2083 12.5H2.0833V8.33334H16.6666V4.16668H20.8333V8.33334H35.4166V12.5H29.375C28.6458 15 27.552 17.5695 26.0937 20.2083C24.6354 22.8472 23.1944 24.8611 21.7708 26.25L26.7708 31.3542L25.2083 35.625L18.8541 29.1146L8.3333 39.5833ZM32.7083 35.8333H40.2083L36.4583 25.2083L32.7083 35.8333Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default Translation;
