// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React, { useCallback } from "react";
import { connect } from "react-redux";
import { RoundButton } from "./styles";
import Icons from "src/icons";
import type { UID, AppState, CurrentFilter } from "src/types";

import { updateFilter } from "src/actions/filter";
import { getCurrentUserId, getCurrentFilter } from "src/reducers";

type Props = {
  uid: UID,
  owner: ?UID,
  _updateFilter: Function,
  currentFilter: CurrentFilter
};

const Owner = ({ uid, owner, _updateFilter, currentFilter }: Props) => {
  const toggleOwner = useCallback(() => {
    if (owner) {
      _updateFilter({
        ...currentFilter,
        owner: false
      });
    } else {
      _updateFilter({
        ...currentFilter,
        owner: uid
      });
    }
  }, [_updateFilter, owner, uid, currentFilter]);

  return (
    <>
      <RoundButton
        type="button"
        onClick={toggleOwner}
        onKeyPress={toggleOwner}
        active={owner === uid}
      >
        <Icons type="iown" />
        {i18n.t(k.I_OWN)}
      </RoundButton>
    </>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  owner: app.chatRooms.filters.current.owner,
  uid: getCurrentUserId(app),
  currentFilter: getCurrentFilter(app)
});

export default connect(mapStateToProps, {
  _updateFilter: updateFilter
})(Owner);
