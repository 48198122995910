// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import { connect, useSelector } from "react-redux";
import React from "react";
import * as R from "ramda";

import { MenuItem, Value } from "./styles";
import defaultStatus, * as statusConstants from "src/constants/status";
import Name from "src/components/Status/Name";
import Archived from "./Archived";
import {
  getChatroomStatus,
  getChatroomCustomStatus,
  getChatRoom,
  getWorkflowAdhocArchived,
  getChatroomManualArchived,
  getStatusArchiveMode,
  getTemplateId
} from "src/reducers";

import type {
  AppState,
  WorkflowStatuses,
  UnifizeChatRoom,
  RoomId
} from "src/types";
import Icon from "src/icons";
import { Tooltip, Button } from "@chakra-ui/react";

type Props = {
  value: number,
  customStatus: WorkflowStatuses,
  handleStatus: (status: number) => void,
  handleArchive: (value: boolean) => void,
  room: UnifizeChatRoom,
  archiveAllowed: boolean,
  roomId: RoomId,
  manuallyArchived: boolean,
  location: string,
  selected: boolean
};

const Workflow = ({
  value,
  customStatus,
  handleStatus,
  handleArchive,
  room,
  roomId
}: Props) => {
  const pending = defaultStatus[statusConstants.PENDING];
  const completed = defaultStatus[statusConstants.COMPLETED];
  const canceled = defaultStatus[statusConstants.CANCELED];

  const manuallyArchived = useSelector(({ app }) =>
    getChatroomManualArchived(app, roomId)
  );
  const chatroomWorkflowId = useSelector(({ app }) =>
    getTemplateId(app, roomId)
  );
  const chatroomStatus = useSelector(({ app }) =>
    getChatroomStatus(app, roomId)
  );
  const chatroomAutoArchived = useSelector(
    ({ app }) =>
      getStatusArchiveMode(app, chatroomWorkflowId, chatroomStatus) ===
      "archive"
  );
  const archiveAllowed = useSelector(({ app }) =>
    getWorkflowAdhocArchived(app, chatroomWorkflowId)
  );

  const hiddenStatusIds = R.pluck("id")(room.hiddenStatuses || []);
  // Exclude hidden statuses
  const statuses = customStatus.filter(
    status => !hiddenStatusIds.includes(status.id)
  );

  const isArchived =
    manuallyArchived === null || manuallyArchived === undefined
      ? chatroomAutoArchived
      : manuallyArchived;

  if (statuses.length === 0) {
    return (
      <>
        <MenuItem
          tabIndex="0"
          role="button"
          onClick={() => handleStatus(statusConstants.PENDING)}
          onKeyPress={() => handleStatus(statusConstants.PENDING)}
        >
          <Value color={pending.color} textColor={pending.textColor}>
            {pending.text}
          </Value>
          <span
            dangerouslySetInnerHTML={{
              __html: `${value === statusConstants.PENDING ? "&#10004;" : ""}`
            }}
          />
        </MenuItem>
        <MenuItem
          tabIndex="0"
          role="button"
          onClick={() => handleStatus(statusConstants.COMPLETED)}
          onKeyPress={() => handleStatus(statusConstants.COMPLETED)}
        >
          <Value color={completed.color} textColor={completed.textColor}>
            {completed.text}
          </Value>
          <span
            dangerouslySetInnerHTML={{
              __html: `${value === statusConstants.COMPLETED ? "&#10004;" : ""}`
            }}
          />
        </MenuItem>
        <MenuItem
          tabIndex="0"
          role="button"
          onClick={() => handleStatus(statusConstants.CANCELED)}
          onKeyPress={() => handleStatus(statusConstants.CANCELED)}
        >
          <Value color={canceled.color} textColor={canceled.textColor}>
            {canceled.text}
          </Value>
          <span
            dangerouslySetInnerHTML={{
              __html: `${value === statusConstants.CANCELED ? "&#10004;" : ""}`
            }}
          />
        </MenuItem>
        {archiveAllowed && (
          <Button
            variant="link"
            bg="none"
            color="unifize_blue"
            fontSize="xs"
            leftIcon={<Icon type="archive" />}
            onClick={() => {
              handleArchive(!manuallyArchived);
            }}
          >
            {isArchived ? i18n.t(k.UNARCHIVE) : i18n.t(k.ARCHIVE)}{" "}
            {i18n.t(k.THIS_CONVERSATION)}
          </Button>
        )}
      </>
    );
  }

  return (
    <>
      {statuses.map(status => {
        const { color } = status.active
          ? defaultStatus[statusConstants.PENDING]
          : defaultStatus[statusConstants.COMPLETED];
        const { textColor } = status.active
          ? defaultStatus[statusConstants.PENDING]
          : defaultStatus[statusConstants.COMPLETED];

        const lockedStatusIndex = (room.lockedStatuses || []).findIndex(
          R.propEq("id", status.id)
        );

        const isLocked = lockedStatusIndex >= 0;

        return (
          <Tooltip
            key={status.id}
            label={
              isLocked &&
              ((room.lockedStatuses || [])[lockedStatusIndex].tooltip ||
                "Disabled")
            }
            hasArrow
            placement="right"
            bg="unifize_red500"
          >
            <MenuItem
              key={status.id}
              tabIndex="0"
              role="button"
              data-cy={`${i18n.t(k.STATUS1)}${-status.id}`}
              onClick={() => !isLocked && handleStatus(status.id)}
              onKeyPress={() => !isLocked && handleStatus(status.id)}
            >
              <Value color={color} textColor={textColor} disabled={isLocked}>
                <Name id={status.id} />
                {status.settings?.archiveMode === "archive" ? (
                  <Archived />
                ) : null}
              </Value>
              <span
                dangerouslySetInnerHTML={{
                  __html: `${value === status.id ? "&#10004;" : ""}`
                }}
              />
            </MenuItem>
          </Tooltip>
        );
      })}
      <MenuItem
        tabIndex="0"
        role="button"
        onClick={() => handleStatus(statusConstants.CANCELED)}
        onKeyPress={() => handleStatus(statusConstants.CANCELED)}
      >
        <Value color={canceled.color} textColor={canceled.textColor}>
          {canceled.text}
        </Value>
        <span
          dangerouslySetInnerHTML={{
            __html: `${value === statusConstants.CANCELED ? "&#10004;" : ""}`
          }}
        />
      </MenuItem>
      {archiveAllowed && (
        <Button
          variant="link"
          bg="none"
          color="unifize_blue"
          fontSize="xs"
          leftIcon={<Icon type="archive" />}
          onClick={() => {
            handleArchive(!manuallyArchived);
          }}
        >
          {isArchived ? i18n.t(k.UNARCHIVE) : i18n.t(k.ARCHIVE)}{" "}
          {i18n.t(k.THIS_CONVERSATION)}
        </Button>
      )}
    </>
  );
};

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  value: getChatroomStatus(app, props.roomId),
  customStatus: getChatroomCustomStatus(app, props.roomId),
  room: getChatRoom(app, props.roomId)
});

export default connect(mapStateToProps)(Workflow);
