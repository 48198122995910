// @flow
import React from "react";

const BulletedList = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_255_340"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="14"
      height="14"
    >
      <rect
        x="0.399902"
        y="0.400024"
        width="13.2"
        height="13.2"
        fill="#D9D9D9"
      />
    </mask>
    <g mask="url(#mask0_255_340)">
      <path d="M5.3498 10.85V9.74998H11.9498V10.85H5.3498ZM5.3498 7.54998V6.44998H11.9498V7.54998H5.3498ZM5.3498 4.24998V3.14998H11.9498V4.24998H5.3498ZM3.1498 11.4C2.8473 11.4 2.58835 11.2923 2.37293 11.0769C2.15751 10.8614 2.0498 10.6025 2.0498 10.3C2.0498 9.99748 2.15751 9.73852 2.37293 9.5231C2.58835 9.30768 2.8473 9.19998 3.1498 9.19998C3.4523 9.19998 3.71126 9.30768 3.92668 9.5231C4.1421 9.73852 4.2498 9.99748 4.2498 10.3C4.2498 10.6025 4.1421 10.8614 3.92668 11.0769C3.71126 11.2923 3.4523 11.4 3.1498 11.4ZM3.1498 8.09998C2.8473 8.09998 2.58835 7.99227 2.37293 7.77685C2.15751 7.56143 2.0498 7.30248 2.0498 6.99998C2.0498 6.69748 2.15751 6.43852 2.37293 6.2231C2.58835 6.00768 2.8473 5.89998 3.1498 5.89998C3.4523 5.89998 3.71126 6.00768 3.92668 6.2231C4.1421 6.43852 4.2498 6.69748 4.2498 6.99998C4.2498 7.30248 4.1421 7.56143 3.92668 7.77685C3.71126 7.99227 3.4523 8.09998 3.1498 8.09998ZM3.1498 4.79998C2.8473 4.79998 2.58835 4.69227 2.37293 4.47685C2.15751 4.26143 2.0498 4.00248 2.0498 3.69998C2.0498 3.39748 2.15751 3.13852 2.37293 2.9231C2.58835 2.70768 2.8473 2.59998 3.1498 2.59998C3.4523 2.59998 3.71126 2.70768 3.92668 2.9231C4.1421 3.13852 4.2498 3.39748 4.2498 3.69998C4.2498 4.00248 4.1421 4.26143 3.92668 4.47685C3.71126 4.69227 3.4523 4.79998 3.1498 4.79998Z" />
    </g>
  </svg>
);

export default BulletedList;
