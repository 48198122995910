// @flow

import React from "react";

import * as colors from "src/styles/constants/colors";

type Props = {
  size?: number,
  color?: string,
  style?: Object
};

const Form = ({ size = 16, color = colors.grey42, style = {} }: Props) => {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...style }}
    >
      <defs>
        <mask
          id="mask0_1340_919"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="16"
          height="16"
        >
          <rect width="16" height="16" fill="#D9D9D9" />
        </mask>
      </defs>
      <g mask="url(#mask0_1340_919)">
        <path
          d="M2.33334 13.6666V12.6666H13.6667V13.6666H2.33334ZM7.66668 11.0833V10.0833H13.6667V11.0833H7.66668ZM7.66668 8.49992V7.49992H13.6667V8.49992H7.66668ZM7.66668 5.91658V4.91659H13.6667V5.91658H7.66668ZM2.33334 3.33325V2.33325H13.6667V3.33325H2.33334ZM2.33334 10.2691V5.73075L4.60251 7.99992L2.33334 10.2691Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default Form;
