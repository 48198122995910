// @flow

import { useEffect } from "react";
import { useSelector } from "react-redux";

import { getFieldWidth, getCurrentLocation } from "src/reducers";

type Props = {
  isOpen: boolean,
  handleClose: () => void,
  callback?: Function,
  parentContainerRef: React$ElementRef<any>,
  threshold?: number,
  offset?: number
};

const usePopoverScroll = ({
  isOpen,
  handleClose,
  callback,
  parentContainerRef,
  threshold,
  offset
}: Props) => {
  const currentLocation =
    useSelector(({ location }) => getCurrentLocation(location)) || "";
  const titleFieldWidth = useSelector(({ app }) => getFieldWidth(app, "title"));
  const defaultThreshold =
    86 +
    parseInt(titleFieldWidth.split("px")[0]) +
    (offset != undefined ? offset : 94);

  const isManageView =
    currentLocation.includes("manage") ||
    currentLocation.includes("reports") ||
    currentLocation.includes("dashboard");

  const handleScroll = () => {
    if (!parentContainerRef.current) return;

    const containerRect = parentContainerRef.current.getBoundingClientRect();

    if (containerRect.right < (threshold ?? defaultThreshold) && isManageView) {
      handleClose();
    }
  };

  useEffect(() => {
    if (!isOpen) return;

    window.addEventListener("scroll", callback ?? handleScroll, true);
    return () =>
      window.removeEventListener("scroll", callback ?? handleScroll, true);
  }, [isOpen, handleClose]);

  return null;
};

export default usePopoverScroll;
