// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";

import type { OrgCloneWizardData } from "src/types";

import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  VStack
} from "@chakra-ui/react";
import * as styles from "./OrgInfo.styles";

type Props = {
  wizardData: OrgCloneWizardData,
  onChange: Function
};

export default function OrgInfo({ wizardData, onChange }: Props) {
  const handleDataChange = path => e => {
    onChange(path)(e.target.value);
  };

  return (
    <VStack sx={styles.container}>
      <FormControl isInvalid={wizardData.orgName.error} isRequired>
        <FormLabel>{i18n.t(k.ORG_NAME)}</FormLabel>
        <Input
          value={wizardData.orgName.value}
          onChange={handleDataChange(["orgName"])}
        />

        <FormErrorMessage>{wizardData.orgName.error}</FormErrorMessage>
      </FormControl>

      <VStack sx={styles.inviteeList}>
        <HStack sx={styles.fieldsRow}>
          <FormControl
            sx={styles.formControl}
            isInvalid={wizardData.colleagues[0].error}
          >
            <FormLabel>{i18n.t(k.EMAIL_ADDRESS)}</FormLabel>
            <Input
              value={wizardData.colleagues[0].value}
              onChange={handleDataChange(["colleagues", 0])}
            />

            <FormErrorMessage>
              {wizardData.colleagues[0].error}
            </FormErrorMessage>
          </FormControl>

          <FormControl
            sx={styles.formControl}
            isInvalid={wizardData.colleagues[1].error}
          >
            <FormLabel>{i18n.t(k.EMAIL_ADDRESS)}</FormLabel>
            <Input
              value={wizardData.colleagues[1].value}
              onChange={handleDataChange(["colleagues", 1])}
            />

            <FormErrorMessage>
              {wizardData.colleagues[1].error}
            </FormErrorMessage>
          </FormControl>
        </HStack>

        <HStack sx={styles.fieldsRow}>
          <FormControl
            sx={styles.formControl}
            isInvalid={wizardData.colleagues[2].error}
          >
            <FormLabel>{i18n.t(k.EMAIL_ADDRESS)}</FormLabel>
            <Input
              value={wizardData.colleagues[2].value}
              onChange={handleDataChange(["colleagues", 2])}
            />

            <FormErrorMessage>
              {wizardData.colleagues[2].error}
            </FormErrorMessage>
          </FormControl>

          <FormControl
            sx={styles.formControl}
            isInvalid={wizardData.colleagues[3].error}
          >
            <FormLabel>{i18n.t(k.EMAIL_ADDRESS)}</FormLabel>
            <Input
              value={wizardData.colleagues[3].value}
              onChange={handleDataChange(["colleagues", 3])}
            />

            <FormErrorMessage>
              {wizardData.colleagues[3].error}
            </FormErrorMessage>
          </FormControl>
        </HStack>
      </VStack>
    </VStack>
  );
}
