// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AreaHighlight {
  border: 1px solid #333;
  background-color: rgba(252, 232, 151, 1);
  opacity: 0.5;
}
`, "",{"version":3,"sources":["webpack://./src/pdf-viewer/style/AreaHighlight.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,wCAAwC;EACxC,YAAY;AACd","sourcesContent":[".AreaHighlight {\n  border: 1px solid #333;\n  background-color: rgba(252, 232, 151, 1);\n  opacity: 0.5;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
