// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React, { useState, useEffect, memo } from "react";
import {
  Input,
  Flex,
  InputGroup,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  Checkbox,
  FormLabel,
  FormControl
} from "@chakra-ui/react";
import { SortableHandle } from "react-sortable-hoc";
import { ChevronUpIcon, ChevronDownIcon } from "@chakra-ui/icons";

import * as colors from "src/styles/constants/colors";
import ComparisonType from "./ComparisonType";
import Tooltip from "src/components/Tooltip";
import useBoolean from "src/hooks/useBoolean";
import ColorDropdown from "./ColorDropdown";
import RateDropdown from "./RateDropdown";
import SummaryType from "./SummaryType";
import DeleteModal from "./DeleteModal";
import * as styles from "../styles";
import Icon from "src/icons";
import {
  getChartFieldId,
  setChartSeries,
  handleTrendline,
  setTrendline,
  handleSeriesDelete
} from "src/utils/charts.js";

const DragHandle = SortableHandle(() => (
  <IconButton
    sx={styles.IconButton}
    // eslint-disable-next-line lingui/no-unlocalized-strings
    aria-label="Drag Handle"
    icon={<Icon type="dragIndicator" />}
  />
));
type Props = {
  id: string,
  chartDetails: Object,
  position: number,
  setChartDetails: Function
};

const Rate = ({ id, position, chartDetails, setChartDetails }: Props) => {
  const [rateOperation, setRateOperation] = useState(null);
  const { value: isCollapsed, toggleBoolean } = useBoolean(true);

  const currentSeries = chartDetails.series.find(item => {
    if (item.seriesId) {
      return item.seriesId === id;
    } else {
      return item.tempId === id;
    }
  });
  const { title, hide, operation, computationField, rateSeries } =
    currentSeries;

  // $FlowFixMe - Flow does not yet support method or property calls in optional chains.
  const currentTrendline = chartDetails.trendlines?.find(
    item => item.seriesId === id
  );
  const isTrendline = Boolean(currentTrendline);

  const computationFieldId =
    computationField && getChartFieldId(computationField);

  useEffect(() => {
    if (chartDetails.id && position) {
      setRateOperation(chartDetails.series[position - 1].rateOperation);
    }
  }, [chartDetails.id, position]);

  useEffect(() => {
    setChartSeries({
      setChartDetails,
      seriesId: id,
      key: "seqNo",
      value: position
    });

    setChartSeries({
      setChartDetails,
      seriesId: id,
      key: "rateOperation",
      value: rateOperation
    });
  }, [position, rateOperation]);

  return (
    <Flex sx={{ gap: "1rem", justifyContent: "center" }}>
      <DragHandle />

      <Flex sx={styles.SeriesContainer}>
        <InputGroup sx={styles.InputGroup}>
          <Tooltip
            sx={{ backgroundColor: "none" }}
            title={i18n.t(k.RATE)}
            placement="top"
          >
            <Icon type="rate" fill={colors.purple} width="24px" height="24px" />
          </Tooltip>

          <Input
            onChange={e =>
              setChartSeries({
                setChartDetails,
                seriesId: id,
                key: "title",
                value: e.target.value
              })
            }
            value={title}
            sx={styles.ValueInput}
            variant="unstyled"
            placeholder={i18n.t(k.UNTITLED_SERIES)}
          />

          <IconButton
            onClick={() =>
              setChartSeries({
                setChartDetails,
                seriesId: id,
                key: "hide",
                value: !hide
              })
            }
            sx={{ bg: "none" }}
            icon={
              <Icon
                type={hide ? "hidden" : "eye"}
                width="24px"
                height="24px"
                fill={colors.inkLightest}
              />
            }
          />

          <IconButton
            onClick={toggleBoolean}
            sx={styles.SeriesExpandButton}
            icon={
              isCollapsed ? (
                <ChevronUpIcon w={6} h={6} />
              ) : (
                <ChevronDownIcon w={6} h={6} />
              )
            }
            variant="unstyled"
          />
        </InputGroup>

        <RadioGroup onChange={setRateOperation} value={rateOperation}>
          <Stack direction="row" spacing="3rem">
            <Radio sx={{ bg: colors.brightLight }} value="percentage">
              {i18n.t(k.PERCENT)}
            </Radio>
            <Radio sx={{ bg: colors.brightLight }} value="ppm">
              {i18n.t(k.PPM)}
            </Radio>
          </Stack>
        </RadioGroup>

        {isCollapsed && (
          <>
            <SummaryType
              seriesId={id}
              operation={operation}
              setChartDetails={setChartDetails}
            />

            {operation && (
              <ComparisonType
                seriesId={id}
                chartDetails={chartDetails}
                operation={operation}
                setChartDetails={setChartDetails}
                computationFieldId={computationFieldId}
              />
            )}

            {computationField && (
              <FormControl sx={styles.FormLabel} isRequired>
                <FormLabel sx={styles.SeriesLabel}>
                  {i18n.t(k.SHOW_AS_PERCENT_OF_OTHER_VALUE)}
                </FormLabel>
                <RateDropdown
                  seriesId={id}
                  chartDetails={chartDetails}
                  rateSeries={rateSeries}
                  setChartDetails={setChartDetails}
                />
              </FormControl>
            )}

            {computationField ? (
              <Checkbox
                onChange={e => handleTrendline(setChartDetails, e, id)}
                isChecked={isTrendline}
              >
                {i18n.t(k.ADD_TRENDLINE)}
              </Checkbox>
            ) : (
              <Tooltip
                sx={{ backgroundColor: "none" }}
                title={i18n.t(k.SELECT_ABOVE_FIELDS_TO_ENABLE)}
                placement="top-start"
                hasArrow={false}
              >
                <Checkbox isDisabled>{i18n.t(k.ADD_TRENDLINE)}</Checkbox>
              </Tooltip>
            )}

            {isTrendline && (
              <Flex sx={{ gap: "1rem" }}>
                <FormControl sx={styles.FormLabel}>
                  <FormLabel sx={styles.SeriesLabel}>
                    {i18n.t(k.TRENDLINE_LABEL)}
                  </FormLabel>
                  <Input
                    onChange={e =>
                      setTrendline(setChartDetails, id, "label", e.target.value)
                    }
                    value={currentTrendline?.label}
                    sx={styles.BuilderInput}
                    placeholder={i18n.t(k.ENTER_TRENDLINE_LABEL)}
                  />
                </FormControl>

                <FormControl sx={styles.TrendlineColorWrapper}>
                  <FormLabel sx={styles.SeriesLabel}>
                    {i18n.t(k.TRENDLINE_COLOR)}
                  </FormLabel>
                  <ColorDropdown
                    setChartDetails={setChartDetails}
                    seriesId={id}
                    colorHexCode={currentTrendline?.color}
                  />
                </FormControl>
              </Flex>
            )}
          </>
        )}
      </Flex>

      <DeleteModal
        series
        title={title}
        handleDelete={() => handleSeriesDelete(setChartDetails, id)}
      />
    </Flex>
  );
};

export default memo<Props>(Rate);
