// @flow

import React from "react";
import {
  Modal as ChakraModal,
  ModalOverlay,
  ModalContent
} from "@chakra-ui/react";
import Button from "src/components/Unifize/Button";
import { Header, Title, CloseIcon, ModalBody, ModalFooter } from "./styles";
import * as colors from "src/styles/constants/colors";
import Icons from "src/icons";

type Props = {
  isOpen: boolean,
  onClose: Function,
  children: any,
  title: string,
  confirmLabel?: string,
  cancelLabel?: string,
  confirmVariant?: "error" | "secondary",
  cancelVariant?: "error" | "secondary",
  onConfirm?: () => void,
  width?: string,
  height?: string
};

const Modal = ({
  isOpen,
  onClose,
  children,
  title,
  confirmLabel,
  cancelLabel,
  confirmVariant = "default",
  cancelVariant = "secondary",
  onConfirm = () => {},
  width,
  height
}: Props) => {
  return (
    <ChakraModal isCentered isOpen={isOpen} onClose={onClose} size="md">
      <ModalOverlay />
      <ModalContent
        {...(width !== undefined ? { minWidth: width, maxWidth: width } : {})}
        {...(height !== undefined
          ? { minHeight: height, maxHeight: height }
          : {})}
        style={{
          borderRadius: "8px"
        }}
      >
        <Header>
          <Title>{title}</Title>
          <CloseIcon onClick={onClose}>
            <Icons type="close" color={colors.primary} />
          </CloseIcon>
        </Header>
        <ModalBody>{children}</ModalBody>
        <ModalFooter>
          <Button variant={cancelVariant} width="fit-content" onClick={onClose}>
            {cancelLabel ?? "Cancel"}
          </Button>
          <Button
            variant={confirmVariant}
            width="fit-content"
            onClick={onConfirm}
          >
            {confirmLabel ?? "Done"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </ChakraModal>
  );
};

export default Modal;
