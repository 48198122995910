// @flow
import React from "react";

const Link = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_255_349"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="14"
      height="14"
    >
      <rect y="0.400024" width="13.2" height="13.2" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_255_349)">
      <path d="M9.3501 11.4V9.75H7.7001V8.65H9.3501V7H10.4501V8.65H12.1001V9.75H10.4501V11.4H9.3501ZM6.0501 9.75H3.8501C3.08926 9.75 2.44072 9.48187 1.90447 8.94562C1.36822 8.40937 1.1001 7.76083 1.1001 7C1.1001 6.23917 1.36822 5.59062 1.90447 5.05437C2.44072 4.51812 3.08926 4.25 3.8501 4.25H6.0501V5.35H3.8501C3.39176 5.35 3.00218 5.51042 2.68135 5.83125C2.36051 6.15208 2.2001 6.54167 2.2001 7C2.2001 7.45833 2.36051 7.84792 2.68135 8.16875C3.00218 8.48958 3.39176 8.65 3.8501 8.65H6.0501V9.75ZM4.4001 7.55V6.45H8.8001V7.55H4.4001ZM12.1001 7H11.0001C11.0001 6.54167 10.8397 6.15208 10.5188 5.83125C10.198 5.51042 9.80843 5.35 9.3501 5.35H7.1501V4.25H9.3501C10.1109 4.25 10.7595 4.51812 11.2957 5.05437C11.832 5.59062 12.1001 6.23917 12.1001 7Z" />
    </g>
  </svg>
);

export default Link;
