// @flow
import React, { useState, useRef, useEffect } from "react";

import Icon from "src/icons";

import {
  Menu,
  MenuButton,
  ColorPicker,
  ColorPickerButton,
  ColorBar,
  TextIcon
} from "./styles";
import LinkModal from "./URLModal";

const StyleMenu = ({ editor }: { editor: Object }) => {
  const [linkModal, setLinkModal] = useState(false);
  const fgColorPickerRef = useRef(null);
  const bgColorPickerRef = useRef(null);
  const linkModalRef = useRef(null);

  // Hides the modal and remembers the last input
  const hideLinkModal = (setURL: string => void) => {
    setURL(editor.getAttributes("link").href);
    setLinkModal(false);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        linkModalRef.current &&
        !linkModalRef.current.contains(event.target)
      ) {
        setLinkModal(false);
      }
    }
    if (linkModal) {
      window.document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      window.document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [linkModal]);

  return (
    <Menu>
      <MenuButton
        onClick={() => editor.chain().focus().toggleBold().run()}
        active={editor.isActive("bold")}
      >
        <Icon type="bold" />
      </MenuButton>
      <MenuButton
        onClick={() => editor.chain().focus().toggleItalic().run()}
        active={editor.isActive("italic")}
      >
        <Icon type="italic" />
      </MenuButton>
      <MenuButton
        onClick={() => editor.chain().focus().toggleUnderline().run()}
        active={editor.isActive("underline")}
      >
        <Icon type="underline" />
      </MenuButton>
      <div style={{ position: "relative" }}>
        <MenuButton
          onClick={() => setLinkModal(!linkModal)}
          active={editor.isActive("link")}
        >
          <Icon type="link" style={{ width: "100%", height: "100%" }} />
        </MenuButton>
        <LinkModal
          isVisible={linkModal}
          onSave={(url: string, setURL) => {
            if (url) {
              editor
                .chain()
                .focus()
                .extendMarkRange("link")
                .setLink({
                  href:
                    url.startsWith("http://") || url.startsWith("https://")
                      ? url
                      : `https://${url}`,
                  title: "Contains link"
                })
                .run();
            } else if (url === "") {
              editor.chain().focus().extendMarkRange("link").unsetLink().run();
            }
            hideLinkModal(setURL);
          }}
          modalRef={linkModalRef}
        />
      </div>
      <MenuButton
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        active={editor.isActive("bulletList")}
      >
        <Icon type="bulletedList" />
      </MenuButton>
      <MenuButton
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        active={editor.isActive("orderedList")}
      >
        <Icon type="numberedList" />
      </MenuButton>
      {/*$FlowFixMe*/}
      <ColorPickerButton onClick={() => fgColorPickerRef.current?.click()}>
        <TextIcon>A</TextIcon>
        <ColorBar color={editor.getAttributes("textStyle").color} />
        <ColorPicker
          ref={fgColorPickerRef}
          type="color"
          active={false}
          onInput={event =>
            editor.chain().focus().setColor(event.target.value).run()
          }
          value={editor.getAttributes("textStyle").color ?? ""}
        />
      </ColorPickerButton>
      {/*$FlowFixMe*/}
      <ColorPickerButton onClick={() => bgColorPickerRef.current?.click()}>
        <Icon type="highlightColor" />
        <ColorBar
          color={editor.getAttributes("highlight").color || "#ffffff"}
        />
        <ColorPicker
          ref={bgColorPickerRef}
          type="color"
          active={false}
          onInput={event =>
            editor
              .chain()
              .focus()
              .toggleHighlight({ color: event.target.value })
              .run()
          }
          value={editor.getAttributes("highlight").color ?? "#ffffff"}
        />
      </ColorPickerButton>
    </Menu>
  );
};

export default StyleMenu;
