// @flow

import React from "react";

import { InlineFormGroup, Label } from "src/styles/form";
import Checkbox from "src/components/Checkbox.old";

type Props = {
  noConfirmation: boolean,
  handleConfirmationToSender: Function
};

const ConfirmationToSender = ({
  handleConfirmationToSender,
  noConfirmation
}: Props) => (
  <InlineFormGroup>
    <Label>
      <Checkbox
        id="confirmationToSender"
        checked={noConfirmation}
        handleChange={handleConfirmationToSender}
      />
      Do not send email confirmation to sender
    </Label>
  </InlineFormGroup>
);

export default ConfirmationToSender;
