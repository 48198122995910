// @flow

export default {
  conversationDialog: "conversation-dialog",
  chatHistory: "chat-history",
  activityPanel: "activity-panel",
  manageView: "manage-view",
  chatroomHeader: "chatroom-header",
  filesModal: "files-modal",
  moreMenu: "more-menu",
  checklistDock: "checklist-dock",
  dashboard: "dashboard"
};
