// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";
import { connect } from "react-redux";

import Common from "./Common";
import LinkedRoom from "./LinkedRoom";
import { getChatroomAddress } from "src/reducers";
import * as notifications from "src/constants/notifications";
import type { Message, ChatroomAddress } from "src/types";

type Props = {
  message: Message,
  dataAddress: ChatroomAddress,
  roomAddress: ChatroomAddress
};

const Foster = ({ message, dataAddress, roomAddress }: Props) => {
  const { action, data } = message;
  const { roomId: dataId } = data;
  let id = i18n.t(k._4);
  let address = i18n.t(k._4);
  let notificationMessage = i18n.t(k._4);
  switch (action) {
    case notifications.FOSTER_PARENT_ADD:
      notificationMessage = i18n.t(k.ADDED_PARENT);
      id = dataId;
      address = roomAddress;
      break;
    case notifications.FOSTER_PARENT_REMOVE:
      notificationMessage = i18n.t(k.REMOVED_PARENT);
      id = dataId;
      address = roomAddress;
      break;
    case notifications.FOSTER_CHILD_ADD:
      notificationMessage = i18n.t(k.ADDED_CHILD);
      id = dataId;
      address = dataAddress;
      break;
    case notifications.FOSTER_CHILD_REMOVE:
      notificationMessage = i18n.t(k.REMOVED_CHILD);
      id = dataId;
      address = dataAddress;
      break;
    default:
      break;
  }
  return (
    <Common {...message}>
      {" "}
      {notificationMessage}{" "}
      <LinkedRoom id={id || ""} chatroomAddress={address} />
    </Common>
  );
};

const mapStateToProps = ({ app }, { message }) => ({
  roomAddress: getChatroomAddress(app, message.roomId),
  dataAddress: getChatroomAddress(app, message.data.roomId)
});

export default connect(mapStateToProps)(Foster);
