// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React, { useMemo, Fragment } from "react";
import * as R from "ramda";
import { marked } from "marked";
import { push } from "redux-first-router";

import type { ExternalAppMentions, MessageText } from "src/types";
import { User as StyledUser } from "./styles";
import MentionedUser from "./MentionedUser";

/**
 * Check if the url belongs in same domain if so open it in same tab without refresh
 */
window.handleSameDomain = (event: any) => {
  if (window.location.origin === event.target.origin) {
    const newUrl = event.target.href.replace(event.target.origin, "");
    push(newUrl);
    return false;
  }
  return true;
};

const mentionsRegExp = /<[@|!](.*?)>/g;
const renderer = new marked.Renderer();

renderer.link = function (href, title, text) {
  var link = marked.Renderer.prototype.link.call(this, href, title, text);
  return link.replace("<a", "<a target='_blank' ");
};

marked.setOptions({
  baseUrl: null,
  breaks: true,
  gfm: true,
  headerIds: true,
  headerPrefix: "",
  highlight: null,
  langPrefix: "language-",
  mangle: false,
  pedantic: false,
  sanitize: true,
  sanitizer: null,
  silent: true,
  smartLists: false,
  smartypants: false,
  tokenizer: null,
  walkTokens: null,
  xhtml: false,
  renderer: renderer
});

const tokenizer = {
  code(src) {
    return undefined;
  }
};

marked.use({ tokenizer });

const MessageWithMentions = ({
  message,
  mentions = {}
}: {
  message: MessageText,
  mentions?: ExternalAppMentions
}) => {
  const messageText = message?.html ?? message?.text ?? message?.message ?? "";
  const textAsMarkdown = useMemo(
    () => marked(messageText || ""),
    [messageText]
  );

  const markdownText = messageText;

  const mentionUIDs = useMemo(
    () =>
      R.match(mentionsRegExp, messageText || "").map((mention: ?string) => {
        let filteredStr = (mention || "").replace(/<[@|!]/g, "");
        filteredStr = filteredStr.replace(/>/g, "");
        return filteredStr;
      }),
    [messageText]
  );

  const splitMessage = useMemo(
    () =>
      (markdownText || "")
        .replace(/&lt;/g, "<")
        .replace(/&gt;/g, ">")
        .split(mentionsRegExp),
    [markdownText]
  );

  // strip css classes from span tags
  const stripAttributes = (text: string) => {
    const formattedHtml = text.replace(
      /<span[^>]*class\s*=\s*["'][^"']*["'][^>]*>/gi,
      "<span>"
    );
    return formattedHtml;
  };

  if (!messageText) {
    return <></>;
  }

  if (mentionUIDs.length > 0) {
    return (
      <>
        {R.map(
          (text: string) => (
            <Fragment key={text}>
              {mentionUIDs.includes(text) ? (
                <StyledUser>
                  {i18n.t(k._40)}
                  <MentionedUser uid={text} mentions={mentions} />
                </StyledUser>
              ) : (
                <span
                  dangerouslySetInnerHTML={{
                    __html: stripAttributes(text) || ""
                  }}
                  data-cy="messageWithMention"
                />
              )}
            </Fragment>
          ),

          splitMessage
        )}
      </>
    );
  }

  return message && message.html ? (
    <span
      data-cy="messageWithoutMention"
      dangerouslySetInnerHTML={{ __html: message.html || "" }}
    />
  ) : (
    <span
      data-cy="messageWithoutMention"
      dangerouslySetInnerHTML={{ __html: textAsMarkdown || "" }}
    />
  );
};

export default MessageWithMentions;
