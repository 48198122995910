import i18n from "i18next";
import k from "src/i18n/keys";

import { useSelector, useDispatch } from "react-redux";
import React from "react";

import { toggleWorkflowDeletionModal } from "src/actions/modal";
import { deleteWorkflow } from "src/actions/workflows";
import { getWorkflowTitle } from "src/reducers";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button
} from "@chakra-ui/react";

const WorkflowDeletionModal = () => {
  const dispatch = useDispatch();

  const modalShown = useSelector(
    ({ app }) => app.modal.workflowDeletion.isOpen
  );
  const workflowId = useSelector(
    ({ app }) => app.modal.workflowDeletion.workflowId
  );
  const workflowTitle = useSelector(({ app }) =>
    getWorkflowTitle(app, workflowId)
  );

  const handleClose = () => dispatch(toggleWorkflowDeletionModal(false));

  const handleDelete = () => {
    dispatch(deleteWorkflow(workflowId));
    handleClose();
  };

  return (
    <Modal isCentered onClose={handleClose} isOpen={modalShown}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{i18n.t(k.DELETE_PROCESS1)}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{`${i18n.t(k.ARE_YOU_SURE_YOU_WANT_TO_DELET)} ${workflowTitle}${i18n.t(k.ALL_ASSOCIATED_CONVERSATIONS)}`}</ModalBody>
        <ModalFooter>
          <Button colorScheme="red" mr={3} onClick={handleDelete}>
            {i18n.t(k.DELETE)}
          </Button>
          <Button variant="ghost" onClick={handleClose}>
            {i18n.t(k.CANCEL)}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default WorkflowDeletionModal;
