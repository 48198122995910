// @flow

import React from "react";
import * as R from "ramda";

import { Item as StyledItem } from "./styles";
import Icons from "src/icons";
import Name from "src/containers/chatroom/Name";

type Props = {
  id: number,
  chatRooms?: Object,
  handleSelect: Function,
  selectNewRoomVersions?: Function,
  alwaysShowRevisionIcon: boolean
};

const FetchedItem = ({
  id,
  chatRooms = {},
  handleSelect,
  selectNewRoomVersions,
  alwaysShowRevisionIcon
}: Props) => {
  const room = !R.isEmpty(chatRooms) ? chatRooms.get(id) : null;

  if (!room) return null;
  return (
    <StyledItem onClick={() => handleSelect(id)}>
      <Name
        type={room?.type}
        name={room?.title}
        seqNo={room?.seqNo}
        canceled={room?.cancelled}
        processTitle={room?.processTitle}
        hideProcessTitle
        id={id}
      />

      {(alwaysShowRevisionIcon ||
        (room?.versionCount > 1 && room?.currentVersion)) &&
        selectNewRoomVersions && (
          <Icons
            type="currentRevision"
            width="14px"
            height="14px"
            onClick={e => {
              e.stopPropagation();
              selectNewRoomVersions(id);
            }}
          />
        )}
    </StyledItem>
  );
};
export default FetchedItem;
