// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React, { useState, useEffect, useCallback } from "react";
import * as R from "ramda";

import type { Set as ImmutableSet } from "immutable";

import { SearchInput } from "./styles";
import { ListItemMedium as StyledItem } from "src/styles/box";

import type { UnifizeUser } from "src/types";

type Props = {
  last: number,
  orgs: ImmutableSet<{ id: number, title: string }>,
  currentUser: UnifizeUser,
  setCurrentOrg: Function
};

const AllOrgs = ({ last, orgs, currentUser, setCurrentOrg }: Props) => {
  const [input, setInput] = useState("");
  const [focus, setFocus] = useState(1);
  const [filteredOrgs, setFilteredOrgs] = useState(
    orgs.filter(id => id !== last).toArray()
  );

  useEffect(() => {
    setFilteredOrgs(orgs.filter(org => org.id !== last).toArray());
  }, [last, orgs, setFilteredOrgs]);

  const switchOrg = (org: number) => {
    // $FlowFixMe
    (persistor || {}).purge().then(() => {
      if (orgs && (orgs.keySeq().size || 0) > 1) {
        setCurrentOrg(org, currentUser.uid, true);
      }
    });
  };

  const handleKeyDown = useCallback(
    (e: any) => {
      if (e.keyCode === 40) {
        // Down arrow
        setFocus(focus === filteredOrgs.length ? 1 : focus + 1);
      } else if (e.keyCode === 38) {
        // Up arrow
        setFocus(focus > 1 ? focus - 1 : filteredOrgs.length);
      }
      if (e.keyCode === 13) {
        const { id } = filteredOrgs[focus - 1] || {};
        if (id) {
          switchOrg(parseInt(id, 10));
        }
      }
    },
    [focus, setFocus, filteredOrgs, switchOrg]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown, false);
    return () => {
      document.removeEventListener("keydown", handleKeyDown, false);
    };
  }, [handleKeyDown]);

  const handleSearch = useCallback(
    (e: any) => {
      const { value } = e.target;
      setInput(value);
      setFocus(1);
      setFilteredOrgs(
        orgs
          .filter(
            org =>
              R.includes(R.toLower(value), R.toLower(org.title)) &&
              org.id !== last
          )
          .toArray()
      );
    },
    [setInput, last, orgs, setFilteredOrgs, setFocus]
  );

  const sortedOrgs = filteredOrgs.sort((a, b) =>
    a.title.localeCompare(b.title)
  );

  return (
    <ul>
      <SearchInput
        type="text"
        placeholder={i18n.t(k.SEARCH_ORG)}
        value={input}
        onChange={handleSearch}
        autoFocus
      />

      {sortedOrgs.map((org, index) => {
        const { title, id } = org;
        return (
          <div
            tabIndex="0"
            role="button"
            key={id}
            onClick={() => switchOrg(parseInt(id, 10))}
            onKeyPress={() => switchOrg(parseInt(id, 10))}
          >
            <StyledItem focus={focus === index + 1}>{title}</StyledItem>
          </div>
        );
      })}

      {sortedOrgs.length === 0 ? (
        <div>
          <StyledItem>{i18n.t(k.NO_ORGS_WITH_GIVEN_NAME)}</StyledItem>
        </div>
      ) : null}
    </ul>
  );
};

export default AllOrgs;
