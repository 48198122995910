// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React, { useRef } from "react";
import { Box, Button, Text } from "@chakra-ui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";

import Dropdown from "src/components/Dropdown";
import useBoolean from "src/hooks/useBoolean";
import * as styles from "../styles";
import RateDropdownRow from "./RateDropdownRow";
import { setChartSeries, keyboardHandler } from "src/utils/charts.js";

import type { DownshiftProps } from "downshift";

type Props = {
  chartDetails: Object,
  rateSeries: number | string,
  seriesId: string,
  setChartDetails: Function
};

const RateDropdown = ({
  chartDetails,
  rateSeries,
  seriesId,
  setChartDetails
}: Props) => {
  const {
    value: isOpen,
    setFalse: closeDropdown,
    toggleBoolean: toggleDropdown
  } = useBoolean();

  const rateDropdownList = chartDetails.series.filter(
    item => item.plotType !== "rate"
  );

  const selectedSeriesId = rateSeries;

  const selectedSeries = rateDropdownList.find(series => {
    // Existing series
    if (series.seriesId) {
      return series.seriesId === selectedSeriesId;
    } else {
      // New series
      return series.tempId === selectedSeriesId;
    }
  });

  const downshiftRef = useRef<DownshiftProps>(null);

  const handleSelect = ({ id }: { id: number | string }) => {
    setChartSeries({
      setChartDetails,
      seriesId,
      key: "rateSeries",
      value: id
    });

    closeDropdown();
  };

  const onKeyboardSelect = selectedIndex => {
    handleSelect({
      id:
        rateDropdownList[selectedIndex].seriesId ||
        rateDropdownList[selectedIndex].tempId
    });
  };

  return (
    <Box>
      <Button
        onClick={toggleDropdown}
        onKeyDown={e =>
          keyboardHandler({
            event: e,
            ref: downshiftRef,
            onSelect: onKeyboardSelect,
            onClose: closeDropdown
          })
        }
        sx={isOpen ? styles.DropdownExpanded : styles.DropdownButton}
        rightIcon={
          isOpen ? (
            <ChevronUpIcon w={6} h={6} />
          ) : (
            <ChevronDownIcon w={6} h={6} />
          )
        }
      >
        <Text>
          {(selectedSeries?.title ||
            (selectedSeries?.seqNo &&
              `${i18n.t(k.SERIES1)}${selectedSeries?.seqNo}`)) ??
            "Select"}
        </Text>
      </Button>

      {isOpen && (
        <Dropdown
          onItemClick={handleSelect}
          isOpen={isOpen}
          onOuterClick={closeDropdown}
          ref={downshiftRef}
        >
          {({
            onItemClick,
            getItemProps,
            highlightedIndex,
            scrollIntoView
          }) => {
            return rateDropdownList.map((list, index) => (
              <RateDropdownRow
                item={rateDropdownList[index]}
                key={list.tempId}
                elId={index}
                index={index}
                highlightedIndex={highlightedIndex}
                scrollIntoView={scrollIntoView}
                {...getItemProps({
                  item: rateDropdownList[index],
                  index,
                  id: rateDropdownList[index].tempId,
                  onItemClick: onItemClick
                })}
              />
            ));
          }}
        </Dropdown>
      )}
    </Box>
  );
};

export default RateDropdown;
