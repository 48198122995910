// @flow

import i18n from "i18next";
import k from "src/i18n/keys";
import React, { useCallback } from "react";

import { EmptyMessage as StyledEmptyMessage } from "./styles";
import { Input } from "src/styles/input";

type Props = {
  title: string,
  subTitle: string,
  handleChange: Function
};

const EmptyMessage = ({ title, subTitle, handleChange }: Props) => {
  const handleTitle = useCallback(
    (event: any) => {
      handleChange({
        emptyMessage: {
          title: event.target.value,
          subTitle
        }
      });
    },
    [subTitle, handleChange]
  );

  const handleSubTitle = useCallback(
    (event: any) => {
      handleChange({
        emptyMessage: {
          title,
          subTitle: event.target.value
        }
      });
    },
    [title, handleChange]
  );

  return (
    <StyledEmptyMessage>
      <Input
        placeholder={i18n.t(k.TITLE)}
        value={title}
        onChange={handleTitle}
      />
      <Input
        placeholder={i18n.t(k.TITLE)}
        value={subTitle}
        onChange={handleSubTitle}
      />
    </StyledEmptyMessage>
  );
};

export default EmptyMessage;
