// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";
import { useSelector } from "react-redux";

import { EditIconWrapper } from "../styles";
import {
  CaretUp,
  Count,
  FormWrapper,
  Address,
  MultiContainer,
  StyledMultiForm as StyledMultipleForm,
  MultiTable,
  MultiConversationRow
} from "./styles";
import Icon from "src/icons";
import {
  getEmbeddedFormColumns,
  getIfFormFieldsVisible,
  getFieldWidth
} from "src/reducers";
import ChecklistItem from "../ChecklistItem";
import useRowExpansion from "src/hooks/useRowExpansion";
import type { ColumnId, WorkflowInstances } from "src/types";

type Props = {
  selected: boolean,
  value: any,
  openChecklistEditModal: Function,
  fieldId: number,
  roomId: string,
  rowIndex: number,
  hideEdit?: boolean,
  autoNo: string,
  columnId: ColumnId,
  type: string,
  process: WorkflowInstances,
  settings: string,
  embeddedIndex?: number
};

const MultipleForm = ({
  value,
  selected,
  openChecklistEditModal,
  fieldId,
  roomId,
  rowIndex,
  hideEdit,
  autoNo,
  columnId,
  process,
  settings,
  embeddedIndex
}: Props) => {
  const { toggleExpand, isRowExpanded } = useRowExpansion(
    columnId,
    autoNo,
    roomId
  );
  const fieldWidth = useSelector(({ app }) => getFieldWidth(app, columnId));

  const expandedForms = useSelector(({ app }) =>
    getIfFormFieldsVisible(app, fieldId)
  );
  const embeddedFormColumns = useSelector(({ app }) =>
    getEmbeddedFormColumns(app)
  );

  return (
    <MultiContainer selected={selected} borderRequired={columnId.includes("-")}>
      <MultiTable>
        <tbody>
          {Array.from(
            { length: isRowExpanded ? Math.max(value.length, 1) : 1 },
            (_, index) => {
              const fieldValue = value[index] || "-";
              return (
                <MultiConversationRow
                  key={index}
                  expandedField={expandedForms ? true : false}
                >
                  <StyledMultipleForm
                    selected={selected}
                    width={fieldWidth}
                    expanded={expandedForms ? true : false}
                  >
                    <FormWrapper onClick={toggleExpand}>
                      <span>
                        {fieldValue ? fieldValue.templateTitle : i18n.t(k._5)}
                      </span>
                      <Address>
                        {fieldValue ? fieldValue.address : i18n.t(k._5)}
                      </Address>
                    </FormWrapper>{" "}
                    {!isRowExpanded && value.length > 1 && (
                      <Count onClick={toggleExpand}>
                        {i18n.t(k._13)}
                        {value.length - 1}
                      </Count>
                    )}
                    {isRowExpanded && index === 0 && (
                      <CaretUp onClick={toggleExpand}>
                        <Icon type="caretUp" />
                      </CaretUp>
                    )}
                    {!hideEdit && (
                      <EditIconWrapper
                        onClick={e =>
                          openChecklistEditModal(
                            e,
                            rowIndex,
                            fieldId,
                            roomId,
                            value,
                            columnId
                          )
                        }
                      >
                        <Icon type="editPencil" />
                      </EditIconWrapper>
                    )}
                  </StyledMultipleForm>
                  {/* Embedded form fields */}
                  {embeddedFormColumns[columnId] &&
                    embeddedFormColumns[columnId].map((column, columnIndex) => {
                      const embeddedFormValue =
                        process && process[column.id]
                          ? process[column.id][index]
                          : [];
                      return (
                        <ChecklistItem
                          selected={selected}
                          key={`${columnIndex}${i18n.t(k._5)}${index}`}
                          columnId={`${column.id}`}
                          fieldId={parseInt(column.fieldId)}
                          value={embeddedFormValue}
                          settings={settings}
                          index={index}
                          isPrivate={false}
                          roomId={roomId}
                          process={process}
                          allRevisionsShown={true}
                          shouldRenderEmbeddedFields={true}
                          embeddedIndex={embeddedIndex}
                        />
                      );
                    })}
                </MultiConversationRow>
              );
            }
          )}
        </tbody>
      </MultiTable>
    </MultiContainer>
  );
};

export default MultipleForm;
