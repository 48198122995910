// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  HStack,
  Text
} from "@chakra-ui/react";
import { updateCurrentVersionConfirmationModal as styles } from "./styles";

type Props = {
  roomTitle: string,
  open: boolean,
  onClose: Function,
  onSuccess: Function
};

const UpdateCurrentVersionConfirmationModal = ({
  roomTitle,
  open,
  onClose,
  onSuccess
}: Props) => {
  return (
    <Modal isOpen={open} onClose={onClose} isCentered>
      <ModalOverlay />

      <ModalContent>
        <ModalHeader {...styles.header}>
          {i18n.t(k.MAKE_AS_CURRENT_REVISION)}
        </ModalHeader>

        <ModalBody>
          <Text {...styles.bodyText}>
            {i18n.t(k.ARE_YOU_SURE_YOU_WANT_TO_MAKE)} {roomTitle}{" "}
            {i18n.t(k.AS_THE_CURRENT_REVISION)}
          </Text>
        </ModalBody>

        <ModalFooter>
          <HStack {...styles.actionBar}>
            <Button variant="uSecondary" onClick={onClose}>
              {i18n.t(k.CANCEL)}
            </Button>
            <Button variant="uBlueFilled" onClick={onSuccess}>
              {i18n.t(k.CONFIRM)}
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default UpdateCurrentVersionConfirmationModal;
