// @flow
import i18n from "i18next";
import k from "src/i18n/keys"; // // @flow

import { connect, useSelector } from "react-redux";
import React from "react";

import {
  OrgSettingsHeader,
  OrgSettingItem,
  List as StyledList,
  OrgItem as StyledItem,
  OrgSettingsList
} from "src/styles/splitPane";

import { setOrgSettings } from "src/actions/orgSettings";
import type { AppState } from "src/types";
import {
  getUserRole,
  getCurrentLanguage,
  getUserPermissions,
  getCurrentOrgSettings
} from "src/reducers";
import { languagePermission } from "src/constants";

type Props = {
  current: ?string,
  _setOrgSettings: Function,
  isSSOViewAllowed: boolean,
  userRole: string
};

const listItems = [
  {
    id: "home-screen",
    title: i18n.t(k.HOME_SCREEN1),
    restricted: false
  },
  {
    id: "org-details",
    title: i18n.t(k.ORG_DETAILS1),
    restricted: false
  },
  {
    id: "role-management",
    title: i18n.t(k.ROLE_MANAGEMENT1),
    restricted: false
  },
  { id: "integrations", title: i18n.t(k.APPS), restricted: true },
  { id: "sso", title: "SSO" },
  { id: "translation-editor", title: "Translations" }
];

const List = ({
  current,
  _setOrgSettings,
  userRole,
  isSSOViewAllowed = false
}: Props) => {
  const userPermissions = useSelector(({ app }) => getUserPermissions(app));

  // TODO: Ideally this component shouldn't recieve all permissions.
  const { resourcePermissions } = userPermissions;
  const showTranslation =
    resourcePermissions.includes(languagePermission.edit) ||
    resourcePermissions.includes(languagePermission.view);

  return (
    <StyledList>
      <OrgSettingsHeader>{i18n.t(k.ORG_SETTINGS)}</OrgSettingsHeader>

      <OrgSettingsList>
        {listItems.map(item => {
          if (item.restricted && userRole !== "admin") return;
          if (item.id === "sso" && !isSSOViewAllowed) return;
          if (item.id === "translation-editor" && !showTranslation) return;

          return (
            <StyledItem
              key={item.id}
              active={current === item.id}
              onClick={() => _setOrgSettings(item.id)}
            >
              <OrgSettingItem>{item.title}</OrgSettingItem>
            </StyledItem>
          );
        })}
      </OrgSettingsList>
    </StyledList>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  current: getCurrentOrgSettings(app),
  userRole: getUserRole(app),
  language: getCurrentLanguage(app)
});

export default connect(mapStateToProps, {
  _setOrgSettings: setOrgSettings
})(List);
