// @flow

import React from "react";
import Link from "redux-first-router-link";
import { connect } from "react-redux";

import { OptionContainer, Option } from "./styles";
import Tooltip from "./NavBar/Tooltip/styles";
import Icons from "src/icons";

import type { AppState } from "src/types";

type Props = {
  _showView: Function,
  linkType: string,
  linkprops?: Object,
  icon: string,
  name: string,
  view: string,
  type: string
};

const Setting = ({
  linkprops = {},
  linkType,
  icon,
  name,
  view,
  type
}: Props) => {
  const selected = view === type;
  const iconType = selected ? `${icon}Dark` : `${icon}`;

  return (
    <Tooltip selected={selected}>
      <OptionContainer>
        <Link to={{ type: linkType, ...(linkprops || {}) }}>
          <Option selected={selected}>
            <Icons type={iconType} />
          </Option>
        </Link>
      </OptionContainer>
      <span>{name}</span>
    </Tooltip>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  view: app.view
});

export default connect(mapStateToProps)(Setting);
