// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React, { useRef } from "react";

import { Empty as StyledEmpty } from "./styles";
import Dropdown from "./Dropdown";
import EmptyMessage from "./EmptyMessage";
import useBoolean from "src/hooks/useBoolean";

type Props = {
  emptyMessage: ?Object,
  value: string,
  handleSelect: Function
};

const EmptyDropdown = ({ value, emptyMessage, handleSelect }: Props) => {
  const outerRef = useRef(null);
  const { value: dropdown, toggleBoolean: toggleDropdown } = useBoolean();
  const title = (emptyMessage || {}).title || "";
  const subTitle = (emptyMessage || {}).subTitle || "";

  return (
    <>
      <StyledEmpty type="button" ref={outerRef} onClick={toggleDropdown}>
        {value ? i18n.t(k.SHOW_A_MESSAGE) : i18n.t(k.DON_T_SHOW_TILE)}
        {dropdown && (
          <Dropdown
            outerRef={outerRef}
            handleClose={toggleDropdown}
            handleSelect={handleSelect}
          />
        )}
      </StyledEmpty>
      {value && (
        <EmptyMessage
          handleChange={handleSelect}
          title={title}
          subTitle={subTitle}
        />
      )}
    </>
  );
};

export default EmptyDropdown;
