// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";

import {
  Text,
  Button,
  Modal,
  ModalContent,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  HStack
} from "@chakra-ui/react";

type Props = {
  open: boolean,
  onClose: Function,
  onSuccess: Function
};

export default function ConfirmModal({ open, onClose, onSuccess }: Props) {
  return (
    <Modal isOpen={open} onClose={onClose} isCentered>
      <ModalOverlay />

      <ModalContent>
        <ModalHeader fontWeight={700} fontSize="lg">
          {i18n.t(k.CREATE_NEW_REVISION)}
        </ModalHeader>

        <ModalBody>
          <Text my={0}>{i18n.t(k.ARE_YOU_SURE_YOU_WANT_TO_CREAT)}</Text>
        </ModalBody>

        <ModalFooter>
          <HStack spacing={4}>
            <Button variant="uSecondary" onClick={onClose}>
              {i18n.t(k.CANCEL)}
            </Button>
            <Button variant="uBlueFilled" onClick={onSuccess}>
              {i18n.t(k.CONFIRM)}
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
