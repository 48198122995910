// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import { connect } from "react-redux";
import React from "react";
import moment from "moment";

import type { AppState, UserAnalyticsFilter } from "src/types";

type Props = {
  filter: UserAnalyticsFilter
};

const CreatedDate = ({ filter }: Props) => {
  const { createdAt } = filter;

  const lastWeek = `${moment()
    .subtract(7, "days")
    .startOf("day")
    .format("YYYY-MM-DD")}::${moment().startOf("day").format("YYYY-MM-DD")}`;

  const lastMonth = `${moment()
    .startOf("month")
    .format("YYYY-MM-DD")}::${moment().endOf("month").format("YYYY-MM-DD")}`;

  if (createdAt === lastWeek) {
    return <>{i18n.t(k.LAST_DAYS)}</>;
  }

  if (createdAt === lastMonth) {
    return <>{i18n.t(k.LAST_MONTH)}</>;
  }

  const ranges = (createdAt || "").split(":");

  if (ranges.length >= 2) {
    return (
      <>
        {moment(ranges[0]).format("MMM DD, YYYY")} {i18n.t(k._5)}
        {moment(ranges[ranges.length - 1]).format("MMM DD, YYYY")}
      </>
    );
  }

  return <>{i18n.t(k.CHOOSE_DATE)}</>;
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  filter: app.userAnalytics.filter
});

export default connect(mapStateToProps)(CreatedDate);
