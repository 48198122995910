// @flow

import { connect } from "react-redux";
import React from "react";

import HomeScreen from "./HomeScreen";
import OrgDetails from "./OrgDetails";
import RoleManagement from "./RoleManagement";
import SSOConfig from "./SSO";
import Integrations from "./Integrations";
import Translations from "./Translations";
import { LanguageProvider } from "src/contexts/LanguageContext";

import type { AppState } from "src/types";
import { Box } from "@chakra-ui/react";

type Props = {
  current: ?string,
  isSSOViewAllowed: boolean
};

const CurrentSelection = ({ current, isSSOViewAllowed = false }: Props) => {
  switch (current) {
    case "home-screen":
      return <HomeScreen />;
    case "org-details":
      return <OrgDetails />;
    case "role-management":
      return (
        <Box overflow="hidden" height="100%">
          <RoleManagement />
        </Box>
      );

    case "sso":
      return isSSOViewAllowed ? <SSOConfig /> : <></>;
    case "integrations":
      return <Integrations />;
    case "translation-editor":
      return (
        <LanguageProvider>
          <Translations />
        </LanguageProvider>
      );
    default:
      return <></>;
  }
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  current: app.orgSettings.current
});

export default connect(mapStateToProps)(CurrentSelection);
