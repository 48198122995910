// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";
import * as orgCloneApi from "src/api/orgClone";

import { Button, HStack, Text, VStack } from "@chakra-ui/react";
import Icons from "src/icons";
import * as styles from "./VerificationEmailNotice.styles";

type Props = {
  email: string,
  orgId: number
};

export default function VerificationEmailNotice({ email, orgId }: Props) {
  return (
    <VStack sx={styles.container}>
      <Icons type="verificationSent" width="60" height="60" />

      <Text textStyle="displayXl">
        {i18n.t(k.PLEASE_VERIFY_YOUR_EMAIL_ADDRE)}
      </Text>

      <Text textStyle="displaySmall">
        {i18n.t(k.CLICK_ON_THE_LINK_WE_SENT_TO)} {email}{" "}
        {i18n.t(k.TO_CREATE_YOUR_ORG)}
      </Text>

      <HStack spacing={0}>
        <Text textStyle="displaySmall">
          {i18n.t(k.DIDN_T_RECEIVE_AN_EMAIL)}
        </Text>

        <Button
          variant="transparentLink"
          textStyle="displaySmall"
          onClick={() => orgCloneApi.sendVerificationEmail({ email, orgId })}
        >
          {i18n.t(k.RESEND_EMAIL)}
        </Button>
      </HStack>
    </VStack>
  );
}
