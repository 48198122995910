// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import { connect, useSelector, useDispatch } from "react-redux";
import React, { useRef, useEffect, useCallback } from "react";
import * as R from "ramda";
import { useDebouncedCallback } from "use-debounce";

import {
  Filter as StyledFilter,
  ListItem,
  SubHeading,
  User as StyledUser,
  Separator
} from "./styles";
import WebWorker from "src/workers/WebWorker";
import worker from "src/workers/app.worker";
import { searchUsers } from "src/actions";
import { filterEmbeddedFields } from "src/actions/workflows";
import {
  getSearchUniqueValues,
  getChecklistFieldsById,
  getAllWorkflows,
  getAllRecords,
  getCurrentUser
} from "src/reducers";
import Checkbox from "src/components/Checkbox";
import AllRecordsFilter from "./AllRecordsFilter";
import Name from "src/containers/user/ImageLabel";
import { capitalize } from "src/utils";
import { Input } from "src/styles/input";
import BlanksNonBlanks from "./BlanksNonBlanks";

import type { AppState, UID, UnifizeUser } from "src/types";
import { ME_FILTER } from "src/constants/users";
import { getAllRecordsLabel } from "src/utils/filters";

type Props = {
  column: string,
  values: Array<UID>,
  parentRef: any,
  handleClose: Function,
  _searchUsers: Function,
  setFilter: Function,
  toggleSortBy: Function,
  filter: Array<string>,
  currentUser: UnifizeUser
};

const User = ({
  parentRef,
  values,
  column,
  handleClose,
  _searchUsers,
  setFilter,
  toggleSortBy,
  filter,
  currentUser
}: Props) => {
  const searchInputRef = useRef();
  const [debouncedSearchUsers] = useDebouncedCallback(_searchUsers, 800);

  const dispatch = useDispatch();
  const workflow = useSelector(({ app }) => getAllWorkflows(app));
  const allRecords = useSelector(({ app }) => getAllRecords(app));
  const fieldsById = useSelector(({ app }) => getChecklistFieldsById(app));
  const filters = workflow.instanceFilter;
  const chatroomAttributes = ["owner", "dueDate", "status"];
  const instancesById = workflow.instancesById;
  const updatedInstances = { ...instancesById };

  const allRecordsColumnId = column.includes("-")
    ? column.split("-")[0]
    : column;

  let workerData = {
    workflow,
    fieldsById: fieldsById.toJS(),
    instancesById,
    filters,
    chatroomAttributes,
    updatedInstances,
    allRecords,
    currentUserUid: currentUser?.uid
  };

  useEffect(() => {
    _searchUsers("", {
      searchType: `unique${capitalize(column)}`,
      columnId: column,
      removeDisabledUsers: false
    });
    return () => {
      _searchUsers("", {
        searchType: `unique${capitalize(column)}`,
        columnId: column,
        removeDisabledUsers: false
      });
    };
  }, []);

  const preventDefault = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleSearch = useCallback(
    (event: any) => {
      preventDefault(event);
      debouncedSearchUsers(event.target.value, {
        searchType: `unique${capitalize(column)}`,
        columnId: column,
        removeDisabledUsers: false
      });
    },
    [debouncedSearchUsers, searchInputRef.current?.value]
  );

  const closeModal =
    ((event: any) => {
      if (event.keyCode === 13) {
        handleClose();
      }
    },
    [handleClose]);

  const clickOutside = useCallback(
    (event: any) => {
      if (parentRef && parentRef.current) {
        if (!parentRef.current.contains(event.target)) {
          handleClose();
        }
      }
    },
    [parentRef, handleClose]
  );

  useEffect(() => {
    // $FlowFixMe
    document.addEventListener("keydown", closeModal, false);
    document.addEventListener("click", clickOutside, false);
    return () => {
      // $FlowFixMe
      document.removeEventListener("keydown", closeModal, false);
      document.removeEventListener("click", clickOutside, false);
    };
  }, []);

  const handleSort = (ascending: boolean) => {
    if (ascending) {
      toggleSortBy(false, true);
    } else {
      toggleSortBy(true, true);
    }
    handleClose();
  };

  const handleSelect = (
    event: Event,
    item: string | number,
    isMeFilter?: boolean = false
  ) => {
    event.preventDefault();
    event.stopPropagation();

    const webWorker = new WebWorker(worker);

    if (
      isMeFilter
        ? R.any(R.includes("me-"), filter || [])
        : R.includes(item, filter || [])
    ) {
      const appliedFilter = isMeFilter ? R.includes("me-") : R.equals(item);
      // $FlowFixMe- Flow type infer error, type of event listener not clear
      webWorker.addEventListener("message", event => {
        dispatch(filterEmbeddedFields({ instances: event.data }));
      });
      workerData = {
        ...workerData,
        payload: {
          ...filters,
          [column]: R.reject(appliedFilter, filter)
        }
      };

      webWorker.postMessage(workerData);

      setFilter(column, R.reject(appliedFilter, filter));
    } else {
      // $FlowFixMe- Flow type infer error, type of event listener not clear
      webWorker.addEventListener("message", event => {
        dispatch(filterEmbeddedFields({ instances: event.data }));
      });

      workerData = {
        ...workerData,
        payload: {
          ...filters,
          [column]: [...(filter || []), isMeFilter ? ME_FILTER : item]
        }
      };

      webWorker.postMessage(workerData);

      setFilter(column, [...(filter || []), isMeFilter ? ME_FILTER : item]);
    }
  };

  // Decide whether or not to show "Me" filter while searching
  const showMeFilter = useCallback(() => {
    const searchTerm = searchInputRef?.current?.value || "";

    if (
      searchTerm.toLowerCase().includes("Me".toLowerCase()) ||
      (currentUser?.displayName || "")
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      (currentUser?.email || "")
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      R.isNil(searchTerm) ||
      R.isEmpty(searchTerm)
    ) {
      return true;
    }

    return false;
  }, [searchInputRef?.current?.value]);

  return (
    <StyledFilter onClick={preventDefault} fullWidth={true}>
      <SubHeading>{i18n.t(k.SORT_BY2)}</SubHeading>
      <ul>
        <ListItem tabIndex="0" role="button" onClick={() => handleSort(true)}>
          {i18n.t(k.ASCENDING)}
        </ListItem>
        <ListItem tabIndex="0" role="button" onClick={() => handleSort(false)}>
          {i18n.t(k.DESCENDING)}
        </ListItem>
      </ul>
      <Separator />
      <SubHeading>{i18n.t(k.FILTER)}</SubHeading>
      <BlanksNonBlanks handleSelect={handleSelect} filter={filter} />
      <Separator />
      {column.includes("-") && (
        <AllRecordsFilter
          label={`${i18n.t(k.ALL)} ${getAllRecordsLabel(
            allRecordsColumnId,
            fieldsById
          )} ${i18n.t(k.RECORDS)}`}
          isAllRecords={allRecords[allRecordsColumnId]}
          columnId={allRecordsColumnId}
          workerData={workerData}
        />
      )}
      <Input
        type="text"
        ref={searchInputRef}
        onChange={handleSearch}
        placeholder={i18n.t(k.SEARCH)}
        autoFocus
      />

      <ul>
        {showMeFilter() && (
          <StyledUser
            tabIndex="0"
            role="button"
            onClick={event => handleSelect(event, currentUser?.uid, true)}
          >
            <Checkbox
              id={`${i18n.t(k.USERFILTER)}${currentUser?.uid}`}
              checked={R.any(R.includes("me-"), filter || [])}
              handleChange={event =>
                handleSelect(event, currentUser?.uid, true)
              }
            />

            <Name uid={currentUser?.uid} isCurrentUser={true} />
          </StyledUser>
        )}

        {values.map(value => (
          <StyledUser
            tabIndex="0"
            role="button"
            key={value}
            onClick={event => handleSelect(event, value)}
          >
            <Checkbox
              id={`${i18n.t(k.USERFILTER)}${value}`}
              checked={R.includes(value, filter || [])}
              handleChange={event => handleSelect(event, value)}
            />

            <Name uid={value} />
          </StyledUser>
        ))}
      </ul>
    </StyledFilter>
  );
};

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  currentUser: getCurrentUser(app),
  values: getSearchUniqueValues(app, props.column)
});

export default connect(mapStateToProps, {
  _searchUsers: searchUsers
})(User);
