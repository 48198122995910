// @flow

import React, { memo } from "react";
import { Flex, Avatar, Text, ListItem } from "@chakra-ui/react";
import { useSelector } from "react-redux";

import { getUser } from "src/reducers";
import * as styles from "./styles";

import type { UID } from "src/types";
import type { SelectOrUnselectParticipant } from "src/components/chatroom/Members/AddUser";

type Props = {
  id: UID,
  selectParticipant: SelectOrUnselectParticipant,
  activeParticipant: boolean
};

const NewUser = ({ id, selectParticipant, activeParticipant }: Props) => {
  const user = useSelector(({ app }) => getUser(app, id));
  const { displayName, photoUrl, email } = user;

  return (
    <ListItem
      onClick={() => selectParticipant({ type: "user", id })}
      sx={
        activeParticipant
          ? styles.ActiveParticipant
          : styles.UsersAndGroupsWrapper
      }
      data-cy="userItem"
    >
      <Avatar
        sx={styles.UserAvatar}
        size="sm"
        name={displayName}
        src={photoUrl}
      />

      <Flex sx={styles.FlexColumn}>
        <Text
          isTruncated
          sx={styles.UserAndGroupName}
          data-cy="participantDisplayName"
        >
          {displayName}
        </Text>
        <Text isTruncated sx={styles.LightGreyText}>
          {email}
        </Text>
      </Flex>
    </ListItem>
  );
};

export default memo<Props>(NewUser);
