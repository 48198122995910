// @flow

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setWorkflowBuiderAttributes } from "src/actions/workflows";
import { Input } from "@chakra-ui/react";
import * as styles from "./styles";

type Props = {
  processTitle: string
};

const Title = ({ processTitle }: Props) => {
  const { title } = useSelector(store => store.app.workflow.builderDialog);
  const dispatch = useDispatch();

  const handleChange = e => {
    const value = e.target.value;
    dispatch(
      setWorkflowBuiderAttributes({
        title: value,
        numberingScheme: `${value.trim()} ###`
      })
    );
  };

  const handleEscape = e => {
    if (e.key === "Escape" || e.keyCode === 27) {
      handleTrimAndUpdate();
    }
  };

  const handleTrimAndUpdate = () => {
    const trimmedTitle = processTitle.trim();
    if (processTitle !== trimmedTitle) {
      dispatch(
        setWorkflowBuiderAttributes({
          title: trimmedTitle,
          numberingScheme: `${trimmedTitle} ###`
        })
      );
    }
  };

  return (
    <Input
      data-cy="processTitle"
      onChange={handleChange}
      value={processTitle}
      onKeyDown={handleEscape}
      onBlur={handleTrimAndUpdate}
      autoFocus={!title}
      sx={styles.processTitleInput}
      placeholder="Untitled"
      size="lg"
      variant="unstyled"
    />
  );
};

export default Title;
