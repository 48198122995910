// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import { connect } from "react-redux";
import React from "react";
import * as R from "ramda";

import { Grid, GridItem, Portal } from "@chakra-ui/react";
import Section from "./Section";
import {
  Homescreen as StyledHomescreen,
  Header,
  SectionContainer,
  Empty as StyledEmpty
} from "./styles";
import { Overlay } from "src/styles/splitPane";
import {
  getOrgName,
  getCurrentUserId,
  getUser,
  getCurrentLanguage
} from "src/reducers";
import { LoadingContainer as StyledLoadingContainer } from "src/components/Dashboard/styles";
import emptyHome from "./emptyHome.svg";
import {
  Dot1,
  Dot2,
  Dot3,
  Loader as StyledLoader
} from "src/components/ButtonLoader/styles";

import type { AppState, HomescreenSection, UnifizeUser } from "src/types";

type Props = {
  user: UnifizeUser,
  loading: ?boolean,
  orgTitle: string,
  sections: Array<HomescreenSection>,
  language: string
};

const formatSections = sections => {
  let centerSections = [];
  let rightSections = [];

  sections.forEach(section => {
    if (section?.settings?.position === "right") {
      rightSections.push(section);
    } else {
      centerSections.push(section);
    }
  });

  centerSections = R.sortBy(R.prop("seqNo"), centerSections);
  rightSections = R.sortBy(R.prop("seqNo"), rightSections);

  return { centerSections, rightSections };
};

const Homescreen = ({ loading, orgTitle, sections, user, language }: Props) => {
  const { centerSections, rightSections } = formatSections(sections);

  return (
    <Portal>
      <Overlay>
        <StyledHomescreen key={language}>
          <SectionContainer>
            <h2>{i18n.t(k.HOME)}</h2>
            <Header>
              {i18n.t(k.WELCOME_BACK)}{" "}
              {user.loading
                ? i18n.t(k.LOADING_USER)
                : user.displayName || user.email}
            </Header>

            {loading !== false ? (
              <StyledLoadingContainer>
                <StyledLoader>
                  <Dot1 />
                  <Dot2 />
                  <Dot3 />
                </StyledLoader>
              </StyledLoadingContainer>
            ) : (
              <>
                {sections.length === 0 ? (
                  <StyledEmpty>
                    <p>{i18n.t(k.YOUR_HOME_SCREEN_HAS_NOT_BEEN)}</p>
                    <img src={emptyHome} />
                    <h2>{i18n.t(k.UNIFIZE_HOME_NOT_SET_UP)}</h2>
                    <p>{i18n.t(k.CONTACT_YOUR_ORG_ADMIN_TO_SET)}</p>
                  </StyledEmpty>
                ) : (
                  <>
                    <p>
                      {i18n.t(k.HERE_S_WHAT_S_GOING_ON_AT)} {orgTitle}
                    </p>
                    <Grid templateColumns="repeat(10, 1fr)" gap={4}>
                      <GridItem colSpan={6}>
                        {centerSections.map(section => (
                          <Section key={section.id} section={section} />
                        ))}
                      </GridItem>

                      <GridItem colSpan={4}>
                        {rightSections.map(section => (
                          <Section key={section.id} section={section} />
                        ))}
                      </GridItem>
                    </Grid>
                  </>
                )}
              </>
            )}
          </SectionContainer>
        </StyledHomescreen>
      </Overlay>
    </Portal>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  user: getUser(app, getCurrentUserId(app)),
  orgTitle: getOrgName(app, parseInt(app.orgs.last, 10)),
  sections: app.homescreen.metaData,
  loading: app.homescreen.loading,
  language: getCurrentLanguage(app)
});

export default connect(mapStateToProps)(Homescreen);
