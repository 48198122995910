// @flow
import styled from "styled-components";

import * as colors from "src/styles/constants/colors";

export const RichTextField = styled.div`
  display: flex;
  width: 100%;
  overflow: hidden;
  padding: 0;
  margin: 0;
  align-items: center;
  & > div {
    width: 100%;
  }
  > span {
    opacity: 0;
  }
  :hover {
    > span {
      opacity: 1;
    }
  }
  .tiptap a {
    cursor: pointer;
  }
  .tiptap a:not(:has(u)) {
    text-decoration: none;
  }
  .tiptap a:not(:has(u)):hover {
    text-decoration: underline;
  }
  .tiptap a:hover u {
    text-decoration: none;
  }
  .tiptap mark:not([data-color="#ffffff"]) {
    padding: 0 2px;
  }
`;

export const Menu = styled.div`
  display: flex;
  align-items: center;
  gap: 11px;
  background-color: ${colors.sarahGrey100};
  color: ${colors.grey39};
  padding: 0.275rem;
  width: 100%;
  height: 100%;
`;

export const MenuButton = styled.button`
  display: inline-flex;
  background-color: transparent;
  align-items: center;
  padding: 2px 3px;
  svg {
    width: 100%;
    height: 100%;
  }
  border: solid 1px
    ${props => (props.active ? `${colors.blue200}` : "transparent")};
  svg path {
    fill: ${props => (props.active ? colors.active : colors.grey39)};
  }
  &:active,
  &:hover svg path {
    fill: ${colors.active};
  }
  overflow: hidden;
`;

export const ColorPickerButton = styled(MenuButton)`
  position: relative;
  flex-direction: column;
  justify-content: center;
  gap: 0;
  color: ${props => (props.active ? colors.active : colors.grey39)};
  &:hover {
    color: ${colors.active};
  }
`;

export const ColorPicker = styled.input`
  position: absolute;
  cursor: pointer;
  opacity: 0;
  width: 0;
  height: 0;
`;

export const ColorBar = styled.span`
  width: 12px;
  height: 2px;
  background-color: ${props => props.color ?? `${colors.primary}`};
`;

export const URLModal = styled.div`
  position: absolute;
  top: 1rem;
  left: -6rem;
  z-index: 1000;
  min-width: 14rem;
  display: flex;
  gap: 0.5rem;
  padding: 4px;
  background-color: ${colors.brightLight};
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 2px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08);
`;

export const URLInput = styled.input`
  display: block;
  width: 100%;
  font-size: 12px;
  color: ${colors.primary};
  padding: 0.25rem 0.5rem;
  ::placeholder {
    color: ${colors.secondary};
  }
  border: 1px solid ${colors.grey40};
`;

export const URLSaveButton = styled.button`
  display: flex;
  align-items: center;
  padding: 0.25rem 0.5rem;
  outline: none;
  border-radius: 2px;
  font-size: 12px;
  color: ${colors.brightLight};
  background-color: ${colors.active};
`;

export const TextIcon = styled.span`
  font-size: 1em;
  line-height: 1;
`;
