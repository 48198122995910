// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";
import { VStack, Text } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { getCreator, getCreatedDate } from "src/reducers";
import moment from "moment";
import MediumImageLabel from "src/containers/user/ImageLabel/Medium";

import * as styles from "./styles";
import * as colors from "src/styles/constants/colors";
import type { RoomId } from "src/types";

type Props = {
  roomId: RoomId
};

const ConversationCreator = ({ roomId }: Props) => {
  const creator = useSelector(({ app }) => getCreator(app, `${roomId}`));
  const createdAt = useSelector(({ app }) => getCreatedDate(app, `${roomId}`));
  const daysDifference = moment().diff(moment(createdAt), "days");

  return (
    <VStack sx={styles.ConversationItem}>
      <VStack sx={styles.ConversationCreator}>
        <MediumImageLabel uid={creator} noTooltip />
        <Text color={colors.inkLight}>
          {createdAt
            ? moment(createdAt).format(i18n.t(k.MMM_DD_YYYY_HH_MM_A))
            : null}
        </Text>
        <Text color={colors.inkLighter}>
          {createdAt ? (
            <>
              {daysDifference > 1 && `${daysDifference} `}
              {daysDifference > 1
                ? i18n.t(k.DAYS_OLD)
                : i18n.t(k.LESS_THAN_A_DAY_OLD)}
            </>
          ) : null}
        </Text>
      </VStack>
    </VStack>
  );
};

export default ConversationCreator;
