// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";

import Common from "../Common";
import NameOrEmail from "src/containers/user/NameOrEmail";
import type { Message } from "src/types";

type Props = {
  message: Message
};

const Invite = ({ message }: Props) => {
  const { data } = message;
  const { members } = data;
  return (
    <Common {...message}>
      {" "}
      {i18n.t(k.INVITED)}{" "}
      {(members || []).map(uid => (
        <NameOrEmail uid={uid} key={uid} showPending={false} />
      ))}{" "}
      {i18n.t(k.TO_THE_CHATROOM)}
    </Common>
  );
};

export default Invite;
