// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";
import { useSelector } from "react-redux";
import * as R from "ramda";

import { EditIconWrapper } from "../styles";
import {
  EmptyUserField,
  EmptyUserValueWrapper,
  NoValue,
  Select as StyledUser,
  MultiContainer,
  ValueWrapper,
  MultiTable,
  MembersContainer
} from "./styles";
import UserIcon from "src/containers/user/picture/Small";
import Group from "src/components/user/Group";
import Tooltip from "src/components/Tooltip";
import { ParticipantCount } from "src/components/Manage/Workflow/Instances/Table/Row/Members/styles";
import MultipleUser from "./MultipleUser";
import Icon from "src/icons";
import { getUsersById, getFieldWidth } from "src/reducers";
import type { ColumnId } from "src/types";

type Props = {
  selected: boolean,
  multiple: boolean,
  value: any,
  columnId: ColumnId,
  openChecklistEditModal: Function,
  index: number,
  fieldId: number,
  roomId: string,
  autoNo: string,
  hideEdit?: boolean,
  embeddedIndex?: number
};

const User = ({
  multiple,
  selected,
  columnId,
  value = [],
  openChecklistEditModal,
  index,
  fieldId,
  roomId,
  autoNo,
  hideEdit,
  embeddedIndex
}: Props) => {
  const fieldWidth = useSelector(({ app }) => getFieldWidth(app, columnId));
  const usersById = useSelector(({ app }) => getUsersById(app));
  if (!value) {
    return columnId.includes("-") ? (
      <MultiContainer
        selected={selected}
        width={fieldWidth}
        borderRequired={columnId.includes(i18n.t(k._5))}
      >
        <MultiTable>
          <tbody>
            <tr>
              <EmptyUserField
                width={fieldWidth}
                selected={selected}
                borderRequired={!columnId.includes(i18n.t(k._5))}
              >
                <EmptyUserValueWrapper>
                  <NoValue>{i18n.t(k.NO_VALUE)}</NoValue>
                  {!hideEdit && (
                    <EditIconWrapper
                      onClick={e =>
                        openChecklistEditModal(
                          e,
                          index,
                          fieldId,
                          roomId,
                          userIds,
                          columnId
                        )
                      }
                    >
                      <Icon type="editPencil" />
                    </EditIconWrapper>
                  )}
                </EmptyUserValueWrapper>
              </EmptyUserField>
            </tr>
          </tbody>
        </MultiTable>
      </MultiContainer>
    ) : (
      <StyledUser width={fieldWidth} selected={selected}>
        <NoValue>{i18n.t(k.NO_VALUE)}</NoValue>
        {!hideEdit && (
          <EditIconWrapper
            onClick={e =>
              openChecklistEditModal(
                e,
                index,
                fieldId,
                roomId,
                userIds,
                columnId
              )
            }
          >
            <Icon type="editPencil" />
          </EditIconWrapper>
        )}
      </StyledUser>
    );
  }

  let userIds = R.all(R.is(Object), value)
    ? R.map(entity => (entity.type === "group" ? entity.id : entity.uid), value)
    : value;

  if (Array.isArray(value[0])) {
    userIds = value.map(entities =>
      entities
        ? entities.map(entity =>
            entity.type === "group" ? entity.id : entity.uid
          )
        : []
    );
  }

  if (!userIds || R.isEmpty(userIds)) {
    return columnId.includes("-") ? (
      <EmptyUserField
        width={fieldWidth}
        selected={selected}
        borderRequired={true}
      >
        <EmptyUserValueWrapper maxWidth={fieldWidth}>
          <NoValue>{i18n.t(k.NO_VALUE)}</NoValue>
          {!hideEdit && (
            <EditIconWrapper
              onClick={e =>
                openChecklistEditModal(
                  e,
                  index,
                  fieldId,
                  roomId,
                  userIds,
                  columnId,
                  embeddedIndex
                )
              }
            >
              <Icon type="editPencil" />
            </EditIconWrapper>
          )}
        </EmptyUserValueWrapper>
      </EmptyUserField>
    ) : (
      <StyledUser width={fieldWidth} selected={selected}>
        <NoValue>{i18n.t(k.NO_VALUE)}</NoValue>
        {!hideEdit && (
          <EditIconWrapper
            onClick={e =>
              openChecklistEditModal(
                e,
                index,
                fieldId,
                roomId,
                userIds,
                columnId
              )
            }
          >
            <Icon type="editPencil" />
          </EditIconWrapper>
        )}
      </StyledUser>
    );
  }

  if (multiple || Array.isArray(userIds[0]) || columnId.includes("-")) {
    const fieldValue = Array.isArray(userIds[0]) ? userIds : [userIds];
    return (
      <MultipleUser
        columnId={columnId}
        autoNo={autoNo}
        selected={selected}
        userIds={fieldValue}
        value={value}
        openChecklistEditModal={openChecklistEditModal}
        index={index}
        fieldId={fieldId}
        roomId={roomId}
        hideEdit={hideEdit}
        embeddedIndex={embeddedIndex}
      />
    );
  }

  const userOrGroupId = userIds[0];

  return (
    <StyledUser selected={selected} width={fieldWidth}>
      <ValueWrapper>
        <MembersContainer>
          {typeof userOrGroupId === "number" ? (
            <Group groupId={parseInt(userOrGroupId, 10)} />
          ) : (
            <Tooltip
              title={usersById?.[userOrGroupId]?.displayName || ""}
              description={usersById?.[userOrGroupId]?.email || ""}
              placement="top"
            >
              <UserIcon uid={userOrGroupId} />
              <ParticipantCount></ParticipantCount>
            </Tooltip>
          )}
        </MembersContainer>
      </ValueWrapper>
      {!hideEdit && (
        <EditIconWrapper
          onClick={e => {
            openChecklistEditModal(e, 0, fieldId, roomId, userIds, columnId);
          }}
        >
          <Icon type="editPencil" />
        </EditIconWrapper>
      )}
    </StyledUser>
  );
};

export default User;
