// @flow

import React from "react";

import { Label } from "src/styles/form";
import { Input } from "src/styles/input";
import { DueDaysWrapper } from "./styles";

type Props = {
  handleDueInDays: Function,
  dueIn: ?number
};

const DueInDays = ({ handleDueInDays, dueIn }: Props) => {
  return (
    <DueDaysWrapper>
      <Label>
        SLA in days (Auto fill due date when conversation is created){" "}
      </Label>
      <Input
        placeholder="Days"
        type="number"
        min="0"
        value={dueIn}
        id="days"
        onChange={handleDueInDays}
        autoComplete="off"
      />
    </DueDaysWrapper>
  );
};

export default DueInDays;
