// @flow
import React from "react";

const ColorFG = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_255_294"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="14"
      height="14"
    >
      <rect y="0.400024" width="13.2" height="13.2" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_255_294)">
      <path d="M1.1001 13.6V11.4H12.1001V13.6H1.1001ZM3.0251 9.75005L5.9126 2.05005H7.2876L10.1751 9.75005H8.8551L8.1676 7.77005H5.0601L4.3451 9.75005H3.0251ZM5.4451 6.67005H7.7551L6.6276 3.48005H6.5726L5.4451 6.67005Z" />
    </g>
  </svg>
);

export default ColorFG;
