// @flow
import i18n from "i18next";
import k from "src/i18n/keys";
import { connect } from "react-redux";
import React, { useCallback } from "react";

import { Edit as StyledEdit } from "./styles";
import Edit from "./Edit";
import useBoolean from "src/hooks/useBoolean";
import useInput from "src/hooks/useInput";
import { updateSection } from "src/actions/orgSettings";
import Icon from "src/icons";

type Props = {
  value: string,
  id: number,
  _updateSection: Function
};

const Description = ({ id, value, _updateSection }: Props) => {
  const { value: newValue, handleChange, setValue } = useInput();
  const { value: edit, toggleBoolean } = useBoolean();

  const handleClose = useCallback(() => {
    if (value !== newValue) {
      _updateSection({
        id,
        description: newValue || ""
      });
    }
    toggleBoolean();
  }, [newValue, id, value, _updateSection, toggleBoolean]);

  const handleEdit = useCallback(() => {
    setValue(value);
    toggleBoolean();
  }, [toggleBoolean, setValue, value]);

  if (edit) {
    return (
      <Edit
        value={newValue}
        handleChange={handleChange}
        handleClose={handleClose}
      />
    );
  }

  return (
    <StyledEdit onClick={handleEdit}>
      <span>{value || i18n.t(k.USE_THIS_SECTION_UPCOMING_TASKS)}</span>

      <Icon type="editPencil" />
    </StyledEdit>
  );
};

export default connect(null, { _updateSection: updateSection })(Description);
