// @flow

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import type { Header } from "@tanstack/react-table";
import type { ElementRef, ElementType } from "react";

import { fetchForm } from "src/actions/forms";
import { toggleFormFieldsVisibility } from "src/actions/workflows";
import Form from "../Filter/Form";
import Icon from "src/icons";
import Tooltip from "src/components/Tooltip";
import {
  HeaderItem as StyledHeaderItem,
  LabelWrapper,
  LabelIconWrapper,
  ChecklistHeaderIconsContainer
} from "../styles";
import Resizer from "../Resizer";
import { getCanEditReportFilters } from "src/reducers/combinedSelectors";

import type { ColumnId } from "src/types";

type Props = {
  parentRef: {
    current: null | ElementRef<ElementType>
  },

  toggleDropdown: Function,
  closeDropdown: Function,
  label: string,
  dropdown: boolean,
  column: ColumnId,
  toggleSortBy: Function,
  settings: Object,
  setFilter: Function,
  filter: Array<string>,
  childColumn: ?boolean,
  header: Header
};

const FormHeaderItem = ({
  toggleDropdown,
  parentRef,
  label,
  dropdown,
  column,
  closeDropdown,
  toggleSortBy,
  settings,
  setFilter,
  filter,
  childColumn,
  header
}: Props) => {
  const dispatch = useDispatch();
  const fieldsByForm = useSelector(({ app }) => app.checklist.fieldsByForms);

  const isFilteringAllowed = useSelector(state =>
    getCanEditReportFilters(state)
  );

  const handleExpansion = e => {
    e.stopPropagation();
    if (settings.selectedForms) {
      settings.selectedForms.forEach(form => {
        // Fetching form fields if it's not already fetched
        if (!fieldsByForm || !fieldsByForm[form]) {
          dispatch(fetchForm(form));
        }
        dispatch(toggleFormFieldsVisibility(form, `${column}`));
      });
    }
  };

  return (
    <StyledHeaderItem
      width={header.getSize()}
      isResizing={header.column.getIsResizing()}
      onClick={toggleDropdown}
      ref={parentRef}
      childColumn={childColumn}
      type="form"
    >
      <Tooltip align="down" title={label}>
        <LabelWrapper>{label}</LabelWrapper>
      </Tooltip>
      {dropdown && (
        <Form
          column={`${column}`}
          handleClose={closeDropdown}
          parentRef={parentRef}
          setFilter={setFilter}
          filter={filter}
          toggleSortBy={toggleSortBy}
        />
      )}
      <ChecklistHeaderIconsContainer>
        {!column.includes("-") && (
          <div style={{ paddingTop: "5px" }} onClick={handleExpansion}>
            <Icon type="expansion" />
          </div>
        )}
        {isFilteringAllowed && (
          <LabelIconWrapper>
            <Icon type="triangleDown" />
          </LabelIconWrapper>
        )}
      </ChecklistHeaderIconsContainer>
      <Resizer header={header} />
    </StyledHeaderItem>
  );
};

export default FormHeaderItem;
