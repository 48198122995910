// @flow

import i18n from "i18next";
import k from "src/i18n/keys";
import { connect } from "react-redux";
import React, { useState, useCallback } from "react";
import * as R from "ramda";

import { User as StyledUser } from "../styles";
import Options from "./Options";
import InvitingUser from "./InvitingUser";
import { RelativeBox } from "./styles";
import NameOrEmail from "src/components/user/NameOrEmail";
import Picture from "src/components/user/picture";
import { ImageLabel, AuthorWrapper } from "src/components/user/styles";
import { getUser } from "src/reducers";
import { resendInvite } from "src/actions/user";

import type { AppState, UnifizeUser, UID } from "src/types";

type Props = {
  user: UnifizeUser,
  uid: UID,
  stickiness: boolean,
  handleEdit: Function,
  toggleStickiness: Function,
  _resendInvite: Function
};

const User = ({
  uid,
  user,
  stickiness,
  handleEdit,
  toggleStickiness,
  _resendInvite
}: Props) => {
  const [dropdown, showDropdown] = useState(false);

  const handleDropdown = useCallback(() => {
    showDropdown(!dropdown);
    toggleStickiness();
  }, [dropdown, showDropdown, toggleStickiness]);

  const handleResendInvite = useCallback(
    (e: any) => {
      e.stopPropagation();
      _resendInvite(uid);
    },
    [uid, _resendInvite]
  );

  return (
    <StyledUser stickiness={stickiness} onClick={handleDropdown}>
      <div>
        <ImageLabel>
          <Picture uid={uid} user={user} />
        </ImageLabel>
        <AuthorWrapper>
          <NameOrEmail uid={uid} user={user} />
        </AuthorWrapper>
        {user.invitePending &&
          user.orgRole !== "contact" &&
          user.orgRole !== "collaborator" && (
            <InvitingUser
              uid={uid}
              text={i18n.t(k.RESEND)}
              handleClick={handleResendInvite}
            />
          )}

        {R.includes(user.orgRole, ["collaborator", "contact"]) && (
          <InvitingUser
            uid={uid}
            text={i18n.t(k.INVITE1)}
            handleClick={handleResendInvite}
          />
        )}
      </div>
      <RelativeBox>
        {dropdown ? (
          <Options
            handleClose={handleDropdown}
            uid={uid}
            handleEdit={handleEdit}
          />
        ) : null}
      </RelativeBox>
    </StyledUser>
  );
};

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  user: getUser(app, props.uid)
});

export default connect(mapStateToProps, {
  _resendInvite: resendInvite
})(User);
