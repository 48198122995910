// @flow

import firebase from "firebase/app";
import "firebase/auth";

import db from "./db";
import localForage from "localforage";

import * as errorMessage from "src/constants/errorMessage";
import firebaseConfig, { backendUrl } from "src/config/firebase";
import * as easyFetch from "src/utils/fetch";

import type { UID, AuthProvider } from "src/types";

export const authenticate = (token: string) =>
  easyFetch.post("/authenticate", { body: { unifizeToken: token } });

/**
 * Verify User's email based on action code
 * @type {string} actionCode
 */
export const validateEmailVerifyCode = async (actionCode: ?string) => {
  try {
    await firebase.auth().applyActionCode(actionCode);
    // $FlowFixMe
  } catch ({ code, message }) {
    switch (code) {
      case "auth/user-not-found":
        throw new Error("This account doesn't exist");
      case "auth/invalid-email":
        throw new Error("Please enter a valid email address");
      case "auth/invalid-action-code":
        throw new Error({ code: "link-expired" });
      default:
        throw message;
    }
  }
};

/**
 * Verify if the password verification link is valid/expired
 * @param  {string} actionCode
 */
export const validatePasswordResetCode = async (actionCode: ?string) => {
  try {
    return await firebase.auth().verifyPasswordResetCode(actionCode);
    // $FlowFixMe
  } catch ({ code, message }) {
    switch (code) {
      case "auth/user-not-found":
        throw new Error("This account doesn't exist");
      case "auth/invalid-email":
        throw new Error("Please enter a valid email address");
      case "auth/invalid-action-code":
        throw new Error({ code: "link-expired" });
      default:
        throw message;
    }
  }
};

/**
 * Send auth token to the backend
 *
 * @param  {UnifizeUser} - user object
 * @param {number} - Current Organization Id of User
 * @return {void}
 */
export const sendAuthToken = async (token: string, orgId?: number) => {
  const response = await fetch(`${backendUrl}/auth`, {
    credentials: "include",
    method: "POST",
    headers: {
      "Content-type": "application/json"
    },
    body: JSON.stringify({ token, orgId })
  });
  return response.json();
};

/**
 * Bootstrap the app
 * @param {string} tenantId - ID of the tenant
 * @return Promise<any>
 */
export const bootstrap = async (tenantId: string): Promise<any> => {
  const response = await fetch(`${backendUrl}/init?tenantId=${tenantId}`, {
    credentials: "include",
    method: "GET"
  });
  return response.json();
};

// TODO: Call fetchCurrentUser in all the functions where current user is needed
/**
 * Get the current logged in user
 * @return Promise<UnifizeUser>
 */
export const getCurrentUser = async (): Promise<Object> => {
  const user = await new Promise((resolve, reject) =>
    firebase.auth().onAuthStateChanged(
      u => resolve(u),
      error => reject(error)
    )
  );
  return user;
};

/**
 * Register callback which runs when auth state changes
 *
 * @params {Function} - Callback
 * @return {Function} - Observer to stop the function
 */
export const onAuthStateChanged = (callback: Function): Function =>
  firebase.auth().onAuthStateChanged(callback);

/**
 * Check if a user is already signed in
 * @type {Function}
 */
export const isUserSignedIn = async (): Promise<boolean> =>
  (await getCurrentUser()) != null;

/*
Fetch current user's email
 */
export const getCurrentUserEmail = (): string =>
  firebase.auth().currentUser.email;

/*
Fetch current user's name and email
 */
export const getCurrentUserInfo = (): { name: string, email: string } => {
  const name = firebase.auth().currentUser.displayName;
  const email = getCurrentUserEmail();
  return {
    name,
    email
  };
};

/**
 * Check if user already exists in our app
 * @param  {string} email - Email address
 * @return {Boolean}
 */
export const isExistingUser = async (email: string) =>
  (await firebase.auth().fetchSignInMethodsForEmail(email)).includes(
    "password"
  );

/*
 * Create a user with a default password which will get reset by the
 * cloud function.
 */
export const createUser = async (
  email: string,
  password: string
): Promise<any> => {
  let user = {};
  try {
    user = await firebase
      .auth()
      .createUserWithEmailAndPassword(email, password);
    // $FlowFixMe: Ignore Flow error for function params destructuring
  } catch ({ code, message }) {
    switch (code) {
      case "auth/email-already-in-use":
        if (user.emailVerified) {
          // User has already signed-up and email address verified
          throw new Error("You have already signed up");
        } else {
          throw new Error(
            "User exists. Please click on the verification link sent on your mail"
          );
        }
      case "auth/weak-password":
        throw new Error(message);
      case "auth/invalid-email":
        throw new Error("Invalid email address");
      default:
        throw new Error("Unknown Error");
    }
  }
  return user;
};

/**
 * Signs the user with custom token
 *
 * @param  {string} customAuthToken - custom auth token for firebase
 * @return {Promise}
 */
export const signInWithCustomAuthToken = async (
  customAuthToken: string
): Promise<any> => firebase.auth().signInWithCustomToken(customAuthToken);

/**
 * Sign in for SRW
 */
export const signInForSRW = async (customAuthToken: string): Promise<any> => {
  await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.NONE);
  return firebase.auth().signInWithCustomToken(customAuthToken);
};

/**
 * Joins the user using email, authCode, orgId
 *
 * @param {string} email - email Id of User
 * @param {string} displayName - name of User
 * @param {string} password - password of User
 * @param {string} authCode - authCode from api
 * @param {string} orgId - organization Id of the User
 * @return {Object}
 */
export const join = async (payload: {
  email: string,
  authCode: string,
  orgId: number,
  displayName: string,
  password: string
}): Promise<any> => {
  try {
    const response = await fetch(`${backendUrl}/join2`, {
      method: "POST",
      headers: {
        "Content-type": "application/json"
      },
      body: JSON.stringify(payload)
    });

    switch (response.status) {
      case 409:
        throw new Error(errorMessage.USER_EXISTS);
      case 400:
        throw new Error(errorMessage.INVALID_LINK);
      default:
    }

    const { customToken, existingUser } = await response.json();
    if (customToken === null) {
      throw new Error("Not returned anything");
    }
    return { customToken, existingUser };
  } catch (e) {
    throw new Error(e);
  }
};

/*
 * A common function for calling the Firebase Sign-in to verify the
 * password.
 */
const validatePassword = (email: string, password: string): Promise<any> =>
  firebase.auth().signInWithEmailAndPassword(email, password);

/*
 * Sign in a user to Firebase
 */
export const signInUser = async (
  email: string,
  password: string
): Promise<any> => {
  db(firebaseConfig);
  try {
    await validatePassword(email, password);
    // $FlowFixMe: Ignore Flow error for function params destructuring
  } catch ({ code }) {
    switch (code) {
      case "auth/user-not-found":
      case "auth/wrong-password":
        throw new Error("Incorrect Username/Password");
      case "auth/invalid-email":
        throw new Error("Please enter a valid email address");
      case "auth/too-many-requests":
        throw new Error("Too many failed login attempts, try again later");
      default:
        throw new Error("Unknown error");
    }
  }
};

/**
 * Verify if user's full name is set
 * @return  {boolean}
 */
export const isUserInfoSet = (): boolean => {
  const user = firebase.auth().currentUser;
  if (user.displayName) return true;
  return false;
};

/**
 * Verify if user's email is verified
 * @return {boolean}
 */
export const isEmailVerified = (): boolean => {
  const user = firebase.auth().currentUser;
  if (user.emailVerified) return true;
  return false;
};

/*
Send Email Verification link on User's email
 */
export const sendEmailVerification = async (): Promise<any> => {
  const user = firebase.auth().currentUser;
  await user.sendEmailVerification();
};

/*
 * Send Reset Password Link on user's email
 */
export const resetPassword = async (email: string): Promise<any> => {
  const response = await fetch(`${backendUrl}/reset-password`, {
    credentials: "include",
    method: "POST",
    headers: {
      "Content-type": "application/json"
    },
    body: JSON.stringify({ email })
  });

  return response;
};

/*
 * Reset user's password using the confirmation code
 */
export const confirmReset = async (
  password: string,
  actionCode: string
): Promise<any> => {
  try {
    return await firebase.auth().confirmPasswordReset(actionCode, password);
    // $FlowFixMe: Ignore Flow error for function params destructuring
  } catch ({ code }) {
    switch (code) {
      case "auth/weak-password":
        throw new Error("Error. Please refer to the password requirements");
      case "auth/expired-action-code":
        throw new Error("The link to reset your password has expired.");
      case "auth/invalid-action-code":
        throw new Error("Password has been reset using this link in the past.");
      case "auth/user-disabled":
        throw new Error("Error. Your account has been disabled.");
      default:
        throw new Error("Unknown error");
    }
  }
};

/**
 * Sign out a user from the app
 */
export const signOutUser = async (): Promise<any> => {
  await easyFetch.get("/signout/user");
  const logout = await firebase.auth().signOut();
  return logout;
};

/**
 * Get Auth token for the current user
 */
export const getAuthToken = async (): Promise<Object> => {
  const user = await getCurrentUser();
  const token = user.getIdToken();
  return token;
};

/**
 * Get custom token for the a specific user
 */
export const loginAs = async (uid: UID) => {
  const response = await fetch(`${backendUrl}/auth/${uid}`, {
    credentials: "include",
    method: "GET"
  });

  const token = response.text();
  return token;
};

/**
 * Validate Access for SRW login
 * @param {
 *  "email": string,
 *  "authCode": string,
 *  "orgId": number,
 *  "address": string
 * } payload
 *
 * @return chatroom metadata for current chatroom
 */
export const validateAccess = async (payload: {
  email: string,
  authCode: string,
  orgId: number,
  address: string
}) => {
  const response = await fetch(`${backendUrl}/validate/access`, {
    method: "POST",
    headers: {
      "Content-type": "application/json"
    },
    body: JSON.stringify(payload)
  });
  return response.json();
};

export const signup = async (email: string) => {
  const response = await fetch(`${backendUrl}/signup/user`, {
    method: "POST",
    headers: {
      "Content-type": "application/json"
    },
    credentials: "include",
    body: JSON.stringify({
      email
    })
  });

  switch (response.status) {
    case 200:
    case 202:
      return { code: response.status };
    case 400:
      throw new Error({
        message: "User cannot signup",
        code: response.status
      });
    case 404:
      throw new Error({
        message: "Unable to reach server please check your network",
        code: response.status
      });
    case 409:
      throw new Error({
        message: "User already exist",
        code: response.status
      });
    default:
      throw new Error({ message: "Unable to signup", code: response.status });
  }
};

export const sendVerificationEmail = async (email: string) =>
  easyFetch.put(`/invitation/${email}`);

export const changePassword = async (newPassword: string) => {
  const response = await fetch(`${backendUrl}/set-password`, {
    method: "POST",
    headers: {
      "Content-type": "application/json"
    },
    credentials: "include",
    body: JSON.stringify({
      newPassword
    })
  });

  return response;
};

/**
 * Get the auth provider associated with a certain user and org
 * @param {string} email - email of the user
 * @param {string} orgId - ID of the org we need the auth provider for
 */
export const getAuthProvider = async (email: string, orgId?: string) => {
  const url = orgId
    ? `${backendUrl}/auth-provider?email=${email}&orgId=${orgId}`
    : `${backendUrl}/auth-provider?email=${email}`;

  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-type": "application/json"
    },
    credentials: "include"
  });

  return response.json();
};

export const signInUserWithAuthProvider = async (
  authProvider: AuthProvider
) => {
  firebase.auth().tenantId = authProvider.tenantId;

  await localForage.setItem("tenantId", authProvider.tenantId ?? "");

  const provider = new firebase.auth.SAMLAuthProvider(authProvider.provider);
  firebase.auth().signInWithRedirect(provider);
};

/**
 * Setup SSO org after successfully authenticating the user through
 * the configured tenant
 * @param {string} email - email of the joinee
 * @param {number | string} orgId - org the user is trying to join
 */
export const joinSsoOrg = async (email: string, orgId: number | string) => {
  const response = await fetch(`${backendUrl}/sso/join`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    credentials: "include",
    body: JSON.stringify({
      email,
      orgId: parseInt(orgId)
    })
  });

  return response.json();
};

/**
 * Get auth provider data for org nickname
 * @param {string} nickName - org nickname
 */
export const getAuthProviderFromNickname = async (nickName: string) => {
  const response = await fetch(`${backendUrl}/auth-provider?slug=${nickName}`, {
    credentials: "include",
    method: "GET"
  });

  return response.json();
};
