// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";
import { FormControl, FormLabel, Text, Switch } from "@chakra-ui/react";

import {
  ItemLabel,
  ItemIcon,
  ItemInfo,
  ItemValue,
  ReminderWrapper
} from "./styles";
import Icon from "src/icons";
import { getChatroomReminders } from "src/reducers";
import { setAttribute } from "src/actions/chatroom";
import { active } from "src/styles/constants/colors";

import type { AppState, RoomId } from "src/types";
import appLocation from "src/constants/location";
import * as styles from "./styles";

type Props = {
  reminders: boolean,
  _setAttribute: Function,
  roomId: RoomId,
  location?: string,
  setIsMoreMenuOpen: (value: boolean) => void
};

const Reminders = ({ reminders, roomId, _setAttribute, location }: Props) => {
  return (
    <>
      {location === appLocation.moreMenu ? (
        <FormControl
          tabIndex={0}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();

            _setAttribute(roomId, {
              reminders: R.isNil(reminders) ? false : !reminders
            });
          }}
          sx={
            location === appLocation.moreMenu
              ? styles.RemindersAlt
              : styles.Reminders
          }
        >
          <FormLabel sx={styles.RemindersLabel} htmlFor="reminders" mb="0">
            <Icon type="bell" fill={active} />
            <Text>{i18n.t(k.REMINDERS1)}</Text>
          </FormLabel>

          <Switch
            id="reminders"
            isFocusable={false}
            isChecked={R.isNil(reminders) ? true : reminders}
            colorScheme="whatsapp"
            size="sm"
          />
        </FormControl>
      ) : (
        <ReminderWrapper>
          <ItemIcon>
            <Icon type="bell" />
          </ItemIcon>
          <ItemInfo>
            <ItemLabel>{i18n.t(k.REMINDERS1)}</ItemLabel>
            <ItemValue>
              {R.isNil(reminders) || reminders ? i18n.t(k.ON) : i18n.t(k.OFF)}
            </ItemValue>
          </ItemInfo>
          <Switch
            onChange={() => {
              _setAttribute(roomId, {
                reminders: R.isNil(reminders) ? false : !reminders
              });
            }}
            isChecked={R.isNil(reminders) ? true : reminders}
            colorScheme="whatsapp"
            size="sm"
          />
        </ReminderWrapper>
      )}
    </>
  );
};

const mapStateToProps = ({ app }: { app: AppState }, { roomId }) => ({
  reminders: getChatroomReminders(app, roomId)
});

export default connect(mapStateToProps, {
  _setAttribute: setAttribute
})(Reminders);
