// @flow

import React from "react";
import { useSelector } from "react-redux";

import FileCellContents from "./FileCellContents";
import MultipleFiles from "./MultipleFiles";
import { Cell, EditIconWrapper } from "../styles";
import { FileWrapper } from "./styles";
import Icon from "src/icons";
import { getFieldWidth } from "src/reducers";
import type { WorkflowInstances, ColumnId } from "src/types";

type Props = {
  process: WorkflowInstances,
  selected: boolean,
  multiple: boolean,
  value: any,
  openChecklistEditModal: Function,
  index: number,
  fieldId: number,
  roomId: string,
  hideEdit?: boolean,
  columnId: ColumnId,
  autoNo: string,
  type: string,
  embeddedIndex?: number,
  settings: string
};

const Files = ({
  process,
  selected,
  multiple,
  value,
  openChecklistEditModal,
  index,
  fieldId,
  roomId,
  hideEdit,
  columnId,
  autoNo,
  type,
  embeddedIndex,
  settings
}: Props) => {
  const fieldWidth = useSelector(({ app }) => getFieldWidth(app, columnId));

  if (
    (value && multiple && Array.isArray(value[0])) ||
    (columnId && columnId.includes("-"))
  ) {
    const fieldValue = value && Array.isArray(value[0]) ? value : [value];
    return (
      <MultipleFiles
        process={process}
        columnId={columnId}
        autoNo={autoNo}
        selected={selected}
        value={fieldValue}
        openChecklistEditModal={openChecklistEditModal}
        rowIndex={index}
        fieldId={fieldId}
        roomId={roomId}
        hideEdit={hideEdit}
        type={type}
        embeddedIndex={embeddedIndex}
        settings={settings}
      />
    );
  }

  return (
    <Cell selected={selected} width={fieldWidth}>
      {/* $FlowFixMe */}
      <FileWrapper maxWidth={fieldWidth}>
        <FileCellContents
          value={value}
          fieldId={fieldId}
          roomId={roomId}
          settings={settings}
          openChecklistEditModal={openChecklistEditModal}
        />
      </FileWrapper>
      {!hideEdit && (
        <EditIconWrapper
          onClick={e =>
            openChecklistEditModal(e, index, fieldId, roomId, value)
          }
        >
          <Icon type="editPencil" />
        </EditIconWrapper>
      )}
    </Cell>
  );
};

export default Files;
