// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import { connect } from "react-redux";
import React from "react";

import { NoInstances as StyledNoValue } from "./styles";

import type { AppState } from "src/types";

type Props = {
  loading: boolean
};

const NoValue = ({ loading }: Props) => {
  if (!loading) {
    return (
      <StyledNoValue>{i18n.t(k.THERE_ARE_NO_PROCESSES_THAT_MA)}</StyledNoValue>
    );
  }
  return <></>;
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  loading: app.workflow.loadingInstances
});

export default connect(mapStateToProps)(NoValue);
