// @flow

import * as easyFetch from "src/utils/fetch";

import type { TranslationQueryParams } from "src/types";

/**
 * Fetch all translations for the org
 */
export const getTranslations = ({
  lang,
  page,
  pageSize,
  search,
  area,
  areaId,
  entity
}: TranslationQueryParams): Promise<any> => {
  const queryParams = new URLSearchParams();
  if (lang) queryParams.append("langAbbr", lang);
  if (page) queryParams.append("pageNo", page.toString());
  if (pageSize) queryParams.append("pageSize", pageSize.toString());
  if (search) queryParams.append("search", search);
  if (area) queryParams.append("area", area);
  if (areaId) queryParams.append("areaId", areaId);
  if (entity) queryParams.append("entity", entity.toString());

  return easyFetch.get(`/translations?${queryParams.toString()}`);
};

/**
 * Update the existing translation manually
 * @param {number} translationId - ID of the translation to update
 * @param {string} manualTranslation - the translated text
 * @param {string} lang - the language the translated text is in
 */
export const updateTranslation = (
  translationId: number,
  manualTranslation: string,
  lang: string
) =>
  easyFetch.patch(`/translation`, {
    body: {
      id: translationId,
      manualTranslation,
      langAbbr: lang
    }
  });

/**
 * Reverts the translation to generated translation
 * @param {number} translationId - ID of the translation to update
 * @param {string} lang - the language the translated text is in
 */
export const refreshTranslation = (translationId: number, lang: string) =>
  easyFetch.patch(`/translation`, {
    body: {
      id: translationId,
      langAbbr: lang,
      reset: true
    }
  });

/**
 * Fetch the languages supported by the org
 */
export const getOrgLanguages = () => easyFetch.get("/language");
