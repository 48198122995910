// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React, { useEffect, useRef } from "react";
import { Stack, Radio, RadioGroup } from "@chakra-ui/react";
import { SortRadio } from "./styles";
import type { ReactRef, ChartSortOrder } from "src/types";
import { useSelector } from "react-redux";
import { getChartDialog } from "src/reducers";

type Props = {
  value: ChartSortOrder,
  setNewChartDialogAttributes: Function
};

const SortBySelector = ({ setNewChartDialogAttributes, value }: Props) => {
  const { sort } = useSelector(({ app }) => getChartDialog(app));
  const handleChange = (val: string) => {
    setNewChartDialogAttributes({
      sort: {
        ...sort,
        orderBy: val
      }
    });
  };

  const ref: ReactRef = useRef(null);
  useEffect(() => {
    ref.current.scrollIntoView();
  }, []);
  return (
    <RadioGroup onChange={handleChange} value={value || "default"}>
      <Stack direction="column">
        <Radio id="default" value={"default"} size="sm">
          <SortRadio>{i18n.t(k.DEFAULT1)}</SortRadio>
        </Radio>
        <Radio id="desc" value={"desc"} size="sm">
          <SortRadio>{i18n.t(k.DESCENDING)}</SortRadio>
        </Radio>
        <Radio id="asc" value={"asc"} size="sm" ref={ref}>
          <SortRadio>{i18n.t(k.ASCENDING)}</SortRadio>
        </Radio>
      </Stack>
    </RadioGroup>
  );
};
export default SortBySelector;
