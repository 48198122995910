// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React, { useState, useCallback } from "react";
import { connect, useSelector } from "react-redux";

import AppInfo from "../AppInfo";
import Input from "./Input";
import {
  Main,
  Header,
  TitleWrapper,
  AppInfoWrapper,
  CloseIcon,
  HistoryWrapper,
  FlexWrapper,
  PanelWrapper,
  MenuTooltip,
  Pointer
} from "./styles";
import OutsideClickHandler from "src/components/OutsideClickHandler";
import Title from "src/components/chatroom/Header/Title";
import ChatHistory from "src/components/messages/History";
import Status from "src/components/chatroom/Status";
import Owner from "src/containers/chatroom/Owner";
import Checklist from "src/components/chatroom/Checklist";
import Files from "src/containers/chatroom/Files";
import Members from "src/components/chatroom/Members";
import Dock from "src/containers/CurrentRoomDock";
import Icon from "src/icons";
import {
  getCurrentChatRoom,
  getMessageData,
  getReplyTo,
  getFileUploadModalStatus,
  getProcessLayoutSettings,
  getIsNavigatedFromHeader
} from "src/reducers";
import * as colors from "src/styles/constants/colors";
import LoadingState from "src/components/LoadingState";
import PreviewModal from "src/components/file/PreviewModal";

import appLocation from "src/constants/location";

import type { AppState, UnifizeChatRoom, UnifizeUser, Reply } from "src/types";

type Props = {
  room: UnifizeChatRoom,
  activeDock: string,
  vh: number,
  showFilesPreviewModal: boolean,
  currentUser: UnifizeUser,
  navigatedFromHeader: boolean
};

const Mobile = ({
  room,
  activeDock,
  vh,
  showFilesPreviewModal,
  currentUser,
  navigatedFromHeader
}: Props) => {
  const [registerScreen, setRegisterScreen] = useState(false);
  const [showMenuTooltip, setMenuTooltip] = useState(true);

  const showRegister = useCallback(() => setRegisterScreen(true), []);
  const hideRegister = useCallback(() => setRegisterScreen(false), []);

  const closeMenuTooltip = useCallback(() => setMenuTooltip(false), []);
  const checklistLayout = useSelector(({ app }) =>
    getProcessLayoutSettings(app, room?.id)
  );

  const isOpen =
    checklistLayout &&
    (checklistLayout.checklistOpenState || checklistLayout.openStatus);

  if (activeDock === "checklist" && (isOpen?.liteMobile || navigatedFromHeader))
    return (
      <PanelWrapper height={vh}>
        <Dock isSrw showClose />
      </PanelWrapper>
    );

  if (activeDock === "files")
    return (
      <PanelWrapper height={vh}>
        <Dock isSrw showClose />
      </PanelWrapper>
    );

  if (registerScreen)
    return (
      <AppInfoWrapper height={vh}>
        <CloseIcon>
          <Icon
            type="close"
            color={colors.primary}
            handleClick={hideRegister}
          />
        </CloseIcon>
        <AppInfo />
      </AppInfoWrapper>
    );

  return (
    <Main height={vh}>
      {showFilesPreviewModal && room?.id && <PreviewModal roomId={room.id} />}
      {room && room.id ? (
        <Header>
          <TitleWrapper>
            <Icon type="menu" handleClick={showRegister} />
            <Title roomId={room.id} location="Chatroom Header" />
            {showMenuTooltip && (
              <OutsideClickHandler onClickOutside={closeMenuTooltip}>
                <MenuTooltip>
                  <Pointer />
                  {i18n.t(k.CLICK_HERE_TO_SIGN_UP_OR_LOG_I)}
                  <Icon
                    type="close"
                    color={colors.brightLight}
                    handleClick={closeMenuTooltip}
                  />
                </MenuTooltip>
              </OutsideClickHandler>
            )}
          </TitleWrapper>
          <FlexWrapper>
            <Status roomId={room.id} location={appLocation.chatroomHeader} />
            <Owner roomId={room.id} location={appLocation.chatroomHeader} />
            <Checklist roomId={room.id} isSrw showPanel />
            <Members
              roomId={room.id}
              location={appLocation.chatroomHeader}
              address={room.address}
            />

            <Files roomId={room.id} isSrw showPanel />
          </FlexWrapper>
        </Header>
      ) : (
        <LoadingState type="header" />
      )}
      <HistoryWrapper>
        {room && room.id ? (
          <ChatHistory roomId={room.id} />
        ) : (
          <LoadingState type="mobileHistory" />
        )}
      </HistoryWrapper>
      {room && room.id && currentUser.uid ? (
        <Input roomId={room.id} />
      ) : (
        <LoadingState type="input" />
      )}
    </Main>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => {
  const replyTo: Reply = getReplyTo(app);
  const { to } = replyTo;
  const room = getCurrentChatRoom(app);
  return {
    currentUser: app.currentUser,
    room,
    activeDock: app.activeDock.srw,
    currentMessage: getMessageData(app, to),
    showFilesPreviewModal: room
      ? getFileUploadModalStatus(app, room.id)
      : false,
    navigatedFromHeader: getIsNavigatedFromHeader(app)
  };
};

export default connect(mapStateToProps, {})(Mobile);
