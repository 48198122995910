import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";
import { TableHeader } from "src/components/Manage/Reports/Templates/Table/styles";

import { Th } from "src/styles/table";

const Header = () => (
  <TableHeader>
    <tr>
      <Th>{i18n.t(k.FORM_NAME)}</Th>
      <Th>{i18n.t(k.CREATED_AT)}</Th>
      <Th>{i18n.t(k.UPDATED_AT)}</Th>
      <Th>{i18n.t(k.CREATED_BY1)}</Th>
    </tr>
  </TableHeader>
);

export default Header;
