// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import { connect, useSelector } from "react-redux";
import React, { useEffect, useCallback } from "react";
import * as R from "ramda";

import {
  Filter as StyledFilter,
  ListItem,
  SubHeading,
  Separator
} from "./styles";
import { setManageViewFilter } from "src/actions/workflows";
import {
  toggleFormFieldsVisibility,
  setFormFieldsVisibility
} from "src/actions/workflows";
import {
  getUniqueValues,
  getAllWorkflows,
  getAllRecords,
  getChecklistFieldsById
} from "src/reducers";
import AllRecordsFilter from "./AllRecordsFilter";
import Checkbox from "src/components/Checkbox";
import FormName from "src/components/Form/Name";
import BlanksNonBlanks from "./BlanksNonBlanks";
import { Flex } from "@chakra-ui/react";
import Switch from "react-switch";
import * as colors from "src/styles/constants/colors";

import type { AppState, FilterWorkerData } from "src/types";

type Props = {
  column: string,
  values: Array<number>,
  parentRef: any,
  _setManageViewFilter: Function,
  handleClose: Function,
  setFilter: Function,
  toggleSortBy: Function,
  toggleFormFieldsVisibility: Function,
  setFormFieldsVisibility: Function,
  filter: Array<string>,
  formFieldsVisibility: Object
};

const CheckItemWithFilter = ({
  parentRef,
  column,
  _setManageViewFilter,
  handleClose,
  setFilter,
  filter,
  toggleSortBy,
  values,
  toggleFormFieldsVisibility,
  formFieldsVisibility,
  setFormFieldsVisibility
}: Props) => {
  const allRecords = useSelector(({ app }) => getAllRecords(app));

  const workflow = useSelector(({ app }) => getAllWorkflows(app));
  const fieldsById = useSelector(({ app }) => getChecklistFieldsById(app));
  const filters = workflow.instanceFilter;
  const chatroomAttributes = ["owner", "dueDate", "status"];
  const instancesById = workflow.instancesById;
  const updatedInstances = { ...instancesById };

  let workerData: FilterWorkerData = {
    workflow,
    fieldsById: fieldsById.toJS(),
    instancesById,
    filters,
    chatroomAttributes,
    updatedInstances,
    allRecords
  };
  const closeModal = (event: any) => {
    if (event.keyCode === 13) {
      handleClose();
    }
  };

  const clickOutside = useCallback(
    (event: any) => {
      if (parentRef && parentRef.current) {
        if (!parentRef.current.contains(event.target)) {
          handleClose();
        }
      }
    },
    [parentRef, handleClose]
  );

  useEffect(() => {
    // $FlowFixMe
    document.addEventListener("keydown", closeModal, false);
    document.addEventListener("click", clickOutside, false);
    return () => {
      // $FlowFixMe
      document.removeEventListener("keydown", closeModal, false);
      document.removeEventListener("click", clickOutside, false);
    };
  }, []);

  const handleSort = (ascending: boolean) => {
    if (ascending) {
      toggleSortBy(false, true);
    } else {
      toggleSortBy(true, true);
    }
    handleClose();
  };

  const handleSelect = useCallback(
    (event: Event, item: number) => {
      event.preventDefault();
      event.stopPropagation();

      if (R.includes(item, filter || [])) {
        setFilter(column, R.reject(R.equals(item), filter));
        setFormFieldsVisibility(item, column, false);
      } else {
        setFilter(column, [...(filter || []), item]);
      }
    },
    [column, filter, _setManageViewFilter]
  );

  const handleFormFieldExpansion = (id: number) => {
    toggleFormFieldsVisibility(id, column);
  };

  return (
    <StyledFilter
      onClick={(e: Event) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <SubHeading>{i18n.t(k.SORT_BY2)}</SubHeading>
      <ul>
        <ListItem tabIndex="0" role="button" onClick={() => handleSort(true)}>
          {i18n.t(k.ASCENDING)}
        </ListItem>
        <ListItem tabIndex="0" role="button" onClick={() => handleSort(false)}>
          {i18n.t(k.DESCENDING)}
        </ListItem>
      </ul>
      <Separator />
      <SubHeading>{i18n.t(k.FILTER)}</SubHeading>
      <BlanksNonBlanks handleSelect={handleSelect} filter={filter} />
      <Separator />
      <AllRecordsFilter
        label={i18n.t(k.ALL_EMBEDDED_RECORDS)}
        isAllRecords={allRecords[column]}
        columnId={column}
        workerData={workerData}
      />

      <Flex justifyContent="space-between">
        <SubHeading>{i18n.t(k.FORM2)}</SubHeading>
        <SubHeading>{i18n.t(k.EXPAND)}</SubHeading>
      </Flex>
      <ul>
        {values.map(value => (
          <Flex key={value} justifyContent="space-between" alignItems="center">
            <ListItem
              tabIndex="0"
              role="button"
              onClick={event => handleSelect(event, value)}
            >
              <Checkbox
                id={`form${value}`}
                checked={R.includes(value, filter || [])}
                handleChange={event => handleSelect(event, value)}
              />

              <FormName id={value} />
            </ListItem>
            <Switch
              onChange={() => handleFormFieldExpansion(value)}
              uncheckedIcon={false}
              checkedIcon={false}
              checked={formFieldsVisibility[column]?.[value] ?? false}
              onColor={colors.checked}
              offColor={colors.grey6}
              className={formFieldsVisibility[column]?.[value] ? "on" : "off"}
              height={18}
              width={31}
            />
          </Flex>
        ))}
      </ul>
    </StyledFilter>
  );
};

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  values: getUniqueValues(app, props.column),
  formFieldsVisibility: app.workflow.formFieldsVisibility
});

export default connect(mapStateToProps, {
  _setManageViewFilter: setManageViewFilter,
  toggleFormFieldsVisibility,
  setFormFieldsVisibility
})(CheckItemWithFilter);
