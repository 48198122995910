// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";
import * as R from "ramda";

import useBoolean from "src/hooks/useBoolean";
import type { UID, RoomId } from "src/types";
import { useSelector } from "react-redux";
import { getUser, getCurrentUserId } from "src/reducers";
import * as notifications from "src/constants/notifications";

import { Avatar, VStack, Button, Divider } from "@chakra-ui/react";
import MessageCard from "src/components/messages/Chat/MessageNotch";
import { UserInfo, ChatCard } from "src/components/messages/Chat/styles";
import ChatTimestamp from "src/components/messages/Chat/ChatTimestamp";
import SlackMessage from "./Message";
import * as styles from "./styles";

type TitleProps = {
  messageType: "created" | "forwarded" | "sent",
  author: string
};

const Title = ({ messageType, author }: TitleProps) => {
  switch (messageType) {
    case "created":
      return (
        <span>
          <strong>{author}</strong> {i18n.t(k.STARTED_THIS_CONVERSATION_FROM)}{" "}
          <strong>{i18n.t(k.SLACK)}</strong>
        </span>
      );

    case "forwarded":
      return (
        <span>
          <strong>{author}</strong> {i18n.t(k.FORWARDED_FROM1)}{" "}
          <strong>{i18n.t(k.SLACK)}</strong>
        </span>
      );

    default:
      return (
        <span>
          <strong>{author}</strong> {i18n.t(k.SENT_THIS_MESSAGE_FROM)}{" "}
          <strong>{i18n.t(k.SLACK)}</strong>
        </span>
      );
  }
};

type SlackUser = {
  id: string,
  realName: string,
  profilePic: string
};

export type SlackFile = {
  name: string,
  originalName: string
};

export type SlackMessageType = {
  ts: string,
  timestamp: Date,
  threadTs: string,
  text: string,
  mentions: SlackMentions,
  permalink: string,
  user: SlackUser,
  files: SlackFile[]
};

export type SlackMentions = {
  [string]: {
    id: string,
    profilePic: string,
    realName: string
  }
};

type Props = {
  roomId: RoomId,
  notification: {
    action: string,
    author: UID,
    timestamp: Date,
    data: {
      app: "slack",
      channel: {
        id: string,
        name: string
      },

      message: SlackMessageType & {
        thread: SlackMessageType[]
      }
    }
  }
};

const getMessageType = notificationAction => {
  switch (notificationAction) {
    case notifications.EXTERNAL_APP_CONVERSATION_START:
      return "created";

    case notifications.EXTERNAL_APP_MESSAGE_FORWARD:
      return "forwarded";

    default:
      return "sent";
  }
};

const getFormattedThread = message => {
  // If thread is empty then return empty array
  if (R.isEmpty(message.thread)) return [];

  // If message is the first message in the thread then remove it from the thread
  if (message.permalink === message.thread[0].permalink) {
    return message.thread.slice(1);
  }

  return message.thread;
};

export default function Slack({ notification, roomId }: Props) {
  const { value: showThread, toggleBoolean: toggleThreadVisibility } =
    useBoolean(false);

  const { displayName: authorName } = useSelector(({ app }) =>
    getUser(app, notification.author)
  );

  const currentUserId = useSelector(({ app }) => getCurrentUserId(app));

  const isAuthorCurrentUser = notification.author === currentUserId;

  const thread = getFormattedThread(notification.data.message);

  return (
    <ChatCard first style={styles.chatCard}>
      <UserInfo>
        <Avatar
          name="unifize-slack-app"
          src="https://s3.amazonaws.com/profile_photos/620309614204904.FiuBxGvJXfYhFSaMU6E8_60x60.png"
          size="sm"
          sx={styles.avatar}
        />
      </UserInfo>

      <MessageCard first singleLine={false}>
        <VStack sx={styles.messageCardContent}>
          <Title
            author={isAuthorCurrentUser ? i18n.t(k.YOU) : authorName}
            messageType={getMessageType(notification.action)}
          />

          <VStack sx={styles.slackMessageContainer}>
            <SlackMessage
              author={notification.data.message.user.realName}
              messageLink={notification.data.message.permalink}
              channel={notification.data.channel.name}
              timestamp={notification.data.message.timestamp}
              content={notification.data.message.text}
              profilePicURL={notification.data.message.user.profilePic}
              files={notification.data.message.files}
              mentions={notification.data.message.mentions}
              roomId={roomId}
            />

            {thread.length > 0 && (
              <Button variant="link" onClick={toggleThreadVisibility}>
                {showThread ? i18n.t(k.HIDE) : i18n.t(k.VIEW)}{" "}
                {i18n.t(k.THREAD)}
              </Button>
            )}

            {showThread && (
              <VStack sx={styles.threadContainer}>
                <Divider />
                <VStack spacing={2} sx={styles.threadList}>
                  {thread.map(threadMessage => (
                    <SlackMessage
                      key={threadMessage.permalink}
                      author={threadMessage.user.realName}
                      messageLink={threadMessage.permalink}
                      timestamp={threadMessage.timestamp}
                      content={threadMessage.text}
                      profilePicURL={threadMessage.user.profilePic}
                      files={threadMessage.files}
                      mentions={threadMessage.mentions}
                      showAvatar
                      roomId={roomId}
                    />
                  ))}
                </VStack>
              </VStack>
            )}
          </VStack>

          <ChatTimestamp
            read
            delivered
            timestamp={notification.timestamp}
            style={styles.timestamp}
          />
        </VStack>
      </MessageCard>
    </ChatCard>
  );
}
