// @flow

import i18n from "i18next";
import k from "src/i18n/keys";
import React from "react";

import useTranslatedFieldNames from "src/hooks/useTranslatedFieldNames";

import { AddButton } from "./styles";
import { convertToTitleCase } from "src/utils";

type Props = {
  type?: string,
  isSelect?: boolean,
  disabled: boolean,
  handleClick: Function,
  isMandatory?: boolean
};

const AddData = ({
  type = "",
  handleClick,
  isSelect = false,
  disabled,
  isMandatory
}: Props) => {
  const fieldNames = useTranslatedFieldNames();

  if (isSelect)
    return (
      <AddButton
        data-cy="addButtonSelect"
        onClick={handleClick}
        disabled={disabled}
        isMandatory={isMandatory}
      >
        {i18n.t(k.SELECT)}
      </AddButton>
    );

  return (
    <AddButton
      data-cy={`${i18n.t(k.ADDBUTTON)}${convertToTitleCase(type ?? "") ?? ""}`}
      onClick={handleClick}
      disabled={disabled}
      isMandatory={isMandatory}
    >
      {isSelect
        ? i18n.t(k.SELECT)
        : `${i18n.t(k.U_B_ADD)} ${fieldNames[type] || ""}`}
    </AddButton>
  );
};

export default AddData;
