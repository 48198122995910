// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React, { useRef } from "react";

import Item from "src/components/Manage/Reports/Instances/Chart/ChartList/Title";
import {
  Dropdown as StyledDropdown,
  ListItem
} from "src/components/Manage/Reports/Instances/Chart/New/FieldSelect/styles";
import useClickoutside from "src/hooks/useClickoutside";
import useAutoPosition from "src/hooks/useAutoPosition";

type Props = {
  outerRef: any,
  handleSelect: Function,
  handleClose: Function,
  value: Array<Object>
};

const Dropdown = ({ outerRef, handleSelect, handleClose, value }: Props) => {
  const dropdownRef = useRef(null);
  useClickoutside({ outerRef, handleClose });
  const { top, bottom } = useAutoPosition({
    outerRef: dropdownRef,
    initialTop: "3em",
    initialBottom: "auto",
    topOffset: "3em",
    bottomOffset: "3em"
  });

  return (
    <StyledDropdown top={top} bottom={bottom} ref={dropdownRef}>
      <ul>
        {value.length > 0 ? (
          value.map(chart => (
            <ListItem key={chart.id} onClick={() => handleSelect(chart.id)}>
              <Item id={chart.id} />
            </ListItem>
          ))
        ) : (
          <span>{i18n.t(k.NO_CHARTS_FOUND)}</span>
        )}
      </ul>
    </StyledDropdown>
  );
};

export default Dropdown;
