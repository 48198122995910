// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React, { Component } from "react";
import onClickOutside from "react-onclickoutside";
import ReactCalendar from "react-calendar/dist/entry.nostyle";

import moment from "moment";
import {
  Calendar as StyledCalendar,
  Footer,
  ButtonPrimary,
  ButtonActive,
  ButtonWarning
} from "./styles";

import type { ReactRef } from "src/types";

type Props = {
  value: ?string,
  outerRef: any,
  handleChange: Function,
  handleClose: Function,
  dropdownRef: ReactRef,
  positionUp: ReactRef
};

type State = {
  date: ?string
};

class Calendar extends Component<Props, State> {
  state = {
    date: null
  };

  componentDidMount() {
    const { value } = this.props;
    this.setState({ date: value });
  }

  handleClickOutside = e => {
    const { outerRef, handleClose } = this.props;
    if (outerRef) {
      if (!outerRef.contains(e.target)) {
        handleClose();
      }
    }
  };

  handleChange = (date: ?string) => {
    this.setState({ date });
  };

  setDate = () => {
    const { handleChange, handleClose } = this.props;
    const { date } = this.state;
    handleChange(date);
    handleClose();
  };

  handleRemove = () => {
    const { handleChange, handleClose } = this.props;
    handleChange(null);
    handleClose();
  };

  preventDefault = (e: any) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  };

  render() {
    const { value, handleClose, dropdownRef, positionUp } = this.props;
    const { date } = this.state;
    return (
      <StyledCalendar
        onClick={this.preventDefault}
        onKeyPress={this.preventDefault}
        ref={dropdownRef}
        positionUp={positionUp}
      >
        <ReactCalendar
          formatMonthYear={(locale, year) => moment(year).format("MMM YY")}
          formatMonth={(locale, month) => moment(month).format("MMM")}
          formatShortWeekday={(locale, day) => moment(day).format("dd")}
          onChange={this.handleChange}
          value={date}
        />

        <Footer>
          {value ? (
            <ButtonWarning type="button" onClick={this.handleRemove}>
              {i18n.t(k.REMOVE)}
            </ButtonWarning>
          ) : (
            <span />
          )}

          <div>
            <ButtonPrimary type="button" onClick={handleClose}>
              {i18n.t(k.CANCEL)}
            </ButtonPrimary>
            <ButtonActive
              data-cy="setDueDate"
              type="button"
              onClick={this.setDate}
            >
              {i18n.t(k.DONE)}
            </ButtonActive>
          </div>
        </Footer>
      </StyledCalendar>
    );
  }
}

export default onClickOutside(Calendar);
