// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React, { useCallback } from "react";

import Tooltip from "src/components/Tooltip";
import { Text, Flex } from "@chakra-ui/react";
import AttachmentIcon from "src/icons/Attach";

type Props = {
  count: number,
  showDock: Function,
  showPanel: boolean,
  isSrw?: boolean,
  showSrwDock: Function
};

const Files = ({
  count,
  showDock,
  isSrw = false,
  showSrwDock,
  showPanel
}: Props) => {
  const showFiles = useCallback(() => {
    if (showPanel) {
      if (isSrw) showSrwDock("files");
      else showDock("files");
    }
  }, [showPanel, isSrw]);

  if (count !== 0) {
    return (
      <Tooltip title={i18n.t(k.FILES_TOOLTIP_TITLE)}>
        <Flex
          cursor="pointer"
          tabIndex="0"
          role="button"
          aria-label={`${i18n.t(k.FILES_IN_CHATROOM)} ${count}`}
          onClick={showFiles}
          onKeyPress={showFiles}
          showPanel={showPanel}
        >
          <AttachmentIcon h={4} w={5} />
          <Text my={0} ml={1} p={0}>
            {count}
          </Text>
        </Flex>
      </Tooltip>
    );
  }
  return null;
};

export default Files;
