// @flow

import React from "react";
import { useSelector } from "react-redux";
import * as R from "ramda";

import { getBulkUpdateStatus } from "src/reducers";
import { dataStages } from "src/constants";
import { Loader as StyledLoader, LoadingCell, UpdateLoader } from "./styles";
import SpinnerLoader from "src/components/Loader/SpinnerLoader";
import PulseLoader from "src/components/PulseLoader";

type Props = {
  rowSize?: number,
  loading: boolean,
  columnSize: string,
  numberOfColumns: number
};

const Loader = ({
  loading,
  rowSize = 8,
  columnSize,
  numberOfColumns
}: Props) => {
  const bulkUpdateStatus = useSelector(({ app }) => getBulkUpdateStatus(app));

  if (loading) {
    return (
      <StyledLoader columnSize={columnSize}>
        {R.times(R.identity, rowSize || 8).map(row => (
          <div key={row}>
            {R.times(R.identity, numberOfColumns).map(id => (
              <LoadingCell key={id} index={id}>
                <PulseLoader w="92%" h="22px" />
              </LoadingCell>
            ))}
          </div>
        ))}
      </StyledLoader>
    );
  }

  if (bulkUpdateStatus === dataStages.updating) {
    return (
      <UpdateLoader>
        <div>
          <SpinnerLoader />
          <h1>Updating fields</h1>
        </div>
      </UpdateLoader>
    );
  }

  return <></>;
};

export default Loader;
