// @flow

import type { ChecklistFieldTypes } from "src/types";

export type ChecklistFieldsKeys =
  | "file"
  | "pdf"
  | "text"
  | "richtext"
  | "number"
  | "date"
  | "select"
  | "user"
  | "task"
  | "decision"
  | "group"
  | "workflow"
  | "conversation"
  | "chatPicklist"
  | "childConversation"
  | "form"
  | "approval"
  | "revision"
  | "link"
  | "subSection"
  | "section"
  | "chatPickList";

export const checklistFields: {
  [ChecklistFieldsKeys]: ChecklistFieldTypes
} = {
  file: "file",
  pdf: "pdf",
  text: "text",
  richtext: "richtext",
  number: "number",
  date: "date",
  select: "select",
  user: "user",
  task: "task",
  decision: "decision",
  group: "group",
  workflow: "workflow",
  conversation: "conversation",
  chatPicklist: "chatPicklist",
  childConversation: "childConversation",
  form: "form",
  approval: "approval",
  revision: "revision",
  link: "link",
  subSection: "subSection",
  section: "section",
  chatPickList: "chatPickList"
};

// Size limit for the rich text field payload (HTML + plain text)
export const RICH_TEXT_BLOB_SIZE_LIMIT = 512000;
