// @flow

import i18n from "i18next";
import k from "src/i18n/keys";
import React, { useCallback } from "react";
import { connect } from "react-redux";

import { setGroupModalAttributes, closeGroupModal } from "src/actions/group";
import SearchHeader from "src/components/SearchHeader.old";
import NewGroup from "src/components/user/Contacts/Groups/New";

import type { AppState, UID } from "src/types";

type Props = {
  text: string,
  show: boolean,
  selectedRows: Array<UID>,
  handleSearch: Function,
  toggleAddContact: Function,
  _setGroupModalAttributes: Function,
  _closeGroupModal: Function
};

const Header = ({
  text,
  show,
  handleSearch,
  selectedRows,
  toggleAddContact,
  _setGroupModalAttributes,
  _closeGroupModal
}: Props) => {
  const createGroup = useCallback(() => {
    _setGroupModalAttributes({
      members: selectedRows,
      show: true
    });
  }, [_setGroupModalAttributes, selectedRows]);

  return (
    <>
      <SearchHeader
        text={text}
        handleChange={handleSearch}
        handleCreate={toggleAddContact}
        button={i18n.t(k.ADD_CONTACTS)}
        secondary={selectedRows.length > 0}
        secondaryPlaceholder={i18n.t(k.ADD_TO_GROUP)}
        handleSecondaryAction={createGroup}
      />

      {show ? <NewGroup handleClose={_closeGroupModal} /> : null}
    </>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  show: app.groups.modal.show,
  selectedRows: app.users.selectedRows
});

export default connect(mapStateToProps, {
  _setGroupModalAttributes: setGroupModalAttributes,
  _closeGroupModal: closeGroupModal
})(Header);
