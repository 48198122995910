import i18n from "i18next";
import k from "src/i18n/keys";

import { useSelector } from "react-redux";
import React, { useState, useEffect } from "react";

import { getCurrentRoomAccess } from "src/reducers";
import { currentRoomAccessStatuses } from "src/reducers/chatRooms";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button
} from "@chakra-ui/react";

const RestrictedModal = () => {
  const currentRoomRestricted = useSelector(
    ({ app }) =>
      getCurrentRoomAccess(app) === currentRoomAccessStatuses.restricted
  );
  const currentRoomFailedToLoad = useSelector(
    ({ app }) =>
      getCurrentRoomAccess(app) === currentRoomAccessStatuses.failedToLoad
  );

  const [restrictedModalShown, setRestrictedModalShown] = useState(
    currentRoomRestricted
  );
  const [failedToLoadModalShown, setFailedToLoadModal] = useState(
    currentRoomFailedToLoad
  );

  useEffect(() => {
    setRestrictedModalShown(currentRoomRestricted);
  }, [currentRoomRestricted]);

  const closeRestrictedModal = () => setRestrictedModalShown(false);

  useEffect(() => {
    setFailedToLoadModal(currentRoomFailedToLoad);
  }, [currentRoomFailedToLoad]);

  const closeFaieldToLoadModal = () => setFailedToLoadModal(false);

  return (
    <>
      <Modal isOpen={restrictedModalShown} onClose={closeRestrictedModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{i18n.t(k.RESTRICTED_ACCESS)}</ModalHeader>
          <ModalCloseButton onClick={closeRestrictedModal} />
          <ModalBody>{i18n.t(k.THE_CONVERSATION_YOU_ARE_TRYIN)}</ModalBody>

          <ModalFooter>
            <Button variant="uBlueFilled" onClick={closeRestrictedModal}>
              {i18n.t(k.CLOSE1)}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={failedToLoadModalShown} onClose={closeFaieldToLoadModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{i18n.t(k.FAILED_TO_LOAD_THE_CHATROOM)}</ModalHeader>
          <ModalCloseButton onClick={closeRestrictedModal} />
          <ModalBody>{i18n.t(k.RESTRICTED_ROOM_MODAL_MESSAGE)}</ModalBody>

          <ModalFooter>
            <Button variant="uBlueFilled" onClick={closeFaieldToLoadModal}>
              {i18n.t(k.CLOSE1)}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default RestrictedModal;
