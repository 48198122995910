import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";
import { Heading } from "@chakra-ui/react";

const Title = () => {
  return (
    <Heading
      as="h2"
      fontSize="24px"
      ml={4}
      sx={{
        lineHeight: 1.2,
        fontWeight: 700,
        letterSpacing: 0
      }}
    >
      {i18n.t(k.ROLE_MANAGEMENT1)}
    </Heading>
  );
};

export default Title;
