// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import { connect } from "react-redux";
import React, { useState } from "react";
import Field from "../NestedField";

import {
  getFormChecklistFields,
  getFormMandatoryFieldCount,
  getAllFormTemplates
} from "src/reducers";
import {
  SelectionSingle as StyledSelectionSingle,
  Heading,
  Preview,
  Address,
  RemoveButton
} from "./styles";
import Modal from "./Modal";
import Icons from "src/icons";
import FieldName from "src/containers/checklist/FieldName";
import PulseLoader from "src/components/PulseLoader";
import SubSection from "../SubSection";
import { RedBadge } from "src/styles/badge";

import type { AppState, RoomId } from "src/types";

type Props = {
  fetching: boolean,
  index: number,
  formTemplates: Object,
  value: Array<number>,
  checklistId: number,
  roomId: RoomId,
  fields: Array<number | Object>,
  disabled: boolean,
  handleRemove: Function,
  formMandatoryFieldCount: number
};

const SelectionSingle = ({
  fetching,
  index,
  fields,
  value,
  checklistId,
  roomId,
  handleRemove,
  formTemplates,
  disabled,
  formMandatoryFieldCount
}: Props) => {
  const [expand, setExpand] = useState(false);
  const [modal, setModal] = useState(false);
  const [formTitle, setFormTitle] = useState("");
  const formId = value[index];

  const formTemplate = formTemplates[`${value[index]}`];

  const handleRemovePrompt = () => {
    setModal(true);
    setFormTitle(formTemplate?.templateTitle);
  };

  const handleCloseModal = () => {
    setModal(false);
    setFormTitle("");
  };

  if (fetching) {
    return (
      <StyledSelectionSingle>
        <PulseLoader w="100%" h="8px" />
        <PulseLoader w="80%" h="8px" />
        <PulseLoader w="60%" h="8px" />
      </StyledSelectionSingle>
    );
  }

  return (
    <StyledSelectionSingle color={formTemplate?.color}>
      {modal && (
        <Modal
          title={formTitle}
          handleClose={handleCloseModal}
          handleRemove={() => handleRemove(formId)}
        />
      )}
      <Heading color={formTemplate?.color}>
        <h4 onClick={() => setExpand(!expand)}>
          <span>
            {formTemplate?.templateTitle}
            <Address>
              {i18n.t(k._1)}
              {formTemplate?.address}
              {i18n.t(k._2)}
            </Address>
          </span>
          {formMandatoryFieldCount ? (
            <RedBadge>{formMandatoryFieldCount}</RedBadge>
          ) : null}
          <Icons type={expand ? "up" : "down"} />
        </h4>

        {!disabled && (
          <RemoveButton
            data-cy="removeFormButton"
            type="button"
            onClick={handleRemovePrompt}
          >
            <Icons type="decrement" />
          </RemoveButton>
        )}
      </Heading>
      {expand && (
        <>
          {fields.map(field => (
            <>
              {typeof field === "object" ? (
                <SubSection
                  fields={field.fields}
                  id={field.sectionId}
                  checklistId={checklistId}
                  isSectionField={true}
                  roomId={roomId}
                  formId={formId}
                />
              ) : (
                <Field
                  key={field}
                  id={field}
                  checklistId={checklistId}
                  formId={formId}
                  roomId={roomId}
                />
              )}
            </>
          ))}
        </>
      )}
      {!expand && (
        <Preview>
          {fields.map((fieldId: number, index: number) => (
            <span key={fieldId}>
              <FieldName id={fieldId} />
              {index === fields.length - 1 ? "" : ", "}
            </span>
          ))}
        </Preview>
      )}
    </StyledSelectionSingle>
  );
};

const mapStateToProps = (
  { app }: { app: AppState },
  { roomId, value, index }
) => {
  return {
    fields: getFormChecklistFields(app, `${value[index]}`),
    formTemplates: getAllFormTemplates(app),
    formMandatoryFieldCount: getFormMandatoryFieldCount(
      app,
      value[index],
      roomId
    )
  };
};

export default connect(mapStateToProps)(SelectionSingle);
