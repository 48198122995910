// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";
import * as R from "ramda";

import { Line } from "rc-progress";
import { ProgressBar as StyledProgressBar } from "./styles";
import * as colors from "src/styles/constants/colors";
import InlineSVG from "src/components/InlineSVG";

import type { FileUploadState, RoomId } from "src/types";

type Props = {
  fileUploadState: FileUploadState,
  clearFileUploadState: Function,
  roomId: RoomId,
  onlyBar: boolean,
  isSrwMobile: boolean
};

const ProgressBar = ({
  fileUploadState,
  clearFileUploadState,
  roomId,
  onlyBar,
  isSrwMobile
}: Props) => {
  const generateColor = (progress, error) => {
    if (error) {
      return colors.danger;
    }
    if (progress <= 20) return colors.warning;
    if (progress <= 80) return colors.active;
    return colors.success;
  };

  if (R.isNil(fileUploadState)) {
    return null;
  }

  const files = R.keys(fileUploadState || {});

  return (
    <>
      {files.map(file => (
        <StyledProgressBar isSrwMobile={isSrwMobile} key={file}>
          {fileUploadState[file].error ? (
            <div>
              {i18n.t(k.FAILED_TO_UPLOAD)} <b>{file}</b>
            </div>
          ) : !onlyBar ? (
            <div>
              {i18n.t(k.UPLOADING)} <b>{file}</b>
            </div>
          ) : null}
          <div>
            <div>
              <Line
                percent={fileUploadState[file].progress}
                strokeColor={generateColor(
                  fileUploadState[file].progress,
                  fileUploadState[file].error
                )}
              />
            </div>
            {fileUploadState[file].error ? (
              <div onClick={() => clearFileUploadState(roomId, file)}>
                <InlineSVG icon="close" />
              </div>
            ) : (
              <b>
                {isNaN(fileUploadState[file].progress)
                  ? 0
                  : Number(fileUploadState[file].progress).toFixed(2)}
                %
              </b>
            )}
          </div>
        </StyledProgressBar>
      ))}
    </>
  );
};

export default ProgressBar;
