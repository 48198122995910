// @flow

import i18n from "i18next";
import k from "src/i18n/keys";
import { connect, useDispatch } from "react-redux";
import React, { useCallback, useState } from "react";

import Chart from "./Chart";
import { Alert } from "@unifize/sarah";
import useFetchChartJSON from "src/hooks/useFetchChartJSON";

import { deleteChartFromDashboard } from "src/actions/dashboard";

import type { AppState } from "src/types";

type Props = {
  charts: Array<any>,
  dashboard: number,
  _setChartLoadingState: (chartIds: Array<number>) => void
};

const Display = ({ charts, dashboard }: Props) => {
  const dispatch = useDispatch();
  // Fetch chart data and update the redux state
  useFetchChartJSON({ charts });
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedChartId, setSelectedChartId] = useState<number | null>(null);

  const openDeleteModal = useCallback((chartId: number) => {
    setIsDeleteModalOpen(true);
    setSelectedChartId(chartId);
  }, []);

  const closeDeleteModal = useCallback(() => {
    setIsDeleteModalOpen(false);
    setSelectedChartId(null);
  }, []);

  const deleteModal = useCallback(() => {
    if (selectedChartId) {
      dispatch(
        deleteChartFromDashboard({
          chart: selectedChartId,
          dashboard
        })
      );
    }
    setSelectedChartId(null);
    setIsDeleteModalOpen(false);
  }, [selectedChartId]);

  return (
    <>
      <Alert
        isOpen={isDeleteModalOpen}
        onClose={closeDeleteModal}
        prompt={i18n.t(k.ARE_YOU_SURE_YOU_WANT_TO_REMOVE_CHART)}
        confirmVariant="negative"
        onConfirm={deleteModal}
      />
      {(charts || []).map(chart => (
        <Chart
          chartId={chart.id}
          dashboard={dashboard}
          key={chart.id}
          title={chart.title}
          description={chart.description}
          reportId={chart.reportId}
          x={chart.x}
          y={chart.y}
          yAxisLabel={chart.yAxisLabel}
          typeId={chart.type}
          openDeleteModal={openDeleteModal}
        />
      ))}
    </>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  charts: app.dashboard.metaData.charts,
  dashboard: app.dashboard.metaData.id
});

export default connect(mapStateToProps)(Display);
