// @flow
import i18n from "i18next";
import k from "src/i18n/keys";
import React from "react";
import { ApprovalStatusContainer, ApprovalStatusTextWrapper } from "./styles";
import { statusDisplayText } from "src/constants/approvalStatus";
type Props = {
  status: string
};

const ApprovalStatus = ({ status }: Props) => {
  return status ? (
    <ApprovalStatusContainer status={status}>
      <ApprovalStatusTextWrapper>
        {statusDisplayText[status]}
      </ApprovalStatusTextWrapper>
    </ApprovalStatusContainer>
  ) : (
    <>{i18n.t(k._24)}</>
  );
};

export default ApprovalStatus;
