// @flow

import * as atypes from "src/constants/actionTypes";

import type { Action } from "src/types";

const view = (state: string | null = "My Inbox", { type, payload }: Action) => {
  switch (type) {
    case atypes.SHOW_VIEW:
      return payload.view;
    case atypes.TOGGLE_VIEW:
      return state === null || state !== payload.view ? payload.view : null;
    case atypes.HIDE_VIEW:
      return null;
    case atypes.SET_PROCESS_REQUEST:
    case atypes.SET_REPORTS_REQUEST:
    case atypes.SET_USER_ANALYTICS_REQUEST:
    case atypes.SET_FORMS_REQUEST:
      return "manage";
    case atypes.SET_CONTACTS_REQUEST:
    case atypes.SET_GROUPS_REQUEST:
      return "contacts";
    case atypes.SET_DASHBOARD_REQUEST:
      return "dashboard";
    case atypes.SET_HOME_SCREEN_REQUEST:
      return "homescreen";
    case atypes.SET_ORG_SETTINGS_REQUEST:
      return "orgSettings";
    default:
      return state;
  }
};

export default view;
