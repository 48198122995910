// @flow

import * as atypes from "src/constants/actionTypes";

import type { Action, UserSearchOptions } from "src/types";

export const signInRequired = (): Action => ({
  type: atypes.SIGN_IN_REQUIRED,
  payload: {}
});

export const newMessage =
  (payload: Object) => (dispatch: Function, getState: Function) => {
    dispatch({
      type: atypes.NEW_MESSAGE,
      payload
    });

    const { roomId } = payload.entities.message[payload.result];
    const { currentChatRoom } = getState().app;

    // Suppress events in the current chat room or by the current user;
    if (roomId != currentChatRoom) {
      dispatch({
        type: atypes.INCREMENT_MESSAGE_COUNT,
        payload: { roomId }
      });
    }
  };

export const setProfileImage = (image: File) => ({
  type: atypes.UPLOAD_PROFILE_PICTURE_FILE,
  payload: {
    image
  }
});

export const logout = (shouldPurge: boolean = true) => ({
  type: atypes.LOGOUT_REQUEST,
  payload: {
    shouldPurge
  }
});

export const searchUsers = (
  searchString: string,
  settings: ?UserSearchOptions
) => ({
  type: atypes.SEARCH_USERS_REQUEST,
  payload: { searchString, settings }
});

export const clearUserSearchResults = () => ({
  type: atypes.CLEAR_USERS_SEARCH_RESULTS,
  payload: {}
});

export const clearCommentsFilter = () => ({
  type: atypes.CLEAR_MESSAGE_FILTER
});

export const clearAllErrors = () => ({
  type: atypes.CLEAR_ALL_ERRORS
});

export const setError = (type: string, error: string) => ({
  type: atypes.SET_ERROR,
  payload: {
    type,
    error
  }
});

export const clearError = (type: string) => ({
  type: atypes.CLEAR_ERROR,
  payload: {
    type
  }
});

export const filterRecentChat = (messages: Array<string>) => ({
  type: atypes.SET_RECENT_CHAT_FILTER,
  payload: {
    messages
  }
});

export const toggleRecentChats = (messages: Array<string>, id: string) => ({
  type: atypes.TOGGLE_RECENT_CHAT_FILTER,
  payload: {
    messages,
    id
  }
});

export const clearRecentChat = (messages: Array<string>) => ({
  type: atypes.CLEAR_RECENT_CHAT_FILTER,
  payload: {
    messages
  }
});

export const showOverlay = () => ({
  type: atypes.SHOW_OVERLAY,
  payload: {}
});

export const hideOverlay = () => ({
  type: atypes.HIDE_OVERLAY,
  payload: {}
});

export const toggleView = (view: string): Action => ({
  type: atypes.TOGGLE_VIEW,
  payload: {
    view
  }
});

export const showView = (view: string): Action => ({
  type: atypes.SHOW_VIEW,
  payload: {
    view
  }
});

export const hideView = (): Action => ({
  type: atypes.HIDE_VIEW,
  payload: {}
});

export const rehydrateChatroom = (): Action => ({
  type: atypes.REHYDRATION_COMPLETE,
  payload: {}
});

/**
 * Update the current view, i.e., the current page.
 *
 * @param {string} view - The view to be set in the store as the current view.
 */
export const updateAppView = (view: string) => ({
  type: atypes.SHOW_VIEW,
  payload: {
    view
  }
});
