// @flow

import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import * as R from "ramda";
import { arrayMove } from "react-sortable-hoc";

import FieldName from "src/containers/checklist/FieldName";
import type { LinkedFieldSettings, AppState } from "src/types";
import {
  getPrincipalChecklist,
  searchPrincipalChecklistField
} from "src/actions/workflows";
import { getPrincipalChecklist as getPrincipalChecklistApi } from "src/api/workflow";

import {
  VStack,
  FormControl,
  FormLabel,
  Checkbox,
  RadioGroup,
  Radio,
  Select
} from "@chakra-ui/react";
import SelectProcess from "./Conversation/Workflow";
import * as styles from "./LinkedField.styles";

type Props = {
  type: string,
  settings: LinkedFieldSettings,
  saveSettings: Function,
  getPrincipalChecklist: Function,
  fieldsOfEmbeddedProcess: Array<number>,
  searchPrincipalChecklistField: Function
};

const viewOptionsLabels = {
  selectOnly: "dispOptions-select",
  selectAndCreate: "dispOptions-select-and-create",
  createOnly: "dispOptions-create",
  showOnly: "dispOptions-showOnly"
};

const getViewOptionsValue = ({ create, select }) => {
  if (create && select) return viewOptionsLabels.selectAndCreate;
  if (!create && select) return viewOptionsLabels.selectOnly;
  if (create && !select) return viewOptionsLabels.createOnly;
  return viewOptionsLabels.showOnly;
};

const getCreateAndSelect = {
  [viewOptionsLabels.selectOnly]: { select: true, create: false },

  [viewOptionsLabels.selectAndCreate]: { select: true, create: true },

  [viewOptionsLabels.createOnly]: { select: false, create: true },

  [viewOptionsLabels.showOnly]: { select: false, create: false }
};

function LinkedField({
  settings,
  saveSettings,
  getPrincipalChecklist,
  fieldsOfEmbeddedProcess,
  searchPrincipalChecklistField
}: Props) {
  const [workflow, setWorkflow] = useState(null);
  const [fields, setFields] = useState([]);
  const [showCompressedPreview, setShowCompressedPreview] = useState(true);
  const [showMetaData, setShowMetaData] = useState(false);
  const [disableTitleHyperlink, setDisableTitleHyperlink] = useState(false);
  const [multiple, setMultiple] = useState(false);
  const [child, setChild] = useState(false);
  const [viewOptions, setViewOptions] = useState(viewOptionsLabels.showOnly);
  const [autoFillRelated, setAutoFillRelated] = useState(false);
  const [alwaysAllowManualLinks, setAlwaysAllowManualLinks] = useState(false);
  const [selectExistingBy, setSelectExistingBy] = useState(null);
  const [showArchived, setShowArchived] = useState(false);

  const [embeddedFields, setEmbeddedFields] = useState([]);

  useEffect(() => {
    setWorkflow(settings.workflow || null);
    setFields(settings.fields || []);
    setShowCompressedPreview(settings.showCompressedPreview || true);
    setShowMetaData(settings.showMetaData || false);
    setDisableTitleHyperlink(
      R.has("disableTitleHyperlink", settings)
        ? settings.disableTitleHyperlink
        : false
    );
    setMultiple(settings.multiple || false);
    setChild(settings.child || false);
    setViewOptions(
      getViewOptionsValue({
        create: settings.create,
        select: settings.select
      })
    );
    setAutoFillRelated(settings.autoFillRelated || false);
    setSelectExistingBy(settings.selectExistingBy || "");

    if (settings.workflow) {
      getPrincipalChecklistApi({ workflow: settings.workflow })
        .then(response => {
          setEmbeddedFields(response.fields || []);
        })
        .catch(err => {
          console.error("failed to fetch checklist fields: ", err);
        });
    }

    setAlwaysAllowManualLinks(settings.alwaysAllowManualLinks || false);
    setShowArchived(settings?.showArchived ?? false);
  }, []);

  useEffect(() => {
    const { create, select } = getCreateAndSelect[viewOptions];

    saveSettings({
      disableTitleHyperlink,
      showCompressedPreview,
      showMetaData,
      create,
      select,
      type: "workflow",
      workflow,
      fields,
      multiple,
      child,
      autoFillRelated,
      selectExistingBy,
      alwaysAllowManualLinks,
      showArchived
    });
  }, [
    disableTitleHyperlink,
    showCompressedPreview,
    showMetaData,
    viewOptions,
    workflow,
    fields,
    multiple,
    child,
    autoFillRelated,
    selectExistingBy,
    alwaysAllowManualLinks,
    showArchived
  ]);

  const handleTemplate = useCallback(
    (newWorkflow: Object) => {
      if (newWorkflow) {
        setWorkflow(newWorkflow.id);
        getPrincipalChecklistApi({ workflow: newWorkflow.id })
          .then(response => {
            setEmbeddedFields(response.fields || []);
          })
          .catch(err => {
            console.error("Failed to fetch checklist fields: ", err);
          });
      } else {
        setWorkflow(null);
      }
      // Clear the attached embedded fields
      setFields([]);
    },
    [setWorkflow, setFields, getPrincipalChecklist]
  );

  const addField = useCallback(() => {
    // $FlowFixMe
    setFields([...fields, null]);
  }, [fields, setFields]);

  const handleField = useCallback(
    ({ fieldId, index }: { fieldId: ?number, index: number }) => {
      if (fieldId) {
        setFields(prevFields => R.update(index, fieldId, prevFields));
      }
    },
    [fields, setFields]
  );

  const removeField = useCallback(
    (field: number) => {
      if (field) {
        setFields(R.reject(R.equals(field), fields));
      } else if (field === null) {
        setFields(fields.filter(f => f !== null)); // NOTE This will remove all empty fields
      }
    },
    [fields, setFields]
  );

  const onSortFields = ({ oldIndex, newIndex }) => {
    setFields(prevFields => arrayMove(prevFields, oldIndex, newIndex));
  };

  return (
    <VStack sx={styles.overallOptionsList} spacing={0}>
      <SelectProcess
        workflow={workflow}
        handleTemplate={handleTemplate}
        addField={addField}
        fields={fields}
        handleField={handleField}
        removeField={removeField}
        onSortFields={onSortFields}
        checklistFields={embeddedFields}
      />

      <FormControl>
        <FormLabel sx={styles.formLabel}>Consumption options:</FormLabel>
        <VStack sx={styles.consumptionOptionsList}>
          <Checkbox
            data-cy="allowMultipleCheckbox"
            size="sm"
            isChecked={multiple}
            onChange={e => setMultiple(e.target.checked)}
          >
            Allow multiple
          </Checkbox>

          <Checkbox
            isChecked={autoFillRelated}
            onChange={e => setAutoFillRelated(e.target.checked)}
            size="sm"
          >
            Auto fill related conversations
          </Checkbox>
          <Checkbox
            isChecked={showArchived}
            onChange={e => setShowArchived(e.target.checked)}
            size="sm"
          >
            Allow archived conversations
          </Checkbox>
        </VStack>
      </FormControl>

      <FormControl>
        <FormLabel sx={styles.formLabel}>Preview options:</FormLabel>
        <VStack alignItems="start">
          <Checkbox
            size="sm"
            isChecked={showCompressedPreview}
            onChange={e => setShowCompressedPreview(e.target.checked)}
          >
            Show compressed preview
          </Checkbox>
          <Checkbox
            size="sm"
            isChecked={showMetaData}
            onChange={e => setShowMetaData(e.target.checked)}
          >
            Show status, owner and due date
          </Checkbox>
          <Checkbox
            size="sm"
            isChecked={disableTitleHyperlink}
            onChange={e => setDisableTitleHyperlink(e.target.checked)}
          >
            Suppress title hyperlink to the record
          </Checkbox>
        </VStack>
      </FormControl>

      <FormControl>
        <FormLabel sx={styles.formLabel}>Linked field relation:</FormLabel>
        <RadioGroup
          value={child ? "child" : "parent"}
          onChange={value => setChild(value === "child")}
        >
          <VStack alignItems="start">
            <Radio size="sm" value="parent">
              Parent
            </Radio>
            <Radio size="sm" value="child">
              Child
            </Radio>
          </VStack>
        </RadioGroup>
      </FormControl>

      <FormControl>
        <FormLabel sx={styles.formLabel}>Other options:</FormLabel>
        <RadioGroup value={viewOptions} onChange={setViewOptions}>
          <VStack alignItems="start">
            <Radio size="sm" value={viewOptionsLabels.selectOnly}>
              Allow only selecting
            </Radio>
            <Radio size="sm" value={viewOptionsLabels.selectAndCreate}>
              Allow selecting and creating
            </Radio>
            <Radio size="sm" value={viewOptionsLabels.createOnly}>
              Allow only creating
            </Radio>
            <Radio size="sm" value={viewOptionsLabels.showOnly}>
              Only show linked fields
            </Radio>
          </VStack>
        </RadioGroup>
      </FormControl>

      <FormControl>
        <FormLabel sx={styles.formLabel}>Revisions:</FormLabel>
        <Checkbox
          size="sm"
          isChecked={alwaysAllowManualLinks}
          onChange={e => setAlwaysAllowManualLinks(e.target.checked)}
        >
          Allow manual revision linking even when <u>there are no revisions</u>{" "}
          {"created"}
        </Checkbox>
      </FormControl>

      <FormControl sx={styles.formLabel}>
        <FormLabel>Select existing by</FormLabel>
        <Select
          size="sm"
          placeholder="-"
          w={48}
          value={selectExistingBy}
          onChange={e => setSelectExistingBy(e.target.value)}
          onClick={() => searchPrincipalChecklistField("")}
        >
          <option value="title">Title</option>
          {fieldsOfEmbeddedProcess.map(field => (
            <FieldName id={field} as="option" key={field} />
          ))}
        </Select>
      </FormControl>
    </VStack>
  );
}

const mapDispatchToProps = {
  getPrincipalChecklist,
  searchPrincipalChecklistField
};

const mapStateToProps = ({ app }: { app: AppState }) => {
  return {
    fieldsOfEmbeddedProcess: app.workflow.principalChecklistSearchResult
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LinkedField);
