// @flow
import i18n from "i18next";
import k from "src/i18n/keys";
import React from "react";
import { connect } from "react-redux";

import { EmailSent as StyledSent, VerifiedStatus, ResendInfo } from "./styles";
import Icon from "src/icons";
import { srwSignUp } from "src/actions/signup";
import useKeyDownHandlers from "src/hooks/useKeyDownHandlers";

type Props = {
  _srwSignUp: Function
};

const EmailReceived = ({ _srwSignUp }: Props) => {
  const { handleEnter } = useKeyDownHandlers(_srwSignUp);

  return (
    <StyledSent>
      <VerifiedStatus>
        <Icon type="verificationFailed" />
        {i18n.t(k.SIGN_UP_FAILED)}
      </VerifiedStatus>
      <ResendInfo>
        {i18n.t(k.CLICK_HERE_TO)}{" "}
        <span
          role="button"
          tabIndex="0"
          onClick={_srwSignUp}
          onKeyDown={handleEnter}
        >
          {i18n.t(k.RESEND1)}
        </span>
      </ResendInfo>
    </StyledSent>
  );
};

export default connect(null, {
  _srwSignUp: srwSignUp
})(EmailReceived);
