// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";
import moment from "moment";

import {
  FilterItem as StyledFilterItem,
  CloseButton
} from "src/components/Manage/Workflow/Instances/styles";
import { formColumnNames } from "src/constants/display";
import * as colors from "src/styles/constants/colors";
import Icon from "src/icons";

type Props = {
  value: any,
  column: string,
  handleClose: Function
};

const Date = ({ value, column, handleClose }: Props) => {
  const dates = (value || "").split(":").filter(d => d !== "");
  const start = dates.length > 0 ? dates[0] : null;
  const end = dates.length === 2 ? dates[1] : null;

  return (
    <StyledFilterItem>
      <span>
        {formColumnNames[column]}
        {i18n.t(k._)}
      </span>
      <strong>
        {start ? moment(start).format(i18n.t(k.MMM_DD_YYYY)) : ""}
      </strong>

      <strong>{start && end ? i18n.t(k.TO) : ""}</strong>

      <strong>{end ? moment(end).format(i18n.t(k.MMM_DD_YYYY)) : ""}</strong>
      <CloseButton onClick={handleClose}>
        <Icon type="close" color={colors.active} />
      </CloseButton>
    </StyledFilterItem>
  );
};

export default Date;
