import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";
import { Text, Link } from "@chakra-ui/react";

const AccessDenied = () => {
  return (
    <>
      <Text fontSize="3xl" as="b">
        {i18n.t(k.ACCESS_DENIED)}
      </Text>

      <Link href="https://www.unifize.com/about-us/#contact-us" target="_blank">
        {i18n.t(k.CONTACT_US)}
      </Link>
    </>
  );
};

export default AccessDenied;
