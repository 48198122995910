// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";
import * as R from "ramda";

import { FormHeading } from "./styles";
import ChecklistContent from "./Content";
import FormName from "src/components/Form/Name";

type Props = {
  forms: Object,
  timestamp: string
};

const Forms = ({ forms, timestamp }: Props) => {
  const formIds = R.keys(forms);

  return (
    <>
      {formIds.map(formId => {
        const form = forms[formId];
        return (
          <div key={formId}>
            <FormHeading>
              <FormName id={form.templateId} />
              {"    "}
              {form.address && (
                <>
                  {i18n.t(k._1)}
                  {form.address}
                  {i18n.t(k._2)}
                </>
              )}
            </FormHeading>
            <ChecklistContent timestamp={timestamp} data={form} />
          </div>
        );
      })}
    </>
  );
};

export default Forms;
