// @flow

import { connect } from "react-redux";
import React, { useRef } from "react";

import {
  LabelWrapper,
  NumberLabelWrapper,
  PicklistLabelWrapper,
  LabelIconWrapper
} from "src/components/Manage/Workflow/Instances/Table/styles";
import { ChecklistItem as StyledHeaderItem } from "./styles";
import SortAndFilter from "./Filter/SortAndFilter";
import User from "./Filter/User";
import Chatroom from "./Filter/Chatroom";
import Select from "./Filter/Select";
import useBoolean from "src/hooks/useBoolean";
import { getInstanceFilterId } from "src/reducers";
import Icon from "src/icons";
import Tooltip from "src/components/Tooltip";

import type { AppState } from "src/types";

type Props = {
  column: number,
  label: string,
  type: string
};

const HeaderItem = ({ column, label, type }: Props) => {
  const parentRef = useRef(null);
  const { value: dropdown, toggleBoolean: toggleDropdown } = useBoolean();

  switch (type) {
    case "user":
      return (
        <StyledHeaderItem onClick={toggleDropdown} ref={parentRef}>
          <Tooltip align="down" title={label}>
            <LabelIconWrapper>
              <LabelWrapper>{label}</LabelWrapper>
              <Icon type="triangleDown" />
            </LabelIconWrapper>
          </Tooltip>

          {dropdown && (
            <User
              column={`${column}`}
              handleClose={toggleDropdown}
              parentRef={parentRef}
            />
          )}
        </StyledHeaderItem>
      );

    case "conversation":
    case "chatPickList":
    case "workflow":
    case "task":
    case "group":
    case "childConversation":
      return (
        <StyledHeaderItem onClick={toggleDropdown} ref={parentRef}>
          <Tooltip align="down" title={label}>
            <LabelIconWrapper>
              <LabelWrapper>{label}</LabelWrapper>
              <Icon type="triangleDown" />
            </LabelIconWrapper>
          </Tooltip>
          {dropdown && (
            <Chatroom
              column={`${column}`}
              handleClose={toggleDropdown}
              parentRef={parentRef}
            />
          )}
        </StyledHeaderItem>
      );

    case "select":
      return (
        <StyledHeaderItem onClick={toggleDropdown} ref={parentRef}>
          <Tooltip align="down" title={label}>
            <LabelIconWrapper>
              <PicklistLabelWrapper>{label}</PicklistLabelWrapper>
              <Icon type="triangleDown" />
            </LabelIconWrapper>
          </Tooltip>
          {dropdown && (
            <Select
              column={`${column}`}
              handleClose={toggleDropdown}
              parentRef={parentRef}
            />
          )}
        </StyledHeaderItem>
      );

    case "text":
    case "number":
    case "file":
      return (
        <StyledHeaderItem onClick={toggleDropdown} ref={parentRef}>
          <Tooltip align="down" title={label}>
            {type === "number" ? (
              <NumberLabelWrapper>{label}</NumberLabelWrapper>
            ) : (
              <LabelWrapper>{label}</LabelWrapper>
            )}
          </Tooltip>
          <LabelIconWrapper>
            <Icon type="triangleDown" />
          </LabelIconWrapper>
          {dropdown && (
            <SortAndFilter
              column={`${column}`}
              handleClose={toggleDropdown}
              parentRef={parentRef}
              filterShown
            />
          )}
        </StyledHeaderItem>
      );

    default:
      return (
        <StyledHeaderItem onClick={toggleDropdown} ref={parentRef}>
          <Tooltip align="down" title={label}>
            <LabelIconWrapper>
              {type === "number" ? (
                <NumberLabelWrapper>{label}</NumberLabelWrapper>
              ) : (
                <LabelWrapper>{label}</LabelWrapper>
              )}
              <Icon type="triangleDown" />
            </LabelIconWrapper>
          </Tooltip>
          {dropdown && (
            <SortAndFilter
              column={`${column}`}
              handleClose={toggleDropdown}
              parentRef={parentRef}
            />
          )}
        </StyledHeaderItem>
      );
  }
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  id: getInstanceFilterId(app)
});

export default connect(mapStateToProps, {})(HeaderItem);
