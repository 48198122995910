// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React, { useCallback, useState } from "react";
import moment from "moment";

import { Value, Select as StyledSelect, CalendarIcon } from "./styles";
import Calendar from "src/components/date/Calendar";
import Icon from "src/icons";

type Props = {
  value: ?string,
  handleChange: Function
};

const Select = ({ value, handleChange }: Props) => {
  const [dropdown, showDropdown] = useState(false);

  const handleDropdown = useCallback(() => {
    showDropdown(!dropdown);
  }, [dropdown, showDropdown]);

  return (
    <StyledSelect onClick={handleDropdown}>
      {dropdown ? (
        <Calendar
          handleChange={handleChange}
          value={value ? moment(value).toDate() : moment().toDate()}
          handleClose={handleDropdown}
        />
      ) : null}

      <Value>
        {value
          ? moment(value).format(i18n.t(k.MMM_DD_YYYY))
          : i18n.t(k.MMM_DD_YYYY)}
      </Value>

      <CalendarIcon>
        <Icon type="calendar" />
      </CalendarIcon>
    </StyledSelect>
  );
};

export default Select;
