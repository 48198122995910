// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";

import User from "./User";
import {
  Dropdown as StyledDropdown,
  Participants,
  ParticipantHeader,
  AddParticipant,
  ParticipantList
} from "./styles";
import { getSortedRoomMembersAndGroups } from "src/reducers";
import OutsideClickHandler from "src/components/OutsideClickHandler";
import { toggleMemberAddDialog } from "src/actions/chatroom";
import { getCurrentUserId, getRole } from "src/reducers";
import Group from "./Group";
import { useSelector } from "react-redux";

import type { AppState, ReactRef, RoomId } from "src/types";

type Props = {
  role: string,
  outerRef: ReactRef,
  room: RoomId,
  location: string,
  toggleParticipants: Function,
  isSrwMobile: boolean,
  addParticipant: ?string,
  _toggleMemberAddDialog: Function,
  activeDock: string
};

const Dropdown = ({
  role,
  outerRef,
  room,
  location,
  toggleParticipants,
  isSrwMobile,
  _toggleMemberAddDialog,
  addParticipant,
  activeDock
}: Props) => {
  const usersAndGroups = useSelector(({ app }) =>
    getSortedRoomMembersAndGroups(app)
  );
  const handleKeyDown = useCallback(
    (event: any) => {
      if (event.key === "Escape") {
        if (addParticipant) {
          _toggleMemberAddDialog(location);
        } else {
          toggleParticipants(location);
        }
      }
    },
    [addParticipant, location, _toggleMemberAddDialog, toggleParticipants]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown, false);
    return () => {
      document.removeEventListener("keydown", handleKeyDown, false);
    };
  }, [handleKeyDown]);

  const handleClickOutside = useCallback(
    (e: any) => {
      if (outerRef.current && !outerRef.current.contains(e.target)) {
        toggleParticipants(location);
      }
    },
    [location, outerRef]
  );

  const showAddParticipantDropdown = useCallback(
    () => _toggleMemberAddDialog(location),
    [location, _toggleMemberAddDialog]
  );

  const isInfoPanelOpen = activeDock === "info";

  if (addParticipant) return <></>;

  return (
    <OutsideClickHandler
      onClickOutside={isInfoPanelOpen ? () => {} : handleClickOutside}
    >
      <StyledDropdown isInfoPanelOpen={isInfoPanelOpen}>
        <Participants>
          <ParticipantHeader>{i18n.t(k.PARTICIPANTS1)}</ParticipantHeader>
          <ParticipantList isSrwMobile={isSrwMobile}>
            {usersAndGroups.map((userOrGroup: Object) => {
              const { id, type } = userOrGroup;
              return type === "user" ? (
                <User key={id} uid={id} room={room} />
              ) : (
                <Group key={id} id={id} room={room} />
              );
            })}
          </ParticipantList>
        </Participants>

        {role !== "demo" && (
          <AddParticipant
            onClick={showAddParticipantDropdown}
            isInfoPanelOpen={isInfoPanelOpen}
            data-cy="addParticipants"
          >
            {i18n.t(k.ADD_PARTICIPANTS_OR_GROUPS1)}
          </AddParticipant>
        )}
      </StyledDropdown>
    </OutsideClickHandler>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  isSrwMobile: app.srw.isMobile,
  addParticipant: app.memberAddDialog,
  activeDock: app.activeDock.main,
  role: getRole(app, getCurrentUserId(app))
});

export default connect(mapStateToProps, {
  _toggleMemberAddDialog: toggleMemberAddDialog
})(Dropdown);
