// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";

import { Footer as StyledFooter } from "./styles";
import { conversationTypes } from "src/constants/display";
import WorkflowName from "src/containers/workflow/Name";
import { AddButtonText } from "./styles";

type Props = {
  text: string,
  type: string,
  workflow: ?number,
  handleCreate: Function,
  handleNewRoom: Function,
  isLoading?: boolean
};

const Footer = ({
  text,
  type,
  workflow,
  handleCreate,
  handleNewRoom,
  isLoading = false
}: Props) => {
  if (isLoading) {
    return <StyledFooter>{i18n.t(k.LOADING)}</StyledFooter>;
  }

  if (text) {
    return (
      <StyledFooter
        tabIndex="0"
        type="button"
        onClick={() => handleCreate(text)}
      >
        {i18n.t(k.CREATE)}
        {"   "}
        <strong>{text}</strong>
      </StyledFooter>
    );
  }

  if (type === "workflow" && workflow) {
    return (
      <StyledFooter type="button" onClick={handleNewRoom}>
        <AddButtonText>
          {i18n.t(k.CREATE_NEW)} <WorkflowName id={workflow} />
        </AddButtonText>
      </StyledFooter>
    );
  }

  return (
    <StyledFooter tabIndex="0" onClick={handleNewRoom}>
      <AddButtonText>
        {i18n.t(k.CREATE_NEW)} {conversationTypes[type][0]}
      </AddButtonText>
    </StyledFooter>
  );
};

export default Footer;
