// @flow

import React from "react";
import { Flex, Text } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { CloseIcon } from "@chakra-ui/icons";

import * as colors from "src/styles/constants/colors";
import { getGroup } from "src/reducers";
import * as styles from "./styles";
import Icon from "src/icons";

import type { SelectOrUnselectParticipant } from "src/components/chatroom/Members/AddUser";

type Props = {
  id: number,
  unselectParticipant: SelectOrUnselectParticipant
};

const SelectedNewGroup = ({ id, unselectParticipant }: Props) => {
  const group = useSelector(({ app }) => getGroup(app, id));
  const { title, photoUrl } = group;

  return (
    <Flex sx={styles.SelectedUsersAndGroupsWrapper}>
      <Icon
        type="groupAvatar"
        text={title}
        size="xs"
        photoUrl={photoUrl}
        id={id}
      />

      <Text isTruncated sx={styles.SelectedUserAndGroupName}>
        {title}
      </Text>
      <CloseIcon
        onClick={() => unselectParticipant({ type: "group", id })}
        sx={styles.CursorPointer}
        color={colors.dark}
        boxSize={2}
      />
    </Flex>
  );
};

export default SelectedNewGroup;
