// @flow

import React, { useCallback } from "react";
import moment from "moment";
import { connect } from "react-redux";

import { FileInfo } from "../styles";

import { fetchMessageRange } from "src/actions/chatroom";
import {
  getFile as fileSelector,
  getChatroomAddress,
  getCurrentRoomId
} from "src/reducers";
import NameOrEmail from "src/containers/user/NameOrEmail";
import Timestamp from "src/components/messages/Timestamp";
import Datestamp from "src/components/messages/Datestamp";

import Icon from "src/components/InlineSVG";
import { ReplyTo } from "src/styles";
import { getFileType } from "src/utils/file";
import { getAuthorColor } from "src/utils";
import { size } from "src/styles/constants";

import type {
  AppState,
  UnifizeFile,
  FileNotification as Message,
  ChatroomAddress
} from "src/types";

type Props = {
  file: UnifizeFile,
  message: Message,
  _fetchMessageRange: Function,
  currentChatroomAddress: ChatroomAddress,
  isCurrentUser: boolean
};

const File = ({
  file,
  message,
  _fetchMessageRange,
  currentChatroomAddress,
  isCurrentUser
}: Props) => {
  const scrollToMessage = useCallback(() => {
    const { timestamp, id } = message;
    _fetchMessageRange({ timestamp, id });
  }, [message, _fetchMessageRange]);

  if (file) {
    const dateDiff = moment().diff(message.timestamp, "days");
    return (
      <div>
        <ReplyTo onClick={scrollToMessage} isCurrentUser={isCurrentUser}>
          <div>
            <div>
              <div>
                <NameOrEmail
                  uid={message.author}
                  hashColor={getAuthorColor(
                    message.author,
                    currentChatroomAddress
                  )}
                />
              </div>
              {dateDiff ? <Datestamp timestamp={message.timestamp} /> : null}
              {!dateDiff && message.timestamp ? (
                <Timestamp timestamp={message.timestamp} />
              ) : null}
            </div>
            <div>
              <FileInfo>
                <Icon
                  size={size.iconSize.large}
                  icon={getFileType(file.originalName)}
                />

                <div>
                  <b>{file.originalName}</b>
                </div>
              </FileInfo>
            </div>
          </div>
        </ReplyTo>
      </div>
    );
  }
  return null;
};

const mapStateToProps = (
  { app }: { app: AppState },
  { message }: { message: Message }
) => ({
  file: fileSelector(app, message.data.name),
  currentChatroomAddress: getChatroomAddress(app, getCurrentRoomId(app))
});

export default connect(mapStateToProps, {
  _fetchMessageRange: fetchMessageRange
})(File);
