// @flow

import React from "react";

import Button from "src/components/Unifize/Button";

const ModalButton = ({
  label,
  onClick,
  loading,
  bgColor,
  textColor,
  margin,
  disabled = false
}: {
  label: string,
  onClick: Function,
  loading: boolean,
  bgColor?: string,
  textColor?: string,
  margin?: string,
  disabled?: boolean
}) => (
  <Button
    variant="default"
    handleClick={onClick}
    bgColor={bgColor}
    color={textColor}
    width="5rem"
    alignSelf="end"
    position="static"
    margin={margin ?? "2rem 0 0 0"}
    borderRadius="0.25rem"
    isLoading={loading}
    disabled={disabled}
  >
    {label}
  </Button>
);

export default ModalButton;
