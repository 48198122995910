// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React, { Component } from "react";

import Result from "./result";
import Selection from "src/containers/workflow/selection";
import selector from "src/components/selector";
import { TextInputWithBorders as SearchInput } from "src/styles/input";
import { Select as StyledSelect } from "src/styles/dropdown";
import { onManageView } from "src/utils";
import { checkIfProcessDeleted } from "src/utils/workflow";

import type { Workflow } from "src/types";

type Props = {
  dropdown: boolean,
  autoFocus?: boolean,
  workflowId: number | null,
  focus: number,
  result: Array<number>,
  setFocus: Function,
  handleClose: Function,
  resetSearch: Function,
  handleChange: Function,
  handleSearch: Function,
  handleDropdown: Function,
  processDetails: Workflow
};

type State = {
  search: string
};

class Select extends Component<Props, State> {
  outerRef: any;
  searchRef: any;

  static defaultProps = {
    autoFocus: false
  };

  state = {
    search: i18n.t(k._4)
  };

  componentDidMount() {
    const { resetSearch } = this.props;
    resetSearch();
  }

  onKeyDown = (e: any) => {
    if (e) {
      // On Enter selecting the first user in the search result
      if (e.key === "Enter") {
        const { focus, result } = this.props;
        const value = result[focus];
        if (value) {
          this.handleSelect(value);
          e.preventDefault();
        }
      }
    }
  };

  handleSelect = (item: ?number) => {
    const { handleChange, handleClose } = this.props;
    this.setState({ search: i18n.t(k._4) });
    handleClose();
    handleChange(item);
  };

  removeHandler = (e: any) => {
    e.stopPropagation();
    this.handleSelect(null);
    if (this.searchRef) {
      this.searchRef.focus();
    }
  };

  handleSearch = (e: any) => {
    const { value, id } = e.target;
    const { dropdown, handleSearch, handleDropdown } = this.props;
    this.setState({ [id]: value });
    handleSearch(value);

    if (!dropdown) {
      handleDropdown();
    }
  };

  handleDropdown = () => {
    const { dropdown, handleDropdown } = this.props;
    if (!dropdown) {
      if (this.searchRef) {
        this.searchRef.focus();
      }
      handleDropdown();
    }
  };

  render() {
    const {
      workflowId,
      dropdown: isDropdownOpen,
      autoFocus,
      focus,
      result,
      setFocus,
      handleClose,
      processDetails
    } = this.props;
    const { search } = this.state;
    const isProcessDeleted = checkIfProcessDeleted(processDetails, workflowId);

    return (
      <StyledSelect
        ref={node => {
          this.outerRef = node;
        }}
        onClick={this.handleDropdown}
        isProcessDeleted={isProcessDeleted}
      >
        <div>
          {/* Don't show the workflow pill here if
           *   - workflow isn't attached
           *   - dropdown is open
           *   - deleted workflow is attached
           */}
          {!workflowId || isDropdownOpen || isProcessDeleted ? (
            <SearchInput
              type="text"
              id="search"
              placeholder={
                onManageView() ? "Search Process" : i18n.t(k.SEARCH_PROCESS)
              }
              autoComplete="off"
              value={search}
              onChange={this.handleSearch}
              onKeyDown={this.onKeyDown}
              ref={node => {
                this.searchRef = node;
              }}
              autoFocus={autoFocus}
            />
          ) : (
            <Selection id={workflowId} removeHandler={this.removeHandler} />
          )}
        </div>
        <div>
          {!isDropdownOpen ? null : (
            <Result
              text={search}
              focus={focus}
              result={result}
              outerRef={this.outerRef}
              setFocus={setFocus}
              handleSelect={this.handleSelect}
              handleClose={handleClose}
            />
          )}
        </div>
      </StyledSelect>
    );
  }
}

export default selector(Select);
