// @flow
import React, { useState } from "react";

import type { Ref } from "react";

import { URLModal as ModalContainer, URLInput, URLSaveButton } from "./styles";

type Props = {
  isVisible: boolean,
  onSave: (url: string, setURL: (string) => void) => void,
  modalRef: Ref<any>
};

const LinkModal = ({ isVisible, onSave, modalRef }: Props) => {
  const [url, setURL] = useState("");

  const handleSave = () => {
    onSave(url, setURL);
  };

  if (!isVisible) return null;

  return (
    <ModalContainer ref={modalRef}>
      <URLInput
        value={url}
        onChange={e => {
          setURL(e.target.value);
        }}
        type="text"
        name="url"
        id="url"
        placeholder="Enter URL"
        autoFocus
      />

      <URLSaveButton onClick={handleSave} type="button">
        Save
      </URLSaveButton>
    </ModalContainer>
  );
};

export default LinkModal;
