// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";
import {
  Flex,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Input,
  FormControl,
  FormLabel
} from "@chakra-ui/react";

import * as styles from "./styles";
import SortDropdown from "./SortDropdown";
import { handleRelativeCount, setChartSort } from "src/utils/charts.js";

type Props = {
  chartDetails: Object,
  setChartDetails: Function
};

const AdvancedSettings = ({ chartDetails, setChartDetails }: Props) => {
  const {
    pYAxisLabel,
    sYAxisLabel,
    sort: { orderBy, seriesId }
  } = chartDetails;
  // $FlowFixMe  - Flow does not yet support method or property calls in optional chains
  const relativeCount = chartDetails.series?.map(
    ({ relativeCount }) => relativeCount
  )[0];

  return (
    <Flex sx={styles.AdvancedSettingsWrapper}>
      <RadioGroup
        onChange={e => setChartSort(setChartDetails, e)}
        value={orderBy}
      >
        <Stack sx={styles.SortRadioStackWrapper} direction="column">
          <Flex sx={{ height: "2rem" }}>
            <Radio value="default">{i18n.t(k.DEFAULT1)}</Radio>
          </Flex>

          <Flex sx={styles.SortRadioWrapper}>
            <Radio value="desc">{i18n.t(k.DESCENDING)}</Radio>
            {orderBy === "desc" && (
              <SortDropdown
                series={chartDetails?.series}
                setChartDetails={setChartDetails}
                seriesId={seriesId}
              />
            )}
          </Flex>

          <Flex sx={styles.SortRadioWrapper}>
            <Radio value="asc">{i18n.t(k.ASCENDING)}</Radio>
            {orderBy === "asc" && (
              <SortDropdown
                series={chartDetails?.series}
                setChartDetails={setChartDetails}
                seriesId={seriesId}
              />
            )}
          </Flex>
        </Stack>
      </RadioGroup>

      <Flex sx={styles.FlexColumn}>
        <Text sx={styles.AdvancedSettingsLabel}>
          {i18n.t(k.MULTIPLE_VALUE_CALCULATION)}
        </Text>
        <Flex sx={styles.FlexColumn}>
          <Text sx={styles.Text}>
            {i18n.t(k.IF_A_FIELD_CONTAINS_MULTIPLE_V)}
          </Text>

          <RadioGroup
            onChange={e => handleRelativeCount(setChartDetails, e)}
            value={relativeCount}
          >
            <Stack direction="column" sx={styles.RadioStackWrapper}>
              <Radio value={true}>
                {i18n.t(k.Y_AXIS_SHOWS_TOTAL_CONVERSATIO)}
              </Radio>
              <Radio value={false}>
                {i18n.t(k.Y_AXIS_SHOWS_TOTAL_VALUE_COUNT)}
              </Radio>
            </Stack>
          </RadioGroup>
        </Flex>
      </Flex>

      <FormControl sx={styles.FormLabel}>
        <FormLabel sx={styles.Label}>
          {i18n.t(k.PRIMARY_Y_AXIS_LABEL)}
        </FormLabel>
        <Input
          sx={styles.BuilderInput}
          onChange={e =>
            setChartDetails(prev => ({ ...prev, pYAxisLabel: e.target.value }))
          }
          value={pYAxisLabel}
          placeholder={i18n.t(k.ENTER_LABEL)}
        />
      </FormControl>

      <FormControl sx={styles.FormLabel}>
        <FormLabel sx={styles.Label}>
          {i18n.t(k.SECONDARY_Y_AXIS_LABEL)}
        </FormLabel>
        <Input
          sx={styles.BuilderInput}
          onChange={e =>
            setChartDetails(prev => ({ ...prev, sYAxisLabel: e.target.value }))
          }
          value={sYAxisLabel}
          placeholder={i18n.t(k.ENTER_LABEL)}
        />
      </FormControl>
    </Flex>
  );
};

export default AdvancedSettings;
