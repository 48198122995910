// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { Box } from "@chakra-ui/react";

import { Owner } from "./styles";
import Tooltip from "src/components/Tooltip";
import SmallImageLabel from "src/containers/user/ImageLabel/Small";
import { NoValue } from "src/styles/box";
import Icon from "src/icons";
import { getUser } from "src/reducers";
import { Text } from "@chakra-ui/react";
import * as colors from "src/styles/constants/colors";
import { ChecklistFieldContext } from "src/components/Dock/Checklist/Field";

type Props = {
  value: Object | string,
  hideName?: boolean,
  location?: string,
  isActive?: boolean,
  toggleOwner?: Function
};

const Pill = ({ value, hideName, location, isActive, toggleOwner }: Props) => {
  const { fieldWidth } = useContext(ChecklistFieldContext);

  const owner = useSelector(({ app }) => getUser(app, value));
  return (
    <Tooltip
      title={
        hideName && owner?.displayName
          ? owner.displayName
          : i18n.t(k.ASSIGN_OWNER)
      }
    >
      <Owner
        tabIndex={0}
        location={location}
        onKeyPress={() => toggleOwner && toggleOwner()}
        isActive={isActive}
      >
        {value ? (
          <SmallImageLabel
            uid={value}
            noTooltip
            hideName={hideName}
            location={location}
            isActive={isActive}
          />
        ) : (
          <NoValue>
            <Box pr={2}>
              <Box
                w="fit-content"
                h="1.125rem"
                borderRadius="full"
                sx={{
                  border: isActive
                    ? `solid ${colors.active} 2px`
                    : "solid transparent 2px"
                }}
              >
                {isActive ? (
                  <Icon type="noOwnerActive" />
                ) : (
                  <Icon type="noOwner" />
                )}
              </Box>
            </Box>
            {!(fieldWidth && fieldWidth < 360) && (
              <Text isTruncated fontSize="xs" color={colors.active}>
                {i18n.t(k.ASSIGN_OWNER1)}
              </Text>
            )}
          </NoValue>
        )}
      </Owner>
    </Tooltip>
  );
};

export default Pill;
