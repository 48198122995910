// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import { connect } from "react-redux";
import React from "react";

import {
  DirectMessage as StyledDirectMessage,
  Placeholder,
  SubWrapper
} from "./styles";
import { Small } from "src/components/chatroom/Title/styles";
import User from "src/containers/user/ImageLabel/Medium";
import { getCurrentUserId } from "src/reducers";
import { createDirectConversation } from "src/actions/chatroom";
import Email from "src/containers/user/Email";

import type { AppState, UID } from "src/types";

type Props = {
  currentUser: UID,
  uid: UID,
  _createDirectConversation: Function
};

const DirectMessage = ({
  uid,
  currentUser,
  _createDirectConversation
}: Props) => {
  if (uid === currentUser) {
    return <></>;
  }

  return (
    <StyledDirectMessage onClick={() => _createDirectConversation(uid)}>
      <SubWrapper>
        <Small>
          <User uid={uid} />
          <Email uid={uid} />
        </Small>
        <Placeholder>{i18n.t(k.SEND_A_DIRECT_MESSAGE)}</Placeholder>
      </SubWrapper>
    </StyledDirectMessage>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  currentUser: getCurrentUserId(app)
});

export default connect(mapStateToProps, {
  _createDirectConversation: createDirectConversation
})(DirectMessage);
