// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";
import { useSelector } from "react-redux";

import type { Message, State } from "src/types";
import { getChatRoom, getCurrentUserId, getProcessTitle } from "src/reducers";

import Container from "src/components/messages/Chat/Notification/Common";

type Props = {
  notification: Message
};

const UpdateCurrentVersion = ({ notification }: Props) => {
  const updatedCurrentVersionRoomId = notification.data.new;

  const currentUser = useSelector(({ app }: State) => getCurrentUserId(app));
  const updatedCurrentVersion = useSelector(({ app }: State) =>
    getChatRoom(app, updatedCurrentVersionRoomId)
  );
  const processTitle = useSelector(({ app }: State) =>
    getProcessTitle(app, updatedCurrentVersionRoomId)
  );

  const updatedCurrentVersionTitle = `${processTitle} #${updatedCurrentVersion?.autoNo}`;

  const verb = currentUser === notification.author ? "have" : "has";

  return (
    <Container {...notification}>
      {" "}
      {verb} {i18n.t(k.MADE)}{" "}
      <strong>
        {updatedCurrentVersion ? updatedCurrentVersionTitle : i18n.t(k._36)}
      </strong>{" "}
      {i18n.t(k.AS_THE_CURRENT_REVISION1)}
    </Container>
  );
};

export default UpdateCurrentVersion;
