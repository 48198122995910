import i18next from "i18next";

import english from "src/i18n/english";
import spanish from "src/i18n/spanish";
import thai from "src/i18n/thai";

i18next.init({
  lng: localStorage.getItem("currentLanguage") || "en",
  debug: false,
  resources: {
    en: { translation: english },
    es: { translation: spanish },
    th: { translation: thai }
  }
});
