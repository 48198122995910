// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";
import moment from "moment";

import { Time as StyledTime, NoValue } from "./styles";

type Props = {
  time: string
};

const Time = ({ time }: Props) => (
  <StyledTime>
    {time ? (
      moment(time).format(i18n.t(k.MMM_DD_YYYY_HH_MM_A))
    ) : (
      <NoValue>{i18n.t(k._19)}</NoValue>
    )}
  </StyledTime>
);

export default Time;
