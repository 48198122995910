// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import { connect } from "react-redux";
import React, { useCallback } from "react";

import { Box, Input } from "@chakra-ui/react";
import { setNewChartDialogAttributes } from "src/actions/chart";

type Props = {
  _setNewChartDialogAttributes: Function,
  timePeriod: ?Object,
  timeInterval: ?string
};

const TimePeriod = ({
  _setNewChartDialogAttributes,
  timePeriod,
  timeInterval
}: Props) => {
  const timeDiff = timePeriod || null;

  const handleTimeDifference = useCallback(() => {
    _setNewChartDialogAttributes({
      timeDiff: null
    });
  }, [_setNewChartDialogAttributes]);

  const handleTimePeriodInput = useCallback(
    (event: any) => {
      _setNewChartDialogAttributes({
        timeDiff: event.target.value ? parseInt(event.target.value, 10) : null
      });
    },
    [_setNewChartDialogAttributes]
  );

  const timeIntervalText = (() => {
    switch (timeInterval) {
      case "monthly":
        return "Months";
      case "annually":
        return "Years";
      case "daily":
        return "Days";
      default:
        return "Weeks";
    }
  })();

  return (
    <Box fontSize="sm">
      {i18n.t(k.LAST)}
      <Input
        type="number"
        min="0"
        placeholder="30"
        value={timeDiff}
        onChange={handleTimePeriodInput}
        size="sm"
        w={20}
        mx={2}
      />

      {timeIntervalText}
    </Box>
  );
};

export default connect(null, {
  _setNewChartDialogAttributes: setNewChartDialogAttributes
})(TimePeriod);
