// @flow

import React from "react";
import { useSelector } from "react-redux";
import { Text, Flex, Avatar, ListItem } from "@chakra-ui/react";

import * as styles from "./styles";
import { getUser } from "src/reducers";

import type { UID } from "src/types";

type GroupMemberCardProps = {
  id: UID
};

const GroupMemberCard = ({ id }: GroupMemberCardProps) => {
  const user = useSelector(({ app }) => getUser(app, id));
  const { displayName, photoUrl, email } = user;

  return (
    <ListItem sx={styles.GroupMemberCard}>
      <Avatar
        sx={styles.MemberAvatar}
        size="sm"
        name={displayName}
        src={photoUrl}
      />

      <Flex sx={styles.MemberNameWrapper}>
        <Text isTruncated sx={styles.MemberName}>
          {displayName}
        </Text>
        <Text sx={styles.MemberEmail} isTruncated>
          {email}
        </Text>
      </Flex>
    </ListItem>
  );
};

export default GroupMemberCard;
