// @flow

import React from "react";

import Text from "./Text";
import Number from "./Number";
import Date from "./Date";
import Chatroom from "./Chatroom";
import Conversation from "./Conversation";
import User from "./User";

import type { ChecklistField } from "src/types";

type Props = {
  field: ChecklistField,
  value: any
};

const Value = (props: Props) => {
  const { field } = props;
  const type = field ? field.get("type") : "";
  switch (type) {
    case "text":
    case "richtext":
    case "select":
      return <Text {...props} />;
    case "date":
      return <Date {...props} />;
    case "number":
      return <Number {...props} />;
    case "chatPickList":
    case "task":
    case "group":
    case "workflow":
      return <Chatroom {...props} />;
    case "conversation":
    case "link":
    case "childConversation":
      return <Conversation {...props} />;
    case "user":
      return <User {...props} />;
    default:
      return <></>;
  }
};

export default Value;
