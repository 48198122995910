// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import { connect, useSelector } from "react-redux";
import React from "react";
import Link from "redux-first-router-link";

import Name from "../Name";
import {
  Breadcrumbs as StyledBreadcrumbs,
  Trail,
  BackButton
} from "src/components/Manage/Workflow/Instances/styles";
import { ButtonLink as Button } from "src/styles/buttons";
import * as atypes from "src/constants/actionTypes";
import { editReportModal } from "src/actions/reports";
import { getInstanceFilter, getAdditionalFiltersChartId } from "src/reducers";
import Icon from "src/icons";

import type { AppState, WorkflowInstanceFilter } from "src/types";

type Props = {
  dashboard: number,
  filter: WorkflowInstanceFilter,
  _editReportModal: Function
};

const Breadcrumbs = ({ dashboard, filter, _editReportModal }: Props) => {
  const chartId = useSelector(({ app }) => getAdditionalFiltersChartId(app));

  if (dashboard || chartId) {
    return <></>;
  }

  return (
    <StyledBreadcrumbs>
      <Trail active>
        <Link
          to={{
            type: atypes.SET_REPORTS_REQUEST
          }}
        >
          <BackButton>
            <Icon type="back" />
          </BackButton>
          <h4>{i18n.t(k.REPORTS)}</h4>
        </Link>
      </Trail>
      <Trail>
        <h4>{i18n.t(k._18)}</h4>
      </Trail>
      <Trail>
        <h4>
          <Name id={filter.reportId} />
        </h4>
      </Trail>
      <Trail>
        <Button onClick={() => _editReportModal(filter.reportId)} type="button">
          <Icon type="edit" />
          {i18n.t(k.EDIT1)} <Name id={filter.reportId} />
        </Button>
      </Trail>
    </StyledBreadcrumbs>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  filter: getInstanceFilter(app),
  dashboard: app.dashboard.current
});

export default connect(mapStateToProps, {
  _editReportModal: editReportModal
})(Breadcrumbs);
