// @flow

import React from "react";
import * as R from "ramda";

import {
  ChecklistContent as StyledContent,
  ChecklistBody,
  ChecklistField,
  Label
} from "../styles";
import Values from "./Values";
import Form from "./Form";
import Checkbox from "src/components/Checkbox.old";
import { getLabel } from "src/utils/checklist";

type Props = {
  timestamp: string,
  data: any
};

const ChecklistContent = ({ timestamp, data }: Props) => {
  const fields = R.keys(data.fields);

  return (
    <StyledContent>
      {fields.map(id => {
        const field = data.fields[id];
        const { checked, type } = field;
        const label = getLabel(type);
        if (R.has("forms")(field)) {
          return <Form key={id} forms={field.forms} timestamp={timestamp} />;
        }

        return (
          <ChecklistField key={id}>
            <Checkbox checked={checked} id={field} />
            <ChecklistBody>
              <Label title={label}>{label || ""}</Label>
              <Values
                field={field}
                timestamp={timestamp}
                fieldId={parseInt(id, 10)}
              />
            </ChecklistBody>
          </ChecklistField>
        );
      })}
    </StyledContent>
  );
};

export default ChecklistContent;
