// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React, { useRef } from "react";
import { connect } from "react-redux";

import Common from "./Common";
import { ToogleText } from "./styles";
import WarningModal from "src/components/Modal/Warning";
import { setAttribute } from "src/actions/chatroom";
import { getChatroomReminders } from "src/reducers";
import useBoolean from "src/hooks/useBoolean";

import type { Message, AppState, UID } from "src/types";

type Props = {
  message: Message,
  _setAttribute: Function,
  reminders: boolean,
  reportModal: boolean,
  currentUser: UID
};

const ReminderStatusChange = ({
  message,
  _setAttribute,
  reminders,
  currentUser
}: Props) => {
  const { data, roomId } = message;
  const { new: on, old: off } = data;
  const outerRef = useRef();
  const {
    value: modal,
    setTrue: showModal,
    setFalse: hideModal
  } = useBoolean();

  const turnOnReminders = () => {
    _setAttribute(roomId, { reminders: true });
  };

  const turnOffReminders = () => {
    _setAttribute(roomId, { reminders: false });
    hideModal();
  };

  const isOwner = currentUser === message.author;
  const verb = isOwner ? "have" : "has";

  if (off)
    return (
      <Common {...message}>
        {" "}
        <span>
          {verb} {i18n.t(k.TURNED_OFF_REMINDERS_FOR_THIS)}{" "}
          {!!on === !!reminders ? (
            <ToogleText onClick={turnOnReminders}>
              {i18n.t(k.TURN_ON)}
            </ToogleText>
          ) : null}
        </span>
      </Common>
    );

  if (on)
    return (
      <>
        {modal && (
          <WarningModal
            handleClose={hideModal}
            handleClick={turnOffReminders}
            heading={i18n.t(k.TURN_OFF_REMINDERS)}
            content={i18n.t(k.TURN_OFF_REMINDERS_CONTENT)}
            primaryText={i18n.t(k.TURN_OFF)}
            secondaryText={i18n.t(k.CANCEL)}
            outerRef={outerRef}
          />
        )}
        <Common {...message}>
          {" "}
          {verb} {i18n.t(k.TURNED_ON_REMINDERS_FOR_THIS_C)}{" "}
          {!!on === !!reminders ? (
            <ToogleText onClick={showModal}>{i18n.t(k.TURN_OFF)}</ToogleText>
          ) : null}
        </Common>
      </>
    );

  return (
    <Common {...message}>
      {" "}
      {verb} {i18n.t(k.TURNED_OFF_REMINDERS_FOR_THIS)}
    </Common>
  );
};

const mapStateToProps = ({ app }: { app: AppState }, { message }) => ({
  currentUser: app.currentUser.uid,
  reminders: getChatroomReminders(app, message.roomId)
});

export default connect(mapStateToProps, {
  _setAttribute: setAttribute
})(ReminderStatusChange);
