// @flow
// Use only lowercase for hex color codes here
const allTileColors: {
  [string]: {
    title: string,
    subTitle: string,
    mainText: string,
    secondaryText: string
  }
} = {
  "#ffff": {
    title: "#16a1e2",
    subTitle: "#6c6c6c",
    mainText: "#262a24",
    secondaryText: "#065986"
  },
  "#fa9a88": {
    title: "#93370d",
    subTitle: "#475467",
    mainText: "#101828",
    secondaryText: "#ec4a0a"
  },
  "#ffc58b": {
    title: "#912018",
    subTitle: "#101828",
    mainText: "#828282",
    secondaryText: "#ec4a0a"
  },
  "#ffea8a": {
    title: "#7a2e0e",
    subTitle: "#828282",
    mainText: "#101828",
    secondaryText: "#dc6803"
  },
  "#bbe5b3": {
    title: "#05603a",
    subTitle: "#828282",
    mainText: "#101828",
    secondaryText: "#1fae1a"
  },
  "#b7ecec": {
    title: "#007e81",
    subTitle: "#828282",
    mainText: "#101828",
    secondaryText: "#00abaf"
  },
  "#b4e0fa": {
    title: "#065986",
    subTitle: "#6c6c6c",
    mainText: "#101828",
    secondaryText: "#16a1e2"
  },
  "#b3bcf5": {
    title: "#2d31a6",
    subTitle: "#667085",
    mainText: "#101828",
    secondaryText: "#0b6dff"
  },
  "#e3d0ff": {
    title: "#42307d",
    subTitle: "#667085",
    mainText: "#101828",
    secondaryText: "#6938ef"
  },
  "#f9fafb": {
    title: "#06439d",
    subTitle: "#6c6c6c",
    mainText: "#101828",
    secondaryText: "#0b6dff"
  },
  "#f3f8ff": {
    title: "#06439d",
    subTitle: "#98a2b3",
    mainText: "#262a24",
    secondaryText: "#0b6dff"
  },
  "#f0f9ff": {
    title: "#026aa2",
    subTitle: "#828282",
    mainText: "#262a24",
    secondaryText: "#16a1e2"
  },
  "#f4f3ff": {
    title: "#3e1c96",
    subTitle: "#828282",
    mainText: "#262a24",
    secondaryText: "#5925dc"
  },
  "#fffcf5": {
    title: "#7a2e0e",
    subTitle: "#828282",
    mainText: "#262a24",
    secondaryText: "#dc6803"
  },
  "#fa9987": {
    title: "#93370d",
    subTitle: "#6c6c6c",
    mainText: "#262a24",
    secondaryText: "#ec4a0a"
  }
};

const tileColors = new Proxy(allTileColors, {
  get(target, prop) {
    return target[prop.toLowerCase()];
  }
});

export default tileColors;
