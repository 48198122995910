// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import { connect } from "react-redux";
import React, { useCallback, useEffect } from "react";

import { Body as StyledBody, AddChart } from "./styles";
import Charts from "./Charts";
import NewChart from "src/components/Manage/Reports/Instances/Chart/New";
import Display from "./Display";
import useBoolean from "src/hooks/useBoolean";
import { getAllCharts } from "src/actions/chart";
import {
  Dot1,
  Dot2,
  Dot3,
  Loader as StyledLoader
} from "src/components/ButtonLoader/styles";

import type { AppState } from "src/types";

type Props = {
  addingChart: boolean,
  showNewChart: boolean,
  _getAllCharts: Function
};

const Body = ({ addingChart, showNewChart, _getAllCharts }: Props) => {
  const { value: dropdown, toggleBoolean, setFalse } = useBoolean(false);

  useEffect(() => {
    _getAllCharts();
  }, []);

  const toggleDropdown = useCallback(() => {
    _getAllCharts();
    toggleBoolean();
  }, [toggleBoolean, _getAllCharts]);

  return (
    <>
      <StyledBody>
        <Display />
        <NewChart show={showNewChart} />
        <AddChart
          type="button"
          onClick={toggleDropdown}
          isLoading={addingChart}
          disabled={addingChart}
        >
          {addingChart ? (
            <StyledLoader>
              <Dot1 />
              <Dot2 />
              <Dot3 />
            </StyledLoader>
          ) : (
            <>{i18n.t(k.ADD_A_CHART)}</>
          )}
        </AddChart>
      </StyledBody>
      {dropdown && <Charts handleClose={setFalse} />}
    </>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  addingChart: app.dashboard.addingChart,
  showNewChart: app.charts.newChartDialog.show
});

export default connect(mapStateToProps, {
  _getAllCharts: getAllCharts
})(Body);
