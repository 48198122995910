// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import { connect } from "react-redux";
import React from "react";

import { Search as StyledSearch, ButtonOutline } from "./styles";
import { GreyInput } from "src/styles/input";
import { showNewReportsModal } from "src/actions/reports";
import Icon from "src/icons";
import SaveAndChartButtons from "src/components/Manage/Reports/Instances/SaveAndChartButtons";

import type { WorkflowInstanceFilter } from "src/types";

type Props = {
  filter: WorkflowInstanceFilter,
  _showNewReportsModal: Function,
  onChange: Function,
  reportId: ?number
};

const Search = ({ onChange, _showNewReportsModal, reportId }: Props) => {
  return (
    <StyledSearch>
      <GreyInput
        placeholder={i18n.t(k.SEARCH)}
        type="text"
        onChange={onChange}
      />
      {reportId ? (
        <SaveAndChartButtons />
      ) : (
        <ButtonOutline
          type="button"
          onClick={() =>
            _showNewReportsModal({
              modalType: "create",
              loading: false
            })
          }
        >
          <Icon type="saveAs" />
          <span>{i18n.t(k.SAVE_AS)}</span>
        </ButtonOutline>
      )}
    </StyledSearch>
  );
};

export default connect(null, {
  _showNewReportsModal: showNewReportsModal
})(Search);
