// @flow

import k from "src/i18n/keys";

const columns = [
  { key: "displayName", display: k.DISPLAY_NAME },
  { key: "status", display: k.STATUS },
  { key: "email", display: k.EMAIL_ID },
  { key: "orgRole", display: k.ROLE1 },
  { key: "department", display: k.DEPARTMENT },
  { key: "phoneNumber", display: k.PHONE_NUMBER },
  { key: "group", display: k.GROUPS },
  { key: "lastLogin", display: k.LAST_LOGGED_IN },
  { key: "dateInvited", display: k.DATE_INVITED },
  { key: "dateJoined", display: k.DATE_JOINED }
];

export default columns;
