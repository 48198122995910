// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import { connect } from "react-redux";
import * as R from "ramda";
import React, { useState, useRef, useCallback, useEffect } from "react";

import { Edit as StyledEdit, Input, Value } from "./styles";
import List from "./List";
import { updateOtherUserDetails } from "src/actions/user";
import { getUser, getUserRole } from "src/reducers";
import Icon from "src/components/Icon";
import { iconSize } from "src/styles/constants/size";

import type { AppState, UnifizeUser, Department } from "src/types";

type Props = {
  role: string,
  user: UnifizeUser,
  departments: Array<Department>,
  _updateOtherUserDetails: Function
};

const Edit = ({ role, user, departments, _updateOtherUserDetails }: Props) => {
  const [dropdown, showDropdown] = useState(false);
  const [value, setValue] = useState("");
  const [result, setResult] = useState(departments);
  const outerRef = useRef(null);

  useEffect(() => {
    setResult(departments);
  }, [setResult, departments]);

  const handleSearch = useCallback(
    (e: any) => {
      const { value } = e.target;
      setValue(value);
    },
    [setValue]
  );

  const handleClose = useCallback(() => {
    showDropdown(false);
  }, [showDropdown]);

  const handleSelect = useCallback(
    d => {
      const { uid } = user;
      _updateOtherUserDetails(uid, { department: d.id });
      handleClose();
    },
    [user, _updateOtherUserDetails, handleClose]
  );

  const handleAdd = useCallback(() => {
    const newDepartment = { id: value, name: value };
    setResult([...result, newDepartment]);
    handleSelect(newDepartment);
    setValue("");
  }, [result, handleSelect, value, setValue, setResult]);

  const byId = R.mergeAll(R.map(d => ({ [d.id]: d.name }), departments));

  if (role === "admin" || role === "phantom") {
    return (
      <StyledEdit ref={outerRef}>
        {dropdown ? (
          <>
            <Input
              type="text"
              placeholder={i18n.t(k.SEARCH_FOR_DEPARTMENTS)}
              onChange={handleSearch}
              value={value}
              autoFocus
            />

            <List
              search={value}
              handleSelect={handleSelect}
              departments={result}
              handleClose={handleClose}
              handleAdd={handleAdd}
              outerRef={outerRef}
            />
          </>
        ) : (
          <span
            tabIndex="0"
            role="button"
            onClick={() => showDropdown(true)}
            onKeyPress={() => showDropdown(true)}
          >
            {user.department && byId[`${user.department}`] ? (
              <Value>
                <span>{byId[`${user.department}`]}</span>
                <Icon icon="edit" size={iconSize.space_m} />
              </Value>
            ) : (
              <em>{i18n.t(k.NO_DEPARTMENT)}</em>
            )}
          </span>
        )}
      </StyledEdit>
    );
  }

  return (
    <StyledEdit>
      <span>
        {user.department && byId[`${user.department}`] ? (
          <Value>
            <span>{byId[`${user.department}`]}</span>
          </Value>
        ) : (
          <em>{i18n.t(k.NO_DEPARTMENT)}</em>
        )}
      </span>
    </StyledEdit>
  );
};

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  user: getUser(app, props.uid),
  departments: app.departments,
  role: getUserRole(app)
});

export default connect(mapStateToProps, {
  _updateOtherUserDetails: updateOtherUserDetails
})(Edit);
