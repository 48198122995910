// @flow

import type {
  TextProps,
  StackProps,
  PopoverContentProps,
  PopoverBodyProps,
  CenterProps,
  SystemCSSProperties
} from "@chakra-ui/react";

export const trigger = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center"
};

export const popover: {
  content: PopoverContentProps,
  body: PopoverBodyProps,
  fileName: TextProps,
  fileData: StackProps,
  noPreview: {
    container: CenterProps,
    text: TextProps
  }
} = {
  content: {
    sx: {
      backgroundColor: "unifize_unifizeBlue"
    }
  },

  body: {
    sx: {
      display: "flex",
      flexDirection: "column",
      gap: "6px",
      width: "100%",
      color: "white"
    }
  },

  fileName: {
    sx: {
      fontSize: "12px",
      lineHeight: "13.33px",
      m: 0
    }
  },

  fileData: {
    sx: {
      alignItems: "center",
      p: {
        fontSize: "10px",
        m: 0,
        lineHeight: "6px",
        letterSpacing: "0.175862px",
        color: "blue.100"
      }
    }
  },

  noPreview: {
    container: {
      sx: {
        backgroundColor: "unifize_whiteAlpha200",
        borderRadius: "6px",
        width: "100%",
        height: "53px"
      }
    },
    text: {
      sx: {
        fontSize: "22px",
        lineHeight: "26.4px"
      }
    }
  }
};

export const previewBar: StackProps = {
  display: "flex",
  justifyContent: "space-between"
};

export const iconBtn: SystemCSSProperties = {
  cursor: "pointer"
};
