// @flow

import React from "react";
import * as R from "ramda";

// Field components
import Text from "./Text";
import Number from "./Number";
import Date from "./Date";
import Form from "./Form";
import Select from "./Select";
import User from "./User";
import Conversation from "./Conversation";
import Pdf from "./Pdf";
import File from "./File";
import Approval from "./AdvancedApproval";
import Revision from "./Revision";
import LinkedField from "./LinkedField";
import Section from "./Section";
import RichText from "./RichText";

type Props = {
  currentSettings: Object,
  type: string,
  setLocalSettings: Function,
  position: number
};

const FieldSettings = ({
  currentSettings,
  type,
  setLocalSettings,
  position
}: Props) => {
  const saveSettings = settings => {
    let newSettings = {};

    if (type === "approval" && settings.version) {
      newSettings = { ...settings };
    } else {
      newSettings = {
        ...currentSettings,
        ...settings
      };
    }

    // Purge old approval settings key values if exists
    if (type === "approval") {
      newSettings = R.omit(
        [
          "minUsers",
          "signatureType",
          "postApprovalStatus",
          "lockPostApprovalStatus",
          "allowRevisionPostApproval",
          "postRevisionStatus",
          "lockedChecklistFields"
        ],

        newSettings
      );
    }

    setLocalSettings(newSettings);
  };

  const forwardProps = {
    type,
    saveSettings,
    settings: currentSettings,
    position
  };

  switch (type) {
    case "text":
      return <Text {...forwardProps} />;
    case "number":
      return <Number {...forwardProps} />;
    case "user":
      return <User {...forwardProps} />;
    case "childConversation":
    case "conversation":
      return <Conversation {...forwardProps} />;
    case "select":
      return <Select {...forwardProps} />;
    case "date":
      return <Date {...forwardProps} />;
    case "pdf":
      return <Pdf {...forwardProps} />;
    case "file":
      return <File {...forwardProps} />;
    case "form":
      return <Form {...forwardProps} />;
    case "approval":
      return <Approval {...forwardProps} />;
    case "revision":
      return <Revision {...forwardProps} />;
    case "link":
      return <LinkedField {...forwardProps} />;
    case "section":
      return <Section {...forwardProps} />;
    case "richtext":
      return <RichText {...forwardProps} />;
    default:
      return null;
  }
};

export default FieldSettings;
