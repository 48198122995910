// @flow

import React, { useCallback } from "react";
import { toast } from "react-toastify";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { StyledCopyInput } from "./styles";
import Icon from "src/icons";
import { Input } from "src/styles/input";
import { CopyButton } from "src/styles/buttons";

type Props = {
  placeholder: string,
  value: ?string,
  id: string,
  readOnly: boolean
};

const CopyInput = ({ placeholder, value, id, readOnly }: Props) => {
  const copyToClipboard = useCallback(() => {
    toast.success("copied");
  }, []);

  return (
    <StyledCopyInput>
      <Input
        type="text"
        autoComplete="off"
        placeholder={placeholder}
        value={value}
        id={id}
        readOnly={readOnly}
      />

      <CopyToClipboard text={value || ""} onCopy={copyToClipboard}>
        <CopyButton type="button">
          <Icon type="copy" />
        </CopyButton>
      </CopyToClipboard>
    </StyledCopyInput>
  );
};

export default CopyInput;
