// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";
import { connect } from "react-redux";

import { Tag } from "./styles";
import { getCurrentRoomOwner } from "src/reducers";

import type { AppState, UID } from "src/types";

type Props = {
  uid: UID,
  owner: UID
};

const Owner = ({ uid, owner }: Props) => (
  <>{uid === owner ? <Tag>{i18n.t(k.OWNER2)}</Tag> : null}</>
);

const mapStateToProps = ({ app }: { app: AppState }) => ({
  owner: getCurrentRoomOwner(app)
});

export default connect(mapStateToProps)(Owner);
