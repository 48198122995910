/* eslint-disable lingui/no-unlocalized-strings */
// @flow

import React, { useState, useEffect, useCallback, useRef } from "react";
import { connect } from "react-redux";
import * as R from "ramda";

import Checkbox from "src/components/Checkbox.old";
import {
  SelectionName,
  RemoveButton
} from "src/components/Dock/Checklist/Conversation/styles";
import { AddButton } from "src/components/Dock/Checklist/styles";
import { Input } from "src/styles/input";
import { FormSettings } from "./styles";
import { CheckboxWrapper, SelectedFormWrapper } from "../styles";
import { searchForm } from "src/actions/forms";
import Icon from "src/icons";
import Dropdown from "./Dropdown";
import Name from "src/components/Form/Name";
import type { AppState, FormFieldSettings } from "src/types";

type Props = {
  type: string,
  settings: FormFieldSettings,
  _searchForm: Function,
  searchedForms: Array<number>,
  saveSettings: Function
};

const Form = ({
  settings,
  _searchForm,
  searchedForms,
  saveSettings
}: Props) => {
  const [search, setSearch] = useState("");
  const [dropdown, setDropdown] = useState(false);
  const [multiple, setMultiple] = useState(false);
  const [selectedForms, setSelectedForms] = useState([]);
  const dropdownRef = useRef(null);

  useEffect(() => {
    _searchForm("");
  }, [_searchForm]);

  useEffect(() => {
    saveSettings({ multiple, selectedForms });
  }, [multiple, selectedForms]);

  // Parse settings props if exists and save in state
  useEffect(() => {
    if (!R.isEmpty(settings) && !R.isNil(settings)) {
      const { multiple, selectedForms } = settings;
      setSelectedForms(selectedForms || []);
      setMultiple(multiple || false);
    }
  }, [setSelectedForms, setMultiple]);

  const handleSearch = useCallback((e: any) => {
    const { value } = e.target;
    setSearch(value);
    _searchForm(value);
  }, []);

  const toggleMultiple = useCallback(() => {
    setMultiple(!multiple);
  }, [multiple]);

  const openDropdown = useCallback(() => {
    _searchForm(search);
    setDropdown(true);
  }, [search]);

  const selectForm = useCallback(
    (id: number) => {
      // prevent duplicate and select
      setSelectedForms(R.uniq([...selectedForms, id]));
      setDropdown(false);
    },
    [selectedForms]
  );

  const currentSelectedForm = R.filter(
    (form: number) => R.includes(form, selectedForms),
    searchedForms
  );

  const removeForms = useCallback(
    (formId: number) => {
      const filtered = R.filter(id => {
        return id !== formId;
      }, selectedForms);

      setSelectedForms(filtered);
    },
    [selectedForms]
  );

  return (
    <FormSettings>
      <form>
        <SelectedFormWrapper>
          {currentSelectedForm.map((form: number) => {
            return (
              <SelectionName key={form}>
                <Name id={form} />
                <RemoveButton type="button" onClick={() => removeForms(form)}>
                  <Icon type="decrement" />
                </RemoveButton>
              </SelectionName>
            );
          })}
        </SelectedFormWrapper>
        {!dropdown && (
          <AddButton data-cy="addFormButton" onClick={openDropdown}>
            + Add a form
          </AddButton>
        )}
        {dropdown && (
          <div ref={dropdownRef}>
            <Input
              type="text"
              value={search}
              onChange={handleSearch}
              placeholder="Search form"
              autoFocus
            />

            <Dropdown
              outerRef={dropdownRef}
              searchedForms={searchedForms}
              handleClose={() => setDropdown(false)}
              selectForm={selectForm}
            />
          </div>
        )}
        <CheckboxWrapper>
          <Checkbox
            handleChange={toggleMultiple}
            checked={multiple}
            id="multiple"
          />

          <span>Allow multiple</span>
        </CheckboxWrapper>
      </form>
    </FormSettings>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  searchedForms: app.form.searchResults
});

export default connect(mapStateToProps, {
  _searchForm: searchForm
})(Form);
