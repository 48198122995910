// @flow

import * as React from "react";

import Icon from "src/icons";

import { Button as StyledButton, AnimatedButton } from "./styles";

type Props = {
  isLoading?: boolean,
  variant: string,
  children?: React.Node,
  disabled?: boolean,
  color?: string,
  handleClick?: Function
};

const Button = ({
  isLoading = false,
  variant,
  children = null,
  disabled = false,
  handleClick = () => {},
  ...restProps
}: Props) => {
  if (isLoading) {
    return (
      <AnimatedButton
        autoFocus={false}
        variant={variant}
        disabled
        {...restProps}
      >
        <Icon type="spinner" />
      </AnimatedButton>
    );
  }

  return (
    <StyledButton
      autoFocus={false}
      variant={variant}
      disabled={disabled}
      onClick={handleClick}
      {...restProps}
    >
      {children || null}
    </StyledButton>
  );
};

export default Button;
