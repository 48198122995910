import React, { useRef } from "react";
import { useDispatch } from "react-redux";

import { getFieldsFromCSV } from "src/utils/checklistBuilder";
import { addFields as addFieldsAction } from "src/actions/checklistBuilder";

import Icon from "src/components/InlineSVG";
import { iconSize } from "src/styles/constants";
import * as colors from "src/styles/constants/colors";
import { TextButtonWithImageAndBorder } from "src/styles/buttons.old";

export default function AddFieldsFromCSVButton() {
  const csvRef = useRef(null);

  const dispatch = useDispatch();

  const handleUploadCSVClick = () => {
    // Trigger the hidden file input
    if (csvRef && csvRef.current) {
      csvRef.current.click();
    }
  };

  const handleCSVChange = e => {
    const fileData = e.target.files[0];

    if (!fileData) {
      return;
    }

    const reader = new FileReader();

    reader.onload = function (e) {
      try {
        // $FlowFixMe
        const text = e.target.result;
        const newFields = getFieldsFromCSV(text);

        dispatch(addFieldsAction({ fields: newFields }));
      } catch (error) {
        console.error(error);
        dispatch(addFieldsAction({ fields: [] }));
      }
    };

    reader.readAsText(fileData);
  };

  return (
    <TextButtonWithImageAndBorder type="button" onClick={handleUploadCSVClick}>
      <Icon
        icon="hollowPlusCircle"
        color={colors.blue500}
        size={iconSize.big}
      />

      <p>Upload Excel Sheet</p>

      <input
        type="file"
        accept=".csv"
        id="bulkUploadFields"
        ref={csvRef}
        onChange={handleCSVChange}
        style={{ display: "none" }}
      />
    </TextButtonWithImageAndBorder>
  );
}
