// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";

import { ProgressContainer, ProgressFill, Text } from "./styles";

type Props = {
  progress: number
};

const ProgressBar = ({ progress, ...restProps }: Props) => {
  return (
    <ProgressContainer {...restProps}>
      <Text progress={progress}>{i18n.t(k.UPLOADING_FILE)}</Text>
      <ProgressFill progress={progress} />
    </ProgressContainer>
  );
};

export default ProgressBar;
