// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";
import { Button } from "src/components/Manage/Workflow/Instances/MenuBar/styles.js";
import { SelectAllContainer } from "./styles";

type Props = {
  onSelectAll: Function,
  onSelectNone: Function
};

const SelectAll = ({ onSelectAll, onSelectNone }: Props) => {
  return (
    <SelectAllContainer>
      <Button type="button" onClick={onSelectAll}>
        <span>{i18n.t(k.SELECT_ALL1)}</span>
      </Button>
      <Button type="button" onClick={onSelectNone}>
        <span>{i18n.t(k.SELECT_NONE)}</span>
      </Button>
    </SelectAllContainer>
  );
};

export default SelectAll;
