// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";

import Common from "./Common";
import { Capitalize } from "src/styles";

import type { Message } from "src/types";

type Props = {
  message: Message
};

const Priority = ({ message }: Props) => {
  const { data } = message;
  const { new: newPriority, old: oldPriority } = data;

  return (
    <Common {...message}>
      {" "}
      {i18n.t(k.UPDATED_THE_PRIORITY_FROM)}{" "}
      <strong>
        <Capitalize>{oldPriority || "normal"}</Capitalize>
      </strong>{" "}
      {i18n.t(k.TO)}{" "}
      <strong>
        <Capitalize data-cy="newPriority">{newPriority || "normal"}</Capitalize>
      </strong>
    </Common>
  );
};

export default Priority;
