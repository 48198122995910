// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";

import type { Message } from "src/types";

import Container from "src/components/messages/Chat/Notification/Common";
import Icon from "src/icons";

type Props = {
  message: Message
};

const ArchiveStatus = ({ message }: Props) => {
  const action = message.data.archived ? "archived" : "unarchived";

  return (
    <Container {...message}>
      <span>
        {" "}
        {action} {i18n.t(k.THIS_CONVERSATION)}{" "}
      </span>
      <Icon
        type={message.data.archived ? i18n.t(k.ARCHIVE1) : i18n.t(k.UNARCHIVE1)}
        fill="black"
      />
    </Container>
  );
};

export default ArchiveStatus;
