// @flow

import React from "react";

import Row from "./Row";
import { useLanguage } from "src/contexts/LanguageContext";

import { NoResultsFound, ShimmerLoader } from "./styles";

type Props = {
  isLoading: boolean
};

const Body = ({ isLoading }: Props) => {
  const { translations } = useLanguage();

  if (isLoading) {
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        {Array.from({ length: 30 }).map((_, index) => (
          <div
            key={index}
            style={{ display: "flex", width: "100%", gap: "0.5rem" }}
          >
            {[1, 2, 3].map((_, ind) => (
              <ShimmerLoader key={ind}></ShimmerLoader>
            ))}
          </div>
        ))}
      </div>
    );
  }

  if (translations.length === 0) {
    return (
      <NoResultsFound>
        <span>No Results Found</span>
      </NoResultsFound>
    );
  }

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {translations.map(translation => (
        <Row key={translation.id} translation={translation} />
      ))}
    </div>
  );
};

export default Body;
