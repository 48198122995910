// @flow

import i18n from "i18next";
import k from "src/i18n/keys";
import { connect } from "react-redux";
import React, { useRef, useCallback } from "react";

import Standard from "./Standard";
import Custom from "./Custom";
import Options from "./Options";
import { Status as StyledStatus } from "./styles";
import { toggleChatroomOverlay } from "src/actions/header";
import { getChatroomStatus } from "src/reducers";
import Tooltip from "src/components/Tooltip";
import useBoolean from "src/hooks/useBoolean";

import type { AppState, RoomId } from "src/types";

type Props = {
  roomId: RoomId,
  value: number,
  location: string,
  chatroomOverlay?: string,
  _toggleChatroomOverlay: Function
};

const Status = ({
  value,
  roomId,
  location,
  chatroomOverlay = "message",
  _toggleChatroomOverlay
}: Props) => {
  const outerRef = useRef(null);
  const {
    value: dropdown,
    toggleBoolean: toggleDropdown,
    setFalse
  } = useBoolean();

  const toggleStatus = useCallback(() => {
    toggleDropdown();
    _toggleChatroomOverlay(chatroomOverlay);
  }, [toggleDropdown, _toggleChatroomOverlay, chatroomOverlay]);

  const handleClose = useCallback(() => {
    setFalse();
    _toggleChatroomOverlay(chatroomOverlay);
  }, [setFalse, _toggleChatroomOverlay, chatroomOverlay]);

  return (
    <StyledStatus ref={outerRef}>
      <Tooltip title={i18n.t(k.STATUS)}>
        <div
          tabIndex="0"
          role="button"
          data-cy="status"
          onClick={toggleStatus}
          onKeyPress={toggleStatus}
        >
          {value < 0 ? (
            <Standard id={value} roomId={roomId} location={location} />
          ) : (
            <Custom id={value} roomId={roomId} location={location} />
          )}
        </div>
      </Tooltip>

      {dropdown && (
        <Options
          outerRef={outerRef}
          roomId={roomId}
          show={dropdown}
          location={location}
          handleClose={handleClose}
        />
      )}
    </StyledStatus>
  );
};

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  value: getChatroomStatus(app, props.roomId)
});

export default connect(mapStateToProps, {
  _toggleChatroomOverlay: toggleChatroomOverlay
})(Status);
