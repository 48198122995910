// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";
import * as R from "ramda";
import { useSelector } from "react-redux";
import { Box, Text, Flex } from "@chakra-ui/react";

import * as styles from "../styles";
import { getValueFromQuery } from "src/reducers";
import * as colors from "src/styles/constants/colors";
import { comparisonValues, comparisonCharts } from "src/constants/charts";
import Icon from "src/icons";

import type { DownshiftProps } from "downshift";
import type { OnItemClick } from "src/components/Dropdown";

type Props = {
  item: {
    id: string,
    typeLabel: string,
    typeSubLabel: string,
    type: string
  },

  onItemClick?: OnItemClick,
  highlightedIndex: number,
  index: number,
  scrollIntoView: DownshiftProps.scrollIntoView
};

const ComparisonRow = ({
  item,
  onItemClick,
  highlightedIndex,
  index
}: Props) => {
  const builder = useSelector(({ location }) =>
    getValueFromQuery("builder")(location)
  );

  const isMultiSeriesChart =
    builder === comparisonCharts.MultiSeries &&
    item.type === comparisonValues.area;

  const isStackedAreaChart =
    builder === comparisonCharts.StackedArea &&
    item.type === comparisonValues.column;

  const isMultiOrStackedChart = isMultiSeriesChart || isStackedAreaChart;

  const isActiveIndex =
    (highlightedIndex !== null && highlightedIndex === index) ||
    (highlightedIndex === null && index === 0);

  return (
    <Flex
      sx={
        isMultiOrStackedChart
          ? styles.DisableDropdownItem
          : isActiveIndex
            ? styles.ActiveDropdownItem
            : styles.InActiveDropdownItem
      }
    >
      <Icon
        type={item.type}
        fill={item.type === comparisonValues.line ? "none" : colors.ink}
      />

      <Text
        sx={{ my: 0, pl: "0.5rem" }}
        onClick={() => onItemClick && onItemClick({ id: item.id, item })}
      >
        <Box as="span" sx={{ pr: "0.5rem" }}>
          {item.typeLabel}
        </Box>
        {!R.isEmpty(item.typeSubLabel) && (
          <Box sx={styles.LightGreyText} as="span">
            {i18n.t(k._1)}
            {item.typeSubLabel}
            {i18n.t(k._2)}
          </Box>
        )}
      </Text>
    </Flex>
  );
};

export default ComparisonRow;
