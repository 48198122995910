// @flow

import styled from "@emotion/styled";
import { ifProp } from "styled-tools";
import * as radius from "src/styles/constants/radius";
import * as spacing from "src/styles/constants/spacing";
import * as colors from "src/styles/constants/colors";
import * as typography from "src/styles/constants/typography";
import { profile } from "src/styles/constants/size";
import { ScrollableBox, ListItemMedium } from "src/styles/box";
import { ButtonLink } from "src/styles/buttons";
import appLocation from "src/constants/location";

const NameOrEmail = styled.span`
  display: inline-block;
  font-size: 0.75rem;
  line-height: 1rem;
  white-space: nowrap;
  color: ${colors.primary};
  font-weight: ${typography.semiBold};
`;

const AuthorWrapper = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const AuthorColor = styled.span`
  color: ${ifProp("hashColor", props => props.hashColor, colors.primary)};
`;

const Picture = styled.img`
  width: ${props => props.size || profile.space_m};
  height: ${props => props.size || profile.space_m};
  border-radius: ${props => (props.radius ? props.radius : radius.small)};
  object-fit: cover;
  border: ${props =>
    props.location === appLocation.chatroomHeader &&
    props.isActive &&
    `solid 1px ${colors.active}`};
`;

const SmallPicture = styled(Picture)`
  width: ${profile.space_s};
  height: ${profile.space_s};
`;

const Initial = styled.div`
  display: inline-flex;
  min-width: ${props => props.size || profile.space_m};
  min-height: ${props => props.size || profile.space_m};
  border-radius: ${props => (props.radius ? props.radius : radius.small)};
  background: ${colors.unifizeBlue};
  color: ${colors.brightLight};
  line-height: ${props => props.size || profile.space_m};
  text-align: center;
  font-size: 10px;
  align-items: center;
  justify-content: center;
  text-transform: lowercase;
`;

const SmallInitial = styled(Initial)`
  min-width: ${profile.space_s};
  min-height: ${profile.space_s};
  line-height: ${profile.space_s};
`;

const ImageLabel = styled.span`
  position: relative;
  display: flex;
  align-items: center;
  > img,
  > div:first-of-type {
    vertical-align: middle;
    margin-right: ${props => (props.hideName ? 0 : spacing.space_m)};
    border-radius: 50%;
  }
  > span {
    font-weight: bold;
  }
`;

const SmallImageLabel = styled(ImageLabel)`
  padding: 1px;
  > img,
  div {
    min-width: ${profile.space_s};
    min-height: ${profile.space_s};
    width: ${profile.space_s};
    height: ${profile.space_s};
    line-height: ${profile.space_s};
  }
  > span {
    overflow-x: hidden;
    text-overflow: ellipsis;
  }
  > span > span {
    font-size: 0.75rem;
  }
`;

const RoundImageLabel = styled(ImageLabel)`
  width: 90px;
  margin-right: 25px;
  > div,
  img {
    min-width: ${spacing.space_l};
    min-height: ${spacing.space_l};
    width: ${spacing.space_l};
    height: ${spacing.space_l};
    border-radius: 50%;
    margin-right: ${spacing.space_s};
  }

  > span {
    width: 110px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    /* @media (max-width: 1080px) {
      width: 40px;
    } */
  }

  > span > span {
    font-weight: normal;
    color: ${props => (props.active ? colors.primary : colors.secondary)};
  }
`;

const ImageLabelRemove = styled.div`
  display: inline-flex;
  justify-content: space-between;
  background: ${colors.grey1};
  height: 2em;
  > span {
    margin: 0px;
  }
  > button {
    border: none;
    background: ${colors.grey1};
    padding: ${spacing.space_list_m};
    padding-right: ${spacing.space_xs};
    outline: none;
    cursor: pointer;
  }
`;

const Invitation = styled(ScrollableBox)`
  padding: ${spacing.space_inset_squish_m};
  margin-top: ${spacing.space_xs};
  > span {
    > div {
      color: ${colors.secondary};
    }
  }
`;

const SearchResult = styled(ListItemMedium)`
  > span > span {
    max-width: 185px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const InviteButton = styled(ButtonLink)`
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
`;

const LargePicture = styled.div`
  > div,
  > img {
    width: 100px;
    height: 100px;
    font-size: 4em;
    border-radius: 12px;
  }

  > img {
    object-fit: cover;
  }
`;

export {
  NameOrEmail,
  AuthorColor,
  Picture,
  Initial,
  ImageLabel,
  ImageLabelRemove,
  SmallPicture,
  SmallInitial,
  SmallImageLabel,
  Invitation,
  SearchResult,
  InviteButton,
  LargePicture,
  RoundImageLabel,
  AuthorWrapper
};
