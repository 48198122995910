// @flow

import styled from "@emotion/styled";
import { SystemCSSProperties } from "@chakra-ui/react";

import { size } from "src/styles/constants";
import * as colors from "src/styles/constants/colors";
import * as colorsOld from "src/styles/constants/colors.old";
import * as radius from "src/styles/constants/radius";
import * as spacing from "src/styles/constants/spacing";
import * as typography from "src/styles/constants/typography";
import { highlightBorder } from "src/styles";

export const SRW = styled.div`
  height: 100vh;
  width: 100%;
  grid-column: 2 / span 1;
  display: grid;
  grid-template-columns: minmax(220px, 274px) minmax(400px, 1fr) auto;
  font-size: ${size.regular};
`;

export const SRWMain = styled.div`
  height: 100vh;
  flex: 1;
  display: flex;
  flex-direction: column;
  background: ${colors.appBackground};

  ${props => props.isDragActive && highlightBorder};
`;

export const Chatroom = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  overflow-y: auto;
  position: relative;
`;

export const ChatArea = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-width: 0;
`;

export const Panel = styled.div`
  width: 320px;
  background: ${colors.brightLight};
  border: solid 1px rgba(136, 136, 136, 0.3);
`;

export const AppInfo = styled.div`
  width: 275px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  border-right: 1px solid ${colors.secondary}4d;
`;

export const InfoItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const OrgName = styled.div`
  font-size: 0.75rem;
  font-weight: bold;
  line-height: 18px;
  color: ${colors.backgroundText};
  text-transform: uppercase;
  margin-bottom: ${spacing.space_xl};
`;

export const LogIn = styled.button`
  width: 160px;
  padding: 5px 0;
  border-radius: ${radius.medium};
  background: ${colors.active};
  font-size: 0.88rem;
  font-weight: bold;
  color: ${colors.brightLight};
  line-height: 20px;
  margin-bottom: ${spacing.space_l};
`;

export const WelcomeMessage = styled.div`
  font-size: 0.75rem;
  line-height: 20px;
  text-align: center;
  margin-bottom: ${spacing.space_l};
  > span {
    font-weight: 600;
    color: ${colors.active};
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const UnifizeLogo = styled.div`
  margin-bottom: ${spacing.space_m};
`;

export const DriveProcess = styled.div`
  font-size: 0.88rem;
  line-height: 20px;
  margin-bottom: 24px;
  text-align: center;
`;

export const UnifizeIntro = styled.div`
  text-align: center;
  line-height: 20px;
  font-size: 0.75rem;
  color: ${colors.secondary};
`;

export const ModalContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  background: ${colors.modal};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalCard = styled.div`
  width: 320px;
  padding: 60px 40px;
  background: ${colors.brightLight};
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
`;

export const ModalInfo = styled.div`
  font-size: 18px;
  line-height: 24px;
  font-weight: ${typography.semiBold};
  margin: 22px 0;
`;

export const CloseIcon = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
`;

export const EmailSent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const VerifiedStatus = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  font-weight: ${typography.medium};
  font-size: 1rem;
  line-height: 19px;
  margin-bottom: ${spacing.space_l};
  > svg {
    margin-right: 6px;
  }
`;

export const EmailNotification = styled.div`
  text-align: center;
  font-size: 0.88rem;
  line-height: 20px;
  margin-bottom: 24px;
  > strong {
    white-space: nowrap;
  }
`;

export const ResendInfo = styled.div`
  font-size: 0.75rem;
  line-height: ${spacing.space_l};
  > span {
    color: ${colors.active};
    cursor: pointer;
  }
`;

export const AccessDenied: SystemCSSProperties = {
  bg: colorsOld.backgroundWithGradient,
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  flexDirection: "column",
  color: colors.brightLight
};
