// @flow

import type {
  ChecklistLayoutBuilder,
  DataStage,
  ImportedProcessStatus
} from "src/types";

export const dataStages: {
  [string]: DataStage
} = {
  idle: 0,
  fetching: 1,
  fetched: 2,
  updating: 3,
  updated: 4,
  error: -1
};

export const alerts = {
  invalidStatusesInProcessTemplate: "invalidStatusesInProcessTemplate"
};

export const multiSelectActions = Object.freeze({
  select: "select",
  remove: "remove",
  removeLast: "removeLast",
  removeAll: "removeAll"
});

export type MultiSelectActions = $Values<typeof multiSelectActions>;

export const approvalFieldStatuses = {
  notStarted: "not-started",
  started: "started",
  rejected: "rejected",
  approved: "approved",
  cancelled: "cancelled"
};

export type ApprovalFieldStatuses = $Values<typeof approvalFieldStatuses>;

export const orgCloneWizardSteps = Object.freeze({
  email: "email",
  verificationEmailSent: "verificationEmailSent",
  signIn: "signIn",
  signUp: "signUp",
  createOrg: "createOrg",
  creatingOrg: "creatingOrg"
});

export const mobileBreakpoint = "@media only screen and (max-width: 768px)";

export const passwordStrengths = {
  empty: "empty",
  weak: "weak",
  fair: "fair",
  good: "good",
  strong: "strong"
};

export const passwordStrengthMeterColorsByLevel = {
  empty: [
    "unifize_grey30",
    "unifize_grey30",
    "unifize_grey30",
    "unifize_grey30"
  ],
  weak: [
    "unifize_redA400",
    "unifize_grey30",
    "unifize_grey30",
    "unifize_grey30"
  ],
  fair: [
    "unifize_amberA400",
    "unifize_amberA400",
    "unifize_grey30",
    "unifize_grey30"
  ],
  good: [
    "unifize_greenLight700",
    "unifize_greenLight700",
    "unifize_greenLight700",
    "unifize_grey30"
  ],
  strong: [
    "unifize_green700",
    "unifize_green700",
    "unifize_green700",
    "unifize_green700"
  ]
};

export const passwordStrengthLabels = {
  empty: "Very Weak",
  weak: "Weak",
  fair: "Fair",
  good: "Good",
  strong: "Strong"
};

export const conversationFieldTypes = [
  "conversation",
  "chatPickList",
  "group",
  "task",
  "workflow",
  "childConversation",
  "link"
];

export const integrationStatus = Object.freeze({
  adminPending: ("adminPending": "adminPending"),
  userPending: ("userPending": "userPending"),
  done: ("done": "done")
});

export type IntegrationStatus = $Values<typeof integrationStatus>;

export const defaultChecklistLayout: ChecklistLayoutBuilder = {
  checklistOpenState: {
    web: true,
    lite: true,
    liteMobile: false
  },
  checklistWidth: "1"
};

export const revisionPrivacyModes = [
  { id: "noChange", label: "Don't change" },
  { id: "full", label: "Hide everything" },
  { id: "content", label: "Hide only chat content" },
  { id: "none", label: "Make Public" }
];

export const roomProps = [
  "owner",
  "versionCount",
  "statusTitle",
  "privacy",
  "id",
  "currentVersion",
  "autoNo",
  "seqNo",
  "address",
  "derivedTitle",
  "status",
  "versionComment",
  "processTitle",
  "title",
  "type",
  "version",
  "fields",
  "dueDate",
  "active",
  "templateId",
  "outcome",
  "orgId",
  "canceled"
];

export const bulkImportStatus: {
  [ImportedProcessStatus]: ImportedProcessStatus
} = {
  success: "success",
  failed: "failed",
  error: "error"
};

export const modalTypes = Object.freeze({
  displayName: "displayName",
  createOrg: "createOrg",
  cloneOrg: "cloneOrg",
  changePassword: "changePassword",
  preferences: "preferences"
});

export const languagePermission = {
  view: "154-1",
  edit: "153-4"
};
