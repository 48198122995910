// @flow
import i18n from "i18next";
import k from "src/i18n/keys";
import React from "react";
import { Text, HStack } from "@chakra-ui/react";
import Icon from "src/icons";

import * as styles from "./styles";

type Props = {
  handleRemove: () => void
};

const RemoveUser = ({ handleRemove }: Props) => {
  const [hoverState, setHoverState] = React.useState(false);

  const handleOnMouseEnter = () => {
    setHoverState(true);
  };

  const handleOnMouseLeave = () => {
    setHoverState(false);
  };

  return (
    <HStack
      sx={styles.RemoveUser}
      onClick={handleRemove}
      onMouseLeave={handleOnMouseLeave}
      onMouseEnter={handleOnMouseEnter}
    >
      <Text
        fontSize="sm"
        sx={hoverState ? styles.RemoveUserTextHover : styles.RemoveUserText}
      >
        {i18n.t(k.REMOVE_SELECTED_USER)}
      </Text>
      <Icon
        type="checklistRemove"
        style={hoverState ? styles.RemoveUserIconHover : styles.RemoveUserIcon}
      />
    </HStack>
  );
};

export default RemoveUser;
