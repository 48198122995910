// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";
import { useSelector } from "react-redux";
import { HStack, Text, VStack } from "@chakra-ui/react";
import { getGroup } from "src/reducers";
import useBoolean from "src/hooks/useBoolean";

import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import Icon from "src/icons";

import type { DownshiftProps } from "downshift";
import type { Group, GroupId } from "src/types";
import type { OnItemClick } from "src/components/Dropdown";

import * as styles from "./styles";
import NewUser from "./NewUser";

type Props = {
  id: GroupId,
  onItemClick?: OnItemClick,
  highlightedIndex?: number,
  index?: number,
  scrollIntoView: DownshiftProps.scrollIntoView
};

const NewGroup = ({
  id,
  onItemClick,
  highlightedIndex,
  index,
  scrollIntoView
}: Props) => {
  const group: Group = useSelector(({ app }) => getGroup(app, id));
  const {
    value: showMembers,
    setTrue: toggleDropdown,
    setFalse: closeDropdown
  } = useBoolean();

  const { title, members, photoUrl } = group;
  return (
    <>
      <HStack
        data-cy="userOption"
        sx={
          (highlightedIndex === null && index === 0) ||
          highlightedIndex === index
            ? styles.ActiveUserOrgroup
            : styles.inActiveUserOrgroup
        }
        ref={highlightedIndex === index ? scrollIntoView : null}
      >
        <Icon
          type="groupAvatar"
          text={title}
          size="md"
          id={id}
          photoUrl={photoUrl}
        />

        <VStack
          sx={styles.UserOrGroupDetails}
          onClick={() => onItemClick && onItemClick({ id })}
        >
          <Text sx={styles.UserOrGroupDetailsHeader}>{title}</Text>
          <Text sx={styles.UserOrGroupDetailsBody}>
            {members.length}{" "}
            {members.length > 1 ? i18n.t(k.MEMBERS2) : i18n.t(k.MEMBER1)}
          </Text>
        </VStack>
        {showMembers ? (
          <ChevronUpIcon
            sx={styles.GroupToggleIcon}
            boxSize={6}
            onClick={closeDropdown}
          />
        ) : (
          <ChevronDownIcon
            sx={styles.GroupToggleIcon}
            boxSize={5}
            onClick={toggleDropdown}
          />
        )}
      </HStack>
      <VStack sx={styles.GroupDropdown}>
        {showMembers &&
          members.map((id, index) => <NewUser key={index} id={id} />)}
      </VStack>
    </>
  );
};

export default NewGroup;
