// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";
import moment from "moment";

import { dateRangeToLabelMap } from "../Table/manageViewFilters";
import { capitalize } from "src/utils";

type Props = {
  value: string
};

const DateField = ({ value }: Props) => {
  if (dateRangeToLabelMap[value]) {
    return <span>{dateRangeToLabelMap[value]}</span>;
  } else if (value.startsWith("lastn") || value.startsWith("nextn")) {
    return (
      <span>{`${capitalize(
        value.replace(/^(last|next)n-/, "$1 ").replace(/-/g, " ")
      )}`}</span>
    );
  }
  const dates = (value || "").split(":").filter(d => d !== "");
  const start = dates.length > 0 ? dates[0] : null;
  const end = dates.length === 2 ? dates[1] : null;

  return (
    <>
      <strong>
        {start ? moment(start).format(i18n.t(k.MMM_DD_YYYY)) : ""}
      </strong>
      <strong>{start && end ? i18n.t(k.TO) : ""}</strong>
      <strong>{end ? moment(end).format(i18n.t(k.MMM_DD_YYYY)) : ""}</strong>
    </>
  );
};

export default DateField;
