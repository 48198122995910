// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";
import moment from "moment";

import {
  SeqNo,
  CenterCell,
  Cell,
  User as StyledUser
} from "src/components/Manage/Workflow/Instances/Table/Row/styles";
import Chatroom from "./Chatroom";
import WorkflowName from "src/containers/workflow/Name";
import User from "src/containers/user/ImageLabel/Small";

import type { FormInstance } from "src/types";

type Props = {
  selected: boolean,
  column: string,
  form: FormInstance
};

const Item = ({ selected, column, form }: Props) => {
  switch (column) {
    case "seqNo":
      return <SeqNo selected={selected}>{form.seqNo}</SeqNo>;
    case "createdAt":
      return (
        <Cell selected={selected}>
          {form.createdAt
            ? moment(form.createdAt).format(i18n.t(k.MMM_DD_YYYY_HH_MM_A))
            : i18n.t(k._19)}
        </Cell>
      );

    case "chatroomId":
      return (
        <Cell selected={selected}>
          <Chatroom
            title={form.derivedTitle}
            address={form.address}
            canceled={form.canceled}
            id={form.chatroomId}
            templateId={form.templateId}
          />
        </Cell>
      );

    case "templateId":
      return (
        <Cell selected={selected}>
          <WorkflowName id={form.templateId} />
        </Cell>
      );

    case "createdBy":
      return (
        <StyledUser selected={selected}>
          <User uid={form.createdBy} />
        </StyledUser>
      );

    case "formId":
      return <Cell selected={selected}>{form.address}</Cell>;
    default:
      return <CenterCell selected={selected}>{column}</CenterCell>;
  }
};

export default Item;
