// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";

import { DirectMessage as StyledDirectMessage } from "./styles";
import { UserInfo } from "src/components/messages/Chat/styles";
import User from "src/components/user/Photo";
import NameOrEmail from "src/containers/user/NameOrEmail";

import type { UID } from "src/types";

type Props = {
  uid: UID
};

const DirectMessage = ({ uid }: Props) => {
  return (
    <StyledDirectMessage>
      <UserInfo>
        <User uid={uid} size="sm" shape="ellipse" />
      </UserInfo>
      <div>
        <h4>
          {i18n.t(k.THIS_IS_YOUR_DIRECT_MESSAGE_WI)} <NameOrEmail uid={uid} />
        </h4>
        <div>{i18n.t(k.THIS_CONVERSATION_IS_PRIVATE)}</div>
      </div>
    </StyledDirectMessage>
  );
};

export default DirectMessage;
