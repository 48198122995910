// @flow

import { connect } from "react-redux";
import React, { useEffect } from "react";
import { Text } from "@unifize/sarah";

import { MyConversationChart as StyledConversation } from "./styles";
import Chart from "./Chart";
import { getChart } from "src/actions/chart";
import { getChart as getChartData } from "src/reducers";
import tileColors from "src/styles/constants/tileColors";
import { setTileEmptyStatus } from "src/actions/homescreen";

import type { AppState, HomescreenTile, Action } from "src/types";

type Props = {
  chart: Object,
  chartData: Object,
  tile: HomescreenTile,
  sectionId: number,
  _getChart: Function,
  _setTileEmptyStatus: (
    sectionId: number,
    tileId: number,
    isEmpty: boolean
  ) => Action
};

const DashboardChart = ({
  chart,
  tile,
  sectionId,
  _getChart,
  _setTileEmptyStatus
}: Props) => {
  useEffect(() => {
    _getChart(tile.settings.chart);
  }, [tile]);

  useEffect(() => {
    _setTileEmptyStatus(sectionId, tile?.id, false);
  }, []);

  const colorScheme = tileColors[tile.settings.tileColor];

  return (
    <StyledConversation color={tile.settings.tileColor}>
      <Text variant="body" weight="bold" color={colorScheme.title}>
        {tile.title}
      </Text>
      <Text variant="caption" color={colorScheme.subTitle}>
        {tile.subTitle}
      </Text>
      <Chart
        chartId={tile.settings.chart}
        key={chart.id}
        title={chart.title}
        description={chart.description}
        reportId={chart.reportId}
        x={chart.x}
        y={chart.y}
        yAxisLabel={chart.yAxisLabel}
        typeId={chart.type}
      />
    </StyledConversation>
  );
};

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  chart: getChartData(app, props.tile.settings.chart)
});

export default connect(mapStateToProps, {
  _getChart: getChart,
  _setTileEmptyStatus: setTileEmptyStatus
})(DashboardChart);
