// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";

import Common from "./Common";
import type { Message } from "src/types";

type Props = {
  message: Message
};

const Privacy = ({ message }: Props) => {
  const { data } = message;
  const { new: newPrivacy } = data;

  const renderInfo = () => {
    if (newPrivacy === "content")
      return (
        <strong data-cy="privacyNotification">
          {i18n.t(k.HIDE_CHAT_CONTENT)}
        </strong>
      );
    if (newPrivacy === "full")
      return (
        <strong data-cy="privacyNotification">
          {i18n.t(k.HIDE_EVERYTHING)}
        </strong>
      );
    return <strong data-cy="privacyNotification">{i18n.t(k.PUBLIC)}</strong>;
  };

  return (
    <Common {...message}>
      {" "}
      {i18n.t(k.CHANGED_THE_PRIVACY_SETTING_TO)} {renderInfo()}
    </Common>
  );
};

export default Privacy;
