// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import { connect } from "react-redux";
import React, { useCallback } from "react";
import * as R from "ramda";
import Switch from "react-switch";

import AddSection from "./AddSection";
import { HomeScreen as StyledHomeScreen, Header } from "./styles";
import Sections from "./Sections";
import Modal from "./Modal";
import {
  setHomeScreenBuilderAttributes,
  clearHomeScreenDialogAttributes,
  toggleHomescreen
} from "src/actions/orgSettings";
import * as colors from "src/styles/constants/colors";

import type { AppState } from "src/types";

type Props = {
  sectionIds: Array<number>,
  enabled: boolean,
  creatingSection: boolean,
  show: boolean,
  tileType: string,
  tileTypes: Object,
  _setHomeScreenBuilderAttributes: Function,
  _clearHomeScreenDialogAttributes: Function,
  _toggleHomescreen: Function
};

const HomeScreen = ({
  show,
  enabled,
  sectionIds,
  tileTypes,
  tileType,
  creatingSection,
  _setHomeScreenBuilderAttributes,
  _clearHomeScreenDialogAttributes,
  _toggleHomescreen
}: Props) => {
  const handleOpenModal = useCallback(
    (sectionId: number, type: string) => {
      _setHomeScreenBuilderAttributes({
        tileTypeId: parseInt(R.invertObj(tileTypes)[type], 10),
        tileType: type,
        title: "",
        subTitle: "",
        buttonText: "",
        displayEmpty: false,
        tileColor: "#ffff",
        show: true,
        sectionId
      });
    },
    [tileTypes, _setHomeScreenBuilderAttributes]
  );

  const handleHomescreen = useCallback(() => {
    _toggleHomescreen(!enabled);
  }, [enabled]);

  return (
    <StyledHomeScreen>
      <Header>
        {i18n.t(k.ENABLE_HOME_SCREEN)}
        <Switch
          onChange={handleHomescreen}
          uncheckedIcon={false}
          checkedIcon={false}
          checked={enabled}
          onColor={colors.checked}
          offColor={colors.grey6}
          className={enabled ? "on" : "off"}
          height={18}
          width={31}
        />
      </Header>
      <Sections sectionIds={sectionIds} handleOpenModal={handleOpenModal} />
      <AddSection loading={creatingSection} />
      {show && (
        <Modal
          modalType={tileType}
          handleClose={_clearHomeScreenDialogAttributes}
        />
      )}
    </StyledHomeScreen>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  sectionIds: app.orgSettings.sectionsIds,
  tileType: app.orgSettings.homeScreenBuilderDialog.tileType,
  show: app.orgSettings.homeScreenBuilderDialog.show,
  creatingSection: app.orgSettings.creatingSection,
  tileTypes: app.orgSettings.tileTypes,
  enabled: app.orgs.homeScreenEnabled
});

export default connect(mapStateToProps, {
  _setHomeScreenBuilderAttributes: setHomeScreenBuilderAttributes,
  _clearHomeScreenDialogAttributes: clearHomeScreenDialogAttributes,
  _toggleHomescreen: toggleHomescreen
})(HomeScreen);
