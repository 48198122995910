// @flow
import React from "react";

import { EditorContent } from "@tiptap/react";

import type { Node } from "react";
import type { Editor } from "@tiptap/react";

import { RichTextField } from "./styles";
import StyleMenu from "./StyleMenu";

type Props = {
  editor: Editor,
  showMenu: boolean,
  handleClick: Function,
  handleKeyDown: Function,
  children: Node
};

const RichTextEditor = ({
  editor,
  showMenu,
  handleClick,
  handleKeyDown,
  children
}: Props) => {
  return (
    <>
      {showMenu && <StyleMenu editor={editor} />}
      <RichTextField onClick={handleClick} onKeyDown={handleKeyDown}>
        <EditorContent data-cy="richTextInput" editor={editor} />
        {children}
      </RichTextField>
    </>
  );
};

export default RichTextEditor;
