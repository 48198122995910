// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React, { useCallback } from "react";
import { connect } from "react-redux";

import { Toast as StyledToast } from "./styles";
import { undoDismiss } from "src/actions/prompts";
import type { AppState, RoomId } from "src/types";

type Props = {
  closeToast: Function,
  _undoDismiss: Function,
  room: RoomId
};

const Toast = ({ closeToast, _undoDismiss, room }: Props) => {
  const undo = useCallback(() => {
    _undoDismiss(room);
    closeToast();
  }, []);

  return (
    <StyledToast>
      {i18n.t(k.STEP_DISMISSED)} <span onClick={undo}>{"UNDO"}</span>
    </StyledToast>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  room: app.chatRooms.current
});

export default connect(mapStateToProps, { _undoDismiss: undoDismiss })(Toast);
