// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React, { useCallback, useRef } from "react";

import { Edit as StyledEdit } from "./styles";
import { Capitalize } from "src/styles";
import { SmallText as Input } from "src/styles/input";
import OutsideClickHandler from "src/components/OutsideClickHandler";

type Props = {
  value: string,
  position: string,
  handleChange: Function,
  handleClose: Function
};

const Edit = ({ value, position, handleChange, handleClose }: Props) => {
  const outerRef = useRef(null);

  const handleClickOutside = useCallback(
    (event: any) => {
      if (
        outerRef &&
        outerRef.current &&
        !outerRef.current.contains(event.target)
      ) {
        handleClose();
      }
    },
    [outerRef, handleClose]
  );

  const handleKeyDown = useCallback(
    (event: any) => {
      if (event.key === "Enter") {
        handleClose();
      }
    },
    [handleClose]
  );

  return (
    <OutsideClickHandler onClickOutside={handleClickOutside}>
      <StyledEdit ref={outerRef}>
        <Capitalize>{position} </Capitalize> {i18n.t(k.SECTION1)}
        <Input
          value={value}
          autoFocus
          onKeyDown={handleKeyDown}
          onChange={handleChange}
        />
      </StyledEdit>
    </OutsideClickHandler>
  );
};

export default Edit;
