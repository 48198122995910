// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import * as R from "ramda";

import { PhoneNumber as StyledPhoneNumber } from "./styles";
import { getUser, getUserRole } from "src/reducers";
import Icon from "src/components/Icon";
import { updateOtherUserDetails } from "src/actions/user";
import { iconSize } from "src/styles/constants/size";

import type { AppState, UnifizeUser } from "src/types";

type Props = {
  role: string,
  user: UnifizeUser,
  _updateOtherUserDetails: Function
};

const PhoneNumber = ({ role, user, _updateOtherUserDetails }: Props) => {
  const [edit, setEdit] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");

  useEffect(() => {
    setPhoneNumber(user.phoneNumber);
  }, [user, setPhoneNumber]);

  const handleChange = useCallback(
    (value: string) => {
      setPhoneNumber(value);
    },
    [setPhoneNumber]
  );

  const handleSubmit = useCallback(
    (e: any) => {
      e.preventDefault();

      setEdit(false);
    },
    [setEdit]
  );

  const handleEnter = useCallback(
    (event: any) => {
      if (event.key === "Enter") {
        const { uid } = user;

        const standardizedPhoneNumber = R.join(
          "",
          // $FlowFixMe
          R.reject(
            (d: string) => R.includes(d, [" ", "-", "(", ")"]),
            phoneNumber
          )
        );

        _updateOtherUserDetails(uid, { phoneNumber: standardizedPhoneNumber });
      }
    },
    [user, _updateOtherUserDetails, phoneNumber]
  );

  if (role === "admin" || role === "phantom") {
    return (
      <StyledPhoneNumber onSubmit={handleSubmit}>
        {edit ? (
          <ReactPhoneInput
            defaultCountry="in"
            value={phoneNumber || ""}
            onChange={handleChange}
            onKeyDown={handleEnter}
            searchPlaceholder="Search for country"
            enableSearchField={false}
            disableSearchIcon
            disableDropdown
          />
        ) : (
          <span>
            <span>
              {user.phoneNumber || <em>{i18n.t(k.NO_PHONE_NUMBER)}</em>}
            </span>
            <Icon
              icon="edit"
              onClick={() => setEdit(true)}
              size={iconSize.space_m}
            />
          </span>
        )}
      </StyledPhoneNumber>
    );
  }

  return (
    <StyledPhoneNumber>
      <span>
        <span>{user.phoneNumber || <em>{i18n.t(k.NO_PHONE_NUMBER)}</em>}</span>
      </span>
    </StyledPhoneNumber>
  );
};

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  user: getUser(app, props.uid),
  role: getUserRole(app)
});

export default connect(mapStateToProps, {
  _updateOtherUserDetails: updateOtherUserDetails
})(PhoneNumber);
