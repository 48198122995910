// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";

import Common from "./Common";
import type { Message } from "src/types";

type Props = {
  message: Message
};

const Objective = ({ message }: Props) => {
  const { data } = message;
  const { old: oldMssg, new: newMssg } = data;
  if (oldMssg && newMssg) {
    return (
      <Common {...message}>
        {" "}
        {i18n.t(k.UPDATED_THE_OBJECTIVE_TO)} <strong>{newMssg}</strong>
      </Common>
    );
  }
  if (newMssg) {
    return (
      <Common {...message}>
        {" "}
        {i18n.t(k.ADDED_OBJECTIVE)} <strong>{newMssg}</strong>
      </Common>
    );
  }
  return <Common {...message}> {i18n.t(k.REMOVED_OBJECTIVE)}</Common>;
};

export default Objective;
