// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import { connect } from "react-redux";
import React, { useEffect, useCallback } from "react";
import * as R from "ramda";

import {
  Filter as StyledFilter,
  ListItem,
  SubHeading,
  Chatroom as StyledChatroom
} from "./styles";
import { setForm } from "src/actions/forms";
import Checkbox from "src/components/Checkbox";
import BlanksNonBlanks from "src/components/Manage/Workflow/Instances/Table/Filter/BlanksNonBlanks";
import { getUniqueValues, getWorkflowInstanceFilter } from "src/reducers";
import { Separator } from "src/components/Manage/Workflow/Instances/Table/Filter/styles";

import type { AppState, WorkflowInstanceFilter } from "src/types";

type Props = {
  column: string,
  values: Array<number>,
  parentRef: any,
  filter: WorkflowInstanceFilter,
  _setForm: Function,
  handleClose: Function
};

const Select = ({
  parentRef,
  values,
  filter,
  column,
  _setForm,
  handleClose
}: Props) => {
  const preventDefault = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const closeModal =
    ((event: any) => {
      if (event.keyCode === 13) {
        handleClose();
      }
    },
    [handleClose]);

  const clickOutside = useCallback(
    (event: any) => {
      if (parentRef && parentRef.current) {
        if (!parentRef.current.contains(event.target)) {
          handleClose();
        }
      }
    },
    [parentRef, handleClose]
  );

  useEffect(() => {
    // $FlowFixMe
    document.addEventListener("keydown", closeModal, false);
    document.addEventListener("click", clickOutside, false);
    return () => {
      // $FlowFixMe
      document.removeEventListener("keydown", closeModal, false);
      document.removeEventListener("click", clickOutside, false);
    };
  }, []);

  const handleSort = useCallback(
    (ascending: boolean) => {
      if (ascending) {
        const newColumn = R.uniq([
          `${column}:asc`,
          ...R.reject(R.equals(`${column}:desc`), filter.sort || [])
        ]);

        _setForm({
          query: {
            ...filter,
            sort: newColumn,
            page: 1
          }
        });
      } else {
        const newColumn = R.uniq([
          `${column}:desc`,
          ...R.reject(R.equals(`${column}:asc`), filter.sort || [])
        ]);

        _setForm({
          query: {
            ...filter,
            sort: newColumn,
            page: 1
          }
        });
      }
    },
    [filter, column, _setForm]
  );

  const handleSelect = useCallback(
    (event: any, item: string | number) => {
      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }

      if (R.includes(item, filter[column] || [])) {
        _setForm({
          query: {
            ...filter,
            [column]: R.reject(R.equals(item), filter[column]),
            page: 1
          }
        });
      } else {
        _setForm({
          query: {
            ...filter,
            [column]: [...(filter[column] || []), item],
            page: 1
          }
        });
      }
    },
    [column, filter, _setForm]
  );

  const chatroomFilter = filter[column] || [];

  return (
    <StyledFilter onClick={preventDefault}>
      <SubHeading>{i18n.t(k.SORT_BY2)}</SubHeading>
      <ul>
        <ListItem tabIndex="0" role="button" onClick={() => handleSort(true)}>
          {i18n.t(k.ASCENDING)}
        </ListItem>
        <ListItem tabIndex="0" role="button" onClick={() => handleSort(false)}>
          {i18n.t(k.DESCENDING)}
        </ListItem>
      </ul>
      <Separator />
      <SubHeading>{i18n.t(k.FILTER_VALUES)}</SubHeading>
      <BlanksNonBlanks handleSelect={handleSelect} filter={chatroomFilter} />
      <Separator />
      <ul>
        {values.map(value => (
          <StyledChatroom
            tabIndex="0"
            role="button"
            key={value}
            onClick={event => handleSelect(event, value)}
          >
            <Checkbox
              id={`chatroom${value}`}
              checked={R.includes(value, chatroomFilter)}
              handleChange={event => handleSelect(event, value)}
            />

            {value}
          </StyledChatroom>
        ))}
      </ul>
    </StyledFilter>
  );
};

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  values: getUniqueValues(app, props.column),
  filter: getWorkflowInstanceFilter(app)
});

export default connect(mapStateToProps, {
  _setForm: setForm
})(Select);
