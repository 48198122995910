// @flow

import { connect } from "react-redux";
import React from "react";

import List from "./List";
import { getCurrentLanguage } from "src/reducers";

import type { AppState, GroupFilter } from "src/types";

type Props = {
  filter: GroupFilter,
  language: string
};

const Groups = ({ filter, language }: Props) => {
  return <List key={language} filter={filter} />;
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  filter: app.groups.filter,
  language: getCurrentLanguage(app)
});

export default connect(mapStateToProps)(Groups);
