// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React, { useCallback, useEffect, useRef } from "react";

import { Dropdown as StyledDropdown } from "../styles";
import { ListItemMedium } from "src/styles/box";
import OutsideClickHandler from "src/components/OutsideClickHandler";
import useAutoPosition from "src/hooks/useAutoPosition";

type Props = {
  outerRef: any,
  handleClose: Function,
  handleCreate: Function
};

const Dropdown = ({ outerRef, handleClose, handleCreate }: Props) => {
  const dropdownRef = useRef(null);
  const { top, bottom } = useAutoPosition({
    outerRef: dropdownRef,
    initialTop: "3em",
    initialBottom: "auto",
    topOffset: "3em",
    bottomOffset: "3em"
  });

  const closeModal = useCallback(
    (event: any) => {
      if (event.key === "Escape") {
        event.preventDefault();
        event.stopPropagation();
        handleClose();
      }
    },
    [handleClose]
  );

  useEffect(() => {
    document.addEventListener("keydown", closeModal, false);
    return () => {
      document.removeEventListener("keydown", closeModal, false);
    };
  }, []);

  const handleClickOutside = useCallback(
    (event: any) => {
      if (
        outerRef &&
        outerRef.current &&
        !outerRef.current.contains(event.target)
      ) {
        handleClose();
      }
    },
    [outerRef, handleClose]
  );

  return (
    <OutsideClickHandler onClickOutside={handleClickOutside}>
      <StyledDropdown top={top} bottom={bottom} ref={dropdownRef}>
        <ListItemMedium onClick={() => handleCreate("center")}>
          {i18n.t(k.CENTER_SECTION)}
        </ListItemMedium>
        <ListItemMedium onClick={() => handleCreate("right")}>
          {i18n.t(k.RIGHT_SECTION)}
        </ListItemMedium>
      </StyledDropdown>
    </OutsideClickHandler>
  );
};

export default Dropdown;
