// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import { connect } from "react-redux";
import React from "react";

import { Total as StyledTotal, OtherTotals, Active } from "./styles";

import type { AppState, Total as TotalType } from "src/types";

type Props = {
  showAverage: boolean,
  total: TotalType
};

const Total = ({ showAverage, total }: Props) => (
  <>
    <StyledTotal showAverage={showAverage}>{i18n.t(k.TOTAL)}</StyledTotal>
    <OtherTotals showAverage={showAverage} />
    <OtherTotals showAverage={showAverage} />
    <OtherTotals showAverage={showAverage}>
      {total.createdCompleted}
    </OtherTotals>
    <OtherTotals showAverage={showAverage}>{total.createdPending}</OtherTotals>
    <OtherTotals showAverage={showAverage} />
    <OtherTotals showAverage={showAverage} />
    <OtherTotals showAverage={showAverage}>
      <Active>{total.pendingUnread}</Active>
    </OtherTotals>
    <OtherTotals showAverage={showAverage}>{total.pendingOwner}</OtherTotals>
    <OtherTotals showAverage={showAverage} />
    <OtherTotals showAverage={showAverage} />
    <OtherTotals showAverage={showAverage}>{total.completedOwner}</OtherTotals>
    <OtherTotals showAverage={showAverage}>
      {total.completedCreator}
    </OtherTotals>
    <OtherTotals showAverage={showAverage}>{total.completedBy}</OtherTotals>
    <OtherTotals showAverage={showAverage} />
  </>
);

const mapStateToProps = ({ app }: { app: AppState }) => ({
  total: app.userAnalytics.total
});

export default connect(mapStateToProps)(Total);
