// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";
import { Portal } from "@chakra-ui/react";

import {
  Content as StyledContent,
  PrimaryButton,
  Overlay,
  ModalText,
  SecondaryFooter,
  HeadingWrapper,
  Secondary,
  Form
} from "src/components/Manage/Reports/Modal/styles";
import { ModalFormBody } from "src/components/chatroom-nav/filter/styles";
import * as colors from "src/styles/constants/colors";
import ButtonLoader from "src/components/ButtonLoader";

type Props = {
  title: string,
  handleRemove: Function,
  handleClose: Function
};

const Modal = ({ title, handleRemove, handleClose }: Props) => {
  const handleSubmit = (event: any) => {
    event.preventDefault();
    handleRemove();
    handleClose();
  };

  return (
    <Portal>
      <Overlay>
        <StyledContent data-cy="deleteConversationPrompt">
          <Form onSubmit={handleSubmit}>
            <HeadingWrapper>{i18n.t(k.DELETE)}</HeadingWrapper>
            <ModalFormBody>
              <ModalText>
                <div>
                  {i18n.t(k.ARE_YOU_SURE_YOU_WANT_TO_REMOV)} {title}{" "}
                  {i18n.t(k.FROM_THE_CHECKLIST)}
                </div>
              </ModalText>
            </ModalFormBody>
            <SecondaryFooter>
              <PrimaryButton delete>
                <ButtonLoader
                  dataCy="deleteConversationButton"
                  type="submit"
                  color={colors.danger}
                >
                  {i18n.t(k.DELETE)}
                </ButtonLoader>
              </PrimaryButton>
              <Secondary type="button" onClick={handleClose}>
                {i18n.t(k.CANCEL)}
              </Secondary>
            </SecondaryFooter>
          </Form>
        </StyledContent>
      </Overlay>
    </Portal>
  );
};

export default Modal;
