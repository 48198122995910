// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React, { useCallback } from "react";
import { connect } from "react-redux";

import { Text } from "@chakra-ui/react";
import ListItem from "./ListItem";
import DefaultConversationType from "./DefaultConversationType";
import type { AppState } from "src/types";
import { hideFilterModal } from "src/actions/header";
import { showView } from "src/actions";
import defaultFilter from "src/constants/defaultFilter";
import {
  setWorkflowFilter,
  setSingleType,
  clearType,
  setFilter
} from "src/actions/filter";
import { clear } from "src/actions/activeFilter";
import type { SystemProcess } from "src/types";

type Props = {
  conversationTypes: Array<number>,
  _setWorkflowFilter: Function,
  _setSingleType: Function,
  _clearType: Function,
  _hideFilterModal: Function,
  _clearActiveFilter: Function,
  _setFilter: Function,
  directmessage: Object,
  systemProcess: SystemProcess,
  emptySearchField: Function,
  _showView: Function
};

const ConversationList = ({
  systemProcess,
  conversationTypes,
  _setWorkflowFilter,
  _setSingleType,
  _clearType,
  _hideFilterModal,
  _clearActiveFilter,
  _setFilter,
  directmessage,
  emptySearchField,
  _showView
}: Props) => {
  const openDirectMessages = useCallback(() => {
    _setFilter(directmessage, null, "Direct Messages");
    _showView("Direct Messages");
    _hideFilterModal();
    emptySearchField();
  }, [_setFilter, _hideFilterModal]);

  const handleSelect = useCallback(
    item => {
      if (item) {
        if (item === -1) {
          openDirectMessages();
        } else if (item === -2) {
          _clearActiveFilter();
          _setWorkflowFilter(null);
          _setSingleType("workflow", "Processes", true);
        } else if (item === -3) {
          _clearActiveFilter();
          _setWorkflowFilter(null);
          const systemTask = systemProcess.task;

          if (systemTask) {
            _setWorkflowFilter(systemTask);
            _setSingleType("workflow", "Tasks", true);
          } else {
            _setSingleType("task", "Tasks", true);
          }
        } else if (item === -4) {
          _clearActiveFilter();
          _setWorkflowFilter(null);
          _setSingleType("approval", "Approvals", true);
        } else if (item === -5) {
          _clearActiveFilter();
          _setWorkflowFilter(null);
          _setSingleType("group", "Feeds", true);
        } else {
          _setWorkflowFilter(null);
          _clearType();
          _clearActiveFilter();
        }
        emptySearchField();
        _hideFilterModal();
      }
    },
    [
      systemProcess,
      _setWorkflowFilter,
      _setSingleType,
      _clearType,
      _hideFilterModal,
      openDirectMessages,
      _clearActiveFilter,
      emptySearchField
    ]
  );

  return (
    <>
      {conversationTypes?.length === 0 ? null : (
        <Text
          fontSize="xs"
          pl="4"
          color="gray.500"
          mb="1"
          letterSpacing="1.5px"
        >
          {i18n.t(k.ALL_TYPES)}
        </Text>
      )}
      {(conversationTypes || []).map(conversation => {
        if (conversation > 0) {
          return (
            <ListItem
              key={conversation}
              id={conversation}
              handleSelect={handleSelect}
              emptySearchField={emptySearchField}
            />
          );
        }

        return (
          <DefaultConversationType
            key={conversation}
            id={conversation}
            handleSelect={handleSelect}
            emptySearchField={emptySearchField}
          />
        );
      })}
    </>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  systemProcess: app.systemProcess,
  conversationTypes: app.header.searchedConversationTypes,
  directmessage: defaultFilter.directmessage
});

export default connect(mapStateToProps, {
  _setWorkflowFilter: setWorkflowFilter,
  _setSingleType: setSingleType,
  _clearType: clearType,
  _hideFilterModal: hideFilterModal,
  _clearActiveFilter: clear,
  _setFilter: setFilter,
  _showView: showView
})(ConversationList);
