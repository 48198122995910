// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React, { useState } from "react";
import { connect } from "react-redux";
import * as R from "ramda";

import { getPermissions, getRolesById, getRoles } from "src/reducers";

import * as colors from "src/styles/constants/colors";
import { Text, Thead, Tbody, Box } from "@chakra-ui/react";
import {
  Table as StyledTable,
  Tr as StyledTr,
  Td as StyledTd,
  StickyTd as StyledStickyTd,
  Th as StyledTh
} from "src/styles/chakraTable";
import Header from "./Header";
import Members from "./Members";
import NewRole from "./NewRole";
import PermissionTitle from "./PermissionTitle";
import PermissionCheckBox from "./PermissionCheckBox";

import type { AppState, RoleIds, Permission } from "src/types";

type Props = {
  roleIds: {
    system: RoleIds,
    custom: RoleIds
  },

  roles: Object,
  permissions: Array<Permission>
};

const RoleTable = ({ roleIds, roles, permissions }: Props) => {
  const [isActive, setIsActive] = useState(null);

  return (
    <Box position="relative" overflow="auto" maxHeight="90vh" pb={4}>
      <StyledTable variant="simple" size="sm">
        <Thead bgColor={colors.grey28} height="43px">
          <StyledTr key="header" color={colors.ink}>
            <StyledTh minWidth="426px">
              <Text color={colors.ink} fontWeight={600}>
                {i18n.t(k.FUNCTIONALITY)}
              </Text>
            </StyledTh>

            {roleIds.system.map(roleId => {
              return (
                <Header
                  id={roleId}
                  key={roleId}
                  header={roles[roleId].title}
                  systemRole
                />
              );
            })}

            {roleIds.custom.map(roleId => {
              return (
                <Header
                  id={roleId}
                  key={roleId}
                  header={roles[roleId].title}
                  systemRole={false}
                />
              );
            })}

            <StyledTh
              width="165px"
              minWidth="165px"
              maxWidth="165px"
              textAlign="center"
            >
              <NewRole />
            </StyledTh>
          </StyledTr>
        </Thead>

        <Tbody>
          <StyledTr key="mutate-users">
            <StyledStickyTd></StyledStickyTd>

            {roleIds.system.map(roleId => {
              return (
                <StyledTd key={roleId}>
                  <Members
                    id={roleId}
                    members={roles[roleId].members}
                    isActive={isActive}
                    setIsActive={setIsActive}
                  />
                </StyledTd>
              );
            })}

            {roleIds.custom.map(roleId => {
              return (
                <StyledTd key={roleId}>
                  <Members
                    id={roleId}
                    members={roles[roleId].members}
                    isActive={isActive}
                    setIsActive={setIsActive}
                  />
                </StyledTd>
              );
            })}

            <StyledTd></StyledTd>
          </StyledTr>

          {permissions.map(row => {
            const { description, verbId, locked, resourceId, componentId } =
              row;

            const formattedResourceId = resourceId || row["resource-id"];
            const formattedComponentId = componentId || row["component-id"];
            const formattedVerbId = verbId || row["verb-id"];

            if (!formattedResourceId && !formattedComponentId) return;

            // $FlowFixMe - already checking if these are undefined
            const resourcePermissionId = `${formattedResourceId}${i18n.t(k._5)}${
              formattedVerbId ?? ""
            }`;

            // $FlowFixMe - already checking it is undefined
            const componentPermissionId = `${formattedComponentId}`;

            const checked = roleId => {
              if (!R.isNil(formattedResourceId)) {
                return R.includes(
                  resourcePermissionId,
                  roles[roleId].resourcePermissions
                );
              }
              if (!R.isNil(formattedComponentId)) {
                return R.includes(
                  componentPermissionId,
                  roles[roleId].componentPermissions
                );
              }

              return false;
            };

            return (
              <StyledTr
                key={
                  formattedResourceId
                    ? resourcePermissionId
                    : componentPermissionId
                }
              >
                <StyledStickyTd>
                  <PermissionTitle title={description} isLocked={locked} />
                </StyledStickyTd>
                {roleIds.system.map(roleId => {
                  return (
                    <StyledTd key={roleId} textAlign="center">
                      <PermissionCheckBox
                        id={roleId}
                        resourceId={formattedResourceId}
                        componentId={formattedComponentId}
                        verbId={formattedVerbId}
                        isChecked={checked(roleId)}
                        isLocked={locked}
                      />
                    </StyledTd>
                  );
                })}
                {roleIds.custom.map(roleId => {
                  return (
                    <StyledTd key={roleId} textAlign="center">
                      <PermissionCheckBox
                        id={roleId}
                        resourceId={resourceId}
                        componentId={componentId}
                        verbId={formattedVerbId}
                        isChecked={checked(roleId)}
                        isLocked={locked}
                      />
                    </StyledTd>
                  );
                })}
                <StyledTd></StyledTd>
              </StyledTr>
            );
          })}
        </Tbody>
      </StyledTable>
    </Box>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  roleIds: getRolesById(app),
  roles: getRoles(app),
  permissions: getPermissions(app)
});

export default connect(mapStateToProps, null)(RoleTable);
