// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  Text,
  Button,
  VStack,
  HStack,
  UnorderedList,
  ListItem,
  Alert,
  AlertIcon,
  AlertDescription
} from "@chakra-ui/react";

import { getOrgName } from "src/reducers";
import { getOAuthAppName, getOAuthState } from "src/selectors";
import { grantOAuthAppPermisssions } from "src/actions/oAuth";
import type { State as OAuthState } from "src/reducers/oAuth";

import FormContainerWithLogo from "src/components/auth/FormContainerWithLogo";
import LoaderWithText from "src/components/Loader";
import * as styles from "./styles";

const integrations: {
  [appName: string]: {
    img?: string,
    displayName: string,
    permissions?: string[]
  }
} = {
  slack: {
    img: i18n.t(k.HTTPS_CDN_BFLDR_COM_H_O_W),

    displayName: i18n.t(k.SLACK),
    permissions: [
      i18n.t(k.CREATE_CONVERSATIONS_ON_UNIFIZ),
      i18n.t(k.FORWARD_MESSAGES_TO_UNIFIZE_ON)
    ]
  },
  microsoft365: {
    img: i18n.t(k.HTTPS_NEWS_MICROSOFT_COM_WP),

    displayName: i18n.t(k.MICROSOFT)
  },
  "microsoft365-admin": {
    img: i18n.t(k.HTTPS_NEWS_MICROSOFT_COM_WP),

    displayName: i18n.t(k.MICROSOFT_ADMIN)
  }
};

const OAuthAppPermissionPage = () => {
  const dispatch = useDispatch();

  const appName = useSelector(getOAuthAppName);
  const oAuthState: OAuthState = useSelector(getOAuthState);
  const currentOrgName = useSelector(({ app }) =>
    getOrgName(app, parseInt(app.orgs.last, 10))
  );

  const integrationData = integrations[appName];

  if (!currentOrgName)
    return (
      <FormContainerWithLogo>
        <LoaderWithText>
          <span data-cy="loading">
            {i18n.t(k.FETCHING_USER_AND_ORG_DETAILS)}
          </span>
        </LoaderWithText>
      </FormContainerWithLogo>
    );

  if (!integrationData)
    return (
      <FormContainerWithLogo>
        <Text>{i18n.t(k.INTEGRATION_NOT_FOUND)}</Text>
      </FormContainerWithLogo>
    );

  return (
    <FormContainerWithLogo>
      <VStack sx={styles.container}>
        <VStack sx={styles.cardHeader}>
          {integrationData.img && (
            <img
              src={integrationData.img}
              alt={`${appName}-logo`}
              style={styles.oAuthAppLogo}
            />
          )}

          <Text sx={styles.headerText}>
            <strong>{integrationData.displayName}</strong>{" "}
            {i18n.t(k.IS_REQUESTING_PERM)} <strong>{currentOrgName}</strong>
          </Text>
        </VStack>

        <VStack sx={styles.cardContent}>
          {integrationData.permissions && (
            <>
              <Text sx={styles.cardContentTitle}>
                {i18n.t(k.WHAT_CAN)} {integrationData.displayName}{" "}
                {i18n.t(k.DO)}
              </Text>

              <UnorderedList sx={styles.permissionList}>
                {/* $FlowFixMe */}
                {integrationData.permissions?.map(permission => (
                  <ListItem key={permission}>{permission}</ListItem>
                ))}
              </UnorderedList>
            </>
          )}

          {oAuthState.error && (
            <Alert status="error" sx={styles.errorAlert}>
              <AlertIcon />
              <AlertDescription>{oAuthState.error}</AlertDescription>
            </Alert>
          )}
        </VStack>

        <HStack sx={styles.cardFooter}>
          <Button
            variant="uBlueFilled"
            onClick={() => dispatch(grantOAuthAppPermisssions())}
            disabled={oAuthState.inProgress}
            isLoading={oAuthState.inProgress}
            loadingText="Allowing"
          >
            {i18n.t(k.ALLOW)}
          </Button>

          <Button
            disabled={oAuthState.inProgress}
            onClick={() => {
              window.location.replace("/");
            }}
          >
            {i18n.t(k.DENY)}
          </Button>
        </HStack>
      </VStack>
    </FormContainerWithLogo>
  );
};

export default OAuthAppPermissionPage;
