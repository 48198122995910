// @flow

import React from "react";

import ChatHistory from "src/components/messages/History";
import ChatInput from "src/components/chatroom/Input";
import LoadingState from "src/components/LoadingState";

import { FullOverlay } from "src/styles";
import { ChatArea as ChatAreaWrapper } from "./styles";
import type { UnifizeChatRoom, UnifizeUser } from "src/types";

type Props = {
  currentUser: UnifizeUser,
  room: UnifizeChatRoom,
  participants: boolean
};

const ChatArea = ({ room, currentUser, participants }: Props) => {
  return (
    <ChatAreaWrapper>
      {room && room.id ? (
        <ChatHistory roomId={room.id} />
      ) : (
        <LoadingState type="history" />
      )}
      {room && room.id && currentUser.uid ? (
        <ChatInput roomId={room.id} />
      ) : (
        <LoadingState type="input" />
      )}
      {participants && <FullOverlay />}
    </ChatAreaWrapper>
  );
};

export default ChatArea;
