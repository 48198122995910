// @flow

import i18n from "i18next";
import k from "src/i18n/keys";
import React, { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Modal, ModalHeader, ModalContent, Text } from "@unifize/sarah";
import Sidebar from "./Sidebar";
import Content from "./Content";
import { togglePreferencesModal } from "src/actions/modal";
import { getCurrentLanguage, getOpenModalName } from "src/reducers";
import { modalTypes } from "src/constants";

import { Container } from "./styles";

const Preferences = () => {
  const dispatch = useDispatch();
  const currentLanguage = useSelector(({ app }) => getCurrentLanguage(app));
  const openModalName = useSelector(({ app }) => getOpenModalName(app));
  const [activeIndex, setActiveIndex] = useState(0);

  const handleClose = () => {
    dispatch(togglePreferencesModal({ open: false }));
  };

  const preferences = [
    {
      label: i18n.t(k.NOTIFICATIONS, { lng: i18n.language }),
      icon: "notificationOutline"
    },
    {
      label: i18n.t(k.LANGUAGE, { lng: i18n.language }),
      icon: "language"
    }
  ];

  const changeActiveIndex = useCallback((index: number) => {
    if (index < preferences.length) setActiveIndex(index);
  }, []);

  return (
    <Modal
      isOpen={openModalName === modalTypes.preferences}
      onClose={handleClose}
      height="561px"
      width="863px"
    >
      <ModalHeader>{i18n.t(k.PREFERENCES)}</ModalHeader>
      <ModalContent>
        <Container>
          <Sidebar
            preferences={preferences}
            activeIndex={activeIndex}
            changeActiveIndex={changeActiveIndex}
          />
          <Content activeIndex={activeIndex} />
        </Container>
      </ModalContent>
    </Modal>
  );
};

export default Preferences;
